import { AuthenticationState, LoginStatusKind } from "./AuthEntities";
import { ErrorMessagePlacementKind } from "./Login/LoginEntities";
import { Config } from "../../Config/Config";

/** Initial value of Authentication */
export const InitAuthenticationState: AuthenticationState = {
    LoginStatus: LoginStatusKind.LoggedOut,
    IsProfilePanelShown: false,
    IsBookingNeededAfterVerification: false,
    Credentials: {
        CountryInfo: Config.DefaultCountryInfo
    },
    ErrorMessagePlacement: ErrorMessagePlacementKind.Popup,
    IsSilentLoginActive: false,
};