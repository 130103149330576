import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { DialogKind } from '../Dialog/DialogEntities';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { FeatureCardProps } from './FeaturesEntities';
import { MarkAsRead } from './FeaturesHelper';


interface PropsFromStore {
    openDialogs: Partial<Record<DialogKind, boolean>>;
}

/** Feature Close Button that only show in Dialog pop up */
export const FeatureCloseButtonCore = (props: FeatureCardProps & PropsFromStore) => {

    const closeButton = getContentUrl(ContentURL.images.BlackX);

    if (!props.openDialogs[DialogKind.NewFeature]) return null;

    if (!props.CanShowCloseButton) return null;

    return (
        <div className="button-close">
            <img alt="close" src={closeButton} onClick={() => MarkAsRead(props.FeatureId)} />
        </div>
    )
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        openDialogs: state.dialog.openDialogs
    }
}

export default connect(mapStateToProps)(FeatureCloseButtonCore)