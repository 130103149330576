import { AddPaymentMethodResponse } from "../../Services/PaymentContractsV2";
import { MpsProfile, PaymentCard } from "../../Services/PaymentEntities";
import { PaymentCardErrorType } from "./PaymentEntities";

/** Application State  for payment */
export interface PaymentState {
    /** Unfiltered list of the user's cards, i.e. all cards */
    AllCards: PaymentCard[];

    /** card to be removed, this value is used by remove payment confirmation to pass to delete card service */
    CardToRemove?: PaymentCard;

    /** Specifies default card, this is used to  reference prev default card while manipulating default in all card list */
    DefaultCard: PaymentCard | null;

    /** If Card Registration Panel is Open , as it can be triggered from multiple places */
    IsCardRegistrationPanelOpen: boolean;

    /** Error type of the payment card if there is any. Null if no errors. */
    PaymentCardError: PaymentCardErrorType | null;

    /**
     * 1. Holds the status to display the add payment card screen after signup to the logged-in user
     * 2. The Signup process is triggered by clicking the "Add a new card" option from the payment method
     */
    ShouldDisplayAddPaymentCardScreenAfterSignup: boolean;

    /** User profile from MPS. This is different from Booking API's user profile. This is used in various payment flows like GetClientToken, PaymentMethodNonce etc. Null if the user not logged in.  */
    MpsProfile: MpsProfile | null;

    /** Whether the Add Payment Method dialog is open. This dialog is not included in the generic dialog tech but is using only some if the components of the dialog. Therefore it is opened and closed differently than other dialogs. */
    IsAddPaymentDialogOpen: boolean;

    /** Client token from MPS. Used to load Braintree client to initiate wallet based payments. */
    ClientToken: string | null;

    /** After registering a wallet based payment method (e.g: Apple Pay, Google Pay etc), it is not included in the user's cards list since not all the devices support wallet based payments. The response of the API call to register a payment method which includes CardId is stored to be used with the booking request later. */
    ApplePay: AddPaymentMethodResponse | null;

    /** Whether the CNP payments are currently available. This becomes false when there is an outage with Braintree or MPS. When false, do not display CNP payment options in the UI and let the user know about CNP unavailability. */
    IsPaymentSystemAvailable: boolean;

    /** After registering a wallet based payment method (e.g: Apple Pay, Google Pay etc), it is not included in the user's cards list since not all the devices support wallet based payments. The response of the API call to register a payment method which includes CardId is stored to be used with the booking request later. */
    GooglePay: AddPaymentMethodResponse | null;

    /** The result of the 3DS authentication performed on the selected payment card for the booking. */
    ThreeDSecure: ThreeDSecureResult | null;

    /** Credit/debit card details from MPS after registering a card for a guest user. 
     * The data type is slightly different from the list of cards of a signed in user. Not many fields in the signed in user's cards are available for guest users. So it is better to store the card as it is than converting data to be the same type as the list of cards of a signed in user. */
    GuestCreditCard: AddPaymentMethodResponse | null;
}

/** Data required to make a booking if 3DS authentication is required for the selected payment card. Obtained from the output of the 3DS verification flow. */
export interface ThreeDSecureResult {
    /** A nonce obtained from 3DS verification. */
    Upgraded3DsNonce: string | null;

    /** Whether the liability for fraudulent chargebacks has been shifted to the card issuer during the 3DS authentication. */
    LiabilityShifted3Ds: boolean | null;
}

/** Initial value of the PaymentState state */
export const PaymentStateInitialState: PaymentState = {
    AllCards: [],
    DefaultCard: null,
    IsCardRegistrationPanelOpen: false,
    PaymentCardError: null,
    ShouldDisplayAddPaymentCardScreenAfterSignup: false,
    MpsProfile: null,
    IsAddPaymentDialogOpen: false,
    ClientToken: null,
    ApplePay: null,
    IsPaymentSystemAvailable: true,
    GooglePay: null,
    ThreeDSecure: null,
    GuestCreditCard: null,
};