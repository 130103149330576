import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './TaxiTrackingDialog.scss';
import { Dispatch } from '../../Dispatch';
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import { BookingStatus } from "../../../Services/BookingEntities";
import { ShowDialogSimpleErrorMessage } from "../../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../../Utils/ErrorMessages";
import { BookingInfo } from '../MyBookingEntities';
import { TaxiTrackingGoogleMap } from '../../../widgets/google-maps/MapComponents/TaxiTrackingGoogleMap';

interface PropsFromStore {
    BookingsList: BookingInfo[];
    MapFocusedBookingID?: number;
}

/**
 * This is a dialog which host a google map for booking tracking purpse.
 */
class TaxiTrackingDialog extends Component<PropsFromStore> {

    closePopup() {
        Dispatch.Dialog.CloseTopDialog();
    }

    componentDidUpdate() {
        if (this.hasTripEnded()) 
            this.showTripEndsMesage();
    }

    // Find and return the booking in myBooking.all by bookingID which was selected to show map
    findSelectedBooking() {
        return this.props.BookingsList.find(booking => booking.BookingID === this.props.MapFocusedBookingID);
    }

    // Close map and open an education message if trip ends
    showTripEndsMesage() {
        this.closePopup();
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.TripEnds);
    }

    // This is to detect if the booking becomes completed or cancelled
    hasTripEnded() : boolean {
        const currBooking = this.findSelectedBooking();

        if (!currBooking) return true;

        if (currBooking.TrackingInfo.BookingStatusID === BookingStatus.Accepted || currBooking.TrackingInfo.BookingStatusID === BookingStatus.PickedUp) return false;

        return true;
    }

    render() {
        const selectedBooking = this.findSelectedBooking();

        const map = (selectedBooking ? <TaxiTrackingGoogleMap BookingDetails={selectedBooking} /> : null);

        return(
            <div className="taxi-tracking-dialog-panel">
                  <img onClick={this.closePopup} src={getContentUrl(ContentURL.images.buttons.greyX)} alt="close" />
                  {map}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        BookingsList: state.myBookings.All,
        MapFocusedBookingID: state.myBookings.MapFocusedBookingID
    };
}

export default connect(mapStateToProps)(TaxiTrackingDialog);