import braintree, { ApplePayPayload } from "braintree-web";
import appstore from "../../appStore";
import { GetValues } from "../../Config/MyAppConfig";
import { Api } from "../../Services/Api";
import { AddPaymentMethodRequest } from "../../Services/PaymentContractsV2";
import { MyStorage } from "../../Storage";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { Dispatch } from "../Dispatch";
import { CalculateExactAmountUserPaysInDollars } from "../Fare/FareHelper";
import { ApplePayPlaceholder } from "../Payment/PaymentEntities";
import { ValidateGuestUser } from "../Verification/VerifyPhoneForBooking";
import { AddApplePayFunnel } from "./AddApplePayFunnel";

/** Adding an Apple Pay payment method. */
export namespace ApplePay {

    /** Braintree client SDK from a client token */
    let braintreeClient: braintree.Client | null = null;

    /** Apple Pay client SDK from our Braintree SDK */
    let applePayClient: braintree.ApplePay | null = null;

    /**
     * Provide the completed BrainTree and Apple Pay SDK clients after they are loaded.
     */
    export function SdkIsReady(btClient: braintree.Client, apClient: braintree.ApplePay) {
        braintreeClient = btClient;
        applePayClient = apClient;
    }

    /** Performs Apple Pay flow (open the Apple Pay sheet, get user authorisation, send nonce to MPS) */
    export async function RunApplePayUiFlowAndProceedBooking() {

        AddApplePayFunnel.Start();

        const fareDetails = appstore.getState().condition.SelectedCondition.FareDetails;
        const tripCost = CalculateExactAmountUserPaysInDollars(fareDetails!, true, true);
        const brandName = GetValues().BrandName;

        // using braintree SDK's createPaymentRequest method because it provides some of the mandatory properties of the payment request.
        const paymentRequest = applePayClient!.createPaymentRequest({
            total: {
                label: brandName,
                amount: tripCost.toFixed(2)
            }
        });

        let paymentSession = new window.ApplePaySession(3, paymentRequest);

        // validate the session
        paymentSession.onvalidatemerchant = async function (event: ApplePayJS.ApplePayValidateMerchantEvent) {

            let merchantSession: any;

            try {
                merchantSession = await applePayClient!.performValidation({
                    validationURL: event.validationURL,
                    displayName: brandName
                });
            }
            catch (error) {
                AddApplePayFunnel.Exception("Validate Merchant", error, true);
                return;
            }

            paymentSession.completeMerchantValidation(merchantSession);
        }

        // use Apple Pay token from the browser to obtain the payment method nonce from Braintree.
        paymentSession.onpaymentauthorized = async function (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) {            

            let tokenisedApplePay: braintree.ApplePayPayload;

            try {
                tokenisedApplePay = await applePayClient!.tokenize({
                    token: event.payment.token
                });
            }
            catch (error) {
                AddApplePayFunnel.Exception("Tokenise Apple Pay", error, true);
                paymentSession.completePayment(ApplePaySession.STATUS_FAILURE);
                return;
            }

            // call 'completePayment' to dismiss the Apple Pay sheet.
            paymentSession.completePayment(ApplePaySession.STATUS_SUCCESS);

            const loginState = appstore.getState().authentication.LoginStatus;

            if (loginState == LoginStatusKind.LoggedIn) {
                AddApplePaySignedIn(tokenisedApplePay);
            }
            else {
                AddApplePayGuest(tokenisedApplePay);
            }
        }

        paymentSession.begin();
    }

    /** Send tokenised Apple Pay payment method to MPS to add it to the user's payment methods. */
    async function AddApplePaySignedIn(applePayData: ApplePayPayload) {

        // block the booking page and let the user know a backgrouund process is in progress by displaying a loading gif.
        Dispatch.UILogicControl.BookingFormApiStart();

        // send nonce to MPS to register Apple Pay for the user.
        const request: AddPaymentMethodRequest = {
            TokenisedPaymentNonce: applePayData!.nonce,
            PaymentType: applePayData!.type
        }

        var result = await Api.PaymentV2.RegisterPaymentMethod(request);

        if (!result.isSuccess) {
            AddApplePayFunnel.ApiError("RegisterPaymentMethod API", result, true);

            // hide loading because the process terminates here
            Dispatch.UILogicControl.BookingFormApiEnd();
            return;
        }

        Dispatch.Payment.AddApplePay(result.value);

        // make this the default payment method for the subsequent bookings.
        MyStorage.DefaultPaymentMethod.StoreData(ApplePayPlaceholder);

        // proceed to booking only if register payment method succeeded. otherwise display an error message (happens in the various stages of the UI flow)
        CreateBookingWithoutSmsVerification();
    }

    /** Store the tokenised Apple Pay payment method for later use and start mobile verification process. */
    async function AddApplePayGuest(applePayData: ApplePayPayload) {
        Dispatch.GuestPayment.ApplePayTokenised(applePayData);
        await PopulateDeviceData(braintreeClient!);
        ValidateGuestUser();
    }

    /**
     * Populate the DeviceData booking property, used for payment fraud detection.
     * This is only required for guest users; signed in users will already have populated this at login.
     */
    async function PopulateDeviceData(braintreeClient: braintree.Client) {

        const dataCollector = await braintree.dataCollector.create({
            client: braintreeClient,
            kount: true,
            paypal: true,
        });

        const deviceData = dataCollector.deviceData;
        Dispatch.Booking.SetDeviceData(deviceData);
    }

    /** Determines whether the current device/browser can make Apple Pay payments. Used to display Apple Pay option only in the devices that supports Apple Pay. */
    export function CanMakePaymentsWithApplePay() {

        // current device/browser doesn't support Apple Pay.
        if (!window.ApplePaySession) {
            return false;
        }

        // the device supports Apple Pay but not version 3.
        if (!window.ApplePaySession.supportsVersion(3)) {
            return false;
        }

        // device is not capable of making Apple Pay payments.
        if (!window.ApplePaySession.canMakePayments()) {
            return false;
        }

        return true;
    }
}