import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { AsyncLoadState, DataLoadingStatus } from "../../modules/Condition/Redux/ConditionEntities";
import { PickupServiceCheckState, ServiceCheckStatus } from "../../modules/Booking/BookingEntities";
import { getContentUrl, ContentURL } from "../../modules/Utils/ContentURL";
import "./DataLoadingStatusPanel.scss";
import { ApiFailureSettingsUI } from "../ApiFailureTesting/ApiFailureSettingsUI";

/** Props we get from the store: all tracking the progress of various asynchronous loads */
interface StoreProps {
    Condition: AsyncLoadState<unknown>;
    Fare: AsyncLoadState<unknown>;
    Service: PickupServiceCheckState;
}

/** A demonstration UI which visualises the state of the async data loaders. For testing / diagnostics. */
class DataLoadStatusPanelCore extends React.Component<StoreProps> {

    /** Convert one status enum (ServiceCheckStatus) into another (DataLoadingStatus). */
    MapStatus(serviceStatus: ServiceCheckStatus): DataLoadingStatus {
        switch (serviceStatus) {
            case ServiceCheckStatus.CheckInProgress:
                return DataLoadingStatus.InProgress;
            case ServiceCheckStatus.NoInputSelected:
            case ServiceCheckStatus.KnownGood:
                return DataLoadingStatus.Idle;
            default:
                return DataLoadingStatus.Error;
        }
    }

    render() {

        // the pickup check needs some extra processing because it's in a different format to the others (which use AsyncLoadState<>)
        const pickupCheck = this.props.Service;
        const pickupStatus = this.MapStatus(pickupCheck.status);
        const pickupErrorMessage = ((pickupCheck.status === ServiceCheckStatus.Error) || (pickupCheck.status === ServiceCheckStatus.KnownBad)) ? pickupCheck.errorMessage.ProblemText : undefined;

        return (
            <div className="DataLoadStatusPanel">
                <DataLoadStatusRow Name="Service Check" Status={pickupStatus} ErrorMessage={pickupErrorMessage} />
                <DataLoadStatusRow Name="Conditions" Status={this.props.Condition.Status} ErrorMessage={this.props.Condition.ErrorMessage} />
                <DataLoadStatusRow Name="Fare" Status={this.props.Fare.Status} ErrorMessage={this.props.Fare.ErrorMessage} />
                <ApiFailureSettingsUI />
            </div>
        );
    }
}

/** Cut down version of AsyncLoadState with only the fields we display on the UI */
interface BasicLoadingStatus {

    /** Human-readable description of the data set being loaded. */
    Name: string;

    /** Idle / In Progress / Error */
    Status: DataLoadingStatus;

    /** The error message, defined when the status is Error */
    ErrorMessage?: string;
}

/** This is used to render a single row in the panel, for one status item. */
class DataLoadStatusRow extends React.Component<BasicLoadingStatus> {

    getImageUrl(): string {
        if (this.props.Status === DataLoadingStatus.Error) return getContentUrl(ContentURL.images.RedX);
        if (this.props.Status === DataLoadingStatus.InProgress) return getContentUrl(ContentURL.images.Loading);
        return getContentUrl(ContentURL.images.GreenCheckMark);
    }

    render() {
        return (
            <div className="DataLoadStatusRow">
                <img src={this.getImageUrl()} alt={this.props.ErrorMessage} title={this.props.ErrorMessage} />
                <span>{this.props.Name}</span>
                <span className="Error">{this.props.ErrorMessage}</span>
            </div>
        );
    }
}

function mapStoreToProps(state: ApplicationState): StoreProps {
    return {
        Condition: state.condition.LoadingStatus,
        Fare: state.condition.FareLoadStatus,
        Service: state.booking.PickupServiceCheck,
    };
}

export const DataLoadStatusPanel = connect(mapStoreToProps)(DataLoadStatusPanelCore);