import { BookingService } from "./BookingService";
import { FareService } from "./FareService";
import { LocationService } from "./LocationService";
import { UserService } from "./UserService";
import { PaymentService } from "./PaymentService";
import { ContactService } from "./ContactUsService";
import { BookingTemplateService } from "./BookingTemplateService";
import { BookingServiceV2 } from "./BookingServiceV2";
import { SmsVerificationService } from "./SmsVerificationService";
import { LegalDocumentsService } from "./LegalDocumentsService";
import { MakeBookingService } from "./MakeBookingService";
import { AddressPointsService } from "./AddressPointsService";
import { FeaturesService } from "./FeaturesService";
import { PaymentServiceV2 } from "./PaymentServiceV2";
import { LocationServiceV2 } from "./LocationsServiceV2";

/** Entry point for all API calls to the Booking Management Service */
export const Api = {

    /** Operations in the Booking Controller */
    Booking: BookingService,

    /** Operations in the Fare Controller */
    Fare: FareService,

    /** Operations in the Location Controller */
    Location: LocationService,

    /** Operations in the Payment Controller */
    Payment: PaymentService,

    /** Operations in the User Controller */
    User: UserService,

    /** Operations in the Contact Controller */
    Contact: ContactService,

    /** Operations in the BookingTemplate Controller */
    BookingTemplate: BookingTemplateService,

    /** Operations in the BookingV2 controller. */
    BookingV2: BookingServiceV2,

    /** Operations in the SMS Verification Controller */
    SmsVerification: SmsVerificationService,

    /** Operations in the LegalDocuments Controller */
    LegalDocuments: LegalDocumentsService,

    /** Operations in the MakeBooking controller */
    MakeBooking: MakeBookingService,

    /** Operations in the AddressPoints Controller */
    AddressPoints: AddressPointsService,

     /** Operations in the Features Controller */
    Features: FeaturesService,

    /** Operations in the PaymentV2 controller*/
    PaymentV2: PaymentServiceV2,

    /** Operations in the LocationV2 Controller */
    LocationV2: LocationServiceV2,
}