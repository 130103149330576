import React from "react";
import SiteHeader from "../Header/SiteHeader";
import { FeatureFlags } from "../../Config/FeatureFlags";
import "./FeatureFlagsUI.scss";
import { Redirect } from "react-router";

/** IsDone is set to true when the user clicks save to trigger the redirect home */
interface MyState {
    IsDone: boolean
}

/** A UI for user to toggle feature flags at runtime, for testing purposes */
export class FeatureFlagsUI extends React.Component<{}, MyState> {

    /** Any values that have been changed on the UI */
    changes: Record<string, boolean> = { }

    constructor() {
        super({});
        this.state = { IsDone: false };

        // immediately enable the nav link feature so the user can freely navigate to this page during their session
        FeatureFlags.UserFlagsNavLink = true;
    }

    render() {

        if (this.state.IsDone) return <Redirect to="/" />;

        var features = this.GetFeatures()

        var tickBoxes = features.map(feature => (
            <div>
                <input key={feature.Name} type="checkbox" defaultChecked={feature.Value} onChange={e => this.OnFeatureClicked(feature, e)} />
                {feature.Name}
            </div>
        ));

        return (
            <div>
                <SiteHeader />
                <div className="featureflags">
                    <button onClick={this.OnSaveClicked}>Save</button>
                    {tickBoxes}
                </div>
            </div>
        );
    }

    /** Extracts the items from the const FeatureFlags into a flat array */
    GetFeatures(): Feature[] {

        // shape it more weakly, but iterable
        const flags: Record<string, boolean> = FeatureFlags;

        const result = Object.keys(FeatureFlags).map<Feature>(featureName => ({
            Name: featureName,
            Value: flags[featureName],
        }));

        return result;
    }

    /** A checkbox is toggled on the UI. Note the changed value. */
    OnFeatureClicked = (feature: Feature, event: React.ChangeEvent<HTMLInputElement>) => {
        const value: boolean = event.target.checked;
        this.changes[feature.Name] = value;

        console.log(`${feature.Name} changed to ${value}`);
    }

    /** User commits the changes by clicking the save button. Write changes back to FeatureFlags */
    OnSaveClicked = () => {

        // a different but equivalent typing that allows setting arbitrary keys
        const flags: Record<string, boolean> = FeatureFlags;

        // apply changes
        for (let key of Object.keys(this.changes)) {
            flags[key] = this.changes[key];
        }

        // navigate back to home via <Redirect> in render()
        this.setState({ IsDone: true });
    }
}

/** One of these records created for each key in the FeatureFlags object */
interface Feature {

    /** The key, e.g. "PromoteGuestBookings" */
    Name: string;

    /** The current value at the time this record was created */
    Value: boolean;
}