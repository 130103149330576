import { styled, Tab, Tabs, Theme } from "@mui/material";

/** Component overrides the styles of existing Tabs MUI component adding custom CSS styling that fits our Theme.*/
export const StyledTabs = styled(Tabs)(() => ({
    alignItems: 'center',
    '& .MuiTabs-flexContainer': {
        justifyContent: 'space-evenly'
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    },
}));

/** Component overrides the styles of existing Tab Item (individual) MUI component adding custom CSS styling that fits our Theme. */
export const StyledTab = styled(Tab)(({ theme }: {theme: Theme}) => ({
    color: theme.palette.text.primary,
    opacity: 1,
    flexDirection: "row",
    border: '1px solid #C0BFBF',
    borderRadius: 18,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 0.15,
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: 'none',
    },
    '& .tabIcon': {
        paddingRight: theme.spacing(4),
        height: 25,
        width: 24
    }
}));