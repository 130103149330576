import React, { FC } from 'react';
import "./Features.scss";
import { connect } from 'react-redux';
import { Dispatch } from '../Dispatch';
import { FeatureCard } from './FeatureCard';
import { FeaturesState } from './FeaturesState';
import { ApplicationState } from '../../appState';
import { FeatureCardProps } from './FeaturesEntities';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import TransparentBlackBg from '../../widgets/transparent-black-background/transparent-black-background';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { CommonDialogHeaderCore } from '../Dialog/CommonDialogHeader';
import { DialogKind } from '../Dialog/DialogEntities';

interface PropsFromStore {
    Features: FeaturesState,
    IsMobileDevice: boolean
}

/**
 * A list of feature / what's new cards.
 */
export const FeatureListCore: FC<PropsFromStore> = (props) => {

    /**
     * Close the What's New panel
     */
    function Close() {

        Dispatch.Features.HideWhatsNewFeatures();         
    }
    
    /**
     * Create a feature list for provided ids 
     */
    function CreateFeatureList(featureIdList: number[], canShowAcknowledgeButton = true) {
        // Sort in the descending order.
        const sortedList = featureIdList.sort((id1, id2) => id2 - id1);
        
        const featureList = sortedList.map(id => {
            
            const props: FeatureCardProps = {
                FeatureId: id,
                CanShowAcknowledgeButton: canShowAcknowledgeButton,
                CanShowCloseButton: false
            }   

            return <FeatureCard {...props}  />
        });

        return featureList;
    }

    const features = props.Features;
    const newFeatureList = CreateFeatureList(features.NewFeatureList);

    const acknowledgedFeatureList = CreateFeatureList(features.AcknowledgedFeatureList, false);

    const openPanelCssClass = props.IsMobileDevice ? 'whats-new-panel-open-in-mobile' : features.IsFeatureListDisplayed && 'whats-new-panel-open';
    
    return (
        <div>
            { openPanelCssClass && <TransparentBlackBg /> }
            <div className={`whats-new-panel ${openPanelCssClass}`}>
                {props.IsMobileDevice ? <CommonDialogHeaderCore TopmostDialog={DialogKind.WhatsNew}/>
                    :
                    <div className="whats-new-header-section">
                        <div className="whats-new-header-content">
                            <div className="whats-new-title">WHAT'S NEW</div>
                            <img className="close-panel-button" src={getContentUrl(ContentURL.images.BlackCircleX)}  alt="close" onClick={() => Close()} />
                        </div>
                    </div>
                }
                <div className="feature-list">
                    <div>{newFeatureList}</div>
                    { 
                        acknowledgedFeatureList.length > 0 &&
                        <>
                            <div className="last-viewed">
                                <span className="last-viewed-title">Last viewed</span>
                                <hr className="last-viewed-divider" />                       
                            </div>
                            <div>{acknowledgedFeatureList}</div>
                        </>
                    }                 
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: ApplicationState) : PropsFromStore {
    return {
        Features: state.features,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(FeatureListCore);