import React, { Component } from 'react';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import './selected-vehicle-bar.scss';
import { ServiceCheckStatus, PickupServiceCheckState } from "../../Booking/BookingEntities";
import { VehicleOption, DataLoadingStatus } from '../Redux/ConditionEntities';
import ConditionView from './ConditionView';
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import Loading from '../../../widgets/loading/loading';
import './VehicleSelector.scss';
import ConditionSelector from './ConditionSelector';
import ConditionInfoView from './ConditionInfoView';
import TransparentBlackBg from '../../../widgets/transparent-black-background/transparent-black-background';
import { Dispatch } from '../../Dispatch';
import { listConditionsWithFareByPickUp } from '../PopulateConditions';
import { DeriveServiceErrorMessage } from '../ProcessAndValidateSelectedCondition';
import { BookingFormKind, UILayoutMode } from '../../UILogicControl/UILogicControlEntities';
import PriceGuaranteeToggle from '../../Fare/PriceGuaranteeToggle';
import { GetValues } from '../../../Config/MyAppConfig';
import { CanShowPriceGuaranteePanel } from '../../Fare/PriceGuaranteeToggleHandler';
import { Tooltip } from '@mui/material';
import { PreviewFeatureId } from '../../Features/FeatureEntities';

/** Component to display the Service selector widget and list of services (based on the open/close status) in the booking form. */
class VehicleSelectorCore extends Component<ReduxProps> {
    constructor(props: ReduxProps) {
        super(props)

        this.ToggleVehicleSelector = this.ToggleVehicleSelector.bind(this);
    }

    /**
     * Opens (expand) the service selector by default (on page load) unless a service is selected already. 
     * Avoid expanding for silver service brand.
     */
    componentDidMount() {
        
        if (GetValues().DefaultToSilverservice) return;

        if (this.props.SelectedCondition.ApiVehicle) return;

        listConditionsWithFareByPickUp();        
    }

    /** This component decides to render price guarantee (fixed price) toggle based on different conditions. 
     * If it is displayed, Fixed Price is always selected (toggled ON) unless the user manually turn it off. The only time 'IsPriceGuaranteeSelected' should become false automatically is when the toggle is hidden.
     * Therefore on every update of this component, the value of IsPriceGuaranteeSelected state is decided based on whether the toggle is displayed or not. 
     * */
    componentDidUpdate() {
        if (!this.props.CanShowPriceGuaranteeToggle) {
            Dispatch.Condition.ClearPriceGuarantee();
        }
        else if (this.props.IsPriceGuaranteeSelected) {
            Dispatch.Condition.SetPriceGuarantee();
        }
    }

    /** Toggle vehicle selector on click of the 'Select your service' label area. */
    ToggleVehicleSelector() {

        // No vehicles loaded from the API (either the API call is failed or no vehicles defined for the brand yet).
        // Only applicable to the V2 API
        if (this.props.VehiclesList.length === 0) return;

        // Toggle the open/close status.
        Dispatch.Condition.ToggleVehicleSelectorUI();
    }

    /** Derive error message for vehicle selector. */
    GetErrorMessage() {
        if (this.props.IsVehicleSelectorActive) return null;

        return this.props.ServiceErrorMessage;
    }

    GetSelectorLabel() {
        if (this.props.IsNewParcelFeatureEnabled) {
             return "Select vehicle type";
        } 
        
        return "More vehicle/service options";
    }

    render() {
        const invalidVehicleContainerClass = this.GetErrorMessage() !== null && "invalid-vehicle-container";
        const isLoadInProgress = (this.props.ConditionLoadState === DataLoadingStatus.InProgress) || (this.props.PickupServiceCheckState.status === ServiceCheckStatus.CheckInProgress);

        const arrowUrl = this.props.IsVehicleSelectorActive ? getContentUrl(ContentURL.images.arrows.ArrowUpBlack) : getContentUrl(ContentURL.images.arrows.ArrowDownBlack);
        const isLoadingShown = this.props.IsVehicleSelectorActive && isLoadInProgress;

        const marginTopCssClass = !this.props.CanShowPriceGuaranteeToggle ? "service-section-marginTop" : "";

        const tooltipTitle = this.props.IsVehicleSelectorActive ? "Hide services" : "Show services";
        
        const selectedVehicleCssClass = this.props.ActiveBookingForm === BookingFormKind.ParcelBooking ? "parcel-selected-vehicle-bar-panel" : "selected-vehicle-bar-panel";

        return (
            <div>
                {this.props.CanShowPriceGuaranteeToggle && <PriceGuaranteeToggle />}
                <div className={selectedVehicleCssClass} onClick={this.ToggleVehicleSelector}>
                    {this.props.ActiveBookingForm === BookingFormKind.PassengerBooking && <div className="vehicle-selector-label-area">
                        <span>{ this.GetSelectorLabel() }</span>
                        <Tooltip title={tooltipTitle} arrow>
                            <span><img src={arrowUrl} /></span>
                        </Tooltip>
                    </div>
                    } 
                    
                    <hr className={`service-section-separator ${marginTopCssClass}`} />

                    {!this.props.IsVehicleSelectorActive && <div className={`${invalidVehicleContainerClass}`}>
                        <ConditionView
                            ServiceDetails={this.props.SelectedCondition}
                            IsTemplateModeOn={this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking}
                            ClickHandler={listConditionsWithFareByPickUp}
                        />
                    </div>}
                </div>
                {isLoadingShown && <Loading CanShowPriceGuaranteeToggle={this.props.CanShowPriceGuaranteeToggle} />}
                {
                    this.GetErrorMessage() && (
                        <div className="booking-form-error-message">
                            {this.GetErrorMessage()}
                        </div>
                    )
                }
                
                {this.props.IsVehicleSelectorActive && <ConditionSelector />}
                {
                    this.props.UnderMobileMode && this.props.ShowServiceInfo &&
                    <>
                        <ConditionInfoView />
                        <TransparentBlackBg />
                    </>
                }
            </div>
        );
    }
}

// #region Redux

interface ReduxProps {
    SelectedCondition: VehicleOption;
    PickupServiceCheckState: PickupServiceCheckState;
    BookingWidgetMode: BookingWidgetModeKind;
    fareLoadStatus: DataLoadingStatus;
    IsVehicleSelectorActive: boolean;
    ConditionLoadState: DataLoadingStatus;
    ShowServiceInfo: boolean;
    ServiceErrorMessage: string | null;
    UnderMobileMode: boolean;
    CanShowPriceGuaranteeToggle: boolean;
    VehiclesList: VehicleOption[];
    IsNewParcelFeatureEnabled: boolean;
    ActiveBookingForm: BookingFormKind;
    IsPriceGuaranteeSelected: boolean;
}

function mapStateToProps(state: ApplicationState): ReduxProps {
    return {
        SelectedCondition: state.condition.SelectedCondition,
        PickupServiceCheckState: state.booking.PickupServiceCheck,
        fareLoadStatus: state.condition.FareLoadStatus.Status,
        IsVehicleSelectorActive: state.condition.IsVehicleSelectionActive,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        ConditionLoadState: state.condition.LoadingStatus.Status,
        ShowServiceInfo: state.condition.ShowServiceInfo,
        ServiceErrorMessage: DeriveServiceErrorMessage(state),
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        CanShowPriceGuaranteeToggle: CanShowPriceGuaranteePanel(state),
        VehiclesList: state.condition.ConditionList,
        IsNewParcelFeatureEnabled: !!state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget],
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected
    }
}

// #endregion

export const VehicleSelector = connect(mapStateToProps)(VehicleSelectorCore);