import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { VerificationState, InitVerificationState } from "./VerificationState";
import { CountryInfo, VerificationTrigger } from "./VerificationEntities";

const slice = new ReduxStoreSlice<VerificationState>("Verification", InitVerificationState);

/** Dispatcher for actions in the Verification state slice. */
export const VerificationDispatchV2 = {

    /** The user enters their preferred contact number. */
    ContactNumber: slice.Action("Contact Number", ContactNumber),

    /** The user picks the country of their contact number. */
    CountryInfo: slice.Action("Country Info", CountryInfoFn),

    /** Clear all details of the user's contact number. */
    ClearContactNumber: slice.EmptyAction("Clear Contact Number", ClearContactNumber),

    /** Show the spinner UI in the Contact Details form while an API call is in progress. */
    ShowLoaderInContactDetails: slice.EmptyAction("Show Loader In Contact Details", ShowLoaderInContactDetails),

    /** Hide the spinner UI in the Contact Details form after an API call has finished. */
    HideLoaderInContactDetails: slice.EmptyAction("Hide Loader In Contact Details", HideLoaderInContactDetails),

    /** Set the error message about the contact number to be displayed in the booking widget. Do NOT pass an empty string; call ClearContactNumberError instead. */
    ContactNumberErrorMessage: slice.Action("Contact Number Error Message", ContactNumberErrorMessage),

    /** Remove any error message associated with the contact number. */
    ClearContactNumberError: slice.EmptyAction("Clear Contact Number Error", ClearContactNumberError),

    /** Indicate which UI started the verification flow: Booking or Signup. */
    WasTriggeredBy: slice.Action("Was Triggered By", WasTriggeredBy),

    /** A new SMS Challenge has started with the specified ID */
    ChallengeStarted: slice.Action("Challenge Started", ChallengeStarted),

    /** Record that the previous SMS challenge was successful. This is only used in cases where the followup action is quite separate from the Submit() API call. */
    ChallengeSucceeded: slice.Action("Challenge Succeeded", ChallengeSucceeded),

    /** The previously succeeded challenge is no longer usable, either because it has been used successfully, or an operation unexpected returned an SMS verification error */
    ClearChallenge: slice.EmptyAction("Clear Challenge", ClearChallenge),

    /** Update the number of times in a row the user entered the verification code for mobile number verification. */
    VerificationAttemptCount: slice.Action("Verification Attempt Count", VerificationAttemptCount),
};

/** Reducer for the Verification store slice */
export const VerificationReducerV2 = slice.MakeCombinedReducer();

/** The user enters their preferred contact number. */
function ContactNumber(state: VerificationState, phoneNumber: string): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: { ...state.UserContactNumberInfo, Contactnumber: phoneNumber },
    };
}

/** The user picks the country of their contact number. */
function CountryInfoFn(state: VerificationState, payload: CountryInfo): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: {
            ...state.UserContactNumberInfo,
            CountryInfo: { ...state.UserContactNumberInfo.CountryInfo, ...payload }
        }
    };
}

/** Clear all details of the user's contact number. */
function ClearContactNumber(state: VerificationState): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: {}
    };
}

/** Show the spinner UI in the Contact Details form while an API call is in progress. */
function ShowLoaderInContactDetails(state: VerificationState): VerificationState {
    return {
        ...state,
        IsLoaderShownInContactDetails: true,
    };
}

/** Hide the spinner UI in the Contact Details form after an API call has finished. */
function HideLoaderInContactDetails(state: VerificationState): VerificationState {
    return {
        ...state,
        IsLoaderShownInContactDetails: false,
    };
}

/** Set the error message about the contact number to be displayed in the booking widget. */
function ContactNumberErrorMessage(state: VerificationState, errorMessage: string): VerificationState {
    return {
        ...state,
        UserContactNumberInfo: { ...state.UserContactNumberInfo, ErrorMessage: errorMessage }
    };
}

/** Remove any error message associated with the contact number. */
function ClearContactNumberError(state: VerificationState): VerificationState {

    // split error message from other bits
    const { ErrorMessage, ...Remain } = state.UserContactNumberInfo;

    return {
        ...state,
        UserContactNumberInfo: Remain,
    };
}

/** Indicate which UI started the verification flow: Booking or Signup. */
function WasTriggeredBy(state: VerificationState, trigger: VerificationTrigger): VerificationState {
    return {
        ...state,
        Trigger: trigger,
    };
}

interface ChallengeStartedPayload {

    /** Mobile phone number being challenged. In E.164 format with leading "+" */
    phoneNumber: string;

    /** The ID of the challenge from the server. */
    challengeId: string;
}

/** A new SMS Challenge has started with the specified ID */
function ChallengeStarted(state: VerificationState, payload: ChallengeStartedPayload): VerificationState {
    return {
        ...state,
        SmsChallengeId: payload.challengeId,
        PhoneNumberUnderChallenge: payload.phoneNumber,
        SuccessfulUnusedCode: null,
    };
}

/** Record that the previous SMS challenge was successful. This is only used in cases where the followup action is quite separate from the Submit() API call. */
function ChallengeSucceeded(state: VerificationState, successfulCode: string): VerificationState {
    return {
        ...state,
        SuccessfulUnusedCode: successfulCode
    }
}

/** The previously succeeded challenge is no longer usable, either because it has been used successfully, or an operation unexpected returned an SMS verification error */
function ClearChallenge(state: VerificationState): VerificationState {
    return {
        ...state,
        SuccessfulUnusedCode: null
    }
}

/** Update the number of times in a row the user entered the verification code for mobile number verification. */
function VerificationAttemptCount(state: VerificationState, count: number) {
    return {
        ...state,
        VerificationAttemptCount: count
    }
}