import { ReduxStoreSlice } from "../../../Redux/ReduxStoreSlice";
import { BookingLocationInfo, BookingWorkFlowState } from "./BookingState";

/** 
 *  Creates redux actions on the Booking slice which apply to one of the booking locations.
 *  In addition to the action payload, they also take the index value to describe the affected location.
 *  This class does the necessary plumbing to make these work like normal booking actions under the hood.
 */
export class PerLocationBookingSlice {

    /** The store slice from BookingDispatchV2 that we will create actions in */
    private slice: ReduxStoreSlice<BookingWorkFlowState>;

    constructor(slice: ReduxStoreSlice<BookingWorkFlowState>) {
        this.slice = slice;
    }

    // #region Entry Points

    /**
     * Register a per-location action and returns the dispatcher.
     */
    public Action<TPayload>(actionName: string, reducer: LocationReducer<TPayload>): LocationDispatcher<TPayload> {

        const bookingReducer = this.UpgradeLocationReducer(reducer);
        const bookingDispatcher = this.slice.Action(actionName, bookingReducer);

        const locationDispatcher: LocationDispatcher<TPayload> = (location: number, payload: TPayload) => {

            const bookingPayload: PayloadAndLocation<TPayload> = {
                Location: location,
                Payload: payload,
            };

            bookingDispatcher(bookingPayload);
        }

        return locationDispatcher;
    }

    /**
     * Register a per-location action which doesn't take any payload, and returns the dispatcher.
     * It uses a quick fudge to make a dummy action payload.
     */
    public EmptyAction(actionName: string, reducer: (state: BookingLocationInfo) => BookingLocationInfo): (location: number) => void {

        // this is pretending that the existing reducer has a string payload argument at the end
        const dummyReducer: LocationReducer<string> = reducer;

        // the resulting dispatch takes an unused string at the end
        const dummyDispatcher = this.Action(actionName, dummyReducer);

        // this version hides the string at the end
        const realDispatcher = (location: number) => dummyDispatcher(location, "dummy");

        return realDispatcher;
    }

    // #endregion

    /**
     * Upgrades a reduces that applies to a specific booking location into one that applies to the whole booking state.
     * The action payload changes from a {TPayload} into a {LocationPayload{TPayload}}.
     */
    private UpgradeLocationReducer<TPayload>(locationReducer: LocationReducer<TPayload>): BookingLocationReducer<TPayload> {

        const bookingReducer: BookingLocationReducer<TPayload> = (state: BookingWorkFlowState, payload: PayloadAndLocation<TPayload>) => {

            // try all locations in turn. Only one will be acted upon.
            let updatedLocations: BookingLocationInfo[] = [];

            state.Locations.map((location, index) => {
                updatedLocations.push(this.ApplyReducerToAnyLocation(locationReducer, payload, index, location));
            });

            const newState: BookingWorkFlowState = {
                ...state,
                Locations: updatedLocations,
            };

            return newState;
        }

        return bookingReducer;
    }

    /**
     * Apply a location-specific action to a booking location using the supplied reducer.
     * This method will be called for every booking location in the booking. But only one of them will match the location of the action.
     */
    private ApplyReducerToAnyLocation<TPayload>(locationReducer: LocationReducer<TPayload>, payload: PayloadAndLocation<TPayload>, inputLocation: number, inputData: BookingLocationInfo): BookingLocationInfo {

        // mismatching location
        if (payload.Location !== inputLocation) return inputData;

        // valid location
        return locationReducer(inputData, payload.Payload);
    }
}

/** 
 *  A reducer which applies an action to a given BookingLocationInfo.
 *  All per-location reducers will be defined with this function signature.
 *  Notice that the state is typed as a BookingLocationInfo instead of a BookingWorkFlowState.
 */
type LocationReducer<TPayload> = (state: BookingLocationInfo, payload: TPayload) => BookingLocationInfo;

/** 
 *  An action dispatcher for a per-location action with a payload.
 *  This is a function you will call from e.g. Dispatch.Booking.Address(...).
 *  The first argument is the booking location to which the action applies.
 *  The second argument is the payload for that location.
 */
type LocationDispatcher<TPayload> = (location: number, payload: TPayload) => void;

/** 
 *  An action payload of type {TPayload} augmented with the booking location.
 *  This is used internally when we upgrade a location-specific action into a booking-level action.
 *  The new payload includes the old payload but also the location index.
 */
interface PayloadAndLocation<TPayload> {

    /** The booking location to which this action applies. */
    Location: number;

    /** The action payload which affects a BookingLocationInfo. */
    Payload: TPayload;
}

/** A booking reducer which reduces a per-location action. */
type BookingLocationReducer<TPayload> = (state: BookingWorkFlowState, payload: PayloadAndLocation<TPayload>) => BookingWorkFlowState;