import React from "react";
import { BookingPhoneInput } from "../../PhoneNumber/BookingPhoneInput";
import BookingAddressComponent from "./BookingAddressComponent";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { BookingLocationContactName } from "./BookingLocationContactName";
import { BookingUseCompanyDetails } from "./BookingUseCompanyDetails";
import NotesToDriver from "./NotesToDriver";
import { PerLocationProps } from "./PerLocationProps";

/**
 * A group of components covering a booking location in full detail.
 */
export const BookingLocationGroup: React.FC<PerLocationProps> = (props) => {

    const isPickup = props.Location === 0;
    const stepLabel = isPickup ? FormStepLabel.PickupDetails : FormStepLabel.DropOffDetails;
    const addressIndex = isPickup ? 0 : 1; // this UI doesn't support multi-stop.

    return (
        <BookingFormGroup WithStepLabel={stepLabel} AddShading={isPickup}>
            <BookingAddressComponent IsPickup={isPickup} AddressIndex={addressIndex} />
            <NotesToDriver Location={props.Location} />
            <BookingUseCompanyDetails Location={props.Location} />
            <BookingLocationContactName Location={props.Location} />
            <BookingPhoneInput Location={props.Location} />
        </BookingFormGroup>
    );
}