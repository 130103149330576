import appstore from "../../appStore";
import { Config } from "../../Config/Config";
import { TryAgainMessageKind } from "../Utils/ErrorMessages";
import { TncInfoResponse } from "../../Services/TncEntities"; 
import { SimpleUserProfile } from '../User/ProfileEntitiesV2';
import Auth from "../Authentication/AuthImpl";
import { ShowDialogRetryErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { RetryErrorMessage } from "../Dialog/DialogEntities";
import { LogoutKind } from "../Authentication/AuthEntities";
import { TaskKind } from "../LoginValidation/LoginValidationEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { MyStorage } from "../../Storage";

/**
 * 2 steps:
 *     1> Call API to retrieve tnc information from one-source management;
 *     2> Parse tnc content from url, if necessary & step 1 succeed.
 */
 export async function RetrieveTnc(IsRetryNeeded: boolean, IsContentParseNeeded: boolean, IsErrorMessageNeeded: boolean) {
    var hasLoadedOk = true, counter = IsRetryNeeded ? Config.RetryTimes: 1;

    /**
     * Retrieve tnc information, if no object in store;
     * If we have an existing one in store, re-use.
     */
    if(appstore.getState().tncPrivacy.TncInfo == undefined) {
        hasLoadedOk = false;

        do {
            counter--;
            const tncResult = await Api.User.GetTncContent();
            if(tncResult.isSuccess) {
                hasLoadedOk = true;
                Dispatch.Tnc.OnTncInfoChange(tncResult.value);
            }

        } while (counter > 0 && hasLoadedOk == false)
    }

    /**
     * Show error message when:
     *     1> Previous step failed;
     *     2> Need to show error message;
     */
    if(!hasLoadedOk && IsErrorMessageNeeded) {
        FailThenPopup(TryAgainMessageKind.TncTryAgain);
        return;
    }

    /**
     * Parse tnc content from url if needed
     */
    if(IsContentParseNeeded) {

        const tncContent = await Api.User.ReadTncContentFromFile();
        
        if(tncContent.isSuccess) { 
            Dispatch.Tnc.OnTncContentChange(tncContent.value);
        }
        else {
            if(IsErrorMessageNeeded) {
                FailThenPopup(TryAgainMessageKind.TncTryAgain);
            }
        }
    }
}

function FailThenPopup(popUpKind: RetryErrorMessage) { 
    ShowDialogRetryErrorMessage(popUpKind); 
}

/**
 * Returns true when
 * 1> This user never agreed any tnc consent;
 * 2> There is new release of 13cabs tnc.
 */
export function IsNewTncConsentNeeded(userProfile: SimpleUserProfile, tncInfo: TncInfoResponse): boolean {
    if (FeatureFlags.NoTermsAndConditions) return false;
    if (!userProfile.TncConsentId) return true;
    if (userProfile.TncConsentId < tncInfo.tncId) {
        return true;
    }
    else {
        return false;
    }
}

/**
 * Helper to add tnc consent to BCC
 * Try 3 times if failed
 * Log out wtih popup if still fail after 3 re-tries.
 */
export async function AddUserTncAgreementHandler(tncId: number) {
    Dispatch.UILogicControl.BookingFormApiStart();
    const result = await Api.User.AddUserTncAgreement(tncId);
    Dispatch.UILogicControl.BookingFormApiEnd();

    if(!result.isSuccess) {
        new Auth().logout(LogoutKind.Website);
        ShowDialogRetryErrorMessage(TryAgainMessageKind.Auth0LoginTryAgain);
    }
    else {
        /**
         * If userAgreementUpdateResult.isSuccess === true, which means Terms and Conditions agreement has been write to BCC database successfully
         * We do not need to get profile again, as no other content of the client user profile has changed, except for TncConsentId
         * Below codes to refelct this TncConsentId to localStorage & Redux
         */
        const userProfile = appstore.getState().authentication.UserProfile!;
        userProfile.TncConsentId = tncId;
        Dispatch.Auth.UserProfile(userProfile);
        MyStorage.UserProfileV2.StoreData(userProfile);
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
    }
}

/**
 * Call API to retrieve 13cabs privacy content
 */
export async function RetrievePrivacyWebPageContent(IsRetryNeeded: boolean, IsErrorMessageNeeded: boolean) {
    var counter = IsRetryNeeded ? Config.RetryTimes: 1;

    /**
     * If we have an existing one in store, re-use.
     */
    if(appstore.getState().tncPrivacy.PrivacyInfo != undefined) return;

    /**
     * Retrieve privacy content
     */
    do {
        counter--;
        const privacyResult = await Api.User.GetPrivacyPageContent();
        if(privacyResult.isSuccess) {
            Dispatch.Tnc.OnPrivacyInfoChange(privacyResult.value);
            return;
        }

    } while (counter > 0)

    /**
     * Show error message when:
     *     1> Previous step failed;
     *     2> Need to show error message;
     */
    if(IsErrorMessageNeeded) {
        FailThenPopup(TryAgainMessageKind.PrivacyTryAgain);
        return;
    }
}

/** 
 * Retrieve terms & conditions information if there is no in Redux store
 */
export async function EnsureTncDataLoaded() {

    if (FeatureFlags.NoTermsAndConditions) return;

    if (!appstore.getState().tncPrivacy.TncInfo) await RetrieveTnc(true, false, false); 
}
