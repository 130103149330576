import { SdkLoadingState } from "../PayPal/PayPalState";

/** 
 * Redux store ApplePay state slice.
 * Manages the background loading of the SDK.
 */
export interface ApplePayState {

    /** The progress or result of loading the ApplePay SDK. */
    LoadingStatus: SdkLoadingState;
}

export const ApplePayInitialState: ApplePayState = {

    LoadingStatus: SdkLoadingState.NotRequested,
}