import React, { useEffect } from "react";
import { Dispatch } from "../Dispatch";
import { FormControl, IconButton, InputAdornment, InputLabel, TextField } from "@mui/material";
import Close from "@mui/icons-material/Close";
import "./EmailForPaymentReceipt.scss";
import { IsValidEmailFormat } from "../Authentication/Login/CredentialHelper";
import { ApplicationState } from "../../appState";
import { connect } from "react-redux";
import ErrorIcon from '@mui/icons-material/Error';

interface PropsFromStore {
    IsStrictValidationModeOn: boolean;
    EmailForReceipt: string | null;
}

/** 
 * Renders the email for receipt input field in the booking form.
 */
export const EmailForPaymentReceiptCore: React.FC<PropsFromStore> = (props) => {

    const [emailAddress, setEmailAddress] = React.useState("");
    const [isError, setIsError] = React.useState(false);

    const emailInputRef = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.IsStrictValidationModeOn && !props.EmailForReceipt) {
            setIsError(true);
        }
    }, [props.IsStrictValidationModeOn, props.EmailForReceipt]);

    /** Clear the data from the store on click of clear button (X). */
    function ClearEmail() {
        setEmailAddress("");
        emailInputRef.current!.value = "";
        Dispatch.GuestPayment.ClearEmail();
    }

    /** Update the store and the state . */
    function OnTextInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setEmailAddress(value);

        if (IsValidEmailFormat(value)) {
            Dispatch.GuestPayment.EmailForReceipt(value);
            setIsError(false);
        }
        else {
            setIsError(true);
        }
        
    }

    return (
        <div className="email-for-receipt">
            <FormControl variant="standard">
                <InputLabel shrink htmlFor="email-input">
                    Email address required for receipt
                </InputLabel>
                <TextField
                    id="email-input"
                    inputRef={emailInputRef}
                    fullWidth={true}
                    variant="outlined"
                    onChange={OnTextInputChanged}
                    placeholder="Enter email address"
                    className="simple-textfield"
                    InputProps={{
                        endAdornment: <InputAdornment position="end" className="clear-icon">
                            {emailAddress !== "" && <IconButton onClick={ClearEmail} size="large">
                                <Close fontSize="small" className="clear-icon" />
                            </IconButton>}
                            {isError && <ErrorIcon fontSize="small" color="error" />}
                        </InputAdornment>
                    }}
                />
                {isError && <div className="booking-form-error-message">Enter a valid email address</div>}
            </FormControl>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        IsStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        EmailForReceipt: state.GuestPayment.EmailForReceipt,
    }
}

export const EmailForPaymentReceipt = connect(mapStateToProps)(EmailForPaymentReceiptCore);