import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { ConvertBookingInfoV2ToBookingInfo, ConvertBookingInfoV2ToBookingInfoBulk } from "../MyBookings/ConvertAPIContractsToInternalModels";
import { Config } from "../../Config/Config";
import { BookingAuthority } from "../../Services/BookingEntities";
import { ConsiderLoadDriverDetailsOfRefreshedBookings } from "../MyBookings/Logic/RefreshDriverDetails";
import { BookingInfo } from "../MyBookings/MyBookingEntities";

/**
 * Load details of a single booking from the API. This is one time call just after booking creation.
 */
export async function LoadBookingDetails(bookingKey: BookingAuthority) {

    const bookingDetails = await Api.BookingV2.GetBookingDetails(bookingKey);

    if (bookingDetails.isSuccess) {

        const convertedBooking = ConvertBookingInfoV2ToBookingInfo(bookingDetails.value)
        Dispatch.MyBookings.Add(convertedBooking);

        // Set the updated booking with ReadAccessCode as the selected booking to share in case the user clicked share from the Confirmation popup.
        Dispatch.MyBookings.SetBookingToShare(convertedBooking);
    }
}

/**
 * Load a list of user bookings based on the specified statuses and Limit (no. of bookings to be loaded).
 */
export async function LoadMyBookingsV2(StatusIDs: number[]) {
    const limit = Config.BookingListCountLimit;
    var request = {
        StatusIDs,
        Limit: limit,
        Skip: 0
    };

    var serviceResult = await Api.BookingV2.GetMyBookings(request);

    if (!serviceResult.isSuccess) {
        return;
    }

    const bookingInfoList = ConvertBookingInfoV2ToBookingInfoBulk(serviceResult.value)
    Dispatch.MyBookings.DataRefresh(bookingInfoList);

    // Consider to load driver details
    ConsiderLoadDriverDetailsOfRefreshedBookings(bookingInfoList);
}

/** Get user's bookings to display in history (and Quick Book tab). */
export async function GetTripHistoryV2(StatusIDs: number[], limit = Config.NoOfBookingsInHistory): Promise<BookingInfo[] | undefined> {
    var request = {
        StatusIDs: StatusIDs,
        Limit: limit,
        Skip: 0
    };

    var serviceResult = await Api.BookingV2.GetMyBookings(request);

    if (!serviceResult.isSuccess) {
        return;
    }

    return ConvertBookingInfoV2ToBookingInfoBulk(serviceResult.value);
}