import React from "react";
import {connect} from "react-redux";
import "./BookingPage.scss";
import SiteHeader from "../../widgets/Header/SiteHeader";
import BookingTabPanel from "./Widget/BookingTabPanel";
import NavBar from "../../widgets/NavBar/NavBar";
import { ApplicationState } from "../../appState";
import { PropsFromStore } from "../../widgets/NavBar/DefaultNavBarProps";
import { DataLoadStatusPanel } from "../../widgets/loading/DataLoadStatusPanel";
import { ContentURL } from "../Utils/ContentURL";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import HeaderProfilePanel from '../../widgets/Header/HeaderProfilePanel';
import { MyBookingsList } from "../MyBookings/UI/MyBookingsList";
import { WellKnownTabName, TabItem, MapView, ScheduleView, TripHistoryView, FeaturesView } from "../../widgets/NavBar/TabEntities";
import { RefreshTimer } from "../MyBookings/RefreshTimer";
import PickedupBookingsList from "../MyBookings/UI/PickedupBookingsList";
import TripHistory from "../TripHistory/TripHistory";
import { Dispatch } from "../Dispatch";
import BookingFormLandingPage from "../../widgets/LandingPage/BookingFormLandingPage";
import { AddressStatusType } from "../UILogicControl/UILogicControlEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import BookingTemplateForm from '../BookingTemplate/BookingTemplateForm';
import { BookingWidgetModeKind } from "../BookingTemplate/BookingTemplateEntities";
import { Dialog2Demo } from "../Dialog2/Demos/Dialog2Demo";
import { GetValues } from "../../Config/MyAppConfig";
import { PopulateConditionListWithDefaultVehicles } from "../Condition/PopulateConditions";
import { BookingAuthority } from "../../Services/BookingEntities";
import { LogEvent } from "../../utils/LogEvent";
import services from "../../utils/service-vehicles/ServiceMetadata";
import { SelectVehicleByService } from "../Condition/SelectVehicleByService";
import ConditionInfoView from "../Condition/UI/ConditionInfoView";
import { BookingWidgetGoogleMap } from "../../widgets/google-maps/MapComponents/BookingWidgetGoogleMap";
import { GoogleMapScriptLoader } from "../../widgets/google-maps/GoogleMapScriptLoader";

interface BookingPageProps {
    IsBookingForNow: boolean;

    /** Whether to enable the async data loading diagnostic UI */
    ShowDiagnosticUI: boolean;

    /** Login status */
    LoginStatus: LoginStatusKind;   
    
    PickupStatus: AddressStatusType;
    DropoffStatus: AddressStatusType;
    IsLandingPageFinished: boolean;
    BookingWidgetMode: BookingWidgetModeKind;
    SelectedItem: TabItem;

    /** Tracking link data which caused the page to be opened. Null if not opened via a tracking link. */
    NewTrackingLink: BookingAuthority | null;
    ShowServiceInfo: boolean;
}

/** Handles the /booking and / URLs. */
class BookingPage extends React.Component<BookingPageProps> {

    /* Store the 'Map View' as the default tab on component mount in order to get the relevant UI on page load. */
    async componentDidMount() {

        // If the page is opened via a tracking link, default to the Schedule View.
        if (this.props.NewTrackingLink != null) {
            Dispatch.Tab.SelectItem(ScheduleView);
        } else {
            Dispatch.Tab.SelectItem(MapView);
        }
        
        LogEvent.OnBookingPageLoad();

        // Default to Silver service condition if the config value is set
        if (GetValues().DefaultToSilverservice) {
            // Populate the default conditions list first in order to select SS from the list.
            PopulateConditionListWithDefaultVehicles();
            SelectVehicleByService(services.silver);
            return;
        }
    }

    /**
     * This function is to decide, under MapView, whether to display landing page or not (map).
     * Show landing page while below requirements met:
     *     1) Under guest booking;
     *     2) Either pickup or dropoff address, either on focus or has value; (store.booking.CreateBookingPayload.pickup or dropoff can be null);
     *     3) IsLandingPageFinished flag is false;
     *     4) Feature flag enabled.
     */
    IsLandingPageShown() {
        return FeatureFlags.LandingPage &&
               this.props.LoginStatus === LoginStatusKind.LoggedOut &&
               this.props.PickupStatus.IsFocus === false && this.props.PickupStatus.DoesInputHaveValue === false &&
               this.props.DropoffStatus.IsFocus === false && this.props.DropoffStatus.DoesInputHaveValue === false &&
               this.props.IsLandingPageFinished === false;
    }

    /**
     * Renders the components on the booking widget depending upon the below conditions
     */
    RenderBookingPage() {

        // Render <ConditionInfoView />, inorder to display more info about the selected service
        if (this.props.ShowServiceInfo) return <ConditionInfoView />;

        // Render <BookingTemplate />, while creating/modifying the template            
        if (this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking) return <BookingTemplateForm />

        return <BookingTabPanel />;
    }
    
    render() {        
        const BookingFormNavItems: TabItem[] = [
            MapView,
            ScheduleView,
            TripHistoryView,
        ];

        if (FeatureFlags.WhatsNew) BookingFormNavItems.push(FeaturesView);

        if (FeatureFlags.Dialog2Demo) {
            BookingFormNavItems.push({
                Title: 'Dialog (V2) Demo',
                Name: WellKnownTabName.Dialog2Demo,
                Image: ContentURL.images.AussieFlag,
            });
        }

        return (
            <div className="booking-page-root">
                <SiteHeader/>
                <NavBar NavItems={BookingFormNavItems} />
                {this.props.ShowDiagnosticUI && <DataLoadStatusPanel />}

                <div className="container">
                    {this.props.SelectedItem.Name === WellKnownTabName.MapView ? <BookingWidgetGoogleMap /> : ""}
                    {this.props.SelectedItem.Name === WellKnownTabName.MapView && this.IsLandingPageShown() ? <BookingFormLandingPage /> : ""}
                    {(this.props.SelectedItem.Name === WellKnownTabName.Dialog2Demo) && <Dialog2Demo />}
                    {
                        this.props.SelectedItem.Name !== WellKnownTabName.TripHistory ?
                            <div className="booking-container">
                                {this.RenderBookingPage()}
                            </div> :
                            <TripHistory />
                    }
                    {/* Adds a google maps API script <GoogleMapScriptLoader>, as required for ETA loading */}
                    {this.props.SelectedItem.Name === WellKnownTabName.ScheduleView ? <><GoogleMapScriptLoader><RefreshTimer /></GoogleMapScriptLoader> <MyBookingsList /><PickedupBookingsList /></> : ""}
                </div>
                
                <HeaderProfilePanel/>
            </div>            
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore & BookingPageProps {
    return {
        SelectedItem: state.tabs.SelectedTab,
        IsBookingForNow: state.booking.BookingTimeV2.IsImmediate,
        ShowDiagnosticUI: state.booking.ShowDiagnosticUI,
        LoginStatus: state.authentication.LoginStatus,
        PickupStatus: state.uiLogicControl.AddressStatus.Pickup,
        DropoffStatus: state.uiLogicControl.AddressStatus.Dropoff,
        IsLandingPageFinished: state.uiLogicControl.BookingForm.IsLandingPageFinished,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        NewTrackingLink: state.trackingLinks.NewFromUrl?.BookingKey ?? null,
        ShowServiceInfo: state.condition.ShowServiceInfo
    };
}

export default connect(mapStateToProps)(BookingPage);
