import React from 'react';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import { Typography } from '@mui/material';

/** What's new card for wallet based payment methods. */
export const PaymentMethods = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.PaymentMethods}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>12.03.2024</span>
                </div>
                <Typography variant="h6" color="primary">NEW PAYMENT METHODS</Typography>
                <Typography variant="body2" gutterBottom>You can now book and pay using either Apple Pay, Google Pay or PayPal as your payment method.</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.PaymentMethods)} />
                </div>
                <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}