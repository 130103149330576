import { BookingTemplate} from "./BookingTemplateEntities";

export interface BookingTemplateState {
	/** List of booking templates belong to the user/company. */
	MyTemplates: BookingTemplate[];
    FilteredTemplates: BookingTemplate[];
    FilterText: string;
}

/** Initial state of Booking Templates. */
export const BookingTemplateInitialState: BookingTemplateState = {
	MyTemplates: [],
    FilteredTemplates: [],
    FilterText: ""
}