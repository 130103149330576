import { useEffect } from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { PaymentOptionKind } from "../Payment/PaymentEntities";
import { Dispatch } from "../Dispatch";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { GetOrMakeBraintreeClient } from "../Payment/GetOrMakeBraintreeClient";
import braintree from "braintree-web";
import { ThreeDSecure } from "./ThreeDSecure";
import { ThreeDSecureFunnel } from "./ThreeDSecureFunnel";

/**
 * Manages the deferred loading of the 3DS SDK. 
 * Instead of loading at startup, we wait for a signal from the UI (e.g. a payment card is selected).
 * This avoids slowing down the startup experience.
 * This component doesn't render anything; it just receives and sends events.
 */
export const ThreeDSecureLoadingWatcher: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.ThreeDSecureVerification);
    const loadingState = useAppState(i => i.ThreeDSecure.LoadingStatus);
    const paymentCardSelected = useAppState(i => i.booking.PaymentOption?.Kind === PaymentOptionKind.CardOrWallet);

    // pre-existing states that should trigger a load
    useEffect(() => {

        // 3DS is not supported
        if (!isFeatureEnabled) return;

        if (paymentCardSelected) {
            Dispatch.ThreeDSecure.ReadyToLoad();
        }

    }, [isFeatureEnabled, paymentCardSelected]);

    // once only load
    useEffect(() => {

        // 3DS is not supported
        if (!isFeatureEnabled) return;

        // UI flow has advanced far enough
        if (loadingState === SdkLoadingState.RequestedNotStarted) {
            StartLoading3dsSdk();
        }
    }, [isFeatureEnabled, loadingState]);

    return null;
}

/**
 * Intended to run only once.
 * This method just manages the redux state changes.
 */
async function StartLoading3dsSdk() {

    Dispatch.ThreeDSecure.LoadStarting();

    const isSuccess = await TryLoadUpTo3dsSdk();

    if (isSuccess) {
        Dispatch.ThreeDSecure.LoadSucceeded();
    }
    else {
        Dispatch.ThreeDSecure.LoadFailed();
    }
}

/**
 * Loads the 3DS SDK. There are two parts, each of which could fail:
 * 1) Braintree SDK
 * 2) 3DS SDK.
 */
async function TryLoadUpTo3dsSdk(): Promise<boolean> {

    // 1) BrainTree SDK
    const braintreeClient = await GetOrMakeBraintreeClient();

    if (!braintreeClient) {
        return false;
    }

    // 2) 3DS SDK
    let threeDsClient: braintree.ThreeDSecure;

    try {
        threeDsClient = await braintree.threeDSecure.create({
            version: 2,
            client: braintreeClient
        });
    }
    catch (error) {
        ThreeDSecureFunnel.Exception("3DS Client", error, false);
        return false;
    }

    // OK!
    ThreeDSecure.SdkIsReady(threeDsClient);
    return true;
}