import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrl, Post } from './ServiceManager';
import { FareEstimateRequestV4, FareEstimateResponseV4 } from './FareEntities';

/** Base URL for the FareController in the Booking Management Service
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Fare/`;
}

/** HTTP API Calls to the FareController in the Booking Management Service. */
class FareServiceClass {

    /** Gets the fixed fare estimate for different vehicle types using the GetFareV4 API in the Booking MGMT. Supports multiple stops. */
    public GetFareV4(request: FareEstimateRequestV4): Promise<ServiceResult<FareEstimateResponseV4>> {
        const url = `${GetBaseUrl()}GetFareV4`;
        return Post<FareEstimateResponseV4>(url, request);
    }
}

/** Singleton FareService instance */
export const FareService = new FareServiceClass();