import { GetValues } from "../../Config/MyAppConfig";
import { Api } from "../../Services/Api";
import { RoughUserLocation } from "../../Services/LocationEntities";
import { ServiceResult } from "../../Services/ServiceEntities";
import { LocationData, GeoPoint, LocationSourceKind, UnknownLocation } from "../Location/Entities";
import { IsSupportedStateCode } from "../Location/Metadata";

// the result of a successful lookup. This will not be exported because it doesn't reliably exist; use the promises instead.
let knownResult: LocationData | undefined;

/** A promise that gives the result of IP geolocation. This object is effectively a singleton, so it will (intentially) only run once. This is suitable for a "reasonable effort" basis. */
export const GetIPLocationFast = Api.Location.GetMyRoughLocation().then(result => shapeToExtendedResult(result));

/** A function which generates a new promise that will perform an IP Geolocation. This version will retry if the initial call failed. I.e. it will cache success but not failure. Use this for a higher degree of reliability than GetIPLocationFast. */
export function GetIPLocationReliable(): Promise<LocationData | undefined> {
    if (knownResult) {
        console.log("IPLookup: retry found an existing value");
        return Promise.resolve(knownResult);
    }
    else {
        console.log("IPLookup: retry is starting a new lookup");
        return Api.Location.GetMyRoughLocation().then(result => shapeToExtendedResult(result));
    }
}

/** The output when IP Geolocation fails */
const ipUnknownLocation: UnknownLocation = {
    isKnown: false,
    source: LocationSourceKind.IPGeolocation,
};

/** This function converts the raw ip lookup (BlipLocationPayload) into the richer LocationData object. */
function shapeToExtendedResult(result: ServiceResult<RoughUserLocation>): LocationData {

    let output: LocationData;

    // API failure
    if (!result.isSuccess) {
        return ipUnknownLocation;
    }

    const data = result.value;

    // no lat/long: too hard also
    if (data.IndicativeGeoPoint == null) {
        return ipUnknownLocation;
    }

    const geoPoint: GeoPoint = {
        latitude: data.IndicativeGeoPoint.Latitude,
        longitude: data.IndicativeGeoPoint.Longitude,
    };

    const isKnown = true;

    const isCountryServiced = GetValues().AllowedCountryCodes.some(i => i === data.CountryIsoCode);
    const isStateServiced = IsSupportedStateCode(data.RegionIsoCode);
    const isValid = isCountryServiced && isStateServiced;

    output = {
        source: LocationSourceKind.IPGeolocation,
        isKnown,
        value: {
            isValid,
            isStateServiced,
            geoPoint,
            displayName: data.CityName,
            stateCode: data.RegionIsoCode,
        },
    };

    // save for later
    knownResult = output;
    return output;
};