import { LocationZoneResult } from "../../Services/LocationsEntitiesV2";

/** Redux store slice for curated locations. Manages the loading status as well as result data. */
export interface CuratedLocationsState {

    /** Result from the curated locations query for any given location. We store the entire result instead of list of addresses since other fields in the response are also requiredin order to display the UI properly. */
    CuratedLocationsResult: LocationZoneResult | null;

    /** Which location in the list of locations of the booking, curated locations are currently loaded for. This is used to update the booking store slice correctly after the user selects a location from the list. */
    CurrentlyActiveLocationIndex: number;
}

export const CuratedLocationsInitialState: CuratedLocationsState = {
    CuratedLocationsResult: null,
    CurrentlyActiveLocationIndex: 0,
}