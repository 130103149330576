import React from "react";
import "./PayDriverDirectLink.scss";
import { Dispatch } from "../Dispatch";
import { DialogKind } from "../Dialog/DialogEntities";

/** Renders the Pay driver direct link which points to signup flow for guest users. */
export const PayDriverDirectLink: React.FC = () => {    

    return (
        <div className="booking-fields-panel">
            <div className="pay-driver-direct-link" onClick={OnPayDriverDirectLinkClicked}>Do you want to pay the driver directly?</div>
        </div>        
    );
}

function OnPayDriverDirectLinkClicked() {
    Dispatch.Dialog.ShowDialog(DialogKind.PayDriverDirectInfo);
}