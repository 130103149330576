import React from "react";
import "../Cards/MyWallet.scss";
import "../User/UserProfile.scss"
import { SimpleUserProfile } from "./ProfileEntitiesV2";
import { ApplicationState } from "../../appState";
import { connect } from "react-redux";
import PersonalData from "./PersonalData";
import { DialogKind } from "../../modules/Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { List, ListItem, ListItemText, Divider} from '@mui/material';
import { ContentURL, getContentUrl } from "../../modules/Utils/ContentURL";
import { CommonDialogHeaderCore } from "../Dialog/CommonDialogHeader";
import { CommonDialogDescriptionCore } from "../Dialog/CommonDialogDescription";

interface PropsFromStore {
    IsMobileDevice: boolean;
    userProfile: SimpleUserProfile;
}

/**
 * Display the user's personal details
 */
class UserPersonalDetails extends React.Component<PropsFromStore>{
    
    constructor(props: PropsFromStore) {
        
        super(props);        
        this.openResetPassword = this.openResetPassword.bind(this);
    }

    openResetPassword() {
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.Dialog.ShowDialog(DialogKind.ResetPassword);
    }   

    render() {
        
        const desktopPanelCssClass = !this.props.IsMobileDevice ? "my-wallet-list-panel" : "";

        return (
            <div className={desktopPanelCssClass}>
                <CommonDialogHeaderCore TopmostDialog={DialogKind.UserPersonalDetails} />
                <CommonDialogDescriptionCore TopmostDialog={DialogKind.UserPersonalDetails} />
                { this.props.IsMobileDevice ? this.mobileView() : this.desktopView() }
                { 
                    FeatureFlags.ResetPassword && !this.props.IsMobileDevice && <button className="btnChangePwd" onClick={this.openResetPassword} >Change my password</button>
                }
            </div>
        )
    }

    mobileView = () => {
        
        const userProfile = this.props.userProfile;
        
        return (
            <div className="personal-data-panel-mobile-view">   
                <List>
                    <ListItem>
                        <img src={getContentUrl(ContentURL.images.Users.Portait)} width="23"></img>
                        <ListItemText primary={userProfile.ContactName}  />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <img src={getContentUrl(ContentURL.images.UserProfile.PhoneWhite)} width="23"></img>
                        <ListItemText primary={userProfile.ContactPhone} />
                    </ListItem>
                    <Divider/>
                    <ListItem>
                        <img src={getContentUrl(ContentURL.images.UserProfile.Contact)} width="23"></img>
                        <ListItemText primary={userProfile.Email} />
                    </ListItem>
                    <Divider/>
                </List>
            </div>
        )
    }

    desktopView = () => {
        
        const userProfile = this.props.userProfile;
        
        return (
            <div className="personal-data-panel">
                <PersonalData personalDataLabel="Profile contact name" personalDataValue={userProfile.ContactName} />
                <PersonalData personalDataLabel="Email" personalDataValue={userProfile.Email} />
                <PersonalData personalDataLabel="Contact number" personalDataValue={userProfile.ContactPhone} /> 
            </div>
        )
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        userProfile: state.authentication.UserProfile!,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }
}

export default connect(mapStateToProps)(UserPersonalDetails);
