import React, { FC } from "react";
import { Dialog2State } from "../Redux/Dialog2State";
import { DialogKind } from "../../Dialog/DialogEntities";
import { VerificationDialog } from "../Verification/VerificationDialog";
import { RemoveTemplateDialog } from "../BookingTemplate/RemoveTemplateDialog";
import { PaymentFailureDialog } from "../PaymentFailure/PaymentFailureDialog";

/**
 * Renders the actual dialog components.
 */
export const DialogHost: FC<Dialog2State> = (props) => {

    const dialog = props.ActiveDialog;

    if (dialog === DialogKind.Verification) {
        return <VerificationDialog {...props.Verification!} />
    } else if (dialog === DialogKind.RemoveBookingTemplate) {
        return <RemoveTemplateDialog {...props.RemoveTemplate!} />;
    } else if (dialog === DialogKind.PaymentFailure) {
        return <PaymentFailureDialog {...props.PaymentFailure!} />
    }

    return null;
}