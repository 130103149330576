
/** TODO: review this. Don't want to expose the list of brands to clients. OTOH, it may be necessary for them to configure properly.
 * ^ UPDATE: it seems safe because the enum only exists in TS land.
 */
export enum Brand {

    OneThreeCabs = "13cabs",

    SilverService = "silverservice",

    CabCharge = "cabcharge",

    /** This is not strictly a brand, only for Book 360 (general brand concept) to integrate with Global Booker via BookingControllerV2. */
    Book360 = "book360",

    Tailwind = "tailwind",
}

/** BrandId internal to the booking MGMT. */
export enum MgmtBrandId {
    OneThreeCabs = 1,
    SilverService = 2,
    Cabcharge = 3,
    Book360 = 5,
    Tailwind = 6,
}