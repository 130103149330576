
import { MainMenu, HeaderMenuItem, MenuItemDeviceKind } from "./HeaderEntities";

import { UnderMobileMode } from "../../modules/UILogicControl/UILogicControlHelper";

/**
 * Filter menu item list per device
 */
export function GetMenuItems(): HeaderMenuItem[] {
    if (UnderMobileMode()) {
        return MainMenu.filter((item) => item.VisibleToDevice != MenuItemDeviceKind.DesktopOnly);
    }
    else {
        return MainMenu.filter((item) => item.VisibleToDevice != MenuItemDeviceKind.MobileOnly);
    }
}