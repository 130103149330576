import EmailValidator from 'email-validator';
import React, { ChangeEvent } from 'react';
import './ContactForm.scss';
import "../Booking/BookingDateTime.scss"
import { ContactFormFieldsV2, ContactFormPropsV2, ContactFormStateV2 } from './ContactFormEntities';
import InputField from './Input/InputField';
import { SelectedAddress, InputTypes } from './Input/InputFieldProps';
import TextArea from './Textarea/TextArea';
import { SectionDefinition } from './ContactFormSections';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import DatePicker from "react-datepicker";
import moment, { Moment } from 'moment';
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import { Dispatch } from '../Dispatch';
import { DialogKind } from '../Dialog/DialogEntities';
import { DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { ShowDialogSimpleErrorMessage } from '../../widgets/general-error-message/ErrorMessagingHelper';
import { Radio, RadioGroup, FormControlLabel, TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { InputPair } from './Input/InputPair';
import { TryGetMobileOsName } from "../../utils/DetectDevice";
import { MobileOSKind } from "../../Config/Entities/DeviceKind";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateTime } from 'luxon';

/* There are some properties available in the TimePicker that are not declared in the type definition of the package. eg: getPopupContainer. Hence, casting it explicitly to any type to avoid typescript errors.*/
let CustomTimePicker = TimePicker as any;

/** All the input fields of the contact form. Some of the fields will be displayed based on the previously selected values of the Section. */
class ContactFormInputs extends React.Component<ContactFormPropsV2, ContactFormStateV2> {

    private calendar = React.createRef<DatePicker>();

    constructor(props: ContactFormPropsV2) {
        super(props);
        this.state = this.getDefaultState(props.defaultFields || {});
    }

    /** Get input fields specific for booked trips. */
    private get extendedDetails() {
        return <React.Fragment>
            <InputPair>
                <InputField label="Booking ref number" name="bookingRef" updateHandler={(e) => this.handleInputChange(e)} value={this.state.bookingRef} clearTextHandler={this.handleClearText.bind(this)} />
                <InputField label="Booking name (required)" name="bookingName" updateHandler={(e) => this.handleInputChange(e)} value={this.state.bookingName} clearTextHandler={this.handleClearText.bind(this)} />
            </InputPair>
                <InputField label="Contact number (required)" name="bookingContactNumber" updateHandler={(e) => this.handleInputChange(e)} value={this.state.bookingContactNumber} type={InputTypes.Phone} imgSrc={getContentUrl(ContentURL.images.AussieFlag)} isError={this.state.invalidBookingPhone} clearTextHandler={this.handleClearText.bind(this)} />
            <InputPair>
                <InputField label="Taxi number" name="cabNumber" updateHandler={(e) => this.handleInputChange(e)} value={this.state.cabNumber}  clearTextHandler={this.handleClearText.bind(this)} />
                <InputField label="Driver ID" name="driverId" updateHandler={(e) => this.handleInputChange(e)} value={this.state.driverId} clearTextHandler={this.handleClearText.bind(this)} />
            </InputPair>
        </React.Fragment>;
    }

    /** Get input fields specific for hailed trips. */
    private get hailDetails() {
        return <React.Fragment>
            <InputPair>
                <InputField label="Booking ref number" name="bookingRef" updateHandler={(e) => this.handleInputChange(e)} value={this.state.bookingRef} clearTextHandler={this.handleClearText.bind(this)} />
            </InputPair>
            <InputField label="Pick-up address (required)" name="pickupAddress" type={InputTypes.Address} onCleared={() => this.handleAddressCleared('pickup')} onAddressChosen={this.addressSelected('pickup')} value={this.state.pickup.display} />
            <InputField label="Destination address (required)" name="destinationAddress" type={InputTypes.Address} onCleared={() => this.handleAddressCleared('destination')} onAddressChosen={this.addressSelected('destination')} value={this.state.destination.display} />
            <InputPair>
                <InputField label="Taxi number" name="cabNumber" updateHandler={(e) => this.handleInputChange(e)} value={this.state.cabNumber} clearTextHandler={this.handleClearText.bind(this)} />
                <InputField label="Driver ID" name="driverId" updateHandler={(e) => this.handleInputChange(e)} value={this.state.driverId} clearTextHandler={this.handleClearText.bind(this)} />
            </InputPair>
        </React.Fragment>;
    }

    public isReadyToEnterText(props: ContactFormPropsV2): boolean {
        if (this.state.firstName === '' || this.state.lastName === '' || this.state.custContactNumber === '' || this.state.emailAddress === '') {
            return false;
        }
        if (!props.currentData) {
            return true;
        }
        if ((props.currentData.label != "General Enquiry") && this.state.selectedBookingHail === 'booking') {
            return this.bookingValidity();

        } else if ((props.currentData.label != "General Enquiry") && this.state.selectedBookingHail === 'hail') {
            return this.hailValidity();
        }
        return true;
    }

    /* Set the send button status according to the values of mandatory fields. */
    public isFormValid(props: ContactFormPropsV2): boolean {
        if (this.state.firstName === '' || this.state.lastName === '' || this.state.custContactNumber === '' || this.state.emailAddress === '' || this.state.itemDescription === '') {
            return false;
        }

        if (props.currentData.label === "Please Select" ) {
            return false
        }

        if ((props.currentData.label != "General Enquiry") && this.state.selectedBookingHail === 'booking') {
            return this.bookingValidity();

        } else if ((props.currentData.label != "General Enquiry") && this.state.selectedBookingHail === 'hail') {
            return this.hailValidity();
        }

        return true;
    }

    /** Validate booking details */
    private bookingValidity() {
        return ((this.state.pickupDateTime !== null) && this.state.bookingName !== '' && this.state.bookingContactNumber !== '');
    }

    /** Validate hail details */
    private hailValidity() {
        return ((this.state.pickupDateTime !== null) && this.state.pickup.display !== undefined && this.state.pickup.display !== '' && this.state.destination.display !== undefined && this.state.destination.display !== '');
    }

    /** Update state with empty value for text fields */
    public handleClearText(name: string) {
        this.setState({[name]: ""} as any, () => this.setState({ isFormValid: this.isFormValid(this.props) }));
    }

    /* Update state variables on input change */
    public handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const name = e.target.name;
        const value = e.target.value;

        const regex = /^[0-9\b]+$/; // Phone fields only can have numbers
        const amountRegex = /^[0-9\b\.]+$/; // Fare amount field can only have number and dot
        
        if (name === 'emailAddress') {
            this.setState({ invalidEmail: false });
        }
        if (name === 'custContactNumber') {
            this.setState({ invalidPhone: false });
            if (!regex.test(value) && value !== '') {
                return;
            }
        }
        if (name === 'bookingContactNumber') {
            this.setState({ invalidBookingPhone: false });
            if (!regex.test(value)) {
                return;
            }
        }
        if(name === 'fareAmount') {
            if (value !== '' && !amountRegex.test(value)) {
                return;
            }
        }

        this.setState({ [name]: value } as any, () => this.setState({ isFormValid: this.isFormValid(this.props) }));
    }

    /**
     * Update state with pickup/destination values
     */
    private handleAddressCleared(name: 'pickup' | 'destination') {

        this.setState({ [name]: "" } as any, () => {

            this.setState({ isFormValid: this.isFormValid(this.props) });

            if (name === "pickup") this.onPickupClearClicked(true);
            
            if (name === "destination") this.onDestinationClearClicked(true);            
        });
    }

    private onPickupClearClicked(shouldClearPickup: boolean) { 
        this.setState({ shouldClearPickup : shouldClearPickup });
    }

    private onDestinationClearClicked(shouldClearDestination: boolean) { 
        this.setState({ shouldClearDestination : shouldClearDestination });
    }

    private addressSelected: (prefix: 'pickup' | 'destination') => (address: SelectedAddress) => void = (prefix: 'pickup' | 'destination') =>
        (address: SelectedAddress) => {
            const result: any = {
                isFormValid: this.isFormValid(this.props),
                [prefix]: {
                    street: `${address.streetNumber} ${address.streetName}`,
                    suburb: address.suburb,
                    display: address.display
                },
            };
            this.setState(result);
        }

    /**
     * Update booking or hail state based on the selected value from the radio buttons
     */
    public updateBookingHail(val: any | null) {
        this.setState({ selectedBookingHail: (val.target.value) || 'booking' }, () => {
            this.setState({ isFormValid: this.isFormValid(this.props) })
        });
    }

    /** Validate data and send the request on click of Send enquiry button. */
    public async sendEnquiry() {
        if (!this.validateSection(this.props.currentSection)) {
            return;
        }
        // Check the email validity upon Send email button click.
        if (!EmailValidator.validate(this.state.emailAddress)) {
            this.setState({ invalidEmail: true });
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidEmail);
            return;
        }
        // Validate the phone number. Allow any number between 8 to 10 digits.
        if (!this.checkValidPhone(this.state.custContactNumber, 'invalidPhone')) {
            return;
        }
        if (document.getElementById('bookingContactNumber') && !this.checkValidPhone(this.state.bookingContactNumber, 'invalidBookingPhone')) {
            return;
        }
        const result = await this.props.onSubmit(this.state);

        if (result.isSuccess) {
            Dispatch.Dialog.ShowDialog(DialogKind.SentEnquiry);
            appInsights.trackEvent("Sent contact enquiry");
            this.setState({ ...this.getDefaultState() });
            window.scrollTo(0, 0);
        } else {
            Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.ContactFormSendFailed });
            Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            appInsights.trackEvent("Failed to send contact enquiry");
        }
    }

    /**
     * Check phone number validity
     */
    private checkValidPhone(phoneNumber: string, name: 'invalidPhone' | 'invalidBookingPhone') {
        const phoneRegex = /^[0-9]{8,10}$/;
        if (!phoneRegex.test(phoneNumber)) {
            this.setState({ [name]: true } as any);
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidPhone);
            return false;
        }
        return true;
    }

    /**
     * Validate Section
     */
    private validateSection(selectedSection: number) {
        if (selectedSection === 0) {
            return this.invalidateSection();
        }
        return true;
    }

    /** Show error message for invalid Section. i.e: if not selected a category */
    private invalidateSection() {
        this.setState({ invalidSection: true });
        //show error message
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidCategory);
        return false;
    }

    /* Show a popup if clicked on the submit button without entering all the required data. */
    public async showRequiredMessage() {
        //Show popup here
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.ContactFormRequiredFields);
    }

    /** Show required fields error message if the user tried to enter description without filling all the other required fields. */
    private displayRequiredMessage() {
        if (!this.isReadyToEnterText(this.props)) {
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.ContactFormRequiredFields);
        }
    }

    public render() {
        const step3Class = this.isReadyToEnterText(this.props) ? "contact-form-step" : "contact-form-step disabled-section";

        return (
            <React.Fragment>
                <div className="contact-form">
                    {this.getBookingHailData(this.props.currentData)}
                    <div className="contact-form-step">
                        <div className="step-data">
                            <div>
                                <span className="step-number">Step 2/3</span>
                                <h1 className="contact-title">Your contact details</h1>
                            </div>
                            <p className="contact-us-description">So we can contact you regarding your enquiry.</p>
                            <InputPair>
                                <InputField label="First name (required)" name="firstName" updateHandler={(e) => this.handleInputChange(e)} value={this.state.firstName} clearTextHandler={this.handleClearText.bind(this)} />
                                <InputField label="Last name (required)" name="lastName" updateHandler={(e) => this.handleInputChange(e)} value={this.state.lastName} clearTextHandler={this.handleClearText.bind(this)} />
                            </InputPair>
                            <InputField label="Contact number (required)" name="custContactNumber" updateHandler={(e) => this.handleInputChange(e)} value={this.state.custContactNumber} type={InputTypes.Phone} imgSrc={getContentUrl(ContentURL.images.AussieFlag)} isError={this.state.invalidPhone} clearTextHandler={this.handleClearText.bind(this)} />
                            <InputField label="Enter your email (required)" name="emailAddress" updateHandler={(e) => this.handleInputChange(e)} value={this.state.emailAddress} isError={this.state.invalidEmail} clearTextHandler={this.handleClearText.bind(this)} />
                        </div>
                        <div className="step-padding"></div>
                    </div>

                    <div className={step3Class} onClick={this.displayRequiredMessage.bind(this)}>
                        <div className="step-data">
                            <div>
                                <span className="step-number">Step 3/3</span>
                                <h1 className="contact-title">Additional detail</h1>
                            </div>
                            <p className="contact-us-description">Provide as much detail as possible to help us action your enquiry (required).</p>
                            <TextArea name="itemDescription" value={this.state.itemDescription} updateHandler={(e) => this.handleInputChange(e)} isActive={this.isFormValid(this.props)} onSubmit={() => this.sendEnquiry()} inactiveSubmit={() => this.showRequiredMessage()} isReadyToEnterText={this.isReadyToEnterText(this.props)} />
                        </div>
                        <div className="step-padding"></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getBookingHailData(contactEntry: SectionDefinition) {
        if (!contactEntry || contactEntry.label === "Please Select") {
            return null;
        }
        if (contactEntry.label != "General Enquiry") {
            return <div>
                {this.getBookingHailForm()}
            </div>;
        }
        return null;
    }

    datePickerInputOnFocus(isFocus: boolean): void {
        this.setState({ isDatepickerInputFocused: isFocus });
    }

    onPickerButtonHovered = (pickerType: string, isMouseOn: boolean) => {
        if (pickerType === "date") this.setState({ dateArrowOnHover: isMouseOn });
        else this.setState({ timeArrowOnHover: isMouseOn });
    }

    /** Update pickup date on change of the desktop UI standalone date picker */
    updateBookingDate(date: Date | null) {

        if (date == null) return;

        const newDate = DateTime.fromJSDate(date);
        const newDateTime = this.state.pickupDateTime.set({
            day: newDate.day,
            month: newDate.month,
            year: newDate.year,
        });

        this.setState({ pickupDateTime: newDateTime });
    }

    /** Update pickup time on change of the desktop UI standalone time picker */
    updatePickupTime(time: Moment | null) {

        if (time == null) return;

        const newDateTime = this.state.pickupDateTime.set({
            hour: time.day(),
            minute: time.minute(),
        });

        this.setState({ pickupDateTime: newDateTime });
    }

    /** Get the form fields for booking or hail if passenger is selected as the user type. */
    private getBookingHailForm() {

        return (
            <div className="contact-form-step">
                <div className="step-data">
                    <h1 className="contact-title">Your trip details</h1>
                    <p className="contact-us-description">This information will help us to better address your enquiry.</p>
                    <div className="radio-group booking-hail-radio" onChange={e => this.updateBookingHail(e)}>
                        <span className="booking-hail-info">My cab was </span>
                        <div className="radio-container">
                            <div className="contact-radio-group">
                                <RadioGroup defaultValue="booking">
                                    <FormControlLabel value="booking" control={<Radio color="secondary" />} label="Booked" /> 
                                    <FormControlLabel value="hail" control={<Radio color="secondary" />} label="Hailed on street" />
                                </RadioGroup>
                            </div>
                        </div>
                    </div>
                    {this.RenderDateTimePicker()}
                    {this.state.selectedBookingHail === 'booking' ? this.extendedDetails : null}
                    {this.state.selectedBookingHail === 'hail' ? this.hailDetails : null}
                </div>
                <div className="step-padding"></div>
            </div>
        );
    }

    /**
     * Date + Time change event handler for iOS.
     */
    updateDateTimeForIOS = (e: ChangeEvent<HTMLInputElement>) => {
        const date = DateTime.fromISO(e.target.value);
        this.setState({ pickupDateTime: date });
    }

    /**
     * Date + Time change event handler for Android.
     */
    updateBookingDateTimeForAndroid = (datetime: DateTime | null) => {

        if (datetime == null) return;
        this.setState({ pickupDateTime: datetime });
    }

    /**
     * Android-specific combined DateTime Picker UI element Modal window Toggle.
     */
     onAndroidDateTimePickerToggle = () => {
        this.setState({ isAndroidDatePickerOpen : !this.state.isAndroidDatePickerOpen})
    }

    /** Decides whether to show native date time elements or the custom elements. */
    DetermineOperatingSystem() {
        return TryGetMobileOsName();        
    }

    RenderDateTimePicker = () => {

        const selectedDateTime = this.state.pickupDateTime;
        
        // Load the datetime picker depending on the device's operating system
        const operatingSysName= this.DetermineOperatingSystem();
        
        switch (operatingSysName) {

            case MobileOSKind.IOS:
                return (
                    <TextField
                        className="native-datepicker"
                        fullWidth={true}
                        variant="outlined"
                        label="Pickup date"
                        type="datetime-local"
                        onChange={this.updateDateTimeForIOS}
                        value={selectedDateTime.toFormat("yyyy-MM-dd'T'HH:mm")}
                    />
                );
            
            case MobileOSKind.Android:
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                            open={this.state.isAndroidDatePickerOpen}
                            onOpen={this.onAndroidDateTimePickerToggle}
                            onClose={this.onAndroidDateTimePickerToggle}
                            disableFuture={true}
                            label="Pickup date"
                            inputFormat="yyyy-MM-dd HH:mm"
                            value={selectedDateTime}
                            onChange={this.updateBookingDateTimeForAndroid}
                            renderInput={(props: TextFieldProps) => <TextField
                                {...props}
                                sx={{width : 1}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton edge="end">
                                            <CalendarTodayIcon onClick={this.onAndroidDateTimePickerToggle} />
                                          </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        />
                    </LocalizationProvider>
                );
        
            default:
                var dateClass = "datepicker-visible-container date-selector date-time-picker-contact";
                const selectedDate = this.state.pickupDateTime.startOf("day").toJSDate();
                const timeClass = "time-selector input-field time-field date-time-picker-contact";
                const dateArrowBtnImg = this.state.dateArrowOnHover ? getContentUrl(ContentURL.images.arrows.arrowRightBlackSolid) : getContentUrl(ContentURL.images.arrows.arrowRightGreySolid);
                const timeArrowBtnImg = this.state.timeArrowOnHover ? getContentUrl(ContentURL.images.arrows.arrowRightBlackSolid) : getContentUrl(ContentURL.images.arrows.arrowRightGreySolid);
                const inputIcon = (<div className="dropdown-section"
                onMouseEnter={() => { this.onPickerButtonHovered("time", true) }}
                onMouseLeave={() => { this.onPickerButtonHovered("time", false) }}
                ><img src={timeArrowBtnImg} alt="" width="15" /></div>);

                return  <InputPair>
                <div className="future-dt contact-dt">
                    <div className="future-dt-fields">
                        <div className="date-time-group date-group date-time-picker-contact">
                            <label className="input-label">Pickup date (required)</label>
                            <label onClick={e => this.calendar.current!.isCalendarOpen() && e.preventDefault()}>
                                <DatePicker
                                    ref={this.calendar}
                                    selected={selectedDate}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={this.updateBookingDate.bind(this)}
                                        maxDate={new Date()}
                                    className="disable-default-datepicker-style"
                                    onFocus={() => this.datePickerInputOnFocus(true)}
                                        onBlur={() => this.datePickerInputOnFocus(false)}
                                        onCalendarClose={() => this.datePickerInputOnFocus(false)}
                                />
                                <div className={dateClass}>
                                    <div className="dropdown-section"
                                        onMouseEnter={() => { this.onPickerButtonHovered("date", true) }}
                                        onMouseLeave={() => { this.onPickerButtonHovered("date", false) }}
                                    ><img src={dateArrowBtnImg} alt="" width="15" /></div>
                                </div>
                            </label>
                        </div>
                        <div className="date-time-group time-group date-time-picker-contact">
                            <label className="input-label">Pickup time (required)</label>
                            <CustomTimePicker
                                defaultValue={moment(this.state.pickupDateTime.toJSDate(), "h:mm A")}
                                showSecond={false}
                                use12Hours={true}
                                inputIcon={inputIcon}
                                className={timeClass}
                                onChange={this.updatePickupTime.bind(this)}
                                format={'h:mm A'}
                                focusOnOpen
                                getPopupContainer={(trigger: { parentNode: any; }) => trigger.parentNode}
                                addon={(panel: { close: () => void; }) => {
                                    return (
                                        <div className="row">
                                            <button className="done-btn" onClick={() => panel.close()}>
                                                Done
                                        </button>
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
                </InputPair>
        }
    }

    /** Initial state of the booking form */
    private getDefaultState(defaultFields: Partial<ContactFormFieldsV2> = {}): ContactFormStateV2 {
        return {
            bookingRef: defaultFields.bookingRef || '',
            cabNumber: defaultFields.cabNumber || '',
            custContactNumber: '',
            destination: {
                street: '',
                suburb: '',
                display: ''
            },
            displayPopup: '',
            bookingContactNumber: '',
            emailAddress: '',
            firstName: '',
            invalidEmail: false,
            invalidPhone: false,
            invalidSection: false,
            invalidBookingPhone: false,
            isFormValid: false,
            itemDescription: '',
            lastName: '',
            message: { title: '', description: '' },
            pickup: {
                street: '',
                suburb: '',
                display: ''
            },
            pickupDateTime: DateTime.now(),
            bookingName: '',
            driverId: '',
            fareAmount: '',
            selectedBookingHail: 'booking',
            isDatepickerInputFocused: false,
            dateArrowOnHover: false,
            timeArrowOnHover: false,
            shouldClearPickup: false,
            shouldClearDestination: false,
            isAndroidDatePickerOpen: false,
        };
    }
}

export default ContactFormInputs;
