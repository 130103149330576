import React from 'react';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { SwitchToQuickBoookTab } from './FeaturesHelper';
import { Link } from 'react-router-dom';
import { SignUpLink } from './SignUpLink';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import { Typography } from '@mui/material';

/**
 * Favourites feature card
 */
export const FavouritesCard = (props: FeatureCardProps ) => {

    return (
        <React.Fragment key={FeatureId.Favourites}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>04.06.2020</span>
                </div>
                <Typography variant="h6" color="primary">BOOK FROM FAVOURITES</Typography>
                <Typography variant="body2" gutterBottom>
                    Create favourites, and quick book using your previous trips. <Link to={"/"} rel="noopener" className="feature-link" onClick={SwitchToQuickBoookTab}>Click here</Link> to see.
                </Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.favouriteFeature)} alt="Favourites" />
                </div>
                    <SignUpLink />
                    <AcknowledgeFeatureButton {...props}/>
            </div>
        </React.Fragment>
    )
}