import React from 'react';
import { connect } from "react-redux";
import { DialogKind, DetailedErrorMessage } from '../Dialog/DialogEntities';
import './ContactVerification.scss';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { CustomErrorMessages, DescriptiveErrorMessages } from "../Utils/ErrorMessages";
import { ApplicationState } from '../../appState';
import { DropLeadingZero } from '../../utils/Formattingutil';
import { Credentials } from '../Authentication/AuthEntities';
import { CheckAddPlus } from '../../utils/Formattingutil';
import { Config } from "../../Config/Config";
import { IsGuest } from "../Verification/IsGuest";
import { RefreshProfileAfterDataChange } from "../Authentication/GetUserProfileService";
import { VerificationTrigger } from './VerificationEntities';
import { Api } from '../../Services/Api';
import { Dispatch } from '../Dispatch';
import AuthImplV2 from "../Authentication/Login/AuthImplV2";
import { CreateCredentialForSignup } from "../Authentication/Login/CredentialHelper";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { IsUnderProfileValidationMode, CloseOffContactNumberUpdateTaskInProfileValidation } from "../LoginValidation/LoginValidationHelper";
import { VerifyLoginFromLocalStorageOrLogout } from "../Authentication/AuthHelper";
import { FeatureFlags } from '../../Config/FeatureFlags';
import SilentLogin from '../Authentication/Login/SilentLogin';
import { AuthType } from '../Authentication/Login/LoginEntities';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { CreateBookingSelector } from '../Booking/CreateBookingCommon';
import { LogEvent } from '../../utils/LogEvent';
import { ChangePhoneNumber, ChangePhoneOutcome } from './ChangePhoneNumber';
import appstore from '../../appStore';
import { SetErrorMessages } from '../Utils/CommonHelpers';
import { WellKnownErrors } from '../Utils/WellKnownErrors';
import { GuestPayUserSetupFlow } from '../GuestPayment/GuestPayUserSetupFlow';
import { OptionalUI } from '../Booking/OptionalParts/OptionalUI';
import { ApplePayPlaceholder, CreditCardPlaceholder, GooglePayPlaceholder, PayPalGuestTempPlaceholder } from '../Payment/PaymentEntities';

/**
 * There's a lot of overlap with ContactDetails.tsx, due to how tangled the implementation is.
 */
interface PropsFromStore {

    /** Country Phone Number Prefix (e.g. "+61") from the user sign up form (appState.auth.Credentials) */
    SignUpCountryPrefix: string;

    /** Local phone number (not including country prefix) from the user sign up form (appState.auth.Credentials) */
    SignUpLocalNumber: string | null;

    /** Country Phone Number Prefix (e.g. "+61") for a guest user (appState.verification) */
    GuestCountryPrefix: string | null;

    /** Local phone number (not including country prefix) for a guest user (appState.verification) */
    GuestLocalNumber: string | null;

    /** Full phone number from a signed in user's profile (appState.authentication.UserProfile.ContactPhone) */
    ProfileFullPhone: string | null;

    /** Whether the current user is a guest (not signed in). */
    IsGuest: boolean;

    /** Full sign up details. Only used to render the silent login form or continue the sign up flow. */
    SignUpCredentials: Credentials;

    /** 
     * The only thing we're using this for is the retry counter inside the DetailedErrorMessage object, which is only used for this one purpose in the whole app.
     * It clearly doesn't fit there at all.
     */
    errorMessage: DetailedErrorMessage;

    /** 
     * Whether a booking should be created if the verification suceeds.
     * WARNING: I don't understand the difference between this and appState.Verification.Trigger == Booking. 
     */
    IsInCreateBookingFlow: boolean;

    /** Which UI flow has led into the contact details entry, e.g. Signup, Guest Booking, etc. */
    UiFlow: VerificationTrigger;

    /** Current SMS Challenge in scope. It will have been triggered from the ContactDetails dialog. */
    SmsChallengeId: string | null;

    /** 
     * Previously used SMS code which was successfully verified but not consumed, for example due to a booking creation error.
     * When this is present in the store, we can pass through the verification process to retry the booking creation.
     */
    RetryableVerificationCode: string | null;

    IsSilentLoginActive: boolean;
    IsMobileDevice: boolean;
}

interface VerificationComponentState {

    /** The digit that has been typed into the first text box */
    code1: string;

    /** The digit that has been typed into the second text box */
    code2: string;

    /** The digit that has been typed into the third text box */
    code3: string;

    /** The digit that has been typed into the fourth text box */
    code4: string;

    /** An empty string is used when there is no error message. */
    verificationErrorMessage: string;

    /** 
     * Wether to display a link to resend the SMS.
     * It only shows up after a certain time has elapsed. 
     */
    canShowResendLink: boolean;

    /** 
     * Whether the server has re-sent the last SMS after the user requested it.
     * This will be expressed on the UI with a tick and text message.
     */
    hasResentSms: boolean;

    /** Whether to display a loading (spinner) icon on the UI. */
    showLoader: boolean;

    /** 
     *  Whether the SMS verification succeeded, failed, or is outstanding (null).
     *  Determines the colour of the border on the verification code text boxes.
     */
    isVerified: boolean | null;

    /**
     * This flag is set when you start typing the first digit of the code.
     * It changes the border colour of the verification code text boxes. 
     */
    hasPartialTextEntered: boolean;
}

/**
 * This component is to pop up a dialog for the user to enter an SMS verification code to complete the user/mobile number verification process.
 * It is used in several flows, including guest bookings and user signup. 
 */
class Verification extends React.Component<PropsFromStore, VerificationComponentState> {

    private noOfAttempts: number;
    private contactNumber: string = "";

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            verificationErrorMessage: "",
            canShowResendLink: false,
            hasResentSms: false,
            showLoader: false,
            isVerified: null,
            hasPartialTextEntered: false
        }

        this.noOfAttempts = 1;

        this.ResendVerificationCode = this.ResendVerificationCode.bind(this);
        this.OnResendSmsClicked = this.OnResendSmsClicked.bind(this);
        this.goBackToContactDetails = this.goBackToContactDetails.bind(this);

        if (this.props.UiFlow === VerificationTrigger.Signup) {
            if (this.props.SignUpLocalNumber) {
                this.contactNumber = this.props.SignUpCountryPrefix + DropLeadingZero(this.props.SignUpLocalNumber);
            } 
        }
        else {
            if (this.props.GuestLocalNumber && this.props.GuestCountryPrefix) {
                this.contactNumber = this.props.GuestCountryPrefix + DropLeadingZero(this.props.GuestLocalNumber);  
            }
            else if (this.props.ProfileFullPhone) {
                this.contactNumber = CheckAddPlus(this.props.ProfileFullPhone);
            }
        }
    }

    componentDidMount() {        
       LogEvent.OnVerificationPageLoad();
        // Provide the link to request new access code after 15 seconds of the popup load.
        var resendtimeout = setTimeout(() => { 
            this.setState({ canShowResendLink: true, hasResentSms: false });
        }, Config.SmsResendTimeoutMillis);

        /** 
         * If the verification code is already in the store on component mount, populate the input boxes with the code.
         * Use case is when the booking creation failed and the user clicked on Try again button on the error message. 
         * It will close the error message and open the verification page with the verification code filled and the spinner is displayed. Also, send the 'createBooking' request in the background.
         */
        if ((this.props.IsGuest) && (this.props.UiFlow === VerificationTrigger.Booking)) {
            const verificationCode = this.props.RetryableVerificationCode;

            if (verificationCode) {
                this.setState({
                    showLoader: true,
                    isVerified: null,
                    code1: verificationCode.substr(0, 1),
                    code2: verificationCode.substr(1, 1),
                    code3: verificationCode.substr(2, 1),
                    code4: verificationCode.substr(3, 1)
                });

                document.getElementById("4")!.focus();   
                this.CreateBookingUsingPreviousVerification();
            }
        }
    }

    /** Request the server to send the last SMS again.*/
    async ResendVerificationCode() {
        LogEvent.OnResendingVerificationCode();
        this.setState({canShowResendLink: false, showLoader: true});

        // After 15 seconds, show the resend link again.
        var resendtimeout = setTimeout(() => { 
             // Display the link only if the number of attempts are less than 4. i.e. Send verification code only 3 times.
            if(this.noOfAttempts < 4) {
                this.setState({canShowResendLink: true, hasResentSms: false}); 
            }         
        }, Config.SmsResendTimeoutMillis);

        const result = await Api.SmsVerification.ResendSms(this.props.SmsChallengeId!);

        if (result.isSuccess) {
            this.setState({ showLoader: false, hasResentSms: true });
        }
        else {
            const errorDetail = SetErrorMessages(result);

            this.setState({ showLoader: false, verificationErrorMessage: errorDetail.errorMessage });
        }
    }

    /** 
     * When the user clicks the "Request a new access code" link.
     * They will really get another SMS of the same challenge.
     */
    OnResendSmsClicked() {

        this.setState({
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            verificationErrorMessage: "",
            hasPartialTextEntered: true
        }); 
        document.getElementById("1")!.focus();  

        if (this.noOfAttempts === 4) {
            LogEvent.OnResendingVerificationCodeFourthTime();
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);

            if (this.props.UiFlow === VerificationTrigger.Signup) { 
                new CredentialsController().GoBackToSignupPopupFromError();
                Dispatch.Verification.HideLoaderInContactDetails();
            }
            else {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.ResendCode, DialogToOpen: DialogKind.ContactDetails }); 
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            }
            return;
        }

        this.ResendVerificationCode();
        this.noOfAttempts++;
    }

    /**
     * Shared event handler for each of the 4 text boxes that make up the code input.
     */
    OnCodeTextBoxChanged(e: React.ChangeEvent<HTMLInputElement>) {
        if (IsUnderProfileValidationMode())
        {
            VerifyLoginFromLocalStorageOrLogout();
        }

        const value = e.target.value;
        const name = e.target.name;

        if (e.target.value !== "" && e.target.id != "4") {
            var idOfNextTextBox = (parseInt(e.target.id) + 1).toString(); 
            document.getElementById(idOfNextTextBox)!.focus();
        }        

        /** 
         * Typescript doesn't support setState with dynamic property keys. As a workaround, coerce the object with (as Pick<VerificationState, keyof VerificationState>)
         * TS also wants us to go via [unknown]. It's a bit of a mess.
         */
        const stateChange = {
            [name]: value
        };

        this.setState((stateChange as unknown) as Pick<VerificationComponentState, keyof VerificationComponentState>, () => {

            if (this.state.code1 !== "") {
                this.setState({ hasPartialTextEntered: true });
            }

            if (this.state.code1 !== "" && this.state.code2 !== "" && this.state.code3 !== "" && this.state.code4 !== "") {
                const codes = this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4;

                this.setState({ showLoader: true, isVerified: null }, () => {
                    this.UseEnteredVerificationCode(codes);
                });
            }
        });
    }

    /** 
     * The user has finished entering their 4 digit code. 
     * Route to one of the different continuations based on which UI flow we are in.
     */
    async UseEnteredVerificationCode(verificationCode: string) {

        if (this.props.UiFlow === VerificationTrigger.Booking) {
            if (this.props.IsGuest) {

                // Create booking if the verification succeeds.
                await this.SubmitCode_CreateBooking(verificationCode);
            }
            else {
                // Authorised && contact phone is not null
                await this.SubmitCode_LegacyUnverifiedPhone(verificationCode);
            }
        }
        else if (this.props.UiFlow === VerificationTrigger.Signup) {
            await this.SubmitCode_NewUserSignup(verificationCode);
        }
    }

    /** 
     * Submit the verification code in the User Signup flow.
     */
    async SubmitCode_NewUserSignup(verificationCode: string) {

        // there must be an existing SMS challenge
        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;

        const submitResult = await Api.SmsVerification.SubmitResponse(smsChallengeId, verificationCode);

        if (submitResult.isSuccess) {
            
            this.setState({
                isVerified: true,
                verificationErrorMessage: "",
                hasPartialTextEntered: false
            });

            if (FeatureFlags.Auth0RedirectInChildWindow) {
                Dispatch.Verification.ChallengeSucceeded(verificationCode);
                Dispatch.Auth.ShowSilentLogin();
            }
            else {
                new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(this.props.SignUpCredentials));
            }
        }
        else {
            this.setState({
                verificationErrorMessage: CustomErrorMessages.IncorrectCode,
                canShowResendLink: true,
                isVerified: false,
                hasResentSms: false,
                hasPartialTextEntered: false,
                showLoader: false,
            });
        }
    }

    /**
     * Submit the verification code in the "legacy signed in user with unverified phone" flow.
     * [codes] is the text the user has entered in response.
     */
    async SubmitCode_LegacyUnverifiedPhone(codes: string) {
        const originalPhoneNumber = this.props.ProfileFullPhone!;

        const result = await ChangePhoneNumber(codes);

        if (result.Outcome === ChangePhoneOutcome.Success) {
            this.setState({
                isVerified: true,
                verificationErrorMessage: "",
                hasPartialTextEntered: false
            });
            await RefreshProfileAfterDataChange();

            if (this.props.IsInCreateBookingFlow) { 
                this.CreateBookingUsingPreviousVerification();
                Dispatch.Auth.IsBookingNeededAfterVerification(false);
            }
            else {
                this.setState({ showLoader: false, canShowResendLink: false }, () => { Dispatch.Dialog.CloseDialog(DialogKind.Verification); }); 
            }

            Dispatch.Verification.ClearContactNumber();

            if (IsUnderProfileValidationMode()) {
                CloseOffContactNumberUpdateTaskInProfileValidation();
                Dispatch.UILogicControl.BookingFormApiEnd();
            }
        }
        else {

            // TODO: review later. Maybe use the API error message directly
            const message = result.Outcome === ChangePhoneOutcome.CodeFailed ? CustomErrorMessages.IncorrectCode : result.ErrorMessage;

            this.setState({
                verificationErrorMessage: message,
                canShowResendLink: true,
                isVerified: false,
                hasResentSms: false,
                hasPartialTextEntered: false,
                showLoader: false,
            });
        }

        // Mobile update related logic for appInsights purpose
        if (originalPhoneNumber !== this.contactNumber) {

            const userProfile = appstore.getState().authentication.UserProfile;

            if (result.Outcome === ChangePhoneOutcome.Success) {
                appInsights.trackEvent("Update Mobile Success", {
                    User: JSON.stringify(userProfile),
                    PreviousMobileNumber: originalPhoneNumber,
                    NewMobileNumber: this.contactNumber
                });
            }
            else {
                appInsights.trackEvent("Update Mobile Failure", {
                    User: JSON.stringify(userProfile),
                    TryToUpdateTo: this.contactNumber
                });
            }
        } 
    }

    /**
     * Submit the verification code in the Create Booking flow.
     */
    async SubmitCode_CreateBooking(verificationCode: string) {

        const success = await this.CompleteSmsVerification(verificationCode);
        if (!success) {
            this.setState({ showLoader: false });
            return;
        }

        await this.CreateBookingInternal();
    }

    /** 
     *  Attempt to complete the current SMS Challenge.
     *  Returns true if successful.
     */
    async CompleteSmsVerification(verificationCode: string): Promise<boolean> {

        // there must be an existing SMS challenge
        const smsChallengeId = appstore.getState().verification.SmsChallengeId!;

        const submitResult = await Api.SmsVerification.SubmitResponse(smsChallengeId, verificationCode);

        // success
        if (submitResult.isSuccess) {
            LogEvent.ValidVerificationCodeEntered();
            Dispatch.Verification.ChallengeSucceeded(verificationCode);

            this.setState({
                isVerified: true,
                verificationErrorMessage: "",
                hasPartialTextEntered: false,
            });

            return true;
        }

        // failure
        LogEvent.InvalidVerificationCodeEntered();

        this.setState({
            verificationErrorMessage: CustomErrorMessages.IncorrectCode,
            canShowResendLink: true,
            isVerified: false,
            hasResentSms: false,
            hasPartialTextEntered: false,
        });

        return false;
    }

    /** 
     *  Create a booking immediately, relying on a previously completed SMS verification.
     *  This supports some non-standard flows.
     */
    async CreateBookingUsingPreviousVerification() {
        await this.CreateBookingInternal();
    }

    async CreateBookingInternal(): Promise<void> {

        // optional just in time guest payment
        if (await this.DeferredGuestPaymentSetup() == false) return;

        // Call this method to select the version of CreateBooking and then call the CreateBooking Method to make the api call
        const createBookingHandler = CreateBookingSelector(true);
        const isSuccess = await createBookingHandler.CreateBooking();
        
        this.setState({showLoader: false, canShowResendLink: false});

        /**
        * Please DO NOT remove this, as it is critical for a correct strict validation for booking.
        */
        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

        if (isSuccess) {

            // Based on the type of booking (v1 or v2) process the booking information
            await createBookingHandler.ProcessBooking();
            
            Dispatch.Dialog.CloseDialog(DialogKind.Verification);
            Dispatch.Dialog.ShowDialog(DialogKind.Confirmation);
            Dispatch.Verification.ClearChallenge();
            Dispatch.Verification.ClearContactNumber();

            // 3DS details only need to be sent once per card, so clear them after a successful booking creation.
            Dispatch.Payment.Clear3DSecure();
        }
        else
        {
            const error = createBookingHandler.GetError();

            // error handling here
            if (error.WellKnownError == WellKnownErrors.SmsVerification) {
                Dispatch.Verification.ClearChallenge();
            }

            if (error.isTimeout) {
                Dispatch.Dialog.CloseDialog(DialogKind.Verification);
                //Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
                //Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
                Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
                LogEvent.BookingCreationTimedOut();
            }
            else {
                Dispatch.Dialog.CloseDialog(DialogKind.Verification);
    
                const retryCount = this.props.errorMessage.RetryCount ?? 0;
    
                // If the retry count is less than 3, prompt an error message with a 'Try again' button. Else, prompt a popup with only a 'OK' button asking the user to call the contact center.
                if (retryCount < 3) {
                    //Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingFailed, DialogToOpen: DialogKind.Verification, RetryCount: retryCount + 1 });
                    //Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
                    Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
                }
                else {
                    //Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
                    //Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
                    Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
                    Dispatch.Verification.ClearChallenge();
                }
    
                LogEvent.BookingCreationFailed(error.errorMessage);
            }
        }
    }

    /** 
     * Perform any deferred setup activities for Guest Payment flows immediately before creating a booking.
     * For example: PayPal guest user creation and payment registration.
     * Returns false if the setup failed and the booking creation should not proceed.
     */
    async DeferredGuestPaymentSetup(): Promise<boolean> {

        const appState = appstore.getState();

        // user has selected PayPal and we haven't created that yet
        if (OptionalUI.PendingPayPalGuest(appState) && appState.booking.PaymentOption === PayPalGuestTempPlaceholder) {

            return await GuestPayUserSetupFlow.Step_2b_PostSmsVerification_PayPal();
        }

        // user has authorised Apple Pay but we haven't created a user account and payment method yet.
        if (OptionalUI.PendingApplePayGuest(appState) && appState.booking.PaymentOption === ApplePayPlaceholder) {

            return await GuestPayUserSetupFlow.PostSmsVerification_ApplePay();
        }

        // user has authorised Google Pay but we haven't created a user account and payment method yet.
        if (OptionalUI.PendingGooglePayGuest(appState) && appState.booking.PaymentOption === GooglePayPlaceholder) {
            return await GuestPayUserSetupFlow.PostSmsVerification_GooglePay();
        }

        // user has tokenised and verified a credit/debit card but we haven't created a user account and payment method yet.
        if (OptionalUI.PendingCreditCardGuest(appState) && appState.booking.PaymentOption === CreditCardPlaceholder) {
            return await GuestPayUserSetupFlow.PostSmsVerification_CreditCard();
        }

        // no action required
        return true;
    }

    /* Function to clear the verification code input boxes on backspace */
    OnCodeTextBoxKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {  
        if (e.which === 8) {
            if (e.currentTarget.value === "") {

                const thisBoxId = parseInt(e.currentTarget.id);

                if (thisBoxId > 1) {
                    const previousBoxId = thisBoxId - 1;
                    document.getElementById(previousBoxId.toString())!.focus(); 
                }
            }
        }
    }

    /** 
     * On click of Back button, close the Verification popup and open the Contact details popup with prepopulated data.
     */
    goBackToContactDetails() {
        Dispatch.Dialog.CloseDialog(DialogKind.Verification);
        Dispatch.Dialog.ShowDialog(DialogKind.ContactDetails);
    }

    render() {
        const verifyCodeClass = this.state.hasPartialTextEntered ? "orange-border" : this.state.isVerified ? "green-border" : this.state.isVerified === false ? "red-border" : "";
        const displayNumber = CheckAddPlus(this.contactNumber);
        
        return (
            <React.Fragment>
                <div className="contact-details">
                    <div className="popup-number-update">
                        <span className="bold-text">{displayNumber}</span>
                        <img src={getContentUrl(ContentURL.images.Lock.NumberEdit)} alt="Edit mobile number button" width="50px" height="auto" onClick={this.goBackToContactDetails}/>
                    </div>
                    <br/>
                    <p className="popup-number-description-sub">Please enter this code below:</p>

                    { this.state.verificationErrorMessage ? <p className="verification-error invalid-code">{ this.state.verificationErrorMessage }</p> : "" }
                    
                    <div className="verification-code">
                        <input type="tel" id="1" maxLength={1} className={verifyCodeClass} name="code1" value={this.state.code1} onChange={(e) => this.OnCodeTextBoxChanged(e)} onKeyDown={(e)=> this.OnCodeTextBoxKeyDown(e)} autoFocus/>
                        <input type="tel" id="2" maxLength={1} className={verifyCodeClass} name="code2" value={this.state.code2} onChange={(e) => this.OnCodeTextBoxChanged(e)} onKeyDown={(e)=> this.OnCodeTextBoxKeyDown(e)} />
                        <input type="tel" id="3" maxLength={1} className={verifyCodeClass} name="code3" value={this.state.code3} onChange={(e) => this.OnCodeTextBoxChanged(e)} onKeyDown={(e)=> this.OnCodeTextBoxKeyDown(e)} />
                        <input type="tel" id="4" maxLength={1} className={verifyCodeClass} name="code4" value={this.state.code4} onChange={(e) => this.OnCodeTextBoxChanged(e)} onKeyDown={(e)=> this.OnCodeTextBoxKeyDown(e)} />
                        <div className="verification-tick">{ this.state.showLoader ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} height="55" /> : null }</div>
                    </div>    

                    { this.state.canShowResendLink ? <a className="resend-code" onClick={this.OnResendSmsClicked}>Request a new access code</a> : null }
                    { this.state.hasResentSms ? <div className="sent-code"><img src={getContentUrl(ContentURL.images.GreenTick)} alt="" width="15" /><p>New access code sent</p></div> : "" }
                </div>

                {this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.SignUpCredentials} AuthType={AuthType.Signup} /> }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        SignUpCountryPrefix: state.authentication.Credentials.CountryInfo.CountryCode,
        SignUpLocalNumber: state.authentication.Credentials.ContactNumber ?? null,
        GuestCountryPrefix: state.verification.UserContactNumberInfo.CountryInfo?.CountryCode ?? null,
        GuestLocalNumber: state.verification.UserContactNumberInfo.Contactnumber ?? null,
        ProfileFullPhone: state.authentication.UserProfile?.ContactPhone ?? null,
        errorMessage: state.dialog.detailedErrorMessage,
        SmsChallengeId: state.verification.SmsChallengeId,
        RetryableVerificationCode: state.verification.SuccessfulUnusedCode,
        IsGuest: IsGuest(state.authentication.AuthToken, state.authentication.UserProfile),
        UiFlow: state.verification.Trigger,
        SignUpCredentials: state.authentication.Credentials,
        IsInCreateBookingFlow: state.authentication.IsBookingNeededAfterVerification,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(Verification);