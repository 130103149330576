import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { PaymentState, PaymentStateInitialState, ThreeDSecureResult } from "./PaymentState";
import { MpsProfile, PaymentCard } from "../../Services/PaymentEntities";
import { PaymentCardErrorType } from "./PaymentEntities";
import { AddPaymentMethodResponse } from "../../Services/PaymentContractsV2";

/** Redux store slice for payments. */
const slice = new ReduxStoreSlice<PaymentState>("Payment", PaymentStateInitialState);

/** Dispatcher for the Payment store slice. */
export const PaymentDispatchV2 = {
    DataRefresh: slice.Action("Data Refresh", DataRefresh),
    SetCardToRemove: slice.Action("Set Card To Remove", SetPaymentCardToRemove),
    RemoveCard: slice.Action("Remove Payment Card", RemovePaymentCard),
    SetDefaultCard: slice.Action("Set Default Payment Card", SetDefaultPaymentCard),
    ClearDefaultCard: slice.EmptyAction("Clear Default", ClearDefaultCard),
    ToggleCardRegistrationPanel: slice.Action("Toggle Card Registration Panel", ToggleCardRegistrationPanel),

    /** Set the error message if the selected payment card is invalid (e.g: card is expired or removed from the account) */
    CardErrorMessage: slice.Action("Set Card Error Message", CardErrorMessage),

    /** Reset any error message about the selected card (payment method). */
    ClearCardError: slice.EmptyAction("Clear Card Error", ClearCardError),
    ShowAddPaymentCardScreenAfterSignup: slice.EmptyAction("Show Add Payment Card", ShowAddPaymentCardScreenAfterSignup),
    HideAddPaymentCardScreenAfterSignup: slice.EmptyAction("Hide Add Payment Card", HideAddPaymentCardScreenAfterSignup),

    /** Refreshes user's MPS Profile in the store. */
    RefreshMpsProfile: slice.Action("MPS Profile", RefreshMpsProfile),

    /** Opens 'Add Payment Method' dialog. */
    ShowAddPaymentMethodDialog: slice.EmptyAction("Show Add Payment Dialog", ShowAddPaymentDialog),

    /** Closes 'Add Payment Method' dialog */
    HideAddPaymentMethodDialog: slice.EmptyAction("Hide Add Payment Dialog", HideAddPaymentDialog),

    /** Refresh the client token from MPS */
    RefreshClientToken: slice.Action("Refresh Client Token", RefreshClientToken),

    /** Add newly registered Apple Pay details to state. */
    AddApplePay: slice.Action("Add Wallet Based Payment Method", AddApplePay),

    /** Whether the CNP payment system currently available. */
    PaymentSystemAvailable: slice.Action("Payment System Available", PaymentSystemAvailable),

    /** Add newly registered Google Pay details to state. */
    AddGooglePay: slice.Action("Add Google Pay Payment Method", AddGooglePay),

    /** Refresh 3DS details obtained from the 3DS SDK during the verification process. */
    Refresh3DSecure: slice.Action("3DS Nonce", Refresh3DSecure),

    /** Clear previously stored 3DS verification result (after sending it to the server) since it is only used once and won't need again in future. */
    Clear3DSecure: slice.EmptyAction("Clear 3DS Nonce", Clear3DSecure),

    /** Add newly registered credit/debit card details to state for a guest user. This is different from the list of credit cards of a signed in user. */
    GuestCreditCard: slice.Action("Add Guest Credit Card", GuestCreditCard),
};

/** Reducer for the Payment store slice. */
export const PaymentReducerV2 = slice.MakeCombinedReducer();

/** Completely refresh the list of cards data. This can update the Default card too. */
function DataRefresh(state: PaymentState, allCards: PaymentCard[]): PaymentState {
    const newDefault = allCards.find(i => i.IsDefault) ?? null;

    return {
        ...state,
        AllCards: allCards,
        DefaultCard: newDefault,
    };
}

/** set card to be removed. This value is used by remove payment dialog. */
function SetPaymentCardToRemove(state: PaymentState, card: PaymentCard): PaymentState {
    return {
        ...state,
        CardToRemove: card
    };
}

/** Removed the selected card. This will affect the All list, and possibly the default card will become null.  */
function RemovePaymentCard(state: PaymentState, card: PaymentCard): PaymentState {
    const newCardList = state.AllCards.filter((paymentCard) => {
        return paymentCard.CardId !== card.CardId;
    });

    // reset default if this was it
    const newDefault = state.DefaultCard?.CardId === card.CardId ? null : state.DefaultCard;

    return {
        ...state,
        AllCards: newCardList,
        DefaultCard: newDefault,
    };
}

/** The specified card has been set as the default. Reflect it in the state. 
 *  This includes updating the list of all cards. */
function SetDefaultPaymentCard(state: PaymentState, newDefault: PaymentCard): PaymentState {

    const newCards = state.AllCards.map((card) => {

        // replace with default-ised version
        if (card.CardId == newDefault.CardId) return newDefault;

        // clear other defaults
        if (card.IsDefault) return {
            ...card,
            IsDefault: false,
        };

        // unchanged
        return card;
    });

    return {
        ...state,
        AllCards: newCards,
        DefaultCard: newDefault
    };
}

/**
 * Remove any existing default card. This can change the list of all cards.
 */
function ClearDefaultCard(state: PaymentState): PaymentState {

    const newCards = state.AllCards.map((card) => {

        // clear defaults
        if (card.IsDefault) return {
            ...card,
            IsDefault: false,
        };

        // unchanged
        return card;
    });

    return {
        ...state,
        AllCards: newCards,
        DefaultCard: null,
    };
}

/** Toggle card registration panel. */
function ToggleCardRegistrationPanel(state: PaymentState, cardRegistrationPanelActive: boolean): PaymentState {
    return {
        ...state,
        IsCardRegistrationPanelOpen: cardRegistrationPanelActive
    };
}

/** Set the error message if the selected payment card is invalid (e.g: card is expired or removed from the account)*/
function CardErrorMessage(state: PaymentState, errorMessage: PaymentCardErrorType): PaymentState {
    return {
        ...state,
        PaymentCardError: errorMessage
    }
}

/** Reset any error message about the selected card (payment method). */
function ClearCardError(state: PaymentState): PaymentState {
    return {
        ...state,
        PaymentCardError: null,
    };
}

/** Show the add payment card screen after signup to the logged-in user */
function ShowAddPaymentCardScreenAfterSignup(state: PaymentState) : PaymentState {
    return {
        ...state, 
        ShouldDisplayAddPaymentCardScreenAfterSignup : true
    }
}

/** Hide the add payment card screen */
function HideAddPaymentCardScreenAfterSignup(state: PaymentState) : PaymentState {
    return {
        ...state,
        ShouldDisplayAddPaymentCardScreenAfterSignup : false
    }
}

/** Refresh MPS Profile. Some of the data in this profile is used later in various API calls to MPS. */
function RefreshMpsProfile(state: PaymentState, mpsProfile: MpsProfile): PaymentState {
    return {
        ...state,
        MpsProfile: mpsProfile
    }
}

/** Open 'Add Payment Method Dialog'. */
function ShowAddPaymentDialog(state: PaymentState): PaymentState {
    return {
        ...state,
        IsAddPaymentDialogOpen: true
    }
}

/** Close 'Add Payment Method Dialog'. */
function HideAddPaymentDialog(state: PaymentState): PaymentState {
    return {
        ...state,
        IsAddPaymentDialogOpen: false
    }
}

/** Refreshes the client token from MPS. */
function RefreshClientToken(state: PaymentState, token: string): PaymentState {
    return {
        ...state,
        ClientToken: token
    }
}

/** Add newly registered Apple Pay details to state. */
function AddApplePay(state: PaymentState, paymentDetails: AddPaymentMethodResponse): PaymentState {
    return {
        ...state,
        ApplePay: paymentDetails
    };
}

/** Whether the CNP payment system currently available. */
function PaymentSystemAvailable(state: PaymentState, availability: boolean): PaymentState {
    return {
        ...state,
        IsPaymentSystemAvailable: availability
    }
}

/** Add newly registered Google Pay details to state. */
function AddGooglePay(state: PaymentState, paymentDetails: AddPaymentMethodResponse): PaymentState {
    return {
        ...state,
        GooglePay: paymentDetails
    };
}

/** Refresh 3DS details obtained from the 3DS SDK during the verification process. */
function Refresh3DSecure(state: PaymentState, threeDs: ThreeDSecureResult): PaymentState {
    return {
        ...state,
        ThreeDSecure: threeDs
    };
}

/** Clear previously stored 3DS verification result (after sending it to the server) since it is only used once and won't need again in future. */
function Clear3DSecure(state: PaymentState): PaymentState {
    return {
        ...state,
        ThreeDSecure: null
    };
}

/** Add newly registered credit/debit card details to state for a guest user. */
function GuestCreditCard(state: PaymentState, card: AddPaymentMethodResponse): PaymentState {
    return {
        ...state,
        GuestCreditCard: card
    };
}