import throttle from 'lodash/throttle';
import { GetValues } from '../../../Config/MyAppConfig';
import { GooglePlaceSessionManager } from './GooglePlaceSessionManager';
import { PlaceSearchInput, LocationTypes } from '../Entities/PlaceSearchEntities';

/**
 * Starts a Google Maps API place predictions lookup.
 * The result is sent to the callback function which is part of the input.
 * It is assumed that the caller has already (caused to become) loaded the Google Maps API script. This is normally achieved by nesting a React component in <GoogleMapScriptLoader>.
 */
export function GetGooglePredictions(input: PlaceSearchInput) {
    GetGooglePredictionsThrottled(input);
}

/** Data for the most recent successful lookup. To prevent duplicate requests. */
interface InputOutput {
    Input: PlaceSearchInput;
    Output: google.maps.places.AutocompletePrediction[];
}

/** The previous lookup result */
let lastResult: InputOutput | null = null;

/** instantiation is deferred in order to wait for the Google Maps script to load */
let googleService: google.maps.places.AutocompleteService | null = null;

/**
 * Unthrottled version. This will be wrapped in a Lodash throttle.
 */
function GetGooglePredictionsRaw(input: PlaceSearchInput) {

    // 1) check for duplicate input vs last
    if (DoesInputMatchPrevious(input)) {
        input.SuccessCallback(lastResult!.Output);
        return;
    }

    // 2) convert to Google
    const googleRequest = BuildGoogleRequest(input);

    // 3) call Google
    if (googleService == null) {
        googleService = new google.maps.places.AutocompleteService();
    }

    googleService.getPlacePredictions(googleRequest, (result, status) => ReceiveGoogleResponse(result, status, input));

    // continues in ReceiveGoogleResponse() below!
}

/**
 * Continuation of GetGooglePredictionsRaw() after the callback from Google.
 */
function ReceiveGoogleResponse(results: google.maps.places.AutocompletePrediction[] | null, status: google.maps.places.PlacesServiceStatus, input: PlaceSearchInput) {

    // 4) handle error cases
    if (status !== google.maps.places.PlacesServiceStatus.OK) {

        // give up
        console.log(`Google Places API failed with status: ${status}`);
        return
    }

    // if we get OK, results will be non-null
    const validResults = results!;

    // 5) save "previous input"
    lastResult = {
        Input: input,
        Output: validResults,
    };

    // 6) pass back to callback
    input.SuccessCallback(validResults);
}

/** Returns true if this input is a duplicate of the last result. */
function DoesInputMatchPrevious(input: PlaceSearchInput): boolean {

    if (lastResult == null) return false;

    const lastInput = lastResult.Input;

    if (lastInput.RawInputText !== input.RawInputText) return false;
    if (lastInput.LocationType !== input.LocationType) return false;
    if (!!lastInput.PreferNearbyTo !== !!input.PreferNearbyTo) return false;

    return true;
}

/** 
 *  Throttled version of GetGooglePredictionsRaw.
 *  It executes at most every 300ms.
 *  This behaviour is to align with a Google Maps API requirement.
 */
let GetGooglePredictionsThrottled = throttle(GetGooglePredictionsRaw, 300);

/**
 * Converts the internal request type PlaceSearchInput into an equivalent one for the Google Maps API.
 */
function BuildGoogleRequest(input: PlaceSearchInput): google.maps.places.AutocompletionRequest {

    // user's input text
    const request: google.maps.places.AutocompletionRequest = {
        input: input.RawInputText
    };

    // location restriction to known countries
    request.componentRestrictions = {
        country: GetValues().AllowedCountryCodes
    };

    // special case to return only cities / suburbs etc
    if (input.LocationType === LocationTypes.CityOrArea) {
        request.types = ['(regions)'];
    }

    // vicinity location (usually dropoff from pickup)
    if (input.PreferNearbyTo) {
        request.location = new google.maps.LatLng(input.PreferNearbyTo.latitude, input.PreferNearbyTo.longitude);
        request.radius = 50000; // meters. Pretty arbitrary. Not a strict limit (in Google)
    }

    // API session ($$$)
    request.sessionToken = GooglePlaceSessionManager.GetSession();

    return request;
}