import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { FeaturesState, FeaturesInitialState }  from './FeaturesState';
import { FeatureId } from "./FeaturesEntities";
import { FeatureInfo } from "../../Services/FeaturesContracts";
import { PreviewFeatureId } from "../Features/FeatureEntities";

const slice = new ReduxStoreSlice<FeaturesState>("Features", FeaturesInitialState);

/** Dispatcher for actions in the WhatsNew state slice. */
export const FeaturesDispatch = {
    
    /** Save new feature list. */
    SaveNewFeatureList: slice.Action("Save NewFeature List", SaveNewFeatureList),

    /** Mark viewed feature as acknowledged */
    AcknowledgeFeature: slice.Action("Mark Feature As Viewed", AcknowledgeFeature),

    /** Save acknowledged feature list. */
    SaveAcknowledgedFeatureList: slice.Action("Save Acknowledged List", SaveAcknowledgedFeatureList),
    
    /** Show whats new feature list. */
    ShowWhatsNewFeatures: slice.EmptyAction("Show Whats New Feature Cards", ShowWhatsNewFeatures),

    /** Hide whats new feature list. */
    HideWhatsNewFeatures: slice.EmptyAction("Hide Whats New Feature Cards", HideWhatsNewFeatures),

    /** Show Azure B2C login UI. */
    AzureB2CPrivateUserOptIn: slice.EmptyAction("Show Azure B2C Login UI", AzureB2CPrivateUserOptIn),

    /** Refresh the set of server-managed preview features */
    RefreshPreviewFeatures: slice.Action("Refresh Preview Features", RefreshPreviewFeatures),
};

/** Reducer for the WhatsNew store slice */
export const FeaturesReducer = slice.MakeCombinedReducer();

/** Show Azure B2C login UI. */
function AzureB2CPrivateUserOptIn(state: FeaturesState): FeaturesState {
    return {
        ...state,
        AzureB2CPrivateUserOptIn: true
    }
}

/** Show whats new feature list. */
function ShowWhatsNewFeatures(state: FeaturesState): FeaturesState {
    return {
        ...state,
        IsFeatureListDisplayed: true
    }
}

/** Hide whats new feature list. */
function HideWhatsNewFeatures(state: FeaturesState): FeaturesState {
    return {
        ...state,
        IsFeatureListDisplayed: false
    }
}


/** Save new feature list. */
function SaveNewFeatureList(state: FeaturesState, features: FeatureId[]): FeaturesState {
    return {
        ...state,
        NewFeatureList: features
    }
}

/** Save acknowledged feature list. */
function SaveAcknowledgedFeatureList(state: FeaturesState, features: FeatureId[]): FeaturesState {
    return {
        ...state,
        AcknowledgedFeatureList: features
    }
}

/** Mark viewed feature as acknowledged */
function AcknowledgeFeature(state: FeaturesState, featureId: FeatureId): FeaturesState {

    // Not include acknowledged feature in new feature list
    const newFeatureList = state.NewFeatureList.filter(element => element !== featureId);

    return {
        ...state,
        NewFeatureList: newFeatureList,
        AcknowledgedFeatureList: [...state.AcknowledgedFeatureList, featureId]
    }
}

/** Refresh the set of server-managed preview features */
function RefreshPreviewFeatures(state: FeaturesState, previewFeatures: FeatureInfo[]): FeaturesState {

    const newEnabledLookup: Partial<Record<PreviewFeatureId, true>> = { };

    for (let preview of previewFeatures) {
        if (preview.IsEnabled) {
            newEnabledLookup[preview.FeatureId] = true;
        }
    }

    return {
        ...state,
        PreviewFeatures: previewFeatures,
        EnabledPreviews: newEnabledLookup,
    };
}