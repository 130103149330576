import { ServiceCallOutcome, NetworkFailResult, WebServerErrorResult, ApplicationErrorResult } from "../../Services/ServiceEntities";
import { ErrorResult } from "./ErrorEntities";
import { WellKnownErrors } from "./WellKnownErrors";

/** This helper function to set error messages when isSuccess false */
export function SetErrorMessages(serviceResult: NetworkFailResult | WebServerErrorResult | ApplicationErrorResult): ErrorResult {

    if (serviceResult.outcome === ServiceCallOutcome.ApplicationError) {

        const knownError = WellKnownErrors.TryMatch(serviceResult.apiError.Detail);

        return {
            errorMessage: serviceResult.apiError.Summary.FullMessage,
            WellKnownError: knownError,
        };
    }

    if (serviceResult.outcome === ServiceCallOutcome.WebServerError) {
        return {
            errorMessage: "Technical error. Please try again.",
        };
    }

    if (serviceResult.isTimeout) {
        return {
            errorMessage: "Request timeout",
            isTimeout: true
        };
    }

    return {
        errorMessage: "Network error"
    };
}