
import moment, { Duration } from "moment";

/** The minimum time between consecutive ETA refreshes of the same booking. Since we display the ETA in minutes, it doesn't make sense to query significantly more than once per minute. */
export const MinRefreshInterval = {
    Accepted: moment.duration(30, "seconds"),
    PickedUp: moment.duration(60, "seconds"),
};

/** A lack of vehicle movement may cause the ETA refresh to be deferred. This is the maximum gap between ETA calls allowed by that process. */
export const MaxRefreshInterval = moment.duration(2, "minutes");

/** Conversion factor from meters moved by the vehicle to potential reduction in ETA.
 *  The value is conservatively low for safety.
 *  Details: https://cabcharge.atlassian.net/wiki/spaces/CA/pages/928973446/Idea+Reduce+ETA+Calls+Based+On+Location+Drift */
export const EtaReductionMetersPerSecond: number = 7.5;

/** Conversion factor between elapsed time and reduction in ETA.
 * The value is conservatively high for safety. */
export const EtaReductionPerElapsedSecond: number = 1.2;

/** How much quota can be saved up, in units of requests. This allows for small bursts of activity to be handled gracefully. */
export const MaxRequestQuotaStored: number = 2.0;

/** How often 1 request worth of quota accumulates. This corresponds to the maximum long-term rate of requests. */
export const QuotaAccumulationTime: Duration = moment.duration(10, "seconds");

/** Multiplier on ETA for optimistic conditions */
export const OptimismMultiplier: number = 0.85;

/** Deprioritise ETA refresh on picked up compared to accepted jobs.
 *  Because ETA to pickup is more important than ETA to dropoff */
export const PickedUpPriorityMultiplier: number = 0.5;