import braintree from "braintree-web";
import { Api } from "../../Services/Api";
import { AddPaymentMethodFunnel } from "./AddPaymentMethodFunnel";

/** Singleton instance of Braintree Client SDK. */
let btClient: braintree.Client | null;

/**
 * Loads the Braintree SDK. There are two parts each of which could fail;
 * 1) Client Token (from API)
 * 2) Braintree SDK
 */
const MakeBtClient = async () => {

    // 1) Client Token
    const tokenResult = await Api.PaymentV2.GetClientToken();

    if (!tokenResult.isSuccess) {
        AddPaymentMethodFunnel.ApiError("Get Token", tokenResult, false);
        return null;
    }

    // 2) Braintree SDK
    try {
        const token = tokenResult.value;

        btClient = await braintree.client.create({
            authorization: token
        });
    }
    catch (error) {
        AddPaymentMethodFunnel.Exception("Braintree Client", error, false);
        return null;
    }

    return btClient;
}

/**
 * Returns the exisiting Braintree client if any, or create a new one.
 */
export const GetOrMakeBraintreeClient = async () => {

    if (!btClient) {
        btClient = await MakeBtClient();
    }

    return btClient;
}