import React, { Component } from 'react';
import { connect } from "react-redux";
import './ResponsiveInputs.scss';
import '../../Booking/NewBooking.scss';
import { Config } from "../../../Config/Config";
import { Dispatch } from '../../Dispatch';
import { ErrorMessagePlacementKind } from './LoginEntities';
import { ApplicationState } from "../../../appState";
import CredentialsController from './CredentialsController';
import { IsValidEmailFormat } from "./CredentialHelper";
import { OutlinedTextField, CustomTextFieldProps } from "../../Booking/Widget/OutlinedTextField";
import { DialogKind } from "../../Dialog/DialogEntities";

interface CredentialEmailProps {
    /**
     * This prop is used to decided the validation on email input.
     * If true  -> input does not need to be an email address, which is catering for log-in where some users have normal string as user name;
     * If false -> input has to be an literally valid email address.
     */
    IsNonEmailLoginAllowed: boolean;
}

export interface CredentialEmailState {
    message: string | null;
    hasInputValue: boolean;
    isFocused: boolean;
}

interface PropsFromStore {
    ErrorMessage: string | undefined;
    ErrorMessagePlacement: ErrorMessagePlacementKind;
    Email: string | undefined;
    TopmostDialog: DialogKind;
}

/**
 * Renders the email/username input in the new login/signup form.
 */
class ResponsiveEmailInput extends Component<CredentialEmailProps & PropsFromStore, CredentialEmailState> {
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: CredentialEmailProps & PropsFromStore) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            message: null,
            hasInputValue: false,
            isFocused: false
        }

        this.onInputValueChange = this.onInputValueChange.bind(this);
        this.clearEmail = this.clearEmail.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
    }

    componentDidMount() {
        if (!!this.props.Email) {
            if (this.props.IsNonEmailLoginAllowed || (!this.props.IsNonEmailLoginAllowed && IsValidEmailFormat(this.props.Email))) {
                this.inputRef.current!.value = this.props.Email;
                this.setState({ hasInputValue: true });
            }
        }
    }

    static getDerivedStateFromProps(currentProps: CredentialEmailProps & PropsFromStore, state: CredentialEmailState) {
        if (currentProps.ErrorMessagePlacement === ErrorMessagePlacementKind.Email && currentProps.ErrorMessage != state.message) {
            return { message: currentProps.ErrorMessage! };
        }
        return null;
    }

    onInputValueChange() {
        this.setState({ hasInputValue: this.inputRef.current!.value.length > 0 }); // This should count empty space in.
        const email = this.inputRef.current!.value.trim(); // This should not count the leading & trailing space in.

        if (this.props.ErrorMessagePlacement === ErrorMessagePlacementKind.Email) {
            new CredentialsController().ResetAuth0ErrorMessage();
        }

        if (this.validateEmail(email)) {
            Dispatch.Auth.CredentialEmail(email);
        }
        else {
            Dispatch.Auth.ClearCredentialEmail();
        }
    }

    clearEmail() {
        if (this.state.isFocused) return;

        this.inputRef.current!.value = "";

        Dispatch.Auth.ClearCredentialEmail();

        this.setState({ message: null, hasInputValue: false});

        if (this.props.ErrorMessagePlacement === ErrorMessagePlacementKind.Email) {
            new CredentialsController().ResetAuth0ErrorMessage();
        }
    }

    /**
     * Validation rules:
     * (1) Can not be empty;
     * (2) Have to an email if this is sign-up popup;
     * (3) Max length 100 (this constriction is on JSX input level).
     * 
     * Validation reference:
     * https://www.npmjs.com/package/email-validator
     * https://github.com/manishsaraan/email-validator/blob/master/index.js
     */
    validateEmail(email: string): boolean {

        if (email.length === 0) {
            this.setState({ message: "Please enter a valid email address." });
            return false;
        }

        if ( !this.props.IsNonEmailLoginAllowed && !IsValidEmailFormat(email)) {
            this.setState({ message: "Please enter a valid email address." });
            return false;
        }

        this.setState({ message: null });
        return true;
    }

    onFocusChange(isFocused: boolean) {
        this.setState({ isFocused: isFocused });

        if (!isFocused) this.onInputValueChange();
    }

    render() {

        const inputProp: CustomTextFieldProps  = {
            LabelText: this.props.IsNonEmailLoginAllowed ? "Your email/username" : "Your email",
            Name: "Authentication input email",
            AllowAutoFocus: !(this.props.TopmostDialog === DialogKind.SignUp || this.props.TopmostDialog === DialogKind.SignUpToAddCard || this.props.TopmostDialog === DialogKind.SignupToPayDriverDirect),
            ErrorMessage: this.state.message || "",
            ErrorMessageStyle: "auth-input-error-message",
            FieldMaxLength: Config.Credentials.EmailMaxLength,
            onClearEvent: this.clearEmail,
            onBlurEvent: () => this.onFocusChange(false),
            IsInputInvalid: !!this.state.message,
            IsInputFocussed: this.state.isFocused,
            onFocusEvent: () => this.onFocusChange(true),
            DoesInputHasValue: this.state.hasInputValue,
            onChangeEvent: this.onInputValueChange
        }

        return (
            <OutlinedTextField ref={this.inputRef} {...inputProp} />
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        ErrorMessage: state.authentication.ErrorMessage,
        ErrorMessagePlacement: state.authentication.ErrorMessagePlacement,
        Email: state.authentication.Credentials.Email,
        TopmostDialog: state.dialog.topmostDialog!
    };
}

export default connect(mapStateToProps)(ResponsiveEmailInput);