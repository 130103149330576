import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { ServiceCheckStatus } from "../../Booking/BookingEntities";
import { AddressPointModalType, AddressPoint, AddressValidity } from "./AddressPointsEntities";

export interface AddressPointsState {

    /** Currently active address point details. This is populated during Add/Edit address point operations. */
    ActiveAddressPoint: AddressPoint;

    /** List of the current user's saved address points. */
    List: AddressPointDetails[];

    /** Currently opened modal type. Null when no modal is opened. */
    UiEditMode: AddressPointModalType | null;

    /** Address validation details. */
    AddressValidity: AddressValidity;

    /** Address point error message, NULL if no error. This is the error mesage for the entire address point like creation failure. Errors for individual fields are handled separately. */
    ErrorMessage: string | null;
}

/** Default value for the Active address point (with no data) */
export const DefaultActiveAddressPoint: AddressPoint = {
    AddressPointId: null,
    Name: null,
    Address: null,
    PickupNotes: null,
    DropoffNotes: null
};

/** Initial state */
export const AddressPointsInitialState: AddressPointsState = {
    ActiveAddressPoint: DefaultActiveAddressPoint,
    List: [],
    UiEditMode: null,
    AddressValidity: {
        ServiceabilityState: {
            status: ServiceCheckStatus.NoInputSelected
        }
    },
    ErrorMessage: null
};

