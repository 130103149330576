import React from "react";
import { useAppState } from "../../Redux/ReduxHooks"
import { Button } from "@mui/material";
import { Dispatch } from "../../modules/Dispatch";

/** Simplest form of error messages. It only contains the message and a button to dismiss the message, no title. Smaller than other various dialogs. */
export const ErrorMessageWithNoTitle: React.FC = () => {

    const errorMessage = useAppState(i => i.dialog.wellKnownErrorMessage);

    return (
        <div className="error-message-base-panel no-title-error-base-panel" >
            <div className="emb-solution-panel error-text">{errorMessage!.SolutionText}</div>
            <div className="emb-btn-panel dismiss-button">
                <Button variant="contained" color="primary" onClick={DismissDialog}>OK</Button>
            </div>
        </div>
    )
}

function DismissDialog() {
    Dispatch.Dialog.CloseTopDialog();
}