import { NameSplit, AuthToken } from "./AuthEntities";

/**
 * This helper function is to parse profile name as first & last name.
 * Profile name has been validated before this function triggered.
 * 
 * Parsing & washing rules:
 * (1) Split by regular expression, such as string(s);
 * (2) Trim each split string;
 * (3) If there is only one element, assign it to "firstName" while leave "lastName" as empty string;
 * (4) If there are, or more than, two elements, assign the last one to "lastName", while the rest as "firstName".
 */
export function ParseWashProfileName(profileName: string): NameSplit {
    let firstName: string = "", lastName: string | undefined;
    const arr = profileName.split(/\s+/);

    if (arr.length === 1) {
        firstName = arr[arr.length - 1];
    }
    else {
        lastName = arr[arr.length - 1];
        arr.pop();
        firstName = arr.join(' ');
    }

    return { FirstName: firstName, LastName: lastName }
}

/**
 * Extract user ID of the user from Auth0 token.
 * Known to be in the following format: "auth0|<stuff>"
 * <stuff> is the bit we are interested in.
 * For 13cabs users, it is the Booking API User ID.
 * For Cabcharge users, it is an ID owned by Auth0. 
 */
export function DeriveUserIdFromAuthToken(token: AuthToken): string {
    return token.sub.split("|")[1];
}