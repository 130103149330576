import React from 'react';
import './ForgotPasswordLink.scss';
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import CredentialsController from "../Login/CredentialsController";
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";

interface MyProps {
    ApiRunningStatus: DataLoadingStatus;
}

/**
 * Clickable link text for the Forgot Password action.
 */
export const ForgotPasswordLink: React.FC<MyProps> = (props) => {

    function OpenForgotPasswordDialog() {
        if (props.ApiRunningStatus !== DataLoadingStatus.Idle) return;
        new CredentialsController().ClosePopup();
        Dispatch.Dialog.ShowDialog(DialogKind.ForgotPassword);
    }

    if (!FeatureFlags.ForgotPassword) return null;

    return (
        <div className="forgot-password-link">
            <p onClick={OpenForgotPasswordDialog}>Help! I forgot my password</p>
        </div>
    );
}