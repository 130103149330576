import { DateTime, Duration } from 'luxon';

/**
 * A session token combines multiple adjacent autocomplete requests into a single unit for costing purposes.
 * As a result, we want to reuse the session token as much as possible.
 * A session ends when there is a place details lookup, or after a period of time.
 */
export namespace GooglePlaceSessionManager {

    /**
     * Returns a session token, creating a new one if required. 
     */
    export function GetSession(): google.maps.places.AutocompleteSessionToken {

        const sessionToUse = ReuseOrMakeNewSession();
        sessionToUse.LastUsedTime = DateTime.now();

        return sessionToUse.Token;
    }

    /** 
     *  Returns the active session, if it is still usable, otherwise null. 
     *  This will be called by the Place details lookup, which expects a previous session to have been created, but still works if there is no such session.
     */
    export function TryGetExistingOnly(): google.maps.places.AutocompleteSessionToken | null {
        if (CanReuseSession()) {
            return currentSession!.Token;
        }
        else {
            return null;
        }
    }

    /** Returns a valid session object, reusing the previous one if possible */
    function ReuseOrMakeNewSession(): GooglePlacesSession {
        if (CanReuseSession()) {
            return currentSession!;
        }

        currentSession = MakeNewSession();
        return currentSession;
    }

    /** 
     *  Call this whenever you do a place ID lookup.
     *  It signifies that the session cannot be used any more.
     */
    export function CloseSession() {

        currentSession = null;
    }

    /** Last used session. Goes back to null when deactivated. */
    let currentSession: GooglePlacesSession | null = null;

    interface GooglePlacesSession {

        /** The session token passed to Google Maps across multiple API calls */
        Token: google.maps.places.AutocompleteSessionToken;

        /** When the session started. This gives an upper bound on the duration. */
        StartTime: DateTime;

        /** When the last API call was made. */
        LastUsedTime: DateTime;
    }

    /** Returns true if it is OK to reuse [currentSession] in a new request */
    function CanReuseSession(): boolean {

        if (currentSession == null) return false;

        // max total: 5 mins
        const totalDuration = currentSession.StartTime.diffNow();

        if (totalDuration > Duration.fromObject({ minutes: 5 })) {
            return false;
        }

        // max idle: 1 min
        const idleDuration = currentSession.LastUsedTime.diffNow();

        if (idleDuration > Duration.fromObject({ minute: 1 })) {
            return false;
        }

        // all checks passed
        return true;
    }

    /** Constructs a new session object */
    function MakeNewSession(): GooglePlacesSession {

        return {
            LastUsedTime: DateTime.now(),
            StartTime: DateTime.now(),
            Token: new google.maps.places.AutocompleteSessionToken(),
        };
    }
}