import React from "react";

interface PersonalDataProps {
    personalDataLabel: string;
    personalDataValue: string;
}


/**
 * Template to display each of the user personal details (name, email etc)
 */
class PersonalData extends React.Component<PersonalDataProps> {
    constructor(props: PersonalDataProps) {
        super(props);
    }

    render() {
        return (
            <div className="personal-data-section">
                <div className="personal-data-label">{this.props.personalDataLabel}</div>
                <div className="personal-data-value">{this.props.personalDataValue}</div>
                <hr className="personal-data-divider"></hr>
            </div>
        );
    }
}

export default PersonalData;

