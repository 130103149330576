import { Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../appState';
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { SignupLinkHandler } from './FeaturesHelper';

interface PropsFromStore {
    LoginStatus: LoginStatusKind;
}

/** Signup link to show for guest user */
export const SignUpLinkCore = (props: PropsFromStore) => {

    const isGuestUser = props.LoginStatus === LoginStatusKind.LoggedOut;

    if (isGuestUser) {
        return <Typography variant="body2" style={{marginBottom:"5px"}}>Not signed up? No problem. Simply click here <Link to={"/"} rel="noopener" className="feature-link" onClick={SignupLinkHandler}>Sign up</Link> to access favourites and more.</Typography>
    } else {
        return null;
    }
    
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        LoginStatus: state.authentication.LoginStatus
    };
}

export const SignUpLink = connect(mapStateToProps)(SignUpLinkCore)
