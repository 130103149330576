import { BookingInfo } from "../MyBookingEntities";
import { Api } from "../../../Services/Api";
import { DoActualRefreshOfBookings } from "./RefreshMyBookings";
import { ConvertLightweightBookingStatusV2ToBookingTrackingDetailsBulk } from "../ConvertAPIContractsToInternalModels";
import appstore from "../../../appStore";
import { BulkBookingStatusUpdateRequest } from "../../../Services/BookingEntitiesV2";
import { Dispatch } from "../../Dispatch";
import { BookingAuthority } from "../../../Services/BookingEntities";

/** Perform a refresh of the specified list of bookings. */
export async function PerformBookingRefreshV2(updateCandidates: BookingInfo[]) {

    // construct the request data
    const lastStatusUpdateTime = appstore.getState().myBookings.LastStatusUpdateRequestTimestamp;
    const bookingKeys = updateCandidates.map<BookingAuthority>(i => ({
        bookingId: i.BookingID,
        hashCode: i.AccessCode,
    }));

    const requestData: BulkBookingStatusUpdateRequest = {
        LastRequestedTimestamp: lastStatusUpdateTime,
        BookingKeys: bookingKeys
    }

    // service call to get new data
    const serviceResult = await Api.BookingV2.GetBulkBookingUpdates(requestData);

    // abort on error
    if (!serviceResult.isSuccess) {
        return;
    }

    // Note the time when the request was sent (to be used in the next request as input).
    // Store and send this value as it is and no conversion is needed since we don't use it in the client.
    Dispatch.MyBookings.RefreshLastBulkStatusUpdateTime(serviceResult.value.CurrentTimestamp);

    const allUpdates = ConvertLightweightBookingStatusV2ToBookingTrackingDetailsBulk(serviceResult.value.BookingStatusUpdates);
    DoActualRefreshOfBookings(updateCandidates, allUpdates);
}
