import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { PayPalInitialState, SdkLoadingState, PayPalState } from "./PayPalState";

/** Redux store slice for the PayPal data. */
const slice = new ReduxStoreSlice<PayPalState>("PayPal", PayPalInitialState);

/** Redux action dispatcher for the PayPal store slice. */
export const PayPalDispatch = {

    /** The UI flow has progressed far enough to warrant loading the SDK. */
    ReadyToLoad: slice.EmptyAction("Ready To Load", ReadyToLoad),

    /** The PayPal SDK is now being loaded asynchronously. */
    LoadStarting: slice.EmptyAction("Load Starting", LoadStarting),

    /** The PayPal SDK load completed successfully. */
    LoadSucceeded: slice.EmptyAction("Load Succeeded", LoadSucceeded),

    /** The PayPal SDK load failed with an error. */
    LoadFailed: slice.EmptyAction("Load Failed", LoadFailed),
}

/** Redux reducer for the PayPal store slice. */
export const PayPalReducer = slice.MakeCombinedReducer();

/** The UI flow has progressed far enough to warrant loading the SDK. */
function ReadyToLoad(state: PayPalState): PayPalState {

    if (state.LoadingStatus === SdkLoadingState.NotRequested) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.RequestedNotStarted,
        };
    }

    // update not supported from this state
    return state;
}

/** The PayPal SDK is now being loaded asynchronously. */
function LoadStarting(state: PayPalState): PayPalState {

    if (state.LoadingStatus === SdkLoadingState.RequestedNotStarted) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.InProgress,
        };
    }

    // update not supported from this state
    return state;
}

/** The PayPal SDK load completed successfully. */
function LoadSucceeded(state: PayPalState): PayPalState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedSuccess,
        };
    }

    // update not supported from this state
    return state;
}

/** The PayPal SDK load failed with an error. */
function LoadFailed(state: PayPalState): PayPalState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedError,
        };
    }

    // update not supported from this state
    return state;
}