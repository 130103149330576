import moment from "moment";
import { BookingAuthority, BookingDataOwnership } from "../../../Services/BookingEntities";
import appstore from "../../../appStore";
import { Api } from "../../../Services/Api";
import { BookingInfo } from "../MyBookingEntities";

/** The user has just logged in or signed up. Gather any suitable bookings and move them to our user. */
export async function PromoteGuestBookings(): Promise<void>{

    const allBookings = appstore.getState().myBookings.All;
    const candidates = allBookings.filter(IsSuitableForPromotion);

    // quick exit: no work
    if (candidates.length == 0) return;

    const bookingKeys = candidates.map<BookingAuthority>(i => ({
        bookingId: i.BookingID,
        hashCode: i.AccessCode,
    }));

    const result = await Api.Booking.TakeOwnershipOfGuestBookings(bookingKeys);

    // keep going on error, but log
    if (!result.isSuccess) {
        appInsights.trackEvent("Take Ownership Failed", { outcome: result.outcome });
    }
}

/**
 * Returns true if the booking is fine to be promoted.
 */
function IsSuitableForPromotion(booking: BookingInfo): boolean {

    // not mine
    if (booking.DataOwnership === BookingDataOwnership.Test) return false;
    if (booking.DataOwnership === BookingDataOwnership.User) return false;
    if (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;

    // too old. 
    // TODO: fix MGMT so we don't need to use this dodgy format string
    const requestedTime = moment(booking.Time, "MM/DD/YYYY HH:mm:SS"); 

    // Note: we're mainly relying on normal mechanisms that remove old bookings from redux over time
    // The check here is just a safety check to stop super-old bookings being affected
    const cutoffTime = moment().startOf("day").add(-1, "days");
    if (requestedTime < cutoffTime) return false;

    // OK!
    return true;
}