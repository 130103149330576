import React from "react";
import "./MyBookings.scss";
import ListOfBookingCards from "./ListOfBookingCards";

/** Component which contains the list of bookings as cards. This is the second column of the Schedule View tab. */
export const MyBookingsList: React.FC = () => {
    return (
        <div className="my-bookings-list-panel">
            <div className="my-bookings-header-section">
                <h3 className="my-bookings-header">Pending pick-up</h3>
            </div>
            <div className="my-bookings-list">
                <ListOfBookingCards />
            </div>
        </div>      
    );    
}