import React from "react";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { AccountDetails } from "../Account/AccountDetails";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { useAppState } from "../../../Redux/ReduxHooks";

/**
 * Possibly renders the Account Details component in the booking form.
 * It only appears when the user has enabled the "Book on Account" toggle option.
 * Supports mobile and desktop layouts.
 */
export const MaybeAccountDetails: React.FC = () => {

    const isBookingOnAccount = useAppState(state => CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile));

    if (!isBookingOnAccount) return null;

    return (
        <BookingFormGroup WithStepLabel={FormStepLabel.AccountDetails}>
            <AccountDetails />
        </BookingFormGroup>
    );
}