import { VerificationTrigger, UserContactNumberInfo } from "./VerificationEntities";

/** Redux state for the "Verification" slice */
export interface VerificationState {

    /** The user's contact phone number. */
    UserContactNumberInfo: UserContactNumberInfo;

    /** True to show a spinner UI in the Contact Details form. */
    IsLoaderShownInContactDetails: boolean;

    /** What caused the phone verification UI to show: booking creation or signup. */
    Trigger: VerificationTrigger;

    /** 
     * The previous successful verification code that hasn't been consumed yet.
     * In simple use cases this is not populated because the challenge will get used immediately.
     * It is populated in the Guest Booking flow where there is a decent separation (in terms of code) between the successful verification and the CreateBooking API call.
     * It supports retries of the CreateBooking API call without re-running the verification where possible.
     */
    SuccessfulUnusedCode: string | null;

    /** The ID of the most recent SMS Verification challenge started. */
    SmsChallengeId: string | null;

    /** The phone number actually provided to the SMS verification challenge. E.164 format including leading "+". */
    PhoneNumberUnderChallenge: string | null;

    /** How many times in a row the user entered the verification code for mobile number verification. */
    VerificationAttemptCount: number;
}

/** Initial value of Verification */
export const InitVerificationState: VerificationState = {
    UserContactNumberInfo: {},
    IsLoaderShownInContactDetails: false,
    Trigger: VerificationTrigger.Booking,
    SuccessfulUnusedCode: null,
    SmsChallengeId: null,
    PhoneNumberUnderChallenge: null,
    VerificationAttemptCount: 0
};