import { DialogKind, WellKnownErrorMessage, DetailedErrorMessage, RetryErrorMessage } from "./DialogEntities";

/** Compound type representing all possible Dialog actions. This is the input to the reducer. */
export type DialogAction = ShowDialogAction | CloseDialogAction | CloseTopDialogAction | SetSimpleErrorMessageAction | CleanSimpleErrorMessageAction | SetDescriptiveErrorMessageAction | SetRetryErrorMessageAction | CleanRetryErrorMessageAction;

/** A request to show a particular dialog */
export interface ShowDialogAction {
    type: DialogActionKind.ShowDialog;
    dialog: DialogKind;
}

/** A request to close a particular dialog */
export interface CloseDialogAction {
    type: DialogActionKind.CloseDialog;
    dialog: DialogKind;
}

/** Close / cancel out of the topmost dialog */
export interface CloseTopDialogAction {
    type: DialogActionKind.CloseTopDialog;
}

/** Set simple error message object only when needed */
export interface SetSimpleErrorMessageAction {
    type: DialogActionKind.SetSimpleErrorMessage;
    payload: WellKnownErrorMessage;
}

/** Set re-try error message object only when needed */
export interface SetRetryErrorMessageAction {
    type: DialogActionKind.SetRetryErrorMessage;
    payload: RetryErrorMessage;
}

/** Clean simple error message object only when needed */
export interface CleanSimpleErrorMessageAction {
    type: DialogActionKind.CleanSimpleErrorMessage;
}

/** Clean re-try error message object only when needed */
export interface CleanRetryErrorMessageAction {
    type: DialogActionKind.CleanRetryErrorMessage;
}

/** Set more detailed error message. These messages include Title, image, description and different action buttons. */
export interface SetDescriptiveErrorMessageAction {
    type: DialogActionKind.SetDescriptiveErrorMessage;
    errorProps: DetailedErrorMessage;
}

/** This is the [type] field on the Dialog actions. */
export enum DialogActionKind {

    /** Open a new dialog */
    ShowDialog = 'Dialog: Show',

    /** Close a specific, existing dialog */
    CloseDialog = 'Dialog: Close',

    /** Close / cancel out of the topmost dialog */
    CloseTopDialog = 'Dialog: Close Top',

    /** Set simple error message object only when needed */
    SetSimpleErrorMessage = "Error message: Set simple error message",

    /** Set re-try error message object only when needed */
    SetRetryErrorMessage = "Error message: Set re-try error message",

    /** Clean simple error message object only when needed */
    CleanSimpleErrorMessage = "Error message: Clean simple error message",

    /** Set more detailed error message. */
    SetDescriptiveErrorMessage = "Set descriptive error message",

    /** Clean re-try error message object only when needed */
    CleanRetryErrorMessage = "Error message: Clean re-try error message"
}