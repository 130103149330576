import { BookingWithEta, GetEffectiveEta } from "./BookingWithEta";
import { BookingStatus, ApiGeoPoint } from "../../../Services/BookingEntities";
import { Duration } from "moment";
import { MinRefreshInterval, MaxRefreshInterval } from "./Parameters";
import { GetCurrentTime } from "../../Utils/GetCurrentTime";
import { CanDeferEtaRefreshDueToLackOfMovement } from "./CanDeferEtaRefresh";

/** Returns true if this booking is available for an ETA refresh.
 *  It must be in the right status, and have a known start and finish point for the ETA. 
 *  Also must not have had an ETA refresh recently. */
export function DoesEtaNeedRefresh(booking: BookingWithEta): boolean {

    // only for accepted / pickedup
    const statusId = booking.Booking.TrackingInfo.BookingStatusID;
    if ((statusId != BookingStatus.Accepted) && (statusId != BookingStatus.PickedUp)) return false;

    // vehicle position must be known
    const vehicleLocation = booking.Booking.TrackingInfo.VehicleLocation;

    if (!vehicleLocation) return false;

    // these values to check depend on the status
    let refreshInterval: Duration;
    let requiredLocation: ApiGeoPoint | null;

    if (statusId == BookingStatus.Accepted) {

        // Accepted: shorter timer, needs pickup location
        requiredLocation = booking.Booking.Locations[0].GeoPoint;
        refreshInterval = MinRefreshInterval.Accepted;
    }
    else {
        // Picked Up: longer timer, needs dropoff location
        requiredLocation = booking.Booking.Locations.length > 1 ? booking.Booking.Locations[booking.Booking.Locations.length - 1].GeoPoint : null;
        refreshInterval = MinRefreshInterval.PickedUp;
    }

    // common checks for accepted and picked up
    if (!requiredLocation) return false;
    if (IsExistingEtaStillCurrent(booking, refreshInterval)) return false;

    // OK!!!
    return true;
}

/** Returns true if the specified ETA was loaded recently, and thus does not need a refresh. */
export function IsExistingEtaStillCurrent(details: BookingWithEta, refreshInterval: Duration): boolean {

    // no existing ETA
    const actualEta = GetEffectiveEta(details);
    if (!actualEta) return false;

    // always refresh at the max limit (e.g. 120s)
    const dataAgeMillis: number = GetCurrentTime().diff(actualEta.TimeOfCalculation);
    if (dataAgeMillis > MaxRefreshInterval.asMilliseconds()) return false;

    // never refresh below the min limit (e.g. 30s)
    if (dataAgeMillis < refreshInterval.asMilliseconds()) return true;

    // don't refresh if car is not really moving
    if (CanDeferEtaRefreshDueToLackOfMovement(details, refreshInterval, actualEta)) return true;

    // has moved a bit, time has elapsed quite a bit: need refresh
    return false;
}