
/** What happened during ConsiderFareUpdate() or ConsiderConditionUpdate() */
export enum AsyncUpdateOutcome {

    /** The API was not called because the inputs were not ready, e.g. missing destination */
    InputsNotReady,

    /** The inputs were not changed from last time, so a new request was not performed */
    InputsUnchanged,

    /** The API was called, but during this time the inputs were changed, so a new request will be running in parallel. 
     * As a result, these results were discarded. */
    InputChangedDuringLoad,

    /** The API was called but returned an error. */
    LoadFailed,

    /** The API was called and ran successfully. */
    Success
}