import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrl, Post } from './ServiceManager';
import { PaymentCard, MpsProfile } from './PaymentEntities';

/** Base URL for the PaymentController in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Payment/`;
}

/** HTTP API Calls to the PaymentController in the Booking Management Service. */
class PaymentServiceClass {

    /** Get the user's registered payment cards. Only meaningful for signed in users. */
    public GetCards(): Promise<ServiceResult<PaymentCard[]>> {
        const url = `${GetBaseUrl()}GetCards`;
        return Post<PaymentCard[]>(url, null);
    }

    /** Get the user's Mps profile.*/
    public GetMpsProfile(): Promise<ServiceResult<MpsProfile>> {
        const url = `${GetBaseUrl()}GetMpsProfile`;
        return Post<MpsProfile>(url, null);
    }

    /** Delete a payment card*/
    public DeleteCard(cardId: string): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}DeleteCard`;
        return Post<null>(url, `"${cardId}"`);
    }

    /** Edit a payment card*/
    public EditCard(card: PaymentCard): Promise<ServiceResult<PaymentCard>> {
        const url = `${GetBaseUrl()}EditCard`;
        return Post<PaymentCard>(url, card);
    }
}

/** Singleton export for static access to the operations */
export const PaymentService = new PaymentServiceClass();