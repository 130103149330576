import { TncPrivacyActionTypes, TncPrivacyActions } from "./TncPrivacyActions";
import { TncInfoResponse } from "../../Services/TncEntities"; 
import appstore from "../../appStore";
import { WebPage } from "./TncPrivacyEntities";

/** Helper class to dispatch TnC actions. It's like an action creator except that it also dispatches the actions. */
export class TncDispatcher {

    public OnTncInfoChange(payload: TncInfoResponse): void {
        dispatch({
            type: TncPrivacyActionTypes.TncInfoOnChange,
            payload
        });
    }

    public OnTncContentChange(payload: string): void {
        dispatch({
            type: TncPrivacyActionTypes.TncContentOnChange,
            payload
        });
    }

    public OnPrivacyInfoChange(payload: WebPage): void {
        dispatch({
            type: TncPrivacyActionTypes.PrivacyInfoOnChange,
            payload
        });
    }
}

/** This function is deliberately put outside the class to avoid issues with "this"
 *  Its purpose is to type-check the actions as TncActions.
 */
function dispatch(action: TncPrivacyActions) {
    appstore.dispatch(action);
}