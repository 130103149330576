import { Switch, SwitchProps, styled } from "@mui/material";
import React from "react";

const uncheckColor = "#979797";

/** Custom switch based on MUI Switch component which can be reusable in the places that require this specific look and feel. Customised to look similar to iOS switch.  */
export const IosSwitch = styled((props: SwitchProps) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            color: "#fff",
            transform: "translateX(16px)",
            "& .MuiSwitch-thumb:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(theme.palette.secondary.main)}" stroke="${encodeURIComponent(theme.palette.secondary.main)}" stroke-width="2" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
            },
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.secondary.main,
                opacity: 1,
                border: `1px solid ${theme.palette.secondary.main}`,
                height: "24px"
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5
            },
            "& .MuiSwitch-thumb": {
                backgroundColor: "#fff"
            }
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.secondary.main,
            border: "6px solid #fff"
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600]
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3
        }
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        backgroundColor: uncheckColor,
        boxShadow: "none",
        "&:before": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            left: 0,
            top: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="white" stroke="white" stroke-width="2" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg>')`
        }
    },
    "& .MuiSwitch-track": {
        borderRadius: 28 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E4E4E4" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500
        }),
        border: `1px solid ${uncheckColor}`,
        height: "24px"
    }
}));

export default IosSwitch;
