import React from 'react';
import { ParcelLinkHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { Link } from 'react-router-dom';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import { Typography } from '@mui/material';
    
export const ParcelCard = (props: FeatureCardProps) => {
    return (
        <React.Fragment key={FeatureId.Parcel}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>04.06.2020</span>
                </div>
                <Typography variant="h6" color="primary">NEED A THING DELIVERED NOW?</Typography>
                <Typography variant="body2" gutterBottom>From grocery things to pharmacy things and all sorts of other things, we’ll bring!</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.parcelFeature)} alt="Parcel" />
                </div>
                <Typography variant="body2" gutterBottom>Simply click here <Link to={"/"} rel="noopener" className="feature-link" onClick={ParcelLinkHandler}>Parcel Delivery</Link> or choose from ‘Select your service’ when booking next.</Typography>
                    <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}