import { ServiceResult } from './ServiceEntities';
import { Post, GetManagementServiceRootUrlWithoutState } from './ServiceManager';
import { BookingInfoV2, BulkBookingStatusUpdateResponse, BulkBookingStatusUpdateRequest, BookingVehicleLocation, DriverPhoto } from "./BookingEntitiesV2";
import { MyBookingsRequest, BookingAuthority } from './BookingEntities';

/** Base URL for the BookingV2Controller in the Booking Management Service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}BookingV2/`;
}

/** Client for the BookingController in the Management API */
class BookingServiceV2Class {    

    /** Get booking details using booking ID. */
    public GetBookingDetails(bookingKey: BookingAuthority): Promise<ServiceResult<BookingInfoV2>> {
        const url = `${GetBaseUrl()}GetBookingDetails`;
        return Post<BookingInfoV2>(url, bookingKey);
    }

    /** Cancel a booking using booking ID. */
    public CancelBooking(bookingKey: BookingAuthority): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}Cancel`;
        return Post<void>(url, bookingKey);
    }

    /** Lightweight booking status updates for multiple bookings. */
    public GetBulkBookingUpdates(request: BulkBookingStatusUpdateRequest): Promise<ServiceResult<BulkBookingStatusUpdateResponse>> {
        const url = `${GetBaseUrl()}BookingStatusBulk`;
        return Post<BulkBookingStatusUpdateResponse>(url, request);
    }

    /** Load user's (currently logged in user) bookings. */
    public GetMyBookings(request: MyBookingsRequest): Promise<ServiceResult<BookingInfoV2[]>> {
        const url = `${GetBaseUrl()}GetMyBookings`;
        return Post<BookingInfoV2[]>(url, request);
    }

    /**
     * Check the validity (only) of a booking link.
     */
    public CheckLink(link: BookingAuthority): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}CheckLink`;
        return Post<void>(url, link);
    }

    /**
     * Load one or more bookings by their links.
     */
    public GetBookingsByLinkBulk(links: BookingAuthority[]): Promise<ServiceResult<BookingInfoV2[]>> {
        const url = `${GetBaseUrl()}GetByLinkBulk`;
        return Post<BookingInfoV2[]>(url, links);
    }

    /**
     * Vehicle locations only, for multiple bookings.
     * Data will only be available for bookings in the Accepted or PickedUp statuses.
     */
    public GetBookingVehicleLocations(links: BookingAuthority[]): Promise<ServiceResult<BookingVehicleLocation[]>> {
        const url = `${GetBaseUrl()}BookingVehicleLocations`;
        return Post<BookingVehicleLocation[]>(url, links);
    }

    /**
     * Get the driver photo of the driver assigned to a booking.
     */
    public GetBookingDriverPhoto(bookingKey: BookingAuthority): Promise<ServiceResult<DriverPhoto>> {
        const url = `${GetBaseUrl()}BookingDriverPhoto`;
        return Post<DriverPhoto>(url, bookingKey);
    }
}

/** Singleton export for static access to the operations */
export const BookingServiceV2 = new BookingServiceV2Class();