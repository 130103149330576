import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import './ErrorMessaging.scss';
import { RetryErrorMessage, TryAgainButtonOnClickActionKind } from '../../modules/Dialog/DialogEntities';
import ErrorMessagingProps from './ErrorMessagingProps';
import { Dispatch } from '../../modules/Dispatch';
import CredentialsController from '../../modules/Authentication/Login/CredentialsController';
import { RouteUrls } from '../../RouteUrls';
import { LoadAllVehicles } from '../../modules/Condition/LoadAllVehicles';

interface PropsFromStore {
    reTryErrorMessage: RetryErrorMessage;
}

class RetryErrorMessaging extends Component<PropsFromStore> {
    onOKClicked() {
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.Dialog.CleanRetryErrorMessage();
    }

    /**
     * This RetryButton function:
     *     1) Generate a button named 'Try again' in conponent RetryErrorMessaging;
     *     2) It's capability is to close off component RetryErrorMessaging, and trigger specific action based on the value of TryAgainButtonOnClickActionKind;
     *     3) It will be injected into component ErrorMessagingBase.
     */
    private RetryButton(): JSX.Element {
        return(<button className="error-message-popup-btn" type="button" onClick={() => this.onReTryClicked()}>Try again</button>);
    }

    onReTryClicked() {
        this.onOKClicked();
        if (this.props.reTryErrorMessage.OnClickAction === TryAgainButtonOnClickActionKind.Auth0LogIn) {
            new CredentialsController().DoLogin();
        }
        else if (this.props.reTryErrorMessage.OnClickAction === TryAgainButtonOnClickActionKind.RefreshTncPage) {
            window.location.href = RouteUrls.TermsAndConditions;
        }
        else if (this.props.reTryErrorMessage.OnClickAction === TryAgainButtonOnClickActionKind.RefreshPrivacyPage) {
            window.location.href = RouteUrls.PrivacyPolicy;
        }
        else if (this.props.reTryErrorMessage.OnClickAction === TryAgainButtonOnClickActionKind.ReloadVehicles) {
            LoadAllVehicles();
        }
    }

    render() {
        return(
            <ErrorMessagingProps
                ProblemText = {this.props.reTryErrorMessage.WellKnownErrorMessage.ProblemText}
                SolutionText = {this.props.reTryErrorMessage.WellKnownErrorMessage.SolutionText}
                Button = {this.RetryButton()}
                IsCloseButtonShown = {false}
            />
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        /**
         * This ! assertion is guaranteed by ./ErrorMessagingHelper.ShowDialogRetryErrorMessage(RetryErrorMessage)
         * The sequential dispatches guarantees that reTryErrorMessage has value when this popup mounts
         */
        reTryErrorMessage: state.dialog.reTryErrorMessage!
    };
}

export default connect(mapStateToProps)(RetryErrorMessaging);