import React from "react";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import DeliveryOptions from "../Parcel/DeliveryOptions";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { useAppState } from "../../../Redux/ReduxHooks";
import { OptionalUI } from "./OptionalUI";

/**
 * Possibly renders the Delivery Option selector in the booking form.
 * It only appears in parcel mode and for account bookings.
 */
export const MaybeDeliveryOptions: React.FC = () => {

    const isRendered = useAppState(OptionalUI.DeliveryOption);
    if (!isRendered) return null;

    return (
        <BookingFormGroup WithStepLabel={FormStepLabel.DeliveryOptions}>
            <DeliveryOptions />
        </BookingFormGroup>
    );
}