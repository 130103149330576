import React, { FC } from "react";
import { ApplicationState } from "../../../appState";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { connect } from "react-redux";

/** 
 * A pair of related inputs in the contact us form. For example first name or last name.
 * In desktop layout, we'll put them on the same line.
 * In mobile layout they will go onto separate lines to make sure there is enough space.
 */
const InputPairCore: FC<MyProps> = (props) => {

    // mobile
    if (props.IsMobile) {
        return (
            <>
                {props.children}
            </>
        );
    }

    // desktop
    return (
        <div className="desktop-input">
            {props.children}
        </div>
    );
};

interface MyProps {
    IsMobile: boolean;
}

function GetMyPropsFromStore(state: ApplicationState): MyProps {
    return {
        IsMobile: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export const InputPair = connect(GetMyPropsFromStore)(InputPairCore);