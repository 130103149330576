import { IsQuotaAvailable, NoteQuotaUsage } from "./RequestQuota";
import appstore from "../../../appStore";
import { BookingWithEta } from "./BookingWithEta";
import { DoesEtaNeedRefresh } from "./DoesEtaNeedRefresh";
import { GetTopPriorityEtaRefresh } from "./GetTopPriorityEtaRefresh";
import { PerformEtaRefresh } from "./PerformEtaRefresh";
import { ExtendedEta } from "../MyBookingEntities";

/** Update the ETA of one of the active bookings if there is a suitable pending booking and quota available. 
  Docs: https://cabcharge.atlassian.net/wiki/spaces/CA/pages/912949892/Background+Booking+ETA+Refresh 
  Returns the ETA loaded from this method call, or null if there is no load. */
export async function ConsiderRefreshingEtas(): Promise<ExtendedEta | null> {

    // quota
    if (IsQuotaAvailable() == false) return null;

    // combined Booking + ETA data
    const myBookings = appstore.getState().myBookings;
    const existingEtaLookup = myBookings.Etas;

    const combinedBookings = myBookings.All.map<BookingWithEta>(i => ({
        Booking: i,
        Eta: existingEtaLookup[i.BookingID],
    }));

    // worthy of refresh
    const candidates = combinedBookings.filter(DoesEtaNeedRefresh);
    if (candidates.length === 0) return null;

    // pick the most deserving
    const topCandidate = GetTopPriorityEtaRefresh(candidates);

    // go!
    NoteQuotaUsage();
    return await PerformEtaRefresh(topCandidate);
}