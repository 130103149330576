import React from "react";
import { PublicAddressPickerProps } from "../Entities/AddressPickerProps";
import { AddressPickerV2 } from "./AddressPickerV2";
import { LoadGooglePlaceDetails } from "../../Booking/BookingLocationHelper";
import { AddressPickerEntry } from "../Entities/AddressPickerEntry";
import { ComboAddress } from "../Entities/ComboAddress";
import { GoogleMapScriptLoader } from "../../../widgets/google-maps/GoogleMapScriptLoader";

/**
 * Public component for the Google Maps Autocomplete.
 * It manages the Google Maps script load and place details selection, and hides most of the details in the internal component.
 */
export const GoogleAddressPicker: React.FC<PublicAddressPickerProps> = (props) => {

    return (
        <GoogleMapScriptLoader>
            <AddressPickerV2
                LocationType={props.LocationType}
                PreferNearbyTo={props.PreferNearbyTo}
                PlaceholderText={props.PlaceholderText}
                OnPlaceSelected={OnPlaceSelected}
                LabelText={props.LabelText}
                SpecifiedPlaceText={props.SpecifiedPlaceText}
                OnCleared={props.OnCleared}
                StartIconUrl={props.StartIconUrl}
                IsSelectedAddressInvalid={props.IsSelectedAddressInvalid}
                AutoFocus={props.AutoFocus}
                IncludeFavouriteAddresses={props.IncludeFavouriteAddresses}
                HideClearButton={props.HideClearButton}
                IsDraggable={props.IsDraggable}
            />
        </GoogleMapScriptLoader>
    );

    /**
     * The internal component gives a raw entry which is a union type.
     * Convert it to a standard place result.
     */
    async function OnPlaceSelected(entry: AddressPickerEntry) {

        const placeId = ComboAddress.GetPlaceId(entry);
        var placeResult = await LoadGooglePlaceDetails(placeId);

        // TODO: do we need to/how do we handle this?
        if (!placeResult) return;

        const preferredText = ComboAddress.GetPrimaryDisplayText(entry)
        props.OnPlaceSelected(placeResult, preferredText, entry);
    }
}