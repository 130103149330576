import { UserProfileNavbarItem,UserProfileNavBarNames } from "./UserProfileNavEntities";

export interface UserProfileNavState {

    /* Selected nav item */
    UserProfileSelectedNav: UserProfileNavbarItem
}

/** 
 * Initial value of the NAV state. The values will be empty initially. 
*/
export const DefaultNavState: UserProfileNavState = {
    UserProfileSelectedNav: {
        Name: UserProfileNavBarNames.None,
        Title: "",
        Image:""       
    }
};