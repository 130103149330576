import { ServiceCheckStatus, IndeterminateServiceCheck, PickupServiceCheckState } from "./BookingEntities";
import { ServiceCallOutcome, ServiceResult } from "../../Services/ServiceEntities";
import { AddressLookupResult, CheckCabServiceRequest } from "../../Services/LocationEntities";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { CheckForCabServiceErrors } from "./CheckCabServiceAvailabilityV2";
import { LogEvent } from "../../utils/LogEvent";
import appstore from "../../appStore";
import { OptionalUI } from "./OptionalParts/OptionalUI";

/** 
 *  Begins a cab service check for the specified pickup address (Google Maps place ID).
 */
export async function CheckCabServiceAsync(place: google.maps.places.PlaceResult) {
    // start as pending
    const pendingStatus: IndeterminateServiceCheck = {
        status: ServiceCheckStatus.CheckInProgress
    };
    Dispatch.Booking.PickupServiceability({ PickupPlaceId: place.place_id!, ServiceabilityCheckState: pendingStatus });

    // do the work
    const request: CheckCabServiceRequest = {
        GooglePlaceDetail: place,
        GoogleOdrdTripId: null, // populated below
    };

    // ODRD Trip ID
    const state = appstore.getState();

    if (OptionalUI.SendOdrdTripId(state)) {
        request.GoogleOdrdTripId = state.booking.GoogleOdrdTripId;
    }

    const result = await Api.Location.CheckCabServiceByPlaceDetail(request);
    const newCheckState = ProcessServiceabilityResult(result);
    CheckForCabServiceErrors(newCheckState, place);

    Dispatch.Booking.PickupServiceability({ PickupPlaceId: place.place_id!, ServiceabilityCheckState: newCheckState });
}

/** There are just lots of cases to convert the result of the service call from CheckCabService into a PickupServiceCheckUpdateAction. */
export function ProcessServiceabilityResult(result: ServiceResult<AddressLookupResult>): PickupServiceCheckState {

    // well-known API error
    if (result.outcome == ServiceCallOutcome.ApplicationError) {

        const errorMessage = result.apiError.Summary.FullMessage;

        /**
         * This was requested by Canmeron to to handle like "location not named specifically enough" for only this case in well-known API error;
         * I.e. "Wagga wagga"
         * Or, should we hanble this in booking management?
         */
        if (/To ensure we get an accurate pickup address/i.test(errorMessage)) {
            return {
                status: ServiceCheckStatus.KnownBad,
                errorMessage: WellKnownMessageKind.StreetNameUnavailable,
                isPickupErrorMessageShown: true,
                internalErrorMessage: errorMessage
            };
        }
        else {            
            return {
                status: ServiceCheckStatus.KnownBad,
                errorMessage: WellKnownMessageKind.GeneralFailure,
                isPickupErrorMessageShown: false,
                internalErrorMessage: errorMessage
            };
        }
    }

    // unanticipated error
    if (!result.isSuccess) {
        return {
            status: ServiceCheckStatus.Error,
            errorMessage: WellKnownMessageKind.GeneralFailure,
            isPickupErrorMessageShown: false,
            internalErrorMessage: result.outcome == ServiceCallOutcome.NoResponseFromServer ? ServiceCallOutcome.NoResponseFromServer : "An unexpected error occured"
        };
    }

    // area not serviceable
    if (!result.value.IsServiceable) {
        return {
            status: ServiceCheckStatus.KnownBad,
            errorMessage: WellKnownMessageKind.NoServiceAvailable,
            isPickupErrorMessageShown: false,
            internalErrorMessage: WellKnownMessageKind.NoServiceAvailable.ProblemText
        };
    }

    // location not named specifically enough
    if (!result.value.PlaceName && !result.value.StreetName) {
        return {
            status: ServiceCheckStatus.KnownBad,
            errorMessage: WellKnownMessageKind.StreetNameUnavailable,
            isPickupErrorMessageShown: true,
            internalErrorMessage: WellKnownMessageKind.StreetNameUnavailable.ProblemText
        };
    }

    // OK!
    LogEvent.CheckPickupAddressAvailabilitySuccess();

    return {
        status: ServiceCheckStatus.KnownGood,
        suburbId: result.value.SuburbId,
        TimeZoneId: result.value.TimeZoneId,
    };
}