import React from 'react';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import { SignUpLink } from './SignUpLink';
import { Typography } from '@mui/material';

export const DriverProfile = (props: FeatureCardProps) => {

    return (
        <React.Fragment key={FeatureId.DriverProfile}>
            <div className="feature-card">
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>21.09.2020</span>
                </div>
                <Typography variant="h6" color="primary">DRIVER PROFILE PICTURES</Typography>
                <Typography variant="body2" gutterBottom>When your cab's on the way, put a face to your driver's name.</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.DriverProfile)} alt="Driver Profile" />
                </div>
                    <SignUpLink />
                    <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}