import { GetManagementServiceRootUrl, Get, Post } from "./ServiceManager";
import { ServiceResult } from "./ServiceEntities";
import { ProfileChanges, ResetPasswordRequest } from "./UserEntities";
import { TncInfoResponse } from "./TncEntities";
import { WebPage } from "../modules//TncPrivacy/TncPrivacyEntities";
import { SimpleUserProfileBase } from "../modules/User/ProfileEntitiesV2";

/** Base URL for the FareController in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}User/`;
}

/** Client for the UserController in the Management API */
class UserServiceClass {

    /** Send an SMS to my phone when the booking is confirmed. Note: there is no payload beyond ServiceResult (the .value field won't be populated). */
    public SendBookingConfirmationSms(bookingId: number, dispatchBookingId: number, phoneNumber: string): Promise<ServiceResult<void>> {
        const data = {
            BookingId: bookingId,
            DispatchBookingID: dispatchBookingId,
            PhoneNumber: phoneNumber,
        };

        const url = `${GetBaseUrl()}SendConfirmation`;
        return Post<void>(url, data);
    }

    /** 
     *  Load the signed in user's profile in the simplified V2 format, which supports multiple sources of user data.
     *  This important API comes in the middle of the login process, so it has an extended timeout.
     */
    public GetMyProfile(): Promise<ServiceResult<SimpleUserProfileBase>> {
        
        const url = `${GetBaseUrl()}GetMyProfile`;
        return Post<SimpleUserProfileBase>(url, null);
    }

    /** 
     * Update user profile.
     * Only support editing contact number for now.
     * */
    public UpdateProfile(profile: ProfileChanges): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}UpdateProfile`;
        return Post<null>(url, profile);
    }

    /**
     * Request to get the one-source content page for tnc
     */
    public GetTncContent(): Promise<ServiceResult<TncInfoResponse>> {
        const url = `${GetBaseUrl()}GetTncContent`;
        return Get<TncInfoResponse>(url);
    }

    /**
     * Request to get the tnc content
     */
    public ReadTncContentFromFile(): Promise<ServiceResult<string>> {
        const data = `${GetBaseUrl()}GetTermsAndConditions`;
        return Get<string>(data);
    }

    /** 
     * This function is to add a consent of tnc to table dbo.UserTncAgreement, via booking API (MGMT), per userId and tncId;
     * This is only for authenticated user;
     * TincId is retrieved from GetTncContent().
     * */
    public AddUserTncAgreement(tncId: number): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}AddUserTncAgreement?tncId=${tncId}`;
        return Get<null>(url);
    }

    /**
     * Request to get 13cabs privacy page content
     */
    public GetPrivacyPageContent(): Promise<ServiceResult<WebPage>> {
        const url = `${GetBaseUrl()}GetPrivacyPageContent`;
        return Get<WebPage>(url);
    }

    /** 
     * This is to verify whether a user exists or not based on username
     */
    public CheckUserExistenceByUserName(username: string): Promise<ServiceResult<null>> {
        const url = `${GetBaseUrl()}CheckUserExistenceByUserName?username=${encodeURIComponent(username)}`;
        return Get<null>(url);
    }

    /** 
     * Reset password old password & new password in request body
     */
    public ResetPassword(passwords: ResetPasswordRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}ResetPassword`;
        return Post<void>(url, passwords);
    }
}

/** Singleton export for static access to the operations */
export const UserService = new UserServiceClass();