import type { Identifier, XYCoord } from 'dnd-core';
import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import React from 'react';
import BookingAddressComponent from '../../Booking/PerLocation/BookingAddressComponent';
import { BookingLocationInfo } from '../../Booking/Redux/BookingState';
import { ConsiderFareUpdate } from '../../Condition/FareLoader';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Dispatch } from '../../Dispatch';

export interface LocationProps {

    /** A unique ID to identify the item being dragged. We are using the entire BookingLocationInfo object here since we don't have a unique ID (we can use GoogleMapsPlaceId or something, but it's not reliable, especially when a location is empty, it can cause errors)*/
    LocationId: BookingLocationInfo;

    /** Current index of the location being dragged in the draggable locations array. This is not the actual index of the location in the booking.Locations array in Redux store. */
    LocationIndex: number;

    /** Callback function to be called when moving an item. Used to update the state. */
    MoveLocation: (dragIndex: number, hoverIndex: number) => void;

    /** Callback function to be called after an item is dropped to a new location. */
    OnDropItem: (item: BookingLocationInfo, index: number) => void;
}

/** These props are required by the react-dnd package. */
interface DragItem {
    index: number;
    id: BookingLocationInfo;
}

/** A draggable address input field. It is implemented using 'react-dnd' npm package. */
export const DraggableAddress: React.FC<LocationProps> = (props) => {
    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<
        DragItem,
        void,
        { handlerId: Identifier | null }
    >({
        accept: 'location',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = props.LocationIndex;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            /* Only perform the move when the mouse has crossed half of the items height.
             When dragging downwards, only move when the cursor is below 50%. When dragging upwards, only move when the cursor is above 50%. */
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // actually perform the action
            props.MoveLocation(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
        drop: (item: DragItem, monitor) => {
            Dispatch.UILogicControl.IsDraggingAnAddress(false);
            props.OnDropItem(item.id, item.index);
            ConsiderFareUpdate();
        }
    });    

    const id = props.LocationId;
    const index = props.LocationIndex;

    const [{ isDragging }, drag, preview] = useDrag({
        type: 'location',
        item: () => {
            // this function is called when drag begins 
            Dispatch.UILogicControl.IsDraggingAnAddress(true);
            return { id, index }
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                Dispatch.UILogicControl.IsDraggingAnAddress(false);
                props.OnDropItem(item.id, item.index);
                ConsiderFareUpdate();
            }
        }
    });

    // hide default drag preview image
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []);

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId} className="draggable-address-container">
            <BookingAddressComponent IsPickup={false} AddressIndex={index + 1} />
        </div>
    )
}