import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Tnc from './modules/TncPrivacy/Tnc';
import './app.scss';
import { Provider } from 'react-redux'
import appstore from './appStore';
import { BookingPageWithDiag } from './modules/Booking/BookingPageWithDiag';
import AuthCallback from './modules/Authentication/AuthCallback';
import AuthCallbackV2 from './modules/Authentication/Login/AuthCallbackV2';
import { UrlLocation } from './modules/URLLocation/UrlLocation';
import ContactUs from './modules/ContactUs/ContactUs';
import { DoStartupWork } from './modules/MyBookings/Logic/AppStartup';
import UserProfile from "./modules/User/UserProfile";
import { PrivateRoute } from "./modules/Authentication/PrivateRoute";
import Privacy from './modules/TncPrivacy/Privacy';
import { FeatureFlags } from './Config/FeatureFlags';
import { FeatureFlagsUI } from './widgets/ApiFailureTesting/FeatureFlagsUI';
import LoginChild from './modules/Authentication/Login/LoginChild';
import { MobileBookingPage } from "./modules/Booking/Mobile/MobileBookingPage";
import { BookingLayoutChooser } from './modules/Booking/BookingLayoutChooser';
import { RouteUrls } from './RouteUrls';
import { UILayoutMode } from "./modules/UILogicControl/UILogicControlEntities";
import { BookingLinkHandler } from './modules/TrackingLinks/BookingLinkHandler';
import UserPersonalDetails from './modules/User/UserPersonalDetails';
import MyWalletTable from './modules/Cards/MyWalletTable';
import FeatureList from './modules/WhatsNew/FeatureList';
import { AddressPointsList } from './modules/AddressPoints/UI/AddressPointsList';
import { FareEstimator } from './modules/Fare/FareEstimator';
import { BookingPageWithB2C } from './modules/Booking/BookingPageWithB2C';
import { PayPalLoadingWatcher } from './modules/PayPal/PayPalLoadingWatcher';
import { ApplePayLoadingWatcher } from './modules/ApplePay/ApplePayLoadingWatcher';
import { GooglePayLoadingWatcher } from './modules/GooglePay/GooglePayLoadingWatcher';
import { ThreeDSecureLoadingWatcher } from './modules/ThreeDSecure/ThreeDSecureLoadingWatcher';

interface AppState {
    hasLoaded: boolean;
}

class App extends React.Component<{}, AppState> {

    constructor(props: {}) {
        super(props);
        this.state = { hasLoaded: false };
    }

    async componentDidMount() {
        // Work consider to do when start up
        DoStartupWork();

        this.setState({ hasLoaded: true });
    }

    render() {
        const { LayoutMode } = appstore.getState().uiLogicControl;

        const wrapperStyle: React.CSSProperties = {
            visibility: this.state.hasLoaded ? "visible" : "hidden"
        };

        const wrapperClassStyle = LayoutMode === UILayoutMode.Mobile ? "mobile-layout" : "desktop-layout";

        return (
            <Provider store={appstore}>
                <PayPalLoadingWatcher />
                <ApplePayLoadingWatcher />
                <GooglePayLoadingWatcher />
                <ThreeDSecureLoadingWatcher />
                <div style={wrapperStyle} className={wrapperClassStyle}>
                    <Switch>
                        <Route exact={true} path="/" component={BookingLayoutChooser} />
                        <Route path="/Booking" component={BookingLayoutChooser} />

                        {!FeatureFlags.NoTermsAndConditions && <Route exact={true} path={RouteUrls.TermsAndConditions} component={Tnc} />}

                        <Route exact={true} path={RouteUrls.PrivacyPolicy} component={Privacy} />
                        <Route exact={true} path="/BookingDiag" component={BookingPageWithDiag} />
                        <Route exact={true} path="/B2CLogin" component={BookingPageWithB2C} />
                        <Route exact={true} path="/LoginReturn" component={AuthCallback} />
                        {FeatureFlags.CustomAuth0Interface && FeatureFlags.Auth0RedirectInChildWindow && <Route exact={true} path={RouteUrls.LoginCallbackV2} component={AuthCallbackV2} />}
                        <Route exact={true} path={RouteUrls.SilentLogin} component={LoginChild} />
                        <Route exact={true} path="/Location/:placeid" component={UrlLocation} />
                        <Route exact={true} path={RouteUrls.ContactUs} component={ContactUs} />
                        <PrivateRoute exact={true} path={RouteUrls.UserProfile} component={UserProfile} />
                        <PrivateRoute exact={true} path={RouteUrls.PersonalDetails} component={UserPersonalDetails} />
                        <PrivateRoute exact={true} path={RouteUrls.PaymentWallet} component={MyWalletTable} />
                        <Route exact={true} path={RouteUrls.WhatIsNew} component={FeatureList} />
                        <PrivateRoute exact={true} path={RouteUrls.AddressPoints} component={AddressPointsList} />
                        {FeatureFlags.FareEstimatorTool && <Route exact={true} path={RouteUrls.FareEstimate} component={FareEstimator} />}
                        {FeatureFlags.UserFeatureFlags && <Route exact={true} path="/flags" component={FeatureFlagsUI} />}
                        {
                            FeatureFlags.BookingApiV2 ?
                                <Route exact={true} path="/b2/:BookingId/:AccessCode">
                                    <BookingLinkHandler LinkVersion={2} />
                                </Route> :
                                <Route exact={true} path="/b/:BookingId/:AccessCode">
                                    <BookingLinkHandler LinkVersion={1} />
                                </Route>
                        }

                        {/* always leave this on the bottom */ }
                        <Route component={BookingLayoutChooser} />
                    </Switch>
                </div>
            </Provider>
        );
    }
}

export default App;
