import React from 'react';
import { connect } from "react-redux";
import { DetailedErrorMessage } from '../../Dialog/DialogEntities';
import '../../../scss/ErrorMessages.scss';
import { ApplicationState } from '../../../appState';
import { Dispatch } from '../../Dispatch';
import { DeclineProfileValidationProcess, IsUnderProfileValidationMode } from "../../LoginValidation/LoginValidationHelper";
import { UILayoutMode } from '../../UILogicControl/UILogicControlEntities';

interface PropsFromStore {
    errorMessage: DetailedErrorMessage;
    IsMobileDevice: boolean;
}

class DescriptiveErrorMessage extends React.Component<PropsFromStore> {

    /** 
     * Close the popup and some popups redirect to another dialog when the 'OK' or equivalent button is clicked.
     */
    onActionButtonClicked() {
        Dispatch.Dialog.CloseTopDialog();

        if (IsUnderProfileValidationMode()) {
            DeclineProfileValidationProcess();
        }
        else {
            if (this.props.errorMessage.DialogToOpen) Dispatch.Dialog.ShowDialog(this.props.errorMessage.DialogToOpen);
        }
    }

    render() {
        const btnText = IsUnderProfileValidationMode() ? "Ok" : this.props.errorMessage.ButtonText;
        
        return (
            <div className="error-message-container">
                <h1 className="error-title">{this.props.errorMessage.Title}</h1>
                <img className="error-image" src={this.props.errorMessage.ImageUrl!} alt="" width={this.props.errorMessage.ImageWidth} />
                {/* Following text is a hard coded string which contains HTML (To make the phone number bold). dangerouslySetInnerHTML prop is used to display it properly. */}
                <p className="error-text" dangerouslySetInnerHTML={{__html: this.props.errorMessage.MessageText!}}></p>
                <button className="retry-btn" onClick={() => this.onActionButtonClicked()}>{btnText}</button>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        errorMessage: state.dialog.detailedErrorMessage,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(DescriptiveErrorMessage);