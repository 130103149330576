/** Inputs to the Remove Booking Template dialog. */
export interface RemoveDialogInput {

    /** ID of the selected template to be deleted. */
    TemplateID: number;
}

/** Output of the Remove Booking Template dialog. */
export interface RemoveDialogResult {

    /** Which action (button click) user has performed.  */
    UserAction: RemoveTemplateUserAction;

    /** Whether the remove booking template request succeeded or not. */
    IsRemoveSuccess?: boolean;
}

/** Which action user has performed in the Remove Booking Template dialog. */
export enum RemoveTemplateUserAction {
    /** Confirm removing by clicking on 'Yes Remove' button. */
    Confirm = "Confirm Remove Template",

    /** Cancel removing by clicking on 'No' button.  */
    Cancel = "Cancel Remove Template"
}