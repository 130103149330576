import React from "react";
import { useState } from "react";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import { DateTime } from "luxon";

type DateRangeTypes = DateTime | null;

interface BookingDateRange {
    StartDate: DateTime | null;
    EndDate: DateTime | null;
}

export interface DateRangeProps {
    startDate: DateRangeTypes;
    endDate: DateRangeTypes;
    onDateChange:  (range: BookingDateRange) => void;
    onDateReset:  () => void;
}

/** Component Date Picker that render on Trip History. */
const DateRangePicker: React.FC<DateRangeProps> = (props) => {
    const [startDate, setStartDate] = useState<DateRangeTypes>(props.startDate);
    const [endDate, setEndDate] = useState<DateRangeTypes>(props.endDate);

    function resetDateRange() {
        props.onDateReset()
    }

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box sx={{display: "flex", flexDirection: "row", marginTop: "5px", marginLeft: "20px"}}>
                <DatePicker
                    label="Start date"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField size="small" {...params} />}
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue);
                        props.onDateChange({StartDate: newValue, EndDate: endDate});
                    }}
                />
                <Typography variant="body1" m={5}>to</Typography>
                <DatePicker
                    label="End date"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => <TextField size="small" {...params} />}
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue);
                        props.onDateChange({StartDate: startDate, EndDate: newValue});
                    }}
                />
                <Tooltip arrow title="Reset dates" componentsProps={{
                    tooltip: {
                        sx: {
                            color: "white",
                            backgroundColor: "black",
                            "& .MuiTooltip-arrow": {
                                "&::before": {
                                    backgroundColor: "black"
                                }
                            }
                        }
                    }
                }}> 
                    <IconButton onClick={resetDateRange}>
                        <RestartAltOutlinedIcon color="primary" fontSize="large" />
                    </IconButton>
                </Tooltip>
            </Box>
        </LocalizationProvider>
    )
}

export { DateRangePicker };