import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrl, Get, Post } from './ServiceManager';
import { AddressLookupResult, CheckCabServiceRequest, RoughUserLocation } from './LocationEntities';

/** Base URL for the LocationController in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Location/`;
}

/** HTTP API Calls to the LocationController in the Booking Management Service. */
class LocationServiceClass {

    /**
     * Given the details of a Google Maps Place, checks the location serviceability in the backend (V1) API. It also returns the timezone information.
     * This operation is preferred over CheckCabServiceV1b if the caller has already resolved the Google Maps Place details; it will avoid making a duplicate Google Maps place lookup.
     */
    public CheckCabServiceByPlaceDetail(request: CheckCabServiceRequest): Promise<ServiceResult<AddressLookupResult>> {
        const url = `${GetBaseUrl()}CheckCabServiceByPlaceDetail`;
        return Post<AddressLookupResult>(url, request);
    }

    /** Rough (city-level) location for the current user based on their IP address. */
    public GetMyRoughLocation(): Promise<ServiceResult<RoughUserLocation>> {
        const url = `${GetBaseUrl()}GetMyRoughLocation`;
        return Get<RoughUserLocation>(url);
    }
}

/** Singleton export for static access to the operations */
export const LocationService = new LocationServiceClass();