import { BookingStatus } from "../../../Services/BookingEntities";
import moment from "moment";
import { ExtendedEta, BookingTrackingDetails } from "../MyBookingEntities";
import { Config } from "../../../Config/Config";
import { FeatureFlags } from "../../../Config/FeatureFlags";

/** Calculate the progress percentage to show in the ETA progress bar before accepted. The progress value is calculated using the current time and the DispatchStartTime.
 */
export function SetProgressBeforeAccepted(bookingDetails: BookingTrackingDetails): number {
    // Return with value 0 if DispatchStartTime is not defined.
    if (!bookingDetails.DispatchStartTime) return 0;

    // Don't show progress for GB bookings.
    if (FeatureFlags.BookingApiV2) return 0;

    if(bookingDetails.BookingStatusID == BookingStatus.Dispatching || bookingDetails.BookingStatusID == BookingStatus.Assigned || bookingDetails.BookingStatusID == BookingStatus.Acknowledged) {
        /** Get the difference between the DispatchStartTime and the current time in seconds and divide it by 10 to make the progress bar value increase by 1% in every 10 seconds. Assuming the average time to Accept is 5 mins.*/
        return moment().diff(bookingDetails.DispatchStartTime, "seconds") / 10;
    }
    return 0;
}

/** Calculate the progress percentage to show in the ETA progress bar after accepted using the ETA to pickup and the assumed maximum time to pickup (configurable value) */
export function SetProgressAccepted(eta: ExtendedEta) {
    return 100 - (eta.OptimisticEtaSeconds * (100 - Config.MaxProgressBeforeAccepted) / Config.TMaxForPickupInSeconds);
}

/** Calculate the progress percentage to show in the ETA progress bar after pickedup using the ETA to dropoff and the assumed maximum time to dropoff (configurable value) */
export function SetProgressPickedup(eta: ExtendedEta) {
    return 100 - (eta.OptimisticEtaSeconds * 100 / Config.TMaxForDropoffInSeconds);
}