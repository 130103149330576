import React, { useEffect } from "react";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { useAppState } from "../../Redux/ReduxHooks";
import { SdkLoadingState } from "./PayPalState";
import { Dispatch } from "../Dispatch";
import { MenuItem } from "@mui/material";
import { PayPal } from "./PayPal";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";

/** 
 * Renders the Add PayPal entry in the payment method entry in the booking form.
 * Only present when the feature is supported and the user doesn't already have PayPal.
 */
export const AddPayPalDropDownEntry: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.AddPayPal);
    const hasPendingPayPalGuest = useAppState(OptionalUI.PendingPayPalGuest);
    const myPaymentMethods = useAppState(i => i.payment.AllCards);
    const sdkLoadStatus = useAppState(i => i.PayPal.LoadingStatus);
    const isCnpDisallowed = useAppState(OptionalUI.CnpDisallowedByMetered);
    const isPaymentSystemAvailable = useAppState(i => i.payment.IsPaymentSystemAvailable);

    // when the user opens the Payment dropdown very early
    useEffect(() => {
        if (sdkLoadStatus === SdkLoadingState.NotRequested) {
            Dispatch.PayPal.ReadyToLoad();
        }
    }, []);

    // not supported
    if (!isFeatureEnabled) return null;
    if (isCnpDisallowed) return null;
    if (!isPaymentSystemAvailable) return null;

    // already have PayPal
    if (myPaymentMethods.find(c => c.CardType === "PayPal")) return null;
    if (hasPendingPayPalGuest) return null;

    const isNotFullyLoaded = sdkLoadStatus !== SdkLoadingState.FinishedSuccess;

    let labelText: string = "PayPal";
    if (isNotFullyLoaded) labelText += " (Loading...)";

    return (
        <MenuItem className="cardMenuItem" value="#" disableGutters
            onClick={OnPayPalButtonClicked}
            disabled={isNotFullyLoaded}
        >
            <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.CnpPaymentMethod.PayPal)} />
            <span className="cardSelectorCardName">{labelText}</span>
        </MenuItem>
    );
}

/** Event handler for clicking on the PayPal menu item. */
function OnPayPalButtonClicked() {

    Dispatch.UILogicControl.CloseCardSelectorDropdown();
    PayPal.RunAddPayPalUiFlow()
}