import React from 'react';
import "./BookingTemplate.scss";
import { connect } from "react-redux";
import { Dispatch } from '../Dispatch';
import { ApplicationState } from "../../appState";
import { CustomTextFieldProps, OutlinedTextField } from '../Booking/Widget/OutlinedTextField';

interface BookingTemplateNameState {
	isFocus: boolean;
}

interface PropFromStore {
    TemplateName: string;
    IsBookingFormStrictValidationModeOn: boolean;
}

/**
 * Input field to accept template name
 */
class BookingTemplateNameField extends React.Component<PropFromStore, BookingTemplateNameState> {

    private inputFavRef: React.RefObject<HTMLInputElement>;
    
    constructor(props: PropFromStore) {
        super(props);
        this.inputFavRef = React.createRef();
        this.state = {
			isFocus: false
		};
    } 

    componentDidMount() {

        if (!this.inputFavRef.current)  return;
        
        // assign value from store
        this.inputFavRef.current.value = this.props.TemplateName;
    }

    componentDidUpdate(prevProps: PropFromStore) {
                
        if (this.props.TemplateName === prevProps.TemplateName) return;
            
        if (!this.inputFavRef.current)  return;

        this.inputFavRef.current.value = this.props.TemplateName ?? "";
    }

    IsInputValid = () => {

        const { TemplateName, IsBookingFormStrictValidationModeOn } = this.props;

        if (!IsBookingFormStrictValidationModeOn) return true;

        if (this.state.isFocus) return true;

        if (TemplateName) return true;

        return false;    
    }

    UpdateFocusStatus = (isFocus: boolean) => {
		this.setState({ isFocus: isFocus });
    };
    
    /** Update empty value for template name in store */
    ClearText = () => Dispatch.Booking.SetTemplateName('');

    /** Updates the template name in store  */
    UpdateTemplateNameInStore = (e: React.FocusEvent<HTMLInputElement>) => {
        this.UpdateFocusStatus(false);
        Dispatch.Booking.SetTemplateName(e.target.value);
    }

    render() {

        const isInputInvalid = !this.IsInputValid();

        const errorText = "Please enter a favourite name";

        const inputProp: CustomTextFieldProps  = {
            AllowAutoFocus: true,
            Name: "FavouriteName",
            ErrorMessage: errorText,
            LabelText: "Favourite name",
            onClearEvent: this.ClearText,
            IsInputInvalid: isInputInvalid,
            IsInputFocussed: this.state.isFocus,
            onFocusEvent: this.UpdateFocusStatus,
            onBlurEvent: this.UpdateTemplateNameInStore,
            DoesInputHasValue: !!this.props.TemplateName
        }        

        return (
            <>
                <div className="booking-fields-panel margin-top-lalala-i-cant-hear-you">
                    <OutlinedTextField ref={this.inputFavRef} {...inputProp} />
                </div>
                <hr className="fav-key-line"></hr>
            </>
        )
    }
}

function mapStateToProps(state: ApplicationState): PropFromStore {
    return {
        TemplateName: state.booking.TemplateName!,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn
    }
} 

export default connect(mapStateToProps)(BookingTemplateNameField)