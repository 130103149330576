import React from "react";
import { useAppState } from "../../../Redux/ReduxHooks";
import { OptionalUI } from "./OptionalUI";
import { PayDriverDirectLink } from "../../Cards/PayDriverDirectLink";

/** Possibly renders the Pay driver direct link which points to signup flow for guest users if fixed price toggle is hidden. */
export const MaybePayDriverDirectLink: React.FC = () => {
    const isFixedPriceToggleDisplayed = useAppState(OptionalUI.FixedPriceToggle);
    if (isFixedPriceToggleDisplayed) return null;

    return (
        <PayDriverDirectLink />
    );
}