import { GetManagementServiceRootUrl, Post } from "./ServiceManager";
import { ServiceResult } from "./ServiceEntities";
import { BookingTemplate, BookingTemplateDeletionRequest } from "../modules/BookingTemplate/BookingTemplateEntities";
import { Config } from "../Config/Config";

/** Base URL for the BookingTemplateController in the BookingManagement service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}BookingTemplate/`;
}

/** HTTP API Calls to the BookingTemplateController in the BookingManagement Service. */
class BookingTemplateServiceClass {

    /** Get the list of Booking Templates of the logged in user. */
    public GetMyTemplates(): Promise<ServiceResult<BookingTemplate[]>> {
        const url = `${GetBaseUrl()}MyTemplates`;
        return Post<BookingTemplate[]>(url, null);
    }

    /** Create Booking Templates for the logged in user. */
    public CreateMyTemplate(request: BookingTemplate): Promise<ServiceResult<number>> {
        const url = `${GetBaseUrl()}CreateTemplate`;
        return Post<number>(url, request);
    }

    /** Remove a given Booking Template from the user. */
    public RemoveTemplate(request: BookingTemplateDeletionRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}RemoveTemplate`;
        return Post<void>(url, request);
    }
}

export const BookingTemplateService = new BookingTemplateServiceClass();