import React, { useEffect } from 'react'
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities'
import BookingDateTime from '../BookingDateTime'
import { MaybeAccountDetails } from '../OptionalParts/MaybeAccountDetails'
import { MaybeCardSelector } from '../OptionalParts/MaybeCardSelector'
import { MaybeDeliveryOptions } from '../OptionalParts/MaybeDeliveryOptions'
import { BookingFormParcelMode } from '../FormModeSelector/BookingFormParcelMode'
import { BookingFormGroup } from '../FormSteps/BookingFormGroup'
import { VehicleSelector } from '../../Condition/UI/VehicleSelector'
import { BookingLocationGroup } from '../PerLocation/BookingLocationGroup'
import { useAppState } from '../../../Redux/ReduxHooks'

/** This component is to capture Parcel booking related input where the user can enter pickup details, drop-off details, date and time and delivery instructions. */
export const ParcelBookingForm: React.FC = () => {

    useEffect(() => BookingFormParcelMode.SwapToParcel(), []);

    const isBookingMode = useAppState(i => i.uiLogicControl.BookingForm.BookingWidgetMode === BookingWidgetModeKind.Booking);

    return (
        <>
            <MaybeAccountDetails />

            <BookingLocationGroup Location={0} />
            <BookingLocationGroup Location={1} />

            <BookingFormGroup>
                {isBookingMode && <BookingDateTime />}
                <VehicleSelector />
            </BookingFormGroup>

            <MaybeCardSelector />
            <MaybeDeliveryOptions />
        </>
    );
}