import { VehicleOption, ConditionLookupTables, MaxiTaxiLookUpType, ApiVehicleOption } from './Redux/ConditionEntities'
import appstore from '../../appStore';
import { ConvertToServiceInfo } from './VehicleToServiceTranslater';
import { Vehicle } from '../../Services/MakeBookingContracts';

/**
 * Process raw vehicle types (Ids) from Global Booker and map them to vehicle details.
 * With initial loading of vehicles from the API, vehicleTypes parameter is null so the default list for the brand is displayed.
 */
export function MakeVehicleLookupFromApiData(vehicleTypes: number[] | null): ConditionLookupTables {
    const ConditionList: VehicleOption[] = [];
    const MaxiTaxiLookUp: MaxiTaxiLookUpType = {};

    const allVehicles = appstore.getState().condition.AllVehicles;

    let vehiclesToDisplay: Vehicle[] = [];

    if (!vehicleTypes) {
        vehiclesToDisplay = allVehicles.filter(i => i.IsInDefaultList);
    } else {
        for (let vehicle of allVehicles) {
            if (vehicleTypes.some(i => i == vehicle.GlobalBookerVehicleId)) {
                vehiclesToDisplay.push(vehicle);
            }
        }
    }

    for (var vehicle of vehiclesToDisplay) {

        // Condition is dummy here, and useless for GB implementation, maybe this implementation is not ideal?
        const vehicleOption: ApiVehicleOption = {
            ApiVersion: 2,
            ApiId: vehicle.GlobalBookerVehicleId,
            Name: vehicle.Name,
            MaxSeat: 0,
            Description: vehicle.ShortDescription
        };

        const service = ConvertToServiceInfo(vehicle);

        ConditionList.push({ Service: service, ApiVehicle: vehicleOption });        
    }    

    return { ConditionList: ConditionList, MaxiTaxiLookUp: MaxiTaxiLookUp };
};
