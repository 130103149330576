import React from "react";
import { connect } from 'react-redux';
import "../Mobile/MobileBookingForm.scss";
import { ApplicationState } from "../../../appState";
import TransparentBlackBg from "../../../widgets/transparent-black-background/transparent-black-background.jsx";
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";

interface BookingLoadingPropsFromStore {
    BookingFormApiRunningStatus: DataLoadingStatus;
    IsLoaderShownInContactDetails: boolean;
    ShouldShowLoading: boolean;
}

/**
 * Loading svg and black transparent background for booking form
 * 
 * Todo:
 * To make this more general through out the application, the restriction is which Redux flag this should subscribe
 */
class BookingPageLoading extends React.Component<BookingLoadingPropsFromStore> {    
    render() {         
        return (
            (this.props.BookingFormApiRunningStatus == DataLoadingStatus.InProgress || this.props.IsLoaderShownInContactDetails || this.props.ShouldShowLoading) &&
                <React.Fragment>
                    <TransparentBlackBg />
                    <div className="booking-form-loading">
                        <img src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/>
                    </div> 
                </React.Fragment>
        )
    }
}

function mapStateToProps(state: ApplicationState) : BookingLoadingPropsFromStore {
    return {
        BookingFormApiRunningStatus: state.uiLogicControl.BookingForm.ApiRunningStatus,
        IsLoaderShownInContactDetails: state.verification.IsLoaderShownInContactDetails,
        ShouldShowLoading: state.uiLogicControl.ShouldShowLoading
    }
}

export default connect(mapStateToProps)(BookingPageLoading);