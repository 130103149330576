import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { MapPurpose } from "../../../widgets/google-maps/MapComponents/GoogleMapProps";
import { GoogleMapRaw } from "../../../widgets/google-maps/MapComponents/GoogleMapRaw";
import { MapMarkers } from "../../../widgets/google-maps/MapMarkers";
import { GeoPoint } from "../../Location/Entities";
import { AddressPoint } from "../Redux/AddressPointsEntities";

interface PropsFromStore {
    ActiveAddressPoint: AddressPoint;
    PreferedLocation: GeoPoint;
}

/** Google map displayed in the top of create/edit address point dialog. Shows the pin on the map if address is selected. */
const GoogleMapForAddressCore: React.FC<PropsFromStore> = (props) => {    

    return (
        <div className="map-in-address-point">
            <GoogleMapRaw
                mapCenter={props.ActiveAddressPoint.Address?.GeoLocation || props.PreferedLocation}
                zoom={15}
                pickupLocation={props.ActiveAddressPoint.Address?.GeoLocation || undefined}
                carNumber={null}
                PickupMarker={MapMarkers.GenericLocation}
                PlannedRoute={null}
                Purpose={MapPurpose.FavouriteAddress}
                Locations={null}
            />
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveAddressPoint: state.addressPoints.ActiveAddressPoint,
        PreferedLocation: state.location.reliableLocation.value.geoPoint
    }
}

export const GoogleMapForAddress = connect(mapStateToProps)(GoogleMapForAddressCore);