import React from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { CreditCardForm } from "./CreditCardForm";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";

/** Possibly renders the credit/debit card input fields inline in the booking form. */
export const MaybeCreditCardForm: React.FC = () => {

    const featureEnabled = useAppState(OptionalUI.GuestCreditCard);
    const isInlineCreditCardSelected = useAppState(i => i.booking.PaymentOption?.Id === "CreditOrDebit");

    if (!featureEnabled) return null;
    if (!isInlineCreditCardSelected) return null;

    return (
        <CreditCardForm />
    );
}