import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";

/** Get user's current legal documents consent status including whether a new consent is required. */
export async function LoadUserConsentStatus() {
    var result = await Api.LegalDocuments.GetLegalDocumentsConsentStatus();

    if (!result.isSuccess) return;

    Dispatch.LegalDocuments.ConsentStatus(result.value);
}