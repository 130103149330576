import appstore from "../../appStore";
import { Api } from "../../Services/Api";
import { FeatureInfo, FeatureOptInRequest } from "../../Services/FeaturesContracts";
import { BookingFormParcelMode } from "../Booking/FormModeSelector/BookingFormParcelMode";
import { Dispatch } from "../Dispatch";
import { BookingFormKind } from "../UILogicControl/UILogicControlEntities";
import { PreviewFeatureId, LookupPreviewMetadata } from "./FeatureEntities";

/** Operations to manage server-managed preview features. */
export namespace PreviewFeatures {

    /** Reload the preview feature list from the API and update redux state. */
    export async function Reload() {

        const result = await Api.Features.GetMyFeatures();
        if (!result.isSuccess) return;

        // drop records defined by the API but not supported on the client
        const supportedFeatures = result.value.filter(i => IsSupported(i.FeatureId));
        const disabledFeatures = FindPreviewsBeingDisabled(supportedFeatures);

        Dispatch.Features.RefreshPreviewFeatures(supportedFeatures);
        CleanUpDisabledPreviewState(disabledFeatures);
    }

    /**
     * Tests whether a feature ID received from the API is understood / supported by the website.
     * This is equivalent to testing whether it matches a defined value in the PreviewFeatureId enum.
     */
    function IsSupported(featureId: number): boolean {

        return !!LookupPreviewMetadata[featureId];
    }

    /**
     * Returns a list of feature previews that will be disabled as a result of refreshing the preview data from the API.
     */
    function FindPreviewsBeingDisabled(newPreviews: FeatureInfo[]): PreviewFeatureId[] {

        const toBeDisabled: PreviewFeatureId[] = [];
        const oldPreviews = appstore.getState().features.PreviewFeatures;

        for (let previewFeature of oldPreviews) {

            if (previewFeature.IsEnabled && !newPreviews.some(i => i.IsEnabled && i.FeatureId === previewFeature.FeatureId)) {
                toBeDisabled.push(previewFeature.FeatureId);
            }
        }

        return toBeDisabled;
    }

    /**
     * Reset Redux state associated with features that have just been turned off.
     * If you are no longer allowed to do some thing, make sure that thing is in the off state.
     * This is optional for each feature and only to prevent the UI being in a broken state.
     */
    function CleanUpDisabledPreviewState(disabledPreviews: PreviewFeatureId[]) {

        for (let preview of disabledPreviews) {

            if (preview === PreviewFeatureId.ParcelDeliveryWidget) {
                Dispatch.UILogicControl.ActiveBookingForm(BookingFormKind.PassengerBooking);
                BookingFormParcelMode.ClearTaxiWidgetUnsupportedState();
            }

            // clear delivery options state 
            if (preview === PreviewFeatureId.EndOfDelivery || preview === PreviewFeatureId.AlcoholDelivery ) {
                Dispatch.Booking.ClearDeliveryOption();
                Dispatch.Booking.SetDeliveryOptionError(null);
            }
        }
    }

    /**
     * Change the opt in/out state of the feature.
     * It will reload the full set of features after this as a way to update redux state.
     */
    export async function ToggleOptInState(feature: FeatureInfo) {

        const request: FeatureOptInRequest = {
            FeatureId: feature.FeatureId,
            UserOptIn: !feature.IsEnabled
        }

        const result = await Api.Features.OptInFeature(request);

        if (result.isSuccess) {
            await Reload();
        }
    }
}