import { DefaultTaskLookUpTable } from "./LoginValidationEntities";
import { TaskLookUpType } from "./LoginValidationEntities";

/**
 * This typing is used for logged in user validation.
 * Please see description of function LoginValidationHelper.ValidateProfileAndInteraction().
 */
export interface LoginValidationState {
    /** Number returned by window.setInterval() which stands for each run of method ValidateProfileAndInteraction() */
    TimerID?: number;
    /** "true" when manually waiting for an async subtask to complete, e.g. user to accept terms and conditions.  */
    IsAsyncTaskRunning: boolean;
    /**
     * This is a lookup table which stands for the predefined list of tasks which needs to be checked one by one in the process of logged-in user validation.
     * Also, the status of each task, wether it is "completed"? "in progress"? or "not start yet".
     */
    TaskLookUpTable: TaskLookUpType;
}

/** Initial value of Authentication */
export const InitLoginValidationState: LoginValidationState = {
    IsAsyncTaskRunning: false,
    TaskLookUpTable: DefaultTaskLookUpTable
};