import { ApiGeoPoint } from "../../../Services/BookingEntities";
import { BookingTrackingDetails } from "../MyBookingEntities";

/** Returns true if the update has genuinely new data, i.e. an actual change of state. 
 * Normally we rely on React / Redux to do automatic change detection, but that isn't sufficient in this scenario because we have to check the values in a multi-level table, which React doesn't do. */
export function HasBookingChanged(trackingDetails: BookingTrackingDetails, update: BookingTrackingDetails): boolean {
    // status
    if (update.BookingStatusID != trackingDetails.BookingStatusID) return true;

    // car
    if (update.CarNumber != trackingDetails.CarNumber) return true;

    // vehicle location. might be null
    if (update.VehicleLocation) {

        // old location is null
        if (!trackingDetails.VehicleLocation) return true;

        if (AreLocationsDifferent(trackingDetails.VehicleLocation, update.VehicleLocation)) return true;
    }

    // no apparent changes
    return false;
}

/** Returns true if the *values* of the two geopoints are different.
 * TODO: consider whether != is OK to use here. Sometimes floating point values can have minute differences, e.g. 3.0000000 vs 3.0000000001 that can occur due to rounding problems.
 * Theoretically we should ignore such differences. But maybe we will never experience them. */
function AreLocationsDifferent(left: ApiGeoPoint, right: ApiGeoPoint): boolean {

    if (left.Latitude != right.Latitude) return true;
    if (left.Longitude != right.Longitude) return true;

    return false;
}