import React from 'react';
import { connect } from "react-redux";
import { DialogKind } from '../Dialog/DialogEntities';
import 'react-intl-tel-input/dist/main.css';
import PhoneInput, { CountryData } from "react-intl-tel-input";
import './ContactVerification.scss';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import { DropLeadingZero } from '../../utils/Formattingutil';
import { IsGuest } from "./IsGuest";
import { CheckAddPlus } from '../../utils/Formattingutil';
import { Config } from "../../Config/Config";
import { Credentials } from '../Authentication/AuthEntities';
import { ApplicationState } from '../../appState';
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { Dispatch } from '../Dispatch';
import { IsUnderProfileValidationMode  } from '../LoginValidation/LoginValidationHelper';
import { ContactNumberValidationResult, ContactNumberKind }  from '../Authentication/Login/LoginEntities';
import AuthImplV2 from "../Authentication/Login/AuthImplV2";
import { CreateCredentialForSignup } from "../Authentication/Login/CredentialHelper";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { ProfileChanges } from '../../Services/UserEntities';
import { UpdateContactNumber } from '../Authentication/UpdateProfileService';
import { VerifyLoginFromLocalStorageOrLogout } from "../Authentication/AuthHelper";
import { VerificationTrigger } from './VerificationEntities';
import { IsValidMobileNumber, IsValidAustralianLandlineNumber, RemoveLeadingPlusSign, ParseContactNumber, ValidateContactNumber } from '../Utils/PhoneValidation';
import { FeatureFlags } from '../../Config/FeatureFlags';
import SilentLogin from '../Authentication/Login/SilentLogin';
import { AuthType } from '../Authentication/Login/LoginEntities';
import { LogEvent } from '../../utils/LogEvent';
import { SmsVerification, StartSmsOutcome } from './SmsVerification';
import { GetValues } from '../../Config/MyAppConfig';
import appstore from '../../appStore';
import { PayPalGuestTempPlaceholder } from '../Payment/PaymentEntities';

/**
 * There are three places where the phone number can come from:
 * 1) Sign up form UI (appState.auth.Credentials)
 * 2) Guest booking SMS Verification (appState.verification)
 * 3) Signed in user's profile (appState.authentication.UserProfile)
 */
interface PropsFromStore {

    /** Country Phone Number Prefix (e.g. "+61") from the user sign up form (appState.auth.Credentials) */
    SignUpCountryPrefix: string;

    /** Local phone number (not including country prefix) from the user sign up form (appState.auth.Credentials) */
    SignUpLocalNumber: string | null;

    /** Country ISO Code (e.g. "au") from the user sign up form (appState.auth.Credentials) */
    SignUpCountryIsoCode: string | null;

    /** Country Phone Number Prefix (e.g. "+61") for a guest user (appState.verification) */
    GuestCountryPrefix: string | null;

    /** Local phone number (not including country prefix) for a guest user (appState.verification) */
    GuestLocalNumber: string | null;

    /** Country ISO Code (e.g. "au") for a guest user (appState.verification) */
    GuestCountryIsoCode: string | null;

    /** Full phone number from a signed in user's profile (appState.authentication.UserProfile.ContactPhone) */
    ProfileFullPhone: string | null;

    /** Whether the current user is a guest (not signed in). */
    IsGuest: boolean;

    /** Full sign up details. Only used to render the silent login form or continue the sign up flow. */
    SignUpCredentials: Credentials;

    /** Which UI flow has led into the contact details entry, e.g. Signup, Guest Booking, etc. */
    UiFlow: VerificationTrigger;

    /** 
     * True when an API call related to the contact details UI is in progress. 
     * The UI will display a loading spinner image.
     */
    IsApiCallInProgress: boolean;

    /** Supports the Auth0 silent login inside a hidden iFrame. */
    IsSilentLoginActive: boolean;
}

interface ContactDetailsState {

    /** 
     * Phone number country code prefix based on the country code flag dropdown list
     * WARNING: the data in this field is inconsistent. Sometimes it appears to include the leading "+", but not at other times. 
     */
    CountryPrefix: string;

    /** 
     * The local party of the phone number that has been entered on the UI.
     * Doesn't include the country prefix.
     */
    LocalNumber: string;

    /** Error message to display on the UI. This is not properly typed as nullable. It probably uses an empty string instead. */
    errorMessage: string;

    /** 
     * Whether the phone number entered in the UI is valid. 
     * Null when the phone number hasn't been entered yet.
     * Determines whether the error message is shown on the UI.
     */
    isValidContactNumber: boolean | null;

    /**
     * Whether the "Next" button at the bottom of the dialog is active.
     * Clicking this button begins the SMS verification process.
     * It requires a valid phone number to be entered.
     */
    isNextButtonActive: boolean;

    /** 
     * Whether the user is on their 4th attempt to enter a SMS code.
     * This is the point at which we'll tell them it's time to give up.
     */
    isFourthAttempt: boolean;
}

/** 
 * A UI component for the user to enter their phone number.
 * It is rendered in a dialog and used as part of SMS Verification.
 * The UI is unfortunately quite tightly coupled to the SMS Verification process, so it performs the action that follows on from the phone number input.
 * 
 * There are several flows that trigger this UI. For legacy reasons, they all store the phone number in different places in Redux.
 * This means there are lots of branches in the code, whenever setting the phone number details, to target the different Redux actions.
 */
class ContactDetails extends React.Component<PropsFromStore, ContactDetailsState> {

    /** 
     * The number of SMS Verification challenges that have been attempted in a row.
     * After several attempts we will cut you off.
     */
    noOfAttempts: number;

    /** The HTML input element for the phone number text. */
    private phoneNumberInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: PropsFromStore) {
        super(props);
        this.phoneNumberInputRef = React.createRef();

        this.state = {
            CountryPrefix: "+61",
            errorMessage: "",
            isValidContactNumber: null,
            isNextButtonActive: false,
            LocalNumber: "",
            isFourthAttempt: false
        }

        this.noOfAttempts = 1;
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.OnNextButtonClicked = this.OnNextButtonClicked.bind(this);
        this.OnUiInputsChanged = this.OnUiInputsChanged.bind(this);
    }

    /**
     * On component mount, populate the contact number field and country code if they are available in the Redux store.
     * E.g. when the UI flow is "edit phone number" we can fill the form with the user's existing phone number.
     */
    componentDidMount() {
        LogEvent.OnDisplayingContactDetailsPage(); 
        
        if (this.props.UiFlow === VerificationTrigger.Signup) {
            if (!!this.props.SignUpLocalNumber) {
                this.setState({ 
                    LocalNumber: DropLeadingZero(this.props.SignUpLocalNumber),
                    CountryPrefix: RemoveLeadingPlusSign(this.props.SignUpCountryPrefix),
                    isNextButtonActive: true
                });
            }
        }
        else {
            if (this.props.GuestLocalNumber) {
                this.setState({
                    LocalNumber: this.props.GuestLocalNumber,
                    isNextButtonActive: true
                });
    
                if (this.props.GuestCountryPrefix) {
                    this.setState({
                        CountryPrefix: RemoveLeadingPlusSign(this.props.GuestCountryPrefix)
                    });
                }
            }
            else if (this.props.ProfileFullPhone) {

                const phoneParts = ParseContactNumber(this.props.ProfileFullPhone);

                this.setState({
                    LocalNumber: phoneParts.NumberPart,
                    CountryPrefix: phoneParts.CountryCode,
                    isNextButtonActive: true,
                });
            }
        }
    }

    /** 
     * Event handler for the user selecting a country code for the phone number by clicking a flag in the dropdown list.
     * Push the new country code into Redux state.
     */
    OnPhoneCountryCodeSelected = (currentNumber: string, value: CountryData) => {

        this.OnUiInputsChanged();
        
        this.setState({ CountryPrefix: value.dialCode }, () => {          
            if (this.props.UiFlow === VerificationTrigger.Signup) {
                Dispatch.Auth.SignupPhoneCountry({CountryCode: CheckAddPlus(value.dialCode), CountryName: value.name, CountryIsoCode: value.iso2});
            }   
            else {
                Dispatch.Verification.CountryInfo({ CountryCode: CheckAddPlus(this.state.CountryPrefix), CountryName: value.name, CountryIsoCode: value.iso2 });
            }                        
        });
    };

    /**
     * Recalculate some state whenever the UI inputs (phone number text or country code flag dropdown) change.
     * There is a very basic validation for the "Next" button being active.
     * The error message is also cleared as soon as you change the input. It will only be regenerated (via an API call) when the "Next" button is clicked.
     */
    OnUiInputsChanged() {

        const phoneNumberText: string = this.phoneNumberInputRef.current!.value;

        // make the button active only if the mobile number length is 8 or higher.
        this.setState({ isNextButtonActive: phoneNumberText.length >= 8});

        // Restricting the phone number field only to numbers. State will not be updated if the user entered other characters.
        const regex = /^[0-9\b]+$/;
        if (phoneNumberText === '' || regex.test(phoneNumberText)) {
            this.setState({ LocalNumber: phoneNumberText });
        }

        // We need to clean error message here if the user start typing
        this.setState({ errorMessage: "", isValidContactNumber: null });
    }

    /**
     * Event handler for the phone number input element losing focus.
     * This is the point at which the UI value is committed to the Redux store.
     * WARNING: I don't understand why only the Signup flow is validating the number.
     */
    OnPhoneTextLostInputFocus(e: React.FocusEvent<HTMLInputElement>) {
        const contactNumber = e.target.value;

        if (this.props.UiFlow === VerificationTrigger.Signup) {

            // WARNING: why isn't this code testing [contactNumber]?
            // Also, why is it using props.SignUpCountryPrefix instead of state.CountryPrefix?
            if (ValidateContactNumber(this.state.LocalNumber, this.props.SignUpCountryPrefix).IsValid) {
                Dispatch.Auth.SignupPhoneNumber(this.state.LocalNumber);
            }
            else {
                this.setState({
                    errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage,
                    isValidContactNumber: false
                });

                Dispatch.Auth.ClearSignupPhoneNumber();
            }
        }
        else {
            Dispatch.Verification.ContactNumber(contactNumber);
            Dispatch.Verification.CountryInfo({ CountryCode: CheckAddPlus(this.state.CountryPrefix) });
        } 
    }

    /**
     * Event handler for the user clicking the "Next" button.
     * Sometimes the SMS Validation part can be skipped if the user has a landline number. We will perform the followup action (e.g. booking creation) immediately.
     * WARNING: a large part of this function seems to be duplicating DetermineSmsPhoneNumber.
     */
    async OnNextButtonClicked() {
        if (IsUnderProfileValidationMode()) {
            VerifyLoginFromLocalStorageOrLogout();
        }

        // WARNING: why isn't this code just testing [state.CountryPrefix] and [state.LocalNumber]?

        if (this.props.IsGuest) {
            let countryPrefix = "", localNumber = "";

            if (this.props.UiFlow === VerificationTrigger.Signup) {
                countryPrefix = this.props.SignUpCountryPrefix;
                localNumber = this.props.SignUpLocalNumber!;
            }
            else {
                countryPrefix = this.props.GuestCountryPrefix!;
                localNumber = this.props.GuestLocalNumber!;
            }

            const validity: ContactNumberValidationResult = ValidateContactNumber(localNumber, countryPrefix);

            if (validity.IsValid) {
                if (validity.ContactNumberKind === ContactNumberKind.Mobile) {
                    await this.sendVerificationCode();
                }
                else if (validity.ContactNumberKind === ContactNumberKind.Landline) {

                    if ((this.props.UiFlow === VerificationTrigger.Booking) && (appstore.getState().booking.PaymentOption == PayPalGuestTempPlaceholder)) {

                        // wallet is stricter and requires a mobile number
                        this.setState({
                            errorMessage: CustomErrorMessages.PhoneNeedsMobile,
                            isValidContactNumber: false,
                            isNextButtonActive: false
                        });

                        return;
                    }

                    Dispatch.Verification.ShowLoaderInContactDetails();

                    if (this.props.UiFlow === VerificationTrigger.Signup) { // Proceed signing up

                        if (FeatureFlags.Auth0RedirectInChildWindow) {
                            Dispatch.Auth.ShowSilentLogin();
                        }
                        else {
                            new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(this.props.SignUpCredentials));
                        }
                    }
                    else {
                        CreateBookingWithoutSmsVerification();
                    }
                }
            }
            else {
                this.setState({
                    errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage,
                    isValidContactNumber: false,
                    isNextButtonActive: false
                });
            }
        }
        /**
         * The user has logged in successfully, no behaviour changed
         */
        else {
            if (IsUnderProfileValidationMode()) {
                const countryPrefix = this.props.GuestCountryPrefix!;
                const localNumber = this.props.GuestLocalNumber!;
                const fullNumber = CheckAddPlus(countryPrefix) + DropLeadingZero(localNumber);
                Dispatch.Verification.ShowLoaderInContactDetails();

                if (IsValidMobileNumber(fullNumber)) {
                    await this.sendVerificationCode();
                }
                else if (IsValidAustralianLandlineNumber(fullNumber, countryPrefix, localNumber)) { // ContactNumber, Profile
                    const body: ProfileChanges = { ContactNumber: { PhoneNumber: fullNumber } }
                    const result = await UpdateContactNumber(body);
                    Dispatch.Verification.HideLoaderInContactDetails();

                    if (result) {
                        this.setState({ isValidContactNumber: true });
                        Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
                    }
                    else {
                        this.setState({
                            errorMessage: CustomErrorMessages.InvalidPhoneNumber,
                            isValidContactNumber: false
                        });
                    }
                }
            }
            else {
                await this.sendVerificationCode();
            }
        }
    }

    /**
     * Starts the SMS Verification process with the phone number that has been entered on this UI.
     * In the normal case, this dialog will close and the Verification dialog will open.
     */
    async sendVerificationCode() {
        
        this.noOfAttempts++;
        Dispatch.Verification.ShowLoaderInContactDetails();
        this.setState({isValidContactNumber: null, errorMessage: ""});
        
        // phone number test on server
        const smsPhoneNumber = this.DetermineSmsPhoneNumber();
        const result = await SmsVerification.StartChallenge(smsPhoneNumber);

        if (result.Outcome === StartSmsOutcome.InvalidPhoneNumber) {
            this.HandleInvalidPhoneNumber();
            return;
        }
                                       
        if (result.Outcome === StartSmsOutcome.Success) {
            LogEvent.SuccessfulPhoneVerification();
            Dispatch.Verification.HideLoaderInContactDetails();
            this.setState({ isValidContactNumber: true });

            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
            Dispatch.Dialog.ShowDialog(DialogKind.Verification);
        }
        else {
            // Display the generic error message for timeouts and other unanticipated errors asking the user to call 132227
            LogEvent.SendingVerificationCodeFailure(result.ErrorMessage);
            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);

            if (this.props.UiFlow === VerificationTrigger.Signup) {
                new CredentialsController().GoBackToSignupPopupFromError();
            }
            else {
                //ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
                Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
            }
        }
    }

    /**
     * Gets the target phone number from several possible places based on the context.
     * WARNING: I don't understand why we don't always just use the state of this component. 
     */
    DetermineSmsPhoneNumber(): string {
        let countryPrefix = "", localNumber = "";

        if (this.props.UiFlow === VerificationTrigger.Signup) {
            countryPrefix = this.props.SignUpCountryPrefix;
            localNumber = DropLeadingZero(this.props.SignUpLocalNumber!);
        }
        else {
            if (this.props.IsGuest) {
                countryPrefix = this.props.GuestCountryPrefix!;
                localNumber = DropLeadingZero(this.props.GuestLocalNumber!);
            }
            else {
                countryPrefix = CheckAddPlus(this.state.CountryPrefix);
                localNumber = DropLeadingZero(this.state.LocalNumber);
            }
        }

        const smsPhoneNumber = countryPrefix + localNumber;
        return smsPhoneNumber;
    }

    /** Logging and state changes after the API indicates that the phone number was invalid. */
    HandleInvalidPhoneNumber() {
        LogEvent.InvalidMobileNumberProvided();
        Dispatch.Verification.HideLoaderInContactDetails();

        this.setState({
            errorMessage: CustomErrorMessages.CredentialContactNumberErrorMessage,
            isValidContactNumber: false
        });

        if (this.noOfAttempts === 4) {
            LogEvent.OnProvidingInvalidMobileNumberFourthTime();
            this.setState({ isFourthAttempt: true });
            Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);

            if (this.props.UiFlow === VerificationTrigger.Signup) {
                new CredentialsController().GoBackToSignupPopupFromError();
            }
            else {
                Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.InvalidPhone, DialogToOpen: DialogKind.ContactDetails });
                Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
            }
        }
    }

    render() {
        
        // By default, Australian flag will be selected
        let countryIsoCode = this.props.GuestCountryIsoCode ?? "au";
        
        if (this.props.UiFlow == VerificationTrigger.Signup) {
            countryIsoCode = this.props.SignUpCountryIsoCode ?? "au";
        }
        
        var sendButtonClass = this.state.isNextButtonActive ? "send-verification-btn active-btn" : "send-verification-btn ghost-button";
        var mobileValidityClass = this.state.isValidContactNumber === false ? "mobile-number red-underline" : this.state.isValidContactNumber || this.state.isNextButtonActive ? "mobile-number green-underline" : "mobile-number";

        return (
            <div className="contact-details">
                <div className="phone-number-group">
                    <div className="country-code-section">
                        <PhoneInput
                            defaultCountry={countryIsoCode}
                            separateDialCode={true}
                            fieldId={"phoneInput"}
                            value={this.state.CountryPrefix}
                            onSelectFlag={this.OnPhoneCountryCodeSelected}
                            preferredCountries={GetValues().AllowedCountryCodes}
                        />
                    </div>
                    <div className="mobile-number-section">
                        <input
                            ref={this.phoneNumberInputRef}
                            type="tel"
                            placeholder="Enter your contact number"
                            className={mobileValidityClass}
                            maxLength={Config.ContactNumberMaxLength}
                            onChange={this.OnUiInputsChanged}
                            onBlur={(e) => this.OnPhoneTextLostInputFocus(e)}
                            value={this.state.LocalNumber}
                            autoFocus />
                    </div>                    
                </div>
                { this.state.errorMessage ? <p className="verification-error incorrect-phone">{ this.state.errorMessage }</p> : "" }
                { !this.state.isFourthAttempt ? <button className={sendButtonClass} onClick={this.OnNextButtonClicked} disabled={!this.state.isNextButtonActive}>Next</button> : null}
                { this.props.IsApiCallInProgress ? <img alt="loading" src={getContentUrl(ContentURL.images.Loading)} className="loading-image" height="60" /> : null }
                { this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.SignUpCredentials} AuthType={AuthType.Signup} /> }
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        SignUpCountryPrefix: state.authentication.Credentials.CountryInfo.CountryCode,
        SignUpLocalNumber: state.authentication.Credentials.ContactNumber ?? null,
        SignUpCountryIsoCode: state.authentication.Credentials.CountryInfo.CountryIsoCode ?? null,
        GuestCountryPrefix: state.verification.UserContactNumberInfo.CountryInfo?.CountryCode ?? null,
        GuestLocalNumber: state.verification.UserContactNumberInfo.Contactnumber ?? null,
        GuestCountryIsoCode: state.verification.UserContactNumberInfo.CountryInfo?.CountryIsoCode ?? null,
        ProfileFullPhone: state.authentication.UserProfile?.ContactPhone ?? null,
        IsGuest: IsGuest(state.authentication.AuthToken, state.authentication.UserProfile),
        SignUpCredentials: state.authentication.Credentials,
        UiFlow: state.verification.Trigger,
        IsApiCallInProgress: state.verification.IsLoaderShownInContactDetails,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive
    };
}

export default connect(mapStateToProps)(ContactDetails);