import { UserContactNumberInfo } from "../Verification/VerificationEntities";
import { FullPhoneNumber } from "./PhoneNumberEntities";
import { PhoneNumberUtil } from "google-libphonenumber";

/** 
 *  Conversion from the modern FullPhoneNumber into the obsolete entities.
 *  This is temporary code while we are halfway through the phone number code upgrade process.
 */
export namespace LegacyPhoneShim {

    /** Singleton of Google's parsing library helper class */
    const phoneUtil = PhoneNumberUtil.getInstance();

    /**
     * Create a legacy UserContactNumberInfo object from a phone number.
     */
    export function ConvertToInfo(phoneNumber: FullPhoneNumber): UserContactNumberInfo {

        const googlePhone = phoneUtil.parse(phoneNumber.FullNumber);
        const countryDialPrefix = googlePhone.getCountryCode()!.toString();

        return {
            Contactnumber: phoneNumber.LocalPart,
            CountryInfo: {
                CountryCode: countryDialPrefix,
                CountryIsoCode: phoneNumber.CountryCodeIso2,
                CountryName: phoneNumber.CountryCodeIso2, // not available
            }
        };
    }
}