import appstore from "../../appStore";
import { AddressPointDetails, AddressPointRequest } from "../../Services/AddressPointsContracts";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { AddressPointModalType, AddressPoint } from "./Redux/AddressPointsEntities";

/** Construct the request payload and call the API to save a new or an updated address point.
 * Return false if saving failed. */
export async function SaveAddressPoint(): Promise<boolean> {

    const addressPointsState = appstore.getState().addressPoints;
    const activeAddressPoint = addressPointsState.ActiveAddressPoint;

    const requestData: AddressPointRequest = {
        AddressPointId: activeAddressPoint.AddressPointId,
        Name: activeAddressPoint.Name!,
        DisplayText: activeAddressPoint.Address?.DisplayText!,
        GooglePlaceId: activeAddressPoint.Address?.GooglePlaceId!,
        PickupNotes: activeAddressPoint.PickupNotes,
        DropoffNotes: activeAddressPoint.DropoffNotes
    }

    // New address point
    if (addressPointsState.UiEditMode === AddressPointModalType.Create) {
        var createResult = await Api.AddressPoints.Create(requestData);

        if (!createResult.isSuccess) return false;

        const newAddressPointId = createResult.value.AddressPointId;
        activeAddressPoint.AddressPointId = newAddressPointId;

        Dispatch.AddressPoints.AddToList(ConvertAddressPointToAddressPointDetails(activeAddressPoint));
    }
    else {
        // Updated address point
        var editResult = await Api.AddressPoints.Update(requestData);

        if (!editResult.isSuccess) return false;

        Dispatch.AddressPoints.UpdateAddress(ConvertAddressPointToAddressPointDetails(activeAddressPoint));
    }
    return true;
}

/** Convert AddressPoint internal data model to AddressPointDetails API contract. */
function ConvertAddressPointToAddressPointDetails(address: AddressPoint): AddressPointDetails {
    return {
        AddressPointId: address.AddressPointId!,
        Name: address.Name!,
        DisplayText: address.Address?.DisplayText!,
        GooglePlaceId: address.Address?.GooglePlaceId!,
        PickupNotes: address.PickupNotes,
        DropoffNotes: address.DropoffNotes
    }
}