import React from 'react';
import { FeatureFlags } from '../../../Config/FeatureFlags';
import { QuickBookExpandedSection } from '../../UILogicControl/UILogicControlEntities';
import { BookingInfo } from '../../MyBookings/MyBookingEntities';
import { RecentTripEntry } from './RecentTripEntry';


interface RecentTripProps {
    listState: QuickBookExpandedSection;
    disabledButton: boolean;
    recentTrips: BookingInfo[];
}

interface DisplayMenuState {
    displayMenuAnchorParent: HTMLButtonElement | null;
}
/**
 * Render the recent trip list
 * Onclick of 'Book again/'Book a return' button, perform the below steps:
   1. Populate the selected trip details
   2. Change to NewBooking Tab
 */
class RecentTripHistoryCore extends React.Component<RecentTripProps, DisplayMenuState> {

    render() {

        const { disabledButton, recentTrips, listState } = this.props;

        /** The Book again and Book a return buttons are disabled, when either of the below condition is met: 
            1. User is not logged-in
            2. Logged-in user but, has no previous booking history
        */            
        const disabledCssClass = disabledButton ? "btn-book-again-disabled" : "";       
        
        const maxHeightClass = FeatureFlags.BookingTemplates ? "recent-list-expand" : "recent-list-expand-no-template";

        // Expand the recent trip list
        const expandListClass = listState === QuickBookExpandedSection.RecentBookings && maxHeightClass;

        // Do not toggle the list for guest users
        const recentListClass = !disabledCssClass ? `recent-list ${expandListClass}` : "";

        const list = recentTrips && recentTrips.map((item : BookingInfo, index) => {

            return (
                <RecentTripEntry IsDisabled={this.props.disabledButton} Booking={item} key={index} />
            )            
        });       
        return(
            <div className={recentListClass}>{list}</div>
        )
    }
}

export const RecentTripHistory = RecentTripHistoryCore;