
/** The return value of an operation which has no direct output, but may fail with an error. */
export type ApiOutcome = SuccessApiOutcome | FailureApiOutcome;

/** The shape of the ApiOutcome type when describing a successful operation. */
export interface SuccessApiOutcome {

    /** Whether the operation completed successfully. */
    IsSuccess: true;
}

/** The shape of the ApiOutcome type when describing an error. */
export interface FailureApiOutcome {

    /** Whether the operation completed successfully. */
    IsSuccess: false;

    /** Details of the error causing this operation to fail. Only populated when IsSuccess = false. */
    Error: ApiError;
}

/** The output of an operation that normally returns a value of type {T}, but may fail and return an error instead. */
export type ApiResult<T> = FailureApiOutcome | SuccessApiResult<T>;

/** The shape of ApiResult<T> when successful */
export type SuccessApiResult<T> = SuccessApiOutcome & ResultPayload<T>;

/** The payload portion of an ApiResult<T> when successful */
export interface ResultPayload<T> {

    /** The output of the operation. */
    Value: T;
}

/** A description of an error returned from an API endpoint */
export interface ApiError {

    /** A complete description of the error, including recursive structure and system and error IDs. */
    Detail: ApiErrorDetail;

    /** A summary of the error, useful for display purposes. */
    Summary: ApiErrorSummary;
}

/** A detailed description of an error that caused an operation to fail. */
export interface ApiErrorDetail {

    /**
     * The top level error message, describing the immediate problem but not any causes.
     * Most suitable as a display message for end users.
     * May be null if this error was redacted by the service.
     */
    Message: string | null;

    /** 
     * The ID of the system / application that issued this error.
     * It should match the A2B Service Register ID.
     */
    SystemId: number;

    /**
     * The ID is unique within this application / system.
     * The ID values of any particular error won't change over time.
     * It can be relied upon by consumers to test for specific errors.
     */
    ErrorId: number;

    /** The intended audience or visibility for the detail of an error message. */
    Audience: ErrorAudience;

    /** An inner error which caused this error. It may be null. */
    Cause: ApiErrorDetail | null;
}

/** The intended audience or visibility of an error message. */
export enum ErrorAudience {

    /** This message is meaningful to an end user. */
    EndUser = 1,

    /** This message may confuse end users, but is safe to be returned from a public API. e.g. it may help external developers. */
    PublicApi = 2,

    /** 
     * This message is not intended to be exposed outside of A2B.
     * An error details with this audience would be redacted when they get to the website. 
     */
    A2BInternal = 3,
}

/** Summary of an error returned from an API, optimised for display purposes. */
export interface ApiErrorSummary {

    /** The error message and all inner error messages(causes). */
    FullMessage: string;

    /**
     * Compact code for just this error, not including any cause.
     * This should uniquely identify the immediate error.
     * Example: "12.34". Error ID 34 in system 12.
     */
    DisplayCode: string;

    /**
     * Display code describing this error and all the full tree of causes (inner errors). 
     * This should uniquely identify a full situation.
     * Example: "12.34;12.56;100.15". Error 34 in system 12, caused by error 56 in system 12, caused by error 15 in system 100.
     */
    FullDisplayCode: string;
}