
import moment from "moment";
import appstore from "../../appStore";
import { FullStoryAPI } from 'react-fullstory';
import { IsValidMobileNumber } from "../Utils/PhoneValidation";
import { HasLogInProperly } from '../Authentication/AuthHelper';
import { GetOverallBookingContact } from "./CreateBookingCommon";
import { LogEvent } from '../../utils/LogEvent';
import { PayDriverOption, PaymentOptionKind } from "../Payment/PaymentEntities";
import { BookingWorkFlowState } from "./Redux/BookingState";
import { BookingFormKind } from "../UILogicControl/UILogicControlEntities";
import { DateTime } from "luxon";
import { BookingDataOwnership } from "../../Services/BookingEntities";
import { LogEventInGA4 } from "../../utils/LogEventsInGoogleAnalytics";

/**
 * Successful booking creation logging component
 */
export function LogBookingCreation(isFixedFareBooking: boolean) {

    let accountNumber = "", orderNumber = "", selectedVehicle = "Next Available";

    // read store
    const state = appstore.getState();
    const accountData = state.booking.AccountData;
    const selectedCondition = state.condition.SelectedCondition;
    const bookingContact = GetOverallBookingContact();

    // account data
    if (accountData) {
        accountNumber = accountData.SelectedAccount.AccountNumber;
        orderNumber = accountData.OrderNumber || "";
    }

    // condition
    if (!!selectedCondition.ApiVehicle) {
        selectedVehicle = selectedCondition.ApiVehicle.Name;
    }

    const LogBookingCreationData: LogBookingCreationData = {
        Kind: HasLogInProperly() ? "Signed In User" : "Guest User",
        DateStamp: moment().format('YYYYMMDD'),
        IsLandline: (!IsValidMobileNumber(bookingContact.PhoneNumber)).toString(),
        AccountNumber: accountNumber,
        OrderNumber: orderNumber,
        Condition: selectedVehicle,
        FixedFare: isFixedFareBooking.toString(),
        PaymentMethod: GetPaymentMethodDescription(state.booking),
        BookingWidgetMode: state.uiLogicControl.BookingForm.ActiveBookingForm,
    };

    LogEvent.BookingCreationSuccess(LogBookingCreationData);
    FullStoryAPI('event', "Booking Creation Success", LogBookingCreationData );

    // international number
    // ISSUE: this australia-only logic can't be used. TODO: fix.
    //if (bookingContact.CountryCode !== "+61") {

    //    const countryName = state.verification.UserContactNumberInfo.CountryInfo?.CountryName;
    //    LogEvent.BookingCreatedWithInternationalNumber(countryName ?? "");
    //}
}

/**
 * Logging a successful booking creation in GA4.
 */
export function LogBookingCreationInGA4(newBookingId: number) {

    const state = appstore.getState();
    const bookingData = state.booking;
    const selectedCondition = state.condition.SelectedCondition;

    // Evaluate booking time
    const timeV2 = bookingData.BookingTimeV2;
    const localTime = timeV2.IsImmediate ? DateTime.now().toISO() : timeV2.RequestedDate.toISO();

    const LogBookingCreationDataInGA4: LogBookingCreationDataInGA4 = {

        // at this point Address is not null, FullTextAddress is defined as nullable but not sure why (check the comment for this property in AddressV2)
        PickupInput: bookingData.Locations[0].Address!.FullTextAddress ?? "",
        DropOffInput: bookingData.Locations[1].Address!.FullTextAddress ?? "",
        FareAmount: selectedCondition.FareDetails?.FixedPrice.GrandTotalCustomerPaysDollars ?? null,
        PickupDateTime: localTime,
        BookingId: newBookingId,
        UserId: state.authentication?.UserProfile?.UserId ?? BookingDataOwnership.Guest,
        VehicleType: selectedCondition.ApiVehicle?.Name ?? "Next Available",
        PaymentMethod: GetPaymentMethodDescription(state.booking),
        DetailedPaymentMethod: GetDetailedPaymentMethodDescription(state.booking)
    };

    LogEventInGA4.CreateBooking(LogBookingCreationDataInGA4);
}

/**
 * Provides the payment method for a booking
 */
function GetPaymentMethodDescription(booking: BookingWorkFlowState): string {

    // Check whether booking on accounts
    if (booking.AccountData && booking.IsOnAccount) return "Booking on accounts";

    // Check whether paying by card. 
    if (booking.PaymentOption?.Kind === PaymentOptionKind.CardOrWallet) return "Paying by card";

    // Paying the Driver directly.
    return PayDriverOption.Name;
}

/** Returns the booking's payment method in detail in a specific format that describes the high level payment type. */
function GetDetailedPaymentMethodDescription(booking: BookingWorkFlowState): string {
    if (booking.AccountData && booking.IsOnAccount) return "account";

    if (booking.PaymentOption) {
        if (booking.PaymentOption.Type == "GooglePay") return "cnp_googlepay";

        if (booking.PaymentOption.Type == "ApplePay") return "cnp_applepay";

        if (booking.PaymentOption.Card) {
            if (booking.PaymentOption.Card.CardType === "PayPal") return "cnp_paypal";

            // various Cabcharge payment methods
            if (booking.PaymentOption.Card.CardType === "DIGITALFASTCARD") return "cnp_cabcharge_digital_fastcard";
            if (booking.PaymentOption.Card.CardType === "Cabcharge") return "cnp_cabcharge_physical_fastcard";
            if (booking.PaymentOption.Card.CardType === "DIGITALPASS") return "cnp_cabcharge_digital_pass";

            return "cnp_credit_debit_card";
        }
    }

    // TODO: there will be more in this category in future once the cash payment methods are separated (Concierge, TSS etc). adding this TODO comment as a reminder to update it once the new payment methods are added.
    return "pay_driver_paying_the_driver_directly";
}

/**
 * The data object which is used for logging successful booking creation
 */
export interface LogBookingCreationData {
    Kind: string;
    DateStamp: string;
    IsLandline: string;
    AccountNumber: string;
    OrderNumber: string;
    Condition: string;
    FixedFare: string;
    PaymentMethod: string;
    BookingWidgetMode: BookingFormKind;
}

/**
 * The data object which is used for logging successful booking creation in GA4
 */
export interface LogBookingCreationDataInGA4 {

    /** Will be null when 'Address.FullTextAddress' is null. */
    PickupInput: string | null;
    DropOffInput: string | null;
    PickupDateTime: string;
    VehicleType: string;
    FareAmount: number | null;
    BookingId: number;
    UserId: string;

    /** Booking's payment method. Just a high level description. e.g. "Paying by card" or "Booking on accounts" etc. This data is also included in the 'DetailedPaymentMethod', but keeping this to not ruin historical data.  */
    PaymentMethod: string;

    /** Booking's payment method in detail including the high level payment type (CNP, Pay driver directly). e.g: cnp_googlepay, pay_driver_tss etc */
    DetailedPaymentMethod: string;
}