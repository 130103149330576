import React from 'react';
import { Dispatch } from '../../Dispatch';
import CredentialsController from './CredentialsController';
import { StartLoginSession } from '../LoginSession';
import { StoreNewAuthToken } from '../AuthHelper';
import { ChildMessageKind, LoginResultMessage, MessageFromChild, AuthType } from './LoginEntities';
import { Credentials } from '../AuthEntities';
import { GenerateRunTimeUrl } from '../../Utils/GenerateRunTimeUrl';
import { LogEvent } from '../../../utils/LogEvent';
import { GetUserAuthErrorText } from './GetUserAuthErrorText';

export interface SilentLoginProps {
    AuthType: AuthType;
    Credentials: Credentials;
}

/**
 * Performs a silent (not user visible) login to Auth0 inside an iFrame. 
 * This avoids a redirect causing the application to reload in the main window.
 */
class SilentLogin extends React.PureComponent<SilentLoginProps> {

    private iFrameRef: React.RefObject<HTMLIFrameElement>;

    constructor(props: SilentLoginProps) {
        super(props);
        this.iFrameRef = React.createRef();
    }

    /** Register to receive messages from the child */
    componentDidMount() {
        window.addEventListener('message', this.onWindowMessageReceived);
    }

    /** Clean up event handlers */
    componentWillUnmount() {
        this.closeWindow();
    }

    /** Close the Login.tsx popup */
    closePopup = () => new CredentialsController().ClosePopup();

    closeWindow = () => window.removeEventListener("message", this.onWindowMessageReceived);

    onWindowMessageReceived = (event: MessageEvent) => {

        // Security check
        if (event.origin != window.location.origin) return;

        const childMessage: MessageFromChild = event.data;

        if (childMessage.MessageKind === ChildMessageKind.ChildIsReady) {
            this.iFrameRef.current!.contentWindow!.postMessage(this.props, window.location.origin);
        }
        else if (childMessage.MessageKind === ChildMessageKind.LoginResult) {

            if (!childMessage.LoginResult) return;

            const data: LoginResultMessage = childMessage.LoginResult;
            Dispatch.UILogicControl.EndCredentialsApiRequest();
            this.closeWindow();

            if (data.IsSuccess) {

                LogEvent.UserSignInSuccess(data.AuthToken!);

                const isLogin = this.props.AuthType === AuthType.Login;

                // Do not show add payment card screen to logged-in user
                if (isLogin) Dispatch.Payment.HideAddPaymentCardScreenAfterSignup();

                StoreNewAuthToken(data.AuthToken!);

                this.closePopup();
                
                StartLoginSession(!isLogin);
            }
            else {
                const authError = data.Error!;
                const errorMessage = GetUserAuthErrorText(authError, this.props.AuthType);

                Dispatch.Auth.LoginErrorMessage(errorMessage);
                LogEvent.UserSignInFailed(authError);
            }

            Dispatch.Auth.HideSilentLogin();
        }
    }

    render() {
        return <iframe style={{ visibility: "hidden", position: "fixed", bottom: "0px" }} title="iframe" src={GenerateRunTimeUrl("login")} ref={this.iFrameRef} />
    }
}

export default SilentLogin;