
/** GPS Coordinates */
export interface GeoPoint {

    latitude: number,
    longitude: number,
}

/** Where a piece of location data has come from */
export enum LocationSourceKind {

    /** Automatic Golocation from the user's IP address */
    IPGeolocation = "IP",

    /** A location the user has selected from the location chooser panel, OR equivalently from the pickup address */
    UserSelected = "UserPick",

    /** Hard coded / fixed fallback data used when all other sources had no data */
    Fallback = "Fallback",

    /** Location from the URL query string when redirected from the CMS  */
    URLLocation = "URL"
}

/** Detailed information about a location: GeoPoint, name, state, etc */
export interface Location {

    /** True when the data is in Australia and in a known state. */
    isValid: boolean;

    /** Lower case state: "nsw" / "sa" / etc 
     * This is 'null' sometimes from IP Geolocation API.
     */
    stateCode: string | null;

    /** Latitude and Longitude */
    geoPoint: GeoPoint;

    /** 
     *  This may be a city name e.g. "Sydney", or sometimes a suburb or even state name. 
     *  This is being defensively typed as nullable, possibly coming from IP Geolocation API not having a name for a city. 
     */
    displayName: string | null;

    /** True when we have services in this Australian state. This is a precondition for LocationData.IsValid. */
    isStateServiced: boolean;

    /** This valid is not initially populated, and only becomes known if we request conditions from the server. It should be assumed true when not known. It is set to false by reportLocationNonserviceable */
    isGeopointServiced?: boolean;
}

/** A Location and metadata describing its source and validity */
export type LocationData = KnownLocation | UnknownLocation;

/** A location sample which provided good data */
export interface KnownLocation {

    /** Where the data came from, e.g. user pick or IP geolocation */
    source: LocationSourceKind;

    /** The discriminator field */
    isKnown: true;

    /** The actual location data */
    value: Location;
}

/** A location sample which provided no value. */
export interface UnknownLocation {

    /** Where the data came from, e.g. user pick or IP geolocation */
    source: LocationSourceKind;

    /** The discriminator field */
    isKnown: false;
}