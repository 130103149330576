import React from "react";
import { styled} from "@mui/material";

import { BookingFormKind } from "../../../../UILogicControl/UILogicControlEntities";
import { StyledTab, StyledTabs } from "../Tabs";

interface StyledTabsProps {
    children?: React.ReactNode;
    value: BookingFormKind;
    onChange: (event: React.SyntheticEvent, newValue: BookingFormKind) => void;
}

interface StyledTabProps {
    label: string;
    icon: React.ReactElement;
    value: BookingFormKind;
}

/** Customized Booking Form Tabs by Re-using the styles of the StyledTabs component.*/
export const BookingFormTabs = styled((props: StyledTabsProps) => (
    <StyledTabs
      {...props} centered
    />
))();

/** Customized Booking Form Tab Item by Re-using the styles of the StyledTab component.*/
export const BookingFormTab = styled((props: StyledTabProps) => (
    <StyledTab disableRipple {...props} />
))(() => ({
    minHeight: 36,
    height: 36,
    width: 170,
}));