import { Api } from "../../Services/Api";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { Dispatch } from "../Dispatch";
import { ConvertBookingInfoV2ToBookingInfoBulk } from "../MyBookings/ConvertAPIContractsToInternalModels";
import { BookingAuthority } from "../../Services/BookingEntities";
import { BookingLink } from "./Redux/TrackingLinksState";
import { ConsiderLoadDriverDetailsOfRefreshedBookings } from "../MyBookings/Logic/RefreshDriverDetails";

/** Use V1 API to load link bookings with URL path /b2/ (LinkVersion === 2) */
export async function LoadLinksV2(bookingLinks: BookingAuthority[]): Promise<void> {

    const result = await Api.BookingV2.GetBookingsByLinkBulk(bookingLinks);

    if (!result.isSuccess) return;

    const linkedBookings = result.value;
    if (linkedBookings.length > 0) {
        const linkedBookingsList = ConvertBookingInfoV2ToBookingInfoBulk(linkedBookings);
        Dispatch.MyBookings.RefreshLinkBookings(linkedBookingsList);
        // Add to the All bookings list
        Dispatch.MyBookings.AddLinkBookingsToAll(linkedBookingsList);

        // Consider to load driver details
        ConsiderLoadDriverDetailsOfRefreshedBookings(linkedBookingsList);
    }
}

/** Use V2 API to check links with LinkVersion === 2 */
export async function CheckNewLinkV2(newLink: BookingLink): Promise<void> {

    const result = await Api.BookingV2.CheckLink(newLink.BookingKey);

    if (!result.isSuccess) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidBookingLink);
        return;
    }

    Dispatch.TrackingLinks.AddLink(newLink);
}