import * as React from 'react';
import { ContactFormFieldsV2, ContactUsStateV2  } from './ContactFormEntities';
import { SendContactRequest, SendContactRequestBooking, SendContactRequestHail } from './ContactFormEntities';
import './ContactForm.scss';
import { SectionDefinition } from './ContactFormSections';
import { LocationData } from '../Location/Entities';
import { ApplicationState } from '../../appState';
import { connect } from 'react-redux';
import { Api } from '../../Services/Api';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ReactNode } from 'react';
import { MobileDialog } from '../Dialog/MobileDialog';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import ContactFormInputsV2 from './ContactFormInputsV2';
import { ContactFormDataV2 } from './ContactFormDataV2';

interface PropsFromStore {
    IsMobileDevice: boolean;
    preferredLocation: LocationData;
}

/** Main component of the contact form. */
class ContactForm extends React.Component<PropsFromStore, ContactUsStateV2> {
    selectCategoryOption: ReactNode;

    constructor(props: PropsFromStore) {
        super(props);
        this.state = {
            selectedSection: 0,
        };

        this.updateSection = this.updateSection.bind(this);
    }

    // this type is really complex, too hard to describe. 
    public updateSection(newValue: any) {
        this.setState(
            {
                selectedSection: (newValue.target && newValue.target.value) || 0,
            });
    }

    public render() {
        // Get section data (dropdown options)
        const content = ContactFormDataV2;
        const current = content[this.state.selectedSection || 0];
        const selectOptions = content.map((entry, index) => {
            return <MenuItem value={index} key={index}>{entry.section}</MenuItem>
        })

        const labelText =  !!selectOptions ? "Enquiry type" : "";
        
        return (
            <div className="contact-us-form">
                <div className="contact-form-step">
                    <div className="step-data">
                        <div>
                            <span className="step-number">Step 1/3</span>
                            <h1 className="contact-title">How can we help</h1>
                        </div>
                        <p className="contact-us-description">Tell us about your enquiry.</p>
                        <p className="contact-form-info">Please select below (required)</p>
                        <div className="section-selection reason">
                            <FormControl variant="outlined" className="custom-select" fullWidth={true}>
                                <InputLabel id="section-selection-label">{labelText}</InputLabel>
                                <Select
                                    label={labelText}
                                    value={this.state.selectedSection}
                                    onChange={this.updateSection}
                                    autoFocus
                                >
                                    {selectOptions}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="step-padding"></div>
                </div>

                <ContactFormInputsV2
                    locationData={this.props.preferredLocation}
                    currentData={current}
                    currentSection={this.state.selectedSection}
                    onSubmit={(formData: ContactFormFieldsV2) => this.onSubmit(formData, current)} />

                { this.props.IsMobileDevice && <MobileDialog /> }
            </div>
        );
    }

    /**
     * Format pickup date-time.
     */
    private getPickupDate(form: ContactFormFieldsV2): string {
        
        if (form.pickupDateTime) {
            return form.pickupDateTime.toISO();
        }

        return '';
    }

    /**
     * Prepare request data and send the request.
     */
    private onSubmit(form: ContactFormFieldsV2, contactEntry: SectionDefinition) {
       
        let request: SendContactRequest;
        const pickupDate = this.getPickupDate(form);
        if (this.state.selectedSection) {
            request = {
                city: this.GetBestCityName(),
                state: this.GetBestStateCode(),
                userType: contactEntry.label,
                categorisation: [contactEntry.label],
                notes: form.itemDescription,
                contact: {
                    email: form.emailAddress,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    phone: form.custContactNumber,
                },
                source: "DesktopWeb"
            };

            if ((contactEntry.label != "General Enquiry") && form.selectedBookingHail === 'booking') {
                (request as SendContactRequestBooking).booking = {
                    reference: form.bookingRef,
                    name: form.bookingName,
                    phoneNumber: form.bookingContactNumber,
                    jobDate: pickupDate,
                    driver: form.driverId,
                    taxi: form.cabNumber,
                };
            } else if ((contactEntry.label != "General Enquiry") && form.selectedBookingHail === 'hail') {
                (request as SendContactRequestHail).hail = {
                    reference: form.bookingRef,
                    jobDate: pickupDate,
                    driver: form.driverId,
                    taxi: form.cabNumber,
                    pickup: { suburb: form.pickup.suburb, street: form.pickup.street },
                    destination: { suburb: form.destination.suburb, street: form.destination.street },
                };
            }
            return Api.Contact.SubmitEnquiry(request);
        } else {
            return Promise.reject('Please select some section');
        }
    }

    /**
     * If preferred location is undefined, put vic/Melbourne as the default location.
     */
    private GetBestCityName(): string {
        const location = this.props.preferredLocation;

        if (location.isKnown && location.value.displayName) {
            return location.value.displayName;
        }

        return "Melbourne";
    }

    /**
     * If the state is non-deterministic, set VIC as default state.
     */
    private GetBestStateCode(): string {
        const location = this.props.preferredLocation;

        if (!location.isKnown) return "vic";

        return location.value.stateCode ?? "vic";
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        preferredLocation: state.location.preferredLocation,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }
}

export default connect(mapStateToProps)(ContactForm);