import { Button, Typography } from "@mui/material";
import React from "react";
import DialogClose from "../Dialog/DialogClose";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { PayPal } from "./PayPal";

export const UnlinkPayPal: React.FC<{}> = () => {

    const CloseDialog = () => {
        Dispatch.Dialog.CloseDialog(DialogKind.UnlinkPayPal);
    }

    return (
        <div className="PriceGuarantee-details">
            <DialogClose />
            <Typography variant='h5' color='primary' align='center' gutterBottom>Unlink PayPal</Typography>
            <div className="PriceGuarantee-description"><Typography variant='body1' align="center">Are you sure you want to unlink your PayPal account?</Typography></div>
            <div className="unlink-btn-group">
                <Button onClick={CloseDialog} variant='outlined' color='primary' className="unlink-dialog-btn">Cancel</Button>
                <Button onClick={() => PayPal.UnlinkPayPal()} variant='contained' color='primary' className="unlink-dialog-btn">Unlink</Button>
            </div>            
        </div>
    );
}