import { ApiFailureTestingState, InitialApiFailureTestingState } from "./FailureTestingState";
import { ApiFailureTestingAction, ApiFailureTestingActionType } from "./FailureTestingActions";
import { Reducer } from "redux";

/** Reducer for the API Failure Testing feature. */
export const ApiFailureTestingReducer: Reducer<ApiFailureTestingState, ApiFailureTestingAction> = (state = InitialApiFailureTestingState, action) => {
    if (action.type === ApiFailureTestingActionType.ConfigChange) {
        return {
            ...state,
            ...action.newState,
        };
    }

    return state;
}