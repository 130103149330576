import { Button } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useAppState } from "../../../Redux/ReduxHooks";
import { Dispatch } from "../../Dispatch";

/** Renders a button which creates more address input fields up to a specified limit. Then the button gets hidden. */
export const AddDestinationButton: React.FC = () => {  

    const currentLocations = useAppState(state => state.booking.Locations);
    const lastLocationAddress = currentLocations[currentLocations.length - 1].Address;

    /** Adds an empty location in the booking state which will create a new input field in the UI. */
    function AddAddressField() {
        Dispatch.Booking.AddEmptyLocation();
    }

    if (currentLocations.length >= 5) return null;
    if (lastLocationAddress == null && currentLocations.length > 2) return null;

    return (
        <Button variant="text" startIcon={<AddCircleOutlineIcon />} onClick={() => AddAddressField()} className="add-destination-button">Add destination</Button>        
    );
}
