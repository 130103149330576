import { ContactKind, ContactRequiredDetailsType } from "./ContactFormEntities";

/** Type for contact category */
export interface ContactCategory extends ContactSubCategory {
    label: string;
    subCategory?: ContactSubCategory[];
}

/** Type for the first level of the sub categories. */
export interface ContactSubCategory extends ContactSubCategory2 {
    label: string;
    subCategory2?: ContactSubCategory2[];
}

/** Second level of the sub categories. They don't have a value */
export interface ContactSubCategory2 extends ContactSubCategory3 {
    label: string;
    subCategory3?: ContactSubCategory3[];
}

/** Third level of the sub categories. They only have a label. */
export interface ContactSubCategory3 {
    label: string;
}

/** Main categorisation entity. */
export interface CategoryDefinition extends ContactCategory, ContactSubCategory {
    /** Type of the user. Passenger, Operator etc. */
    sectionKind: ContactKind;

    /** Required details for the for each section kind. */
    requiredDetails?: ContactRequiredDetailsType;

    /** Sub categories for each section kind */
    subCategories?: ContactCategory[];

    /** Fleet types. 13cabs/silver service etc */
    fleetTypes?: ContactSubCategory3[];
}

export interface ContactEntry extends CategoryDefinition {
    section: string;
    sectionKind: ContactKind;
}

/** List of categories and sub categories. */
export const feedbackCategories: Record<ContactKind, CategoryDefinition> = {
    [ContactKind.PleaseSelect]: {
        label: 'Please Select',
        sectionKind: ContactKind.PleaseSelect,
    },
    [ContactKind.PassengerCategory]: {
        label: 'Passenger',
        requiredDetails: ContactRequiredDetailsType.ALL,
        subCategories: [
            { label: 'Please Select' },
            {
                label: 'Lost Property',
                subCategory: [
                    { label: 'Please Select' },
                    {
                        label: 'Phone',
                        subCategory2: [
                            { label: 'Please Select' },
                            {
                                label: 'iPhone',
                                subCategory3: [
                                    { label: 'Please Select' },
                                    { label: 'iPhone 6' },
                                    { label: 'iPhone 7' },
                                    { label: 'iPhone 8' },
                                    { label: 'iPhone 9' },
                                    { label: 'iPhone X' },
                                    { label: 'iPhone XS' },
                                    { label: 'iPhone XR' },
                                    { label: 'iPhone 11' },
                                    { label: 'iPhone Other' },
                                ]
                            },
                            {
                                label: 'Samsung',
                                subCategory3: [
                                    { label: 'Please Select' },
                                    { label: 'Galaxy S7' },
                                    { label: 'Galaxy S8' },
                                    { label: 'Galaxy S9' },
                                    { label: 'Galaxy S10' },
                                    { label: 'Galaxy A7' },
                                    { label: 'Galaxy A8' },
                                    { label: 'Galaxy A9' },
                                    { label: 'Galaxy A10' },
                                    { label: 'Samsung Other' },
                                ]
                            },
                            { label: 'Google' },
                            { label: 'HTC' },
                            { label: 'LG' },
                            { label: 'Sony' },
                            { label: 'OnePlus' },
                            { label: 'Oppo' },
                            { label: 'Nokia' },
                            { label: 'Huawei' },
                            { label: 'Phone Brand Other' },
                        ]
                    },
                    {
                        label: 'Notebook/Laptop',
                        subCategory2: [
                            { label: 'Please Select' },
                            { label: 'Acer' },
                            { label: 'Apple' },
                            { label: 'Asus' },
                            { label: 'HP' },
                            { label: 'Dell' },
                            { label: 'Samsung' },
                            { label: 'Lenovo' },
                            { label: 'Notebook/Laptop Other' },
                        ]
                    },
                    { label: 'Wallet' },
                    { label: 'Purse' },
                    { label: 'Bag' },
                    { label: 'Keys' },
                    { label: 'Glasses' },
                    { label: 'Passport/Visa/ID' },
                    {
                        label: 'Lost Property Other',
                        subCategory2: [
                            { label: 'Please Select' },
                            { label: 'Clothing' },
                            { label: 'Beauty Products' },
                            { label: 'Umbrella' },
                            { label: 'Alcohol' },
                            { label: 'e-cigarettes/Tobacco' },
                            { label: 'Medication/Hearing Aids' },
                            { label: 'Jewellery & Watches' },
                            { label: 'Lost Property Other' },
                        ]
                    },
                ]
            },
            { label: 'Compliment' },
            {
                label: 'Complaint',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Arrival Time' },
                    { label: 'Driving' },
                    { label: 'Fare Price' },
                    { label: 'Refused Fare' },
                    { label: 'Driver Service' },
                    { label: 'Vehicle Condition' },
                    { label: 'Collection' },
                    { label: 'Other' },
                ]
            },
            { label: 'Trip Receipt' },
            {
                label: 'General Trip Information',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Taxi Prices / Fare Estimate' },
                    { label: 'Child Seats' },
                    { label: 'Gov. Subsidy Scheme' },
                    { label: 'National Disability Ins. Scheme' },
                    { label: 'Fare Payment Options' },
                    { label: 'Prepaid Fares' },
                    { label: 'Assistance Animals' },
                ]
            },
            { label: '13cabs Passenger App' },
            { label: 'Report an Accident' },
            {
                label: '13cabs Account Enquiry',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'New Corporate Account' },
                    { label: 'Transport Statements' },
                    { label: 'Taxi Bulter' },
                    { label: 'Other' },
                ]
            },
            { label: 'Other' },
        ],
        sectionKind: ContactKind.PassengerCategory,
    },
    [ContactKind.GeneralPublic]: {
        label: 'General Public',
        requiredDetails: ContactRequiredDetailsType.BASIC,
        subCategories: [
            { label: 'Please Select' },
            { label: 'Compliment' },
            {
                label: 'Complaint',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Arrival Time' },
                    { label: 'Driving' },
                    { label: 'Fare Price' },
                    { label: 'Refused Fare' },
                    { label: 'Driver Service' },
                    { label: 'Vehicle Condition' },
                    { label: 'Collection' },
                    { label: 'Other' },
                ]
            },
            {
                label: '13cabs Account Enquiry',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'New Corporate Account' },
                    { label: 'Transport Statements' },
                    { label: 'Taxi Bulter' },
                    { label: 'Other' },
                ]
            },
            {
                label: 'General Trip Information',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Taxi Prices / Fare Estimate' },
                    { label: 'Child Seats' },
                    { label: 'Gov. Subsidy Scheme' },
                    { label: 'National Disability Ins. Scheme' },
                    { label: 'Fare Payment Options' },
                    { label: 'Prepaid Fares' },
                    { label: 'Assistance Animals' },
                ]
            },
            { label: '13cabs Employment' },
            { label: 'Report an Accident' },
            { label: 'Hazards & Roadworks' },
            { label: 'Other' },
        ],
        sectionKind: ContactKind.GeneralPublic,
    },
    [ContactKind.Driver]: {
        label: '13cabs Driver',
        requiredDetails: ContactRequiredDetailsType.BASIC,
        subCategories: [
            { label: 'Please Select' },
            {
                label: 'Driver Training & IT',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Advanced Driver' },
                    { label: 'WATS Training' },
                    { label: 'Driver Remedial' },
                    { label: 'Driver Induction Training' },
                    { label: 'Training Centres' },
                    { label: 'MTI / Dispatch Query' },
                    { label: '13cabs Driver App' },
                ]
            },
            { label: 'Driver IDs' },
            { label: 'Driver Uniform' },
            { label: 'Report an Accident' },
            { label: 'Hazards & Roadworks' },
            {
                label: 'Lost Property',
                subCategory: [
                    { label: 'Please Select' },
                    {
                        label: 'Phone',
                        subCategory2: [
                            { label: 'Please Select' },
                            {
                                label: 'iPhone',
                                subCategory3: [
                                    { label: 'Please Select' },
                                    { label: 'iPhone 6' },
                                    { label: 'iPhone 7' },
                                    { label: 'iPhone 8' },
                                    { label: 'iPhone 9' },
                                    { label: 'iPhone X' },
                                    { label: 'iPhone XS' },
                                    { label: 'iPhone XR' },
                                    { label: 'iPhone 11' },
                                    { label: 'iPhone Other' },
                                ]
                            },
                            {
                                label: 'Samsung',
                                subCategory3: [
                                    { label: 'Please Select' },
                                    { label: 'Galaxy S7' },
                                    { label: 'Galaxy S8' },
                                    { label: 'Galaxy S9' },
                                    { label: 'Galaxy S10' },
                                    { label: 'Galaxy A7' },
                                    { label: 'Galaxy A8' },
                                    { label: 'Galaxy A9' },
                                    { label: 'Galaxy A10' },
                                    { label: 'Samsung Other' },
                                ]
                            },
                            { label: 'Google' },
                            { label: 'HTC' },
                            { label: 'LG' },
                            { label: 'Sony' },
                            { label: 'OnePlus' },
                            { label: 'Oppo' },
                            { label: 'Nokia' },
                            { label: 'Huawei' },
                            { label: 'Phone Brand Other' },
                        ]
                    },
                    {
                        label: 'Notebook/Laptop',
                        subCategory2: [
                            { label: 'Please Select' },
                            { label: 'Acer' },
                            { label: 'Apple' },
                            { label: 'Asus' },
                            { label: 'HP' },
                            { label: 'Dell' },
                            { label: 'Samsung' },
                            { label: 'Lenovo' },
                            { label: 'Notebook/Laptop Other' },
                        ]
                    },
                    { label: 'Wallet' },
                    { label: 'Purse' },
                    { label: 'Bag' },
                    { label: 'Keys' },
                    { label: 'Glasses' },
                    { label: 'Passport/Visa/ID' },
                    {
                        label: 'Lost Property Other',
                        subCategory2: [
                            { label: 'Please Select' },
                            { label: 'Clothing' },
                            { label: 'Beauty Products' },
                            { label: 'Umbrella' },
                            { label: 'Alcohol' },
                            { label: 'e-cigarettes/Tobacco' },
                            { label: 'Medication/Hearing Aids' },
                            { label: 'Jewellery & Watches' },
                            { label: 'Lost Property Other' },
                        ]
                    },
                ]
            },
            { label: 'Other' },
        ],
        sectionKind: ContactKind.Driver,
    },
    [ContactKind.Operator]: {
        label: '13cabs Operator',
        requiredDetails: ContactRequiredDetailsType.BASIC,
        subCategories: [
            { label: 'Please Select' },
            { label: 'Cab Access' },
            { label: 'Bureau Enquiry' },
            { label: 'Operator Account Query' },
            {
                label: 'Vehicle Tech (Taxitech)',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Taxi Fit-out' },
                    { label: 'Security Cameras' },
                    { label: 'Taxitech Inspections' },
                    { label: 'Taxitech Locations' },
                    { label: 'Bureau Parts' },
                    { label: 'Other' },
                ]
            },
            { label: 'Report an Accident' },
            { label: 'Other' },
        ],
        sectionKind: ContactKind.Operator,
    },
    [ContactKind.NewDriverOperator]: {
        label: 'New Driver or Operator',
        requiredDetails: ContactRequiredDetailsType.BASIC,
        subCategories: [
            { label: 'Please Select' },
            { label: 'Becoming a 13cabs Driver' },
            {
                label: 'Becoming an Operator',
                subCategory: [
                    { label: 'Please Select' },
                    { label: '13cabs' },
                    { label: 'Silver Service' },
                    { label: 'WATS' },
                    { label: '13cabs Tiger Taxi' },
                ]
            },
            {
                label: 'Vehicle Tech (Taxitech)',
                subCategory: [
                    { label: 'Please Select' },
                    { label: 'Taxi Fit-out' },
                    { label: 'Security Cameras' },
                    { label: 'Taxitech Inspections' },
                    { label: 'Taxitech Locations' },
                    { label: 'Bureau Parts' },
                    { label: 'Other' },
                ]
            },
            { label: 'Plate Management' },
            { label: 'Vehicle Leasing & Sales' },
            { label: 'Bureau Enquiry' },
        ],
        sectionKind: ContactKind.Operator,
    },
    [ContactKind.PoliceRegulator]: {
        label: 'Police or Regulator',
        requiredDetails: ContactRequiredDetailsType.BASIC,
        subCategories: [
            { label: 'Please Select' },
            { label: 'Camera Download' },
            { label: 'Police Assistance' },
            { label: 'Police Enquiry Other' },
            { label: 'Regulator Enquiry Other' },
        ],
        sectionKind: ContactKind.PoliceRegulator,
    }
};
