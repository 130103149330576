import { DialogKind, WellKnownErrorMessage, RetryErrorMessage } from '../../modules/Dialog/DialogEntities';
import { Dispatch } from "../../modules/Dispatch";

/**
 * Wapper function to:
 *     1) Accept WellKnownErrorMessage type message;
 *     2) Display dialog to show SimpleErrorMessaging.
 */
export function ShowDialogSimpleErrorMessage(message: WellKnownErrorMessage) : void {
    Dispatch.Dialog.SetSimpleErrorMessage(message); 
    Dispatch.Dialog.ShowDialog(DialogKind.SimpleErrorMessaging);
}

/**
 * Wapper function to:
 *     1) Accept RetryErrorMessage type message;
 *     2) Display dialog to show RetryrrorMessaging.
 */
export function ShowDialogRetryErrorMessage(message: RetryErrorMessage) : void {
    Dispatch.Dialog.SetRetryErrorMessage(message); 
    Dispatch.Dialog.ShowDialog(DialogKind.RetryErrorMessaging);
}
