/**
 * Booking Template (a.k.a. Favourite) data. Only BookingTemplateId and Name are mandatory.
 * */
export interface BookingTemplate {
    /** Unique ID of the booking template. */
    BookingTemplateId: number;

    /** Booking template name given by the user. */
    Name: string;

    /** Owner (creator) of the booking template. */
    UserId?: number;

    /** Company ID if the creator of the template belonged to a company. When populated, any user from the company can see it. */
    CompanyId?: number;

    /** Selected conditions. */
    Conditions?: number;

    /** Account number if the favourite is a company favourite. */
    AccountNumber?: string;

    /** Order number or any custom field set by the company. */
    AccountReferenceText?: string;

    /** Notes to driver.
     * OBSOLETE: Keeping this for backwards compatibility. But for any new Template creation, use BookingTemplateLocation.DriverNotes. */
    DriverNotes?: string;

    /** Unique ID of the payment card. */
    PaymentCardId?: string;

    /** List of locations  */
    Locations: BookingTemplateLocation[];
}

/**
 * Booking template location data. Can be either pickup or destination location. One template can have multiple locations.
 * */
export interface BookingTemplateLocation {
    /** The sequence of the location in its template (e.g: 0 = Pickup, 1 = Dropoff) */
    SequenceNumber: number;

    /** The display text of the address (e.g: A2B Australia Limited, Riley Street,...) */
    DisplayText?: string;

    /** Place ID of the specified location. */
    GoogleMapsPlaceId?: string;

    /** Contact name for the location. */
    ContactName?: string;

    /** 
     *  Contact number for the location.
     *  This is meant to be formatted in E.164 (leading '+' and country dial code prefix) but I'm not sure this is being done universally. 
     */
    ContactPhone?: string;

    /** Booking template ID this location belongs to. */
    BookingTemplateId: number;

    /** Notes to driver for the location. */
    DriverNotes?: string;
}

/**
 * Booking template mode
 */
export enum BookingWidgetModeKind {
    
    /** Displays the booking form */
    Booking = 'Booking',

    /** Displays the create template (favourite) */
    CreateTemplate = 'CreateTemplate',

    /** Displays the edit template (favourite) */
    EditTemplate = 'EditTemplate'
}

/**
 * Booking template loading status
 */
export enum BookingTemplateLoadKind {

    /** Default value */
    Idle = 'Idle',

    /** Before calling the GetMyTemplates API */
    Loading = 'Loading Booking Templates',    

    /** After calling the GetMyTemplates API, booking templates are returned */
    Loaded = 'Booking Templates Loaded'
}

/** Request data to delete a booking template */
export interface BookingTemplateDeletionRequest {

    /** Unique ID of the Booking Template to be removed. */
    BookingTemplateId: number;
}

/**
 * This typing defines the account related information needed from templates, recent trips and trip history to booking
 */
export interface TemplateToBookingAccountInfo {
    AccountNumber: string | undefined;
    FileName: string;
    OrderNumber: string | undefined;
}
