import { ApiGeoPoint } from './BookingEntities';
import { TaxiFareEstimateRequestV2, TaxiFareEstimateResponseV2 } from './FareEntities';
import { CreateBookingRequest, CreateBookingResponse, ServiceAvailability, Vehicle } from './MakeBookingContracts';
import { ServiceResult } from './ServiceEntities';
import { Post, GetManagementServiceRootUrlWithoutState } from './ServiceManager';

/** Base URL for the MakeBookingController in the Booking Management Service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}MakeBooking/`;
}

/** Client for the MakeBookingController in the Management API */
class MakeBookingServiceClass {

    /** Create booking using Global Booker. */
    public CreateBooking(request: CreateBookingRequest): Promise<ServiceResult<CreateBookingResponse>> {
        const url = `${GetBaseUrl()}Create`;
        return Post<CreateBookingResponse>(url, request);
    }

    /** Check cab availability and get vehicle conditions using Global Booker. */
    public CheckCabServiceAvailability(request: ApiGeoPoint): Promise<ServiceResult<ServiceAvailability>> {
        const url = `${GetBaseUrl()}CheckCabServiceAvailability`;
        return Post<ServiceAvailability>(url, request);
    }

    /**
    * Request a fare estimate using Global Booker.
    * The input includes pickup and dropoff coordinates and time of day. 
    */
    public GetFare(request: TaxiFareEstimateRequestV2): Promise<ServiceResult<TaxiFareEstimateResponseV2>> {
        const url = `${GetBaseUrl()}GetEstimatedFare`;
        return Post<TaxiFareEstimateResponseV2>(url, request);
    }

    /** Get all the available vehicles for the brand. */
    public GetAllVehicles(): Promise<ServiceResult<Vehicle[]>> {
        const url = `${GetBaseUrl()}AllVehicles`;
        return Post<Vehicle[]>(url, null);
    }
}

/** Singleton export for static access to the operations */
export const MakeBookingService = new MakeBookingServiceClass();