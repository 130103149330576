import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import React from 'react';
import { BookingLocationInfo } from '../../Booking/Redux/BookingState';
import { useAppState } from '../../../Redux/ReduxHooks';
import { Dispatch } from '../../Dispatch';
import "./DraggableAddress.scss";
import { DraggableAddress } from './DraggableAddress';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { UILayoutMode } from '../../UILogicControl/UILogicControlEntities';
import { TouchBackend } from 'react-dnd-touch-backend';
import { usePreview } from 'react-dnd-preview';
import { IconButton, TextField } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';

export interface ContainerState {
    locations: LocationWithId[];
}

interface LocationWithId {
    Id: number;
    Location: BookingLocationInfo;
}

/** Contains all the input fields that can be reordered by drag and drop wrapped in <DndProvider>. */
export const DraggableAddressesContainer: React.FC = () => {
    {
        const currentLocations = useAppState(state => state.booking.Locations);
        const [locations, setLocations] = useState(currentLocations.slice(1).map((location, index) => ({ Id: index, Location: location })));

        useEffect(() => {
            setLocations(currentLocations.slice(1).map((location, index) => ({ Id: index, Location: location }))); // remove the pickup location from the draggable locations array.
        }, [currentLocations]);

        function MoveLocation(dragIndex: number, hoverIndex: number) {
            Dispatch.Booking.ReOrderLocations({ Location: currentLocations[dragIndex + 1], NewIndex: hoverIndex + 1 });
            setLocations((prevLocations: LocationWithId[]) =>
                update(prevLocations, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevLocations[dragIndex] as LocationWithId],
                    ],
                }),
            );
        }

        /** Update redux store when a location is dragged to a new index. */
        function ReOrderLocations(location: BookingLocationInfo, index: number) {
            Dispatch.Booking.ReOrderLocations({ Location: location, NewIndex: index + 1 }); // (index + 1) because the first location is the pickup location and not included in the draggable locations array.
        }

        const isMobile = useAppState(state => state.uiLogicControl.LayoutMode === UILayoutMode.Mobile);
        const dndBackend = isMobile ? TouchBackend : HTML5Backend;

        if (currentLocations.length == 2) return null; // no dragging when there are only two locations.

        return (
            <>
                <DndProvider backend={dndBackend}>
                    {locations.map((location, index) => <DraggableAddress
                        key={location.Id}
                        LocationIndex={index}
                        LocationId={location.Location}
                        MoveLocation={MoveLocation}
                        OnDropItem={ReOrderLocations}
                    />)}
                    <AddressPreviewForMobile/>
                </DndProvider>                
            </>
        )
    }
}

const AddressPreviewForMobile = () => {
    const preview = usePreview();
    if (!preview.display) {
        return null;
    }

    const itemId = (preview.item as any).id;
    const addressText = itemId.Address?.FullTextAddress;

    return (
        <div className="booking-fields-panel">
            <div className="booking-address-field" style={preview.style}>
                <div className="address-field-components">
                    <div className="inline-address-components drag-preview">
                        <TextField
                            placeholder={addressText}
                            InputProps={
                                <IconButton size="large">
                                    <DragHandleIcon fontSize="small" />
                                </IconButton>}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
