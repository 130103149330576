import React, { FC } from 'react';
import { RecentTripButtonKind } from '../../UILogicControl/UILogicControlEntities';
import { PopulateSelectedTripDetails } from './PopulateSelectedTripDetails';
import { QuickBookSource } from './QuickBookEntities';
import { BookingInfo } from '../../MyBookings/MyBookingEntities';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { BrandedImage, GetBrandedUrl } from '../../Utils/BrandedContentUrls';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';

interface RecentTripsMenuProps {
    Booking: BookingInfo;
    IsDisabled: boolean
}

export const RecentTripEntry: FC<RecentTripsMenuProps>= (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    
    const onClickHandler = async (btnClicked: RecentTripButtonKind, trackingResult : BookingInfo) => {
        
        // Do nothing, if the booking buttons are disabled        
        if(props.IsDisabled) return;

        PopulateSelectedTripDetails(btnClicked, trackingResult, QuickBookSource.RecentTrips);
    }

    /** Prepare the list of all the addresses of the booking */
    const DisplayAddresses = () => {
        return <>
            {
                props.Booking.Locations.map((location, index) => {
                    let addressIcon = index === 0 ? GetBrandedUrl(BrandedImage.DotA) : getContentUrl(ContentURL.images.MyBookings.DropoffIcon);
                    if (index === props.Booking.Locations.length - 1) addressIcon = getContentUrl(ContentURL.images.MyBookings.DestinationIcon);
                    const addressClass = index === props.Booking.Locations.length - 1 ? "default-gray-background address-display" : "address-display";

                    return <div className={addressClass}>
                        <span className="address-icon"><img src={addressIcon}></img></span>
                        <div>{location.AddressText}</div>
                    </div>
                })
            }
        </>
    }
    
    return (
        <div className="booking-template">
            <p className="passenger-name">{props.Booking.PassengerName}</p>
            {props.Booking.Time !== "" ? 
            <p className="passenger-time">{moment(props.Booking.Time, "MM/DD/YYYY HH:mm:SS").format("DD MMM YYYY h:mma")} 
                <Tooltip title="Book return and more" arrow>
                    <IconButton aria-controls="open-menu" onClick={handleMenuOpen} size="large">
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip> 
            </p> : ""}
            <Menu
                id="open-menu"
                keepMounted
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => onClickHandler(RecentTripButtonKind.BookAgain, props.Booking)}>Book Again</MenuItem>
                {
                    // do not display 'Book Return' for multi-stop bookings.
                    props.Booking.Locations.length <= 2 && <MenuItem onClick={() => onClickHandler(RecentTripButtonKind.BookReturn, props.Booking)}>Book Return</MenuItem>
                }
            </Menu>
            {DisplayAddresses()}
        </div>
    );
}

