import React from 'react';
import { connect } from "react-redux";
import { Dispatch } from '../../Dispatch';
import { ApplicationState } from "../../../appState";
import { BookingTabKind } from '../../UILogicControl/UILogicControlEntities';
import "./BookingTab.scss";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { LoginStatusKind } from '../../Authentication/AuthEntities';
import { OptionalUI } from '../OptionalParts/OptionalUI';

interface PropsFromStore {
    ActiveTab: BookingTabKind;
    LayoutMode: UILayoutMode;
    NoOfBookings: number;
    IsGuest: boolean;
    ShouldShowBookingTabs: boolean;
}

/**
 * Component which contains a few tabs at the top of the booking widget where the user picks among:
 * 1) Quick booking;
 * 2) New booking;
 * 3) Active for mobile mode.
 * 
 * This component can be used by both desktop & mobile modes,
 * by default, the NewBooking tab (i.e. tab position 1) is active 
 */
class BookingTab extends React.Component<PropsFromStore> {
      
    // Switch tab
    onTabChange = (tabValue: BookingTabKind) => {   
        this.ConsiderScrollToTop(tabValue);
        Dispatch.UILogicControl.OnBookingTabSelectionChange(tabValue);
    }

    /**
     * This feature is required by UX.
     * If it's on Active tab (mobile only), and user click on New tab, view should scroll to top.
     */
    ConsiderScrollToTop = (tabValue: BookingTabKind) => {   
        if (tabValue === BookingTabKind.NewBooking && this.props.ActiveTab === BookingTabKind.ActiveBooking) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
    }

    render() {       

        const { ActiveTab, LayoutMode, NoOfBookings } = this.props;   
        
        const IsMobileDevice = LayoutMode === UILayoutMode.Mobile;

        const TabPanelStyle = IsMobileDevice ? "booking-tab-panel-common booking-tab-panel-mobile" : "booking-tab-panel-common booking-tab-panel-desktop";

        const QuickBookingTabClass: string = ActiveTab === BookingTabKind.QuickBook ? "active" : "";

        let NewBookingTabClass: string = ActiveTab === BookingTabKind.NewBooking ? "active" : "";   
        
        const ActiveBookingTabClass: string = ActiveTab === BookingTabKind.ActiveBooking ? "active" : "";

        if (!this.props.ShouldShowBookingTabs) return null;

        return (
            <React.Fragment>
                <div id="app-tab" className={TabPanelStyle}>
                    {!this.props.IsGuest && <div className={QuickBookingTabClass} onClick={() => this.onTabChange(BookingTabKind.QuickBook)}>Quick Book</div>}
                    <div className= {NewBookingTabClass} onClick={() => this.onTabChange(BookingTabKind.NewBooking)}>{IsMobileDevice ? "New" : "New Booking"}</div>
                    {IsMobileDevice && <div className={ActiveBookingTabClass} onClick={() => this.onTabChange(BookingTabKind.ActiveBooking)}>
                        <span>Active</span>
                        {this.props.NoOfBookings > 0 ? <div className="booking-tab-notification-bubble">{NoOfBookings}</div> : null} 
                    </div>}         
                </div>
            </React.Fragment>              
        )
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {        
        ActiveTab: state.uiLogicControl.BookingForm.SelectedBookingTab,
        LayoutMode: state.uiLogicControl.LayoutMode,
        NoOfBookings: state.myBookings.All.length,
        IsGuest: state.authentication.LoginStatus === LoginStatusKind.LoggedOut,
        ShouldShowBookingTabs: OptionalUI.BookingTabs(state),
    }
}

export default connect(mapStateToProps)(BookingTab);
