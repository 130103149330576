import appstore from "../../appStore";
import { Api } from "../../Services/Api";
import { ShowDialogRetryErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { Dispatch } from "../Dispatch";
import { TryAgainMessageKind } from "../Utils/ErrorMessages";
import { PopulateConditionListWithDefaultVehiclesV2 } from "./PopulateConditions";
import { DataLoadingStatus } from "./Redux/ConditionEntities";

/** Load all the vehicles for the brand from the database and store in redux. */
export async function LoadAllVehicles() {
    const LoadingStatus = appstore.getState().condition.LoadingStatus;

    // To display the loading spinner
    Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.InProgress });
    var result = await Api.MakeBooking.GetAllVehicles();

    if (!result.isSuccess) {
        Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.Error });
        ShowDialogRetryErrorMessage(TryAgainMessageKind.RetryLoadVehicles);
        return;
    }
    var allVehicles = result.value;

    if (allVehicles.length > 0) {
        Dispatch.Condition.AllVehicles(allVehicles);
    }

    PopulateConditionListWithDefaultVehiclesV2();
}

