import React from 'react';
import { connect } from "react-redux";
import LogoHeader from "../../widgets/Header/LogoHeader";
import './Privacy.scss';
import DefaultLoadingPageContent from "../../widgets/DefaultLoadingPageContent/DefaultLoadingPageContent";
import { ApplicationState } from '../../appState';
import { TncPrivacyState } from './TncPrivacyEntities';
import { RetrievePrivacyWebPageContent } from './TncPrivacyHelper';

interface PropsFromStore {
    tncPrivacy: TncPrivacyState;
}

class Privacy extends React.Component<PropsFromStore, {}> {
    componentDidMount() {
        if(!this.props.tncPrivacy.PrivacyInfo){ 
            RetrievePrivacyWebPageContent(false, true);
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <LogoHeader />
                {!!this.props.tncPrivacy.PrivacyInfo && <div className="privacy" dangerouslySetInnerHTML={{__html: this.props.tncPrivacy.PrivacyInfo.PageContent}}></div>}
                {!this.props.tncPrivacy.PrivacyInfo && <DefaultLoadingPageContent blackTransparentLevel='light' />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        tncPrivacy: state.tncPrivacy
    };
}

export default connect(mapStateToProps)(Privacy);
