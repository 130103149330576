import { SimpleUserProfile } from '../User/ProfileEntitiesV2';
import { ErrorMessagePlacementKind } from './Login/LoginEntities';
import { CountryInfo } from '../Verification/VerificationEntities';
import { Auth0DecodedHash } from 'auth0-js';

/** State in the "authentication" redux store slice. */
export interface AuthenticationState {

    /** Whether the user is logged in, logged out, or profile loading. */
    LoginStatus: LoginStatusKind;

    /** The user's profile only for logged in users. */
    UserProfile?: SimpleUserProfile;

    /** Auth0 login token and metadata. Defined when the user is logged in. */
    AuthToken?: AuthToken;

    /** Whether to display a floating card UI with the user's profile details. */
    IsProfilePanelShown: boolean;

    /** When true, the Verification dialog should create a booking at the end. 
        Should not longer be required when Awaitable Dialogs tech is ready. */
    IsBookingNeededAfterVerification: boolean;

    /** Details the user has entered in the Login / Signup dialogs. */
    Credentials: Credentials;

    /**
     * This optional field store the error message from server side, such as Auth0 or Booking MGMT, 
     * which shared by all Auth0 popups, such as sign-up and log-in
     */
    ErrorMessage?: string;

    /**
     * This optional field store the success message from Auth0 side, 
     * which is used by forgot-password only for now.
     */
    Auth0SuccessMessage?: string;

    /**
     * Where Auth0ErrorMessage displays
     */
    ErrorMessagePlacement: ErrorMessagePlacementKind;

    /**
     * Whether to hide/show the SilentLogin component 
     */
    IsSilentLoginActive: boolean;
}

/**
 * This is a type to desceribe the definition of credentials shared by both sign-up and login popup.
 * Each property is optional.
 * "Email" will be used as "Username" in log-in popup.
 * 
 * Password:
 * For some security consideration, we did not store password here.
 * We stored password in the state of components of Signup and Login popups, and using callback to store from component CredentialPassword.
 * But this does not meet the requirenent of component-communication in the case of mobile verification before sign-up.
 * As discussed wtih team, we store password here.
 */
export interface Credentials {

    /** Only recorded for Signup */
    CountryInfo: CountryInfo;

    /** Only recorded for Signup */
    FirstName?: string;

    /** Only recorded for Signup */
    LastName?: string;

    /** Only recorded for Signup */
    ContactNumber?: string;

    /** Signup / Login / Forgot Password */
    Email?: string;

    /** Signup / Login */
    Password?: string;
}

/**
 * This type is used by a helper function to split profile name as first name and last name.
 */
export interface NameSplit {
    FirstName: string;
    LastName?: string;
}

/**
 * When displaying the Auth0 Lock screen, whether it is in "Login" or "Sign Up" mode.
 * This affects the fields visible and primary action.
 */
export enum LoginUiMode {
    /** Open the Auth0 screen in Login mode. (email, password, OK) */
    Login = 'login',
    
    /** Open the Auth0 screen in Sign Up mode. (email, name, phone, etc etc, Sign Up) */
    Register = 'signUp'
}

/** 
 * The status of logIn/logOut, the status will refelct the display of right-top corner of screen, potential values:
 * 1> Sign in/ Register;
 * 2> Loading;
 * 3> Customer name.
 */
export enum LoginStatusKind {
    /** Logged out status */
    LoggedOut = 'Logged Out',
    
    /** Logged in status and obtained user profile successfully */
    LoggedIn = 'Logged In',

    /** Calling the GetUserProfile after authenticated */
    GetUserProfileInProgress = 'Get User Profile In Progress'
}

/** 
 *  Details about the Auth0 token for a logged in user.
 *  CAREFUL: this entity is saved in Local Storage. Therefore it is not safe to change the names of its fields.
 *  Even adding a field is dangerous unless the code is backwards compatible.
 */
export interface AuthToken {
    /** https://auth0.com/docs/tokens/overview-access-tokens */
    accessToken: string;

    /** https://auth0.com/docs/tokens/id-token */
    idToken: string;

    /** The email address of the user which is returned from the Identity Provider */
    email: any;

    /** 
     *  The unique identifier of the user from the Auth0 token.
     */
    sub: string;

    /** N/A */
    state: string;

    /** The number of seconds before the Access Token expires, i.e. 7200 */
    expiresIn: number;

    /** Alwaws be null in our scenario. */
    scope: string | undefined;

    /** Time when the token will expire, expressed in system time milliseconds. This value can be compared to new Date().getTime(). */
    expiresAt: number;
}

/** 
 * This enum is a typing which will be passed as a parameter to AuthImpl.logout():
 *     Website -> Do housekeeping only from application only;
 *     WebsiteAndAuth0 -> Do housekeeping nt only from application, but also Auth0 server, such as cookies of the login session.
 */
export enum LogoutKind {
    Website = 'Logout only from website',
    WebsiteAndAuth0 = 'Logout from both website & Auth0 server',
    WebsiteAndAuth0OpenLogin = 'Logout from both website & Auth0 server, open login after log out'
}

/** Type wrapper for Auth0 return type. Exactly one of SuccessfulResult and ErrorResult will be populated. */
export type Auth0SuccessOrError = Auth0Success | Auth0Failure;

/** The output of an Auth0 operation (handle login, renew session) when it succeeds. */
interface Auth0Success {

    /** The typing from Auth0 is AuthResult, but Auth0DecodedHash types [idTokenPayload] as "any", which we need to read the [email] property from it. [email] will be defined since we request that scope. */
    SuccessfulResult: Auth0DecodedHash,

    /** Discriminator */
    IsSuccess: true,
}

/** The output of an Auth0 operation (handle login, renew session) when it fails. */
interface Auth0Failure {
    ErrorResult: auth0.Auth0Error,

    /** Discriminator */
    IsSuccess: false,
}