
/** 
 *  What type of device we understand the client is using.
 *  Used to decide which layout to present to the user.
 */
export enum DeviceKind {
    Phone = "Phone",
    Tablet = "Tablet",
    Desktop = "Desktop",
    Unknown = "Unknown",
}

/**
 * 1. Mobile operating system on the client's phone.
 * 2. These enum values corresponds to the known output of MobileDetect().os(), please do not modify it.
 */
export enum MobileOSKind {

    // these values come from MobileDetect
    IOS = "iOS",
    Android = "AndroidOS",

    // careful: there may be other return values from the MobileDetect() library that are not defined in this enum. 

    // dummy value we made for other cases (e.g. desktop devices)
    Unknown = "unknown",
}