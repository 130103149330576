import moment, { Duration } from "moment";
import { ExtendedEta } from "../MyBookingEntities";
import { BookingWithEta } from "./BookingWithEta";
import { VehicleLocation } from "../../../Services/BookingEntities";
import { GetCurrentTime } from "../../Utils/GetCurrentTime";
import { EtaReductionPerElapsedSecond, EtaReductionMetersPerSecond } from "./Parameters";
import { GetDistanceBetweenPoints } from "./DistanceBetweenPoints";

/** Returns true if we detect the Taxi has been mostly stationary since the last ETA refresh, and therefore we don't need a refresh. 
 * Docs: https://cabcharge.atlassian.net/wiki/spaces/CA/pages/928973446/Idea%3A+Reduce+ETA+Calls+Based+On+Location+Drift
 */
export function CanDeferEtaRefreshDueToLackOfMovement(details: BookingWithEta, refreshInterval: Duration, actualEta: ExtendedEta): boolean {

    // location will be defined in this code path; see DoesEtaNeedRefresh
    const taxiCurrentLocation = details.Booking.TrackingInfo.VehicleLocation!;

    const maxPotentialEtaChange = EstimatePotentialEtaDecrease(actualEta, taxiCurrentLocation);
    if (maxPotentialEtaChange.asMilliseconds() < refreshInterval.asMilliseconds()) return true;

    // genuinely large change in ETA; can't defer refresh
    return false;
}

/**
 * An overall upper bound on the change in ETA considering two different estimation methods.
 */
export function EstimatePotentialEtaDecrease(previousEta: ExtendedEta, taxiCurrentLocation: VehicleLocation): Duration {

    const fromDistance = EstimateEtaChangeFromDistanceMoved(previousEta, taxiCurrentLocation);
    const fromDataAge = EstimateEtaChangeFromAgeOfData(taxiCurrentLocation);

    return fromDistance.clone().add(fromDataAge);
}

/**
 * Determine how far the vehicle has moved (in meters) since the previous ETA call.
 * Convert that into an equivalent number of seconds of ETA reduction.
 */
export function EstimateEtaChangeFromDistanceMoved(previousEta: ExtendedEta, taxiCurrentLocation: VehicleLocation): Duration {

    const previousLocation = previousEta.VehicleLocation;
    const changeInPositionMeters = GetDistanceBetweenPoints(previousLocation, taxiCurrentLocation);

    const equivalentEtaChangeSeconds = changeInPositionMeters / EtaReductionMetersPerSecond;
    return moment.duration(equivalentEtaChangeSeconds, "seconds");
}

/**
 * The location data is not perfectly up to date. So the car may have moved further in the last few seconds after the last location update. 
 * This movement could reduce the ETA further. Estimate how much. 
 */
export function EstimateEtaChangeFromAgeOfData(taxiCurrentLocation: VehicleLocation): Duration {

    // how long ago was the taxi's location sampled? 
    const taxiNowTimestamp = taxiCurrentLocation.LocalDataLoadedTime;

    if (!taxiNowTimestamp) {
        // this data should be populated by BulkUpdateStatus. Give up if not (i.e. make CanDefer return false)
        return moment.duration(1, "hour");
    }

    const elapsedTimeMillis: number = GetCurrentTime().diff(taxiNowTimestamp);
    const feasibleEtaChangeMillis = elapsedTimeMillis * EtaReductionPerElapsedSecond;

    return moment.duration(feasibleEtaChangeMillis, "milliseconds");
}