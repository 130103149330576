import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { PerLocationProps } from "../Booking/PerLocation/PerLocationProps";
import { Dispatch } from "../Dispatch";
import { BookingFormKind, EditorState } from "../UILogicControl/UILogicControlEntities";
import { FullPhoneNumber } from "./PhoneNumberEntities";
import { PhoneNumberInput } from "./PhoneNumberInput";

/**
 *  Phone number picker component bound to a booking form location in redux.
 *  Pickup or dropoff mode is set by the JSX prop.
 *  This file just manages the redux reading and writing; the UI is in PhoneNumberInput.
 */
const BookingPhoneInputCore: React.FC<PerLocationProps & ReduxProps> = (props) => {

    // #region Render

    const isPickup = props.Location === 0;
    const isOptional = props.IsParcelMode || !isPickup;
    const currentValue = isPickup ? props.PickupPhone : props.DropoffPhone;

    let label = "Contact number";

    if (props.IsParcelMode) {
        label = isPickup ? "Pickup contact number" : "Dropoff contact number";
    }

    return (
        <PhoneNumberInput
            Value={currentValue}
            OnValueChanged={OnV2ValueChanged}
            OnEditorStateChanged={OnEditorStateChanged}
            LabelText={label}
            StrictValidation={props.IsValidationStrict}
            IsOptional={isOptional} />
    );

    // #endregion

    // #region Event Handlers

    /**
     * When the user clears or enters a valid phone number.
     */
    function OnV2ValueChanged(value: FullPhoneNumber | null): void {

        if (value) {
            Dispatch.Booking.ContactPhone(props.Location, value);
        }
        else {
            Dispatch.Booking.ClearContactPhone(props.Location);
        }
    }

    /**
     * Low level UI editor state changes.
     */
    function OnEditorStateChanged(newState: EditorState): void {

        if (props.Location === 0) {
            Dispatch.UILogicControl.PickupPhoneNumber(newState);
        }
        else {
            Dispatch.UILogicControl.DropoffPhoneNumber(newState);
        }
    }

    // #endregion
}

// #region Redux

interface ReduxProps {

    /** Current value of the phone number for the pickup location */
    PickupPhone: FullPhoneNumber | null;

    /** Current value of the phone number for the dropoff location */
    DropoffPhone: FullPhoneNumber | null;

    /** Whether the booking form is in parcel widget (extended details) mode */
    IsParcelMode: boolean;

    /** When true, errors are displayed for empty mandatory fields. */
    IsValidationStrict: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {

    return {
        PickupPhone: state.booking.Locations[0].Contact.Phone,
        DropoffPhone: state.booking.Locations[1].Contact.Phone,
        IsParcelMode: state.uiLogicControl.BookingForm.ActiveBookingForm === BookingFormKind.ParcelBooking,
        IsValidationStrict: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
    }
}

// #endregion

export const BookingPhoneInput = connect(GetPropsFromRedux)(BookingPhoneInputCore);