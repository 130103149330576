import React, { Component } from 'react';
import './ResponsiveInputWrapper.scss';

/**
 * Renders the input wrapper for each input in the new login/signup form.
 * It controls the styles of uniformed spacing, size per device
 */
export class ResponsiveInputWrapper extends Component<{}> {
    render() {
        return(
            <div className="responsive-input-wrapper">
                {this.props.children}
            </div>
        );
    }
}