import React from "react";
import "./MobileBookingForm.scss";
import BookingAddressGroup from "../Widget/BookingAddressGroup";
import BookingPageLoading from "../Widget/BookingPageLoading";
import BookingButton from "../Widget/BookingButton";
import BookingDateTime from "../BookingDateTime";
import TrackVisibility from 'react-on-screen';
import { TrackVisibilityBlock } from "./TrackVisibilityBlock";
import GoogleMapWrapperWithGps from "./GoogleMapWrapperWithGps";
import { TncFooter } from "../Widget/TncFooter";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { FareEstimateSnackbar } from "../Widget/FareEstimateSnackbar";
import { SnackbarForPriceGuaranteeTip } from "../Widget/SnackbarForPriceGuaranteeTip";
import { DriverNotesSection } from "../Widget/DriverNotesSection";
import { BookingLocationContactName } from "../PerLocation/BookingLocationContactName";
import { FormStepLabel } from "../FormSteps/FormStepLabel";
import { MaybeParcelModeSelector } from "../OptionalParts/MaybeParcelModeSelector";
import { MaybeAccountDetails } from "../OptionalParts/MaybeAccountDetails";
import { MaybeCardSelector } from "../OptionalParts/MaybeCardSelector";
import { MaybeDeliveryOptions } from "../OptionalParts/MaybeDeliveryOptions";
import { MaybeAccountOption } from "../OptionalParts/MaybeAccountOption";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { VehicleSelector } from "../../Condition/UI/VehicleSelector";
import { BookingPhoneInput } from "../../PhoneNumber/BookingPhoneInput";
import { BookingLocationGroup } from "../PerLocation/BookingLocationGroup";
import { useAppState } from "../../../Redux/ReduxHooks";
import { BookingWidgetGoogleMap } from "../../../widgets/google-maps/MapComponents/BookingWidgetGoogleMap";
import { OptionalUI } from "../OptionalParts/OptionalUI";

/**
 * Renders the booking form on mobile device layouts.
 */
export const MobileBookingForm: React.FC = () => {

    const isParcelMode = useAppState(i => i.uiLogicControl.BookingForm.ActiveBookingForm === BookingFormKind.ParcelBooking);
    const shouldShowBookingTabs = useAppState(OptionalUI.BookingTabs);
    const bookingPanelClass = shouldShowBookingTabs ? "mobile-booking-form-panel" : "mobile-booking-form-panel mobile-booking-form-panel-without-tabs";

    return (
        <div className={bookingPanelClass} >
            <TrackVisibility partialVisibility>
                <TrackVisibilityBlock />
            </TrackVisibility>

            <MaybeAccountOption />
            <MaybeParcelModeSelector />
            <MaybeAccountDetails />

            <BookingFormGroup>
                <GoogleMapWrapperWithGps ShowGps={true}>
                    <BookingWidgetGoogleMap />
                </GoogleMapWrapperWithGps>
            </BookingFormGroup>

            {isParcelMode ? <FormInParcelMode /> : <FormInTaxiMode />}
            
            <MaybeDeliveryOptions />
            <FareEstimateSnackbar />
            <SnackbarForPriceGuaranteeTip />
            <BookingButton />
            <TncFooter />
            <BookingPageLoading />
        </div>
    );
}

/** Render the booking form in parcel mode (extra details) */
const FormInParcelMode: React.FC = () => (
    <>
        <BookingLocationGroup Location={0} />
        <BookingLocationGroup Location={1} />

        <BookingFormGroup>
            <BookingDateTime />
            <VehicleSelector />
        </BookingFormGroup>

        <MaybeCardSelector />
    </>
);

/** Render the booking widget in Taxi (simple) mode. */
const FormInTaxiMode: React.FC = () => (
    <>
        <BookingFormGroup WithStepLabel={FormStepLabel.BookingDetails}>
            <BookingAddressGroup />
        </BookingFormGroup>

        <BookingFormGroup>
            <BookingDateTime />
            <VehicleSelector />
        </BookingFormGroup>

        <BookingFormGroup WithStepLabel={FormStepLabel.ContactDetails}>
            <BookingLocationContactName Location={0} />
            <BookingPhoneInput Location={0} />
        </BookingFormGroup>

        <MaybeCardSelector />

        <BookingFormGroup WithStepLabel={FormStepLabel.DriverInstruction}>
            <DriverNotesSection />
        </BookingFormGroup>
    </>
);