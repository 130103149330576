import { DialogKind, WellKnownErrorMessage, DetailedErrorMessage, RetryErrorMessage } from "./DialogEntities";

/** The subset of the Application's state related to the Dialog feature. */
export interface DialogState {

    /** True when any dialog is open. This will enable the background. */
    isAnyOpen: boolean;

    /** For each kind of dialog, whether that one is currently open.
     * "Partial<Record" means each DialogKind enum is an optional key. */
    openDialogs: Partial<Record<DialogKind, boolean>>;

    /** Which dialog is on the top, if there are multiple */
    topmostDialog: DialogKind | null;

    wellKnownErrorMessage?: WellKnownErrorMessage;

    reTryErrorMessage?: RetryErrorMessage;

    detailedErrorMessage: DetailedErrorMessage;
}

/** Initial value of the Dialog state */
export const DefaultDialogState: DialogState = {
    isAnyOpen: false,
    openDialogs: {},
    topmostDialog: null,
    detailedErrorMessage: {}
};