import { FC } from "react";
import React from "react";
import { Snackbar } from "@mui/material";
import Alert, { AlertColor } from '@mui/material/Alert';
import { SnackbarType } from "./SnackbarEntities";

interface SnackbarProps {
    DisplayText: string;

    /** The type of the snackbar. The background colour and icon are decided based on the type.  */
    Type: SnackbarType;

    /** Custom MUI icon if the icon is different from the default icon for the alert severity. Null if the icon is the default icon from MUI. */
    CustomIcon: React.ReactNode | null;
}

/** Generic component that implements material-ui Snackbar. Can be used in different places with custom DisplayText and custom CSS for the position.
 * Uses <Alert> inside the Snackbar to have the Alert behaviour (with icon)*/
export const SnackBar: FC<SnackbarProps> = (props) => {

    let severity: AlertColor = "info";
    
    if(props.Type === SnackbarType.Warning) severity = "warning";

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={true}
        >
            <Alert
                icon={props.CustomIcon}
                variant="filled"
                severity={severity} >
                {props.DisplayText}
            </Alert>
        </Snackbar>
    );
}