import { ContentURL, getContentUrl } from "../../modules/Utils/ContentURL";
import { GetValues } from "../../Config/MyAppConfig";
import { RouteUrls } from "../../RouteUrls";
import { FeatureFlags } from "../../Config/FeatureFlags";

/** Item displays based on device  */
export enum MenuItemDeviceKind {
    /** Display to desktop only */
    DesktopOnly =  "Desktop only",

    /** Display to mobile only */
    MobileOnly =  "Mobile only",

    /** Display to both */
    Both =  "Display to both"
}

/** Properties of a menu item in the main header menu.  */
export interface HeaderMenuItem {
    /** Display text. */
    Title: string;

    /** Link to open (href value) */
    ToLink: string;

    /** Whether the link is an external URL. */
    IsExternalLink: boolean;

    /** To which devices this item is visible */
    VisibleToDevice: MenuItemDeviceKind;

    /** This is used for mobile only for now */
    Icon?: string;

    /** Display only when user is logged-in */
    DisplayToLoggedInUserOnly?: boolean;

    /** Menu item's width. We give a fixed width to each menu item in order to stop other menu items moving when mouse over on one menu item (due to the text being bigger from bold effect). Only applicable to desktop mode. */
    Width: number | null;
}

const FareEstimate: HeaderMenuItem = {
    Title: "Fare estimate",
    ToLink: RouteUrls.FareEstimate,
    IsExternalLink: false,
    VisibleToDevice: MenuItemDeviceKind.DesktopOnly,
    Width: 132,
};
 
const BookATaxi: HeaderMenuItem = {
    Title: "Book a taxi",
    ToLink: RouteUrls.BookingWidget,
    IsExternalLink: false,
    VisibleToDevice: MenuItemDeviceKind.DesktopOnly,
    Width: 116,
};

const ContactUs: HeaderMenuItem = {
    Title: "Contact us",
    ToLink: GetValues().ExternalContactUsUrl ?? RouteUrls.ContactUs,
    IsExternalLink: !!GetValues().ExternalContactUsUrl,
    VisibleToDevice: MenuItemDeviceKind.Both,
    Icon: getContentUrl(ContentURL.images.UserProfile.Contact),
    Width: 112,
};

/** Menu item to open the wallet page in mobile layout. */
export const MyWallet: HeaderMenuItem = {
    Title: "Payment",
    IsExternalLink: false,
    ToLink: RouteUrls.PaymentWallet,
    DisplayToLoggedInUserOnly: true,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.PaymentType.WalletIconBlack),
    Width: null,
};

const MyProfile: HeaderMenuItem = {
    Title: "Profile",
    IsExternalLink: false,
    DisplayToLoggedInUserOnly: true,
    ToLink: RouteUrls.PersonalDetails,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.Users.Portait),
    Width: null,
};

export const WhatIsNew: HeaderMenuItem = {
    Title: "What's New",
    IsExternalLink: false,
    DisplayToLoggedInUserOnly: false,
    ToLink: RouteUrls.WhatIsNew,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.WhatsNew.WhatIsNew),
    Width: null,
}

const SavedAddresses: HeaderMenuItem = {
    Title: "Saved Addresses",
    IsExternalLink: false,
    DisplayToLoggedInUserOnly: true,
    ToLink: RouteUrls.AddressPoints,
    VisibleToDevice: MenuItemDeviceKind.MobileOnly,
    Icon: getContentUrl(ContentURL.images.UserProfile.Star),
    Width: null,
};

/** Main menu items (in site header) */
export const MainMenu: HeaderMenuItem[] = [
    BookATaxi,
    MyProfile,
    MyWallet,
    SavedAddresses,
    WhatIsNew,
];

// Add company website to the main menu only if HomeUrl for the current brand is not null.
const companyWebsite = GetValues().HomeUrl;
if (companyWebsite) {
    MainMenu.push({
        Title: "Company website",
        IsExternalLink: true,
        ToLink: companyWebsite,
        VisibleToDevice: MenuItemDeviceKind.DesktopOnly,
        Width: 162,
    });
}

// Contact Us comes after company website if that is present
MainMenu.push(ContactUs);

if (FeatureFlags.FareEstimatorTool) {
    MainMenu.unshift(FareEstimate);
}

