import React, { Component } from 'react';
import './GoToOldSite.scss';

/**
 * UI block which is located in "Login" dialog
 * Open a tab to old website, if clicked
 */
class GoToOldSite extends Component<{}> {
    /**
     * This function is only for tracking app Insights
     */
    linkClicked = () => { appInsights.trackEvent("Revert to old site login clicked"); }

    render() {
        return(
            <div className="login-goto-oldsite-panel">
                <a href="https://www.13cabs.com.au/My13Cabs/Index" target="_blank" onClick={this.linkClicked}>Revert to old site <span>log in</span></a>
            </div>
        );
    }
}

export default GoToOldSite;