import { RecentTripButtonKind, BookingTabKind } from "../../UILogicControl/UILogicControlEntities";
import { RecentTripSelection } from "../NewBookingTabHelper";
import { Dispatch } from "../../Dispatch";
import { MapView } from "../../../widgets/NavBar/TabEntities";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { QuickBookSource } from "./QuickBookEntities";
import { LogEvent } from "../../../utils/LogEvent";
import { BookingInfo, SelectedBookingDetails } from "../../MyBookings/MyBookingEntities";

/** Populate an existing booking details in the booking form fields. 
 * Triggered by different components such as Trip History list and Quick Book tab. */
export function PopulateSelectedTripDetails(btnClicked: RecentTripButtonKind, trackingResult: BookingInfo, recentTripSource: QuickBookSource) {

    const recentTripDetails: SelectedBookingDetails = {
        TripType: btnClicked,
        BookingDetails: trackingResult
    }

    // Log different events to appInsights based on the source of the selected booking
    LogEvent.SelectedBookingToRebook(recentTripSource, btnClicked);

    // Populate selected trip details
    RecentTripSelection(recentTripDetails);

    // On click of 'Book again' button, change the tab to be Map View always.
    Dispatch.Tab.SelectItem(MapView);

    // the NewBooking tab must be displayed on Booking Mode.
    Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);
    Dispatch.UILogicControl.OnBookingWidgetModeChange(BookingWidgetModeKind.Booking);

}