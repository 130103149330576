
/** Application feature flags. Any nontrivial new feature should not take effect if its feature flag is off. */
export const FeatureFlags = {

    /** UI to control feature flags at runtime from /flags */
    UserFeatureFlags: true,

    /** Navigation panel link to the /flags UI. False by default. The Feature Flags UI will set it to true at runtime. */
    UserFlagsNavLink: false,

    /** Enable/disable the landing page which is on top of the map in booking page */
    LandingPage: false,

    /** Show/Hide the new customised UI interface for Auth0 API implementation */
    CustomAuth0Interface: true,

    /** Perform the Auth0 login and redirect in an iFrame, avoiding a reload of the application */
    Auth0RedirectInChildWindow: true,

    /** Retrieve the list of Booking Templates of the logged in user. */
    BookingTemplates: true,
    
    /** Enable Auth0 Custom Domain */
    CustomDomain: true,

    /** Shows a demo panel for the Dialog (V2) feature */
    Dialog2Demo: false,

    /** Shows a link to log in interface of old 13cabs website */
    GoToOldSite: false,

    /** Allow bookings with Fixed fare when true. */
    FixedFare: true,

    /** Allow book with Fixed fare with cash option. i.e.: guest users or signed in users with 'Pay driver directly' option selected. */
    FixedFareWithCash: true,

    /** Reset password for logged-in users. */
    ResetPassword: true,

    /** Show Whats new feature list */
    WhatsNew: true,

    /** Don't redirect mobile users. */
    KeepMobileUsers: true,

    /** Hide Sign up buttons and links. Only Log in is available. */
    NoSignup: false,

    /** Always force login. i.e. guest bookings are not allowed in the website when this is enabled. */
    ForceLogin: false,

    /** Skip the terms and conditions check. */
    NoTermsAndConditions: false,

    /** Check whether there is an SSO session on Auth0 server if enabled, via function TryRestoreLoginSession() */
    SingleSignOn: false,

    /** Forgot password feature for in login popup */
    ForgotPassword: true,

    /** Whether customers are able to add card in payment option dropdown */
    CardNotPresentPayment: true,

    /** UI, logic and data operations are against BookingV2Controller (behind the scene is GlobalBooker implementation), if value is true; Otheriwse to BookingController. */
    BookingApiV2: false,

    /** Switch to enable booking on account, specifically for mobile. */
    MobileBookingOnAccount: true,

    /** Switch to enable multiple authentication tenant options during login (for showcase purpose for now). */
    MultiTenantLogin: false,

    /** When true, show the Downlaod app button on the site header */
    ShowDownloadMobileAppButton: false,

    /** Master flag required for book on account functionality. */
    BookOnAccount: true,

    /** When true, show price guarantee toggle to display fixed fare. */
    PriceGuarantee: false,

    /** Display 'Order Number' input field in the booking widget when booking on account. */
    OrderNumber: true,

    /** Use Legal Documents V2 (handled by the booking MGMT) operations. */
    LegalDocumentsV2: false,

    /** Disable login, signup and other logged in user features. */
    GuestOnly: false,

    /** Enable Price Guarantee bookings for account users. This is only applied if PriceGuarantee flag above is true. */
    PriceGuaranteeWithAccount: false,

    /** Pre authorise booking payments. The flag actually decides whether to make PaymentPreAuthOptIn true in booking request.  */
    PreAuth: false,

    /** Allow make fixed fare parcel bookings with cash. */
    ParcelFixedFareWithCash: true,

    /** Local debug only. Stop emitting GTM in order to speed up page load times during local debugging. */
    DropGoogleTagManager: false,

     /** Enable/disable the fare estimate widget from url /fare-estimate */
    FareEstimatorTool: true,
    
    /** Server-managed feature previews with user optin via the profile UI */
    PreviewFeatures: true,

    /** Setting this to false will stop using the beta channel of Google Maps, which will prevent ODRD functionality from working. Hotfix, hopefully temporary. */
    GoogleMapsBetaChannel: true,
}