import { FailureResult } from "../../Services/ServiceEntities";
import { LogEvent } from "../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { PaymentFunnelExitDetails } from "../Payment/PaymentFunnelExit";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";

/** 
 * Records the user's passage through Google Pay UI flow.
 */
export namespace AddGooglePayFunnel {

    /** Signal the start of the Add Google Pay funnel. i.e. the user selected Google Pay and clicked Book button. */
    export function Start() {
        LogEvent.AddGooglePayStart();
    }

    /** 
     * Record an exit from the Add Google Pay funnel with a JavaScript or BrainTree error.
     * Since it comes from a JavaScript catch block, we can't type it properly. 
     */
    export function Exception(stageName: string, error: any, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.Exception(error);

        NoteFunnelExit(stageName, "Exception", details, isUserAction);
    }

    /** Record an exit from the Add Google Pay funnel with a failed API call. */
    export function ApiError(stageName: string, apiError: FailureResult, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.ApiError(apiError);

        NoteFunnelExit(stageName, "API Error", details, isUserAction);
    }

    /**
     * The point is to record where along the flow the user dropped off.
     * Also displays a generic error UI.
     */
    function NoteFunnelExit(stageName: string, errorKind: string, details: Record<string, string>, isUserAction: boolean) {

        details.IsUserAction = String(isUserAction);
        LogEvent.GooglePayFunnelExit(stageName, errorKind, details);

        if (isUserAction) {

            // this case is not really an error. i.e. user closed the Google Payment sheet (popup) without proceeding to payment/booking.
            if ((details.name === "AbortError") && (parseInt(details.code) === 20)) {
                return;
            }

            // generic error UI
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        }
    }
}