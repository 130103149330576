import React, { useEffect } from 'react';
import { Dispatch } from '../Dispatch';
import BookingPage from './BookingPage';

/** Show the booking page, but enable Azure B2C login / Signup for the user */
export const BookingPageWithB2C = () => {

    useEffect(() => {
        Dispatch.Features.AzureB2CPrivateUserOptIn();
    }, []);
  
    return (
        <BookingPage />
    )
}
