import React from "react";
import '../PriceGuaranteeDialog/PriceGuaranteeModal.scss';
import { Typography, Button } from "@mui/material"
import { Dispatch } from "../../Dispatch";
import DialogClose from "../../Dialog/DialogClose";
import { PaymentFailureInput } from "./PaymentFailureEntities";
import { DialogProps } from "../Entities";
import "./PaymentFailure.scss";

type MyProps = DialogProps<PaymentFailureInput, null>;

/**
 * Dialog to let the user know that the booking was cancelled due to payment failure. The API returns the message to be displayed.
 */
export const PaymentFailureDialog: React.FC<MyProps> = (props) => {

    const closeDialog = () => {
        Dispatch.DialogV2.Close();
    }

    return (
        <div className="PriceGuarantee-details payment-failure">
            <DialogClose />
            <Typography variant='h5' color='primary' align='center' gutterBottom>Booking request cancelled</Typography>
            <div className="PriceGuarantee-description"><Typography variant='body1' align="center">{props.Input.ErrorMessage}</Typography></div>
            <Button onClick={closeDialog} size="large" variant='contained' color='primary'>OK</Button>
        </div>        
    );
}