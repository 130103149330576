import { PaymentCard } from "../../Services/PaymentEntities";

/**
 * MpsPaymentCard maps to the payment card(credit/cabcharge) in MPS system
 * It is used to type the response from add card functionality.
 * The difference between MpsPaymentCard and PaymentCard is that MpsPaymentCard doesn't have the additional fields like(IsDefault,Isblocked etc)
 * Also, there won't be a cash option
 */
export interface MpsPaymentCard {
    /** CardId - It is unique Id for a card*/
    CardId: string;

    /** CardNumber - It is the number of a card(****123)*/
    CardNumber: string;

    /** CardType - Visa/Mastercard/Cabcharge etc*/
    CardType: string;

    /** CardName - Name of the card which user sets while adding card, It is not always unique*/
    CardName: string;

    /** CardExpiry - expiry month/year for card*/
    CardExpiry: string;

    /** Bank Identification Number (BIN) of the payment card. */
    CardBin?: string;
}

/**
 * Payment option details appearing on the payment method dropdown 
 */
export interface PaymentOption {

    /** Unique identifier for a payment option */
    Id: string;

    /** Display name of the payment option e.g. "Paying the Driver directly", "SATSS", etc. */
    Name: string;

    /** This property is populated / owned by this codebase. Any records loaded from the backend payment API are given the "CardOrWallet" value. */
    Kind: PaymentOptionKind;

    /** Default payment option of the user */
    IsDefault: boolean;

    /** Populated for payment options that represent cards */
    Card?: PaymentCard;

    /** "Visa", "Mastercard", "Cash/ETFPOS", etc. This comes from the backend payment API. */
    Type: string;
}

/** 
 * Denotes the payment option used for a booking 
 */
export enum PaymentOptionKind {

    /** Any kind of CNP (Card Not Present) payment option. */
    CardOrWallet = "CardOrWallet",

    /** South Australia Taxi Subsidy Scheme. */
    SatssVoucher = "SatssVoucher",

    /** Paying the driver at the end of the trip. */
    PayDriverDirectly = "PayDriverDirectly"
}

/** Different errors related to payment cards (e.g: card is removed from the account, expired, blocked etc.) */
export enum PaymentCardErrorType {
    /** Card added to the selected booking (from history) is not in the list of user's cards (i.e. card is removed from the account) */
    CardNotFound = "Card not found",

    /** No payment method selected while making a booking  */
    CardNotSelected = "Please select a payment method",

    CardNotProvidedForPriceGuarantee = "Card payment required when selecting a 'Fixed Price' fare",

    /** Selected card is expired. */
    CardExpired = "Card Expired",

    /** The selected payment method has an error. e.g: JIT preauth failed in the previous booking and that booking is selected to rebook with the same payment method. */
    SelectedPaymentMethodError = "Please use an alternative payment method",

    /** Only fixed price trips can be paid with Apple Pay. */
    ApplePayWithFixedPriceOnly = "Apple Pay can only be used with Fixed Price",

    /** Only fixed price trips can be paid with Google Pay. */
    GooglePayWithFixedPriceOnly = "Google Pay can only be used with Fixed Price",
}

/** "Paying the Driver directly" as a payment option */
export const PayDriverOption: PaymentOption = {
    Id: "Cash/ETFPOS",
    IsDefault: false,
    Type: "Cash/ETFPOS",
    Name: "Paying the Driver directly",
    Kind: PaymentOptionKind.PayDriverDirectly
};

/** SATSS (South Australian Taxi Subsidy Scheme) voucher as a payment option */
export const SatssVoucherOption: PaymentOption = {
    Id: "SATSS",
    Name: "SATSS",
    IsDefault: false,
    Type: "SATSS",
    Kind: PaymentOptionKind.SatssVoucher
}

/** Placeholder card for PayPal Guest: after loading the PayPal account details but before registering the user in the Booking API / MPS. */
export const PayPalGuestTempPlaceholder: PaymentOption = {
    Id: "PayPal",
    Name: "PayPal",
    IsDefault: true,
    Type: "PayPal",
    Kind: PaymentOptionKind.CardOrWallet,
};

export const ApplePayPlaceholder: PaymentOption = {
    Id: "ApplePay",
    Name: "Apple Pay",
    IsDefault: true,
    Type: "ApplePay",
    Kind: PaymentOptionKind.CardOrWallet,
}

/** Placeholder card for Google Pay. Registration of the payment method happens once the user clicks on the 'Book' button. Hence we need a placeholder to represent Google Pay has selected. */
export const GooglePayPlaceholder: PaymentOption = {
    Id: "GooglePay",
    Name: "Google Pay",
    IsDefault: true,
    Type: "GooglePay",
    Kind: PaymentOptionKind.CardOrWallet,
}

/** Placeholder card for guest credit or debit card. Registration of the payment method happens once the user clicks on the 'Book' button. Hence we need a placeholder to represent Credit/Debit card has selected. */
export const CreditCardPlaceholder: PaymentOption = {
    Id: "CreditOrDebit",
    Name: "Credit/Debit card",
    IsDefault: true,
    Type: "CreditOrDebit",
    Kind: PaymentOptionKind.CardOrWallet,
}

/** Payment help text for the selected payment option */
export const PaymentHelpText = {
    
    PayDriverDirectly: "'Fixed Price' must be switched off above when selecting 'Paying the driver directly'",
    
    SatssVoucher: "Provide a subsidy voucher to the driver.",
    
    Card: "Payment will occur automatically using this card at trip end."
}

/** Contains the list of payment options to be displayed on the my wallet list */
export const WalletPaymentOptions: PaymentOptionKind[] = [
    PaymentOptionKind.CardOrWallet,
    PaymentOptionKind.PayDriverDirectly 
];

/** Different CNP payment methods can be registered with MPS. e.g: Cedit card, PayPal, ApplePay etc. */
export enum CnpPaymentKind {
    CreditOrDebitCard = "Credit/Debit Card",
    PayPal = "PayPal",
    Cabcharge = "Cabcharge FastCard",
    ApplePay = "Apple Pay"
}