import React from 'react';
import { connect } from "react-redux";
import "./NavBar.scss";
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import { TabItem, WellKnownTabName, FeaturesView } from './TabEntities';
import { ApplicationState } from '../../appState';
import { PropsFromStore } from "./DefaultNavBarProps";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { Dispatch } from '../../modules/Dispatch';
import { GetValues } from '../../Config/MyAppConfig';
import { LocationDisplayPanel } from '../location/display-panel/LocationDisplayPanel';
import { UILayoutMode } from '../../modules/UILogicControl/UILogicControlEntities';
import { FeatureFlags } from '../../Config/FeatureFlags';

/* The list of tabs to be shown in each page will be sent as props by the page */
interface NavBarProps {
    NavItems: TabItem[];
}

interface NavBarPropsFromStore {
    
    /** Number of bookings in the store */
    NoOfBookings: number;
    NewFeatureList: number[];
    UserProfile: SimpleUserProfile | undefined;
    UnderMobileMode: boolean;
}

class NavBar extends React.Component<PropsFromStore & NavBarProps & NavBarPropsFromStore> {

    constructor(props: any) {
        super(props);
    }

    /* On select a tab, update the store with the selected tab's title and name */
    selectTab = (item: TabItem) => {
        
        // On "Whats New" tab selection, display the feature list
        if (item === FeaturesView) {
            Dispatch.Features.ShowWhatsNewFeatures();                 
        }
        else {
            Dispatch.Tab.SelectItem(item);
        }    
    }
    
    render() {
        
        let navItems = this.props.NavItems;
        const unviewedFeatureCount = this.props.NewFeatureList.length;
        
        if (!this.props.UserProfile) {
            navItems = navItems.filter(i => i.NeedLogin != true);
        }

        const PhoneNumber = GetValues().BrandContactNumber;

        return (
            <React.Fragment>
                <div className="navbar">
                    <div className="nav-content">
                        <div className="phone-section">
                            {PhoneNumber && <img src={getContentUrl(ContentURL.images.headerAndNav.PhoneWhite)} className="phone-icon"></img>}
                            <span className="phone-number">{PhoneNumber}</span>
                            {
                            FeatureFlags.FareEstimatorTool && !this.props.UnderMobileMode && < LocationDisplayPanel />
                            }
                        </div>
                        <nav>
                            {navItems.map((item, index) => {
                                const alignRightCssClass = item.Name === WellKnownTabName.FeaturesView && 'nav-align-right';
                                return (                                
                                <div className={`nav-item ${alignRightCssClass}`} onClick={() => this.selectTab(item)} key={index}>
                                    <img src={getContentUrl(item.Image)} alt="" width="18"/>
                                    {this.props.SelectedItem.Name === item.Name 
                                        ? <div className="tab-item"><div className="tab-title tab-active">{item.Title}</div><hr className="tab-hr"/></div> 
                                        : <div className="tab-item"><div className="tab-title">{item.Title}</div><hr className="tab-hr hide-hr"/></div>
                                    }
                                    {item.Name === WellKnownTabName.ScheduleView && this.props.NoOfBookings > 0 ? <div className="notification-bubble">{this.props.NoOfBookings}</div> : null}
                                    {item.Name === WellKnownTabName.FeaturesView && unviewedFeatureCount > 0 ? <div className="notification-bubble">{unviewedFeatureCount}</div> : null}
                                </div>
                                )}
                            )}
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore & NavBarPropsFromStore {
    return {
        SelectedItem: state.tabs.SelectedTab,
        NoOfBookings: state.myBookings.All.length,
        UserProfile: state.authentication.UserProfile,
        NewFeatureList: state.features.NewFeatureList,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(NavBar);