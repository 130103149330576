import { PickupServiceCheckState } from "../../Booking/BookingEntities";
import { GeoPoint } from "../../Location/Entities";

/** Details of an address point. */
export interface AddressPoint {

    /** Address point ID. Only populated on Edit. */
    AddressPointId: number | null;

    /** Name given by the user for this address point. e.g.: Home, Work */
    Name: string | null;

    /** Actual address details of the address point. */
    Address: AddressDetails | null;

    /** Notes for driver if this address is selected as the pickup address. */
    PickupNotes: string | null;

    /** Notes for driver if this address is selected as the dropoff address. */
    DropoffNotes: string | null;
}

/** Various address parts. */
export interface AddressDetails {
    /** Address display text. Use to display in the UI. Usually the full address text. */
    DisplayText: string;

    /** Google place ID for this location */
    GooglePlaceId: string;

    /** Geo codes of the selected location. */
    GeoLocation: GeoPoint | null;
}

/** Different window types related to an address point (create, edit etc) */
export enum AddressPointModalType {
    Create = "Create An Address Point",
    Edit = "Edit An Address Point"
}

/** Address validation details including serviceability status and any error messages. */
export interface AddressValidity {
    ServiceabilityState: PickupServiceCheckState;
}