import React from "react";
import { VerificationDialogInput, VerificationDialogResult, VerificationDialogUserAction } from "./UIEntities";
import { DialogProps } from "../Entities";
import DialogClose from "../../Dialog/DialogClose";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";

/** Standard dialog props. */
type MyProps = DialogProps<VerificationDialogInput, VerificationDialogResult>;

/** To allow indexed access of MyState */
type BoxStateKeys = "code1" | "code2" | "code3" | "code4";

/** 
 *  An input dialog to enter a 4-digit verification code that has been sent via SMS.
 *  This dialog is intentionally "dumb": it doesn't perform any work like making web service calls.
 *  It only collects input from the user, then immediately completes (closes).
 *  It is up to the consumer to fit this into a coherent UI flow.
 */
export class VerificationDialog extends React.Component<MyProps, MyState> {

    /** The "Resend Code" link will be shown after this long, in milliseconds */
    private showResendAfterMillis: number = 15000;

    /** ID of our setTimeout timer, so it can be cancelled during unmount 
     *  I'm typing this as any because the typings are conflicting between the NodeJS and browser implementations.
     */
    private resendTimeoutId: any;

    constructor(props: MyProps) {
        super(props);

        this.state = {
            code1: "",
            code2: "",
            code3: "",
            code4: "",
            IsResendLinkAvailable: false,
            HasStartedInput: false,
        }
    }

    componentDidMount() {

        if (this.props.Input.IsResend == false) {
            this.StartResendTimer();
        }
    }

    /** Start a timer (timeout) that will make the "Resend Code" link visible. */
    StartResendTimer() {

        this.resendTimeoutId = setTimeout(() => {
            this.setState({ IsResendLinkAvailable: true });
        }, this.showResendAfterMillis);
    }

    /** Stop any pending timer (timeout), if it exists */
    componentWillUnmount() {
        if (this.resendTimeoutId) {
            clearTimeout(this.resendTimeoutId);
        }
    }

    /** 
     * A few things to implement:
     * 1) DialogClose will need a change to make it properly generate an output. Maybe make it call our callback? Not sure.
     * 2) verifyCodeClass has been simplified a bit. The green will never be displayed because the dialog will close before then. You will need a new spinner UI for this.
     */
    render() {

        const verifyCodeClass = this.state.HasStartedInput ? "orange-border" : this.props.Input.ErrorMessage ? "red-border" : "";

        return (
            <div className="contact-details">
                <DialogClose />
                <h1 className="orange-header verification-header">Verification</h1>
                <p className="popup-description-verification">To check we have your details correct, an access code was sent to:</p>
                <div className="popup-number-update">
                    <span className="bold-text">{this.props.Input.PhoneNumber}</span>
                    <img src={getContentUrl(ContentURL.images.Lock.NumberEdit)} alt="Edit mobile number button" width="50px" height="auto" onClick={this.ClickedEditPhoneNumber} />
                </div>
                <br />
                <p className="popup-number-description-sub">Please enter this code below:</p>

                {this.props.Input.ErrorMessage ? <p className="verification-error invalid-code">{this.props.Input.ErrorMessage}</p> : ""}

                <div className="verification-code">
                    <input type="tel" id="1" maxLength={1} className={verifyCodeClass} name="code1" value={this.state.code1} onChange={(e) => this.ChangedCodeText(e, "code1", 1)} onKeyDown={(e) => this.KeyDownCodeText(e)} autoFocus />
                    <input type="tel" id="2" maxLength={1} className={verifyCodeClass} name="code2" value={this.state.code2} onChange={(e) => this.ChangedCodeText(e, "code2", 2)} onKeyDown={(e) => this.KeyDownCodeText(e)} />
                    <input type="tel" id="3" maxLength={1} className={verifyCodeClass} name="code3" value={this.state.code3} onChange={(e) => this.ChangedCodeText(e, "code3", 3)} onKeyDown={(e) => this.KeyDownCodeText(e)} />
                    <input type="tel" id="4" maxLength={1} className={verifyCodeClass} name="code4" value={this.state.code4} onChange={(e) => this.ChangedCodeText(e, "code4", 4)} onKeyDown={(e) => this.KeyDownCodeText(e)} />
                </div>

                {this.state.IsResendLinkAvailable && (
                    <a className="resend-code" onClick={this.ClickedRequestNewCode}>
                        Request a new access code
                    </a>
                )}

                {this.props.Input.IsResend && (
                    <div className="sent-code">
                        <img src={getContentUrl(ContentURL.images.GreenTick)} alt="" width="15" />
                        <p>New access code sent</p>
                    </div>
                )}
            </div>
        );
    }

    /** User clicks the "Change Phone Number" link. This completes the dialog. */
    ClickedEditPhoneNumber = () => {

        this.props.CompletionCallback({
            UserAction: VerificationDialogUserAction.ChangePhoneNumber,
        });
    }

    /** User clicked the "Request New Code" link. This completes the dialog. */
    ClickedRequestNewCode = () => {

        this.props.CompletionCallback({
            UserAction: VerificationDialogUserAction.RequestNewCode,
        });
    }

    /** User entered text into one of the code digit text boxes. */
    ChangedCodeText(e: React.ChangeEvent<HTMLInputElement>, boxName: BoxStateKeys, boxId: number) {
        const value = e.target.value;

        if (value !== "" && boxId < 4) {
            const nextBoxElementId = (boxId + 1).toString();
            const nextBox = document.getElementById(nextBoxElementId);

            nextBox!.focus();
        }

        /** TypeScript workaround. It can't detect that [boxName] is one of the keys in state ("code1" / "code2" / etc) */
        const change = {
            [boxName]: value
        } as Pick<MyState, BoxStateKeys>;

        this.setState(change, () => {

            // this will reset the box colours after the user starts typing
            if (this.state.code1 !== "") {
                this.setState({ HasStartedInput: true });
            }

            // dialog finishes when all 4 boxes are filled
            if (this.state.code1 !== "" && this.state.code2 !== "" && this.state.code3 !== "" && this.state.code4 !== "") {
                const codes = this.state.code1 + this.state.code2 + this.state.code3 + this.state.code4;

                this.props.CompletionCallback({
                    UserAction: VerificationDialogUserAction.EnterCode,
                    CodeEntered: codes,
                });
            }
        });
    }

    /** 
     *  KeyDown event handler for the code text boxes.
     *  Intercept the backspace key and focus the previous box.
     */
    KeyDownCodeText(e: React.KeyboardEvent<HTMLInputElement>) {

        if (e.which === 8) {

            const target = e.target as HTMLInputElement;
            const thisBoxNumber = parseInt(target.id);

            if (target.value === "") {
                if (thisBoxNumber >= 2 && thisBoxNumber <= 4) {

                    const previousBoxNumber = thisBoxNumber - 1;
                    const previousBox = document.getElementById(previousBoxNumber.toString());

                    previousBox!.focus();
                }
            }
        }
    }
}

/** UI state for the Verification dialog */
interface MyState {

    /** Digit the user has typed into the first box. Empty string by default. */
    code1: string;

    /** Digit the user has typed into the second box. Empty string by default. */
    code2: string;

    /** Digit the user has typed into the third box. Empty string by default. */
    code3: string;

    /** Digit the user has typed into the fourth box. Empty string by default. */
    code4: string;

    /** Whether the "Resend Code" link is available. Only after the timeout period elapses. */
    IsResendLinkAvailable: boolean,

    /** Whether the user has filled out any boxes. */
    HasStartedInput: boolean,
}