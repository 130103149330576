import { Button } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { Api } from "../../../Services/Api";
import { DialogKind } from "../../Dialog/DialogEntities";
import { Dispatch } from "../../Dispatch";
import { AddressPoint } from "../Redux/AddressPointsEntities";

interface PropsFromStore {
    ActiveAddressPoint: AddressPoint;
}

/** Confirmation dialog to remove address point */
const RemoveAddressCore: React.FC<PropsFromStore> = (props) => {

    /** On click of No button, close the dialog and clear data from ActiveAddressPoint store slice. */
    function CancelAndCloseDialog() {
        Dispatch.Dialog.CloseDialog(DialogKind.RemoveAddressPoint);
        Dispatch.AddressPoints.ClearActiveAddressPoint();
    }

    /** Call API to delete the address point from the database. */
    async function RemoveAddressPoint() {
        const deleteRequest = {
            AddressPointId: props.ActiveAddressPoint.AddressPointId!
        };

        var result = await Api.AddressPoints.Remove(deleteRequest);

        if (!result.isSuccess) {
            // TODO: Consider display an error message here.
            Dispatch.Dialog.CloseDialog(DialogKind.RemoveAddressPoint);
            Dispatch.AddressPoints.ClearActiveAddressPoint();

            return;
        }

        Dispatch.AddressPoints.RemoveFromList(props.ActiveAddressPoint.AddressPointId!);
        Dispatch.Dialog.CloseDialog(DialogKind.RemoveAddressPoint);
        Dispatch.AddressPoints.ClearActiveAddressPoint();
    }

    return (
        <div className="contact-details remove-address-point">
            <h1 className="orange-header">Are you sure you want to remove this address?</h1>

            <div className="address-points-actions-panel">
                <Button variant="outlined"
                    onClick={CancelAndCloseDialog}>
                    No, Cancel
                </Button>
                <Button variant="contained"
                    size="medium"
                    color="primary"
                    disabled={false}
                    onClick={RemoveAddressPoint}>
                    Yes, Remove
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveAddressPoint: state.addressPoints.ActiveAddressPoint
    }
}

export const RemoveAddressPoint = connect(mapStateToProps)(RemoveAddressCore);