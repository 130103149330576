import { Reducer } from 'redux';
import { DialogState, DefaultDialogState } from './DialogState';
import { DialogAction, DialogActionKind } from './DialogActions';
import { DialogKind, WellKnownErrorMessage, DetailedErrorMessage, RetryErrorMessage } from './DialogEntities';

/** Reducer for the Dialog feature. This method just dispatches each case to their own methods. */
export const DialogReducer: Reducer<DialogState, DialogAction> = (state = DefaultDialogState, action) => {
    if (action.type === DialogActionKind.ShowDialog) {
        return HandleShowDialog(state, action.dialog);
    }
    else if (action.type === DialogActionKind.CloseDialog) {
        return HandleCloseDialog(state, action.dialog);
    }
    else if (action.type === DialogActionKind.CloseTopDialog) {
        return HandleCloseTopDialog(state);
    }
    else if (action.type === DialogActionKind.SetSimpleErrorMessage) {
        return HandleSetSimpleErrorMessage(state, action.payload);
    }
    else if (action.type === DialogActionKind.CleanSimpleErrorMessage) {
        return HandleCleanSimpleErrorMessage(state);
    }
    else if (action.type === DialogActionKind.SetRetryErrorMessage) {
        return HandleSetRetryErrorMessage(state, action.payload);
    }
    else if (action.type === DialogActionKind.CleanRetryErrorMessage) {
        return HandleCleanRetryErrorMessage(state);
    }
    else if (action.type === DialogActionKind.SetDescriptiveErrorMessage) {
        return HandleSetDescriptiveErrorMessage(state, action.errorProps);
    }
    else {
        return state;
    }
}

/** Handler to set simple error message object, if there is any */
function HandleSetSimpleErrorMessage(state: DialogState, payload: WellKnownErrorMessage): DialogState {
    return {
        ...state,
        wellKnownErrorMessage: payload
    };     
}

/** Handler to clean simple error message object */
function HandleCleanSimpleErrorMessage(state: DialogState): DialogState {
    let { wellKnownErrorMessage, ...newSate} = state;     
    return newSate;
}

/** Handler to set re-try error message object, if there is any */
function HandleSetRetryErrorMessage(state: DialogState, payload: RetryErrorMessage): DialogState {
    return {
        ...state,
        reTryErrorMessage: payload
    };     
}

/** Handler to clean re-try error message object */
function HandleCleanRetryErrorMessage(state: DialogState): DialogState {
    let { reTryErrorMessage, ...newSate} = state;     
    return newSate;
}

/** Handler for the ShowDialog action. The specified dialog will become true. Overall dialog state will become true too. */
function HandleShowDialog(state: DialogState, dialog: DialogKind): DialogState {
    return {
        ...state,
        isAnyOpen: true,
        openDialogs: {
            ...state.openDialogs,
            [dialog]: true,
        },
        topmostDialog: dialog,
    };
}

/** Handler for the CloseTopDialog action. It is safe to assert state.topmostDialog is not null here, given that this action has been raised. */
function HandleCloseTopDialog(state: DialogState): DialogState {
    return HandleCloseDialog(state, state.topmostDialog!);
}

/** Handler for the CloseDialog action. The specified dialog will become false (not open). Global state (isAnyOpen) may still be true if there are other dialogs open. */
function HandleCloseDialog(state: DialogState, dialog: DialogKind): DialogState {

    // new state for openDialogs: just set this dialog to false
    const newOpenDialogs: Partial<Record<DialogKind, boolean>> = {
        ...state.openDialogs,
        [dialog]: false,
    };

    // new state for isAnyOpen: recompute from values
    let newIsAnyOpen: boolean = false;
    let newTopmostDialog: DialogKind | null = null;

    // careful: this uses the weird enum member enumeration from TypeScript
    // the values in the for loop will be names of the members of the enum, e.g. "LocationChooser" (without spaces), as strings
    // Then we access DialogKind[dialogMemberName] to get the actual DialogKind value, e.g. "Location Chooser" (with a space), typed as DialogKind
    for (const dialogMemberName in DialogKind) {

        const dialogValue = DialogKind[dialogMemberName] as DialogKind;

        if (newOpenDialogs[dialogValue]) {
            newIsAnyOpen = true;
            newTopmostDialog = dialogValue;
            break;
        }
    }

    // put it all together
    return {
        ...state,
        isAnyOpen: newIsAnyOpen,
        openDialogs: newOpenDialogs,
        topmostDialog: newTopmostDialog,
    };
}

function HandleSetDescriptiveErrorMessage(state: DialogState, errorProps: DetailedErrorMessage): DialogState {
    return {
        ...state,
        detailedErrorMessage: errorProps
    };     
}