import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './SignupLogin.scss';
import { CredentialPopupTrigger } from "../../UILogicControl/UILogicControlEntities";
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import { ErrorMessagePlacementKind, AuthType } from "./LoginEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { Credentials } from '../AuthEntities';
import GoToOldSite from "./GoToOldSite";
import SilentLogin from './SilentLogin';
import ResponsiveEmailInput from "../Login/ResponsiveEmailInput";
import ResponsivePasswordInput from "../Login/ResponsivePasswordInput";
import { ResponsiveInputWrapper } from "../../Authentication/Mobile/ResponsiveInputWrapper";
import { ForgotPasswordLink } from "../Mobile/ForgotPasswordLink";
import { ButtonsOfLogin, ExecLogin } from "../Mobile/ButtonsOfLogin";
import { ErrorInDialog } from "../Mobile/ErrorInDialog";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";

interface PropsFromStore {
    CredentialPopupTriggerSource: CredentialPopupTrigger;
    Credentials: Credentials;
    ApiRunningStatus: DataLoadingStatus;
    ErrorMessage: string | undefined;
    Auth0SuccessMessage: string | undefined;
    ErrorMessagePlacement: ErrorMessagePlacementKind;
    IsSilentLoginActive: boolean;
    LayoutMode: UILayoutMode;
}

/**
 * Renders the login popup container for the new Auth0 interface.
 * Renders <SilentLogin /> when "Login" button is clicked after providing the credentials.
 */
class Login extends Component<PropsFromStore> {

    /**
     * Enter pressed event from password widget
     */
    Login() {
        ExecLogin(this.props.Credentials.Email, this.props.Credentials.Password, this.props.ApiRunningStatus === DataLoadingStatus.Idle);
    }

    render() {
        return (
            <div className="signup-login-popup-panel login-panel-height">
                <ResponsiveInputWrapper>
                    <ResponsiveEmailInput IsNonEmailLoginAllowed={true} />
                </ResponsiveInputWrapper>
                <ResponsiveInputWrapper>
                    <ResponsivePasswordInput EnforcePasswordComplexity={false} EnterPressedEvent={this.Login.bind(this)} />
                </ResponsiveInputWrapper>
                <ForgotPasswordLink ApiRunningStatus={this.props.ApiRunningStatus} />
                <ButtonsOfLogin />
                {this.props.ErrorMessage && this.props.ErrorMessagePlacement === ErrorMessagePlacementKind.Popup && <ErrorInDialog ErrorMessage={this.props.ErrorMessage} />}

                {FeatureFlags.GoToOldSite && this.props.LayoutMode !== UILayoutMode.Mobile && <GoToOldSite />}

                {this.props.IsSilentLoginActive && <SilentLogin Credentials={this.props.Credentials} AuthType={AuthType.Login} /> }
            </div>          
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        CredentialPopupTriggerSource: state.uiLogicControl.Credentials.PopupTriggerSource,
        Credentials: state.authentication.Credentials,
        ApiRunningStatus: state.uiLogicControl.Credentials.ApiRunningStatus,
        ErrorMessage: state.authentication.ErrorMessage,
        Auth0SuccessMessage: state.authentication.Auth0SuccessMessage,
        ErrorMessagePlacement: state.authentication.ErrorMessagePlacement,
        IsSilentLoginActive: state.authentication.IsSilentLoginActive,
        LayoutMode: state.uiLogicControl.LayoutMode
    };
}

export default connect(mapStateToProps)(Login);