import { LocationData } from "../Location/Entities";
import { CategoryDefinition } from "./ContactFormCategories";
import { ServiceResult } from "../../Services/ServiceEntities";
import { DateTime } from "luxon";
import { SectionDefinition } from "./ContactFormSections";

/** Form field of the contact form escept for the categories. */
export interface ContactFormFields {
    bookingRef: string;
    cabNumber: string;
    custContactNumber: string;
    driverNumber: string;
    emailAddress: string;
    firstName: string;
    itemDescription: string;
    lastName: string;
    pickupDateTime: DateTime;
    bookingName: string;
    driverId: string;
    fareAmount: string;
    selectedBookingHail: string;
    pickup: Address;
    destination: Address;
    fleetType: number;
    corporateAccountNumber: string;
    corporateAccountName: string;
    isDatepickerInputFocused: boolean;
    dateArrowOnHover: boolean;
    timeArrowOnHover: boolean;
    shouldClearPickup: boolean;
    shouldClearDestination: boolean;
    isAndroidDatePickerOpen: boolean;
}

/** Address data. */
export interface Address {
    street: string;
    suburb: string;
    display: string;
}

/** State of the contact form. */
export interface ContactFormState extends ContactFormFields {
    isFormValid: boolean;
    displayPopup: string;
    invalidEmail: boolean;
    invalidPhone: boolean;
    invalidBookingPhone: boolean;
    message: { title: string; description: string; };
    invalidCategories: boolean;
}

/** Form field of the contact form V2. */
export interface ContactFormFieldsV2 {
    bookingRef: string;
    cabNumber: string;
    custContactNumber: string;
    bookingContactNumber: string;
    emailAddress: string;
    firstName: string;
    itemDescription: string;
    lastName: string;
    pickupDateTime: DateTime;
    bookingName: string;
    driverId: string;
    fareAmount: string;
    selectedBookingHail: string;
    pickup: Address;
    destination: Address;
    isDatepickerInputFocused: boolean;
    dateArrowOnHover: boolean;
    timeArrowOnHover: boolean;
    shouldClearPickup: boolean;
    shouldClearDestination: boolean;
    isAndroidDatePickerOpen: boolean;
}

/** State of the contact form. */
export interface ContactFormStateV2 extends ContactFormFieldsV2 {
    isFormValid: boolean;
    displayPopup: string;
    invalidEmail: boolean;
    invalidPhone: boolean;
    invalidBookingPhone: boolean;
    message: { title: string; description: string; };
    invalidSection: boolean;
}

export interface ContactFormProps {
    /** Required details */
    detailsType?: ContactRequiredDetailsType;

    /** Function to run on submit the form */
    onSubmit: (fields: ContactFormFields) => Promise<ServiceResult<void>>;

    /** Category data */
    currentData: CategoryDefinition;

    /** Selected values of each category */
    selectedCategories: ContactUsState;

    /** User selected/IP location */
    locationData?: LocationData;

    /** Default fields which should be shown for any enquiry type */
    defaultFields?: Partial<ContactFormFields>;
}

export interface ContactFormPropsV2 {
    /** Required details */
    detailsType?: ContactRequiredDetailsType;

    /** Function to run on submit the form */
    onSubmit: (fields: ContactFormFieldsV2) => Promise<ServiceResult<void>>;

    /** Category data */
    currentData: SectionDefinition;

    /** Section data */
    currentSection: number;

    /** User selected/IP location */
    locationData?: LocationData;

    /** Default fields which should be shown for any enquiry type */
    defaultFields?: Partial<ContactFormFields>;
}

/** Fleet types. Only have fleet name */
export const fleet: { [property: string]: {} } = {
    fleetTypes: [
        { label: '13cabs' },
        { label: 'Silver Service' },
    ]
};

/** Request type to submit the contact enquiry. */
export interface SendContactRequest {
    /** Selected city */
    city: string;

    /** Selected state */
    state: string;

    /** User type. Passenger/operator/driver etc. */
    userType: string;

    /** Selected list of categories */
    categorisation: string[];

    /** Additional notes */
    notes?: string;

    /** Contact details of the person who makes the enquiry. */
    contact: {
        email: string;
        firstName: string;
        lastName: string;
        phone: string;
    };

    /** Source from where the request has been made. eg: Mobile/Desktop web */
    source: string;
}

/** Which details are required by the form. Different forms have different levels of required details. */
export enum ContactRequiredDetailsType {
    ALL,
    BASIC,
    DATE_AND_TAXI
}


/** Categorisation of a Contact Entry, to support searches for specific contact types */
export enum ContactKind {
    PassengerCategory = 'passengerCategory',
    GeneralPublic = 'generalPublic',
    Driver = '13cabsDriver',
    Operator = '13cabsOperator',
    NewDriverOperator = 'newDriverOperator',
    PoliceRegulator = 'policeRegulator',
    PleaseSelect = 'pleaseSelect'
}

/** Categorisation of a Enquiry Type, to support searches for specific enquiries */
export enum EnquiryKind {
    PleaseSelect = 'PleaseSelect',
    Receipt = 'Receipt',
    Fare_Payment = 'Fare/Payment',
    LostProperty = 'LostProperty',
    AboutDriver = 'AboutDriver',
    GeneralEnquiry = 'GeneralEnquiry',
}

/** Specific details of the booking for booked trips */
export interface SendContactRequestBooking extends SendContactRequest {
    booking: {
        /** Booking reference number */
        reference?: string;

        /** Name used in the booking */
        name: string;
        /** Phone number used to make the booking */
        phoneNumber: string;

        /** Booking date and time */
        jobDate: string;

        /** Driver ID */
        driver?: string;

        /** Fleet name. 13cabs/silver service */
        fleet?: string;

        /** Taxi number. eg: T1234 */
        taxi?: string;

        /** Account name for account bookings */
        accountName?: string;

        /** Account number for account bookings */
        accountNumber?: string;
    };
}

/** Specific details of the hailed trip */
export interface SendContactRequestHail extends SendContactRequest {
    hail: {
        /** Booking reference number */
        reference?: string;
        
        /** Taxi number. eg: T1234 */
        taxi?: string;

        /** Driver ID */
        driver?: string;

        /** Taxi fare */
        fare?: number;

        /** Fleet name. 13cabs/silver service */
        fleet?: string;

        /** Pickup location */
        pickup: { suburb: string; street: string };

        /** Dropoff location */
        destination: { suburb: string; street: string };

        /** Trip date and time */
        jobDate: string;

        /** Account name for account bookings */
        accountName?: string;

        /** Account number for account bookings */
        accountNumber?: string;
    };
}

/** State of the Contact form component */
export interface ContactUsState {
    selectedSection: number;
    selectedCategory: number;
    selectedsubCategoryDetail: number;
    selectedsubCategory2Detail: number;
    selectedsubCategory3Detail: number;
}

/** State of the Contact form component */
export interface ContactUsStateV2 {
    selectedSection: number;
}

/** Dropdown option */
export interface SelectOption {
    label: string;
    value: number;
}

/** State of the ContactCategory component */
export interface ContactDetailState {
    selectedsubCategory: number;
    selectedsubCategory2: number;
    selectedsubCategory3: number;
}

/** Props for the ContactCategory component */
export interface ContactDetailProps {
    receiveState: (fields: ContactDetailState) => void;
    currentData: CategoryDefinition;
    selectedCategory: number;
    selectedSubCategory: number;
    selectedSubCategory2: number;
    selectedSubCategory3: number;
}
