import { Api } from "../../Services/Api";
import { LocationZoneQuery } from "../../Services/LocationsEntitiesV2";
import appstore from "../../appStore";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";

/** Call the API to load curated locations and store the result in redux. Then open the location selector popup if necessary. */
export async function LoadCuratedLocations(locationIndex: number) {

	const currentLocationAddress = appstore.getState().booking.Locations[locationIndex].Address!;

	const request: LocationZoneQuery = {
		Latitude: currentLocationAddress.GeoLocation.Latitude,
		Longitude: currentLocationAddress.GeoLocation.Longitude,
		GooglePlaceId: currentLocationAddress.GoogleMapsPlaceId,
		IsPickup: locationIndex === 0
	}

	const result = await Api.LocationV2.QueryCuratedLocation(request);

	if (!result.isSuccess) {
		return;
	}

	if (result.value.IsLocationZone && !result.value.IsExactLocation) {
		Dispatch.CuratedLocations.LocationSuggestions(result.value);

		// Open location selection dialog.
		Dispatch.Dialog.ShowDialog(DialogKind.CuratedLocations);
	}

	return;
}