import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { ApplePayPlaceholder, CreditCardPlaceholder, GooglePayPlaceholder } from "../Payment/PaymentEntities";

/** There are some situations where we need to reset some data related to guest payment method. Especially, the backing user. For e.g.: there is a logic on click of book button to ignore the payment approval if 'stae.GuestPayment.BackingUser' is not null. This is to reduce the friction for the user and allow multiple bookings with the same payment method within the session. But if the user paid with GooglePay or ApplePay for one booking and decided to pay with the credit card for the next booking (this is an edge case, but still an issue if not handled), since the BackingUser is not null, the second booking will not trigger the guest credit card flow and since the selected payment method in booking state slice is 'CreditCard' and there is no credit card data the website hangs after the book button click. Therefore, if a different payment method is selected, we need to clear the backing user and other payment method details. */
export function ConsiderGuestPaymentReset() {

	const state = appstore.getState();
	const guestPayment = state.GuestPayment;
	const selectedPaymentOption = state.booking.PaymentOption;

	// only do this for guest users. i.e. BackingUser is not null
	if (guestPayment.BackingUser) {

		// clearing the tokenised form of various payment methods. 'OnChange' event is triggered even if the selected payment method is same as the previous one. we only need to clear data if it is different from the previous one.
		if (selectedPaymentOption !== ApplePayPlaceholder && guestPayment.ApplePayTokenised) {
			Dispatch.GuestPayment.ClearTempUser();
			Dispatch.GuestPayment.ClearApplePay();
		}
		if (selectedPaymentOption !== GooglePayPlaceholder && guestPayment.GooglePayTokenised) {
			Dispatch.GuestPayment.ClearTempUser();
			Dispatch.GuestPayment.ClearGooglePay();
		}
		if (selectedPaymentOption?.Type !== "PayPal" && guestPayment.PayPalTokenised) {
			Dispatch.GuestPayment.ClearTempUser();
			Dispatch.GuestPayment.ClearPayPal();
		}
		if (selectedPaymentOption !== CreditCardPlaceholder && guestPayment.CreditCard3DsVerified) {
			Dispatch.GuestPayment.ClearTempUser();
			Dispatch.GuestPayment.ClearCreditCard3Ds();
		}
	}		
}