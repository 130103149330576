import * as React from "react";
import { Nav, NavItem } from "reactstrap";
import "./UserProfile.scss";
import { SimpleUserProfile } from '../../modules/User/ProfileEntitiesV2';
import { ApplicationState } from '../../appState';
import { connect } from "react-redux";
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { UserProfileNavbarItem } from "./UserProfileNavEntities";
import { Dispatch } from "../Dispatch";
import { GetBrandedUrl, BrandedImage } from "../Utils/BrandedContentUrls";
import { GetValues } from "../../Config/MyAppConfig";
import { MapView } from "../../widgets/NavBar/TabEntities";
import { Link } from "react-router-dom";

/**
 * UserProfile - type for get user profile
 * SelectedNav - Current Active Navbar Item
 */
interface UserProfileProps {
    UserProfile: SimpleUserProfile;
    UserProfileSelectedNav: UserProfileNavbarItem;
}

interface NavBarProps {
    UserProfileNavItems: UserProfileNavbarItem[];
}

/**
 * Vertical Nav Bar for user profile page
 */
export class UserProfileNav extends React.Component<UserProfileProps & NavBarProps> {
    constructor(props: UserProfileProps & NavBarProps) {
        super(props);
    }

    /**
     * set the selected nav item, so user profile page can load the correct component
     *  */
    selectNav = (item: UserProfileNavbarItem) => {
        Dispatch.UserProfileNav.SelectNavItem(item)
    }

    /**
     * Set the class for NavItem, normal class or normal + active class
    */
    getNavItemClass = (item: UserProfileNavbarItem) => {
        return JSON.stringify(this.props.UserProfileSelectedNav) === JSON.stringify(item) ? "userProfileNavItem userProfileNavActiveItem" : "userProfileNavItem";
    }

    /**
     * return the navitem arrow, normal right arrow or active orange left arrow
    */
    getNavItemArrow = (item: UserProfileNavbarItem) => {
        return JSON.stringify(this.props.UserProfileSelectedNav) === JSON.stringify(item) ? GetBrandedUrl(BrandedImage.ArrowLeft) : getContentUrl(ContentURL.images.arrows.arrowRightBlack);
    }

    /** Defines the behaviour when closing the profile page with the Close button. */
    CloseProfile = () => {
        Dispatch.Tab.SelectItem(MapView);
    }

    render() {
        const userProfile = this.props.UserProfile;
        const PhoneNumber = GetValues().BrandContactNumber;

        return (
            <div>
                <Link to="/booking" onClick={() => this.CloseProfile()} className="close-profile-button" >
                    <span className="close-button-text">Close</span>
                    <img alt="close" src={getContentUrl(ContentURL.images.BlackX)} className="close-button-icon" />
                </Link>
                <div className="userProfileImage"><img src={userProfile.PictureUrl} /></div>
                <div className="user-profile-nav-namecard">
                    <span className="user-profile-nav-namecard-title">{userProfile.DisplayName}</span>
                    <span className="user-profile-nav-namecard-email">{userProfile.Email}</span>
                </div>

                <Nav vertical className="userProfileNav">
                    {this.props.UserProfileNavItems.map((item: UserProfileNavbarItem, index: number) =>
                        <NavItem onClick={() => this.selectNav(item)} className={this.getNavItemClass(item)} key={index}>
                            <img className="menuItemIcon" src={getContentUrl(item.Image)} />
                            <div className="menuItemHeader">{item.Title}</div>
                            <img className="menuItemArrow" src={this.getNavItemArrow(item)} />
                        </NavItem>
                    )}
                </Nav>

                {PhoneNumber && < div className="userProfileFooter">
                    <div className="userProfileFooterMainContent">
                        <img src={GetBrandedUrl(BrandedImage.PhoneIcon)} ></img>
                        <span className="footerPhoneNumber">{PhoneNumber}</span></div>
                    <span className="footerMessage" >We’re here to help 24/7</span>
                </div>}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): UserProfileProps {
    return {
        UserProfile: state.authentication.UserProfile!,
        UserProfileSelectedNav: state.userProfileNav.UserProfileSelectedNav
    };
}

export default connect(mapStateToProps)(UserProfileNav);
