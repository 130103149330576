import React, { Component } from 'react';
import './DefaultLoadingPageContent.scss';
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';

interface DefaultLoadingPageContentProps {
    blackTransparentLevel: string;
}

class DefaultLoadingPageContent extends React.Component<DefaultLoadingPageContentProps> {
    render() {
        let styleDefaultLoading = "default-loading-page-content";
        
        if(this.props.blackTransparentLevel === 'light') {
            styleDefaultLoading += " black-transparent-light";
        }
        else {
            styleDefaultLoading += " black-transparent-medium";
        }

        return(
            <div className="default-loading-page-panel">
                <div className={styleDefaultLoading}><img src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/></div>
            </div>
        );
    }
}

export default DefaultLoadingPageContent;