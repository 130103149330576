import React from 'react';
import './PassengerInfo.scss';
import {Button} from "@mui/material";
import { Dispatch } from '../Dispatch';
import { DialogKind } from '../Dialog/DialogEntities';
import { GetValues } from '../../Config/MyAppConfig';


/** Popup that displays instructions to make a booking when the user opens the site through a 'Passenger trip' URL. */
export const PassengerInfo: React.FC<{}> = () => {
    
    function ClosePopup() {
        Dispatch.Dialog.CloseDialog(DialogKind.PassengerInfo);
    }
    
    return (
        
        <div className="passenger-info">
            <h1 className="orange-header">Welcome to {GetValues().BrandName}</h1>
            <p>Please complete and confirm your booking details.</p>
            <Button onClick={ClosePopup} size="large" variant='contained' color='primary'>OK</Button>
        </div>
    )
}


