import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { ApplePayInitialState, ApplePayState } from "./ApplepayState";

/** Redux store slice for the Apple Pay data. */
const slice = new ReduxStoreSlice<ApplePayState>("ApplePay", ApplePayInitialState);

/** Redux action dispatcher for the ApplePay store slice. */
export const ApplePayDispatch = {

    /** The UI flow has progressed far enough to warrant loading the SDK. */
    ReadyToLoad: slice.EmptyAction("Ready To Load", ReadyToLoad),

    /** The ApplePay SDK is now being loaded asynchronously. */
    LoadStarting: slice.EmptyAction("Load Starting", LoadStarting),

    /** The ApplePay SDK load completed successfully. */
    LoadSucceeded: slice.EmptyAction("Load Succeeded", LoadSucceeded),

    /** The ApplePay SDK load failed with an error. */
    LoadFailed: slice.EmptyAction("Load Failed", LoadFailed),
}

/** Redux reducer for the ApplePay store slice. */
export const ApplePayReducer = slice.MakeCombinedReducer();

/** The UI flow has progressed far enough to warrant loading the SDK. */
function ReadyToLoad(state: ApplePayState): ApplePayState {

    if (state.LoadingStatus === SdkLoadingState.NotRequested) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.RequestedNotStarted,
        };
    }

    // update not supported from this state
    return state;
}

/** The ApplePay SDK is now being loaded asynchronously. */
function LoadStarting(state: ApplePayState): ApplePayState {

    if (state.LoadingStatus === SdkLoadingState.RequestedNotStarted) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.InProgress,
        };
    }

    // update not supported from this state
    return state;
}

/** The ApplePay SDK load completed successfully. */
function LoadSucceeded(state: ApplePayState): ApplePayState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedSuccess,
        };
    }

    // update not supported from this state
    return state;
}

/** The ApplePay SDK load failed with an error. */
function LoadFailed(state: ApplePayState): ApplePayState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedError,
        };
    }

    // update not supported from this state
    return state;
}