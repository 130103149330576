import { ApplicationState } from "../../../appState";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { LoginStatusKind } from "../../Authentication/AuthEntities";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { PreviewFeatureId } from "../../Features/FeatureEntities";
import { CreditCardPlaceholder } from "../../Payment/PaymentEntities";
import { BookingFormKind, UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { CalculateIsBookingOnAccount } from "../NewBookingForm";

/** 
 *  Reusable logic for whether to render various pieces of the Booking Form.
 *  These can vary with feature settings, login state, account users, parcel mode, etc.
 */
export namespace OptionalUI {

    /**
     * Whether to display the end of delivery dropdown list.
     */
    export function DeliveryOption(state: ApplicationState): boolean {

        const isBookingOnAccount = CalculateIsBookingOnAccount(state.authentication.LoginStatus, state.booking.IsOnAccount, state.uiLogicControl.LayoutMode === UILayoutMode.Mobile);

        // intentionally not supported for templates; the user should decide for each booking
        const isTemplateMode = state.uiLogicControl.BookingForm.BookingWidgetMode !== BookingWidgetModeKind.Booking;

        const isParcelMode = state.uiLogicControl.BookingForm.ActiveBookingForm === BookingFormKind.ParcelBooking;

        const isFeatureEnabled = !!state.features.EnabledPreviews[PreviewFeatureId.EndOfDelivery];

        return isBookingOnAccount && !isTemplateMode && isParcelMode && isFeatureEnabled;
    }

    /**
     * Whether the user can book on account.
     * Makes the account on/off toggle visible, also account-only preview features.
     */
    export function CanBookOnAccount(state: ApplicationState): boolean {

        // global feature flag
        if (!FeatureFlags.BookOnAccount) return false;

        // device-specific feature flag
        const isMobileDevice = MobileLayout(state);
        if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

        // not logged in
        const userProfile = state.authentication.UserProfile;
        if (!userProfile) return false;

        // no accounts
        if (userProfile.Accounts.length <= 0) return false;

        // DVA accounts have extra custom behaviour that isn't yet supported on mobile
        // TODO: what behaviour? Is this still the case?
        if (isMobileDevice && userProfile.Accounts.some(account => account.IsDVA)) return false;

        // all OK!
        return true;
    }

    /**
     * True when the application is in mobile layout mode.
     * This normally means we've identified that the user is on a mobile device.
     * However, a desktop user can also swap to the layout mode.
     */
    export function MobileLayout(state: ApplicationState): boolean {
        return state.uiLogicControl.LayoutMode === UILayoutMode.Mobile;
    }

    /**
     * True when the user is logged in. Lots of extra UI and functionality becomes available.
     */
    export function LoggedInUser(state: ApplicationState): boolean {
        return state.authentication.LoginStatus === LoginStatusKind.LoggedIn;
    }

    /**
     * True when the Preview feature for Azure B2C is enabled and the magic URl '/B2CLogin' is accessed by the user.
     */
    export function AzureB2CLogin(state: ApplicationState): boolean {
        return state.features.AzureB2CPrivateUserOptIn && !!state.features.EnabledPreviews[PreviewFeatureId.AzureB2CLogin];
    }

    /** 
     *  Whether to include the GoogleOdrdTripId with applicable API calls.
     *  The preview feature is server-managed and is thus supported by guest users too.
     */
    export function SendOdrdTripId(state: ApplicationState): boolean {

        return state.features.EnabledPreviews[PreviewFeatureId.GoogleOdrdTripIdInCreates] ?? false;
    }

    /**
     * Whether the Add PayPal UI action is enabled.
     * There are different feature flags for guest vs logged in.
     */
    export function AddPayPal(state: ApplicationState): boolean {

        const featureId = LoggedInUser(state) ? PreviewFeatureId.AddPayPalSignedIn : PreviewFeatureId.AddPayPalGuest;
        return state.features.EnabledPreviews[featureId] ?? false;
    }

    /**
     * True when there is a pending PayPal guest registration. 
     * The user has provided their PayPal account details but we haven't created the dummy user or registered the payment yet. (It is deferred until booking creation).
     */
    export function PendingPayPalGuest(state: ApplicationState): boolean {

        return !LoggedInUser(state) && !!state.GuestPayment.PayPalTokenised && !state.GuestPayment.BackingUser;
    }

    /**
     * Whether the Add Apple Pay action is enabled.
     * There are different feature flags for guest vs logged in.
     */
    export function AddApplePay(state: ApplicationState): boolean {

        const featureId = LoggedInUser(state) ? PreviewFeatureId.AddApplePaySignedIn : PreviewFeatureId.AddApplePayGuest;
        return state.features.EnabledPreviews[featureId] ?? false;
    }

    /**
     * True when there is a pending Apple Pay guest registration. 
     * The user has authorised the Apple Pay payment but we haven't created the dummy user or registered the payment yet. (It is deferred until booking creation(after SMS verification)).
     */
    export function PendingApplePayGuest(state: ApplicationState): boolean {
        return !LoggedInUser(state) && !!state.GuestPayment.ApplePayTokenised && !state.GuestPayment.BackingUser;
    }

    /**
     * True when CNP options are disallowed due to the metered payment mode being selected.
     * This only occurs when the corresponding API feature is active.
     */
    export function CnpDisallowedByMetered(state: ApplicationState): boolean {

        return (state.condition.IsPriceGuaranteeSelected == false) && !!state.features.EnabledPreviews[PreviewFeatureId.DisallowCnpMetered];
    }

    /**
     * Whether the Add Google Pay action is enabled.
     * There are different feature flags for guest vs logged in.
     */
    export function AddGooglePay(state: ApplicationState): boolean {

        const featureId = LoggedInUser(state) ? PreviewFeatureId.AddGooglePaySignedIn : PreviewFeatureId.AddGooglePayGuest;
        return state.features.EnabledPreviews[featureId] ?? false;
    }

    /**
     * True when there is a pending Google Pay guest registration. 
     * The user has authorised the Google Pay payment but we haven't created the dummy user or registered the payment yet. (It is deferred until booking creation(after SMS verification)).
     */
    export function PendingGooglePayGuest(state: ApplicationState): boolean {
        return !LoggedInUser(state) && !!state.GuestPayment.GooglePayTokenised && !state.GuestPayment.BackingUser;
    }

    /**
     * Whether the Fixed Price toggle should be displayed. This is based on different conditions like the login status, user's location and feature flag status. It is hidden only for very specific user base (e.g. guest users in Adelaide)
     */
    export function FixedPriceToggle(state: ApplicationState): boolean {

        if (LoggedInUser(state)) return true;
        if (!state.features.EnabledPreviews[PreviewFeatureId.HideFixedPriceToggle]) return true;
        if (state.location.reliableLocation.value.stateCode?.toUpperCase() != "SA") return true;

        return false;
    }
    
    /** Decides whether to show or hide the tabs above the booking form (Quick Book, New Booking etc) */
    export function BookingTabs(state: ApplicationState): boolean {

        const isGuest = state.authentication.LoginStatus === LoginStatusKind.LoggedOut;

        // Logged in users should always see the tabs
        if (!isGuest) return true;

        const noOfBookings = state.myBookings.All.length;
        const isMobile = state.uiLogicControl.LayoutMode === UILayoutMode.Mobile;

        // Mobile guest users should see the tabs if they have at least 1 active booking
        if (isMobile && noOfBookings > 0) return true;

        // Mobile guest users with no active bookings and desktop guest users.
        return false;
    }

    /** When True, launch 3DS verification flow for the selected payment card if 3DS check for the card is required by the backend. */
    export function ThreeDSecureVerification(state: ApplicationState): boolean {
        return !!state.features.EnabledPreviews[PreviewFeatureId.ThreeDSecureVerification] && !state.booking.IsOnAccount;
    }

    /** When true, enable some UI components that allow users to make bookings with credit/debit cards as a guest. */
    export function GuestCreditCard(state: ApplicationState): boolean {
        return state.authentication.LoginStatus === LoginStatusKind.LoggedOut && !!state.features.EnabledPreviews[PreviewFeatureId.GuestCreditCardBookings];
    }

    /**
     * True when there is a pending Credit/Debit card guest registration. 
     * The user has tokenised and verified (3DS) the payment card but we haven't created the dummy user or registered the payment in MPS yet. (It is deferred until booking creation(after SMS verification)).
     */
    export function PendingCreditCardGuest(state: ApplicationState): boolean {
        return !LoggedInUser(state) && !!state.GuestPayment.CreditCard3DsVerified && !state.GuestPayment.BackingUser;
    }

    /** Decides when to display the email input field for the receipt to be sent. 
     * TODO: Do we need a feature flag for this? I don't think so.
     */
    export function EmailForPaymentReceipt(state: ApplicationState): boolean {

        if (state.booking.PaymentOption === CreditCardPlaceholder) return true;

        return false;
    }
}