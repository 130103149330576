
import appstore from "../../appStore";
import { Api } from '../../Services/Api';
import { ProfileChanges } from '../../Services/UserEntities';
import { CloseOffContactNumberUpdateTaskInProfileValidation } from "../LoginValidation/LoginValidationHelper";
import { RefreshProfileAfterDataChange } from "./GetUserProfileService";

/** 
 * This function mainly do update contact number of a logged-in user's profile.
 */
export async function UpdateContactNumber(profileChanges: ProfileChanges): Promise<boolean> {
    const { UserProfile } = appstore.getState().authentication;
    const result = await Api.User.UpdateProfile(profileChanges);

    // profile load failure: abort login
    if (!result.isSuccess) {
        appInsights.trackEvent("Update User Profile Fail", { Type: "Contact number", UserId: UserProfile!.UserId });
        return false;
    }

    /**
     * 1) await is important here;
     * 2) RefreshProfileAfterDataChange() must be put before CloseOffContactNumberUpdateTaskInProfileValidation().
     */
    await RefreshProfileAfterDataChange();
    CloseOffContactNumberUpdateTaskInProfileValidation();
    
    appInsights.trackEvent("Update User Profile Success", { Type: "Contact number", UserId: UserProfile!.UserId });
    return true;
}
