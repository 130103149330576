import { AddressPointDetails, AddressPointRequest, AddressPointResponse, DeleteAddressPointRequest } from "./AddressPointsContracts";
import { ServiceResult } from "./ServiceEntities";
import { GetManagementServiceRootUrlWithoutState, Post } from "./ServiceManager";

/** Base URL for the AddressPointsController in the BookingManagement service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}AddressPoints/`;
}

/** HTTP API Calls to the AddressPointsController in the BookingManagement Service. */
class AddressPointsServiceClass {

    /** Create an address point */
    public Create(request: AddressPointRequest): Promise<ServiceResult<AddressPointResponse>> {
        const url = `${GetBaseUrl()}Create`;
        return Post<AddressPointResponse>(url, request);
    }

    /** Update an address point details */
    public Update(request: AddressPointRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}Update`;
        return Post<void>(url, request);
    }

    /** Get the list of address points belonged to the current user. */
    public GetMyAddressPoints(): Promise<ServiceResult<AddressPointDetails[]>> {
        const url = `${GetBaseUrl()}GetMyAddressPoints`;
        return Post<AddressPointDetails[]>(url, null);
    }

    /** Remove an address point from the database */
    public Remove(request: DeleteAddressPointRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}Remove`;
        return Post<void>(url, request);
    }
}

export const AddressPointsService = new AddressPointsServiceClass();