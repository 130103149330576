import { Component } from 'react';
import AuthImplV2 from './AuthImplV2';
import { ChildMessageKind, AuthType } from "./LoginEntities";
import { SilentLoginProps } from './SilentLogin';
import { CreateCredentialForSignup } from './CredentialHelper';

/**
 * This component triggers the Auth0 login/signup call
 */
class LoginChild extends Component {
    
    /** Clean up event handlers */
    componentWillUnmount() {
        window.removeEventListener("message", this.onWindowMessageReceived);
    }

    componentDidMount() {
        // register for messages from the parent
        window.addEventListener("message", this.onWindowMessageReceived, false);

        // inform parent I'm ready
        if (window.parent) {
            const message = { MessageKind: ChildMessageKind.ChildIsReady };
            window.parent.postMessage(message, window.location.origin);
        }
    }

    onWindowMessageReceived = (event: MessageEvent) => {
        
        // Security check
        if (event.origin != window.location.origin) return;
        if (event.source !== window.parent) return;

        const dataReceived: SilentLoginProps = event.data;

        if (dataReceived.AuthType === AuthType.Login) {

            const { Email, Password  } = dataReceived.Credentials;

            new AuthImplV2().LogInAuth0(Email!, Password!);
        }
        else if (dataReceived.AuthType === AuthType.Signup) {
            new AuthImplV2().SignUpAuth0(CreateCredentialForSignup(dataReceived.Credentials));  
        }        
    }

    render() { return null; }
}

export default LoginChild;