/** The list of places where a user can select an existing booking to re-book the same trip or a return trip */
export enum QuickBookSource {

    /** 'Recent trips' list in the Quick Book tab. */
    RecentTrips = "Quick Book",

    /** Trip History page. */
    TripHistory = "Trip History",

    /** Booking tracking card (only available for completed trips). */
    BookingCard = "Booking Card"
}