import { ServiceResult, HttpStatus, SuccessResult, ServiceCallOutcome, NetworkFailResult, ApplicationErrorResult, WebServerErrorResult, FailureResult } from "./ServiceEntities";
import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import appstore from "../appStore";
import { Config } from "../Config/Config";
import { InjectApiTesting } from "./InjectApiTesting";
import { IsExpired } from "../modules/Authentication/AuthHelper";
import { TryRenewAuth0Session } from "../modules/Authentication/AuthHelper";
import { GetMyBrand, GetValues } from "../Config/MyAppConfig";
import { FailureApiOutcome, SuccessApiResult } from "./ApiContracts";

const bookingAPIID = GetValues().InternalApplicationId;
const mgmtBrandId = GetValues().MgmtBrandId;

/** Includes the root URL and the /api/v1/<state>/ segments. Ends in a forwawrd slash. */
export function GetManagementServiceRootUrl(): string {
    const rootUrl = GetValues().ApiBaseUrl;
    const stateCode = GetAustraliaStateCode();
    return `${rootUrl}api/v1/${stateCode}/`;
}

/** Includes the root URL and the /api/v1/ segments without the state code. Ends in a forwawrd slash. */
export function GetManagementServiceRootUrlWithoutState(): string {
    const rootUrl = GetValues().ApiBaseUrl;
    return `${rootUrl}api/v1/`;
}

/** The state code (nsw / vic / etc) becomes a segment in the URL */
function GetAustraliaStateCode(): string {
    return appstore.getState().location.reliableLocation.value.stateCode ?? "vic";
}

/** Helper method to execute a get for the URL. 
    WARNING: don't consume this directly. It will get called by service classes like BookingService. */
export function Get<T>(url: string, timeout?: number): Promise<ServiceResult<T>> {
    return MakeCall<T>({
        method: "get",
        url,
        timeout,
    });
}

/** Helper method to execute a post for the URL, with the specified post body (data). */
export function Post<T>(url: string, data: any, timeout?: number): Promise<ServiceResult<T>> {
    return MakeCall<T>({
        method: "post",
        data,
        url,
        timeout,
    });
}

/** This config goes on all requests by default. Config from Get() / Post() will override these values */
var defaultConfig: AxiosRequestConfig = {
    timeout: Config.HttpRequestTimeoutMilliseconds,
    headers: {
        'Content-Type': 'application/json',
        'AppID': bookingAPIID,
        'BrandId': mgmtBrandId,
        BrandCode: GetMyBrand()
    },
}

/**
 * Add idToken to header if authenticated & got user profile
 */
async function GetAuthorisationHeader(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    /**
     * Renew Auth0 session if expired
     */
    const authState = appstore.getState().authentication;
    var expiresAt = authState.AuthToken!.expiresAt, idToken = "";

    if(IsExpired(Number(expiresAt))) {
        await TryRenewAuth0Session().then(authToken => { idToken = authToken != null ? authToken.idToken : ""; });
    }
    else {
        idToken = authState.AuthToken!.idToken;
    }

    /**
     * Add idToken into headers
     *
     * Only one scenario that we need to consider:
     *   1> A user loged in before;
     *   2> Auth0 session is almost expired;
     *   3> The user is on the booking form and filled all information and is going to make a booking;
     *   4> The user clicks the "Book" button;
     *   5> Before the API (createBooking), our logic check the session expired;
     *   6> Re-try 5 times all failed (NOT HAPPEN IN REAL SCENARIO); 
     *   7> We give pop-up and logout automatically;
     *   8> API call will still made to booking management with below 4 informartion as null:
     *      (1) Contact number;
     *      (2) VerificationID;
     *      (3) Verification code;
     *      (4) Token.
     *   9> Confirmed with backend, API will fail on booking management in this case. 
    */   
    if (idToken != "") {       
        config = {
            ...config,
            headers: { ...config.headers, 'Authorization':'Bearer ' + idToken } 
        };
    } 

    return config;
}

/** Helper method to execute an arbitrary HTTP request. */
async function MakeCall<T>(config: AxiosRequestConfig): Promise<ServiceResult<T>> {

    // API Failure Testing hook
    var testingResult = await InjectApiTesting();
    if (testingResult) {
        return testingResult;
    }
    // attach the default config
    
    config = {
        ...defaultConfig,
        ...config,
    };

    // Make sure an undefined timeout in config doesn't win
    config.timeout = config.timeout || Config.HttpRequestTimeoutMilliseconds;

    /**
     * Add idToken into headers if authenticated
     */
    const appState = appstore.getState();
    const auth = appState.authentication;

    if (auth.AuthToken) {
        config = await GetAuthorisationHeader(config);
    }
    else if (appState.GuestPayment.BackingUser) {
        // guest user with a registered payment method. act as the backing user
        config.headers.Authorization = appState.GuestPayment.BackingUser.Auth;
    }
    else {
        // removing previously assigned Authorization header if the state (appState) has changed without a page refresh (e.g. removing the BackingUser manually without refreshing the page)
        if (config.headers.Authorization) {
            const { Authorization, ...newHeaders } = config.headers;

            config.headers = newHeaders;
        }
    }

    if (GetValues().IsTestModeSupported && appstore.getState().apiFailureTesting.IsEnabled) {
        config.headers.TestMode = "true";
    }

    try {
        const axiosResult = await Axios(config);
        return Success(axiosResult);
    }
    catch (ex) {
        return Failure(ex);
    }
}

/**
 * Create a SuccessResult{T} object from a successful HTTP response.
 */
function Success<T>(axiosResult: AxiosResponse<unknown>): SuccessResult<T> {

    // unwrap ApiResponse<> from the payload
    const apiWrappedPayload = axiosResult.data as SuccessApiResult<T>;

    const result: SuccessResult<T> = {
        isSuccess: true,
        outcome: ServiceCallOutcome.Success,
        value: apiWrappedPayload.Value,
    };

    return result;
}

/**
 * Generate a FailureResult (various kinds) from an error thrown from axios.
 * It could be a timeout, or a negative HTTP status.
 */
function Failure(ex: unknown): FailureResult {

    const error = ex as AxiosError;

    // no response: network error
    if (!error.response) {
        const networkFail: NetworkFailResult = {
            isSuccess: false,
            outcome: ServiceCallOutcome.NoResponseFromServer,
            isTimeout: error.code === 'ECONNABORTED', // from axios documentation
        };

        return networkFail;
    }

    // otherwise we will at least get an http status
    const http: HttpStatus = {
        Code: error.response.status,
        Text: error.response.statusText,
    };

    // application level error
    if (error.response.status === 418) {

        const errorResult = error.response.data as FailureApiOutcome;

        const applicationError: ApplicationErrorResult = {
            isSuccess: false,
            outcome: ServiceCallOutcome.ApplicationError,
            httpStatus: http,
            apiError: errorResult.Error,
        };

        return applicationError;
    }

    // generic http error from the web server
    const webServerError: WebServerErrorResult = {
        isSuccess: false,
        outcome: ServiceCallOutcome.WebServerError,
        httpStatus: http,
    };

    return webServerError;
}