import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { LocationZoneResult } from "../../Services/LocationsEntitiesV2";
import { CuratedLocationsInitialState, CuratedLocationsState } from "./CuratedLocationsState";

/** Redux store slice for the Curated locations. */
const slice = new ReduxStoreSlice<CuratedLocationsState>("CuratedLocations", CuratedLocationsInitialState);

/** Redux action dispatcher for the CuratedLocations store slice. */
export const CuratedLocationsDispatch = {

    /** Update which address is currently active and the curated locations are loaded for. This is used to update the correct address index if the user picked an address from the curated addresses list. */
    CurrentlyActiveLocationIndex: slice.Action("CurrentlyActiveLocationIndex", UpdateCurrentlyActiveLocationIndex),

    /** Store the response from the curated locations API if there are any curated addresses in the response. */
    LocationSuggestions: slice.Action("LocationSuggestions", RefreshLocationsList)
}

/** Redux reducer for the CuratedLocations store slice. */
export const CuratedLocationsReducer = slice.MakeCombinedReducer();

/** Update which address is currently active and the curated locations are loaded for. This is used to update the correct address index if the user picked an address from the curated addresses list. */
function UpdateCurrentlyActiveLocationIndex(state: CuratedLocationsState, index: number): CuratedLocationsState {
    return {
        ...state,
        CurrentlyActiveLocationIndex: index
    }
}

/** Store the response from the curated locations API if there are any curated addresses in the response. */
function RefreshLocationsList(state: CuratedLocationsState, locationsResult: LocationZoneResult): CuratedLocationsState {
    return {
        ...state,
        CuratedLocationsResult: locationsResult
    }
}