import { Dispatch } from "../../Dispatch";
import { CheckPhone } from "../../PhoneNumber/CheckPhone";

export function ParseAndStoreDropoffContactNumber(phoneNumber: string) : void {

    const result = CheckPhone.FromRawNumber(phoneNumber);

    if (result.IsValid) {
        Dispatch.Booking.ContactPhone(1, result.Value);
    }
}

export function UpdateDropoffContactAndName(contactName: string, contactPhone: string) {
    
    Dispatch.Booking.ContactName(1, contactName);
	ParseAndStoreDropoffContactNumber(contactPhone)
}