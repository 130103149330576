import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { GooglePayInitialState, GooglePayState } from "./GooglePayState";

/** Redux store slice for the Google Pay data. */
const slice = new ReduxStoreSlice<GooglePayState>("GooglePay", GooglePayInitialState);

/** Redux action dispatcher for the GooglePay store slice. */
export const GooglePayDispatch = {

    /** The UI flow has progressed far enough to warrant loading the SDK. */
    ReadyToLoad: slice.EmptyAction("Ready To Load", ReadyToLoad),

    /** The GooglePay SDK is now being loaded asynchronously. */
    LoadStarting: slice.EmptyAction("Load Starting", LoadStarting),

    /** The GooglePay SDK load completed successfully. */
    LoadSucceeded: slice.EmptyAction("Load Succeeded", LoadSucceeded),

    /** The GooglePay SDK load failed with an error. */
    LoadFailed: slice.EmptyAction("Load Failed", LoadFailed),

    /** The current device/browser supports Google Pay. */
    GooglePaySupported: slice.EmptyAction("Google Pay Supported", GooglePaySupported),
}

/** Redux reducer for the GooglePay store slice. */
export const GooglePayReducer = slice.MakeCombinedReducer();

/** The UI flow has progressed far enough to warrant loading the SDK. */
function ReadyToLoad(state: GooglePayState): GooglePayState {

    if (state.LoadingStatus === SdkLoadingState.NotRequested) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.RequestedNotStarted,
        };
    }

    // update not supported from this state
    return state;
}

/** The GooglePay SDK is now being loaded asynchronously. */
function LoadStarting(state: GooglePayState): GooglePayState {

    if (state.LoadingStatus === SdkLoadingState.RequestedNotStarted) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.InProgress,
        };
    }

    // update not supported from this state
    return state;
}

/** The GooglePay SDK load completed successfully. */
function LoadSucceeded(state: GooglePayState): GooglePayState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedSuccess,
        };
    }

    // update not supported from this state
    return state;
}

/** The GooglePay SDK load failed with an error. */
function LoadFailed(state: GooglePayState): GooglePayState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedError,
        };
    }

    // update not supported from this state
    return state;
}

/** The current device/browser supports Google Pay. */
function GooglePaySupported(state: GooglePayState): GooglePayState {
    return {
        ...state,
        IsGooglePaySupported: true
    }
}