import { TabItem, EmptyView } from "./TabEntities";

export interface TabState {

    /* Selected nav item */
    SelectedTab: TabItem;
}

/** 
 * Initial value of the Tab state. The values will be empty initially. 
 * Each page which has the nav bar has its own default value and the store will be updated on page load.
*/
export const DefaultTabState: TabState = {
    SelectedTab: EmptyView
};