import { SdkLoadingState } from "../PayPal/PayPalState";

/** 
 * Redux store 3DS state slice.
 * Manages the background loading of the SDK.
 */
export interface ThreeDSecureState {

    /** The progress or result of loading the 3DS SDK. */
    LoadingStatus: SdkLoadingState;
}

export const ThreeDSecureInitialState: ThreeDSecureState = {

    LoadingStatus: SdkLoadingState.NotRequested,
}