import { ServiceCheckStatus, IndeterminateServiceCheck, PickupServiceCheckState } from "./BookingEntities";
import { ServiceCallOutcome, ServiceResult } from "../../Services/ServiceEntities";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { LogEvent } from '../../utils/LogEvent';
import { ApiGeoPoint } from "../../Services/BookingEntities";
import { DateTime } from "luxon";
import { ServiceAvailability } from "../../Services/MakeBookingContracts";

/** 
 * Version 2 from Global Booker.
 * Begins a cab service check for the specified pickup address (lat/lon).
 * This service will respond with vehicle conditions along with its availability.
 */
export async function CheckCabServiceAvailabilityV2(place: google.maps.places.PlaceResult): Promise<ServiceAvailability | null> {

    // start as pending
    const pendingStatus: IndeterminateServiceCheck = { status: ServiceCheckStatus.CheckInProgress };
    Dispatch.Booking.PickupServiceability({ PickupPlaceId: "", ServiceabilityCheckState: pendingStatus });

    // check cab availability first
    const geoLocation: ApiGeoPoint = {
        Latitude: place.geometry!.location!.lat(),
        Longitude: place.geometry!.location!.lng(),
    };

    const result = await Api.MakeBooking.CheckCabServiceAvailability(geoLocation);
    const newCheckState = ProcessServiceabilityResultV2(result);
    CheckForCabServiceErrors(newCheckState, place);
    
    Dispatch.Booking.PickupServiceability({ PickupPlaceId: "", ServiceabilityCheckState: newCheckState });

    // OK!
    if (result.isSuccess && newCheckState.status === ServiceCheckStatus.KnownGood) {
        return result.value;
    }

    // Failed to get conditions
    return null;
}

/** 
 * Post operations after the check cab service, such as application insights and show popup if there is an error
 */
export function CheckForCabServiceErrors(checkState: PickupServiceCheckState, place: google.maps.places.PlaceResult) {

    if (checkState.status === ServiceCheckStatus.KnownBad || checkState.status === ServiceCheckStatus.Error) {

        // appInsights
        LogEvent.CheckPickupAddressAvailabilityFailure(checkState.internalErrorMessage, place);

        // popup
        if (checkState.isPickupErrorMessageShown === false) {
            ShowDialogSimpleErrorMessage(checkState.errorMessage); 
        }
    }

    if (checkState.status === ServiceCheckStatus.KnownGood) {

        const myTimeZone = DateTime.local().zoneName;
        const observedZone = checkState.TimeZoneId;

        if (observedZone != myTimeZone) {
            LogEvent.TimeZoneMismatch(myTimeZone, observedZone, place);
        }
    }
}

/** 
 * This is V2 of ProcessResult() in file CheckCabService.ts
 * Convert the result of the service call from CheckCabServiceAvailabilityService into a PickupServiceCheckUpdateAction
 */
export function ProcessServiceabilityResultV2(result: ServiceResult<ServiceAvailability>): PickupServiceCheckState {

    // well-known API error
    if (result.outcome == ServiceCallOutcome.ApplicationError) {
        return {
            status: ServiceCheckStatus.KnownBad,
            errorMessage: WellKnownMessageKind.GeneralFailure,
            isPickupErrorMessageShown: false,
            internalErrorMessage: result.apiError.Summary.FullMessage,
        };
    }

    // unanticipated error
    if (!result.isSuccess) {
        return {
            status: ServiceCheckStatus.Error,
            errorMessage: WellKnownMessageKind.GeneralFailure,
            isPickupErrorMessageShown: false,
            internalErrorMessage: result.outcome == ServiceCallOutcome.NoResponseFromServer ? ServiceCallOutcome.NoResponseFromServer : "An unexpected error occured"
        };
    }

    // area not serviceable
    if (!result.value.IsServiced) {
        return {
            status: ServiceCheckStatus.KnownBad,
            errorMessage: WellKnownMessageKind.NoServiceAvailable,
            isPickupErrorMessageShown: false,
            internalErrorMessage: result.value.ServiceFailureMessage
        };
    }

    // OK!
    LogEvent.CheckPickupAddressAvailabilitySuccess();

    // There is no suburbId required in implementation from Global Booker
    return {
        status: ServiceCheckStatus.KnownGood,
        suburbId: null,
        TimeZoneId: result.value.TimeZoneId,
    };
}