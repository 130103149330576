import { GeoPoint } from "../Location/Entities";

/** The subset of the Application's state related to the Google Map feature. */
export interface GoogleMapState {

    /** For the "a" marker / pin on the map. May be undefined while the user is still filling out a booking form. */
    pickupLocation?: GeoPoint;

    /** For the "b" marker / pin on the map. May be undefined while the user is still filling out a booking form. */
    dropoffLocation?: GeoPoint;

    /** The center of the map's focus. This property is managed by the reducer based on the other properties. It will be undefined if pickup AND dropoff are undefined. */
    mapCenter?: GeoPoint;

    /** integer parameter used by Google Maps. 1 = most zoomed out, 14 = roughly suburb level. This property is managed by the reducer based on the other properties. */
    zoomLevel?: number;

    /** A route from the pickup to the dropoff, usually associated with a fare estimate. */
    PlannedRoute: google.maps.LatLngLiteral[] | null;
}

export const GoogleMapInitialState: GoogleMapState = {
    PlannedRoute: null,
};