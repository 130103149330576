import React from "react";
import { connect } from "react-redux";
import "./MyBookings.scss";
import BookingCard from "./BookingCard";
import { BookingStatus } from "../../../Services/BookingEntities";
import { ApplicationState } from "../../../appState";
import { ExtendedEta, BookingInfo } from "../MyBookingEntities";
import { OrderBookingsByTimeInDesc } from "../Logic/OrderMyBookings";

interface MyBookingsProps {
    BookingsList: BookingInfo[];
    EtaLookup: Record<number, ExtendedEta>
}

/** Component which contains the list of bookings in Pickedup and Completed statuses. This is the third column of the Schedule View tab. */
class PickedupBookingsList extends React.Component<MyBookingsProps> {

    constructor(props: MyBookingsProps) {
        super(props);
    }

    render() {
        // Sorting the bookings list based on the pickedup time (recent pickedup on top).
        const bookingList = OrderBookingsByTimeInDesc(this.props.BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp || booking.TrackingInfo.BookingStatusID == BookingStatus.Completed));

        return (
            <div className="pickedup-bookings-list-panel">
                <div className="pickedup-bookings-header-section">
                    <h3 className="pickedup-bookings-header">En-route to destination</h3>
                </div>
                <div className="pickedup-bookings-list">
                    {bookingList.map((booking) => <BookingCard ShouldAutoOpen={false} BookingDetails={booking} key={booking.BookingID} Eta={this.props.EtaLookup[booking.BookingID]} />)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): MyBookingsProps {
    return {
        BookingsList: state.myBookings.All,
        EtaLookup: state.myBookings.Etas
    }
}

export default connect(mapStateToProps)(PickedupBookingsList);