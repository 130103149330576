import React from "react";
import "./NotesToDriver.scss";
import $ from "jquery";
import { connect } from "react-redux";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { Dispatch } from "../../Dispatch";
import { ApplicationState } from "../../../appState";
import { Config } from "../../../Config/Config";
import { TextField } from "@mui/material";
import { LogEvent } from "../../../utils/LogEvent";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import { PerLocationProps } from "./PerLocationProps";

const MaxLength = Config.DriverNotesMaxLength;

interface PropsFromStore {
    PickupNotes: string | null;
    DropoffNotes: string | null;
    ActiveBookingForm: BookingFormKind;
}

interface NotesToDriverState {
    isFocus: boolean;
    NotesLength: number;
}

interface DriverNotesProps extends PerLocationProps {

    /** 
     *  Indicates there is a single driver note for the whole booking.
     *  It will still be represented behind the scenes as the pickup notes, but the label will be broader.
     */
    Combined?: true;
}

class NotesToDriver extends React.Component<PropsFromStore & DriverNotesProps, NotesToDriverState> {
    private inputRef: React.RefObject<HTMLTextAreaElement>;

    constructor(props: PropsFromStore & DriverNotesProps) {
        super(props);
        this.state = {
            isFocus: false,
            NotesLength: MaxLength
        };
        this.inputRef = React.createRef();
        this.CalculateCharactersLength = this.CalculateCharactersLength.bind(this);
    }

    componentDidMount() {
        if (this.props.Location === 1) {
            this.inputRef.current!.value = this.props.DropoffNotes ?? "";
        }
        else {
            this.inputRef.current!.value = this.props.PickupNotes ?? "";
        }

        // Display the remaining character length when populating data from a different source (e.g: Favourites or book from history).
        this.CalculateCharactersLength();
    }

    componentDidUpdate(prevProps: Readonly<PropsFromStore & DriverNotesProps>): void {
        if((this.props.Location === 1 && this.props.DropoffNotes != prevProps.DropoffNotes)||(this.props.Location === 0 && this.props.PickupNotes != prevProps.PickupNotes))
        {
            const driverNote = this.props.Location === 1 ? this.props.DropoffNotes : this.props.PickupNotes;
            
            if (this.inputRef.current!.value !== driverNote) {
                this.inputRef.current!.value = driverNote ?? "";
                this.CalculateCharactersLength();
            }
        }
    }

    /** Condition check and dynamic css changes */
    CalculateCharactersLength() {
        var currentLength = this.inputRef.current!.value.length;

        if (currentLength >= MaxLength - 30) {
            $(".custom-label-notes").css("color", "red");
            $(".custom-img-layout-notes").css("display", "block");
        } else {
            $(".custom-label-notes").css("color", "black");
            $(".custom-img-layout-notes").css("display", "none");
        }
        var noteslength = MaxLength - currentLength;
        this.setState({ NotesLength: noteslength });
    }

    /** Call Action Creator  */
    CallStoreToUpdate(e: any) {
        Dispatch.Booking.DriverNotes(this.props.Location, e.target.value);

        this.UpdateFocusStatus(false);
        if (e.target.value) {
            LogEvent.OnAddingDriverNotes();
        }
    }

    /** Set focus status for driver notes */
    UpdateFocusStatus = (isFocus: boolean) => this.setState({ isFocus: isFocus });

    /** Returns the label for the text box. */
    GetLabelText(): string {

        // pickup + dropoff combined
        if (this.props.Combined) return "Notes for driver";

        // pickup
        if (this.props.Location === 0) return "Pickup notes for driver";

        // dropoff has two different names for some reason
        if (this.props.ActiveBookingForm === BookingFormKind.ParcelBooking) {
            return "Dropoff notes for driver";
        }
        else {
            return "Destination notes for driver";
        }
    }

    render() {
        
        const canShrinkLabel = this.state.isFocus || !!this.inputRef.current?.value;
        const label = this.GetLabelText();
        
        return (
            <div className="booking-fields-panel drivernotes-panel">
                <TextField
                    maxRows={3}
                    multiline={true}
                    fullWidth={true}
                    variant="outlined"
                    inputRef={this.inputRef}
                    label={label}
                    className="simple-textfield"
                    id="outlined-multiline-static"
                    inputProps={{maxLength: MaxLength}}
                    onInput={this.CalculateCharactersLength}
                    InputLabelProps={{shrink: canShrinkLabel}}                
                    onBlur={(e1) => this.CallStoreToUpdate(e1)}                        
                    onFocus={() => this.UpdateFocusStatus(true)}
                    placeholder="e.g. unit, gate and floor numbers."
                />
                <div className="character-calculation-div">           
                    <img
                        alt="warning"
                        src={getContentUrl(ContentURL.images.Diagnostic.warning)}
                        className="custom-img-layout-notes"
                    />
                    <label className="input-label custom-label-notes">{this.state.NotesLength} characters left</label>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState): PropsFromStore => {
    return {
        PickupNotes: state.booking.Locations[0].DriverNotes,
        DropoffNotes: state.booking.Locations[1].DriverNotes,
        ActiveBookingForm: state.uiLogicControl.BookingForm.ActiveBookingForm
    };
};

export default connect(mapStateToProps)(NotesToDriver);
