import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import BookingTemplateNameField from "../../BookingTemplate/BookingTemplateNameField";

/**
 * Possibly renders the Booking Template Name field in the booking form.
 * This only appears when editing a booking template.
 * Currently this is desktop only.
 */
const MaybeBookingTemplateNameFieldCore: React.FC<ReduxProps> = (props) => {

    if (props.BookingTemplateMode === BookingWidgetModeKind.Booking) return null;

    return <BookingTemplateNameField />
}

// #region Redux

interface ReduxProps {

    /** Whether the user is editing a booking or just a booking template. */
    BookingTemplateMode: BookingWidgetModeKind;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        BookingTemplateMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
    };
}

export const MaybeBookingTemplateNameField = connect(GetPropsFromRedux)(MaybeBookingTemplateNameFieldCore);

// #endregion