import { BookingAuthority } from "../../../Services/BookingEntities";

/** Application state about shared booking tracking links. */
export interface TrackingLinksState {

    /** 
     * All booking tracking links that have been received over time. 
     * This data will be persisted in HTML Local Storage.
     * There may be multiple links for the same booking, but they will have different access codes.
     */
    All: BookingLink[];

    /** 
     *  A booking link from the URL the application started from.
     *  Null if the website was not loaded at a tracking link URL.
     */
    NewFromUrl: BookingLink | null;
}

/** Initial value of the TrackingLinks state */
export const TrackingLinksInitialState: TrackingLinksState = {
    All: [],
    NewFromUrl: null,
};

/** Full details of a tracking link including the link version. */
export interface BookingLink {

    /** Version of the link derived from the path /b or /b2 */
    LinkVersion: number;

    /** Booking key which is used to determine the access rights for the booking */
    BookingKey: BookingAuthority;
}