import { AddPaymentMethodRequest, AddPaymentMethodResponse, AddPayPalRequest, GuestPayUserInfo, RegisterCnpGuestRequest, RegisterCnpGuestResponse, RegisterPayPalGuestRequest, VaultedCardNonceRequest, VaultedCardNonceResponse } from './PaymentContractsV2';
import { ServiceResult } from './ServiceEntities';
import { GetManagementServiceRootUrlWithoutState, Post } from './ServiceManager';

/** Base URL for the PaymentV2Controller in the Booking Management Service 
 *  I moved this outside the class to avoid issues with "this". */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}PaymentV2/`;
}

/** HTTP API Calls to the PaymentV2Controller in the Booking Management Service. */
class PaymentServiceV2Class {

    /** Returns a token enabling the client (website) to authenticate to the payment gateway in order to perform tokenisation of a payment method. */
    public GetClientToken(): Promise<ServiceResult<string>> {
        const url = `${GetBaseUrl()}GetClientToken`;
        return Post<string>(url, null);
    }

    /** Register a PayPal payment method while being a guest user. */
    public RegisterPayPalGuest(request: RegisterPayPalGuestRequest): Promise<ServiceResult<GuestPayUserInfo>> {
        const url = `${GetBaseUrl()}RegisterPayPalGuest`;
        return Post<GuestPayUserInfo>(url, request);
    }

    /** Add a PayPal payment method to the (signed in) user's wallet. */
    public AddPayPal(request: AddPayPalRequest): Promise<ServiceResult<object>> {
        const url = `${GetBaseUrl()}AddPayPal`;
        return Post<object>(url, request);
    }

    /** Add a wallet based payment method to the signed in user's wallet. */
    public RegisterPaymentMethod(request: AddPaymentMethodRequest): Promise<ServiceResult<AddPaymentMethodResponse>> {
        const url = `${GetBaseUrl()}RegisterPaymentMethod`;
        return Post<AddPaymentMethodResponse>(url, request);
    }

    /** Register an Apple Pay payment method while being a guest user. */
    public RegisterApplePayGuest(request: RegisterCnpGuestRequest): Promise<ServiceResult<RegisterCnpGuestResponse>> {
        const url = `${GetBaseUrl()}RegisterApplePayGuest`;
        return Post<RegisterCnpGuestResponse>(url, request);
    }

    /** Register a Google Pay payment method while being a guest user. */
    public RegisterGooglePayGuest(request: RegisterCnpGuestRequest): Promise<ServiceResult<RegisterCnpGuestResponse>> {
        const url = `${GetBaseUrl()}RegisterGooglePayGuest`;
        return Post<RegisterCnpGuestResponse>(url, request);
    }

    /** Get a nonce value for a pre-existing (vaulted) card from MPS that can be used to initiate the 3Ds verification flow. */
    public GetVaultedCardNonce(request: VaultedCardNonceRequest): Promise<ServiceResult<VaultedCardNonceResponse>> {
        const url = `${GetBaseUrl()}GetVaultedCardNonce`;
        return Post<VaultedCardNonceResponse>(url, request);
    }

    /** Register a credit/debit card payment method while being a guest user. */
    public RegisterCreditCardGuest(request: RegisterCnpGuestRequest): Promise<ServiceResult<RegisterCnpGuestResponse>> {
        const url = `${GetBaseUrl()}RegisterCreditCardGuest`;
        return Post<RegisterCnpGuestResponse>(url, request);
    }
}

/** Singleton export for static access to the operations */
export const PaymentServiceV2 = new PaymentServiceV2Class();