import React from 'react';
import './MobileDialog.scss';
import { connect } from 'react-redux';
import { DialogState } from './DialogState';
import { ApplicationState } from '../../appState';
import { ResponsiveDialogHost } from './ResponsiveDialogHost';
import { DialogLoading } from './DialogLoading';
import { DialogConfigLookup } from "./DialogConfig";
import Slide from '@mui/material/Slide';

type PropsFromStore = Omit<DialogState, 'detailedErrorMessage'>;;

/**
 * Host for all popups (dialogs) in the mobile application.
 */
export const MobileDialogCore: React.FC<PropsFromStore> = (props) => {
    
    // quick exit: none open
    if (!props.isAnyOpen) return null;

    return (
        <>
            {DecideDialogAnimationEffect()}
            <DialogLoading />
        </>
    );

    /**
     * Decide whether to use Material-UI <slide> animation for dialog show/close operations per dialog kind.
     */
    function DecideDialogAnimationEffect() {

        const config = DialogConfigLookup(props.topmostDialog!);

        const content = (
            <div className="mobile-container">
                <ResponsiveDialogHost dialog={props.topmostDialog!} />
            </div>
        );

        if (!config?.RequireAnimation) {
            return content;
        }

        return (
            <Slide direction="right" in={!!props.topmostDialog} mountOnEnter unmountOnExit>
                {content}
            </Slide>
        );
    }
}

/** Connection from store state to local props. */
function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        isAnyOpen: state.dialog.isAnyOpen,
        openDialogs: state.dialog.openDialogs,
        topmostDialog: state.dialog.topmostDialog
    };
}

export const MobileDialog = connect(mapStateToProps)(MobileDialogCore);