import appstore from "../../appStore";
import { ApiVehicleOption, ConditionIds } from "./Redux/ConditionEntities";
import { GetValues } from "../../Config/MyAppConfig";

/**
 * Add Silver Service condition if required. Don't add it for 'Next Available'.
 */
export function ConsiderAddingSilverServiceCondition(vehicleOptions: ApiVehicleOption[]) {

    if (ShouldAddSilverServiceCondition()) {

        const silverService = appstore.getState().condition.ConditionList.find(c => c.ApiVehicle?.ApiId === ConditionIds.silver);

        if (silverService && silverService.ApiVehicle) {
            vehicleOptions.push(silverService.ApiVehicle);
        }
    }
}

/** Decide whether to add Silver Service condition in addition to the user selected condition. */
function ShouldAddSilverServiceCondition() {
    const availableConditions = appstore.getState().condition.ConditionList;
    const selectedCondition = appstore.getState().condition.SelectedCondition;

    // Silver Service is not available in the region.
    if (availableConditions.filter(c => c.ApiVehicle?.ApiId === ConditionIds.silver).length === 0) return false;

    // User has selected Silver Service from the vehicle selector
    if (selectedCondition.ApiVehicle?.ApiId === ConditionIds.silver) return false;

    // Current application is configured to default Silver Service.
    if (GetValues().DefaultToSilverservice) return true;

    return false;
}