import { TncInfoResponse } from "../../Services/TncEntities"; 
import { WebPage } from "./TncPrivacyEntities"; 

export type TncPrivacyActions = TncInfoChangeAction | TncContentChangeAction | PrivacyInfoChangeAction;

/** Action type for changing tncId, tncDescription & tncUrl in TncState */
export interface TncInfoChangeAction {
    type: TncPrivacyActionTypes.TncInfoOnChange;
    payload: TncInfoResponse;
}

/** Action type for changing TncState.tncContent */
export interface TncContentChangeAction {
    type: TncPrivacyActionTypes.TncContentOnChange;
    payload: string;
}

/** Action type for changing TncPrivacyState.PrivacyInfo */
export interface PrivacyInfoChangeAction {
    type: TncPrivacyActionTypes.PrivacyInfoOnChange;
    payload: WebPage;
}

export enum TncPrivacyActionTypes {
    /** Action type for changing tncId, tncDescription & tncUrl in TncPrivacyState */
    TncInfoOnChange = 'TncInfoOnChange',

    /** Action type for changing TncPrivacyState.tncContent */
    TncContentOnChange = 'TncContentOnChange',

    /** Action type for changing TncPrivacyState.PrivacyInfo */
    PrivacyInfoOnChange = 'PrivacyInfoOnChange'
}