import appstore from "../../../appStore";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { ApiGeoPoint } from "../../../Services/BookingEntities";
import { loadGoogleDirections } from "../../../widgets/google-maps/loadGoogleDirections";
import { GeoPoint } from "../../Location/Entities";

/** Wraps the loadGoogleDirections() call with entity translation.
 * Returns null if the Google Maps API call fails. */
export async function GetGoogleDirections(start: ApiGeoPoint, finish: ApiGeoPoint, googleOdrdTripId?: string ): Promise<google.maps.DirectionsResult | null> {

    // translate to GeoPoint
    const googleStart: GeoPoint = {
        latitude: start.Latitude,
        longitude: start.Longitude,
    };

    const googleFinish: GeoPoint = {
        latitude: finish.Latitude,
        longitude: finish.Longitude,
    };

    // temporary overwrite of global ODRD IDs
    if (!!googleOdrdTripId && FeatureFlags.GoogleMapsBetaChannel) {
        google.maps.Settings.getInstance().experienceIds = [googleOdrdTripId];
    }

    const result = await loadGoogleDirections(googleStart, googleFinish, true);

    // restore previous ODRD IDs, if possible
    if (!!googleOdrdTripId && FeatureFlags.GoogleMapsBetaChannel) {
        const originalId = appstore.getState().booking.GoogleOdrdTripId;

        if (originalId) {
            google.maps.Settings.getInstance().experienceIds = [originalId];
        }
    }

    if (!result) return null;

    return result.directions;
}