import React from 'react';
import { DialogKind } from '../Dialog/DialogEntities';
import '../Verification/ContactVerification.scss';
import { ScheduleView } from '../../widgets/NavBar/TabEntities';
import { ContactNumberKind } from '../Verification/VerificationEntities';
import { DetermineContactPhoneKind } from '../Verification/DetermineContactPhoneKind';
import { Dispatch } from '../Dispatch';
import { ApplicationState } from '../../appState';
import { connect } from 'react-redux';
import { IsMobileDevice } from '../../utils/DetectDevice';
import { UILayoutMode } from '../UILogicControl/UILogicControlEntities';
import { GetBrandedUrl, BrandedImage } from '../Utils/BrandedContentUrls';
import { BookingTabKind } from '../UILogicControl/UILogicControlEntities';
import { ShowDialogSimpleErrorMessage } from '../../widgets/general-error-message/ErrorMessagingHelper';
import { WellKnownMessageKind } from '../Utils/ErrorMessages';
import { LogEvent } from '../../utils/LogEvent';
import { BookingInfo } from '../MyBookings/MyBookingEntities';

interface PropsFromStore {
    BookingToShare: BookingInfo;
    IsMobileDevice: boolean;
}

/** 
 * The popup to let the user know that the booking has been created successfully and an SMS has been sent.
 * This is an interim solution until implement the scheduled view UI
 */
class Confirmation extends React.Component<PropsFromStore> {

    constructor(props: PropsFromStore) {
        super(props);

        this.openSharePopup = this.openSharePopup.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.okClicked = this.okClicked.bind(this);
    }

    closeDialog() {
        Dispatch.Dialog.CloseDialog(DialogKind.Confirmation);
        Dispatch.Tab.SelectItem(ScheduleView); // This is only working for desktop, but harmless for mobile device
        
        if (this.props.IsMobileDevice) {
            Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.ActiveBooking);
        }
    }

    okClicked() {
        LogEvent.OkButtonClickedOnBookingConfirmation();
        this.closeDialog();
    }

    componentDidMount() {
        LogEvent.OnBookingConfirmationLoad();
    }

    /** Get the read only hash code and open the share booking popup. */
    async openSharePopup() {        
        this.closeDialog();
        if (this.props.BookingToShare.ReadAccessCode) {
            Dispatch.Dialog.ShowDialog(DialogKind.ShareBooking);
        } else {
            // Read only access code is not available. e.g.: API call failed.
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        }
        
    }

    render() {
        /**
         * "Thanks text" and "confirmation-illustration" will be different depends on whether the contact number used for booking is "mobile" or "landline"
         */
        var thanksText = "Thanks for booking with 13cabs!";
        var illustrationConfirmation = GetBrandedUrl(BrandedImage.BookingConfirmed);
        var illustrationStyle = "confirmation-illustration-landline";
        const footerClass = "confirmation-footer";
        const confirmButtonWrapperCssClass = !IsMobileDevice() ? "confirm-btn-wrapper" : "";

        if (DetermineContactPhoneKind() === ContactNumberKind.Mobile) {
            thanksText = "We’ve sent an SMS to your mobile with all your booking details.";
            illustrationConfirmation = GetBrandedUrl(BrandedImage.SmsToPhone);
            illustrationStyle = "confirmation-illustration-mobile";
        }
        
        return (
            <div className="contact-details mobile-confirmation">
                <h1 className="orange-header">Booking Scheduled</h1>
                <p className="thanks-text">{thanksText}</p>
                <img className={illustrationStyle} src={illustrationConfirmation} alt="" />
                <div className={footerClass}>
                    <div className={confirmButtonWrapperCssClass}><button type="button" className="confirmation-btn" onClick={this.okClicked}>OK</button></div>
                    { !this.props.IsMobileDevice && <button type="button" className="share-from-confirmation" onClick={this.openSharePopup}>Share booking</button> }
                </div>                
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        BookingToShare: state.myBookings.SelectedBookingToShare!,
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    }
}

export default connect(mapStateToProps)(Confirmation);