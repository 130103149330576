import React from "react";
import { connect } from "react-redux";
import "./MyBookings.scss";
import { BookingStatus, BookingDataOwnership } from "../../../Services/BookingEntities";
import moment from "moment";
import { Dispatch } from "../../Dispatch";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { ApplicationState } from "../../../appState";
import { ShouldRemoveCancelledBooking } from "../Logic/RefreshMyBookings";
import { RemoveBookingCard } from "../Logic/OperateOnBookingCard";
import { BookingInfo } from "../MyBookingEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";

interface BookingProps {
    BookingDetails: BookingInfo;
}

interface StatusDisplayPropsFromStore {
    LayoutMode: UILayoutMode;
}

/** Component to display the status of the booking. */
class StatusDisplay extends React.Component<BookingProps & StatusDisplayPropsFromStore> {  

    private completedTimer: any;

    constructor(props: BookingProps & StatusDisplayPropsFromStore) {
        super(props);
    }
    
    /** When this detects the status update to Completed for the first time, the timer starts and runs every one minute.
     * After 3 hours of the booking time (might be the MeterOff time later), the card will be removed. */
    countDownToRemove() {

        const isLinkBooking = this.props.BookingDetails.DataOwnership === BookingDataOwnership.WritableLink || this.props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink;

        // No auto remove of tracking link bookings.
        if (isLinkBooking) return;
        if (this.props.BookingDetails.TrackingInfo.BookingStatusID != BookingStatus.Completed) return;
        if (this.completedTimer) return;

        this.completedTimer = setInterval(() => {
            const bookingTime = moment(this.props.BookingDetails.Time, "MM/DD/YYYY HH:mm:SS");
            const cutoffTime = moment().add(-3, "hours");
            if (bookingTime < cutoffTime) {
                clearInterval(this.completedTimer);
                RemoveBookingCard(this.props.BookingDetails);
            }
        }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.completedTimer);
    }

    /** Derive the status display text based on the status id, driver details and meter on/off times */
    getStatusDisplayText(statusID: BookingStatus, driverName: string | null): string {
        const { LayoutMode } = this.props; 

        if (statusID == BookingStatus.NotDispatched) {
            return "Live tracking map will appear here.";
        }
        else if (FeatureFlags.BookingApiV2 && (statusID == BookingStatus.Dispatching || statusID == BookingStatus.Assigned)) {
            // Don't show dispatching text for GB bookings.
            return "Booking scheduled";
        }
        else if (statusID == BookingStatus.Acknowledged || statusID == BookingStatus.Assigned || statusID == BookingStatus.Dispatching) {
            return "We're finding you a cab";
        } 
        else if (statusID == BookingStatus.Accepted && driverName) {
            if (this.props.BookingDetails.IsDeliveryBooking) {
                return "Driver " + driverName + " is on their way to pickup"; 
            } else {
                return "Driver " + driverName + " is on their way";  
            }
        }      
        else if (statusID == BookingStatus.Accepted) {
            return "Your driver is on the way";
        } 
        else if (statusID == BookingStatus.PickedUp && driverName) {
            
            if (this.props.BookingDetails.IsDeliveryBooking) {
                return driverName + " picked up your parcel";
            } else {
                return "Picked-up by " + driverName;
            }
        } 
        else if (statusID == BookingStatus.PickedUp) {
            return "In taxi";
        } 
        else if(statusID == BookingStatus.Completed) {
            this.countDownToRemove();
            if (this.props.BookingDetails.IsDeliveryBooking) {
                return "Delivery Completed";
            } else {
                return "Trip Completed";
            }
        } 
        else if(statusID == BookingStatus.Cancelled) {
            // Remove the booking from the list
            if (ShouldRemoveCancelledBooking(this.props.BookingDetails, this.props.BookingDetails.TrackingInfo.WasJitPreauthFailure)) {
                Dispatch.MyBookings.Remove(this.props.BookingDetails);
            } else {
                return "Booking Cancelled";
            }
        } 
        else if(statusID == BookingStatus.NoJob) {
            
            if (this.props.BookingDetails.IsDeliveryBooking) {
                return "The parcel could not be collected";
            } else {
                return LayoutMode === UILayoutMode.Mobile ? "Passenger not found" : "The passenger could not be found";
            }
        }
        return "";
    }

    GetStatusDisplayClass() {
        const statusID = this.props.BookingDetails.TrackingInfo.BookingStatusID;

        if (statusID == BookingStatus.Cancelled) return "status-section cancelled-text";
        if (statusID == BookingStatus.NotDispatched) return "status-section scheduled-text";

        return "status-section";
    }

    /** Decides when to display the loading ellipsis next to the status text. */
    ShouldDisplayLoadingEllipsis() {
        const statusID = this.props.BookingDetails.TrackingInfo.BookingStatusID;

        // GB's 'Dispatch' status is different from real dispatching of the booking. Don't show 'dispatching' status for GB bookings. 
        if (FeatureFlags.BookingApiV2) return false;
        if (statusID == BookingStatus.Dispatching) return true;
        if (statusID == BookingStatus.Assigned) return true;
        if (statusID == BookingStatus.Acknowledged) return true;

        return false;
    }

    render() {
        const statusText = this.getStatusDisplayText(this.props.BookingDetails.TrackingInfo.BookingStatusID, this.props.BookingDetails.TrackingInfo.DriverName);

        return (
            <React.Fragment>
                <div className={this.GetStatusDisplayClass()}>
                    {statusText}
                    {this.ShouldDisplayLoadingEllipsis() && <div className="loading"></div>}
                </div>                
            </React.Fragment>
        );
    }
}

function GetMyPropsFromStore(state: ApplicationState): StatusDisplayPropsFromStore {
    return {
        LayoutMode: state.uiLogicControl.LayoutMode
    };
}

export default connect(GetMyPropsFromStore)(StatusDisplay);