import appstore from "../../appStore";
import { Api } from "../../Services/Api";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { Dispatch } from "../Dispatch";
import { MyStorage } from "../../Storage";
import { ConvertBookingTrackingResultToBookingInfoBulk } from "../MyBookings/ConvertAPIContractsToInternalModels";
import { BookingAuthority } from "../../Services/BookingEntities";
import { CheckNewLinkV2, LoadLinksV2 } from "./LoadLinksV2";
import { BookingLink } from "./Redux/TrackingLinksState";
import { ConsiderLoadDriverDetailsOfRefreshedBookings } from "../MyBookings/Logic/RefreshDriverDetails";

/** Startup: load link bookings. */
export async function LoadLinks(): Promise<void> {

    RestoreLinks();
    await CheckNewLink();

    var links = appstore.getState().trackingLinks.All;

    // Use different APIs to load link bookings based on their LinkVersion.
    const linksV1 = links.filter(i => i.LinkVersion === 1);
    const linksV2 = links.filter(i => i.LinkVersion === 2);

    if (linksV1.length > 0) {
        const bookingKeys: BookingAuthority[] = linksV1.map(i => i.BookingKey);
        await LoadLinksV1(bookingKeys);
    }
    if (linksV2.length > 0) {
        const bookingKeys: BookingAuthority[] = linksV2.map(i => i.BookingKey);
        await LoadLinksV2(bookingKeys);
    }
}

/** Use V1 API to load link bookings with URL path /b/ (LinkVersion === 1) */
async function LoadLinksV1(bookingLinks: BookingAuthority[]): Promise<void> {

    const result = await Api.Booking.GetBookingsByLinkBulk(bookingLinks);

    if (!result.isSuccess) return;

    const linkedBookings = result.value;
    if (linkedBookings.length > 0) {
        const linkedBookingsList = ConvertBookingTrackingResultToBookingInfoBulk(linkedBookings);
        Dispatch.MyBookings.RefreshLinkBookings(linkedBookingsList);
        // Add to the All bookings list
        Dispatch.MyBookings.AddLinkBookingsToAll(linkedBookingsList);

        // Consider to load driver details
        ConsiderLoadDriverDetailsOfRefreshedBookings(linkedBookingsList);
    }    
}

/** Restore links from storage; put back in Redux */
function RestoreLinks() {

    const previousLinks = MyStorage.TrackingLinksV2.LoadData();

    if (previousLinks) {
        Dispatch.TrackingLinks.RestoreLinks(previousLinks);
    }
}

/** If a new link came in from the URL, check it */
async function CheckNewLink(): Promise<void> {

    const newLink = appstore.getState().trackingLinks.NewFromUrl;
    if (!newLink) return;

    if (newLink.LinkVersion === 1) {
        await CheckNewLinkV1(newLink);
    } else {
        await CheckNewLinkV2(newLink);
    }
}

/** Use V1 API to check links with LinkVersion === 1 */
async function CheckNewLinkV1(newLink: BookingLink): Promise<void> {
    const result = await Api.Booking.CheckLink(newLink.BookingKey);

    if (!result.isSuccess) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidBookingLink);
        return;
    }

    Dispatch.TrackingLinks.AddLink(newLink);
}