/**
 * Nav bar structure
 * Name - Menu item name, this is used to compare selected item 
 * Title - Menu item title - this is displayed on the screen
 * Image - Menu Icon
 */
export interface UserProfileNavbarItem {
    Name: UserProfileNavBarNames,
    Title: string,
    Image: string
}


/** 
 * enum for user profile menu items
 * followed a similar approach to tab items, because enums are better way to compare selected
 * item rather than harcoded string
 */
export enum UserProfileNavBarNames {
    /** No view visible. Just for the default state */
    None = "",

    /** Personal details component to display user's details like phone number etc */
    PersonalDetails = "personalDetails",

    /** Component which will display user's payment options*/
    Wallet = "wallet",

    /** Address points saved by the user. */
    AddressPoints = "Address Points",

     /** Available features component to display list of feature to opt-in/opt-out by the user. */
     AvailableFeatures = "Available Features"
}