import { ServiceInfo } from "../../utils/service-vehicles/ServiceMetadata";

/** 
 *  Strongly typed representation of the booking widget prefill options. 
 *  These come in from the URL query string.
 *  The properties are optional since we can't guarantee that any have been populated.
 */
export interface PrefillOptions {

    /** pickup address text */
    PickupAddress?: string;

    /** dropoff address text */
    DropoffAddress?: string;

    /** customer's name */
    CustomerName?: string;

    /** Service / (vehicle) */
    Service?: ServiceInfo;

    /** customer's phone number */
    PhoneNumber?: string;

    /** Order number from the vendor so the driver can present that at the Click and Collect counter. */
    OrderNumber?: string;

    /** driver notes for the pickup location */
    PickupInstruction?: string;

    /** driver notes for the dropoff location */
    DropoffInstruction?: string;

    /** A popup note explaining the situation */
    ScenarioTip?: PrefillTip,

    /** Trigger a feature like login or fare estimate */
    Feature?: StartupFeature,
}

/** A directive to show a custom dialog explaining the feature. */
export enum PrefillTip {

    /** A generic welcome message for the passenger trip */
    PassengerTrip = 'pax',

    /** An explanation of the click and collect service, and directive for the user to fill in any missing inputs */
    ClickAndCollect = 'cnc',
}

/** Might add Login and Signup here in future. Also could combine this with PrefillTip, because all of them are basically requests to show a particular dialog. */
export enum StartupFeature {
    FareEstimate = 'FareEstimate',
}