import React from "react";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";

/** UI elements that displays above the inline credit card input fields in the booking form. */
export const CreditCardFormHeader: React.FC = () => {
    return (
        <div className="card-images">
            {
                CardTypes.map((type: string, index: number) => {
                    return <img src={getContentUrl(ContentURL.images.PaymentType.Selector[type])} key={index}></img>
                })
            }
        </div>
    );
}

const CardTypes = [
    "Visa",
    "Mastercard",
    "AmericanExpress",
    "Discover",
]