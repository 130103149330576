import { Auth0Error } from "auth0-js";
import { AuthType } from "./LoginEntities";

/**
 * Best effort attempt to present a good error message to the user from an authentication failure.
 */
export function GetUserAuthErrorText(error: Auth0Error, authType: AuthType) {

    const loginTypeText = authType == AuthType.Login ? "Login" : "Sign Up";
    const errorText = GetBestErrorDetails(error);

    return `${loginTypeText} failed: ${errorText}`;
}

/**
 * The data that comes back in the Auth0Error object is highly inconsistent.
 * Based on observed data from logs, we handle some well known cases directly.
 */
function GetBestErrorDetails(error: auth0.Auth0Error): string {

    // fairly normal: wrong password
    if (error.code == "access_denied") return "wrong email or password.";

    // clock skew. Rare but we get a few per day
    if ((error.error == "invalid_token") && error.errorDescription && (error.errorDescription.indexOf("(iat)") != -1)) {

        return "your device's clock appears to be skewed. Adjust the time then retry.";
    }

    // fallback
    return error.errorDescription ?? error.error_description ?? error.description ?? error.name ?? error.original?.response?.body?.description ?? "(unknown error)";
}