import { FeatureInfo } from "../../Services/FeaturesContracts";
import { PreviewFeatureId } from "../Features/FeatureEntities";
import { FeatureId } from "./FeaturesEntities";

/** 
 * There are two types of features managed here:
 * 1) What's New items - notifying the user about a newly implemented feature
 * 2) Preview features - dynamic, server-managed features the user can opt in to.
 */
export interface FeaturesState {

    /** The feature IDs that are not acknowledged by the user */
    NewFeatureList: FeatureId[];

    /** The feature IDs that are acknowledged by the user */
    AcknowledgedFeatureList: FeatureId[];

    /** Hold the visibility status of the feature list  */
    IsFeatureListDisplayed: boolean;

    /** 
     *  The set of dynamic (server managed) features.
     *  Note: this only includes features that are understood by the client, i.e. the ID exists in our PreviewFeatureId enum.
     */
    PreviewFeatures: FeatureInfo[];

    /** Preview features that are currently enabled for this user, keyed by ID. */
    EnabledPreviews: Partial<Record<PreviewFeatureId, true>>;

    /** The user has opted in the test Azure B2C functionality by visiting a private URL  */
    AzureB2CPrivateUserOptIn: boolean;
}

export const FeaturesInitialState: FeaturesState = {
    NewFeatureList: [],
    AcknowledgedFeatureList: [],
    IsFeatureListDisplayed: false,
    PreviewFeatures: [],
    EnabledPreviews: {},
    AzureB2CPrivateUserOptIn: false
};