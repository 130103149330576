import { ApiGeoPoint } from "./BookingEntities";

/** Details of a vehicle. Mostly UI focused data. */
export interface Vehicle {

    /** Vehicle ID from GlobalBooker. This is used to map vehicle types between brand and GB. */
    GlobalBookerVehicleId: number;

    /** Display name of the vehicle. */
    Name: string;

    /** Long description of the vehicle. */
    Description: string;

    /** Path to the thumbnail image displayed in the vehicle selector. Basically everything goes after the CDN URL. */
    ThumbnailImagePath: string;

    /** Path to the full image displayed in the booking tracking card. */
    LargeImagePath: string;

    /** Whether this vehicle should be displayed in the default vehicle list. i.e.: the list of vehicles in the vehicle selector. */
    IsInDefaultList: boolean;

    /** Maximum no. of seats in this vehicle. Important in bigger vehicles like Maxi Taxi or Van. */
    MaxNoOfSeats: number;

    /** A short description of the vehicle displayed along with the name in smaller spaces. */
    ShortDescription: string;

    /** Whether this vehicle is the default selected vehicle (i.e.: before the user selects any vehicle) for this brand. */
    IsPrimaryForBrand: boolean;

    /** If the vehicle allowed to be paid with any TSS (Taxi Subsidy Scheme) vouchers. */
    CanUseWithSubsidyVouchers: boolean;
}

/** Response type of CheckCabServiceAvailability from MakeBookingController */
export interface ServiceAvailability {

    /** Indicates if the location is serviced. */
    IsServiced: boolean;

    /** Vehicle types that are serviced at the current service request location. */
    ServicedVehicleTypes: number[] | null;

    /** If the area is not serviced, this will be the message indicating any course of action to be performed, from GB. */
    ServiceFailureMessage: string;

    /** IANA Timezone ID, e.g. "Australia/Sydney". */
    TimeZoneId: string;
}

/** Create booking request payload for the CreateBooking method in MakeBookingController in Booking MGMT. */
export interface CreateBookingRequest {

    /** Main contact person for the booking. To be used by the contact center. */
    ContactDetails: ContactDetails;

    /** Pickup location of the booking. */
    PickupLocation: BookingLocation;

    /** Last dropoff location. Can be null. */
    DropoffLocation: BookingLocation | null;

    /** Timing data of the booking. */
    BookingTime: BookingTiming;

    /** Account details of the booking. Null for non-account bookings. */
    AccountDetails: BookingAccount | null;

    /** Vehicle type requested. e.g: Silver service, Limousine, MaxiTaxi etc. */
    VehicleType: number;

    /** User specified payment method. e.g.: Card, cash, account etc. */
    PaymentMethod: BookingPaymentMethod;
}

/** Booking contact details */
export interface ContactDetails {

    /** Nullable identifier of the contact person. */
    Identifier: string | null;

    Name: string;
    PhoneNumber: string;
}

/** Booking location (pickup, dropoff) details. */
export interface BookingLocation {
    /** Contact details for the location. e.g.: Driver would call to this contact based on the booking status. */
    Contact: ContactDetails;

    /** Address of the location. */
    Address: AddressV2;

    /** Additional driver notes. */
    DriverInstructions: string | null;
}

/** Details of AddressV2 which are parsed from google.maps.places.PlaceResult */
export interface AddressV2Details {
    /** Place name if the address has a known place name. e.g: A2B Australia (pvt) ltd. */
    Place: string | null;

    /** Street number of the address. */
    Number: string | null;

    /** Unit number. e.g.: U2, Unit 2 */
    Unit: string | null;

    /** Street name. e.g: Riley Street. */
    StreetName: string | null;

    /** Name of the Suburb. */
    Suburb: string | null;

    /** State code. e.g.: VIC, NSW */
    State: string | null;

    /** Country name. */
    Country: string | null;

    /** Post code for the address if a post code is available. e.g.: 2010 */
    PostCode: string | null;
}

/** Details of an address of a booking. */
export interface AddressV2 extends AddressV2Details {

    /** 
     *  Full text address for display purposes. 
     *  TODO: review why this is null. I don't understand the scenario. 
     */
    FullTextAddress: string | null;

    /** Lat/long values for the address. */
    GeoLocation: ApiGeoPoint;

    /** 
     *  Identifier of this address from the Google Maps API.
     *  CAREFUL: not part of the service contract defined in MGMT, but used in our internal address representation during the pre-booking phase in the UI.
     *  TODO: separate these cases. We want to enrich the UI data in future (e.g. favourites); it makes no sense to put this stuff in the data contract. 
     */
    GoogleMapsPlaceId: string;
}

/** The requested time for a booking. */
export interface BookingTiming {

    /** True for "now" bookings. */
    IsImmediate: boolean;

    /** ISO 8601 formatted date and time. null for "now" bookings. */
    RequestedDate: string | null;
}

/** Account details for the booking if the booking is an account booking. */
export interface BookingAccount {
    OrderNumber: string | null;
    AccountNumber: string;
}

/** Predefined payment methods allowed by the Global Booker. */
export enum BookingPaymentMethod {
    Cash = 1,
    Account = 2,
    CreditCard = 3,
    AccountPassThrough = 4
}

/** Response type of the CreateBookingV2 request. */
export interface CreateBookingResponse {
    BookingIdentifier: string;

    /** Read or Write access code. This value is private to the owner of the booking and is used to prove ownership in the guest scenario. */
    WriteAccessCode: string;
}