import { Button, Typography } from "@mui/material";
import React from "react";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import "./PayDriverDirectInfo.scss";
import { Dispatch } from "../Dispatch";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { CredentialPopupTrigger } from "../UILogicControl/UILogicControlEntities";

/** Provide information about the things the user will miss out if they choose to pay the driver directly and give options to either sign up and pay the driver directly or pay online as a guest (with a wallet payment such as Apple Pay or Google Pay) */
export const PayDriverDirectInfo: React.FC = () => {

	return (
		<div className="pay-driver-direct-info-main-panel">
			<Typography variant="h4" color="primary" className="pay-driver-direct-title">When you pay the driver directly,<br></br> you'll miss out on</Typography>
			<div className="pay-driver-direct-info-section">
				{
					InfoItems.map((item, index) => (
						<div className="pay-driver-direct-info-list-item" key={index}>
							<div className="image-container">
								<img src={getContentUrl(ContentURL.images.PayDriverDirect[item.ImageName])}></img>
							</div>
							<span>{item.ItemText}</span>
						</div>
					))
				}
			</div>
			<div className="pay-driver-direct-footer">
				<div className="pay-driver-direct-buttons-section">
					<Button variant="contained" color="primary" onClick={OnGoBackButtonClicked}>Go back & pay online</Button>
					<Button variant="outlined" color="primary" onClick={OnPayDriverDirectButtonClicked}>Pay the driver directly (sign up)</Button>
				</div>
				<div className="pay-driver-direct-info-disclaimer">Sign up is required for Taxi Subsidy Scheme card holders. TSS card must be presented at the start of the trip.</div>
			</div>			
		</div>
	)
}

interface InfoItem {
	ImageName: string;
	ItemText: string;
}

const InfoItems: InfoItem[] = [
	{ ImageName: "Lock", ItemText: "Fixed price" },
	{ ImageName: "SecurePayment", ItemText: "Secure payment" },
    { ImageName: "FasterExit", ItemText: "Faster cab exit" },
]

function OnGoBackButtonClicked() {
	Dispatch.Dialog.CloseTopDialog();
}

function OnPayDriverDirectButtonClicked() {
	new CredentialsController().SignUp(CredentialPopupTrigger.FromPayDriverDirectLink);
}