/** 
 * If an API call returned any type of error (server error/http error etc), client will get following object as result.
 */
export interface ErrorResult {

    errorMessage: string;
    isTimeout?: boolean;

    /** One of the values defined in WellKnownErrors, if this has been recognised as one. */
    WellKnownError?: FullErrorId | null;
}

/** System ID + Error ID from an API error expressed using the Ultimate Return Value. */
export interface FullErrorId {

    /** The A2B system which generated this error. */
    SystemId: SystemIds;

    /** The ID of the error in that system. Usually defined in a file ErrorIds.cs */
    ErrorId: number;
}

/** A2B System IDs, as per the service register https://cabcharge.atlassian.net/jira/software/c/projects/SYS/boards/64
 * These System IDs are included in Ultimate Return Value errors.
 */
export enum SystemIds {

    /** Booking Management API (MGMT) */
    ManagementAPI = 170,

    /** 13cabs Booking API */
    BookingAPI = 143
}