import React from "react";
import "./BookingAddressGroup.scss";
import BookingAddressComponent from "../PerLocation/BookingAddressComponent";
import { AddDestinationButton } from "../../AddressPicker/Components/AddDestinationButton";
import { DraggableAddressesContainer } from "../../AddressPicker/Components/DraggableAddressesContainer";
import { useAppState } from "../../../Redux/ReduxHooks";

/**
 * Wrapper component of grouping of pickup & dropoff
 */
const BookingAddressGroup: React.FC = () => {

    const locations = useAppState(state => state.booking.Locations);
   
    return(
        <>
            <BookingAddressComponent IsPickup={true} AddressIndex={0} />
            {locations.length == 2 && <BookingAddressComponent IsPickup={false} AddressIndex={1} /> /* When there are only two locations, the dropoff should not be able to be dragged. Hence, placing it outside the draggable container. */ }
            <DraggableAddressesContainer />
            <AddDestinationButton />
        </> 
    )
}
export default BookingAddressGroup;