import { GetOverallBookingContact } from "../Booking/CreateBookingCommon";
import { IsValidMobileNumber } from "../Utils/PhoneValidation";
import { ContactNumberKind } from "./VerificationEntities";

/**
 * Describe the type of the contact phone number used in the booking just made.
 * Supports the messaging from the Booking Confirmation screen.
 */
export function DetermineContactPhoneKind(): ContactNumberKind {

    const contactNumber: string = GetOverallBookingContact().PhoneNumber;

    if (IsValidMobileNumber(contactNumber)) {
        return ContactNumberKind.Mobile;
    }

    return ContactNumberKind.Landline;
}