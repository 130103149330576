/** 
 * This enum is used to stand for the status of a task for user profile validation
 */
export enum TaskStatus {
    Completed = 'Task completed successfully',
    Todo = 'Not start yet',
    InProgress = 'In progress'
}

/** 
 * This enum is the full list of names of tasks for user profile validation
 */
export enum TaskKind {
    NoValidContactNumber = 'No contact number or contact number is invalid',
    MobileVerification = 'Mobile verification',
    TncAcceptance = 'Terms and conditions acceptance'
}

/** A table of tasks indexed by the task name. */
export type TaskLookUpType = Record<TaskKind, TaskStatus>;

/** Default value for UserProfileValidation.TaskLookUpTable */
export const DefaultTaskLookUpTable: TaskLookUpType = { [TaskKind.NoValidContactNumber]: TaskStatus.Todo, [TaskKind.MobileVerification]: TaskStatus.Todo, [TaskKind.TncAcceptance]: TaskStatus.Todo };