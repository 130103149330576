import { AppConfig } from "./Entities/AppConfig";
import { ConfigValues } from "./Entities/ConfigValues";
import { FeatureFlags } from "./FeatureFlags";
import { AssembleMyConfig } from "./Server/AssembleConfig";
import { Brand } from "./Entities/Brand";
import { DeviceKind } from "./Entities/DeviceKind";
import { ClientInfo } from "./Entities/ClientInfo";
import { RouteUrls } from "../RouteUrls";

/** The global variable (on window) where the server will put the config settings. */
const ConfigGlobalPropertyNameOnWindow = "MyConfigValues";

/**
 *  On the client side, populated by HydrateConfig() at the top of client.tsx.
 *  On the server side, populated by GetValues() --> EnsureConfigExists() -->  AssembleMyConfig().
 */
let Config: AppConfig | null = null;

/**
 * On the client side, populated by HydrateConfig() at the top of client.tsx.
 * On the server side, not supported.
 */
let Device: DeviceKind = DeviceKind.Unknown;

/** 
 *  Public accessor for environment-specific variables.
 */
export function GetValues(): ConfigValues {

    EnsureConfigExists();
    return Config!.Values;
}

/** Get the brand (theme) used by the current configuration */
export function GetMyBrand(): Brand {

    EnsureConfigExists();
    return Config!.Brand;
}

/** 
 *  Get the device type (phone / tablet / desktop).
 *  This method is only supported on the client.
 *  It relies on the value having been provided by the server. 
 */
export function GetMyDevice(): DeviceKind {
    return Device;
}

/** Get the title of the Website based on URL visited */
export function GetMyWebsiteTitle(values: ConfigValues, requestUrl: string): string {
    if (requestUrl === RouteUrls.ContactUs)
    {
        return `${values.BrandName} contact us`;
    }
    else if (requestUrl === RouteUrls.FareEstimate)
    {
        return `Fare Estimate Calculator - ${values.BrandName}`;
    }
    
    return `Book a taxi online with ${values.BrandName}`;
}

/**
 * Get the HTML fragment that will populate the config settings into a global variable MyConfig.
 * The server will call this to allow the configs to be unpacked at the client.
 */
export function GetConfigDefinitionHtmlFragment(device: DeviceKind) {

    EnsureConfigExists();

    const info: ClientInfo = {
        Config: Config!,
        DeviceKind: device,
    };

    const configText = JSON.stringify(info);

    return `<script>window.${ConfigGlobalPropertyNameOnWindow} = ${configText} </script>`;
}

/** 
 *  Loads configuration from the global value (on the window object) generated at the server. 
 *  The client will run this to pick up the values set by the server.
 */
export function HydrateConfig() {

    const info: ClientInfo = window[ConfigGlobalPropertyNameOnWindow];

    ApplyConfig(info.Config);
    Device = info.DeviceKind;
}

/** This should only do work on the server side. The client relies on HydrateConfig to populate the value. */
function EnsureConfigExists() {

    if (Config == null) {
        const config = AssembleMyConfig();
        ApplyConfig(config);
    }
}

/**
 * Loads configuration from the specified data.
 */
function ApplyConfig(config: AppConfig) {

    // store config object for permanent use
    Config = config;

    // push into feature flags
    // TODO: change feature flags to not be an exported const object so we don't have to do this!
    for (const value in config.Features) {
        FeatureFlags[value] = config.Features[value];
    }
}