
import { Dispatch } from "../../Dispatch";
import { DialogKind } from '../../Dialog/DialogEntities';
import { CredentialPopupTrigger } from "../../UILogicControl/UILogicControlEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import Auth from "../AuthImpl";
import { LoginUiMode } from "../AuthEntities";
import { ErrorMessagePlacementKind } from "./LoginEntities";
import { WellKnownMessageKind } from "../../Utils/ErrorMessages";
import { VerificationTrigger } from '../../Verification/VerificationEntities';
import appstore from "../../../appStore";

export default class CredentialsController {
    /**
     * This function is to open the log-in popup, it will open either the new custom interface or Auth0 Lock depends on feature flag.
     */
    DoLogin(): void{
        if (FeatureFlags.CustomAuth0Interface) {
            this.LogIn(CredentialPopupTrigger.FromMenu);
        }
        else {
            new Auth().showAuth0LockScreen(LoginUiMode.Login);
        }
    }

    /**
     * This function is to open the log-in popup, it will open either the new custom interface or Auth0 Lock depends on feature flag.
     */
    DoSignup(): void{
        if (FeatureFlags.CustomAuth0Interface) {
            new CredentialsController().SignUp(CredentialPopupTrigger.FromMenu);
        }
        else {
            new Auth().showAuth0LockScreen(LoginUiMode.Register);
        }
    }

    /**
     * This is to open the new Auth0 customised login popup
     */ 
    LogIn(credentialPopupTriggerSource: CredentialPopupTrigger): void { 
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.UILogicControl.ChangeCredentialsPopupTriggerSource(credentialPopupTriggerSource);
        Dispatch.Dialog.ShowDialog(DialogKind.LogIn);
    }

    /**
     * This is to open the new Auth0 customised signup popup
     */ 
    SignUp(credentialPopupTriggerSource: CredentialPopupTrigger): void { 
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.UILogicControl.ChangeCredentialsPopupTriggerSource(credentialPopupTriggerSource);

        if (credentialPopupTriggerSource === CredentialPopupTrigger.FromMenu) Dispatch.Dialog.ShowDialog(DialogKind.SignUp);
        else if (credentialPopupTriggerSource === CredentialPopupTrigger.FromPayDriverDirectLink) Dispatch.Dialog.ShowDialog(DialogKind.SignupToPayDriverDirect);
        else Dispatch.Dialog.ShowDialog(DialogKind.SignUpToAddCard);
        
    }

    /**
     * This is to open the new Auth0 customised forgot-password popup
     */ 
    ForgotPassword(credentialPopupTriggerSource: CredentialPopupTrigger): void { 
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.UILogicControl.ChangeCredentialsPopupTriggerSource(credentialPopupTriggerSource);
        Dispatch.Dialog.ShowDialog(DialogKind.ForgotPassword);
    }

    /**
     * Close new custom popups for Auth0
     */ 
    ClosePopup(): void { 
        Dispatch.Verification.HideLoaderInContactDetails();
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.Dialog.CleanSimpleErrorMessage();
        Dispatch.Auth.ClearAuth0SuccessMessage();
        Dispatch.UILogicControl.EndCredentialsApiRequest();
        this.ResetAuth0ErrorMessage();
    }

    /**
     * Reset Auth0 error message
     */ 
    ResetAuth0ErrorMessage(): void { 
        Dispatch.Auth.ClearLoginErrorMessage();
        Dispatch.Auth.PlaceErrorMessage(ErrorMessagePlacementKind.Popup);
    }

    /**
     * This is for some other components do wrong, such as verification & contact details, and want to go back up sign-up popup.
     */ 
    GoBackToSignupPopupFromError(errorMessage?: string): void {
        /**
         * Close potentially opened Verification or Contact details popup
         */
        const topmostDialog = appstore.getState().dialog.topmostDialog;
        if (topmostDialog === DialogKind.Verification || topmostDialog === DialogKind.ContactDetails) {
            Dispatch.Dialog.CloseTopDialog();
        }

        const error = !!errorMessage ? errorMessage : WellKnownMessageKind.GeneralFailure.SolutionText
        Dispatch.Auth.LoginErrorMessage(error);
        Dispatch.Auth.PlaceErrorMessage(ErrorMessagePlacementKind.Popup);
        Dispatch.Verification.WasTriggeredBy(VerificationTrigger.Booking);

        new CredentialsController().DoSignup();
    }
}

