import React, { Component } from 'react';
import './transparent-black-background.scss';

class TransparentBlackBg extends Component {
    constructor(props) {
        super(props)
        this.onClickAction = this.onClickAction.bind(this);  
    }
    onClickAction() { 
        if(this.props.onClickAction instanceof Function) { this.props.onClickAction(); }  
    }

    render() {
        return(
            <div className="transparent-black-bg-panel" ref = {TransparentBlackBgPanel => this.TransparentBlackBgPanel = TransparentBlackBgPanel} onClick={this.onClickAction}></div>
        );
    }
}

export default TransparentBlackBg;