import React, { FC, useEffect } from "react";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { MobileBookingPage } from "./Mobile/MobileBookingPage";
import BookingPage from "./BookingPage";
import { ApplicationState } from "../../appState";
import { connect } from "react-redux";
import { PreFillDataFromUrl } from "../ClickAndCollect/PreFillDataFromUrl";

/**
 * Renders the booking component.
 * It decides whether to render the Desktop or Mobile layout.
 */
const BookingLayoutChooserCore: FC<MyStoreProps> = (props) => {

    /**
     * Read the querystring part from the url, inorder to pre-populate the data on page load
     */
    useEffect(() => {

        PreFillDataFromUrl();

    }, [props.LayoutMode]);

    if (props.LayoutMode === UILayoutMode.Mobile) {
        return <MobileBookingPage />;
    }
    else {
        return <BookingPage />;
    }
};

/** Props from the store. */
interface MyStoreProps {

    /** Mobile (compact) or Desktop (normal) layout. */
    LayoutMode: UILayoutMode;
}

function GetMyPropsFromStore(state: ApplicationState): MyStoreProps {
    return {
        LayoutMode: state.uiLogicControl.LayoutMode,
    };
}

export const BookingLayoutChooser = connect(GetMyPropsFromStore)(BookingLayoutChooserCore);