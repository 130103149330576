import React, { useEffect } from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { Dispatch } from "../Dispatch";
import braintree from "braintree-web";
import { ApplePay } from "../ApplePay/ApplePay";
import { AddApplePayFunnel } from "../ApplePay/AddApplePayFunnel";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { GetOrMakeBraintreeClient } from "../Payment/GetOrMakeBraintreeClient";

/**
 * Manages the loading of the Apple SDK. 
 * Instead of loading at startup, we wait until the user selects Apple Pay option from the payment method dropdown.
 * This component doesn't render anything; it just receives and sends events.
 */
export const ApplePayLoadingWatcher: React.FC = () => {

    const loadingState = useAppState(i => i.ApplePay.LoadingStatus);
    const applePaySelected = useAppState(i => i.booking.PaymentOption && i.booking.PaymentOption.Type == "ApplePay");
    const payPalLoadingState = useAppState(i => i.PayPal.LoadingStatus);
    const googlePayLoadingState = useAppState(i => i.GooglePay.LoadingStatus);

    // pre-existing states that should trigger a load
    useEffect(() => {

        if (applePaySelected) {
            Dispatch.ApplePay.ReadyToLoad();
        }

    }, [applePaySelected]);

    // once only load
    useEffect(() => {

        // PayPal loading is still in progress
        if (payPalLoadingState === SdkLoadingState.InProgress) return;

        // GooglePay loading is still in progress. Waiting for Google and PayPal SDK loading because only one Braintree client creation request can be sent at any time.
        if (googlePayLoadingState === SdkLoadingState.InProgress) return;

        // UI flow has advanced far enough
        if (loadingState === SdkLoadingState.RequestedNotStarted) {
            StartLoadingApplePaySdk();
        }
    }, [loadingState, payPalLoadingState, googlePayLoadingState]);

    return null;
}

/**
 * Intended to run only once.
 * This method just manages the redux state changes.
 */
async function StartLoadingApplePaySdk() {

    Dispatch.ApplePay.LoadStarting();

    const isSuccess = await TryLoadUpToApplePaySdk();

    if (isSuccess) {
        Dispatch.ApplePay.LoadSucceeded();
    }
    else {
        Dispatch.ApplePay.LoadFailed();
    }
}

/**
 * Loads the ApplePay SDK. There are two parts, each of which could fail:
 * 1) Braintree SDK (this includes loading of Client Token from API)
 * 2) ApplePay SDK.
 */
async function TryLoadUpToApplePaySdk(): Promise<boolean> {

    //1) Braintree SDK
    const braintreeClient = await GetOrMakeBraintreeClient();

    if (!braintreeClient) {
        return false;
    }

    // 2) ApplePay SDK
    let applePayClient: braintree.ApplePay;

    try {
        applePayClient = await braintree.applePay.create({
            client: braintreeClient
        });
    }
    catch (error) {
        AddApplePayFunnel.Exception("ApplePay Client", error, false);
        return false;
    }

    ApplePay.SdkIsReady(braintreeClient, applePayClient);
    return true;
}