import React, { Component } from 'react';
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import './BookingFormLandingPage.scss';
import { Dispatch } from '../../modules/Dispatch';

export default class BookingFormLandingPage extends Component<{}> { 
    componentWillUnmount(){
        Dispatch.UILogicControl.FinishLandingPage();
    }

    render() {
        return(
            <div className="landing-page-container" >
                <img src={getContentUrl(ContentURL.images.LandingPage.LadyImage)} alt="Landing page image"/>
                <p>
                    <span>Need a quick ride?</span>
                    <span>We’ve got more than</span>
                    <span>10,000 cabs nationally</span>
                    <span>on the road to get you</span>
                    <span>there.</span>
                </p>
            </div>
        );
    }
}