import { SdkLoadingState } from "../PayPal/PayPalState";

/** 
 * Redux store GooglePay state slice.
 * Manages the background loading of the SDK.
 */
export interface GooglePayState {

    /** The progress or result of loading the GooglePay SDK. */
    LoadingStatus: SdkLoadingState;

    /** Whether the current device and browser supports Google Pay. */
    IsGooglePaySupported: boolean
}

export const GooglePayInitialState: GooglePayState = {

    LoadingStatus: SdkLoadingState.NotRequested,
    IsGooglePaySupported: false
}