import React from 'react';
import "./Header.scss";
import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';

class LogoHeader extends React.Component<{}, {}> {
    render() {
        return (
            <header>
                <div className="logo-header-content">
                    <img src={GetBrandedUrl(BrandedImage.Logo)} alt="logo" className="logo"/>                 
                </div>
            </header>
        );
    }
}

export default LogoHeader;