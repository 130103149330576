import { ApiFailureTestingState } from "./FailureTestingState";
import appstore from "../../appStore";

/** This will make more sense if we get extra actions later - they will go into a type union */
export type ApiFailureTestingAction = ApiFailureTestingConfigChangeAction;

/** Action Creator and dispatcher in one */
export class ApiFailureTestingDispatcher {

    public ConfigChange(newState: ApiFailureTestingState): void {
        appstore.dispatch({
            type: ApiFailureTestingActionType.ConfigChange,
            newState,
        });
    }
}

/** The name is getting a bit silly in length but I can't figure out how to stop it. */
export interface ApiFailureTestingConfigChangeAction {
    type: ApiFailureTestingActionType.ConfigChange;
    newState: ApiFailureTestingState;
}

/** At the moment there's just one action that changes the whole state. */
export enum ApiFailureTestingActionType {
    ConfigChange = 'API Failure Testing: Config Change'
}