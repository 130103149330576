import React from "react";
import { connect } from 'react-redux';
import { ApiFailureTestingState, ApiFailureMode } from "../../modules/ApiFailureTesting/FailureTestingState";
import { ApplicationState } from "../../appState";
import { LoadMyBookings } from "../../modules/Booking/BookingLoaders";
import { Config } from "../../Config/Config";
import { Dispatch } from "../../modules/Dispatch";
import { GetValues } from "../../Config/MyAppConfig";

/** Connect the whole API Failure Testing state into my props */
interface StoreProps {
    Settings: ApiFailureTestingState
}

/** A UI Component to configure the API Failure Testing settings. */
class ApiFailureSettingsUICore extends React.Component<StoreProps> {

    MasterToggle = () => {
        const existing = this.props.Settings;

        Dispatch.ApiFailureTesting.ConfigChange({
            ...existing,
            IsEnabled: !existing.IsEnabled,
        });

        /**To bring the super user bookings for testing purpose when the team click on Testing Checkbox in BookingDiag and clear the bookings from Redux/Local Storage if unchecked */
        if (GetValues().IsTestModeSupported) {
            if (existing.IsEnabled) { 
                Dispatch.MyBookings.Clear();
            }
            else {                       
                LoadMyBookings(Config.TestBookingStatusIds);
            }
        }
    }

    DelayToggle = () => {
        const existing = this.props.Settings;

        Dispatch.ApiFailureTesting.ConfigChange({
            ...existing,
            IsDelayEnabled: !existing.IsDelayEnabled,
        });
    }

    DelayMillisToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        Dispatch.ApiFailureTesting.ConfigChange({
            ...this.props.Settings,
            DelayMilliseconds: event.target.valueAsNumber,
        });
    }

    FailureModeToggle = () => {
        const existing = this.props.Settings;

        Dispatch.ApiFailureTesting.ConfigChange({
            ...existing,
            IsFailureModeEnabled: !existing.IsFailureModeEnabled,
        });
    }

    FailureModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as ApiFailureMode;

        Dispatch.ApiFailureTesting.ConfigChange({
            ...this.props.Settings,
            FailureMode: value,
        });
    }

    render() {
        return (
            <div>
                <input type="checkbox" defaultChecked={this.props.Settings.IsEnabled} onChange={this.MasterToggle} /> Testing
                {this.props.Settings.IsEnabled && (
                    <div>
                        <input type="checkbox" defaultChecked={this.props.Settings.IsDelayEnabled} onChange={this.DelayToggle} /> Lag
                        <input type="number" value={this.props.Settings.DelayMilliseconds} min="1" max="10000" onChange={this.DelayMillisToggle} /> <br />
                        <input type="checkbox" defaultChecked={this.props.Settings.IsFailureModeEnabled} onChange={this.FailureModeToggle} /> Force Error <br />

                        <div>
                            <input type="radio" name="failuremode" value={ApiFailureMode.Timeout} defaultChecked={this.props.Settings.FailureMode === ApiFailureMode.Timeout} onChange={this.FailureModeChange} />
                            Timeout
                        </div>

                        <div>
                            <input type="radio" name="failuremode" value={ApiFailureMode.UnableToConnect} defaultChecked={this.props.Settings.FailureMode === ApiFailureMode.UnableToConnect} onChange={this.FailureModeChange} />
                            Connect
                        </div>

                        <div>
                            <input type="radio" name="failuremode" value={ApiFailureMode.BusinessRule} defaultChecked={this.props.Settings.FailureMode === ApiFailureMode.BusinessRule} onChange={this.FailureModeChange} />
                            Business Rule
                        </div>
                    </div>
                    )
                }
            </div>
            );
    }
}

/** The mapping from the redux store to my props. */
function MapStoreToProps(storeState: ApplicationState): StoreProps {
    return {
        Settings: storeState.apiFailureTesting,        
    };
}

/** Hook up to the Redux store */
export const ApiFailureSettingsUI = connect(MapStoreToProps)(ApiFailureSettingsUICore);