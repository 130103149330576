import { LocationData, KnownLocation } from "./Entities";
import { LocationActionKind, LocationAction } from "./Actions";
import appstore from "../../appStore";
import { ConsiderSatssInvalidation } from "../Payment/PaymentHandler";

/** Helper class to dispatch LocationAction actions. It's like an action creator except that it also dispatches the actions. */
export class LocationDispatcher {

    public ipGeolocationComplete(ipLocation: LocationData): void {
        dispatch({
            type: LocationActionKind.IPGeolocationComplete,
            ipLocation,
        });

        ConsiderSatssInvalidation();
    }

    public userSelectsLocation(userLocation: KnownLocation): void {
        dispatch({
            type: LocationActionKind.UserSelectsLocation,
            userLocation,
        });

        ConsiderSatssInvalidation();
    }
}

/** This function is deliberately put outside the class to avoid issues with "this"
 *  Its purpose is to type-check the actions as LocationAction.
 */
function dispatch(action: LocationAction) {
    appstore.dispatch(action);
}