import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import './ErrorMessaging.scss';
import { WellKnownErrorMessage } from '../../modules/Dialog/DialogEntities';
import ErrorMessagingProps from './ErrorMessagingProps';
import { Dispatch } from '../../modules/Dispatch';
import { WellKnownMessageKind } from "../../modules/Utils/ErrorMessages";
import CredentialsController from '../../modules/Authentication/Login/CredentialsController';
import { DeclineProfileValidationProcess, IsUnderProfileValidationMode } from "../../modules/LoginValidation/LoginValidationHelper";

interface PropsFromStore {
    wellKnownErrorMessage: WellKnownErrorMessage;
}

class SimpleErrorMessaging extends Component<PropsFromStore> {
    onOKClicked() {
        Dispatch.Dialog.CloseTopDialog();

        /**
         * Automatically display Auth0 log in pop-up
         */
        if(this.props.wellKnownErrorMessage === WellKnownMessageKind.Auth0RenewFailure || this.props.wellKnownErrorMessage === WellKnownMessageKind.LoginFailure) {
            new CredentialsController().DoLogin();
        }

        Dispatch.Dialog.CleanSimpleErrorMessage();

        if (IsUnderProfileValidationMode()) { DeclineProfileValidationProcess(); }
    }

    /**
     * This OKButton function:
     *     1) Generate a button named 'Ok' in conponent SimpleErrorMessaging;
     *     2) It's capability is to close off component SimpleErrorMessaging;
     *     3) It will be injected into component ErrorMessagingBase.
     */
    private OKButton(): JSX.Element {
        return(<button className="error-message-popup-btn" type="button" onClick={() => this.onOKClicked()}>OK</button>);
    }

    render() {
        return(
            <ErrorMessagingProps
                ProblemText = {this.props.wellKnownErrorMessage.ProblemText}
                SolutionText = {this.props.wellKnownErrorMessage.SolutionText}
                Button = {this.OKButton()}
            />
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        /**
         * This ! assertion is guaranteed by ./ErrorMessagingHelper.ShowDialogSimpleErrorMessage(WellKnownErrorMessage)
         * The sequential dispatches guarantees that wellKnownErrorMessage has value when this popup mounts
         */
        wellKnownErrorMessage: state.dialog.wellKnownErrorMessage!
    };
}

export default connect(mapStateToProps)(SimpleErrorMessaging);