import { VehicleTypeV2 } from "../modules/Condition/Redux/ConditionEntities";
import { ApiGeoPoint, BookingDataOwnership, BookingAuthority } from "./BookingEntities";
import { BookingLocation, ContactDetails } from "./MakeBookingContracts";

/** Details of a booking. This is the response type of track booking API call. */
export interface BookingInfoV2 {

    /** Internal ID from the GB inter system. This ID is used for all the API operations related to the booking. */
    InternalBookingId: string;

    /** Booking reference number provided to the user. This will be displayed in the UI and can be used by the user for any booking related inquiry. */
    UserBookingReference: string;

    /** Requested pickup time. ISO 8601, normally expressed in UTC offset. */
    RequestedDateTime: string;

    /**
     * Requested pickup time, expressed in the time zone local to the booking.
     * Formatted as an ISO 8601 date including a UTC offset for the local time zone.
     * Could very rarely be null if the system is unable to determine the local time zone.
     */
    LocalRequestedTime: string | null;

    /** Actual pickedup time (i.e.: Meter ON time) */
    PickedUpDateTime: string | null;

    /** Trip completed time (i.e.: Meter OFF time) */
    CompletedDateTime: string | null;

    /** Contact details for the booking (Passenger details are inside Pickup). */
    BookingContact: ContactDetails;

    /** Pickup details. Location, Full text address, contact details etc. */
    Pickup: BookingLocation;

    /** Dropoff details. Location, Full text address, contact details etc. */
    Dropoff: BookingLocation;

    /** Assigned drivers details. NULL if no driver is assigned yet. */
    Driver: Driver | null;

    /** Vehicle type requested */
    VehicleType: VehicleTypeV2;

    /** Current status of the booking */
    StatusID: BookingStatusV2;

    /** Meta properties of the booking such as access codes and tracking URL */
    MetaData: BookingMetaData;

    /** Car number suitable for display to human.
     * May be null if there is no car defined, e.g. booking is pre-dispatching.*/
    CarNumberPlate: string | null;
};

/** Details of the Driver who has accepted the booking. */
export interface Driver {
    DriverName: string;
    DriverIdentifier: string;

    /** Whether a photo exists or not with the Driver's profile. This value is used to decide if an API request should be sent to load driver photo for the booking. */
    DriverPhotoExists: boolean;
}

/** Booking status IDs from the Global Booker. These statuses are different from Booking API statuses. */
export enum BookingStatusV2 {
    Pending = 0,
    Dispatch = 1,
    Assigned = 2,
    Accepted = 3,
    PickedUp = 4,
    Complete = 5,
    Cancel = 6,
    NoJob = 7,
    NoCarAvailable = 8
}

/** 
 * Minimal version of booking status of the bookings in V2 API.
 * It only contains the fields that can change over time, like status, car number, driver. 
 * */
export interface LightweightBookingStatusV2 {

    /** Booking identifier in Global Booker. Important to distinguish bookings when there are multiple bookings in a single query. */
    InternalBookingId: string;

    /** Well-known ID for booking status */
    StatusID: BookingStatusV2;

    /** Car number suitable for display to human.
     * May be null if there is no car defined, e.g. booking is pre-dispatching.*/
    CarNumberPlate: string | null;

    /** Details of the Driver who accepted the booking, or null if the Booking does not have a driver associated with it yet. */
    Driver: Driver | null;

    /** Picked up time. i.e.: Meter On time. Null before pick up. */
    PickedUpDateTime: string | null;

    /** Trip completed time. i.e.: Meter Off time. Null before completion of the booking. */
    CompletedDateTime: string | null;
}

/** Response type of the /BookingstatusBulk request. */
export interface BulkBookingStatusUpdateResponse {

    /** UTC time when this request was sent. This time is set and sent from the server. */
    CurrentTimestamp: string;

    /** List of bookings with status updates. */
    BookingStatusUpdates: LightweightBookingStatusV2[]; 
}

/** Request data of the /BookingstatusBulk request. */
export interface BulkBookingStatusUpdateRequest {

    /** When was the last /BookingstatusBulk reqest sent. This value is obtained from the response of the previous request. NULL in the first request. */
    LastRequestedTimestamp: string | null;

    /** List of booking IDs to get status updates. */
    BookingKeys: BookingAuthority[];
}

/** Properties of a booking that are not details of actual booking but useful for various operations. */
interface BookingMetaData {
    /** Internal access code of the booking. This value is used to prove the ownership of the booking while performing various operations.
     * Based on the ownership of the booking, this can be either 'read+write access' or 'read only access' code.
     */
    AccessCode: string;

    /** This code, along with the booking ID, is used to perform readonly actions on the booking. */
    ReadAccessCode: string;

    /** Describes the ownership of the booking in the current context. e.g.: User, Guest, ReadOnlyLink, Test etc */
    DataOwnership: BookingDataOwnership;

    /** Base URL (host + path) to generate tracking link. */
    BookingTrackingBaseUrl: string;
}

/** The GPS location of the vehicle for a booking. */
export interface BookingVehicleLocation {

    /** GB Booking ID */
    InternalBookingId: number;

    /** Lat/Long of the vehicle for this booking. */
    VehicleLocation: ApiGeoPoint;
}

/** Photo of the driver assigned for a booking. */
export interface DriverPhoto {
    /** Image file content as base64 string. */
    Base64FileContent: string;
}