import { ApplePayPayload, GooglePaymentTokenizePayload, PayPalTokenizePayload, ThreeDSecureVerifyPayload } from "braintree-web";
import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { BackingUserDetail, GuestPaymentInitialState, GuestPaymentState } from "./GuestPaymentState";

/** Redux store slice for the GuestPayment data. */
const slice = new ReduxStoreSlice<GuestPaymentState>("GuestPayment", GuestPaymentInitialState);

/** Redux action dispatcher for the GuestPayment store slice. */
export const GuestPaymentDispatch = {

    /** Record the tokenised form of a PayPal payment method while in the middle of PayPal guest setup */
    PayPalTokenised: slice.Action("PayPal Tokenised", PayPalTokenised),

    /** Clear the tokenised PayPal account and backing user auth data to unlink PayPal from a guest user. */
    ClearPayPal: slice.EmptyAction("Clear PayPal", ClearPayPal), 

    /** Record the temporary user account created for a guest payment method. */
    TempUserDetails: slice.Action("Temp User Details", TempUserDetails),

    /** Clear the temporary user account created for a guest payment method. */
    ClearTempUser: slice.EmptyAction("Clear Temp User", ClearTempUser),

    /** Record the tokenised form of an Apple Pay payment method while in the middle of Apple Pay guest setup */
    ApplePayTokenised: slice.Action("Apple Pay Tokenised", ApplePayTokenised),

    /** Clear the tokenised Apple Pay account. */
    ClearApplePay: slice.EmptyAction("Clear Apple Pay", ClearApplePay),

    /** Record the tokenised form of a Google Pay payment method while in the middle of Google Pay guest setup */
    GooglePayTokenised: slice.Action("Google Pay Tokenised", GooglePayTokenised),

    /** Clear the tokenised Google Pay account. */
    ClearGooglePay: slice.EmptyAction("Clear Google Pay", ClearGooglePay),

    /** Records the 3DS verified payload of a tokenised credit card while in the middle of credit card guest setup. */
    CreditCard3DsVerified: slice.Action("Credit Card 3DS Verified", CreditCard3DsVerified),

    /** Clear the 3DS verified payload. */
    ClearCreditCard3Ds: slice.EmptyAction("Clear Credit Card 3DS", ClearCreditCard3Ds),

    /** Record the email address to which the payment receipt need to be sent. */
    EmailForReceipt: slice.Action("Email For Receipt", UpdateEmailForReceipt),

    /** Clear the email address for receipt. */
    ClearEmail: slice.EmptyAction("Clear Email", ClearEmail),

    /** Update the validity status of the inline credit card form. */
    InlineCreditCardFormValidated: slice.Action("Validate Inline Credit Card Form", ValidateInlineCreditCardForm),
};

/** Redux reducer for the GuestPayment store slice. */
export const GuestPaymentReducer = slice.MakeCombinedReducer();

/** Record the tokenised form of a PayPal payment method while in the middle of PayPal guest setup */
function PayPalTokenised(state: GuestPaymentState, paypalData: PayPalTokenizePayload): GuestPaymentState {
    return {
        ...state,
        PayPalTokenised: paypalData,
    };
}

/** Record the temporary user account created for a guest payment method. */
function TempUserDetails(state: GuestPaymentState, userAuth: BackingUserDetail): GuestPaymentState {
    return {
        ...state,
        BackingUser: userAuth,
    };
}

/** Clear the tokenised PayPal account and backing user auth data to unlink PayPal from a guest user. */
function ClearPayPal(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        PayPalTokenised: null,
        BackingUser: null
    }
}

/** Record the tokenised form of an Apple Pay payment method while in the middle of Apple Pay guest setup. */
function ApplePayTokenised(state: GuestPaymentState, applePayData: ApplePayPayload): GuestPaymentState {
    return {
        ...state,
        ApplePayTokenised: applePayData,
    };
}

/** Clear the tokenised Apple Pay account. */
function ClearApplePay(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        ApplePayTokenised: null
    };
}

/** Record the tokenised form of a Google Pay payment method while in the middle of Google Pay guest setup. */
function GooglePayTokenised(state: GuestPaymentState, googlePayData: GooglePaymentTokenizePayload): GuestPaymentState {
    return {
        ...state,
        GooglePayTokenised: googlePayData,
    };
}

/** Clear the tokenised Google Pay account. */
function ClearGooglePay(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        GooglePayTokenised: null
    };
}

/** Records the 3DS verified payload of a tokenised credit card while in the middle of credit card guest setup. */
function CreditCard3DsVerified(state: GuestPaymentState, threeDsPayload: ThreeDSecureVerifyPayload): GuestPaymentState {
    return {
        ...state,
        CreditCard3DsVerified: threeDsPayload
    };
}

/** Clear the 3DS verified payload. */
function ClearCreditCard3Ds(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        CreditCard3DsVerified: null
    };
}

/** Clear the temporary user account created for a guest payment method. */
function ClearTempUser(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        BackingUser: null
    };
}

/** Record the email address to which the payment receipt need to be sent. */
function UpdateEmailForReceipt(state: GuestPaymentState, email: string): GuestPaymentState {
    return {
        ...state,
        EmailForReceipt: email
    };
}

/** Clear the email address for receipt. */
function ClearEmail(state: GuestPaymentState): GuestPaymentState {
    return {
        ...state,
        EmailForReceipt: null
    };
}

/** Update the validity status of the inline credit card form. */
function ValidateInlineCreditCardForm(state: GuestPaymentState, isValid: boolean): GuestPaymentState {
    return {
        ...state,
        IsInlineCrediCardFormValid: isValid
    };
}