import React, { FC } from "react";
import { UILayoutMode } from "../../modules/UILogicControl/UILogicControlEntities";
import { Dispatch } from "../../modules/Dispatch";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { FeatureFlags } from "../../Config/FeatureFlags";
import "./LayoutSelector.scss";

/** Props from the store. */
interface MyStoreProps {

    /** Mobile (compact) or Desktop (normal) layout. */
    LayoutMode: UILayoutMode;
}

/**
 * Displays the current layout mode (desktop vs mobile) and gives a link to toggle.
 */
const LayoutSelectorCore: FC<MyStoreProps> = (props) => {

    // this element only makes sense in a mobile responsive environment
    if (!FeatureFlags.KeepMobileUsers) return null;

    const otherMode = (props.LayoutMode === UILayoutMode.Desktop) ? UILayoutMode.Mobile : UILayoutMode.Desktop;

    return (
        <div className="layout-selector">
            <button onClick={ToggleLayoutMode} className="selector-link">View in {otherMode} Mode</button>
        </div>
    );

    function ToggleLayoutMode() {
        Dispatch.UILogicControl.LayoutMode(otherMode);
    }
};

function GetMyPropsFromStore(state: ApplicationState): MyStoreProps {
    return {
        LayoutMode: state.uiLogicControl.LayoutMode,
    };
}

export const LayoutSelector = connect(GetMyPropsFromStore)(LayoutSelectorCore);