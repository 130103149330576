
/** Creates a new promise but also gives you access to the resolver and rejector methods for it. */
export function MakeDecomposedPromise<T>(): DecomposedPromise<T> {

    // I had troubles with this typing. I want to say this is a (value?: T | PromiseLike<T>) => void.
    // But it is initially null, and TS doesn't understand that the Promise constructor will result in it being initialised, because the executor function will run.
    let resolver: any = null;
    let rejector: any = null;

    const executor = (a: (value: T | PromiseLike<T>) => void, b: (reason?: any) => void) => {
        resolver = a;
        rejector = b;
    }

    // the Promise constructor will immediately call the executor function, which we will use to capture the resolve() and reject() functions
    // we hope.
    var promise = new Promise<T>(executor);

    return {
        Promise: promise,
        Rejector: rejector,
        Resolver: resolver,
    };
}

/** A new promise object together with its resolver and rejector functions */
export interface DecomposedPromise<T> {
    Promise: Promise<T>;
    Resolver: (value?: T | PromiseLike<T>) => void;
    Rejector: (reason?: any) => void;
}