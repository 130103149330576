import { DateTime } from "luxon";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { CustomErrorMessages } from "../Utils/ErrorMessages";

/** The output of CheckFutureBookingTime */
export interface CheckFutureBookingTimeResult {

    /** Overall yes/no. */
    IsValid: boolean,

    /** Error message, if we could think up a good one. */
    ErrorMessage: string | null;
}

/**
 * This is to verify whether the selected future date & time in booking form is valid or not
 */
export function CheckFutureBookingTime(time: DateTime, bookInAdvanceDays: number): CheckFutureBookingTimeResult {

    let ErrorMessage: string | null = null;
    let IsValid = true;

    if (!time.isValid) {
        IsValid = false;
        ErrorMessage = time.invalidReason;
    }
    else if (time < DateTime.now()) {
        IsValid = false;
        ErrorMessage = CustomErrorMessages.PastDateTime;
    }
    else if (time > DateTime.now().plus({ days: bookInAdvanceDays }).endOf('day')) {
        IsValid = false;
        ErrorMessage = `Please select a date that's no more than ${bookInAdvanceDays} days from now`;
    }

    return { IsValid: IsValid, ErrorMessage: ErrorMessage };
}

/** How many days ahead from today the user is allowed to book. */
export function GetBookInAdvanceDays(userProfile?: SimpleUserProfile): number {

    return userProfile ? userProfile.BookingInAdvanceDays : 7;
}