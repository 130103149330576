import React from "react";
import "./BookingCard.scss";
import { BookingStatus } from "../../../Services/BookingEntities";
import { SetProgressBeforeAccepted } from "../Logic/ProgressCalculator";
import { ExtendedEta, BookingInfo } from "../MyBookingEntities";
import { Config } from "../../../Config/Config";
import { ProcessProgressAndEtaDisplay } from "../Logic/ProgressAndETA";

interface BookingProps {
    BookingDetails: BookingInfo;
    Eta?: ExtendedEta;
}

interface ProgressState {
    progressBeforeAccepted: number;
}

/** Component to display the ETA and the progress bar */
class ProgressBarAndETA extends React.Component<BookingProps, ProgressState> {
    
    private progressTimer: any;

    constructor(props: BookingProps) {
        super(props);

        this.state = {
            progressBeforeAccepted: 0
        }

    }

    componentDidMount() {
        var percentageNumber = 0;

        // Calculate the progress in every 10 seconds before accepted
        if(this.props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.Accepted) {
            this.progressTimer = setInterval(() => {
                if(this.props.BookingDetails.TrackingInfo.BookingStatusID >= BookingStatus.Accepted) {
                    this.setState({progressBeforeAccepted: 0});
                    clearInterval(this.progressTimer);
                } else {
                    var progressValue = SetProgressBeforeAccepted(this.props.BookingDetails.TrackingInfo);
                    percentageNumber = progressValue <= Config.MaxProgressBeforeAccepted ? progressValue : Config.MaxProgressBeforeAccepted;
                    this.setState({progressBeforeAccepted: percentageNumber});
                }
                
            }, 2000);
        }
        
    }

    componentWillUnmount() {
        clearInterval(this.progressTimer);
    }   

    render() {

        const { BookingDetails, Eta } = this.props; 

        const etaDetails = ProcessProgressAndEtaDisplay(BookingDetails, Eta);

        const progressBarClass = "progress-bar-inner progress-" + etaDetails.Progress.Colour;

        const progressBarOuterClass = etaDetails.Progress.IsHidden ? "hide-progress" : "progress-bar-outer";

        const etaDisplay = (<div className="eta-display">
            {etaDetails.Eta.PrefixText} <span>{etaDetails.Eta.MainText}</span>
        </div>);

        const progressWidth = etaDetails.Progress.WidthPercent;

        return (
            <React.Fragment>
                <div className="eta-section">
                    <div className={progressBarOuterClass}>
                        <div className={progressBarClass} style={{ width: `${this.state.progressBeforeAccepted || progressWidth}%` }}></div>
                    </div>
                    {etaDisplay}
                </div>
            </React.Fragment>
        );
    }
}

export default ProgressBarAndETA;