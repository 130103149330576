import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { LoginValidationState, InitLoginValidationState } from "./LoginValidationState";
import { TaskKind, TaskStatus } from "./LoginValidationEntities";

const slice = new ReduxStoreSlice<LoginValidationState>("LoginValidation", InitLoginValidationState);

/** Dispatcher for actions in the LoginValidation state slice. */
export const LoginValidationDispatch = {
    StartVerification: slice.Action("Change TaskStatus", StartVerification),
    FinishVerification: slice.EmptyAction("Finish Login Validation", FinishVerification),
    AwaitSubTask: slice.Action("Await SubTask", AwaitSubTask),
    CompleteSubTask: slice.Action("Complete SubTask", CompleteSubTask)
};

/** Reducer for the LoginValidation store slice */
export const LoginValidationReducer = slice.MakeCombinedReducer();

/** 
 * Stands for the start the process of logged-in users validation, also means the timer is set.
 */
function StartVerification(state: LoginValidationState, TimerID: number): LoginValidationState {
    return {
        ...InitLoginValidationState,
        TimerID: TimerID
    }
}

/** Start a subtask and await it to complete */
function AwaitSubTask(state: LoginValidationState, task: TaskKind): LoginValidationState {
    const newTable = { ...state.TaskLookUpTable, [task]: TaskStatus.InProgress};

    return {
        ...state,
        IsAsyncTaskRunning: true,
        TaskLookUpTable: newTable,
    }
}

/** Complete a subtask */
function CompleteSubTask(state: LoginValidationState, task: TaskKind): LoginValidationState {
    const newTable = { ...state.TaskLookUpTable, [task]: TaskStatus.Completed};

    return {
        ...state,
        IsAsyncTaskRunning: false,
        TaskLookUpTable: newTable,
    }
}

/** Reset LoginValidation to be default value */
function FinishVerification(): LoginValidationState {
    return InitLoginValidationState;
}