import React from 'react';
import './MultiTenantSelector.scss';
import { Brand } from "../../../Config/Entities/Brand";
import { SpecifyBrandGetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import CredentialsController from '../../Authentication/Login/CredentialsController';

/**
 * A dialog component which allows users to select which authentication tenant (brand specific) to connect when log in.
 */
export const MultiTenantSelector: React.FC<{}> = () => {
    return (
        <div className="login-multi-tenant-select-panel">
            <AuthTenantOptionButton Brand={Brand.OneThreeCabs} /> 
            <AuthTenantOptionButton Brand={Brand.CabCharge} /> 
        </div>          
    );
}

interface AuthTenantOptionButtonProps {
    Brand: Brand;
}

/**
 * An option button per auth tenant
 */
const AuthTenantOptionButton: React.FC<AuthTenantOptionButtonProps> = (props) => {

    const btnStyle = props.Brand === Brand.CabCharge ? "brand-auth-tenant-option-btn selectable-brand" : "brand-auth-tenant-option-btn";

    return (
        <div className={btnStyle} onClick={() => {SelectBrandTenantLogin(props.Brand)}}>
            <img src={SpecifyBrandGetBrandedUrl(BrandedImage.Logo, props.Brand)}></img>
        </div>
    );
}

/**
 * Open login dialog
 */
function SelectBrandTenantLogin(brand: Brand) {

    if (brand !== Brand.CabCharge) return;

    new CredentialsController().DoLogin();
}
