
/** The environment in which the application is running. */
export enum DeploymentEnvironment {

    LocalDebug = "localdebug",

    Development = "development",

    /** This environment value is only used together with Brand === book360. */
    Preprod = "pre-production",

    Production = "production",
}