import React from "react";
import { connect } from "react-redux";
import BookingCard from "./BookingCard";
import { BookingStatus, BookingDataOwnership, BookingAuthority } from "../../../Services/BookingEntities";
import { ApplicationState } from "../../../appState";
import { ExtendedEta, BookingInfo } from "../MyBookingEntities";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { OrderBookingsByTimeInAsc, OrderBookingsForMobileDevice } from "../Logic/OrderMyBookings";
import { Typography } from "@mui/material";

interface ListOfBookingCardsProps {
    BookingsList: BookingInfo[];
    EtaLookup: Record<number, ExtendedEta>;
    LayoutMode: UILayoutMode;
    BookingTrackingLink: BookingAuthority | null;
}

/** 
 * Return a list of BookingCard from myBookings, which is used by both mobile & desktop.
 * 
 * Business requirement:
 * If desktop, the list maps the column "Pending pick-up", which includes pending pick-up and no job with asc order;
 * If mobile, the list maps all bookings except for canceled with desc order.
 */
class ListOfBookingCards extends React.Component<ListOfBookingCardsProps> {

    render() {

        let bookingList;
        const showEmptyNote = (this.props.LayoutMode === UILayoutMode.Mobile) && (this.props.BookingsList.length === 0);

        //Display different status of bookings per device with required order by time
        if (this.props.LayoutMode === UILayoutMode.Mobile) {
            bookingList = OrderBookingsForMobileDevice(this.props.BookingsList);
        }
        else {
            let activeBookings = this.props.BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID <= BookingStatus.Accepted || booking.TrackingInfo.BookingStatusID == BookingStatus.NoJob);            

            // bookings cancelled by the server due to JIT preauth failure should not disappear from the list until the user clicks on 'Remove' button (to avoid confusions).
            let cancelledDueToPreauthFailure = this.props.BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID == BookingStatus.Cancelled && booking.TrackingInfo.WasJitPreauthFailure);
            activeBookings.push(...cancelledDueToPreauthFailure);

            // bookings opened via a tracking link but not cancelled due to JIT preauth failure.
            let cancelledLinkBookings = this.props.BookingsList.filter((booking) => booking.TrackingInfo.BookingStatusID == BookingStatus.Cancelled && (booking.DataOwnership === BookingDataOwnership.ReadOnlyLink || booking.DataOwnership === BookingDataOwnership.WritableLink) && !booking.TrackingInfo.WasJitPreauthFailure);
            activeBookings.push(...cancelledLinkBookings);

            bookingList = OrderBookingsByTimeInAsc(activeBookings);
        }

        const TrackingLink = this.props.BookingTrackingLink;

        return (
            <>
                {bookingList.map((booking, index) => <BookingCard ShouldAutoOpen={index === 0 || (!!TrackingLink && TrackingLink.bookingId == booking.BookingID)} BookingDetails={booking} key={booking.BookingID} Eta={this.props.EtaLookup[booking.BookingID]} />)}
                {showEmptyNote && <Typography variant="h6" style={{color: "#4a4a4a", paddingTop: "20px"}}>No active booking found</Typography>}
            </>      
        );
    }
}

function mapStateToProps(state: ApplicationState): ListOfBookingCardsProps {
    return {
        BookingsList: state.myBookings.All,
        EtaLookup: state.myBookings.Etas,
        LayoutMode: state.uiLogicControl.LayoutMode,
        BookingTrackingLink: state.trackingLinks.NewFromUrl?.BookingKey ?? null
    }
}

export default connect(mapStateToProps)(ListOfBookingCards);