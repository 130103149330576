import React, { FC } from 'react';
import "./Features.scss";
import "../Dialog/Dialog.scss";
import { connect } from 'react-redux';
import { ApplicationState } from '../../appState';
import { FeatureCard } from './FeatureCard';
import { NewFeatureIds } from './FeaturesEntities';
import { FeaturesState } from './FeaturesState';

interface PropsFromStore {
    Features: FeaturesState
}

/** this feature will render into dialog in ResponsiveDialogHost.tsx  */
const LatestFeatureCardCore: FC<PropsFromStore> = ({Features}) => {

    function CreateFeatureList(featureIdList: number[]) {
        const keys = featureIdList;    
        const newKeys =  NewFeatureIds;
    
        const newFeatures = keys.filter(k => newKeys.includes(k)); 

        const LatestfeatureCard = newFeatures.map(id => {
            return <FeatureCard FeatureId={id} CanShowAcknowledgeButton={true} CanShowCloseButton={true} />
        });
        return LatestfeatureCard;
    }

    const newFeatureList = CreateFeatureList(Features.NewFeatureList);
    
    return (
        <div className="highest-features-on-mobile">
            {newFeatureList}
        </div>
    )
}

function mapStateToProps(state: ApplicationState) : PropsFromStore {
    return {
        Features: state.features
    };
}


export default connect(mapStateToProps)(LatestFeatureCardCore);
