import { FeatureInfo, FeatureOptInRequest } from "./FeaturesContracts";
import { ServiceResult } from "./ServiceEntities";
import { Get, GetManagementServiceRootUrlWithoutState, Post } from "./ServiceManager";

/** Base URL for the FeaturesController in the BookingManagement service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}Features/`;
}

/** HTTP API Calls to the FeaturesController in the BookingManagement Service. */
class FeaturesServiceClass {

    /** Get the list of features for the current user. */
    public GetMyFeatures(): Promise<ServiceResult<FeatureInfo[]>> {
        const url = `${GetBaseUrl()}GetMyFeatures`;
        return Get<FeatureInfo[]>(url);
    }

    /** For the current user, opt-in or opt-out of a particular feature */
    public OptInFeature(request: FeatureOptInRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}OptInFeature`;
        return Post<void>(url, request);
    }
}

export const FeaturesService = new FeaturesServiceClass();