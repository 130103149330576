import { HasLogInProperly } from "./AuthHelper";
import { Redirect, Route, RouteProps } from "react-router-dom";
import React from "react";

interface PrivateRouteProps extends RouteProps {
  component: any;
}

/**
 * It acts like a protected version of Route
 * where it renders that component only when the user is logged it, else it redirects to homepage(/)
 * It helps where inside each component, we don't have to check whether the user is logged in.
 * 
 * Note:- As we are using state to decide whether the user is logged in, hard refresh or direct access to the url won't work , as the state is not set by then
 * 1st option is to fix the order of setting authentication state
 * 2nd is to use local storage
 * 3rd  is to use something like
 * <Route {...rest} component={BookingPage} />
 */

export const PrivateRoute = (props: PrivateRouteProps) => { 
  const { component: Component, ...rest } = props;
  return (
    <Route {...rest} render={routeProps => HasLogInProperly() ? ( <Component {...routeProps} />):(
      <Redirect to={{pathname: "/"}}/>) } />
  );
};


