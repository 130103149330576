import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './SignupLogin.scss';
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import AuthImplV2 from "./AuthImplV2";
import { IsValidEmailFormat } from "./CredentialHelper";
import { GetValues } from '../../../Config/MyAppConfig';
import ResponsiveEmailInput from "../Login/ResponsiveEmailInput";
import { ResponsiveInputWrapper } from "../../Authentication/Mobile/ResponsiveInputWrapper";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";

interface PropsFromStore {
    Email: string | undefined;
    IsApiRunning: boolean;
    UnderMobileMode: boolean;
}

/**
 * Renders the forgot-password popup container for the new Auth0 interface.
 */
class ForgotPassword extends Component<PropsFromStore> {

    render() {
        return (
            <div className="signup-login-popup-panel forgot-password-panel-height">
                <ResponsiveInputWrapper>
                    <ResponsiveEmailInput IsNonEmailLoginAllowed={false} />
                </ResponsiveInputWrapper>
                <SendLinkButton {...this.props} />
                <CallUsText {...this.props} />
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        Email: state.authentication.Credentials.Email,
        IsApiRunning: state.uiLogicControl.Credentials.ApiRunningStatus === DataLoadingStatus.InProgress,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export default connect(mapStateToProps)(ForgotPassword);

/**
 * Call us for forgot password
 */
export const CallUsText: React.FC<PropsFromStore> = (props) => {

    const phoneNumber = GetValues().BrandContactNumber;
    if (!phoneNumber) return null;

    const number = !props.UnderMobileMode ? (<span>{phoneNumber}</span>) : (<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>);

    return (
        <p className="credentials-education-message">If you have any problems, give us a call on {number} to book a cab.</p>
    );
}

/**
 * Renders the "Send link" button in the forgot password form.
 */
export const SendLinkButton: React.FC<PropsFromStore> = (props) => {

    const btnStyle = IsSendReady() ? "credential-btn-loading credential-btn-proceed-large-active" : "credential-btn-loading credential-btn-proceed-large-inactive";

    const proceedBtnContent = props.IsApiRunning && !props.UnderMobileMode ? <img className="credential-loading" src={getContentUrl(ContentURL.images.Loading)} alt="Loading" /> : "Send link";

    return (
        <div className="signup-popup-btn-group">
            <button className={btnStyle} onClick={SendEmail}>
                {proceedBtnContent}
            </button>
        </div>
    );

    /**
     * Whether the Send Link button is enabled.
     */
    function IsSendReady(): boolean {
        return !!props.Email && IsValidEmailFormat(props.Email);
    }

    /**
     * Button click action for Forgot Password.
     * Sends a password reset email.
     */
    function SendEmail() {
        if (!props.Email) return;
        if (props.IsApiRunning) return;

        new AuthImplV2().ForgotPasswordAuth0(props.Email);
    }
}