import moment from "moment";
import { Dispatch } from "../Dispatch";
import { PaymentCardErrorType, PaymentOption } from "./PaymentEntities";

/** If a payment card is selected, check if it is expired and set an error message to display (and to block booking creation). */
export function CheckForCardExpiry(paymentOption: PaymentOption) {
    if (!paymentOption.Card) return;

    const isExpired = moment(paymentOption.Card.CardExpiry, "MM/YY").add(1, "month").isBefore(moment());

    if (isExpired) {
        Dispatch.Payment.CardErrorMessage(PaymentCardErrorType.CardExpired);
    }
}