import { Component } from 'react';
import Auth from "../AuthImpl";
import { AuthToken, Auth0SuccessOrError } from "../AuthEntities";
import { ParseAuth0Response } from "../AuthHelper";
import { ChildMessageKind, MessageFromChild } from "./LoginEntities";

/**
 * This component handles "/loginreturnv2" route, which is the redirect landing page from an Auth0 login.
 * This page will load in a hidden iFrame, handle the login token, and send the result in a message to the parent window, which is managed by the SilentLogin component.
 */
class AuthCallbackV2 extends Component {
    constructor(props: {}) {
        super(props);
    }

    async componentDidMount() {

        // Check if parent exists, else abort
        if (window.parent === window) return;

        const result: Auth0SuccessOrError = await new Auth().handleAuthentication();

        if (result.IsSuccess) {

            const authToken: AuthToken = ParseAuth0Response(result.SuccessfulResult);
            const message: MessageFromChild = {
                MessageKind: ChildMessageKind.LoginResult,
                LoginResult: {
                    IsSuccess: true,
                    AuthToken: authToken
                }
            };

            window.parent.postMessage(message, window.location.origin);
        }
        else {
            const message: MessageFromChild = {
                MessageKind: ChildMessageKind.LoginResult,
                LoginResult: {
                    IsSuccess: false,
                    Error: result.ErrorResult,
                }
            };

            window.parent.postMessage(message, window.location.origin);
        }
    }

    render() { return null; }
}

export default AuthCallbackV2;