import React, { useEffect, useState } from "react";
import "./TripHistory.scss";
import { getContentUrl, ContentURL } from "../Utils/ContentURL";
import { Config } from "../../Config/Config";
import { GetTripHistoryByDateRange, GetTripHistory } from "../Booking/BookingLoaders";
import { BookingInfo, PartialDateRange } from "../MyBookings/MyBookingEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { GetTripHistoryV2 } from "../BookingV2/BookingLoadersV2";
import { DataGrid, GridCsvGetRowsToExportParams, GridRowId, gridSortedRowIdsSelector, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Stack, Tooltip } from "@mui/material";
import { TaxiHistoryGridColumns } from "./TripHistoryGridDefinition";
import { ApiDateRange } from "../../Services/BookingEntities";
import { Dispatch } from "../Dispatch";
import { DateRangePicker } from "../../widgets/DatePicker/DateRangePicker";
import { useAppState } from "../../Redux/ReduxHooks";

/** styling data grid component using sx props. */
const datagridSx = {
    padding: 10,
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        "& .MuiDataGrid-row": {
            "&:nth-child(odd)": { backgroundColor: "#f8f6f2" },
            paddingLeft: "5px"
        }
    },
    "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#a4a4a46b",
        color: "black",
        paddingLeft: "5px",
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold"
        }
    },
    "& .MuiTablePagination-root .MuiToolbar-root": {
        marginLeft: 0
    }
}

/** Component which contains a table of Trip history of the logged in user. */
const TripHistory: React.FC = () => {

    // component state
    const [listOfBookings, setListOfBookings] = useState<BookingInfo[]>([]);
    const [isDataLoadInProgress, setIsDataLoadInProgress] = useState<boolean>(false);

    // app state
    const dateRange = useAppState(i => i.myBookings.RecentTripHistoryDateRange);

    // startup
    useEffect(() => { StartupDataLoad() }, []);

    /** Preset the Date Range selection with state value during component mount and load the trip history table for the range.
     * Or if there is no existing state, make a default trip history request.
     */
    async function StartupDataLoad() {
        await handleMaybeDateRangeSearch(dateRange);
    }
   
    async function searchTripHistory() {
        let tripHistory;
        setIsDataLoadInProgress(true);
        if (!FeatureFlags.BookingApiV2) {
            tripHistory = await GetTripHistory(Config.StatusListForRecentTripList);
        } else {
            tripHistory = await GetTripHistoryV2(Config.StatusListInHistoryFromV2API);
        }

        sortAndLoadTripHistory(tripHistory);
        setIsDataLoadInProgress(false);
    }
    
    /**
     * Handling selected start date and end date from date range picker
     * or loading default trip history.
     */
    async function handleMaybeDateRangeSearch(range: PartialDateRange) {
        if (range.StartDate && range.EndDate && !FeatureFlags.BookingApiV2) {

            // Convert the UI / user understanding "1st to 7th" to the API format, where the end date is not included. i.e. add 1 extra day.
            const requestDates: ApiDateRange = {
                StartDateInclusive: range.StartDate.toISO(),
                EndDateExclusive: range.EndDate.plus({ days: 1 }).toISO(),
            }
            Dispatch.MyBookings.MyRecentTripsDateRangeRefresh(range);

            setIsDataLoadInProgress(true);

            const tripHistory = await GetTripHistoryByDateRange(requestDates);
            sortAndLoadTripHistory(tripHistory);
            setIsDataLoadInProgress(false);
        }
        else if (!range.StartDate && !range.EndDate)
        {
            await searchTripHistory();
        }
    }

    /** Reset and load default number of trips in booking history */
    async function handleDateRangeResetAndLoadTripHistory() {
        const dates: PartialDateRange = {
            StartDate: null,
            EndDate: null
        };

        Dispatch.MyBookings.MyRecentTripsDateRangeRefresh(dates);
        await searchTripHistory();
    }

    const inactiveBookingStatusList = Config.PastBookingTripStatusList;

    /**
     * Sort the booking list by booking status, display the booking with status "Booked" to appear at the top of the list.
     */
    function sortAndLoadTripHistory(tripHistory: BookingInfo[] | undefined)
    {
        if (tripHistory) {
            tripHistory.sort(a => inactiveBookingStatusList.includes(a.TrackingInfo.BookingStatusID.toString()) ? 1 : -1);

            setListOfBookings(tripHistory);
        }
    }

    /**
     * A function which determines which row in the grid will be exported in CSV export.
     * This uses an in-buit selector to export the rows in their current sort order.
     */
    function GetExportedRowIds(params: GridCsvGetRowsToExportParams): GridRowId[] {
        return gridSortedRowIdsSelector(params.apiRef);
    }

    // #region Rendering

    return (
        <div className="trip-history-main-panel">
            {RenderPrimaryContent()}
        </div>      
    );

    function RenderPrimaryContent(): JSX.Element {

        // loading
        if (isDataLoadInProgress) {
            return (
                <img
                    alt="loading"
                    src={getContentUrl(ContentURL.images.Loading)}
                    className="loading-history" />
            );
        }

        // normal case: Grid
        const rowData = listOfBookings;
        const columns = TaxiHistoryGridColumns;

        return (
            <DataGrid
                autoHeight
                rows={rowData}
                columns={columns}
                sx={datagridSx}
                getRowId={(booking) => booking.BookingID}
                components={{
                    Toolbar: RenderGridToolbar,
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            There are no bookings in your history.
                        </Stack>
                      ),
                }}
            />
        );
    }

    function RenderGridToolbar(): JSX.Element {
        return (
            <GridToolbarContainer style={{ paddingLeft: "12px" }}>
                <Tooltip arrow title="Export trip history into a CSV" placement="top-end"
                    componentsProps={{
                        tooltip: {
                            sx: {
                                color: "white",
                                backgroundColor: "black",
                                "& .MuiTooltip-arrow": {
                                    "&::before": {
                                        backgroundColor: "black"
                                    }
                                }
                            }
                        }
                    }}>
                    <GridToolbarExport
                        variant="contained"
                        color="primary"
                        size="medium"
                        style={{
                            marginTop: "15px",
                            marginBottom: "15px",
                            fontWeight: "bold",
                            letterSpacing: "1px"
                        }}
                        printOptions={{ disableToolbarButton: true }}
                        csvOptions={{ getRowsToExport: GetExportedRowIds }} />
                </Tooltip>
                {!FeatureFlags.BookingApiV2 && <DateRangePicker
                    startDate={dateRange.StartDate}
                    endDate={dateRange.EndDate}
                    onDateChange={handleMaybeDateRangeSearch}
                    onDateReset={handleDateRangeResetAndLoadTripHistory} />}
            </GridToolbarContainer>
        );
    }

    // #endregion
}

export default TripHistory;