import React from 'react';
import { connect } from "react-redux";
import LogoHeader from "../../widgets/Header/LogoHeader";
import './Tnc.scss';
import DefaultLoadingPageContent from "../../widgets/DefaultLoadingPageContent/DefaultLoadingPageContent";
import { ApplicationState } from '../../appState';
import { TncPrivacyState } from './TncPrivacyEntities';
import { RetrieveTnc } from './TncPrivacyHelper';

interface PropsFromStore {
    tncPrivacy: TncPrivacyState;
}

/** Handles the /Terms URL. */
class Tnc extends React.Component<PropsFromStore, {}> {
    componentDidMount() {
        if(!this.props.tncPrivacy.TncContent){ 
            RetrieveTnc(false, true, true);
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <LogoHeader />
                {!!this.props.tncPrivacy.TncContent && <div className="terms" dangerouslySetInnerHTML={{__html: this.props.tncPrivacy.TncContent}}></div>}
                {!this.props.tncPrivacy.TncContent && <DefaultLoadingPageContent blackTransparentLevel='light' />}
            </React.Fragment>
        )
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {
        tncPrivacy: state.tncPrivacy
    };
}

export default connect(mapStateToProps)(Tnc);
