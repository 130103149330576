import { Brand } from "../Entities/Brand";
import { DeploymentEnvironment } from "../Entities/DeploymentEnvironment";
import { AppConfig } from "../Entities/AppConfig";
import { BaseConfig } from "./BaseConfig";
import { EnvironmentConfig } from "./EnvironmentConfig";
import { FeatureFlags } from "../FeatureFlags";
import { BrandConfig } from "./BrandConfig";
import { BrandEnvConfig } from "./BrandEnvConfig";

/**
 * Generates an AppConfig object for the current environment.
 * We are relying on environment variables for the deployed environment and brand name being passed in. They should be provided during build.
 */
export function AssembleMyConfig(): AppConfig {

    // careful: type assertion from process.env (any)
    const myBrand = (process.env["RAZZLE_BRAND_NAME"] ?? Brand.OneThreeCabs) as Brand;
    const myEnv = (process.env["RAZZLE_DEPLOYMENT_ENV"] ?? DeploymentEnvironment.Development) as DeploymentEnvironment;

    return AssembleConfig(myBrand, myEnv);
}

/**
 * Generate a full app configuration customised for a given brand and environment.
 */
function AssembleConfig(brand: Brand, environment: DeploymentEnvironment): AppConfig {

    // start with default
    let values = BaseConfig;
    let features = FeatureFlags;

    // environment override
    let override = EnvironmentConfig[environment];

    if (override?.Values) {
        values = {
            ...values,
            ...override.Values,
        };
    }

    if (override?.Features) {
        features = {
            ...features,
            ...override.Features,
        };
    }

    // brand override
    override = BrandConfig[brand];

    if (override?.Values) {
        values = {
            ...values,
            ...override.Values,
        };
    }

    if (override?.Features) {
        features = {
            ...features,
            ...override.Features,
        };
    }

    // brand + environment override
    override = BrandEnvConfig[brand]?.[environment];

    if (override?.Values) {
        values = {
            ...values,
            ...override.Values,
        };
    }

    if (override?.Features) {
        features = {
            ...features,
            ...override.Features,
        };
    }

    return {
        Brand: brand,
        Environment: environment,
        Features: features,
        Values: values,
    };
}