import React from "react";
import { FormStepLabel } from "./FormStepLabel";
import StepControl from "./StepControl";
import './BookingFormGroup.scss';

/**
 * A group of adjacent UI elements in the booking form.
 * The content to go under this group should be nested as JSX children.
 * When a step name is included, adds a label like "Step 1 of 3: Account Details".
 */
export const BookingFormGroup: React.FC<JsxProps> = (props) => {

    const maybeIncludeStep = !!props.WithStepLabel ? "booking-form-named-step" : "";
    const maybeIncludeShading = props.AddShading ? "booking-form-group-shading" : "";

    return (
        <div className={`booking-form-group ${maybeIncludeStep} ${maybeIncludeShading}`} >
            {props.WithStepLabel && <StepControl StepKind={props.WithStepLabel} />}
            {props.children}
        </div>
    );
}

interface JsxProps {
    /** 
     *  Adds a named step label like "Step 1 of 4: Pickup Details"
     *  It is almost always populated, except for the timing section, which doesn't use a label.
     */
    WithStepLabel?: FormStepLabel;

    /** Set the background to a shaded colour. To help visually separate this step group from the adjacent ones. */
    AddShading?: boolean;
}