import { AuthToken, LogoutKind, LoginStatusKind } from "./AuthEntities";
import { Auth0DecodedHash } from 'auth0-js';
import { Config } from "../../Config/Config";
import Auth from "./AuthImpl";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { Dispatch } from "../Dispatch";
import { SimpleUserProfile } from '../User/ProfileEntitiesV2';
import appstore from "../../appStore";
import { getContentUrl, ContentURL }  from '../Utils/ContentURL';
import { PasswordErrorItemStyleDecisionObject, PasswordErrorType } from "./Login/LoginEntities";
import { LogEvent } from "../../utils/LogEvent";
import { MyStorage } from "../../Storage";
import CredentialsController from "./Login/CredentialsController";

/**
 * Returns a safe expiry time for the token, given the stated expiry time in seconds.
 * The output is a system time in milliseconds.
 */
export function CalculateTokenExpiryTime(expiresInSeconds: number) : number {
    return GetSafeExpiryMillis(expiresInSeconds) + new Date().getTime();
}

/**
 * Gets a safe value for the expiry duration, in milliseconds.
 * We drop up to 5 minutes from the end to ensure an early refresh.
 */
function GetSafeExpiryMillis(expiresInSeconds: number): number {

    const defaultExpiryMillis = expiresInSeconds * 1000;

    // don't allow negative durations
    if (defaultExpiryMillis < Config.TokenExpiryBufferMillis) {
        return defaultExpiryMillis;
    }

    return defaultExpiryMillis - Config.TokenExpiryBufferMillis;
}

/**
 * Check if current Auth0 session expired or not
 */
export function IsExpired(expiresAt: number) : boolean {
    return expiresAt < new Date().getTime();
}

/**
 * Convert a successful Auth0 authentication result into an AuthToken object.
 * It includes 13cabs-specific properties such as your User ID.
 */
export function ParseAuth0Response(authResult: Auth0DecodedHash) : AuthToken {
    const authToken: AuthToken = {
        accessToken: authResult.accessToken!,
        idToken: authResult.idToken!,
        email: authResult.idTokenPayload.email! as string,
        sub: authResult.idTokenPayload.sub!,
        state: authResult.state!,
        expiresIn: authResult.expiresIn!,
        scope: authResult.scope,
        expiresAt: CalculateTokenExpiryTime(authResult.expiresIn!)
    }

    return authToken;
}

/**
 * Handling after Auth0 callback is successful.
 * Store the newly created token in both Redux and Local Storage.
 */
export function StoreNewAuthToken(authToken: AuthToken): void {    
    Dispatch.Auth.Auth0TokenCreated(authToken);
    MyStorage.AuthToken.StoreData(authToken);
}

/**
 * Attempts to silently renew an Auth0 Single Sign-On session, returning the new login token details.
 * Does logging to App Insights and limited retries (timeouts only).
 * Returns null and logs the user out on failure.
 */
export async function TryRenewAuth0Session() : Promise<AuthToken | null> {
    let retriesLeft = Config.RetryTimes;

    // retry loop. Only for timeouts. Max [Config.RetryTimes] retries
    while (true) {

        const authResult = await new Auth().renewSession();

        // success case
        if (authResult.IsSuccess) {
            const authToken = ParseAuth0Response(authResult.SuccessfulResult);
            StoreNewAuthToken(authToken);
            LogEvent.Auth0RenewSuccess(authToken);

            return authToken;
        }

        // failure cases
        const error = authResult.ErrorResult!;

        // timeout is the only retryable error
        if (error.error === "timeout" && (retriesLeft > 0)) {
            retriesLeft--;
            continue;
        }

        // non-retryable failure. Give up here
        LogEvent.Auth0RenewFailure(error);
        new Auth().logout(LogoutKind.Website);
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.Auth0RenewFailure);
        
        return null;
    }
}

/**
 * Returns true when the user still requires SMS verification.
 * 2 conditions contribute a "true" based on user profile:
 *     1> Contact number is a valid mobile number;
 *     2> Is user has not verified yet;
 */
export function IsVerificationNeeded(userProfile: SimpleUserProfile) : boolean {
    if (userProfile.IsValidMobileNumber == false) return false;
    if (userProfile.IsVerified) return false;
    return true;
}

/**
 * Critical way to test if a user has logged in properly
 * What is log in properly?
 *   1> Authenticated;
 *   2> Got profile;
 *   3> Has contact number, only if IsCheckingContactNumber == true (Need to check userProfile.contactPhone).
 */
export function HasLogInProperly(isContactNumberCheckMandatory: boolean = false): boolean {
    
    // Read store
    const authentication = appstore.getState().authentication;
    
    // Direct login status
    if (authentication.LoginStatus !== LoginStatusKind.LoggedIn) return false;

    // Check if login related data exit
    if (!authentication.AuthToken || !authentication.UserProfile) return false;

    // This is to cater for some legacy business requirement, which need to considering if contact number is provided or not
    if (!isContactNumberCheckMandatory) return true;

    return authentication.UserProfile!.ContactPhone != "";
}

/**
 * This function is used for password error message panel, if there is, per item.
 * There are 4 validation criteria for password input in sign-up pop-up:
 *     1> Length strength;
 *     2> Contain upper case character or not;
 *     3> Contain lower case character or not;
 *     4> Contain numeric or not; 
 * 
 * This function will be applied per above item to decide the statuses of each item to support UI.
 * There 3 parameters:
 *     @invalidity: Whether this criterion is valid or not;
 *                  True means invalid, which is consistent with state in component CredentialPassword;
 *     @cssDent: value can be "small" or "large", dent of css style of the message of this criterion in the panel;
 *     @errorType: error type.
 */
export function DecideCredentialErrorMessagePerItemStyle(invalidity: boolean, cssDent: string, errorType: PasswordErrorType) : PasswordErrorItemStyleDecisionObject {
    let icon = "", cssClass = "", validityText = "";

    if (!invalidity) {
        icon = getContentUrl(ContentURL.images.Login.MessageIconCorrect);
        validityText = "valid";
    }
    else {
        if (errorType === PasswordErrorType.Missing) {
            icon = getContentUrl(ContentURL.images.Login.MessageIconEmpty);
            validityText = "missing";
        }
        else if (errorType === PasswordErrorType.Incorrect) {
            icon = getContentUrl(ContentURL.images.Login.MessageIconIncorrect);
            validityText = "invalid";
        }
    }

    cssClass = "credential-password-message-line-container password-message-" + cssDent + "-leading-space password-message-text-color-" + validityText;  

    return {Icon: icon, CssClass: cssClass}
}

/**
 * Check local storage if this user is logged-in status.
 * Logout again if not logged-in in local storage.
 */
export function VerifyLoginFromLocalStorageOrLogout() {
    /**
     * Todo:
     * Below condition has similar capability of function HasLogInProperly(), and we might resurface the solution for long term.
     */
    if (!MyStorage.AuthToken.LoadData() || !MyStorage.UserProfileV2.LoadData()) {
        appInsights.trackEvent("Logged out already", { ErrorMessage: "This user has logged out in another tab" }); 
        new Auth().logout(LogoutKind.Website);
    }
}

/**
 * Mobile login/logout trigger
 */
export function TriggerSheetLoginOrLogout(status: LoginStatusKind) {
    if (status === LoginStatusKind.LoggedOut) {
        Dispatch.UILogicControl.CloseMenu();
        new CredentialsController().DoLogin();
        return;
    }
    else {
        new Auth().logout(LogoutKind.WebsiteAndAuth0);
    }
}