import { GetManagementServiceRootUrl, Post } from "./ServiceManager";
import { ServiceResult } from "./ServiceEntities";
import { SendContactRequest } from "../modules/ContactUs/ContactFormEntities";

/** Base URL for the ContactController in the Booking Management Service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrl()}Contact/`;
}

/** Client for the ContactController in the Management API */
class ContactServiceClass {

    /** Submit the enquiry. */
    public SubmitEnquiry(ContactRequest: SendContactRequest): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}Submit`;
        return Post<void>(url, ContactRequest);
    }
}

export const ContactService = new ContactServiceClass();