import {  UserProfileNavBarNames } from "./UserProfileNavEntities"
import { ContentURL } from '../Utils/ContentURL';
import { FeatureFlags } from "../../Config/FeatureFlags";

/**
 * This file holds all the individual and the array for User profile  nav items
 */


/**
 * Menu Item to open user's personal details
 */
export const personalDetailsNavItem = { Title: "Profile", Name: UserProfileNavBarNames.PersonalDetails, Image: ContentURL.images.UserProfile.MyProfileIcon };

/**
 * Menu Item to open user's wallet
 */
export const walletNavItem = { Title: "Payment", Name: UserProfileNavBarNames.Wallet, Image: ContentURL.images.PaymentType.WalletIconBlack };

/**
 * Menu Item to open user's list of saved addresses, and add address points
 */
export const addressPointsNavItem = { Title: "Saved Addresses", Name: UserProfileNavBarNames.AddressPoints, Image: ContentURL.images.UserProfile.Star };

/**
 * Menu Item to open available features to the user where the user can enable or disable features.
 */
 export const availableFeaturesNavItem = { Title: "Available Features", Name: UserProfileNavBarNames.AvailableFeatures, Image: ContentURL.images.UserProfile.Rocket};

/**
 * All Nav Items 
 */
export let allUserProfileNavItems = FeatureFlags.CardNotPresentPayment ? [personalDetailsNavItem, walletNavItem, addressPointsNavItem] : [personalDetailsNavItem, addressPointsNavItem];

if (FeatureFlags.PreviewFeatures) allUserProfileNavItems.push(availableFeaturesNavItem);
