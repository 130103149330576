import { LogoutKind } from "../Authentication/AuthEntities";
import { TaskKind, TaskStatus  } from "./LoginValidationEntities";
import Auth from "../Authentication/AuthImpl";
import { Dispatch } from "../Dispatch";
import appstore from "../../appStore";

/** 
 * As a part of logged-in users' validation, if they decline:
 *    1) Provide contact number if there is no valid one;
 *    2) Verify their mobile;
 *    3) Accept new version of terms and Conditions.
 */
export function DeclineProfileValidationProcess() {
    Dispatch.LoginValidation.FinishVerification();
    new Auth().logout(LogoutKind.WebsiteAndAuth0);
}

/** 
 * Test if it's under logged-in users' validation process mode.
 */
export function IsUnderProfileValidationMode() {
    const LoginValidation = appstore.getState().loginValidation;
    return !!LoginValidation.TimerID && LoginValidation.IsAsyncTaskRunning;
}

/** 
 * As a part of logged-in users' validation, to remove timer/listener.
 */
export function RemoveProfileValidationListener() {
    const LoginValidation = appstore.getState().loginValidation;
    if (LoginValidation.TimerID) {
        clearInterval(LoginValidation.TimerID);
        Dispatch.LoginValidation.FinishVerification();
    }
}

/** 
 * As a part of logged-in users' validation, to update authentication.UserProfileValidation.TaskLookUpTable on either:
 *     1) TaskKind.NoValidContactNumber;
 *     or, 2) TaskKind.MobileVerification;
 */
export function CloseOffContactNumberUpdateTaskInProfileValidation() {
    const { TaskLookUpTable } = appstore.getState().loginValidation;

    if (TaskLookUpTable[TaskKind.NoValidContactNumber] === TaskStatus.InProgress) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.NoValidContactNumber);
    }
    else if (TaskLookUpTable[TaskKind.MobileVerification] === TaskStatus.InProgress) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.MobileVerification);
    }
}