import React from "react";
import './PriceGuaranteeModal.scss';
import {Typography, Button} from "@mui/material"
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";
import { MyStorage } from "../../../Storage";
import DialogClose from "../../Dialog/DialogClose";

/* This Component UI is for explaining the Price Guarantee feature  */

function PriceGuaranteeDialogTip() {

    const closeDialog = () => {        
        MyStorage.HasSeenPriceGuaranteeModalFAQ.StoreData(true);
        Dispatch.Dialog.CloseDialog(DialogKind.PriceGuaranteeFAQ);
    }

    return (
        <div className="PriceGuarantee-details">
            <DialogClose />
            <Typography variant='h5' color='primary' align='center' gutterBottom>Fixed Price</Typography>
            <div className="PriceGuarantee-description"><Typography variant='body1' align="center">Fixed Price is a price set at the time of booking and is the final price you will pay. No additional charges apply.</Typography></div>
            <Button onClick={closeDialog} size="large" variant='contained' color='primary'>OK</Button>
        </div>
    );
}

export default PriceGuaranteeDialogTip;