import React from 'react';

export class QuickBookLoadingAnimation extends React.Component {
    
    render() {

        const rows = ["one", "two", "three"];
        
        const list =  rows.map((x, index) => {
            return (
                <div key={index} id={x}></div>
            )
        });

        return (
            <div className="animationLoading">
                {list}                
            </div>
        )
    }
}