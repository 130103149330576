import { AuthToken } from "../Authentication/AuthEntities";
import { SimpleUserProfile } from '../User/ProfileEntitiesV2';

/**
 * To check if the current status is a guest booking or not, 
 * which includes who log in successfully but failed to get profile [we won't consider this scenario soon, as the system will force the user log out]
 */
export function IsGuest(authToken: AuthToken | undefined, userProfile: SimpleUserProfile | undefined) : boolean {
    return authToken == null || userProfile == null;
}
