import appstore from "../../appStore";
import { AddressDetails, AddressPointModalType } from "../AddressPoints/Redux/AddressPointsEntities";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";

/** Opens the Save address dialog for logged in users and Sign Up dialog for guest users.
 * Selected Address details are populated in the Address field in the save dialog. This is a best effort and if the selected address is not resolved, open the dialog without prefilled data. */
export function ConsiderOpeningSaveDialogWithData(addressDetails: AddressDetails | null) {

    const loginStatus = appstore.getState().authentication.LoginStatus;

    if (loginStatus === LoginStatusKind.LoggedOut) {
        new CredentialsController().DoSignup();
        return;
    }

    if (addressDetails) {
        Dispatch.AddressPoints.RefreshAddress(addressDetails);
    }

    Dispatch.Dialog.ShowDialog(DialogKind.CreateAddressPoint);
    Dispatch.AddressPoints.SetUiEditModeType(AddressPointModalType.Create);
}