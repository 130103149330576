import { DropLeadingZero, CheckAddPlus, FormatBusinessNumber } from '../../utils/Formattingutil';
import { CustomErrorMessages, DescriptiveErrorMessages, WellKnownMessageKind } from "../Utils/ErrorMessages";
import appstore from "../../appStore";
import { DialogKind } from '../Dialog/DialogEntities';
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { CreateBookingV2 } from "../BookingV2/CreateBookingV2";
import { CreateBookingV1 } from "./CreateBookingV1";
import { BookingWorkFlowState } from "./Redux/BookingState";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { BookingContactDetails } from '../../Services/BookingEntities';
import { HasLogInProperly } from '../Authentication/AuthHelper';
import { BookingLocationContact } from './BookingEntities';
import { BookingFormKind } from '../UILogicControl/UILogicControlEntities';
import { WellKnownErrors } from '../Utils/WellKnownErrors';
import { ICreateBooking } from './ICreateBooking';

/** V1 vs V2 selector for CreateBooking. Make sure all callers use this method! */
export function CreateBookingSelector(withSmsVerification: boolean) : ICreateBooking {
    if (FeatureFlags.BookingApiV2) {
        return new CreateBookingV2();
    }
    else {
        return new CreateBookingV1(withSmsVerification);
    }
}

/**
 * The behaviour of the "Book" button; hopefully to successfully create a new booking.
 */
export async function CreateBookingWithoutSmsVerification(): Promise<boolean> {

    Dispatch.UILogicControl.BookingFormApiStart();
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    // Call this method to select the version of CreateBooking and then call the CreateBooking Method to make the api call
    const createBookingHandler = CreateBookingSelector(false);
    const isSuccess = await createBookingHandler.CreateBooking();

    // Handle the case when this function was invoked from "Contact details"
    if (appstore.getState().dialog.topmostDialog === DialogKind.ContactDetails) {
        Dispatch.Verification.HideLoaderInContactDetails();
        Dispatch.Dialog.CloseDialog(DialogKind.ContactDetails);
    }

    if (isSuccess) {

        Dispatch.Dialog.ShowDialog(DialogKind.Confirmation);
        await createBookingHandler.ProcessBooking();
        Dispatch.Verification.ClearContactNumber();

        return true;
    }

    // error handling
    const error = createBookingHandler.GetError();

    if (error.isTimeout) {
        appInsights.trackEvent("Booking creation timeout");
        //Dispatch.Dialog.SetDescriptiveErrorMessage({ ...DescriptiveErrorMessages.CreateBookingTimeout });
        //Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
        Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
        return false;
    }
    
    appInsights.trackEvent("User booking failed");
    var errorMessage = { ...DescriptiveErrorMessages.CreateBookingFailed };
    
    // Specific error message for payment preauth failures.
    if (error.WellKnownError === WellKnownErrors.PreAuthFailed) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.PaymentPreAuthFailed);
        return false;
    }

    if (error.WellKnownError === WellKnownErrors.WatsOnXmasDay) {
        Dispatch.Dialog.ShowDialog(DialogKind.WatsOnXmasDayMessage);
        return false;
    }
    
    /**
    * When an account booking made that addresses are not consistent with region which account belongs to,
    * ODI gives error message "The account number is not valid".
    * 
    * If this case, we show a specific error message.
    * 
    * Tips:
    * Some other scenario will still give error message "The account number is not valid" from ODI.
    * For example, incorrect account number etc.
    * PO confirmed that we only need this error message.
    * 
    * Looping through all CustomErrorMessages to find the match and display relevant text
    */
    for (let relevantMessageText in CustomErrorMessages)
    {
        if (error.errorMessage.trim().includes(CustomErrorMessages[relevantMessageText])) {
            errorMessage.MessageText = CustomErrorMessages[relevantMessageText];
        }
    }
    
    //Dispatch.Dialog.SetDescriptiveErrorMessage(errorMessage);
    //Dispatch.Dialog.ShowDialog(DialogKind.DescriptiveErrorMessage);
    Dispatch.Dialog.ShowDialog(DialogKind.TechnicalDifficulties);
    
    return false;
}

/** Compute driver notes (pickup) */
export function ComputeDriverNotes(booking: BookingWorkFlowState): string {

    let notes = '';

    // When booking on accounts, prefix the remarks with the predefined driver note(s)
    if(booking.AccountData && booking.AccountData.SelectedAccount) {           
        notes = '-- Do Not Ask For Cash. No Stops, Go Direct. -- ';
    }

    // Append the user's specified remarks
    if (booking.Locations[0].DriverNotes) {
        return notes + booking.Locations[0].DriverNotes;
    }

    return notes;
}

/**
 * Gets the best contact details for a particular booking location (e.g. pickup / dropoff).
 * If details weren't specified in the UI, reuse the details from the booking contact.
 */
export function GetBestLocationContact(locationContact: BookingLocationContact): BookingContactDetails {

    // this is the fallback
    const bookingContact = GetOverallBookingContact();

    // PickupAdditionalDetails are mandatory for passenger bookings. Same details should be used as the dropoff details instead of booking contact(from user profile) only for passenger bookings. Because there are no dropoff contact section in the UI for passenger bookings
    const bookingMode = appstore.getState().uiLogicControl.BookingForm.ActiveBookingForm;

    if (bookingMode === BookingFormKind.PassengerBooking) {
        locationContact = appstore.getState().booking.Locations[0].Contact;
    }

    const name = locationContact.Name ?? bookingContact.Name;
    const number = locationContact.Phone?.FullNumber ?? bookingContact.PhoneNumber;

    return {
        Name: name,
        PhoneNumber: number,
    };
}

/**
 * Get the name and number for the overall contact person of the booking.
 * For logged in users, this typically comes from the user profile.
 * For guest bookings, it comes from SMS verification details.
 */
export function GetOverallBookingContact(): BookingContactDetails {

    const name = GetOverallContactName();
    const phoneNumber = GetOverallContactPhone();

    return {
        Name: name,
        PhoneNumber: phoneNumber,
    };
}

/**
 * Get the overall contact name for the booking. There are a few special cases.
 */
function GetOverallContactName(): string {

    const booking = appstore.getState().booking;

    // special case: DVA accounts
    if (booking.AccountData) {
        const { FileNumber, SelectedAccount } = booking.AccountData;

        if (SelectedAccount && SelectedAccount.IsDVA) {
            return FileNumber!;
        }
    }

    // logged in users
    const loggedInUser = appstore.getState().authentication.UserProfile;

    if (loggedInUser) {
        return loggedInUser.ContactName;
    }

    // guests: default from passenger
    return booking.Locations[0].Contact.Name ?? "";
}

/** 
 *  Generates the phone number of the overall booking contact.
 *  Returns an E.164 formatted string including leading "+" and country dial code prefix.
 */
function GetOverallContactPhone(): string {

    const isUserLoggedIn = HasLogInProperly(true);

    // Read from store
    const appState = appstore.getState();

    // logged in user: get from profile
    if (isUserLoggedIn) {
        
        const formattedNumber = FormatBusinessNumber(appState.authentication.UserProfile!.ContactPhone);        
        return CheckAddPlus(formattedNumber);
    }
    else if (appState.GuestPayment.BackingUser) {
        return appState.GuestPayment.BackingUser.PhoneNumber;
    }
    else {
        // guest: get from SMS verification
        const numberParts = appState.verification.UserContactNumberInfo;

        const countryCode = numberParts.CountryInfo!.CountryCode;
        return CheckAddPlus(countryCode + DropLeadingZero(numberParts.Contactnumber!));
    }
}