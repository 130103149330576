import React, { Component } from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import './SignupLogin.scss';
import InputWidgetWrapper from "./InputWidgetWrapper";
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import CredentialsController from "./CredentialsController";
import CredentialPassword from "./CredentialPassword";
import { ResetPasswordRequest } from "../../../Services/UserEntities";
import { TryResetPassword } from "./CredentialHelper";
import { LogoutKind } from "../../Authentication/AuthEntities";
import Auth from "../../Authentication/AuthImpl";
import { Dispatch } from '../../Dispatch';

interface PropsFromStore {
    ApiRunningStatus: DataLoadingStatus;
    ErrorMessage: string | undefined;
}

interface ResetPasswordState {
    CurrentPassword: string;
    NewPassword: string;
    ConfirmPassword: string;
}

/**
 * Renders the reset password popup container for only logged-in users.
 */
class ResetPassword extends Component<PropsFromStore, ResetPasswordState> {

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            CurrentPassword: "",
            NewPassword: "",
            ConfirmPassword: ""
        }
              
        this.closePopup = this.closePopup.bind(this);
        this.reset = this.reset.bind(this);
    }

    closePopup() {
        new CredentialsController().ClosePopup();
    }

    /**
     * How are ready?
     * Current, new and confirmed password values cannot be null.
     */
    areInputsReady(): boolean {
        return !!this.state.CurrentPassword && !!this.state.NewPassword && !!this.state.ConfirmPassword;
    }

    async reset() {
        const currentPassword = this.state.CurrentPassword, newPassword = this.state.NewPassword, confirmPassword = this.state.ConfirmPassword;

        if (this.areInputsReady() && this.props.ApiRunningStatus === DataLoadingStatus.Idle) {

            // Check if new password is same as old one
            if (currentPassword == newPassword) {
                Dispatch.Auth.LoginErrorMessage("You new password and old password are the same");
                return;
            }

            // New passwords should match
            if (newPassword != confirmPassword) {
                Dispatch.Auth.LoginErrorMessage("Please make sure your new passwords match");
                return;
            }

            const passwords: ResetPasswordRequest = { OldPassword: currentPassword, NewPassword: newPassword }
            const result = await TryResetPassword(passwords);

            if (result) { 
                new Auth().logout(LogoutKind.WebsiteAndAuth0OpenLogin); 
            }
        }
    }

    render() {
        const btnActivity = this.areInputsReady() ? "active" : "inactive";
        const proceedBtnStyle = "credential-btn-loading credential-btn-proceed-small-" + btnActivity;
        const proceedBtnContent = this.props.ApiRunningStatus === DataLoadingStatus.InProgress ? <img className="credential-loading" src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/> : "Set";

        return(
            <React.Fragment>
                <div className="signup-login-popup-panel reset-password-panel-height">
                    <h1 className="signup-login-popup-header">Change password</h1>
                    <p className="signup-login-popup-description medium-description-width">You can change your password below. Once done, you’ll be required to sign back in using your new password</p>
                    {this.props.ApiRunningStatus === DataLoadingStatus.Idle && <img className="credential-popup-quick-clear" onClick={this.closePopup} src={getContentUrl(ContentURL.images.buttons.greyX)} alt="close"/>}

                    <InputWidgetWrapper LabelName="Current password">
                        <CredentialPassword 
                            CustomChangeHandler={ value => this.setState({ CurrentPassword: value })}
                            EnforcePasswordComplexity={false} 
                            Placeholder="Current password"
                        /></InputWidgetWrapper>
                    <InputWidgetWrapper LabelName="New password">
                        <CredentialPassword 
                            CustomChangeHandler={ value => this.setState({ NewPassword: value })}
                            EnforcePasswordComplexity={true} 
                            Placeholder="New password"
                        /></InputWidgetWrapper>
                    <InputWidgetWrapper LabelName="Confirm password">
                        <CredentialPassword 
                            CustomChangeHandler={ value => this.setState({ ConfirmPassword: value })}
                            EnforcePasswordComplexity={true} 
                            EnterPressedEvent={this.reset}
                            Placeholder="Confirm password"
                        /></InputWidgetWrapper>

                    <div className="signup-popup-btn-group">
                        <button className="credential-btn-skip" onClick={this.closePopup}>Cancel</button>
                        <button className={proceedBtnStyle} onClick={this.reset}>{proceedBtnContent}</button>
                    </div>

                    {this.props.ErrorMessage && <p className="credentials-auth0-error-message" dangerouslySetInnerHTML={{__html: this.props.ErrorMessage}}></p>}
                </div>
            </React.Fragment>            
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ApiRunningStatus: state.uiLogicControl.Credentials.ApiRunningStatus,
        ErrorMessage: state.authentication.ErrorMessage
    };
}

export default connect(mapStateToProps)(ResetPassword);