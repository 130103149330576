import moment, { Moment, Duration } from "moment";
import { QuotaAccumulationTime, MaxRequestQuotaStored } from "./Parameters";
import { GetCurrentTime } from "../../Utils/GetCurrentTime";

/** The number of ETA requests we are allowed to make. It builds up over time. */
let quotaValue: number = MaxRequestQuotaStored;

/** When the quota was last recomputed. It is accumulating steadily over time, but we only recompute the value when it is needed in a decision. */
let quotaLastCalculatedTime: Moment = GetCurrentTime();

/** Returns true if there is enough quota available to make a new request. 
    The current value of the quota is recalculated first. */
export function IsQuotaAvailable(): boolean {

    UpdateCurrentQuotaValue();
    return quotaValue >= 1;
}

/** A request has been performed. Decrement the available quota. */
export function NoteQuotaUsage() {

    UpdateCurrentQuotaValue();
    quotaValue -= 1;
}

/** For unit tests only. Reset to defaults */
export function Internal_ResetQuota() {
    quotaValue = MaxRequestQuotaStored;
    quotaLastCalculatedTime = GetCurrentTime();
}

/** Recalculate the value of the quota based on how much time has passed since the last refresh, and the known rate of accumulation. */
function UpdateCurrentQuotaValue() {

    // how long has passed
    const intervalSinceLastCalculation: Duration = moment.duration(GetCurrentTime().diff(quotaLastCalculatedTime));
    const elapsedSeconds = intervalSinceLastCalculation.asSeconds();

    // convert to request units
    const secondsPerRequest = QuotaAccumulationTime.asSeconds();
    const increaseInQuota = elapsedSeconds / secondsPerRequest;

    // apply limits
    quotaLastCalculatedTime = GetCurrentTime();
    quotaValue += increaseInQuota;

    if (quotaValue > MaxRequestQuotaStored) {
        quotaValue = MaxRequestQuotaStored;
    }
}