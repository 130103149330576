import React, { Component } from 'react';
import './MobileBookingForm.scss';
import { ContentURL, getContentUrl } from '../../../modules/Utils/ContentURL';
import { LogEvent } from '../../../utils/LogEvent';
import { GetGpsSuccessHandler, GetGpsFailureHandler } from '../../../modules/Utils/Geolocation';
import { ShowDialogSimpleErrorMessage } from "../../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../../Utils/ErrorMessages";

interface GoogleMapMobileWrapperProps {
    ShowGps: boolean;
}

interface GoogleMapWrapperWithGpsState {
    isGpsServiceRunning: boolean;
}

/**
 * Wrapper for google map, for component <GoogleMapWrapped />, to add Gps button on the map.
 */
class GoogleMapWrapperWithGps extends Component<GoogleMapMobileWrapperProps, GoogleMapWrapperWithGpsState> {
    private timerId: any = undefined;
    private isUnmounted: boolean = false; // Prevent the success / error handlers , if component getting unmounted

    constructor(props: GoogleMapMobileWrapperProps) {
        super(props);

        this.state = { isGpsServiceRunning: false };
    }

    /** Clear timer if it is alive */
    componentWillUnmount() {
        this.isUnmounted = true;
        if ( this.timerId && this.timerId > 0 ) clearTimeout(this.timerId);
    }

    /** Make loader start running */
    TurnOnLoader() {
        this.setState({ isGpsServiceRunning: true }); 
    }

    /** Make loader not running */
    TurnOffLoader() {
        this.setState({ isGpsServiceRunning: false }); 
    }

    /**
     * Retrieve the geolocation of the device, and populate pick-up and Google map if result is good.
     */
    getGpsLocation() {

        // Prevent double click
        if (this.state.isGpsServiceRunning) return;

        LogEvent.TryGetGpsLocation();

        if (window.navigator.geolocation) {
            this.TurnOnLoader();

            this.timerId = setTimeout(() => { 
                this.TurnOffLoader();
                LogEvent.GetGpsLocationTimeout();
                ShowDialogSimpleErrorMessage(WellKnownMessageKind.GpsTimeout);
            }, 10000)

            window.navigator.geolocation.getCurrentPosition((position) => {
                clearTimeout(this.timerId);
                this.TurnOffLoader();
                if (this.isUnmounted) return;
                GetGpsSuccessHandler(position);
            }, (error) => {
                clearTimeout(this.timerId);
                this.TurnOffLoader();
                if (this.isUnmounted) return;
                GetGpsFailureHandler(error);
            });
        } 
        else { 
            LogEvent.GetGpsLocationNotSupported(window.navigator.userAgent);
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.GpsNotSupported);
        }  
    }

    render() {
        return(
            <div className="google-map-wrapper-gps">
                {this.state.isGpsServiceRunning && <img className="google-map-loader" src={getContentUrl(ContentURL.images.Loading)} width="120px" height="auto" alt="Loading" />}
                {this.props.ShowGps && <img className="gps-button" src={getContentUrl(ContentURL.images.Map.Gps)} width="65px" height="auto" onClick={() => this.getGpsLocation()}/>}
                {this.props.children}
            </div>
        );
    }
}

export default GoogleMapWrapperWithGps;