import { CategoryDefinition } from "./ContactFormCategories";
import { ContactUsState } from "./ContactFormEntities";

/**
 * Given the selected section / category from the drop downs, map the label data for capturing category information
 */
export function LoadContactCategoryDetails(selectedCategories: ContactUsState, currentData: CategoryDefinition): string[] {
    let subCategory: string = '';
    let subCategory2: string = '';
    let subCategory3: string = '';
    let subCategory4: string = '';

    const category = currentData!.subCategories![selectedCategories.selectedCategory];
    subCategory = category.label;
    const category2 = category.subCategory ? category.subCategory[selectedCategories.selectedsubCategoryDetail] : { label: '', subCategory2: [{ label: '' }] };
    subCategory2 = category2.label;
    const category3 = category2.subCategory2 ? category2.subCategory2[selectedCategories.selectedsubCategory2Detail] : { label: '', subCategory3: [{ label: '' }] };
    subCategory3 = category3.label;
    const category4 = category3.subCategory3 ? category3.subCategory3[selectedCategories.selectedsubCategory3Detail] : { label: '' };
    subCategory4 = category4.label;
    
    return [subCategory, subCategory2, subCategory3, subCategory4];
} 