import React from 'react';
import { AccessHistoryTabHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { Link } from 'react-router-dom';
import { SignUpLink } from './SignUpLink';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import FeatureCloseButton from './FeatureCloseButton';
import { Typography } from '@mui/material';
import { useAppState } from '../../Redux/ReduxHooks';
import { OptionalUI } from '../Booking/OptionalParts/OptionalUI';

/**
 * This feature will render into FeatureCard.tsx
 * Feature cards that explain the usage of the new Trip History Grid.
 */
export const TripHistoryCard: React.FC<FeatureCardProps> = (props) => {

    // app state
    const isLoggedIn = useAppState(OptionalUI.LoggedInUser);
    const isMobile = useAppState(OptionalUI.MobileLayout);

    // the feature isn't supported for mobile devices
    if (isMobile) return null;

    return (
        <React.Fragment key={FeatureId.TripHistory}>
            <div className="feature-card">
                <FeatureCloseButton {...props} />
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>04.07.2022</span>
                </div>
                <Typography variant="h6" color="primary"> EXPORT YOUR TRIP HISTORY </Typography>
                <Typography variant="body2" gutterBottom>Filter, sort and export a detailed list of your trip history into a CSV file.</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.TripHistory)} alt="Trip History" />
                </div>
                {
                    isLoggedIn ?
                    <Typography variant="body2" style={{marginBottom:"20px"}}>Simply <Link to={"/"} rel="noopener" className="feature-link" onClick={AccessHistoryTabHandler}>Click here</Link> to see. </Typography>
                    :
                    <SignUpLink />
                }
                <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}

