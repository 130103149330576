import { Brand } from "../Entities/Brand";
import { ConfigOverride } from "../Entities/ConfigOverride";
import { DeploymentEnvironment } from "../Entities/DeploymentEnvironment";

/** 
 * Brand & environment specific overrides to the default config. 
 * The overrides may not exist for each brand (first Partial{}). 
 * An override may only override some of the settings (second Partial{}). 
 */
type PerEnvironmentSettings = Partial<Record<DeploymentEnvironment, ConfigOverride>>;
export const BrandEnvConfig: Partial<Record<Brand, PerEnvironmentSettings >> = { };

/** overrides for Cabcharge */
BrandEnvConfig[Brand.CabCharge] = { }

/** overrides for Cabcharge prod */
BrandEnvConfig[Brand.CabCharge]![DeploymentEnvironment.Production] = {
    Values : {
        Auth0: {
            ClientDomain: "cabcharge.au.auth0.com",
            ClientId: "tAQC8MEqYTBCXjgL3H3gBdNRtO6EHJ2Y",
            CustomDomain: "auth.cabcharge.com.au",
            Connection: "cconline-Prod-UserName-DB"
        },
        AppInsightsKey: "c6e671fa-bc54-4412-9f98-01586fd608e2",

        // "Booking Websites Prod Shared Low Volume Only"
        GoogleMapsApiKey: "AIzaSyAPdc0iz2yz9Vp3ve7b9_PUn_Dj7ZTdB9s",
    },
};

/** overrides for Cabcharge dev */
BrandEnvConfig[Brand.CabCharge]![DeploymentEnvironment.Development] = {
    Values: {
    }
};

/** overrides for Silver Service */
BrandEnvConfig[Brand.SilverService] = {}

/** Overrides for Silver Service prod. */
BrandEnvConfig[Brand.SilverService]![DeploymentEnvironment.Production] = {
    Values: {
        ApiBaseUrl: "https://invc-mgmt-api-prod.azurewebsites.net/",
        AppInsightsKey: "b36c7ba2-3390-4af8-9794-fdf65d4ff77f",

        // "Booking Websites Prod Shared Low Volume Only"
        GoogleMapsApiKey: "AIzaSyAPdc0iz2yz9Vp3ve7b9_PUn_Dj7ZTdB9s",
        TrackingLinkBaseUrl: "https://book.silverservice.com.au/b/"
    }
};

/** Overrides for Silver Service Dev. */
BrandEnvConfig[Brand.SilverService]![DeploymentEnvironment.Development] ={
    Values: {
        TrackingLinkBaseUrl: "https://silverservice.mtidev.net/b/",
    }
};

/** overrides for Book 360 */
BrandEnvConfig[Brand.Book360] = { }

/** overrides for Book 360 pre-production */
BrandEnvConfig[Brand.Book360]![DeploymentEnvironment.Preprod] = {
    Values: {
        ApiBaseUrl: "https://invc-mgmt-api-dev-gb-preprod.azurewebsites.net/",
        Auth0: {
            ClientDomain: "cabcharge.au.auth0.com",
            ClientId: "tAQC8MEqYTBCXjgL3H3gBdNRtO6EHJ2Y",
            CustomDomain: "auth.cabcharge.com.au",
            Connection: "cconline-Prod-UserName-DB"
        },
        TrackingLinkBaseUrl: "https://labs.cabcharge.com.au/b2/"
    }
};

BrandEnvConfig[Brand.OneThreeCabs] = {}
BrandEnvConfig[Brand.OneThreeCabs]![DeploymentEnvironment.Development] = {
    Features: {
        //LegalDocumentsV2: true,
    }
}

BrandEnvConfig[Brand.OneThreeCabs]![DeploymentEnvironment.Production] = {
    Values: {

        // "Booking Website 13cabs Prod"
        GoogleMapsApiKey: "AIzaSyBY2Cj93BJUkD-uCAL8xhZUJJMd8AicPuY",
    }
}