import { ServiceResult, ServiceCallOutcome } from "../../Services/ServiceEntities";
import { AsyncLoadState, DataLoadingStatus } from "./Redux/ConditionEntities";

/** Analyse the result, work out if successful, etc. */
export function ComputeAsyncLoadState<TInput>(result: ServiceResult<unknown>, input: TInput): AsyncLoadState<TInput> {

    // well-known API error
    if (result.outcome == ServiceCallOutcome.ApplicationError) {
        return {
            Status: DataLoadingStatus.Error,
            ErrorMessage: result.apiError.Summary.FullMessage,
            LastInput: input,
        };
    }

    // unanticipated error
    if (!result.isSuccess) {
        return {
            Status: DataLoadingStatus.Error,
            ErrorMessage: "An unexpected error occurred",
            LastInput: input,
        };
    }

    // OK!
    return {
        Status: DataLoadingStatus.Idle,
        LastInput: input,
    };
}