import { Dispatch } from "../../Dispatch";
import { Api } from "../../../Services/Api";
import { BookingInfo, PODImageDetails } from "../MyBookingEntities";
import { HasMaxPollingPeriodExceeded } from "./PodRefreshValidations";
import { BookingAuthority, BookingStatus } from "../../../Services/BookingEntities";

/**
 * Allow only completed delivery bookings to load proof of delivery image urls.
 */
export async function ConsiderRefreshingPODImageUrls(booking: BookingInfo) {

    if (booking.TrackingInfo.BookingStatusID != BookingStatus.Completed) return;

    if (HasMaxPollingPeriodExceeded(booking)) return;

    await PerformPODImageUrlRefresh(booking);
}

/**
 * Loads the image urls for the completed delivery bookings and save it in the store.
 */
export async function PerformPODImageUrlRefresh(booking: BookingInfo) {

    const bookingKey: BookingAuthority = {
        hashCode: booking.AccessCode,
        bookingId: booking.BookingID
    };

    // service call to get delivery images
    const serviceResult = await Api.Booking.GetProofOfDeliveryImageUrls(bookingKey);

    if (serviceResult.isSuccess) {

        const podDetails: PODImageDetails = {
            BookingId: booking.BookingID,
            ImageUrls: serviceResult.value
        }

        Dispatch.MyBookings.AddPODImageUrls(podDetails);
    }
}