import { BookingWithEta, GetEffectiveEta } from "./BookingWithEta";
import { BookingStatus } from "../../../Services/BookingEntities";
import { PickedUpPriorityMultiplier } from "./Parameters";
import { GetCurrentTime } from "../../Utils/GetCurrentTime";

/** Returns the booking that is most in need of an ETA refresh. 
 *  Based on the previous ETA load time and taxi movement since then. */
export function GetTopPriorityEtaRefresh(bookings: BookingWithEta[]): BookingWithEta {

    var sortedBookings = bookings.sort(CompareBookingEtaPriority);
    return sortedBookings[0];
}

/** Comparison function suitable for sorting by priority. */
function CompareBookingEtaPriority(left: BookingWithEta, right: BookingWithEta): number {

    // priority: ETA missing
    const leftEta = GetEffectiveEta(left);
    const rightEta = GetEffectiveEta(right);

    if (leftEta && !rightEta) return 1;
    if (rightEta && !leftEta) return -1;

    // both ETA missing
    if (!leftEta && !rightEta) {

        // priority: accepted first
        if (left.Booking.TrackingInfo.BookingStatusID != right.Booking.TrackingInfo.BookingStatusID) {
            return left.Booking.TrackingInfo.BookingStatusID == BookingStatus.Accepted ? -1 : 1;
        }

        // doesn't matter at this point
        return 0;
    }

    // at this point, both bookings have ETAs. Compiler can't figure that out so I will be using ! a bit from now. 
    let leftAge = GetCurrentTime().diff(leftEta!.TimeOfCalculation);
    if (left.Booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) {
        leftAge = leftAge * PickedUpPriorityMultiplier;
    }

    let rightAge = GetCurrentTime().diff(rightEta!.TimeOfCalculation);
    if (right.Booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) {
        rightAge = rightAge * PickedUpPriorityMultiplier;
    }

    // we want times to be in ascending order (x - y), so age must be in descending order (y - x)
    return rightAge - leftAge;
}