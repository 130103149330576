import { Reducer } from 'redux';
import { UserProfileNavState, DefaultNavState } from './UserProfileNavState';
import { NavAction, UserProfileNavActionTypes } from './UserProfileNavActions';
import { UserProfileNavbarItem } from "./UserProfileNavEntities";

export const UserProfileNavReducer: Reducer<UserProfileNavState, NavAction> = (state = DefaultNavState, action) => {

    switch (action.type) {
        case UserProfileNavActionTypes.SelectUserProfileNavItem:
            return HandleSelectUserProfileNavItem(state, action.item);
        default:
            return state;
    }
}

/** Handler for the SelectItem action. */
function HandleSelectUserProfileNavItem(state: UserProfileNavState, item: UserProfileNavbarItem): UserProfileNavState {
    return {
        UserProfileSelectedNav: item
    };
}
