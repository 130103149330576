import { UserProfileNavbarItem } from "./UserProfileNavEntities";
import {  UserProfileNavActionTypes } from "./UserProfileNavActions";
import appstore from "../../appStore";

export class UserProfileNavDispatcher {

    public SelectNavItem(item: UserProfileNavbarItem): void {
        appstore.dispatch({
            type: UserProfileNavActionTypes.SelectUserProfileNavItem,
            item,
        });
    }
}