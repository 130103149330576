import { PrefillOptions, PrefillTip, StartupFeature } from "./PrefillOptions";
import queryString from "query-string";
import services from "../../utils/service-vehicles/ServiceMetadata";
import { GetServiceByUrlRef } from "../../utils/service-vehicles/LookupServices";

/**
 * Reads all well-known query string values for booking prefill into a strongly typed object to facilitate further processing.
 */
export function ReadUrlPrefillData(): PrefillOptions {

    // raw parsed query string
    const queryStr = window.location.search;
    const values = queryString.parse(queryStr);

    const output: PrefillOptions = {};

    // service
    if (values.service === "clickcollect") {
        output.Service = services.parcel;
        output.ScenarioTip = PrefillTip.ClickAndCollect;
    }
    else if (values.service === "passengertrip") {
        output.ScenarioTip = PrefillTip.PassengerTrip;
    }
    else if (values.service) {
        output.Service = GetServiceByUrlRef(values.service.toString());
    }

    // "feature" to trigger special behaviours
    if (values.feature && (values.feature.toString() == "fareestimate")) {
        output.Feature = StartupFeature.FareEstimate;
    }

    // "tip" is the preferred way to trigger click and collect / passenger dialogs
    if (values.tip) {
        if (values.tip === "cnc") {
            output.Service = services.parcel;
            output.ScenarioTip = PrefillTip.ClickAndCollect;
        }
        else if (values.tip === "pax") {
            output.ScenarioTip = PrefillTip.PassengerTrip;
        }
    }

    // "name" for Customer Name
    if (values.name) {
        output.CustomerName = values.name.toString();
    }

    // "phoneNumber"
    if (values.phoneNumber) {
        output.PhoneNumber = values.phoneNumber.toString();
    }

    // driver notes: "pickupInstruction" and "dropoffInstruction"
    if (values.pickupInstruction) {
        output.PickupInstruction = values.pickupInstruction.toString();
    }

    if (values.dropoffInstruction) {
        output.DropoffInstruction = values.dropoffInstruction.toString();
    }

    // addresses: "pickup" and "dropoff"
    if (values.pickup) {
        output.PickupAddress = values.pickup.toString();
    }

    if (values.dropoff) {
        output.DropoffAddress = values.dropoff.toString();
    }

    // "orderNumber"
    if (values.orderNumber) {
        output.OrderNumber = values.orderNumber.toString();
    }

    return output;
}