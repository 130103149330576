import React from "react";
import { Dispatch } from "../../Dispatch";
import { Button } from "@mui/material";
import { useAppState } from "../../../Redux/ReduxHooks";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";
import { GetValues } from "../../../Config/MyAppConfig";
import "./WatsOnXmasDayMessage.scss";
import { OptionalUI } from "../OptionalParts/OptionalUI";

/** Dialog asking the user to call contact center to book a wheelchair accessible taxi on the Christmas day. This is due to the high demand on that day. */
export const WatsOnXmasDayMessage: React.FC = () => {

    const isOnMobile = useAppState(OptionalUI.MobileLayout);
    const buttonText = isOnMobile ? `Call ${GetValues().BrandName}` : "OK";

    function DismissDialog() {
        Dispatch.Dialog.CloseTopDialog();
        if (isOnMobile) {
            document.location.href = `tel:${GetValues().BrandContactNumber}`;
        }
    }

    return (
        <div className="error-message-base-panel small-non-error-info-dialog">
            <div className="wats-on-xmas-day-title emb-problem-panel">Please call to request a wheelchair accessible taxi</div>
            <div className="wats-on-xmas-day-description emb-solution-panel error-text">We are experiencing a high volume of requests for wheelchair accessible taxis, please call {GetValues().BrandContactNumber}.</div>
            <div className="wats-on-xmas-day-button emb-btn-panel dismiss-button">
                <Button variant="contained" color="primary" onClick={DismissDialog}>{buttonText}</Button>
            </div>
        </div>
    )
}