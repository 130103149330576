import { BookingStatus } from "../../../Services/BookingEntities";
import { ExtendedEta, EtaKind, BookingInfo } from "../MyBookingEntities";

/** Helper entity to combine the two pieces of data in the MyBookings store: booking and ETA. */
export interface BookingWithEta {

    Booking: BookingInfo;
    Eta?: ExtendedEta;
}

/** Handles the case where a booking has an ETA, but the ETA was the for the pickup and we now want a dropoff ETA. 
 * Effectively, there is no ETA available in this case. */
export function GetEffectiveEta(details: BookingWithEta): ExtendedEta | null {

    if (!details.Eta) return null;

    // this is the only direction in which the ETA can be the wrong way around
    if ((details.Booking.TrackingInfo.BookingStatusID == BookingStatus.PickedUp) && (details.Eta.Kind == EtaKind.ToPickup)) {
        return null;
    }

    return details.Eta;
}