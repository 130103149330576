import React from 'react';
import { AccessSaveAddressListHandler } from './FeaturesHelper';
import { getContentUrl, ContentURL } from '../Utils/ContentURL';
import { FeatureId, FeatureCardProps } from './FeaturesEntities';
import { Link } from 'react-router-dom';
import { SignUpLink } from './SignUpLink';
import AcknowledgeFeatureButton from './AcknowledgeFeatureButton';
import FeatureCloseButton from './FeatureCloseButton';
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { ApplicationState } from '../../appState';
import { connect } from 'react-redux';
import { IsMobileDevice } from '../../utils/DetectDevice';
import { RouteUrls } from '../../RouteUrls';
import { Typography } from '@mui/material';

interface PropsFromStore {
    LoginStatus: LoginStatusKind;
}

/**
 * This feature will render into FeatureCard.tsx
 * Feature cards that explain the usage of saved addresses.
 */
const SaveAddresses = (props: FeatureCardProps & PropsFromStore) => {

    const isLoggedInUser = props.LoginStatus === LoginStatusKind.LoggedIn;

    const savedAddressUrl = IsMobileDevice() ? RouteUrls.AddressPoints : RouteUrls.UserProfile;

    return (
        <React.Fragment key={FeatureId.SaveAddresses}>
            <div className="feature-card">
                <FeatureCloseButton {...props} />
                <div className="feature-date">
                    <span className="feature-date-img"></span>
                    <span>15.02.2022</span>
                </div>
                <Typography variant="h6" color="primary"> SAVE YOUR ADDRESSES </Typography>
                <Typography variant="body2" gutterBottom>Book a trip faster with saved addresses. Search your saved places by name when booking your next trip!</Typography>
                <div className="feature-image">
                    <img src={getContentUrl(ContentURL.images.WhatsNew.SaveAddresses)} alt="Save Addresses" />
                </div>
                {
                    isLoggedInUser ?
                    <Typography variant="body2" style={{marginBottom:"20px"}}>Simply <Link to={savedAddressUrl} rel="noopener" className="feature-link" onClick={() => AccessSaveAddressListHandler()}>Click here</Link> to access your saved address list. </Typography>
                    :
                    <SignUpLink />
                }
                <AcknowledgeFeatureButton {...props} />
            </div>
        </React.Fragment>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        LoginStatus: state.authentication.LoginStatus
    };
}

export default connect(mapStateToProps)(SaveAddresses);
