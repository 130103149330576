import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { TabState, DefaultTabState } from "./TabState";
import { TabItem } from "./TabEntities";

const slice = new ReduxStoreSlice<TabState>("Tabs", DefaultTabState);

/** Dispatcher for actions in the Tab state slice. */
export const TabDispatch = {
    SelectItem: slice.Action("Select Tab", SelectItem)
};

/** Reducer for the Tab store slice */
export const TabReducer = slice.MakeCombinedReducer();

/** Handler for the selected tab action. */
function SelectItem(state: TabState, payload: TabItem): TabState {
    return {
        ...state,
        SelectedTab: payload
    }
}
