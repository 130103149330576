import React from "react";
import { GridColDef } from '@mui/x-data-grid';
import { BookingInfo } from "../MyBookings/MyBookingEntities";
import { DateTime } from "luxon";
import { BookingStatus } from "../../Services/BookingEntities";
import { GenerateBookingTrackingUrl } from "../Booking/BookingHelper";
import { GetValues } from "../../Config/MyAppConfig";
import { PopulateSelectedTripDetails } from "../Booking/QuickBook/PopulateSelectedTripDetails";
import { RecentTripButtonKind } from "../UILogicControl/UILogicControlEntities";
import { QuickBookSource } from "../Booking/QuickBook/QuickBookEntities";
import { Button } from "@mui/material";

/** 
 *  The date is a bit special: BookingInfo has [Time] and [LocalTime].
 *  [LocalTime] is preferred and ISO 8601 formatted, but not always populated.
 *  [Time] has a non-standard format.
 *  The value type is DateTime (Luxon), which isn't supported out of the box, so we need a manual sort function. Format is also custom.
 */
const dateColumn: GridColDef<BookingInfo, DateTime, string> = {
    field: "DateAndTime",
    headerName: "Date and time",
    flex: 2,
    valueGetter: (params) => {
        const booking = params.row;

        if (booking.LocalTime) {
            return DateTime.fromISO(booking.LocalTime);
        }
        else {
            return DateTime.fromFormat(booking.Time, "MM/dd/yyyy HH:mm:ss")
        }
    },
    valueFormatter: (params) => params.value.toFormat("ccc dd MMM yyyy h:mma"),
    sortComparator: (v1, v2) => v1.toSeconds() - v2.toSeconds(),
};

/** 
 *  Booking Status. Raw value is an enum. Simplified string text.
 *  Custom cell highlighting for active bookings.
 */
const statusColumn: GridColDef<BookingInfo, BookingStatus, string> = {
    field: "Status",
    headerName: "Status",
    flex: 2,
    valueGetter: (params) => (params.row.TrackingInfo.BookingStatusID),
    valueFormatter: (params) => {
        const statusID = params.value;

        if (statusID == BookingStatus.Cancelled) {
            return "Trip cancelled";
        } else if (statusID == BookingStatus.Completed) {
            return "Trip completed";
        } else if (statusID == BookingStatus.NoJob) {
            return "Passenger not found";
        }
        return "Booked";
    },
    renderCell: (params) => {
        const isActive = params.formattedValue == "Booked";
        const cssClass = isActive ? "active-booking-status" : "";

        return <div className={cssClass}>{params.formattedValue}</div>
    }
};

/** 
 *  Trip Tracking link
 *  Not a data column; just renders a HTML link.
 */
const tripLinkColumn: GridColDef<BookingInfo> = {
    field: "Triplink",
    headerName: "Trip link",
    flex: 1,
    disableExport: true, sortable: false, filterable: false,
    renderCell: (params) => {
        const booking = params.row;
        const url = GenerateBookingTrackingUrl(booking.BookingID.toString(), booking.AccessCode, GetValues().TrackingLinkBaseUrl);

        return <a href={url} target="_blank">Link</a>
    }
};

/**
 * Non-data column showing a "Book again" button.
 */
const bookAgainColumn: GridColDef<BookingInfo> = {
    field: "BookAgain",
    headerName: "Action",
    flex: 2,
    disableExport: true, sortable: false, filterable: false,
    renderCell: (params) => (
        <Button
            variant="outlined"
            size="small"
            sx={{
                // TODO
            }}
            onClick={() => PopulateSelectedTripDetails(RecentTripButtonKind.BookAgain, params.row, QuickBookSource.TripHistory)} >
            Book again
        </Button>
    ),
};

/** Display 'multi-stop' label if the booking has more than two locations. */
const multiStopColumn: GridColDef<BookingInfo> = {
    field: "MultiStop",
    headerName: "",
    flex: 2,
    renderCell: (params) => {
        return <div className="multi-stop-text">{params.row.Locations.length > 2 ? "multi-stop" : ""}</div>
    }
};

/** Pickup address is extracted from the first location in the Locations array. */
const pickupAddressColumn: GridColDef<BookingInfo> = {
    field: "PickupText",
    headerName: "Pickup",
    flex: 3,
    valueGetter: (params) => {
        return params.row.Locations[0].AddressText;
    },
};

/** Destination address is extracted from the last location in the Locations array. */
const destinationAddressColumn: GridColDef<BookingInfo> = {
    field: "DestinationText",
    headerName: "Destination",
    flex: 3,
    valueGetter: (params) => {
        const locations = params.row.Locations;
        return locations[locations.length - 1].AddressText;
    },
};

/** The set of columns for the Taxi History grid */
export const TaxiHistoryGridColumns: GridColDef<BookingInfo>[] = [

    { field: "DispatchBookingID", headerName: "Booking#", flex: 2 },
    { field: "PassengerName", headerName: "Passenger name", flex: 2 },
    dateColumn,
    pickupAddressColumn,
    multiStopColumn,
    destinationAddressColumn,
    statusColumn,
    tripLinkColumn,
    bookAgainColumn,
];