import { VerificationDialogInput, VerificationDialogResult } from "./Verification/UIEntities";
import { DialogResult, DialogSetup } from "./Entities";
import { MakeDecomposedPromise, DecomposedPromise } from "./PromiseDecomposition";
import { Dispatch } from "../Dispatch";
import { RemoveDialogInput, RemoveDialogResult } from "./BookingTemplate/RemoveTemplateEntities";
import { PaymentFailureInput } from "./PaymentFailure/PaymentFailureEntities";

/** await-able dialog components */
export const ShowDialog = {

    /** Mobile Phone SMS Code verification input dialog. */
    Verification(input: VerificationDialogInput): Promise<DialogResult<VerificationDialogResult>> {

        return RunDialog(input, Dispatch.DialogV2.Verification);
    },

    /** Confirmation dialog to Remove Booking Template. */
    RemoveBookingTemplate(input: RemoveDialogInput): Promise<DialogResult<RemoveDialogResult>> {
        return RunDialog(input, Dispatch.DialogV2.RemoveTemplate)
    },

    /** Booking cancelled due to payment failure message dialog. */
    PaymentFailure(input: PaymentFailureInput): Promise<DialogResult<null>> {
        return RunDialog(input, Dispatch.DialogV2.PaymentFailure)
    }
}

/**
 * Creates a new promise that the consumer will await.
 * Generates callbacks for the dialog X button and dialog inner component to resolve the promise - they each have different payloads.
 * Fires a Redux action to put this data into the store and cause the dialog to start rendering.
 * Returns the promise object that the consumer will await.
 */
async function RunDialog<TInput, TOutput>(input: TInput, actionDispatcher: (payload: DialogSetup<TInput, TOutput>) => void): Promise<DialogResult<TOutput>> {

    const promiseParts = MakePromiseParts<TOutput>();
    const setup: DialogSetup<TInput, TOutput> = {
        Props: {
            Input: input,
            CompletionCallback: promiseParts.CompletionCallback,
        },
        CancelCallback: promiseParts.CancelCallback,
    };

    // show the dialog
    actionDispatcher(setup);

    // wait for it to resolve
    const result = await promiseParts.DecomposedPromise.Promise;

    // hide the dialog
    Dispatch.DialogV2.Close();

    return result;
}

/**
 * Helper method to manufacture some data we need for each dialog.
 * We start with a decomposed promise, which comes with its own resolver function.
 * The resolve handles two separate scenarios:
 *  1) dialog cancel (no payload)
 *  2) dialog complete (output payload)
 * We generate two separate resolvers, one for each of these cases.
 */
function MakePromiseParts<TDialogResult>(): PromiseAndCallbacks<TDialogResult> {

    const decomposedPromise = MakeDecomposedPromise<DialogResult<TDialogResult>>();
    const cancelCallback = () => decomposedPromise.Resolver({
        DidUserCancel: true,
    });
    const successCallback = (output: TDialogResult) => decomposedPromise.Resolver({
        DidUserCancel: false,
        Output: output,
    });

    return {
        DecomposedPromise: decomposedPromise,
        CancelCallback: cancelCallback,
        CompletionCallback: successCallback,
    };
}

/** Promise and callback parts that will be used with each displayed dialog. */
interface PromiseAndCallbacks<TDialogResult> {

    /** The underlying promise and its resolver function */
    DecomposedPromise: DecomposedPromise<DialogResult<TDialogResult>>;

    /** A callback for the cancel scenario to use. It will resolve the promise with a DialogCanceled value */
    CancelCallback: () => void;

    /** A callback for the dialog component to use. It will resolve the promise with a DialogCompleted<TDialogResult> value */
    CompletionCallback: (output: TDialogResult) => void;
}