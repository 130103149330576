import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";

/** Load current user's address points from the API. */
export async function LoadMyAddressPoints() {
    var result = await Api.AddressPoints.GetMyAddressPoints();

    if (!result.isSuccess) return;

    Dispatch.AddressPoints.Refresh(result.value);
}