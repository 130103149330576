
/** The store state in the "ApiFailureTesting" slice. */
export interface ApiFailureTestingState {

    /** Global switch to enable the failure testing behaviour */
    IsEnabled: boolean;

    /** When true, a delay is added to all API calls. */
    IsDelayEnabled: boolean;

    /** The artificial latency to add to API calls. */
    DelayMilliseconds: number;

    /** When true, all API calls will return a failure instead of running. */
    IsFailureModeEnabled: boolean;

    /** When Failure Mode behaviour is enabled, what type of error will occur. */
    FailureMode: ApiFailureMode;
}

/** Different API failure behaviours. These might trigger different UI errors. */
export enum ApiFailureMode {
    Timeout = 'Timeout',
    UnableToConnect = 'Connection',
    BusinessRule = 'Business Rule',
}

/** Initial state for API Failure Testing. Basically, everything turned off. */
export const InitialApiFailureTestingState: ApiFailureTestingState = {
    IsEnabled: false,
    IsDelayEnabled: false,
    DelayMilliseconds: 3000,
    IsFailureModeEnabled: false,
    FailureMode: ApiFailureMode.Timeout,
}