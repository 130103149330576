import { ApiError } from "./ApiContracts";

/** The return value of a call to the Booking Management Service with a payload data type {T} */
export type ServiceResult<T> = FailureResult | SuccessResult<T>;

/** The possible types for a failed call to the Booking Management service */
export type FailureResult = NetworkFailResult | WebServerErrorResult | ApplicationErrorResult;

/** The result where the request doesn't complete: timeout or network down */
export interface NetworkFailResult {
    outcome: ServiceCallOutcome.NoResponseFromServer;
    isSuccess: false;

    /** True for timeout, false for unable to connect (we presume) */
    isTimeout: boolean;
}

/** The result where the web server returns an HTTP error, e.g. 404 for invalid URL */
export interface WebServerErrorResult {
    outcome: ServiceCallOutcome.WebServerError;
    isSuccess: false;
    httpStatus: HttpStatus;
}

/** The result where the application (booking management service) returns an error */
export interface ApplicationErrorResult {
    outcome: ServiceCallOutcome.ApplicationError;
    isSuccess: false;
    httpStatus: HttpStatus;
    apiError: ApiError;
}

/** Successful result with a payload of type {T}. */
export interface SuccessResult<T> {
    outcome: ServiceCallOutcome.Success;
    isSuccess: true;
    value: T;
}

/** This categorises the major response types. It can be used as a discriminator for the compound type ServiceResult<T>. */
export enum ServiceCallOutcome {

    /** Timeout or unable to connect */
    NoResponseFromServer = "No Response From Server",

    /** HTTP non-success raised by the web server, typically 404 / 500. No ApiResponse will be available. */
    WebServerError = "Web Server Error",

    /** HTTP non-success raised by the web API. 500 but wrapped in an ApiResponse{T} */
    ApplicationError = "Application Error",

    /** HTTP 200-299. Will be wrapped in an ApiResponse{T} as well. */
    Success = "Success",
}

/** HTTP Status Code and Text. Available from any HTTP request that completes (i.e. not timeout) */
export interface HttpStatus {
    Code: number;
    Text: string;
}