import React, { Component } from 'react';
import './SignupPromotionPopup.scss';
import { Dispatch } from '../../Dispatch';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';

class SignupPromotionPopup extends Component<{}> {
    onClickClose() {
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.Dialog.CleanSimpleErrorMessage();
    }

    render() {
        return(
            <div className="signup-promotion-popup-panel">
                <h1 className="signup-promotion-popup-header">Why not sign up?</h1>
                <p className="signup-promotion-popup-description">An email is all we need.</p>

                <div className="signup-promotion-popup-images-group">
                   <div className="signup-promotion-popup-image-box box-center">
                        <img src={getContentUrl(ContentURL.images.Login.MobileVerification)} alt="Stop verification" width="auto" height="80px"/>
                        <p><span>Stop</span> having to verify each</p><p>booking</p>
                    </div>

                    <div className="signup-promotion-popup-image-box">
                        <img src={getContentUrl(ContentURL.images.Login.FastBooking)} alt="Fast booking" width="auto" height="80px"/>
                        <p><span>Past</span> booking with recents.</p><p>Booking again and return.</p>
                    </div>
                    
                    <div className="signup-promotion-popup-image-box">
                        <img src={getContentUrl(ContentURL.images.Login.AddCreditCard)} alt="Add credit card" width="auto" height="80px"/>
                        <p><span>Add</span> Credit cards and</p><p>assign to bookings</p>
                        
                    </div>
                </div>

                <div className="signup-promotion-popup-btn-group">
                    <button className="signup-promotion-btn-common signup-promotion-btn-skip" onClick={this.onClickClose}>Skip for now</button>
                    <button className="signup-promotion-btn-common signup-promotion-btn-proceed" onClick={this.onClickClose}>Sign up</button>
                </div>
            </div>
        );
    }
}

export default SignupPromotionPopup;