import { AddressPointDetails } from "../../../Services/AddressPointsContracts";

/** An entry in the suggestions list for an address picker. */
export type AddressPickerEntry = GoogleAddressEntry | FavouriteAddressEntry;

/** An address picker entry representing a Google Maps autocomplete prediction */
export interface GoogleAddressEntry {

    /** Discriminator field for the type union */
    Kind: AddressEntryKind.GoogleMaps;

    /** Google Maps address autocomplete suggestion metadata */
    Suggestion: google.maps.places.AutocompletePrediction;
}

/** An address picker entry representing a previously saved favourite */
export interface FavouriteAddressEntry {

    Kind: AddressEntryKind.Favourite;

    /** A favourite address (name, address, place ID, etc) */
    Favourite: AddressPointDetails;
}

/** Discriminator for the AddressPickerEntry type */
export enum AddressEntryKind {

    /** An address entry sourced from Google Maps API */
    GoogleMaps = 'Google',

    /** An address entry that the user previously saved as a favourite */
    Favourite = 'Favourite',
}