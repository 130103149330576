import { Button } from "@mui/material"
import React from "react"
import { DialogKind } from "../../Dialog/DialogEntities"
import { Dispatch } from "../../Dispatch"
import { BrandedImage, GetBrandedUrl } from "../../Utils/BrandedContentUrls"
import { AddressPointModalType } from "../Redux/AddressPointsEntities"

/** Confirmation after successfully adding a new saved address. */
export const AddAddressConfirmation: React.FC<{}> = () => {

    /** Close the confirmation dialog and opens Add Address dialog to add a new address. */
    function AddAnotherAddress() {
        Dispatch.Dialog.CloseTopDialog();
        Dispatch.Dialog.ShowDialog(DialogKind.CreateAddressPoint);
        Dispatch.AddressPoints.SetUiEditModeType(AddressPointModalType.Create);
    }

    function CloseDialog() {
        Dispatch.Dialog.CloseTopDialog();
    }

    return (
        <div className="address-points-dialog-main-panel">
            <h1 className="confirmation-title">Thanks, your address has been added.</h1>
            <p className="confirmation-text">You can edit the address at any time within your profile.</p>
            <img className="confirmation-illustration" src={GetBrandedUrl(BrandedImage.GoodNewsMan)} alt="" />
            <div className="confirmation-footer">
                <Button variant="contained"
                    color="primary"
                    onClick={AddAnotherAddress}>
                    Add another address
                </Button>                     
                <Button variant="outlined"                    
                    onClick={CloseDialog}>
                    I'm good to go
                </Button>
            </div>
        </div>
        )
}