import React from "react";
import { Dispatch } from "../Dispatch";
import { DialogKind } from "../Dialog/DialogEntities";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import "./ClickAndCollect.scss";
import { Button } from "@mui/material";
import MobileStepper from '@mui/material/MobileStepper';

/** Popup that displays instructions to make a booking when the user opens the site through a 'Click and Collect' URL. */
export const ClickAndCollectInfo: React.FC<{}> = () => {
    const [activeStep, setActiveStep] = React.useState(0);

    function ClosePopup() {
        Dispatch.Dialog.CloseDialog(DialogKind.ClickAndCollectInfo);
    }

    function handleNext() {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    function handleBack() {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const step1 = (
        <div className="click-and-collect-info-content-step1">
            <h1 className="orange-header">Welcome!</h1>
            <img className="click-and-collect-logo" src={getContentUrl(ContentURL.images.ClickAndCollect.Logo)}></img>
            <div className="click-and-collect-instructions">
                <p>Please note that <span className="strong-text">Parcel Contact Name and Number </span> must be as in your order.</p>
                <p className="instructions-level2">When booking, please make sure to add your <span className="strong-text"> order/invoice number</span> to the driver instruction.</p>
            </div>
        </div>
    );

    const step2 = (
        <div className="click-and-collect-info-content-step2">
            <h1 className="orange-header">Size requirements</h1>
            <p className="instructions-summary"> If it fits in a cab, no larger than 1.05m and not heavier than 15kg we can deliver it. </p>
            <div className="click-and-collect-instructions">
                <p>Items that aren't flat packed and cannot be adequately secured inside the cab can not be delivered. Please note, 13cabs drivers will not pick up any illegal items or dangerous goods.</p>
                <p className="instructions-level2">By clicking OK, you authorise 13cabs to deliver this order under the <a href="https://www.13cabs.com.au/terms-conditions/">Terms and Conditions</a> of Delivery.</p>
            </div>
        </div>
    );

    // Put all the steps' content in an array so it's easy to display the content based on the array index. This gives the flexibility to have many steps as possible (if required, but not good UX)
    const stepContents = [step1, step2];

    return (
        <div className="click-and-collect-info">
            { stepContents[activeStep] }
            <MobileStepper
                variant="dots"
                steps={2}
                position="static"
                activeStep={activeStep}
                style={{ backgroundColor: "#fff" }}
                nextButton={
                    activeStep === 0 ?
                        <Button size="large" variant='contained' color='primary' onClick={handleNext}>
                            Next
                        </Button>
                        :
                        <Button onClick={ClosePopup} size="large" variant='contained' color='primary'>OK</Button>
                }
                backButton={
                    <Button size="large" onClick={handleBack} disabled={activeStep === 0}>
                            Back
                    </Button>
                }
                />
            </div>
        );
}