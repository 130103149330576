
/** 
 *  Preview Feature IDs that are supported (understood) by the website.
 *  These values correspond to IDs from the API's database.
 */
export enum PreviewFeatureId {

    /** Detailed parcel widget and taxi vs parcel form selector */
    ParcelDeliveryWidget = 1,

    /** Add parcel delivery options which require proof of delivery photo, signature, or proof of age for restricted items. */
    EndOfDelivery = 2,

    /** Add parcel delivery option to deliver alcohol. */
    AlcoholDelivery = 3,

    /** Include Google ODRD Trip IDs in CreateBooking requests */
    GoogleOdrdTripIdInCreates = 4,

    /** Include Azure B2C Login in Guest/Sign-In user flow based on request */
    AzureB2CLogin = 5,

    /** Replace Contact Us Form with V2 implementation */
    ContactUsFormV2 = 6,

    /** When enabled, display fare estimate range so that the fixed fare is closer to the lower bound (min value) of the range instead of the middle value (50%). e.g: min value = fixed fare - 5%; max value = fixed fare + 15% */
    FareRangeV2 = 7,

    /** 
     * Allows registering a PayPal payment method for signed in users.
     * Does not prevent its display or usage after already registered. 
     */
    AddPayPalSignedIn = 8,

    /**
     * Allows registering a PayPal payment method for guest users.
     */
    AddPayPalGuest = 9,

    /**
     * Allows registering an Apple Pay payment method for signed in users.
     */
    AddApplePaySignedIn = 10,

    /**
     * Allows registering an Apple Pay payment method for guest users.
     */
    AddApplePayGuest = 11,

    /** Disallow CNP payment for metered price bookings. */
    DisallowCnpMetered = 12,

    /**
     * Allows registering a Google Pay payment method for signed in users.
     */
    AddGooglePaySignedIn = 13,

    /**
     * Allows registering a Google Pay payment method for guest users.
     */
    AddGooglePayGuest = 14,

    /** Hide Fixed Price toggle for guest users in Adelaide. */
    HideFixedPriceToggle = 16,

    /** Enable 3DS verification for payment cards. */
    ThreeDSecureVerification = 17,

    /** Allows guest users to make bookings with credit/debit cards without signing in. */
    GuestCreditCardBookings = 18,
}

/** Client-owned metadata about each preview feature. */
export interface PreviewMetadata {

    /** Short name (~4 words) for the preview */
    DisplayName: string;

    /** Extended description explaining what the preview will do. */
    Description: string;

    /** Set this flag to restrict visibility to account users. */
    AccountUsersOnly?: true;
}

/** How the preview features are displayed in the list. */
export const LookupPreviewMetadata: Record<PreviewFeatureId, PreviewMetadata> = {
    [PreviewFeatureId.ParcelDeliveryWidget]: {
        DisplayName: "Parcel delivery",
        Description: "Access our new parcel delivery feature to add pickup and dropoff contact details and driver notes for each location.",
    },
    [PreviewFeatureId.EndOfDelivery]: {
        DisplayName: "Proof of delivery",
        Description: "Choose among several options for proof of delivery. Options include a photo of the delivered items, captured signature or proof of age at dropoff.",
        AccountUsersOnly: true,
    },
    [PreviewFeatureId.AlcoholDelivery]: {
        DisplayName: "Alcohol delivery",
        Description: "Use this feature for same-day alcohol delivery. The recipient must be 18 years of age or over.",
        AccountUsersOnly: true,
    },
    [PreviewFeatureId.GoogleOdrdTripIdInCreates]: {
        DisplayName: "New Mapping Features",
        Description: "This is meant to be hidden, sorry!",
    },
    [PreviewFeatureId.AzureB2CLogin]: {
        DisplayName: "Azure B2C Login",
        Description: "This is meant to be hidden, sorry!",
    },
    [PreviewFeatureId.ContactUsFormV2]: {
        DisplayName: "Contact Us Form Version 2",
        Description: "This is meant to be hidden, sorry!",
    },
    [PreviewFeatureId.FareRangeV2]: {
        DisplayName: "Fare range V2",
        Description: "This is meant to be hidden, sorry!",
    },
    [PreviewFeatureId.AddPayPalSignedIn]: {
        DisplayName: "Add PayPal",
        Description: "Add your PayPal account as a payment method.",
    },
    [PreviewFeatureId.AddPayPalGuest]: {
        DisplayName: "Add PayPal (Guest)",
        Description: "Add your PayPal account as a payment method.",
    },
    [PreviewFeatureId.AddApplePaySignedIn]: {
        DisplayName: "Add Apple Pay",
        Description: "Add your Apple Pay account as a payment method.",
    },
    [PreviewFeatureId.AddApplePayGuest]: {
        DisplayName: "Add Apple Pay (Guest)",
        Description: "Add your Apple Pay account as a payment method without sign in.",
    },
    [PreviewFeatureId.DisallowCnpMetered]: {
        DisplayName: "Disallow CNP Metered",
        Description: "This is meant to be hidden, sorry!",
    },
    [PreviewFeatureId.AddGooglePaySignedIn]: {
        DisplayName: "Add Google Pay",
        Description: "Add your Google Pay account as a payment method.",
    },
    [PreviewFeatureId.AddGooglePayGuest]: {
        DisplayName: "Add Google Pay (Guest)",
        Description: "Add your Google Pay account as a payment method without sign in.",
    },
    [PreviewFeatureId.HideFixedPriceToggle]: {
        DisplayName: "Hide Fixed Price Toggle",
        Description: "Hide Fixed Price toggle for guest users in Adelaide.",
    },
    [PreviewFeatureId.ThreeDSecureVerification]: {
        DisplayName: "3DS Verification",
        Description: "Opens 3DS verification flow for a selected payment card.",
    },
    [PreviewFeatureId.GuestCreditCardBookings]: {
        DisplayName: "Guest Credit Card",
        Description: "Allows guest users to book with credit/debit card without log in.",
    },
};