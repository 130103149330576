import { ReduxStoreSlice } from "../../../Redux/ReduxStoreSlice";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { ServiceCheckStatus } from "../../Booking/BookingEntities";
import { AddressPointModalType, AddressDetails, AddressPoint, AddressValidity } from "./AddressPointsEntities";
import { AddressPointsInitialState, AddressPointsState, DefaultActiveAddressPoint } from "./AddressPointsState";

const slice = new ReduxStoreSlice<AddressPointsState>("AddressPoints", AddressPointsInitialState);

/** Dispatcher for the actions in the AddressPoints store slice. */
export const AddressPointsDispatch = {
    Refresh: slice.Action("Refresh List", RefreshList),
    AddToList: slice.Action("Add To List", AddToList),
    UpdateAddress: slice.Action("Update Address Point", UpdateAddressPoint),
    RemoveFromList: slice.Action("Remove", RemoveFromList),
    ActiveAddressPoint: slice.Action("Active Address Point", RefreshActiveAddressPoint),
    ClearActiveAddressPoint: slice.EmptyAction("Clear Active Address Point", ClearActiveAddressPoint),
    RefreshName: slice.Action("Update Name", RefreshAddressPointName),
    RefreshAddress: slice.Action("Update Address", RefreshAddress),
    RefreshPickupNotes: slice.Action("Update Pickup Notes", RefreshPickupNotes),
    RefreshDropoffNotes: slice.Action("Update Dropoff Notes", RefreshDropoffNotes),
    ClearName: slice.EmptyAction("Clear Name", ClearAddressPointName),
    ClearAddress: slice.EmptyAction("Clear Address", ClearAddress),
    SetUiEditModeType: slice.Action("Set Active Window Type", SetUiEditModeType),
    ClearUiEditModeType: slice.EmptyAction("Clear Active Window Type", RemoveUiEditModeType),
    SetAddressValidity: slice.Action("Set Address Validity", UpdateAddressValidity),
    ClearAddressValidity: slice.EmptyAction("Clear Address Validity", ClearAddressValidity),
    RefreshAddressPointError: slice.Action("Set Address Point Error", SetAddressPointError),
    ClearAddressPointError: slice.EmptyAction("Clear Address Point Error", ClearAddressPointError)
}

/** Reducer for the AddressPoints store slice */
export const AddressPointsReducer = slice.MakeCombinedReducer();

/** Refresh the list of user's address points. */
function RefreshList(state: AddressPointsState, list: AddressPointDetails[]): AddressPointsState {
    return {
        ...state,
        List: list
    }
}

/** Add a newly created address point to the list of address points. */
function AddToList(state: AddressPointsState, addressPoint: AddressPointDetails): AddressPointsState {
    const newList = [addressPoint, ...state.List];

    return {
        ...state,
        List: newList
    }
}

/** Update an existing address point (after successfully updated in the database) */
function UpdateAddressPoint(state: AddressPointsState, addressPoint: AddressPointDetails): AddressPointsState {
    const updatedAddressList = state.List.map(address => {

        if (address.AddressPointId !== addressPoint.AddressPointId) return address;

        // Updated address point
        return addressPoint;
    });

    return {
        ...state,
        List: updatedAddressList
    }
}

/** Remove an address point from the list. This happens after a successful deletion of an address point from the database. */
function RemoveFromList(state: AddressPointsState, addressPointId: number): AddressPointsState {

    const newList = state.List.filter((a) => {
        return a.AddressPointId !== addressPointId;
    });

    return {
        ...state,
        List: newList
    }
}

/** Refresh currently active address point. This happens when edit/delete an address point. */
function RefreshActiveAddressPoint(state: AddressPointsState, addressPoint: AddressPointDetails): AddressPointsState {

    const activeAddressPoint: AddressPoint = {
        AddressPointId: addressPoint.AddressPointId,
        Name: addressPoint.Name,
        Address: {
            DisplayText: addressPoint.DisplayText,
            GooglePlaceId: addressPoint.GooglePlaceId,
            GeoLocation: null
        },
        PickupNotes: addressPoint.PickupNotes,
        DropoffNotes: addressPoint.DropoffNotes
    }

    return {
        ...state,
        ActiveAddressPoint: activeAddressPoint
    }
}

/** Clear ActiveActivePoint details. This happens after an address point is successfully deleted or when the edit/add screens closed. */
function ClearActiveAddressPoint(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: DefaultActiveAddressPoint
    }
}

/** Update address point name. */
function RefreshAddressPointName(state: AddressPointsState, name: string): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            Name: name
        }
    };
}

/** Update various address parts (place ID, display text etc) of the address point. */
function RefreshAddress(state: AddressPointsState, address: AddressDetails): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            Address: address
        }
    }
}

/** Update pickup driver notes. */
function RefreshPickupNotes(state: AddressPointsState, notes: string): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            PickupNotes: notes
        }
    }
}

/** Update dropoff driver notes. */
function RefreshDropoffNotes(state: AddressPointsState, notes: string): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            DropoffNotes: notes
        }
    }
}

/** Clear address point name. Used when clear button is clicked in the input field. */
function ClearAddressPointName(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            Name: null
        }
    };
}

/** Clear address details. Used when clear button is clicked in the address input field. */
function ClearAddress(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        ActiveAddressPoint: {
            ...state.ActiveAddressPoint,
            Address: null
        }
    }
}

/**Update currently active window type. i.e.: Create or Edit address point. This is used to decide which operation to perform since both modals are using the same component. */
function SetUiEditModeType(state: AddressPointsState, windowType: AddressPointModalType): AddressPointsState {
    return {
        ...state,
        UiEditMode: windowType
    }
}

/** Remove active window type when create or edit window is closed. */
function RemoveUiEditModeType(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        UiEditMode: null
    }
}

/** Update address validation details. */
function UpdateAddressValidity(state: AddressPointsState, validity: AddressValidity): AddressPointsState {
    return {
        ...state,
        AddressValidity: validity
    }
}

/** Reset address validation details. */
function ClearAddressValidity(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        AddressValidity: {
            ServiceabilityState: {
                status: ServiceCheckStatus.NoInputSelected
            }
        }
    }
}

/** Update address point error message if there is any. */
function SetAddressPointError(state: AddressPointsState, error: string): AddressPointsState {
    return {
        ...state,
        ErrorMessage: error
    }
}

/** Clear address point error message. */
function ClearAddressPointError(state: AddressPointsState): AddressPointsState {
    return {
        ...state,
        ErrorMessage: null
    }
}