import React, { Component } from 'react';
import './ErrorMessaging.scss';
import DialogClose from "../../modules/Dialog/DialogClose";

interface DispatchProps {
    ProblemText: string;
    SolutionText: string;
    Button: JSX.Element;
    IsCloseButtonShown?: boolean; 
}

export default class ErrorMessagingProps extends Component<DispatchProps> { 
    render() {
        return(
            <div className="error-message-base-panel" >
                {this.props.IsCloseButtonShown && <DialogClose />}
                <div className="emb-problem-panel">{this.props.ProblemText}</div>
                <div className="emb-solution-panel" dangerouslySetInnerHTML={{__html: this.props.SolutionText}}></div>
                <div className="emb-btn-panel">{this.props.Button}</div>    
            </div>
        );
    }
}