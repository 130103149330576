
/** Inputs to the verification dialog */
export interface VerificationDialogInput {

    /** True when the user requested for a new verification code to be sent after a timeout period.
     * Show a label explaining we have sent the new code. Don't allow further resends. */
    IsResend: boolean;

    /** The phone number we just sent the verification code to. */
    PhoneNumber: string;

    /** Any error message from a previous verification attempt. e.g. if the user entered the wrong code. */
    ErrorMessage?: string;
}

/** Dialog output generated by the VerificationDialog. */
export interface VerificationDialogResult {

    /** What action the user performed */
    UserAction: VerificationDialogUserAction;

    /** The verification code the user entered. Only populated when the Action was "EnterCode" */
    CodeEntered?: string;
}

/** The action the user performed on the Verification dialog */
export enum VerificationDialogUserAction {

    /** The user clicked the "Change phone number" link. */
    ChangePhoneNumber = "Change Phone Number",

    /** The user clicked the "Request new code" */
    RequestNewCode = "Request New Code",

    /** The user entered the 4 digit code */
    EnterCode = "Enter Code",
}