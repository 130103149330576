import braintree, { GooglePaymentTokenizePayload } from "braintree-web";
import appstore from "../../appStore";
import { Api } from "../../Services/Api";
import { AddPaymentMethodRequest } from "../../Services/PaymentContractsV2";
import { MyStorage } from "../../Storage";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { Dispatch } from "../Dispatch";
import { CalculateExactAmountUserPaysInDollars } from "../Fare/FareHelper";
import { SetDeviceData } from "../Payment/PaymentHelper";
import { ValidateGuestUser } from "../Verification/VerifyPhoneForBooking";
import { GooglePayPlaceholder } from "../Payment/PaymentEntities";
import { AddGooglePayFunnel } from "./AddGooglePayFunnel";
import { GetValues } from "../../Config/MyAppConfig";

/** Adding a Google Pay payment method. */
export namespace GooglePay {

    /** Google Pay client SDK from our Braintree SDK */
    let googlePayClient: braintree.GooglePayment | null = null;

    /** Google Payments client from Google payment API. */
    let googlePaymentsClient: google.payments.api.PaymentsClient | null = null;

    /**
     * Provide the completed BrainTree and Google Pay SDK clients and Google Payments client (from Google Payments API) after they are loaded.
     */
    export function SdkIsReady(gpClient: braintree.GooglePayment, paymentsClient: google.payments.api.PaymentsClient) {
        googlePayClient = gpClient;
        googlePaymentsClient = paymentsClient
    }

    /**
     * Performs the add Google Pay payment method UI flow for signed in and guest users. 
     * This method just addresses the loader UI concern. 
     */
    export async function RunGooglePayUiFlowAndProceedBooking() {

        Dispatch.UILogicControl.ShowLoading();
        await RunGooglePayUiFlowAndProceedBooking2();
        Dispatch.UILogicControl.HideLoading();
    }

    /** Performs Google Pay flow (opens the Google Pay sheet, select payment method and send nonce to MPS) */
    export async function RunGooglePayUiFlowAndProceedBooking2() {

        AddGooglePayFunnel.Start();        

        const fareDetails = appstore.getState().condition.SelectedCondition.FareDetails;
        const tripCost = CalculateExactAmountUserPaysInDollars(fareDetails!, true, true);

        let requestData: PaymentDataRequest = {
            transactionInfo: {
                currencyCode: 'AUD',
                totalPriceStatus: 'FINAL',
                totalPrice: tripCost.toFixed(2)
            }
        }

        // this is only available/applicable for Production environment.
        const merchantId = GetValues().GoogleMerchantId

        if (merchantId) {
            requestData.merchantInfo = {
                merchantId: merchantId
            }
        }

        const paymentDataRequest = await googlePayClient!.createPaymentDataRequest(requestData);

        // opens the Google Pay payment sheet
        let paymentData: google.payments.api.PaymentData;

        try {
            paymentData = await googlePaymentsClient!.loadPaymentData(paymentDataRequest);
        }
        catch (error) {
            AddGooglePayFunnel.Exception("Loading PaymentData", error, true);
            return;
        }

        let tokenisedGooglePay: braintree.GooglePaymentTokenizePayload;

        try {
            tokenisedGooglePay = await googlePayClient!.parseResponse(paymentData);
        }
        catch (error) {
            AddGooglePayFunnel.Exception("Tokenise Google Pay", error, true);
            return;
        }

        const loginState = appstore.getState().authentication.LoginStatus;

        if (loginState == LoginStatusKind.LoggedIn) {
            await AddGooglePaySignedIn(tokenisedGooglePay);
        }
        else {
            await AddGooglePayGuest(tokenisedGooglePay);
        }
    }

    /** Send tokenised Google Pay payment method to MPS to add it to the user's payment methods. */
    async function AddGooglePaySignedIn(googlePayData: GooglePaymentTokenizePayload) {        

        // send nonce to MPS to register Google Pay for the user.
        const request: AddPaymentMethodRequest = {
            TokenisedPaymentNonce: googlePayData!.nonce,
            PaymentType: googlePayData!.type //either "AndroidPayCard" or "PayPalAccount" based on user's selection
        }

        const result = await Api.PaymentV2.RegisterPaymentMethod(request);

        if (!result.isSuccess) {
            AddGooglePayFunnel.ApiError("RegisterPaymentMethod API", result, true);            
            return;
        }

        Dispatch.Payment.AddGooglePay(result.value);

        // make this the default payment method for the subsequent bookings.
        MyStorage.DefaultPaymentMethod.StoreData(GooglePayPlaceholder);

        // proceed to booking only if register payment method succeeded. otherwise display an error message (happens in the various stages of the UI flow)
        CreateBookingWithoutSmsVerification();
    }

    /** Store the tokenised Google Pay payment method for later use and start mobile verification process. */
    async function AddGooglePayGuest(googlePayData: GooglePaymentTokenizePayload) {
        Dispatch.GuestPayment.GooglePayTokenised(googlePayData);
        await SetDeviceData();
        ValidateGuestUser();
    }
}

/** Request data to create a configuration object for use in the `loadPaymentData` method. */
interface PaymentDataRequest {
    /** Should be undefined for Test environment and mandatory for Production. */
    merchantInfo?: {
        merchantId: string;
    };
    transactionInfo: {
        currencyCode: string;
        totalPriceStatus: string;
        totalPrice: string;
    };
}