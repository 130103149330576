import { ReduxStoreSlice } from "../../../Redux/ReduxStoreSlice";
import { TrackingLinksState, TrackingLinksInitialState, BookingLink } from "./TrackingLinksState";
import { BookingAuthority } from "../../../Services/BookingEntities";
import { MyStorage } from "../../../Storage";

/** Helper object to create the Dispatcher and Reducer */
const slice = new ReduxStoreSlice<TrackingLinksState>("TrackingLinks", TrackingLinksInitialState);

/** Redux Actions for the TrackingLinks state */
export const TrackingLinksDispatch = {

    /** Record the BookingAuthority when the website loads on a tracking URL. */
    NoteLinkFromUrl: slice.Action("Note Link From URL", NoteLinkFromUrl),

    /** Reset the links list to the provided data. Expected at startup. */
    RestoreLinks: slice.Action("Restore Links", RestoreLinks),

    /** Adds a new link to the list. Updates local storage. Don't duplicate. */
    AddLink: slice.Action("Add Link", AddLink),

    /** Remove all links of a booking from the list. Update local storage. */
    RemoveLink: slice.Action("Remove Link", RemoveLink),
}

/** Redux Reducer for the TrackingLinks state */
export const TrackingLinksReducer = slice.MakeCombinedReducer();

/** Record the BookingAuthority when the website loads on a tracking URL. */
function NoteLinkFromUrl(state: TrackingLinksState, link: BookingLink): TrackingLinksState {
    return {
        ...state,
        NewFromUrl: link,
    };
}

/** 
 *  Reset the links list to the provided data. Expected at startup. 
 *  This is just restoring the links from local storage, so it won't write back to storage.
 */
function RestoreLinks(state: TrackingLinksState, links: BookingLink[]): TrackingLinksState {
    return {
        ...state,
        All: links,
    };
}

/** Adds a new link to the list. Updates local storage. Don't duplicate. */
function AddLink(state: TrackingLinksState, link: BookingLink): TrackingLinksState {

    // ignore dupes
    if (state.All.some(i => (i.BookingKey.bookingId == link.BookingKey.bookingId) && (i.BookingKey.hashCode == link.BookingKey.hashCode))) {
        return state;
    }

    // push to local storage
    var newAll = [...state.All, link];
    MyStorage.TrackingLinksV2.StoreData(newAll);

    return {
        ...state,
        All: newAll,
    };
}

/** Remove all links of a booking from the list. Update local storage. */
function RemoveLink(state: TrackingLinksState, link: BookingAuthority): TrackingLinksState {
    // Filter out removed booking
    const newLinkList = state.All.filter((l) => {
        return l.BookingKey.bookingId != link.bookingId;
    });

    MyStorage.TrackingLinksV2.StoreData(newLinkList);

    return {
        ...state,
        All: newLinkList,
    };
}