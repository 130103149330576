import appstore from "../../appStore";
import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { MakeVehicleLookupFromApiData } from "./ConditionHelperV2";
import { ConsiderFareUpdate } from "./FareLoader";
import { Dispatch } from "../Dispatch";
import { AsyncUpdateOutcome } from "./AsyncUpdateOutcome";
import { ServiceAvailability } from "../../Services/MakeBookingContracts";

/** Caller signals that the pickup address has changed. Trigger a calculation (already loaded) of the condition data if necessary. */
export async function ConsiderConditionUpdateV2(serviceAvailability: ServiceAvailability | null): Promise<AsyncUpdateOutcome> {

    // service check failure
    if (!serviceAvailability || !serviceAvailability.IsServiced) return AsyncUpdateOutcome.InputsNotReady;

    // check for input drift (i.e. pickup changed to a new suburb), which would make the result no longer meaningful
    const pickupNow = appstore.getState().booking.PickupServiceCheck;

    if (pickupNow.status !== ServiceCheckStatus.KnownGood) {
        return AsyncUpdateOutcome.LoadFailed;
    }

    const servicedVehicleTypes = serviceAvailability.ServicedVehicleTypes!;
    const parsedLookupTables = MakeVehicleLookupFromApiData(servicedVehicleTypes);
    Dispatch.Condition.RefreshConditionsV2(parsedLookupTables);

    // TODO: ideally i would return the Fare's status as well as this one. But currently it would make the return value too awkward.
    await ConsiderFareUpdate();

    return AsyncUpdateOutcome.Success;
}