import React from "react";
import { useAppState } from "../../../Redux/ReduxHooks";
import { PreviewFeatureId } from "../../Features/FeatureEntities";
import { BookingFormTypeSelector } from "../FormModeSelector/BookingFormTypeSelector";
import { BookingFormGroup } from "../FormSteps/BookingFormGroup";
import { FormStepLabel } from "../FormSteps/FormStepLabel";

/**
 * Possibly renders the Parcel Mode selector in the booking form.
 * It only appears if you have access to the feature.
 */
export const MaybeParcelModeSelector: React.FC = () => {

    const canUseParcelMode = useAppState(state => !!state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget]);

    if (!canUseParcelMode) return null;

    return (
        <BookingFormGroup WithStepLabel={FormStepLabel.SelectYourService}>
            <BookingFormTypeSelector />
        </BookingFormGroup>
    );
}