import { Reducer } from 'redux';
import { LocationState, initialLocationState, fallbackLocation } from './State';
import { LocationAction, LocationActionKind } from './Actions';
import { LocationData, KnownLocation, Location } from './Entities';

/** Reducer for the Location feature. This method just dispatches each case to their own methods. */
export const LocationReducer: Reducer<LocationState, LocationAction> = (state = initialLocationState, action) => {
    if (action.type === LocationActionKind.IPGeolocationComplete) {
        return HandleNewIpLocation(state, action.ipLocation);
    }
    else if (action.type === LocationActionKind.UserSelectsLocation) {
        return HandleNewUserLocation(state, action.userLocation);
    }
    else if (action.type === LocationActionKind.NoVehiclesFound) {
        return HandleNoVehiclesFound(state, action.location);
    }
    else {
        return state;
    }
}

/** Handler for the IPGeolocationComplete action. The payload is the new ipLocation data. This might result in a change of preferredLocation and reliableLocation. */
function HandleNewIpLocation(state: LocationState, ipLocation: LocationData): LocationState {

    // 1) directly update ipLocation
    state = { ...state, ipLocation: ipLocation };

    // 2) recompute dynamic properties
    return RecomputeLocations(state);
}

/** Handler for the UserSelectsLocation action. The payload is the user's selected location. This might result in a change of preferredLocation and reliableLocation. */
function HandleNewUserLocation(state: LocationState, userLocation: KnownLocation): LocationState {

    // 1) directly update ipLocation
    state = { ...state, userPickedLocation: userLocation };

    // 2) recompute dynamic properties
    return RecomputeLocations(state);
}

/** Handler for the NoVehiclesFound action. The payload is the location that is now known to be not serviceable. If this location matches (ipLocation) or (userLocation), treat it as an update. */
function HandleNoVehiclesFound(state: LocationState, brokenLocation: KnownLocation): LocationState {

    // create a new copy of the location record with isGeopointServiced === false
    const changedValue: Location = {
        ...brokenLocation.value,
        isGeopointServiced: false,
    };

    const changedLocation: KnownLocation = {
        ...brokenLocation,
        value: changedValue,
    };

    // handle it like an action
    if (brokenLocation === state.ipLocation) {
        return HandleNewIpLocation(state, changedLocation);
    }
    else if (brokenLocation === state.userPickedLocation) {
        return HandleNewUserLocation(state, changedLocation);
    }
    else {
        // out of date change; ignore
        return state;
    }
}

/** Either the IP location or the User location has changed. Recompute preferredLocation and reliableLocation.
 * UserPick is preferred, otherwise fallback.
 */
function RecomputeLocations(state: LocationState): LocationState {

    // preferred is easy because it's best effort
    const newPreferredLocation: LocationData = state.userPickedLocation || state.ipLocation || fallbackLocation;

    // reliable location is harder because we have to check for validity. We go in reverse order and overwrite.
    let newReliableLocation: KnownLocation = fallbackLocation;

    if (state.ipLocation && state.ipLocation.isKnown && state.ipLocation.value.isValid) {
        newReliableLocation = state.ipLocation;
    }

    if (state.userPickedLocation && state.userPickedLocation.value.isValid) {
        newReliableLocation = state.userPickedLocation;
    }

    const newState: LocationState = {
        ...state,
        preferredLocation: newPreferredLocation,
        reliableLocation: newReliableLocation,
    };

    return newState;
}