import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import NotesToDriver from "../PerLocation/NotesToDriver";

/** Consists of various driver notes fields based on the status/type of input values in booking address fields. */
const DriverNotesSectionCore: React.FC<ReduxProps> = (props) => {

    const separateNotesInputs = (
        <>
            <NotesToDriver Location={0} />
            <NotesToDriver Location={1} />
        </>
    );

    if (props.IsPickupUsingFavourite) return separateNotesInputs;
    if (props.IsDropoffUsingFavourite) return separateNotesInputs;

    return <NotesToDriver Location={0} Combined />
}

// #region Redux

interface ReduxProps {

    IsPickupUsingFavourite: boolean;
    IsDropoffUsingFavourite: boolean;
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {
    return {
        IsPickupUsingFavourite: !!state.booking.Locations[0].Favourite,
        IsDropoffUsingFavourite: !!state.booking.Locations[1].Favourite,
    }
}

export const DriverNotesSection = connect(GetPropsFromRedux)(DriverNotesSectionCore);

// #endregion