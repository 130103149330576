import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { ThreeDSecureInitialState, ThreeDSecureState } from "./ThreeDSecureState";

/** Redux store slice for the 3DS data. */
const slice = new ReduxStoreSlice<ThreeDSecureState>("ThreeDSecure", ThreeDSecureInitialState);

/** Redux action dispatcher for the 3DS store slice. */
export const ThreeDSecureDispatch = {

    /** The UI flow has progressed far enough to warrant loading the SDK. */
    ReadyToLoad: slice.EmptyAction("Ready To Load", ReadyToLoad),

    /** The 3DS SDK is now being loaded asynchronously. */
    LoadStarting: slice.EmptyAction("Load Starting", LoadStarting),

    /** The 3DS SDK load completed successfully. */
    LoadSucceeded: slice.EmptyAction("Load Succeeded", LoadSucceeded),

    /** The 3DS SDK load failed with an error. */
    LoadFailed: slice.EmptyAction("Load Failed", LoadFailed),
}

/** Redux reducer for the 3DS store slice. */
export const ThreeDSecureReducer = slice.MakeCombinedReducer();

/** The UI flow has progressed far enough to warrant loading the SDK. */
function ReadyToLoad(state: ThreeDSecureState): ThreeDSecureState {

    if (state.LoadingStatus === SdkLoadingState.NotRequested) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.RequestedNotStarted,
        };
    }

    // update not supported from this state
    return state;
}

/** The 3DS SDK is now being loaded asynchronously. */
function LoadStarting(state: ThreeDSecureState): ThreeDSecureState {

    if (state.LoadingStatus === SdkLoadingState.RequestedNotStarted) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.InProgress,
        };
    }

    // update not supported from this state
    return state;
}

/** The 3DS SDK load completed successfully. */
function LoadSucceeded(state: ThreeDSecureState): ThreeDSecureState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedSuccess,
        };
    }

    // update not supported from this state
    return state;
}

/** The 3DS SDK load failed with an error. */
function LoadFailed(state: ThreeDSecureState): ThreeDSecureState {

    if (state.LoadingStatus === SdkLoadingState.InProgress) {
        return {
            ...state,
            LoadingStatus: SdkLoadingState.FinishedError,
        };
    }

    // update not supported from this state
    return state;
}