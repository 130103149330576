import { DialogKind, WellKnownErrorMessage, DetailedErrorMessage, RetryErrorMessage } from "./DialogEntities";
import { DialogActionKind, DialogAction } from "./DialogActions";
import appstore from "../../appStore";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";

/** Helper class to dispatch Dialog actions. It's like an action creator except that it also dispatches the actions. */
export class DialogDispatcher {

    /** A request to show a particular dialog */
    public ShowDialog(dialog: DialogKind): void {
        dispatch({
            type: DialogActionKind.ShowDialog,
            dialog,
        });
    }

    /** Generates a dialog, but only on mobile devices. 
    This creates a behaviour similar to a mobile app transition to a new screen. */
    public MobileOnly(dialog: DialogKind): void {
        if (appstore.getState().uiLogicControl.LayoutMode === UILayoutMode.Mobile) {
            dispatch({
                type: DialogActionKind.ShowDialog,
                dialog,
            });
        }
    }

    /** A request to hide an existing dialog. */
    public CloseDialog(dialog: DialogKind): void {
        dispatch({
            type: DialogActionKind.CloseDialog,
            dialog,
        });
    }

    /** Close / cancel out of the topmost dialog */
    public CloseTopDialog(): void {
        dispatch({
            type: DialogActionKind.CloseTopDialog,
        });
    }

    /** Set simple error message object only when needed */
    public SetSimpleErrorMessage(payload: WellKnownErrorMessage): void {
        dispatch({
            type: DialogActionKind.SetSimpleErrorMessage,
            payload: payload
        });
    }

    /** Set re-try error message object only when needed */
    public SetRetryErrorMessage(payload: RetryErrorMessage): void {
        dispatch({
            type: DialogActionKind.SetRetryErrorMessage,
            payload: payload
        });
    }

    /** Clean simple error message object only when needed */
    public CleanSimpleErrorMessage(): void {
        dispatch({
            type: DialogActionKind.CleanSimpleErrorMessage,
        });
    }

    /** Clean re-try error message object only when needed */
    public CleanRetryErrorMessage(): void {
        dispatch({
            type: DialogActionKind.CleanRetryErrorMessage,
        });
    }

    public SetDescriptiveErrorMessage(errorProps: DetailedErrorMessage): void {
        dispatch({
            type: DialogActionKind.SetDescriptiveErrorMessage,
            errorProps: errorProps
        });
    }
}

/** This function is deliberately put outside the class to avoid issues with "this"
 *  Its purpose is to type-check the actions as DialogAction.
 */
function dispatch(action: DialogAction) {
    appstore.dispatch(action);
}