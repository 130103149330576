import React from 'react'
import { FormControl, MenuItem, TextField } from '@mui/material'
import PersonIcon from "@mui/icons-material/PersonOutlined";
import AddAPhotoIcon from '@mui/icons-material/AddAPhotoOutlined';
import LocalBarIcon from '@mui/icons-material/LocalBarOutlined';
import AssignmentIndIcon from '@mui/icons-material/AssignmentIndOutlined';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import './DeliveryOptions.scss';
import { ApplicationState } from '../../../appState';
import { connect } from 'react-redux';
import { SnackBar } from '../../../widgets/InformationDisplay/Snackbar';
import { Dispatch } from '../../Dispatch';
import { DeliveryOptionHelpText, DeliveryOptionKind } from './ParcelEntities';
import { PreviewFeatureId } from '../../Features/FeatureEntities';
import { SnackbarType } from '../../../widgets/InformationDisplay/SnackbarEntities';

/** This component show delivery options to select when making parcel booking. This will only show up when booking on account. */
const DeliveryOptions: React.FC<ReduxProps> = (props) => {

	const OnDropDownEntrySelected = (event: React.ChangeEvent<any>) => {

		const selectedDeliveryOptionValue = event.target.value;
	
		Dispatch.Booking.ChangeDeliveryOption(selectedDeliveryOptionValue);
		Dispatch.Booking.SetDeliveryOptionError(null);
    }

	/**
     * Return the delivery option help text to be displayed below the delivery option dropdown.
     */
	const GetDeliveryHelpText = (): string => {

        // Do not show, in case of error
        if (props.ErrorMessage) return "";
        
        return props.SelectedOption ? DeliveryOptionHelpText[props.SelectedOption] : "";
    }

	/**
 	* Possibly renders all non-RSA delivery options.
 	*/
	const GetDropDownEntries: () => JSX.Element[] = () => {

		// these entries are always available
		const entries = [
			<MenuItem value={DeliveryOptionKind.HandDirectlyToRecipient}>
				<div className='delivery-option-item'>
					<PersonIcon />
					<span>Hand directly to the recipient</span>
				</div>
			</MenuItem>,
			<MenuItem value={DeliveryOptionKind.Unattended}>
				<div className='delivery-option-item'>
					<AddAPhotoIcon />
					<span> Leave unattended</span>
				</div>
			</MenuItem>,
			<MenuItem value={DeliveryOptionKind.ProofOfAge}>
				<div className='delivery-option-item'>
					<AssignmentIndIcon />
					<span>Proof of age required </span>
				</div>
			</MenuItem>,
			<MenuItem value={DeliveryOptionKind.SignatureRequired}>
				<div className='delivery-option-item'>
					<AssignmentTurnedInIcon />
					<span>Signature required</span>
				</div>
			</MenuItem>
		];

		// this one requires a feature
		if (props.IsRsaOptionSupported) {
			entries.push((
				<MenuItem value={DeliveryOptionKind.SameDayAlcohol}>
					<div className='delivery-option-item'>
						<LocalBarIcon />
						<span>Same-day alcohol delivery (RSA)</span>
					</div>
				</MenuItem>
			));
		}

		return entries;
	}

	const labelText = props.SelectedOption  ? "" : "Please select";
	const optionHelperText = GetDeliveryHelpText();

	return (
		<div className="booking-fields-panel">
			<FormControl variant="outlined" fullWidth={true} className="account-selector-dropdown">
				<TextField
					variant={"outlined"}
					select
					label={labelText}
					value={props.SelectedOption}
					id="delivery-options-label"
					onChange={OnDropDownEntrySelected}
				>
					{GetDropDownEntries()}
				</TextField>
			</FormControl>
			{props.ErrorMessage && (
                <div className="booking-form-error-message">{props.ErrorMessage}
                </div>
            )}
			{optionHelperText && (
				<div className="delivery-help-text">
					<SnackBar DisplayText={optionHelperText} Type={SnackbarType.Info} CustomIcon={null} />
				</div>
            )}
		</div>
	)
}

// #region Redux

interface ReduxProps {
	ErrorMessage: string | null;
	SelectedOption: DeliveryOptionKind | null;

	/** Whether the RSA delivery preview feature is enabled. This is for same day alcohol. */
	IsRsaOptionSupported: boolean;
}

function mapStateToProps(state: ApplicationState): ReduxProps {
    return {
        ErrorMessage: state.booking.DeliveryOptionError,
		SelectedOption: state.booking.DeliveryOption,
		IsRsaOptionSupported: !!state.features.EnabledPreviews[PreviewFeatureId.AlcoholDelivery],
    };
}

export default connect(mapStateToProps)(DeliveryOptions);

// #endregion