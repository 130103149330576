import { ApiErrorDetail } from "../../Services/ApiContracts";
import { FullErrorId, SystemIds } from "./ErrorEntities";

/** A list of well known error IDs for which we have custom messages or business logic */
export namespace WellKnownErrors {

    /** Payment pre-authorisation failed during booking creation */
    export const PreAuthFailed: FullErrorId = {
        SystemId: SystemIds.BookingAPI,
        ErrorId: 14,
    };

    /** Any SMS Verification error from MGMT. This is a special case: it really represents a 4xx error. */
    export const SmsVerification: FullErrorId = {
        SystemId: SystemIds.ManagementAPI,
        ErrorId: 400,
    }

    /** Booking creation fails when Wheelchair is requested on the Christmas day due to the high demand. We capture this special case in order to display an informative error message to the user. */
    export const WatsOnXmasDay: FullErrorId = {
        SystemId: SystemIds.BookingAPI,
        ErrorId: 22,
    }

    /** The set of all well known errors. In a list to make it easy to iterate. */
    const AllErrors: FullErrorId[] = [PreAuthFailed, WatsOnXmasDay];

    /** 
     *  Checks whether the specified API error matches a well known error ID.
     *  Returns the matching record from WellKnownErrors, or null otherwise.
     */
    export function TryMatch(detail: ApiErrorDetail): FullErrorId | null {

        // match at this error
        for (const knownError of AllErrors) {

            if ((knownError.SystemId == detail.SystemId) && (knownError.ErrorId == detail.ErrorId)) {
                return knownError;
            }
        }

        // special case: SMS Verification (error range)
        if ((detail.SystemId == SystemIds.ManagementAPI) && (detail.ErrorId >= 400) && (detail.ErrorId < 500)) {
            return SmsVerification;
        }

        // check any causative error
        if (detail.Cause != null) {
            return TryMatch(detail.Cause);
        }

        // no match
        return null;
    }
}
