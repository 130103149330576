import { ContactRequiredDetailsType, EnquiryKind } from "./ContactFormEntities";


/** Section label / fleet type information. */
export interface ContactLabel {
    label: string;
}

/** Main categorisation entity. */
export interface SectionDefinition extends ContactLabel {
    /** Type of the user. Passenger, Operator etc. */
    sectionKind: EnquiryKind;

    /** Required details for the for each section kind. */
    requiredDetails?: ContactRequiredDetailsType;

    /** Fleet types. 13cabs/silver service etc */
    fleetTypes?: ContactLabel[];
}

export interface EnquiryEntry extends SectionDefinition {
    section: string;
}

/** List of sections. */
export const feedbackSections: Record<EnquiryKind, SectionDefinition> = {
    [EnquiryKind.PleaseSelect]: {
        label: 'Please Select',
        sectionKind: EnquiryKind.PleaseSelect,
    },
    [EnquiryKind.Receipt]: {
        label: 'Receipt',
        sectionKind: EnquiryKind.Receipt,
    },
    [EnquiryKind.Fare_Payment]: {
        label: 'Fare / Payment',
        sectionKind: EnquiryKind.Fare_Payment,
    },
    [EnquiryKind.LostProperty]: {
        label: 'Lost Property',
        sectionKind: EnquiryKind.LostProperty,
    },
    [EnquiryKind.AboutDriver]: {
        label: 'About Driver',
        sectionKind: EnquiryKind.AboutDriver,
    },
    [EnquiryKind.GeneralEnquiry]: {
        label: 'General Enquiry',
        sectionKind: EnquiryKind.GeneralEnquiry,
    },
};