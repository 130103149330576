import React, { Component } from 'react';
import './InputWidgetWrapper.scss';

interface InputWidgetWrapperProps {
    LabelName: string;
}

/**
 * Renders the input containter for each input in the new login/signup form.
 */
class InputWidgetWrapper extends Component<InputWidgetWrapperProps> {
    render() {
        return(
            <div className="input-widget-wrapper-panel">
                <span className="input-widget-wrapper-label">{this.props.LabelName}</span>
                <div className="input-widget-wrapper-input-box">{this.props.children}</div>
            </div>
        );
    }
}

export default InputWidgetWrapper;