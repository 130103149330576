import { BookingAuthority } from "../../../Services/BookingEntities";
import { Dispatch } from "../../Dispatch";
import { DialogKind } from "../../Dialog/DialogEntities";
import { GenerateBookingTrackingUrl } from "../../Booking/BookingHelper";
import { LogEvent } from "../../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../../Utils/ErrorMessages";
import appstore from "../../../appStore";
import { UILayoutMode, BookingTabKind } from "../../UILogicControl/UILogicControlEntities";
import { MapView } from "../../../widgets/NavBar/TabEntities";
import { BookingInfo } from "../MyBookingEntities";

/** Open the popup for cancel booking confirmation */
export function TryCancelTheBooking(bookingDetails: BookingInfo) {
    Dispatch.MyBookings.CancelCandidate(bookingDetails);
    Dispatch.Dialog.ShowDialog(DialogKind.CancelBooking);
}

/** Get the share URL and the read only hash code and open the share booking popup. */
export async function ShareTheBooking(bookingDetails: BookingInfo) {
    Dispatch.MyBookings.SetBookingToShare(bookingDetails);

    const isMobileDevice = appstore.getState().uiLogicControl.LayoutMode === UILayoutMode.Mobile;

    // Read access code is not available
    if (!bookingDetails.ReadAccessCode) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        return;
    }

    // Not a mobile device.
    if (!isMobileDevice) {
        Dispatch.Dialog.ShowDialog(DialogKind.ShareBooking);
        return;
    }

    // Webshare API is not supported by the device or browser.
    if (!navigator.share) {
        LogEvent.ShareIsNotSupported();
        // Fallback to the share dialog.
        Dispatch.Dialog.ShowDialog(DialogKind.ShareBooking);
        return;
    }

    // At this point, BookingTrackingBaseUrl is guaranteed to be defined.
    const bookingURL = GenerateBookingTrackingUrl(bookingDetails.BookingID.toString(), bookingDetails.ReadAccessCode, bookingDetails.BookingTrackingBaseUrl!);

    navigator.share({
        title: "Share Booking",
        url: bookingURL
    }).then(() => {
        LogEvent.ShareWithWebAPISuccess();
    }).catch((e: any) => {
        LogEvent.ShareWithWebAPIFailed(e);
    });
}

/** Remove the booking from the All list and the UI. */
export function RemoveBookingCard(booking: BookingInfo) {
    // Remove from different booking lists.
    Dispatch.MyBookings.Remove(booking);
    Dispatch.MyBookings.RemoveLinkBooking(booking);

    // Remove the link from redux and local storage.
    const bookingAuthority: BookingAuthority = {
        bookingId: booking.BookingID,
        hashCode: booking.AccessCode
    };

    Dispatch.TrackingLinks.RemoveLink(bookingAuthority);

    const allBookings = appstore.getState().myBookings.All;
    const layoutMode = appstore.getState().uiLogicControl.LayoutMode;

    // Change the UI if there are no bookings in the list.
    if (allBookings.length === 0) {
        if (layoutMode === UILayoutMode.Mobile) {
            Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);
        } else {
            Dispatch.Tab.SelectItem(MapView);
        }
    }
}
