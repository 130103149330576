import { MaxPollingDuration } from "./PodRefreshParameters";
import { GetCurrentTime } from "../../Utils/GetCurrentTime";
import { BookingStatus } from "../../../Services/BookingEntities";
import { BookingInfo } from "../MyBookingEntities";

/**
 *  Validates whether it's a delivery booking with valid status or not
 */
export function ShouldWatchForPodImageUrls(booking: BookingInfo) {
    
    if (!booking.IsDeliveryBooking) return false;

    if (booking.TrackingInfo.BookingStatusID > BookingStatus.Completed) return false;

    return true;
}

/**
 *  Validates whether the max polling period i.e. 30 mins is exceeded
 */
export function HasMaxPollingPeriodExceeded(booking: BookingInfo) {
    
    const diffInMinutes = GetCurrentTime().diff(booking.TrackingInfo.PickedupTime, "minutes");
    
    // Do not set the timer to poll the image urls, if the time difference is more than 30 mins
    if (diffInMinutes > MaxPollingDuration.asMinutes()) return true;

    return false;
}