import { GeoPoint } from "../../Location/Entities";

/** Input field properties */
export interface InputFieldProps {
    label: string;
    value: string;
    name: string;
    isError?: boolean;
    imgSrc?: string;
    type?: InputTypes;
    location?: GeoPoint;
    placeholder?: string;
    onAddressChosen?: (address: SelectedAddress) => void;
    updateHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    labelForPhoneNumber?: string;
    doesInputHaveValue?: boolean;
    clearTextHandler?: (name: string) => void;
    onCleared?: () => void;
}

/** Address properties */
export interface SelectedAddress {
    streetNumber: string;
    streetName: string;
    suburb: string;
    display: string;
}

/** Accepted types for input fields */
export enum InputTypes {
    Phone = "phone",
    Text = "text",
    Number = "number",
    Address = "address",

    // For currency inputs
    Amount = "amount"
}
