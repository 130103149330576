import { AddressEntryKind, AddressPickerEntry } from "./AddressPickerEntry";

/** Helper methods to get pieces from an AddressPickerEntry, which represents either a google Maps Place suggestion, or a favourite address. */
export namespace ComboAddress {

    /**
     * The Place ID is used to retried the full place details from Google Maps API.
     */
    export function GetPlaceId(entry: AddressPickerEntry) {

        if (entry.Kind === AddressEntryKind.GoogleMaps) {
            return entry.Suggestion.place_id;
        }
        else {
            return entry.Favourite.GooglePlaceId;
        }
    }

    /**
     * The single-line display text for an entry.
     * This is displayed in the text box when that entry is selected.
     */
    export function GetPrimaryDisplayText(item: AddressPickerEntry) {

        if (item.Kind === AddressEntryKind.GoogleMaps) {
            return item.Suggestion.description;
        }
        else {
            return item.Favourite.Name;
        }
    }

    /** The second text adds extra detail and is displayed below the primary */
    export function GetSecondaryText(entry: AddressPickerEntry): string {

        if (entry.Kind === AddressEntryKind.GoogleMaps) {
            return entry.Suggestion.structured_formatting.secondary_text;
        }
        else {
            return entry.Favourite.DisplayText;
        }
    }
}