import { FormatBusinessNumber, CheckAddPlus, DropLeadingZero } from "../../utils/Formattingutil";
import { PhoneNumberUtil, PhoneNumberType, PhoneNumber } from 'google-libphonenumber';
import { ContactNumberObject } from "../Verification/VerificationEntities";
import { ContactNumberValidationResult, ContactNumberKind } from "../Authentication/Login/LoginEntities";
import { FullPhoneNumber } from "../PhoneNumber/PhoneNumberEntities";
import { GetValues } from "../../Config/MyAppConfig";

const phoneUtil = PhoneNumberUtil.getInstance();

/**
 * Turn a string phone number into a Google PhoneNumber object.
 * May return null if the phoneNumber is seriously invalid, e.g. empty string.
 */
function TryParseNumber(phoneNumber: string): PhoneNumber | null {

    const numberWithPlus = CheckAddPlus(phoneNumber);

    try {
        return phoneUtil.parseAndKeepRawInput(numberWithPlus);
    }
    catch (ex) {
        return null;
    }
}

/**
 * This function is used to determine if a contact number, for both mobile and landline, is valid or not via lib "google-libphonenumber"
 */
export function IsValidNumber(phoneNumber: string): boolean {
    const formattedNumber = FormatBusinessNumber(phoneNumber);

    const phone = TryParseNumber(formattedNumber);
    if (!phone) return false;

    return phoneUtil.isValidNumber(phone);
}

/**
 * This function is used to determine if a mobile number is valid or not via lib "google-libphonenumber"
 * This is eaxtly consistent with logic and implementation from client API.
 */
export function IsValidMobileNumber(phoneNumber: string): boolean {

    // parses
    const phone = TryParseNumber(phoneNumber);
    if (!phone) return false;

    // valid
    if (!phoneUtil.isValidNumber(phone)) return false;

    // mobile type
    const phoneType = phoneUtil.getNumberType(phone);

    return (phoneType === PhoneNumberType.MOBILE) || (phoneType === PhoneNumberType.FIXED_LINE_OR_MOBILE);
}

/**
 * TODO: remove this and replace with calls to IsValidLandlineNumber.
 */
export function IsValidAustralianLandlineNumber(contactNumber: string, contactCountryCode: string, contactNumberPart: string): boolean {
    return IsValidNumber(contactNumber) && CheckAddPlus(contactCountryCode) === "+61" && /^0?[1-8]\d{8,9}$/g.test(contactNumberPart);
}

/**
 * Landline numbers are only allowed in the local countries of the booking website.
 */
export function IsValidLandlineNumber(phoneNumber: FullPhoneNumber): boolean {

    return !phoneNumber.IsMobile && GetValues().AllowedCountryCodes.some(i => i.toLowerCase() == phoneNumber.CountryCodeIso2.toLowerCase());
}

/**
 * This is to get country code from phone number via lib "google-libphonenumber"
 */
export function ParseContactNumber(contactPhone: string): ContactNumberObject {

    // Verify invalid country code
    if (contactPhone.charAt(0) === "0") {

        // Prepend the number with australian country code
        contactPhone = "+61" + contactPhone;
    }

    const parsedPhoneNumber = phoneUtil.parse(CheckAddPlus(contactPhone));
    const countryCodeTemp = parsedPhoneNumber.getCountryCode();
    const numberPartTemp = parsedPhoneNumber.getNationalNumber();
    const isoRegionCodeTemp = phoneUtil.getRegionCodeForNumber(phoneUtil.parseAndKeepRawInput(CheckAddPlus(contactPhone)));

    return {
        NumberPart: !!numberPartTemp ? numberPartTemp.toString() : "",
        CountryCode: CheckAddPlus(!!countryCodeTemp ? countryCodeTemp.toString() : ""),
        IsoRegionCode: !!isoRegionCodeTemp ? isoRegionCodeTemp : ""
    };
}

/**
 * Remove leading plus sign
 * In this function, we assume that the parameter is not empty and has a plus sign
 */
export function RemoveLeadingPlusSign(s: string): string {
    return s.charAt(0) === "+" ? s.substr(1, s.length - 1) : s;
}

/**
 * Validate the contact number with the selected country code. We only allow Australian landline numbers and local/international mobile numbers.
 */
export function ValidateContactNumber(contactNumber: string, countryCode: string): ContactNumberValidationResult {
    
    // this step is to avoid an exception in the google phone library
    if (contactNumber.length <= 1) {
        return {
            IsValid: false,
            ContactNumberKind: ContactNumberKind.NotAccepted,
        };
    }

    const fullNumber = CheckAddPlus(countryCode) + DropLeadingZero(contactNumber);

    if (IsValidMobileNumber(fullNumber)) {
        return {
            IsValid: true,
            ContactNumberKind: ContactNumberKind.Mobile,
        };
    }

    if (IsValidAustralianLandlineNumber(fullNumber, countryCode, contactNumber)) {
        return {
            IsValid: true,
            ContactNumberKind: ContactNumberKind.Landline,
        };
    }

    return {
        IsValid: false,
        ContactNumberKind: ContactNumberKind.NotAccepted,
    };
}