import { LocationData, KnownLocation } from "./Entities";

/** IP Geolocation has completed and provided a LocationData */
export interface IPGeolocationComplete {
    type: LocationActionKind.IPGeolocationComplete;
    ipLocation: LocationData;
}

/** The user has selected a location of interest */
export interface UserSelectsLocation {
    type: LocationActionKind.UserSelectsLocation;
    userLocation: KnownLocation;
}

/** No vehicle conditions available for a location - mark it as not serviceable */
export interface NoVehiclesFound {
    type: LocationActionKind.NoVehiclesFound;
    location: KnownLocation;
}

/** Compound type representing all possible location actions */
export type LocationAction = IPGeolocationComplete | UserSelectsLocation | NoVehiclesFound;

/** This enum is used to discriminate LocationAction subtypes */
export enum LocationActionKind {

    /** IP Geolocation has completed and provided a LocationData */
    IPGeolocationComplete = 'Location: IPGeolocationComplete',

    /** The user has selected a location of interest */
    UserSelectsLocation = 'Location: UserSelectsLocation',

    /** No vehicle conditions available for a location - mark it as not serviceable */
    NoVehiclesFound = 'Location: NoVehiclesFound',
}