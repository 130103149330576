import React from 'react';
import { connect } from 'react-redux';
import './Dialog.scss';
import { ApplicationState } from '../../appState';
import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { GetBrandedUrl, BrandedImage } from "../Utils/BrandedContentUrls";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";

interface MobileFullScreenPopupProps {
    IsApiRunning: boolean;
    LayoutMode: UILayoutMode;
};

/**
 * It has two purposes:
 *   1) Common black transpatent background for dialog of mobile & desktop;
 *   2) Loading effect, but currently only used for mobile devices & autentication interfaces.
 * 
 * Todo: 
 * To make purpose 2) for all scenarios.
 * Currently loading strategy has different implementation for different scenarios, I prefer we have a PR to improve this.
 */
export const DialogLoadingCore: React.FC<MobileFullScreenPopupProps> = (props) => {

    const style = props.IsApiRunning && props.LayoutMode === UILayoutMode.Mobile ? "dialog-loading-panel used-as-loading" : "dialog-loading-panel used-as-bg";

    return (
        <div className={style}>
            {props.IsApiRunning && <img alt="loading" src={GetBrandedUrl(BrandedImage.Loading)} width="auto" height="100"/>}
        </div>
    );
}

function mapStateToProps(state: ApplicationState): MobileFullScreenPopupProps {
    return {
        IsApiRunning: state.uiLogicControl.Credentials.ApiRunningStatus === DataLoadingStatus.InProgress,
        LayoutMode: state.uiLogicControl.LayoutMode
    };
}

export const DialogLoading = connect(mapStateToProps)(DialogLoadingCore);

