import React from 'react';
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import '../Login/SignupLogin.scss';
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { DataLoadingStatus } from "../../Condition/Redux/ConditionEntities";
import { Dispatch } from '../../Dispatch';
import { Credentials } from '../AuthEntities';
import { getContentUrl, ContentURL }  from '../../Utils/ContentURL';
import AuthImplV2 from "../Login/AuthImplV2";
import CredentialsController from "../Login/CredentialsController";
import { UILayoutMode } from "../../UILogicControl/UILogicControlEntities";

/**
 * Log in 
 */
export function ExecLogin(email: string | undefined, password: string | undefined, idle: boolean) {

    if (!idle) return;

    if (IsLoginReady(email, password)) {

        Dispatch.UILogicControl.StartCredentialsApiRequest();

        if (FeatureFlags.Auth0RedirectInChildWindow) {
            
            Dispatch.Auth.ShowSilentLogin();
        }
        else {
            new AuthImplV2().LogInAuth0(email!, password!);
        }
    }
}

/**
  * How are ready?
  * 1) Email or username has been filled and validated;
  * 2) Password has been filled and validated;
  */
function IsLoginReady(email: string | undefined, password: string | undefined): boolean {
    return !!email && !!password;
}

interface PropsFromStore {
    Credentials: Credentials;
    ApiRunningStatus: DataLoadingStatus;
    UnderMobileMode: boolean;
}

/**
 * Buttons group in login dialog.
 */
const ButtonsOfLoginCore: React.FC<PropsFromStore> = (props) => {

    const isReady = IsLoginReady(props.Credentials.Email, props.Credentials.Password) ? "active" : "inactive";

    const style = FeatureFlags.NoSignup ? "signup-popup-btn-group login-only-btn" : "signup-popup-btn-group";
    
    const btnstyle = "credential-btn-loading credential-btn-proceed-small-" + isReady;
    
    const btn = props.ApiRunningStatus === DataLoadingStatus.InProgress && !props.UnderMobileMode ? <img className="credential-loading" src={getContentUrl(ContentURL.images.Loading)} alt="Loading" /> : <>Log in</>;

    return (
        <div className={style}>
            {!FeatureFlags.NoSignup && <button className="credential-btn-skip" onClick={GoToSignup}>Sign up</button>}
            <button className={btnstyle} onClick={() => ExecLogin(props.Credentials.Email, props.Credentials.Password, props.ApiRunningStatus === DataLoadingStatus.Idle)}>{btn}</button>
        </div>
    );

    /**
     * User clicks the "Sign up" button.
     */
    function GoToSignup() {

        if (props.ApiRunningStatus === DataLoadingStatus.Idle) {
            new CredentialsController().DoSignup();
        }
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        Credentials: state.authentication.Credentials,
        ApiRunningStatus: state.uiLogicControl.Credentials.ApiRunningStatus,
        UnderMobileMode: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile
    };
}

export const ButtonsOfLogin = connect(mapStateToProps)(ButtonsOfLoginCore);