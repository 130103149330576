import { LoadScriptProps, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { GetValues } from "../../Config/MyAppConfig";

/** Which version of the Google Maps library we want to use.*/
export const GoogleMapsScript: LoadScriptProps = {
    id: "script-loader",
    googleMapsApiKey: GetValues().GoogleMapsApiKey,
    version: FeatureFlags.GoogleMapsBetaChannel ? "beta" : undefined,
    libraries: ["geometry", "drawing", "places"],
};

/** Wrapper for components that depend on Google Maps API
 *  Loads Script before rendering children
 */
export const GoogleMapScriptLoader: React.FC = (props) => {

    const googleScriptLoadResult = useJsApiLoader(GoogleMapsScript);
    if (!googleScriptLoadResult.isLoaded) return null;

    return <>{props.children}</>;
}