import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { BookingTemplate } from "./BookingTemplateEntities";
import { BookingTemplateInitialState, BookingTemplateState } from "./BookingTemplateState";

const slice = new ReduxStoreSlice<BookingTemplateState>("BookingTemplates", BookingTemplateInitialState);

/** Dispatcher for actions in the BookingTemplates state slice. */
export const BookingTemplatesDispatch = {
    LoadTemplates: slice.Action("Load My Booking Templates", LoadMyBookingTemplates),
    ListFilterText: slice.Action("Load filtered Text", FilterTextChanged )
};

/** Reducer for the BookingTemplates store slice */
export const BookingTemplatesReducer = slice.MakeCombinedReducer();

/** Apply a LoadTemplates action to the BookingTemplates state. */
function LoadMyBookingTemplates(state: BookingTemplateState, bookingTemplates: BookingTemplate[]): BookingTemplateState {
    return {
        ...state,
        MyTemplates: bookingTemplates,
        FilteredTemplates: bookingTemplates
    };
}

/** Apply FilterTextChanged action to Filtered Templates  */
function FilterTextChanged(state: BookingTemplateState, filterText: string): BookingTemplateState {
    return {
        ...state,
        FilterText: filterText,
        FilteredTemplates: state.MyTemplates.filter(i => filterText == "" || i.Name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0)
    };
}