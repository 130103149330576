import appstore from "../../../appStore";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { MyStorage } from "../../../Storage";
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { IsDVAUser } from "../../BookingTemplate/BookingTemplateHelper";
import { IsFixedFareAvailable } from "../../Condition/ShouldDisplayFare";
import { Dispatch } from "../../Dispatch";

/** This is a small, inline floating tooltip ("snackbar") to say PG is available */
export function ConsiderToShowPriceGuaranteeTip(){
    const appState = appstore.getState();
    
    if (!IsFixedFareAvailable(appState)) return;
    if (MyStorage.HasSeenPriceGuaranteeTip.LoadData() === true) return;
    if (!FeatureFlags.PriceGuarantee) return;
    if (appState.booking.IsOnAccount) return;
    if (IsDVAUser(appState.authentication.UserProfile)) return; 
    if (appState.uiLogicControl.BookingForm.BookingWidgetMode !== BookingWidgetModeKind.Booking) return;

    // temporarily disable Snackbar. to reactivate =>
    //Dispatch.UILogicControl.ShowSnackbarForPriceGuaranteeTip()
}
