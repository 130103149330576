import React from 'react';
import "./BookingTemplate.scss";
import NewBooking from '../Booking/NewBooking';
import { ResetBookingForm } from './BookingTemplateHelper';
import { BookingWidgetModeKind } from './BookingTemplateEntities';

/**
 * Create booking template
 */
class BookingTemplateForm extends React.Component {

    backButtonClickHandler = () => {
        // Switch ON the booking mode
        ResetBookingForm(BookingWidgetModeKind.Booking);       
    }

    render() {
        return (
            <React.Fragment>
                <div className="booking-form-tag-header">
                    <div className="header-section">
                        <div className="btn-back" onClick={this.backButtonClickHandler}>
                            <span className="arrow-left"></span>
                            <span className="btn-back-label">Back</span>
                        </div>
                    </div>
                </div>               
                <NewBooking />
            </React.Fragment>
        )
    }
}

export default BookingTemplateForm;