import React, { FC, useState } from "react";
import { DialogResult } from "../Entities";
import { VerificationDialogResult, VerificationDialogInput } from "../Verification/UIEntities";
import { ShowDialog } from "../ShowDialog";

/** Demonstration module for the Dialog2 feature. Trigger the Verification dialog on its own, measure the result. */
export const Dialog2Demo: FC = () => {

    // the result of the last dialog. Initially null.
    const [result, setResult] = useState<DialogResult<VerificationDialogResult> | null>(null);

    // inputs
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isResend, setResend] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    return (
        <div style={{marginTop: 100, marginLeft: 400}}>
            <div>Input:</div>
            <div>
                <span>Phone Number:</span>
                <input onChange={PhoneNumberChanged} />
            </div>
            <div>
                <span>Resend?</span>
                <input type="checkBox" onChange={IsResendChanged} />
            </div>
            <div>
                <span>Error Message:</span>
                <input onChange={ErrorMessageChanged} />
            </div>
            <button onClick={ClickedShowDialog}>Show Dialog</button>
            <div>result:</div>
            <DisplayResult1 Data={result} />
        </div>
    );

    function PhoneNumberChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setPhoneNumber(e.target.value);
    }

    function IsResendChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setResend(e.target.checked);
    }

    function ErrorMessageChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setErrorMessage(e.target.value);
    }

    async function ClickedShowDialog() {

        const input: VerificationDialogInput = {
            IsResend: isResend,
            PhoneNumber: phoneNumber,
        };

        // ignores ""
        if (errorMessage) {
            input.ErrorMessage = errorMessage;
        }

        const verificationResult = await ShowDialog.Verification(input);
        setResult(verificationResult);
    }
}

interface DataProps<T> {
    Data: T;
}

/**
 * This unwraps the null.
 */
const DisplayResult1: FC<DataProps<DialogResult<VerificationDialogResult> | null>> = ({ Data }) => {
    if (Data == null) return null;

    return <DisplayResult2 Data={Data} />
}

/**
 * This unwraps the DialogResult.
 */
const DisplayResult2: FC<DataProps<DialogResult<VerificationDialogResult>>> = ({ Data }) => {

    if (Data.DidUserCancel) return (<div>The user cancelled out of the dialog</div>);

    return <DisplayResult3 Data={Data.Output} />;
}

/**
 * Displays a VerificationDialogResult.
 */
const DisplayResult3: FC<DataProps<VerificationDialogResult>> = ({ Data }) => {

    return (
        <div>
            <div>
                <span>User Action: </span>
                <span>{Data.UserAction}</span>
            </div>
            {Data.CodeEntered && (
                <div>
                    <span>Verification Code: </span>
                    <span>{Data.CodeEntered}</span>
                </div>
            )}
        </div>
    );
}