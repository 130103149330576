import { LoginStatusKind, LoginUiMode, LogoutKind, Auth0SuccessOrError } from "./AuthEntities";
import Auth0Lock from 'auth0-lock';
import { ContentURL, getContentUrl } from '../../modules/Utils/ContentURL';
import "../../scss/Lock.scss";
import { FeatureFlags } from '../../Config/FeatureFlags';
import LogoWithText from './logoWithText.png'; // Auth0 cannot handle this img well working with CDN
import { Auth0DecodedHash } from 'auth0-js';
import { Dispatch } from "../Dispatch";
import { MapView } from "../../widgets/NavBar/TabEntities";
import { GetValues } from "../../Config/MyAppConfig";
import { Config } from '../../Config/Config';
import { GenerateRunTimeUrl } from "../Utils/GenerateRunTimeUrl";
import { MyStorage } from "../../Storage";

const auth0Config = GetValues().Auth0!;

/** A wrapper around the Auth0 Lock UI. */
export default class Auth {
    /**
     * Learnings:
     * https://auth0.com/docs/libraries/lock/v11/configuration#theme-object-
     */
    clientID = auth0Config.ClientId;
    domain = FeatureFlags.CustomDomain && auth0Config.CustomDomain ? auth0Config.CustomDomain : auth0Config.ClientDomain;
    authOptions: Auth0LockConstructorOptions = { 
        auth: {
            redirectUrl: GenerateRunTimeUrl('loginreturnv2'),
            responseType: 'token id_token',
            params: {
                scope: Config.Auth0TokenScope
            }
        },
        configurationBaseUrl: 'https://cdn.au.auth0.com', // https://auth0.com/docs/custom-domains/additional-configuration
        leeway: 300, // allow 5 minute clock skews
        allowedConnections: [auth0Config.Connection], 
        rememberLastLogin: false,
        allowShowPassword: true,
        languageDictionary: {
            emailInputPlaceholder: "yours@example.com",
            title: "",
            loginLabel: 'Log in',
            loginSubmitLabel: 'Log in',
            loginWithLabel: 'Log in with %s',
            signUpTitle: '',
            signUpLabel: 'Sign up',
            signUpSubmitLabel: 'Sign up',
            forgotPasswordAction: "Forgot password?",
            signUpTerms: "I accept the <a href='https://book.13cabs.com.au/Terms' target='_new'>Terms and Conditions <br>and Privacy Policy</a>",
            forgotPasswordTitle: '',
            forgotPasswordInstructions: 'We\'ll send an email to reset your password. Please enter your email below.'
        },
        theme: {
            logo: LogoWithText,
            primaryColor: GetValues().BrandColour
        },
        avatar: null,
        allowForgotPassword: true,
        allowSignUp: true,
        mustAcceptTerms: true,

        additionalSignUpFields: [
            {   
                name: "first_name",
                placeholder: "first name",
                icon: getContentUrl(ContentURL.images.Lock.HumanIcon),
                validator: function(first_name) {
                    return {
                        valid: first_name.length > 0,
                        hint: "Can't be blank"
                   };
                }
            },
            {   
                name: "last_name",
                placeholder: "last name",
                icon: getContentUrl(ContentURL.images.Lock.HumanIcon),
                validator: function(last_name) {
                    return {
                        valid: last_name.length > 0,
                        hint: "Can't be blank"
                   };
                }
            },
            {   
                name: "contact_number",
                placeholder: "phone number",
                icon: getContentUrl(ContentURL.images.Lock.PhoneIcon),
                validator: function(contact_number) {
                    return {
                        /**
                         * Australian number verification
                         * Reuse exactly same rule from https://sydney.13cabs.com.au as requested
                         * A better solution is using 'google-libphonenumber' to verify.
                         */
                        valid: /^0[0-8]\d{8}$/g.test(contact_number),
                        hint: "Must be a valid phone number"
                   };
                }
            }
        ],
    };

    /**
     * Lock instace
     */
    auth0: Auth0LockStatic = new Auth0Lock(this.clientID, this.domain, this.authOptions);

    /** 
     *  Display the Auth0 Lock screen in either Login or SignUp mode. 
     */
    showAuth0LockScreen(uiMode: LoginUiMode) { 

        const showOptions: Auth0LockShowOptions = {
            initialScreen: uiMode
        };

        this.auth0.show(showOptions); 
    }

    /**
     * Logout order:
     * 
     * 1> Clean localstorage;
     * 2> Logout on Auth0 side depends on source, terminate here if redirect happens;
     * 3> Clean store of no redirect happened.
     */
    logout(IsTriggeredByCustomer: LogoutKind) {
        //If current path is userprofile, redirect to main page on logout 
        MyStorage.AuthToken.ClearData();
        MyStorage.UserProfileV2.ClearData();
        MyStorage.Bookings.ClearData();
        MyStorage.TrackingLinksV2.ClearData();

        /**
         * If it's WebsiteAndAuth0, we need to log out from both application and Auth0
         */
        if (IsTriggeredByCustomer != LogoutKind.Website) {
            const callbackUrl = IsTriggeredByCustomer === LogoutKind.WebsiteAndAuth0OpenLogin ? 'booking?logIn=true' : 'booking';

            this.auth0.logout({
                returnTo: GenerateRunTimeUrl(callbackUrl),
                client_id: this.clientID
            });

            return;
        }

        Dispatch.Auth.ClearAuth0Token();
        Dispatch.Auth.LoginStatus(LoginStatusKind.LoggedOut);
        Dispatch.Auth.ClearUserProfile();
        Dispatch.Auth.HideProfilePanel();
        Dispatch.Booking.ClearContactName(0);
        Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);
        
        // clear bookings if they will be reloaded later
        Dispatch.MyBookings.Clear();

        // Change view to MapView to avoid errors if the user was in a tab that needs sign in (e.g: Trip History)
        Dispatch.Tab.SelectItem(MapView);
        
        /** There are some fields that are shown when the Book on account toggle is enabled. 
         *  Update the property to be false to hide those fields on sign out.*/
        Dispatch.Booking.SetBookOnAccount(false);
        Dispatch.Booking.ClearAccountDetails();
    }

    /** Returns a promise that waits for events generated by the auth0 object during login. */
    handleAuthentication(): Promise<Auth0SuccessOrError> {
        return new Promise((resolve, reject) => {
            /**
             * CAREFUL: event says it sends an Auth0Result but we are using Auth0DecodedHash
             * typings are very similar, but Auth0DecodedHash allows arbitrary data (any) in the returned idtoken
             * we use this to access an "email" field which is not part of authResult later.
             */
            this.auth0.on("authenticated", (authResult: Auth0DecodedHash) => {
                resolve({ SuccessfulResult: authResult, IsSuccess: true });
            });

            /**
             * Prefer to resolve instead of reject for "unrecoverable_error" and "authorization_error". 
             * Then you can get rid of the catch block and just await for a Auth0SuccessOrError value.
             */
            this.auth0.on("unrecoverable_error", (err: auth0.Auth0Error) => {
                resolve({ ErrorResult: err, IsSuccess: false });
            });

            this.auth0.on("authorization_error", (err: auth0.Auth0Error) => {
                resolve({ ErrorResult: err, IsSuccess: false });
            });
        }
    )}

    /**
     * Attempts to silently renew an Auth0 Single Sign-On session. 
     * You will get a fresh ID Token and Access Token without any user interaction, as long as your Auth0 cookie is still good.
     * This method essentially converts the Auth0 checkSession API from a callback to a Promise.
     */
    renewSession(): Promise<Auth0SuccessOrError> {

        return new Promise((resolve, reject) => {
            this.auth0.checkSession({}, (err: auth0.Auth0Error | undefined, authResult: AuthResult | undefined) => {

                if (err) {

                    // error: resolve as Auth0Failure
                    resolve({ErrorResult: err, IsSuccess: false });
                }
                else {

                    // success: resolve as Auth0Success
                    resolve({SuccessfulResult: authResult!, IsSuccess: true});
                }
            });
        });
    }
}
