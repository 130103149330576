import { BookingTrackingResult, BookingStatus, LightweightBookingStatus } from "../../Services/BookingEntities";
import { BookingInfo, BookingTrackingDetails } from "./MyBookingEntities";
import { BookingInfoV2, BookingStatusV2, LightweightBookingStatusV2 } from "../../Services/BookingEntitiesV2";
import moment from "moment";

/**
 * Convert BookingTrackingResult API contract in to BookingInfo data model.
 * */
export function ConvertBookingTrackingResultToBookingInfo(booking: BookingTrackingResult): BookingInfo {

    return {
        Time: booking.Time,
        LocalTime: booking.LocalTime,
        MaxSeat: booking.MaxSeat,
        CarConditionID: booking.CarConditionID,        
        DispatchBookingID: booking.DispatchBookingID.toString(),
        BookingID: booking.BookingID,
        AccessCode: booking.HashCode,
        PassengerName: booking.PassengerName,
        DataOwnership: booking.DataOwnership,
        ContactName: booking.ContactName,
        NotesToDriver: booking.NotesToDriver,
        OrderNumber: booking.OrderNumber,
        AccountNumber: booking.AccountNumber,
        CustomerPhone: booking.CustomerPhone,
        ReadAccessCode: booking.ReadAccessCode,
        BookingTrackingBaseUrl: booking.BookingTrackingBaseUrl,
        IsDeliveryBooking: booking.IsDeliveryBooking,
        ApiVersion: 1,
        GoogleOdrdTripId: booking.GoogleOdrdTripId,
        TrackingInfo: ConvertToBookingTrackingDetails(booking),
        Locations: booking.Locations,
    }
}

/**
 * Convert BookingTrackingResult API contract array in to BookingInfo data model array.
 * */
export function ConvertBookingTrackingResultToBookingInfoBulk(bookings: BookingTrackingResult[]): BookingInfo[] {
    return bookings.map(ConvertBookingTrackingResultToBookingInfo);
}

/**
 * Convert BookingTrackingResult API contract in to BookingTrackingDetails data model.
 * */
function ConvertToBookingTrackingDetails(booking: BookingTrackingResult): BookingTrackingDetails {

    const trackingDetails: BookingTrackingDetails = {
        BookingID: booking.BookingID,
        VehicleLocation: null,
        CarNumber: booking.CarNumber ?? null,
        DriverName: booking.DriverName,
        DriverImage: booking.DriverImage,
        DriverID: booking.DriverID ?? null,
        BookingStatusID: booking.BookingStatusID,
        DispatchStartTime: null,
        DropoffTime: null,
        PickedupTime: null,
        ProofOfDeliveryUrls: null,
        DriverPhotoExists: true,
        WasJitPreauthFailure: booking.WasJitPreauthFailure,
        JitPreauthFailureMessage: booking.JitPreauthFailureMessage
    }

    // vehicle location can be undefined or an empty array.
    if (booking.vechileLocation && booking.vechileLocation.length > 0) {
        // Most recent location is the last element of the array
        trackingDetails.VehicleLocation = booking.vechileLocation[booking.vechileLocation.length - 1];
    }

    return trackingDetails;
}

/**
 * Convert BookingInfoV2 API contract in to BookingInfo data model.
 * */
export function ConvertBookingInfoV2ToBookingInfo(booking: BookingInfoV2): BookingInfo {

    const bookingV1: BookingInfo = {
        BookingID: parseInt(booking.InternalBookingId),
        Time: moment(booking.RequestedDateTime).format("MM/DD/YYYY HH:mm:ss"),
        LocalTime: booking.LocalRequestedTime,
        MaxSeat: "1-4",
        CarConditionID: booking.VehicleType,
        DispatchBookingID: booking.UserBookingReference,
        ContactName: booking.BookingContact.Name,
        PassengerName: booking.Pickup.Contact.Name,
        NotesToDriver: booking.Pickup.DriverInstructions ?? "",
        CustomerPhone: booking.Pickup.Contact.PhoneNumber,
        IsDeliveryBooking: false,
        AccessCode: booking.MetaData.AccessCode,
        ReadAccessCode: booking.MetaData.ReadAccessCode,
        DataOwnership: booking.MetaData.DataOwnership,
        ApiVersion: 2,
        TrackingInfo: ConvertBookingInfoV2ToBookingTrackingDetails(booking),
        BookingTrackingBaseUrl: booking.MetaData.BookingTrackingBaseUrl,
        Locations: [// multi-stop support has not been implemented yet for Booking API V2
            {
                AddressText: booking.Pickup.Address.FullTextAddress ?? "",
                GeoPoint: booking.Pickup.Address.GeoLocation
            }
        ],
    };

    if (booking.Dropoff) {
        bookingV1.Locations.push({
            AddressText: booking.Dropoff.Address.FullTextAddress ?? "",
            GeoPoint: booking.Dropoff.Address.GeoLocation
        });
    }

    return bookingV1;
}

/**
 * Convert BookingInfoV2 API contract array in to BookingInfo data model array.
 * */
export function ConvertBookingInfoV2ToBookingInfoBulk(bookings: BookingInfoV2[]): BookingInfo[] {
    return bookings.map(ConvertBookingInfoV2ToBookingInfo);
}

/**
 * Status IDs from V2 API (Global Booker) is slightly different from the status IDs from V1 (Booking API). But the statuses are mostly similar.
 * A conversion is needed from StatusV2 to StatusV1 in order to have a consistent behaviour in the UI.
 */
function ConvertStatusV2ToStatusV1(status: BookingStatusV2): BookingStatus {
    if (status === BookingStatusV2.Pending) return BookingStatus.NotDispatched;
    if (status === BookingStatusV2.Dispatch) return BookingStatus.Dispatching;
    if (status === BookingStatusV2.Assigned) return BookingStatus.Assigned;
    if (status === BookingStatusV2.Accepted) return BookingStatus.Accepted;
    if (status === BookingStatusV2.PickedUp) return BookingStatus.PickedUp;
    if (status === BookingStatusV2.Complete) return BookingStatus.Completed;
    if (status === BookingStatusV2.Cancel) return BookingStatus.Cancelled;
    if (status === BookingStatusV2.NoJob) return BookingStatus.NoJob;
    if (status === BookingStatusV2.NoCarAvailable) return BookingStatus.Cancelled;

    return BookingStatus.NotDispatched;
}

/**
 * Convert BookingInfoV2 API contract in to BookingTrackingDetails data model.
 * */
function ConvertBookingInfoV2ToBookingTrackingDetails(booking: BookingInfoV2): BookingTrackingDetails {
    return {
        BookingID: parseInt(booking.InternalBookingId),
        DriverName: booking.Driver?.DriverName ?? null,
        DriverImage: null,
        BookingStatusID: ConvertStatusV2ToStatusV1(booking.StatusID),
        CarNumber: booking.CarNumberPlate ?? null,
        DispatchStartTime: null,
        DropoffTime: null,
        PickedupTime: null,
        ProofOfDeliveryUrls: null,
        DriverID: booking.Driver ? parseInt(booking.Driver.DriverIdentifier) : null,
        VehicleLocation: null,
        DriverPhotoExists: booking.Driver?.DriverPhotoExists ?? false,
        WasJitPreauthFailure: false,
        JitPreauthFailureMessage: null
    }
}

/**
 * Convert LightweightBookingStatus API contract (response of the /BookingStatusBulk request) to BookingTrackingDetails data model.
 * */
function ConvertLightweightBookingStatusToBookingTrackingDetails(update: LightweightBookingStatus): BookingTrackingDetails {
    return {
        BookingID: update.BookingId,
        BookingStatusID: update.BookingStatusId,
        CarNumber: update.CarNumberPlate,
        DriverID: update.DriverId,
        DriverImage: null,
        DriverName: null,
        VehicleLocation: update.VehicleLocation,
        DispatchStartTime: null,
        DropoffTime: null,
        PickedupTime: null,
        ProofOfDeliveryUrls: null,
        DriverPhotoExists: true,
        WasJitPreauthFailure: update.WasJitPreauthFailure,
        JitPreauthFailureMessage: update.JitPreauthFailureMessage
    }
}

/**
 * Convert a list of LightweightBookingStatus objects to a list of BookingTrackingDetails objects.
 * */
export function ConvertLightweightBookingStatusToBookingTrackingDetailsBulk(updates: LightweightBookingStatus[]): BookingTrackingDetails[] {
    return updates.map(ConvertLightweightBookingStatusToBookingTrackingDetails);
}

/**
 * Convert LightweightBookingStatusV2 API contract (response of the /BookingStatusBulk request) to BookingTrackingDetails data model.
 * */
function ConvertLightweightBookingStatusV2ToBookingTrackingDetails(update: LightweightBookingStatusV2): BookingTrackingDetails {
    return {
        BookingID: parseInt(update.InternalBookingId),
        BookingStatusID: ConvertStatusV2ToStatusV1(update.StatusID),
        CarNumber: update.CarNumberPlate,
        DriverID: update.Driver ? parseInt(update.Driver.DriverIdentifier) : null,
        DriverImage: null,
        DriverName: update.Driver?.DriverName ?? null,
        VehicleLocation: null,
        DispatchStartTime: null,
        DropoffTime: moment(update.CompletedDateTime),
        PickedupTime: moment(update.PickedUpDateTime),
        ProofOfDeliveryUrls: null,
        DriverPhotoExists: update.Driver?.DriverPhotoExists ?? false,
        WasJitPreauthFailure: false,
        JitPreauthFailureMessage: null
    }
}

/**
 * Convert a list of LightweightBookingStatusV2 objects to a list of BookingTrackingDetails objects.
 * */
export function ConvertLightweightBookingStatusV2ToBookingTrackingDetailsBulk(updates: LightweightBookingStatusV2[]): BookingTrackingDetails[] {
    return updates.map(ConvertLightweightBookingStatusV2ToBookingTrackingDetails);
}