import React, { useEffect } from "react";
import { useAppState } from "../../Redux/ReduxHooks";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { SdkLoadingState } from "./PayPalState";
import { PayPal } from "../PayPal/PayPal";
import { Dispatch } from "../Dispatch";
import braintree from "braintree-web";
import { AddPayPalFunnel } from "./AddPayPalFunnel";
import { GetOrMakeBraintreeClient } from "../Payment/GetOrMakeBraintreeClient";

/**
 * Manages the deferred loading of the PayPal SDK. 
 * Instead of loading at startup, we wait for a signal from the UI (e.g. pickup address selected).
 * This avoids slowing down the startup experience.
 * This component doesn't render anything; it just receives and sends events.
 */
export const PayPalLoadingWatcher: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.AddPayPal);
    const loadingState = useAppState(i => i.PayPal.LoadingStatus);
    const hasPickup = useAppState(i => !!i.booking.Locations[0].Address);
    const googlePayLoadingState = useAppState(i => i.GooglePay.LoadingStatus);
    const isAddPaymentDialogOpen = useAppState(i => i.payment.IsAddPaymentDialogOpen);

    // pre-existing states that should trigger a load
    useEffect(() => {

        if (hasPickup) {
            Dispatch.PayPal.ReadyToLoad();
        }

        // load the sdk when the user opens Add Payment dialog from the user profile
        if(isAddPaymentDialogOpen) {
            Dispatch.PayPal.ReadyToLoad();
        }

    }, [hasPickup, isAddPaymentDialogOpen]);

    // once only load
    useEffect(() => {

        // PayPal is supported
        if (!isFeatureEnabled) return;

        // GooglePay loading is still in progress or about to start. Waiting for GooglePay SDK loading because only one Braintree client creation request can be sent at any time.
        if (googlePayLoadingState === SdkLoadingState.InProgress || googlePayLoadingState === SdkLoadingState.RequestedNotStarted) return;

        // UI flow has advanced far enough
        if (loadingState === SdkLoadingState.RequestedNotStarted) {
            StartLoadingPayPalSdk();
        }
    }, [isFeatureEnabled, loadingState, googlePayLoadingState]);

    return null;
}

/**
 * Intended to run only once.
 * This method just manages the redux state changes.
 */
async function StartLoadingPayPalSdk() {

    Dispatch.PayPal.LoadStarting();

    const isSuccess = await TryLoadUpToPayPalSdk();

    if (isSuccess) {
        Dispatch.PayPal.LoadSucceeded();
    }
    else {
        Dispatch.PayPal.LoadFailed();
    }
}

/**
 * Loads the PayPal SDK. There are two parts, each of which could fail:
 * 1) Braintree SDK (including the Client Token from API)
 * 2) PayPal SDK.
 */
async function TryLoadUpToPayPalSdk(): Promise<boolean> {

    // 1) Braintree SDK
    const braintreeClient = await GetOrMakeBraintreeClient();

    if (!braintreeClient) {
        return false;
    }

    // 2) PayPal SDK
    let payPalClient: braintree.PayPal;

    try {
        payPalClient = await braintree.paypal.create({
            client: braintreeClient
        });
    }
    catch (error) {
        AddPayPalFunnel.Exception("PayPal Client", error, false);
        return false;
    }

    // OK!
    PayPal.SdkIsReady(braintreeClient, payPalClient);
    return true;
}