import { ParcelDeliveryOptions, RestrictedItemCheck } from "../../../Services/BookingEntities"

/** 
 * Denotes the delivery option used for a parcel booking 
 */
export enum DeliveryOptionKind {
    HandDirectlyToRecipient = "HandDirectlyToRecipient",
    Unattended = "Unattended",
    SameDayAlcohol = "SameDayAlcohol", 
    ProofOfAge = "ProofOfAge",
    SignatureRequired = "SignatureRequired",

}

/** Delivery help text for the selected delivery option */
export const DeliveryOptionHelpText = {
	HandDirectlyToRecipient: "This delivery cannot be left unattended and must be handed to any recipient at the destination.",
	Unattended: "This delivery requires a proof of delivery photo.",
	SameDayAlcohol: "Alcohol delivery can only be made to a nominated recipient over 18 years of age. The recipient will be asked for ID and signature, which may be recorded along with their date of birth. Their name must be the same as dropoff contact name.",
	ProofOfAge: "This delivery requires proof of age from any recipient at the destination, and the recipient must be over 18 years of age.",
	SignatureRequired: "This delivery requires proof of delivery signature from any recipient at the destination."
}

/**
 * TODO: move this into a namespace later.
 */
export function ConvertToApiModel(option: DeliveryOptionKind): ParcelDeliveryOptions {

    switch (option) {
        case DeliveryOptionKind.HandDirectlyToRecipient:
            return {
                AllowUnattendedDelivery: false,
                ParcelRestrictedItemCheck: RestrictedItemCheck.None,
                RequireProofOfDelivery: false,
            };

        case DeliveryOptionKind.ProofOfAge:
            return {
                AllowUnattendedDelivery: false,
                ParcelRestrictedItemCheck: RestrictedItemCheck.BasicProofOfAge,
                RequireProofOfDelivery: false,
            };

        case DeliveryOptionKind.SameDayAlcohol:
            return {
                AllowUnattendedDelivery: false,
                ParcelRestrictedItemCheck: RestrictedItemCheck.RSA,
                RequireProofOfDelivery: true,
            };

        case DeliveryOptionKind.SignatureRequired: 
            return {
                AllowUnattendedDelivery: false,
                ParcelRestrictedItemCheck: RestrictedItemCheck.None,
                RequireProofOfDelivery: true,
            };

        case DeliveryOptionKind.Unattended:
            return {
                AllowUnattendedDelivery: true,
                ParcelRestrictedItemCheck: RestrictedItemCheck.None,
                RequireProofOfDelivery: true,
            };

        default:
            const exhaustiveCheck: never = option;
            throw new Error(`Unknown option ${option}`);
    }
}