import React from 'react';
import { Dispatch } from '../../Dispatch';
import { RecentTripHistory } from './RecentTripHistory';
import { FeatureFlags } from '../../../Config/FeatureFlags';
import { QuickBookExpandedSection } from '../../UILogicControl/UILogicControlEntities';
import CredentialsController from '../../../modules/Authentication/Login/CredentialsController';
import { LogEvent } from '../../../utils/LogEvent';
import { BookingInfo } from '../../MyBookings/MyBookingEntities';

interface RecentTripProps {
    isUserLoggedIn : boolean;
    listState: QuickBookExpandedSection;
}

/**
 * Show Sign up button/link, if user is not logged-in 
 * Load <RecentTripHistory /> with sample booking template details
*/
class EmptyRecentTrips extends React.Component<RecentTripProps> {
    
    // Opens the signup modal for register or Login modal based on the current brand.
    OpenLoginOrSignupModal = () => {
        if (FeatureFlags.NoSignup) {
            LogEvent.LoginFromQuickBook();
            new CredentialsController().DoLogin();            
        } else {
            Dispatch.Payment.HideAddPaymentCardScreenAfterSignup();
            LogEvent.SignupFromQuickBook();
            new CredentialsController().DoSignup();
        }
        
    }

    render(){

        const { listState, isUserLoggedIn } = this.props;

        // Recent trip sample booking details
        let booking = {} as BookingInfo;        
        booking.Time = "";
        booking.Locations = [
            {
                GeoPoint: null,
                AddressText: "Pickup location"
            },
            {
                GeoPoint: null,
                AddressText: "Dropoff location"
            }
        ];

        const list : BookingInfo[] = [booking];
        
        const maxHeightClass = isUserLoggedIn && FeatureFlags.BookingTemplates && "recent-list-expand";

        const collapseListClass = listState !== QuickBookExpandedSection.RecentBookings && "empty-recent-list-collapse";

        const buttonText = FeatureFlags.NoSignup ? "Log in" : "Sign up";

        return(
            <div className={`empty-recent-list ${maxHeightClass} ${collapseListClass}`}>
                <div className="recent-trips-template">
                    {
                        isUserLoggedIn ?                               
                            <p>
                                Should you want to book again, your most recent trips will be stored here.
                            </p>
                        :   <p>
                                We’ve made booking regular trips faster. &nbsp;
                                <a href="#" className="signup-link" onClick={() => this.OpenLoginOrSignupModal()}>{buttonText}</a> to try the new experience.
                            </p>                              
                    }    
                </div>
                <div className="arrow-down"></div>
                <RecentTripHistory recentTrips={list} disabledButton={true} listState={QuickBookExpandedSection.RecentBookings}/>
                {
                    isUserLoggedIn ? null
                    :  
                        <div className="btn-quick-login">
                            <button className="btn-signup" onClick={() => this.OpenLoginOrSignupModal()}>{buttonText}</button>
                    </div>
                }
            </div>
        )
    }
}

export default EmptyRecentTrips;