import { UserProfileNavbarItem } from "./UserProfileNavEntities";

/** Compound type for all possible menu actions(only SelectNavItem) for now.
 * There won't be any other action, but wanted to keep it consistent
*/
export type NavAction = SelectUserProfileNavItemAction;

/** Select an item from the menu */
export interface SelectUserProfileNavItemAction {
    type: UserProfileNavActionTypes.SelectUserProfileNavItem;
    item: UserProfileNavbarItem;
}

/** Menu action types. */
export enum UserProfileNavActionTypes {
    /** Select a menu item */
    SelectUserProfileNavItem = 'Select User profile Nav',
}