import * as LookupServices from "../../utils/service-vehicles/LookupServices";
import { VehicleOption, ConditionLookupTables, MaxiTaxiLookUpType, ApiVehicleOption } from './Redux/ConditionEntities';
import { ServiceInfo } from "../../utils/service-vehicles/ServiceMetadata";
import services from '../../utils/service-vehicles/ServiceMetadata';
import { FeatureFlags } from "../../Config/FeatureFlags";
import { Config } from "../../Config/Config";

export function ParseVehicleConditionHelper(conditions: ApiVehicleOption[]) : ConditionLookupTables {
    var ConditionList: VehicleOption[] = [], MaxiTaxiLookUp: MaxiTaxiLookUpType = { };
    var hasWheelChair: boolean = false, hasMaxiTaxi: boolean = false, wheelChairPlaceHolder: any = { "MaxSeat" : 0 };
    const nextAvailable = conditions.some(v => /limo/i.test(v['Name'])) ? services.anySA : services.any;
    ConditionList.push(MakeVehicleFromService(nextAvailable));     

    for (var condition of conditions) {
        const service = LookupServices.byConditionId(condition.ApiId);
        if (service === services.any) continue;

        if (service === services.wheelchair) {
            // Allow wheelchair bookings only if the maximum number of seats is less than or equal to the configured value.
            if (condition.MaxSeat >= wheelChairPlaceHolder.MaxSeat && condition.MaxSeat <= Config.WheelchairMaxSeat) {
                hasWheelChair = true;
                wheelChairPlaceHolder = condition;
            }
        }
        else if (service === services.maxi) {
            hasMaxiTaxi = true;
            MaxiTaxiLookUp[condition.MaxSeat] = condition;
        }
        else {
            ConditionList.push({ Service: service, ApiVehicle : condition }); 
        }
    }

    /** Add wheelchair & MAXI TAXI*/
    if (hasWheelChair === true) { 
        ConditionList.push(MakeVehicleFromService(services.wheelchair, wheelChairPlaceHolder)); 
    }
    if (hasMaxiTaxi === true) { 
        ConditionList.push(MakeVehicleFromService(services.maxi)); 
    }

    // force the items into a standard ordering.
    ConditionList.sort((a, b) => a.Service.StandardSortOrder - b.Service.StandardSortOrder);

    return {ConditionList: ConditionList, MaxiTaxiLookUp: MaxiTaxiLookUp};
};

function MakeVehicleFromService(service: ServiceInfo, wheelChairCondition?: ApiVehicleOption): VehicleOption {
    return !!wheelChairCondition ? { Service: service, ApiVehicle: wheelChairCondition } : { Service: service, RequiresPaxCount: !FeatureFlags.BookingApiV2 && service.isMaxiTaxi };
}
