import { FailureResult } from "../../Services/ServiceEntities";
import { LogEvent } from "../../utils/LogEvent";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { PaymentFunnelExitDetails } from "../Payment/PaymentFunnelExit";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";

/** 
 * Records errors and exceptions in various stages of guest credit card booking flow.
 */
export namespace GuestCreditCardFunnel {

    /** 
     * Record an exit from the credit card funnel with a JavaScript or BrainTree error.
     * Since it comes from a JavaScript catch block, we can't type it properly. 
     */
    export function Exception(stageName: string, error: any, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.Exception(error);

        NoteFunnelExit(stageName, "Exception", details, isUserAction);
    }

    /** Record an exit from the credit card funnel with a failed API call. */
    export function ApiError(stageName: string, apiError: FailureResult, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.ApiError(apiError);

        NoteFunnelExit(stageName, "API Error", details, isUserAction);
    }

    /**
     * The point is to record where along the flow the user dropped off.
     * Also displays an error message asking the user to try a different payment method.
     */
    function NoteFunnelExit(stageName: string, errorKind: string, details: Record<string, string>, isUserAction: boolean) {

        details.IsUserAction = String(isUserAction);
        LogEvent.GuestCreditCardFunnelExit(stageName, errorKind, details);

        if (isUserAction) {
            Dispatch.Dialog.SetSimpleErrorMessage(WellKnownMessageKind.CardCouldNotBeVerified);
            Dispatch.Dialog.ShowDialog(DialogKind.ErrorMessageWithNoTitle);            
        }

        Dispatch.UILogicControl.HideLoading();
    }
}