import React from "react";
import { Dispatch } from "../Dispatch";
import { MenuItem } from "@mui/material";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import { ApplePay } from "./ApplePay";
import { ApplePayPlaceholder } from "../Payment/PaymentEntities";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { useAppState } from "../../Redux/ReduxHooks";
import { ConsiderGuestPaymentReset } from "../GuestPayment/ConsiderGuestPaymentReset";

/** 
 * Renders the Add ApplePay entry in the payment method dropdown in the booking form.
 * Only present when the feature is supported and the device/browser allows Apple Pay payments.
 */
export const AddApplePayDropDownEntry: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.AddApplePay);
    const isCnpDisallowed = useAppState(OptionalUI.CnpDisallowedByMetered);
    const isPaymentSystemAvailable = useAppState(i => i.payment.IsPaymentSystemAvailable);

    // feature is disabled    
    if (!isFeatureEnabled) return null;
    if (isCnpDisallowed) return null;
    if (!isPaymentSystemAvailable) return null;

    // current device/browser doesn't suppot Apple Pay payments.
    if (!ApplePay.CanMakePaymentsWithApplePay()) return null;

    return (
        <MenuItem className="cardMenuItem" value="#" disableGutters
            onClick={OnApplePayButtonClicked}
        >
            <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.PaymentType.Selector.ApplePay)} />
            <span className="cardSelectorCardName">Apple Pay</span>
        </MenuItem>
    );
}

/** Event handler for clicking on the Apple Pay menu item. */
function OnApplePayButtonClicked() {

    Dispatch.Payment.ClearCardError();
    Dispatch.UILogicControl.CloseCardSelectorDropdown();
    Dispatch.Booking.PaymentMethod(ApplePayPlaceholder);

    // clear any other guest payment method
    ConsiderGuestPaymentReset();
}