
/** 
 * Redux store "PayPal" state slice.
 * Manages the background loading of the SDK.
 */
export interface PayPalState {

    /** The progress or result of loading the PayPal SDK. */
    LoadingStatus: SdkLoadingState;
}

/** The workflow states for loading a payment SDK such as PayPal or Apple Pay. */
export enum SdkLoadingState {

    /** Not yet ready to load. Wait for the right UI signal. */
    NotRequested = "NotRequested",

    /** UI has indicated it is time to load. Background loader should pick this up and start loading. */
    RequestedNotStarted = "RequestedNotStarted",

    /** Loading in progress. Don't start a new load. Show a spinner. */
    InProgress = "InProgress",

    /** Load finished with an error. PayPal can't be used. */
    FinishedError = "FinishedError",

    /** Load finished successfully. Ready for tokenisation. */
    FinishedSuccess = "FinishedSuccess",
}

export const PayPalInitialState: PayPalState = {

    LoadingStatus: SdkLoadingState.NotRequested,
}