import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../../appState';
import { BookingFormKind } from '../../UILogicControl/UILogicControlEntities';
import { BrandedImage, GetBrandedUrl } from '../../Utils/BrandedContentUrls';

interface TaxiIconProps {
	ActiveForm: BookingFormKind;
}

/** The taxi icon used in the taxi vs parcel selector in the booking form */
const TaxiIcon: React.FC<TaxiIconProps> = (props) => {

	const taxiIconImage = props.ActiveForm === BookingFormKind.PassengerBooking ? GetBrandedUrl(BrandedImage.TaxiIconWhiteSvg) : GetBrandedUrl(BrandedImage.TaxiIconBlackSvg);

	return (
		<div className="tabIcon">
			<img src={taxiIconImage} alt="Taxi Icon" ></img>
		</div>
	)
}

function mapStateToProps(state: ApplicationState): TaxiIconProps {
	return {
		ActiveForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
	}
}

export default connect(mapStateToProps)(TaxiIcon);