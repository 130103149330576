import { GeoPoint } from "../../modules/Location/Entities";

/** Really quick helper to convert the data type common to the app into the Google Maps version. */
export function toGoogleLatLng(point: GeoPoint): google.maps.LatLng {
    return new google.maps.LatLng(point.latitude, point.longitude);
}

export function toGoogleLatLngV2(point: GeoPoint): google.maps.LatLngLiteral {
    return {
        lat: point.latitude,
        lng: point.longitude
    }
}
