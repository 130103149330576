import { Api } from '../../Services/Api';
import { Dispatch } from '../Dispatch';

/** moving this to its own file to try to make the bundler / node happy. */
export async function LoadNewOdrdTripId() {

    const result = await Api.Booking.GetTempTripId();
    if (result.isSuccess) {
        Dispatch.Booking.RegenerateOdrdTripId(result.value);
    }
}