import { GetMyBrand, GetValues } from "../../Config/MyAppConfig";
import { Brand } from "../../Config/Entities/Brand";

/**
 * Specify the brand and get the URL of a branded image (varies between brands)
 */
export function SpecifyBrandGetBrandedUrl(image: BrandedImage, brand: Brand): string {

    return `${GetValues().StaticContentBaseUrl}Branding/${brand}/${image}`;
}

/**
 * Gets the URL of a branded image (varies between brands)
 */
export function GetBrandedUrl(image: BrandedImage): string {

    const subfolder = GetCurrentBrandFolder();
    return `${GetValues().StaticContentBaseUrl}Branding/${subfolder}/${image}`;
}

/** Get the subfolder under "Branding" for the current brand. */
function GetCurrentBrandFolder(): string {

    let brand = GetMyBrand();

    return brand;
}

/** URL paths for images that vary between brands. */
export enum BrandedImage {

    /** Primary brand logo displayed in top left corner of the site */
    Logo = 'logo.svg',

    /** 
     * An icon that connotes "Edit", displayed in the Location Chooser.
     * Coloured in the brand's primary colour. 
     */
    EditGlyph = 'edit-icon.svg',

    /**
     * Filled in circle with an "a" inside, symbolising the pickup location.
     * Used in the pickup location input field.
     * Coloured in the brand's primary colour.
     */
    PickupAddressA = 'pickup-icon.svg',

    /**
     * Map marker for the pickup address in the Google Maps map.
     * A filled in circle with a tail forming a map pin.
     * Coloured in the brand's primary colour.
     */
    MapMarkerPickup = 'new-map-marker-pickup.png',

    /**
     * Credit card image displayed along with the title in the My Wallet page.
     * Coloured in the brand's primary colour.
     * */
    Wallet = 'wallet-icon.svg',

    /**
     * The chevron icon pointing left.
     * Coloured in the brand's primary colour.
     * */
    ArrowLeft = 'arrow-left.svg',

    /**
     * Favicon -  The image dislayed on the browser tab.
     * */
    Favicon = 'favicon.ico',

    /**
     * The eye icon in the password input field.
     * Coloured in the brand's primary colour.
     * */
    ShowPassword = 'show-password.svg',

    /**
     * X icon used as the close button in modals.
     * Coloured in the brand's primary colour.
     * */
    CloseIcon = 'close-x.svg',

    /**
     * Phone icon displayed along with the contact number (customer support).
     * Coloured in the brand's primary colour.
     * */
    PhoneIcon = 'phone-icon.svg',

    /**
     * Phone icon displayed along with the contact number (customer support).
     * Coloured in the brand's primary colour.
     * This is a different one for mobile menu.
     * */
    PhoneIconInMenu = 'call-us-menu.svg',

    /**
     * The illustration used in the booking confirmation dialog when the booking was made with a mobile number.
     * The image consists of an arrow coloured in the primary brand colour going from an envilope to a phone.
     * */
    SmsToPhone = 'sms2phone.svg',

    /**
     * The filled circle used in the pickup address row in booking cards.
     * Coloured in the brand's primary colour.
     * */
    DotA = 'a-dot.svg',

    /**
     * The illustration used in confirmation dialogs such as Cancel booking and Remove payment card.
     * */
    ThinkingGirl = 'thinking-girl.png',

    /**
     * The illustration used in error message dialogs.
     * */
    WomanShrugging = 'woman-shrugging.png',

    /**
     * Facebook logo.
     * Coloured in the brand's primary colour.
     * */
    Facebook = 'facebook.svg',

    /**
     * Instagram logo.
     * Coloured in the brand's primary colour.
     * */
    Instagram = 'instagram.svg',

    /**
     * Twitter logo.
     * Coloured in the brand's primary colour.
     * */
    Twitter = 'twitter.svg',

    /** 
     *  PNG format of the favicon. 
     *  Some browsers like Firefox and IE use this image. 
     * */
    FaviconPng = 'favicon-16x16.png',

    /**
     * Illustration used in the booking confirmation dialog when the booking was made with a landline number.
     * */
    BookingConfirmed = 'booking-scheduled.png',

    /** 
     * This image is used in place of the driver's image, if the driver's image is not loaded. 
     * */
    DriverDummy = 'driver-dummy.svg',

    /** 
     * This image is used for loading effect
     * */
    Loading = 'loading.svg',

    /**
     * This image is used as an illustration for successfully adding the payment card
     */
    AddPaymentCardIllustration = 'pay-success-illustration.svg',

    /** 
     * This image is used for Hero Image in contact form
     */
    ContactHeroImage = 'contact-us-hero-head.jpg',

    /** Illustrator used in confirmation dialogs after a successful operation such as adding a saved address. */
    GoodNewsMan = 'good-news-man.png',

    /** A generic Location marker for the map without 'a' or 'b'. Used when an address is not specifically a pickup or dropoff address. */
    GenericMapMarker = 'generic-map-pin.png',

    /** This image is used for parcel booking toggle on/selected */
    ParcelIconWhiteSvg = 'parcel-icon-white.svg',

    /** This image is used for parcel booking toggle off */
    ParcelIconBlackSvg = 'parcel-icon-black.svg',

    /** This image is used for passenger booking toggle on/selected */
    TaxiIconWhiteSvg = 'taxi-icon-white.svg',

    /** This image is used for passenger booking toggle off */
    TaxiIconBlackSvg = 'taxi-icon-black.svg',

    /** Share booking icon used in the share button. */
    ShareBookingIcon = 'share-icon.svg',

    /** Lock icon used in the vehicle selector to represent fixed price. */
    LockIcon = 'lock.svg',
}