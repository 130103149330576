import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { Dispatch } from "../../Dispatch";
import { BookingFormKind } from "../../UILogicControl/UILogicControlEntities";
import ParcelIcon from "./ParcelIcon";

import TaxiIcon from "./TaxiIcon";
import { BookingFormParcelMode } from "./BookingFormParcelMode";
import { BookingFormTab, BookingFormTabs } from "../Widget/Styled/BookingForm/BookingFormModeSelector";

interface PropsFromStore {
    ActiveForm: BookingFormKind;
}

/** Component contains tabs to select the type of booking. e.g: Book a Taxi | Parcel Delivery. */
const BookingFormTypeSelectorCore: React.FC<PropsFromStore> = (props) => {

    function OnTabChange(event: React.SyntheticEvent, value: BookingFormKind) {
        Dispatch.UILogicControl.ActiveBookingForm(value);

        // Select Parcel as default condition and lock the vehicle selector.
        if (value === BookingFormKind.ParcelBooking) {
            BookingFormParcelMode.SwapToParcel();
        }

        if (value === BookingFormKind.PassengerBooking) {
            BookingFormParcelMode.SwapToTaxi();
        }
    }

    return (
        <>
            <BookingFormTabs
                value={props.ActiveForm}
                onChange={OnTabChange}
            >
                <BookingFormTab label="Book a Taxi" icon={<TaxiIcon />} value={BookingFormKind.PassengerBooking}   />
                <BookingFormTab label="Parcel Delivery" icon={<ParcelIcon />} value={BookingFormKind.ParcelBooking}  />
            </BookingFormTabs>
		</>
	)
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    }
}

export const BookingFormTypeSelector = connect(mapStateToProps)(BookingFormTypeSelectorCore);