 import { FormControl, InputLabel } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { GoogleAddressPicker } from "../../AddressPicker/Components/GoogleAddressPicker";
import { LocationTypes } from "../../AddressPicker/Entities/PlaceSearchEntities";
import { ServiceCheckStatus } from "../../Booking/BookingEntities";
import { Dispatch } from "../../Dispatch";
import { GeoPoint } from "../../Location/Entities";
import { SetAddressServiceability } from "../AddressValidation";
import { AddressDetails, AddressValidity } from "../Redux/AddressPointsEntities";

interface PropsFromStore {
    Address: AddressDetails | null;
    LocationVicinity: GeoPoint;
    AddressValidity: AddressValidity;
}

/** Component contains the address input field. Use the GoogleAddressPicker component. */
const AddressInputCore: React.FC<PropsFromStore> = (props) => {

    async function UpdateAddress(place: google.maps.places.PlaceResult, displayAddress: string) {

        // TODO: Need to display any error message in these cases??
        if (!place.geometry) return;
        if (!place.geometry.location) return;
        if (!place.place_id) return;

        Dispatch.AddressPoints.RefreshAddress({
            GooglePlaceId: place.place_id,
            DisplayText: displayAddress,
            GeoLocation: {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            }
        });

        await SetAddressServiceability(place);
    }

    /** Clear the address from the input field. Also remove validity status and pin on the map. */
    function ClearAddress() {
        Dispatch.AddressPoints.ClearAddress();
        Dispatch.AddressPoints.ClearAddressValidity();
    }

    let isAddressInvalid = false;
    let errorMessage = "";

    if (props.AddressValidity.ServiceabilityState.status === ServiceCheckStatus.Error || props.AddressValidity.ServiceabilityState.status === ServiceCheckStatus.KnownBad) {
        isAddressInvalid = true;
        errorMessage = props.AddressValidity.ServiceabilityState.internalErrorMessage;
    }

    return (
        <div className="address-point-input booking-address-field">
            <FormControl variant="standard">
                <InputLabel shrink>
                    Address
                </InputLabel>
                <GoogleAddressPicker
                    LocationType={LocationTypes.AddressStrict}
                    OnPlaceSelected={UpdateAddress}
                    PreferNearbyTo={props.LocationVicinity}
                    SpecifiedPlaceText={props.Address?.DisplayText ?? ""}
                    OnCleared={ClearAddress}
                    IsSelectedAddressInvalid={isAddressInvalid}
                    AutoFocus={true}
                    IncludeFavouriteAddresses={false}
                    PlaceholderText="Enter address"
                />
                {isAddressInvalid && <div className="booking-address-message">{errorMessage}</div>}
            </FormControl>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        Address: state.addressPoints.ActiveAddressPoint.Address,
        LocationVicinity: state.location.reliableLocation.value.geoPoint,
        AddressValidity: state.addressPoints.AddressValidity
    }
}

export const AddressInput = connect(mapStateToProps)(AddressInputCore);