import React, { Component } from 'react';
import './loading.scss';
import { getContentUrl, ContentURL }  from '../../modules/Utils/ContentURL';

interface LoadingProps {
    CanShowPriceGuaranteeToggle?: boolean;
}

class Loading extends Component<LoadingProps> {
    constructor(props: LoadingProps) {
        super(props)
    }

    render() {

        const assignTopPositionCssClass = this.props.CanShowPriceGuaranteeToggle ? "add-top-position" : "";

        return(
            <div className={`loading-panel ${assignTopPositionCssClass}`}>
                <img src={getContentUrl(ContentURL.images.Loading)} alt="Loading"/>
            </div>
        );
    }
}

export default Loading;