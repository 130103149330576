import React from 'react';
import { connect } from 'react-redux';
import './CommonDialogHeader.scss';
import { ApplicationState } from '../../appState';
import { DialogConfigLookup } from "./DialogConfig";
import { DialogKind } from './DialogEntities';

interface DialogDescriptionProps { 
    TopmostDialog: DialogKind;
    Auth0SuccessMessage?: string | undefined;
};

/**
 * Dialog description
 */
export const CommonDialogDescriptionCore: React.FC<DialogDescriptionProps> = (props) => {

    var dialogConfig = DialogConfigLookup(props.TopmostDialog);

    if (!dialogConfig?.Description) return null;

    return (
        <div className="dialog-description">
            <span>{props.Auth0SuccessMessage ? props.Auth0SuccessMessage : dialogConfig.Description}</span> 
        </div>
    );
}

function mapStateToProps(state: ApplicationState): DialogDescriptionProps {
    return {
        TopmostDialog: state.dialog.topmostDialog!,
        Auth0SuccessMessage: state.authentication.Auth0SuccessMessage
    };
}

export const CommonDialogDescription = connect(mapStateToProps)(CommonDialogDescriptionCore);
