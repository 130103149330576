import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { GeoPoint } from "../Location/Entities";
import { GoogleMapInitialState, GoogleMapState } from "./GoogleMapState";

const slice = new ReduxStoreSlice<GoogleMapState>("GoogleMap", GoogleMapInitialState);

/** Dispatcher for actions in the GoogleMap state slice. */
export const GoogleMapDispatchV2 = {

    /** Change the pickup location on the map. Can cause the map center to change. */
    PickupSelected: slice.Action("Pickup Selected", PickupSelected),

    /** Change the dropoff location on the map. Can cause the map center to change. */
    DropoffSelected: slice.Action("Dropoff Selected", DropoffSelected),

    /** Removes the pickup location and recalculates the map center. */
    PickupCleared: slice.EmptyAction("Pickup Cleared", PickupCleared),

    /** Removes the dropoff location and recalculates the map center. */
    DropoffCleared: slice.EmptyAction("Dropoff Cleared", DropoffCleared),

    /** Set the route from the pickup from the dropoff. It comes from the fare estimate. */
    PlannedRoute: slice.Action("Planned Route", PlannedRoute),
}

/** Reducer for the GoogleMap store slice */
export const GoogleMapReducerV2 = slice.MakeCombinedReducer();

/** Change the pickup location on the map. Can cause the map center to change. */
function PickupSelected(state: GoogleMapState, pickupLocation: GeoPoint): GoogleMapState {

    // direct change to pickup:
    state = { ...state, pickupLocation: pickupLocation };

    // redo computed state
    return RecomputeMapBounds(state);
}

/** Change the dropoff location on the map. Can cause the map center to change. */
function DropoffSelected(state: GoogleMapState, dropoffLocation: GeoPoint): GoogleMapState {
    // direct change to dropoff:
    state = { ...state, dropoffLocation: dropoffLocation };

    // redo computed state
    return RecomputeMapBounds(state);
}

/** Removes the pickup location and recalculates the map center. */
function PickupCleared(state: GoogleMapState): GoogleMapState {

    // clear pickup (from newState)
    const { pickupLocation, ...newState } = state;

    // drop the route as a result
    newState.PlannedRoute = null;

    // redo computed state
    return RecomputeMapBounds(newState);
}

/** Removes the dropoff location and recalculates the map center. */
function DropoffCleared(state: GoogleMapState): GoogleMapState {

    // clear dropoff (from newState)
    const { dropoffLocation, ...newState } = state;

    // drop the route as a result
    newState.PlannedRoute = null;

    // redo computed state
    return RecomputeMapBounds(newState);
}

/** Set the route from the pickup from the dropoff. It comes from the fare estimate. */
function PlannedRoute(state: GoogleMapState, route: google.maps.LatLngLiteral[]): GoogleMapState {
    return {
        ...state,
        PlannedRoute: route,
    };
}

/** Recompute the MapCenter and MapZoom properties based on the existence of the pickup and destination addresses. */
function RecomputeMapBounds(state: GoogleMapState): GoogleMapState {

    let newCenter: GeoPoint | undefined;
    let zoomLevel: number | undefined;

    if (!state.pickupLocation && !state.dropoffLocation) {
        newCenter = undefined;
        zoomLevel = undefined;
    }
    else if (!state.pickupLocation) {
        newCenter = state.dropoffLocation;
        zoomLevel = 18;
    }
    else if (!state.dropoffLocation) {
        newCenter = state.pickupLocation;
        zoomLevel = 18;
    }
    else {
        newCenter = GetMidpoint(state.pickupLocation, state.dropoffLocation);
        zoomLevel = undefined;
    }

    state = { ...state, mapCenter: newCenter, zoomLevel: zoomLevel };
    return state;
}

/** Returns the middle of two GeoPoints. I'm not worried about wrapping effects since we are Aus only. */
function GetMidpoint(point1: GeoPoint, point2: GeoPoint): GeoPoint {

    const latitude: number = (point1.latitude + point2.latitude) / 2;
    const longitude: number = (point1.longitude + point2.longitude) / 2;

    const result: GeoPoint = {
        latitude,
        longitude,
    };

    return result;
}