import React from "react";
import { FC } from "react";
import { useParams, Redirect } from "react-router";
import { BookingAuthority } from "../../Services/BookingEntities";
import { Dispatch } from "../Dispatch";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { BookingLink } from "./Redux/TrackingLinksState";

interface LinkHandlerProps {
    /** Based on the URL path /b or /b2. */
    LinkVersion: number;
}

/**
 * Handles the "/b/<booking ID>/<access code>" and "/b2/<booking ID>/<access code>" URLs.
 * Captures the Booking Authority for later processing.
 */
export const BookingLinkHandler: FC<LinkHandlerProps> = (props) => {

    const params = useParams<MyRouteParams>();
    const link = TryLoadLinkDetails(params);

    if (link) {
        const bookingLink: BookingLink = {
            LinkVersion: props.LinkVersion,
            BookingKey: link
        };

        Dispatch.TrackingLinks.NoteLinkFromUrl(bookingLink);
    }
    else {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.InvalidBookingLink);
    }

    return <Redirect to="/" />;
}

/**
 * Check the validity of the booking link. Register it or report an error to the user.
 */
function TryLoadLinkDetails(params: MyRouteParams): BookingAuthority | null {

    if (!params.BookingId) return null;
    if (!params.AccessCode) return null;

    const bookingId = Number.parseInt(params.BookingId, 10);
    if (Number.isNaN(bookingId)) return null;

    return {
        bookingId: bookingId,
        hashCode: params.AccessCode,
    };
}

/** The parameters derived from the URL. This has to match the route definition in App.tsx. */
interface MyRouteParams {
    BookingId: string;
    AccessCode: string;
}