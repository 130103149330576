import React from 'react';
import appstore from '../../../appStore';
import { OptionalUI } from '../../../modules/Booking/OptionalParts/OptionalUI';
import { DialogKind } from '../../../modules/Dialog/DialogEntities';
import { Dispatch } from '../../../modules/Dispatch';
import { GeoPoint } from '../../../modules/Location/Entities';
import { BookingTrackingProps } from '../../../modules/MyBookings/UI/ExpandedSection';
import { MapMarkers } from '../MapMarkers';
import './TaxiTrackingGoogleMap.scss';
import { MapPurpose } from './GoogleMapProps';
import { GoogleMapRaw } from './GoogleMapRaw';

/**
 * Google Map component optimised for tracking the taxi on a live booking.
 */
export const TaxiTrackingGoogleMap: React.FC<BookingTrackingProps> = (props) => {
    return (
        <div className="tracking-map-wrapper" onClick={OnMapClicked}>
            <GoogleMapRaw
                mapCenter={GetMapCenter()}
                zoom={15}
                vehicleLocation={GetTaxiMarkerLocation()}
                carNumber={props.BookingDetails.TrackingInfo.CarNumber}
                PickupMarker={MapMarkers.PickupLocation}
                PlannedRoute={null}
                Purpose={MapPurpose.TaxiTracking}
                Locations={GetLocations()}
            />
        </div>
    );

    /** 
     *  Gets the vehicle location for the Taxi map marker. 
     *  It will be undefined (no marker) when there is no Taxi assigned or its location isn't known.
     */
    function GetTaxiMarkerLocation(): GeoPoint | undefined {

        const vehicleLocation = props.BookingDetails.TrackingInfo.VehicleLocation;
        if (!vehicleLocation) return undefined;

        const result: GeoPoint = {
            latitude: vehicleLocation.Latitude,
            longitude: vehicleLocation.Longitude,
        };

        return result;
    }

    /** Normally centered on the Taxi. */
    function GetMapCenter(): GeoPoint {

        const taxiLocation = GetTaxiMarkerLocation()
        if (taxiLocation) return taxiLocation;

        // fallback (not expected)
        return appstore.getState().location.reliableLocation.value.geoPoint;
    }

    /** The user clicks on the <div> which hosts the tracking map. */
    function OnMapClicked() {

        // only supported on desktop devices
        if (OptionalUI.MobileLayout(appstore.getState())) return;

        Dispatch.MyBookings.AddMapFocusedBookingID(props.BookingDetails.BookingID);
        Dispatch.Dialog.ShowDialog(DialogKind.TaxiTrackingMap);
    }

    /** The list of locations of the booking to be displayed in the map. */
    function GetLocations() {

        // very rarely, some locations may not have GeoPoints when geopoints are not available in the database.
        const locationsWithGeoPoints = props.BookingDetails.Locations.filter(location => location.GeoPoint);
        if (locationsWithGeoPoints.length === 0) return null;

        const locationsForMap = locationsWithGeoPoints.map(location => {
            return {
                latitude: location.GeoPoint!.Latitude,
                longitude: location.GeoPoint!.Longitude,
            }
        });

        return locationsForMap;
    }
}