import { LogEventInGA4 } from './../../utils/LogEventsInGoogleAnalytics';
import appstore from "../../appStore";
import { Dispatch } from '../Dispatch';
import { EnsureTncDataLoaded, IsNewTncConsentNeeded, RetrieveTnc } from "../TncPrivacy/TncPrivacyHelper";
import { TaskKind, TaskStatus } from "../LoginValidation/LoginValidationEntities";
import { DialogKind } from "../Dialog/DialogEntities";
import { IsVerificationNeeded } from "./AuthHelper";
import { VerifyPhoneForProfile } from "../Verification/VerifyPhoneForProfile";
import { RemoveProfileValidationListener } from "../LoginValidation/LoginValidationHelper";
import { Api } from "../../Services/Api";
import { RefreshProfileAfterDataChange } from "./GetUserProfileService";
import { LogEvent } from "../../utils/LogEvent";
import { IsValidNumber } from "../Utils/PhoneValidation";
import { MyStorage } from "../../Storage";
import { LoadUserConsentStatus } from "../LegalDocuments/LoadUserConsentStatus";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { UserConsentRequest } from "../../Services/LegalDocumentsContracts";
import { LoginStatusKind } from './AuthEntities';

/**
 * Prerequisite:
 * Successful completion of function LoadUserProfile().
 *
 * Responsibilities:
 *    1) Retrieve and see if there is a new version of terms and conditions;
 *    2) Check whether is profile is newly created, if yes, go function ;
 *    3) Validate information for general logged in users, go function .
 */
export async function ValidateProfile(isNewSignUp: boolean) {

    if (!appstore.getState().authentication.UserProfile) return;

    // terms and conditions data will be needed for profile verification later
    if (FeatureFlags.LegalDocumentsV2) {        
        await LoadUserConsentStatus();
    } else {
        await EnsureTncDataLoaded();
    }    

    // Update some information to Bcc only for newly signed up users
    if (isNewSignUp) await AdjustNewlyCreatedProfile();
        
    // Start to validate profile, generally for both new and old logged in users
    if (!appstore.getState().loginValidation.TimerID) {
        Dispatch.LoginValidation.StartVerification(window.setInterval(() => { ValidateProfileAndInteraction(); }, 1000));
    }
}

/** 
 * Validates the profile of a user who has just logged in:
 *     1) If contact number is null or invalid;
 *     2) If contact number is a valid mobile number, but never gets verified;
 *     3) Terms & conditions acceptance.
 * Order is defined by PO and BA.
 * 
 * Business rule:
 * https://cabcharge.atlassian.net/wiki/spaces/CA/pages/1034584204/Logged-in+users+validation
 *
 * Because each step is an interaction with the user, we need to set a timer to guarantee the order.
 */
async function ValidateProfileAndInteraction() {

    // Step 1 -- Running check
    const state = appstore.getState();
    if (state.loginValidation.IsAsyncTaskRunning) return;

    // Step 2 -- If contact number is null or invalid
    const userProfile = state.authentication.UserProfile!; // this code only runs for logged in users
    const ContactNumber = userProfile.ContactPhone;

    if ((ContactNumber != "") && (ContactNumber != null) && IsValidNumber(ContactNumber)) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.NoValidContactNumber);
    }
    else {
        Dispatch.Dialog.ShowDialog(DialogKind.ContactDetails);
        Dispatch.LoginValidation.AwaitSubTask(TaskKind.NoValidContactNumber);
        return;
    }

    // Step 3 -- If contact number is a valid mobile number, but has not been verified yet
    if (!IsVerificationNeeded(userProfile)) {
        Dispatch.LoginValidation.CompleteSubTask(TaskKind.MobileVerification);
    }
    else {
        VerifyPhoneForProfile(userProfile, false);
        Dispatch.LoginValidation.AwaitSubTask(TaskKind.MobileVerification);
        return;
    }

    // Step 4 -- Terms & conditions acceptance
    if (FeatureFlags.LegalDocumentsV2) {
        if (state.legalDocuments.IsConsentRequired) {

            Dispatch.Dialog.ShowDialog(DialogKind.LegalDocumentsConsent);
            Dispatch.LoginValidation.AwaitSubTask(TaskKind.TncAcceptance);
            return;
        }
        else {
            Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
        }
    }
    else {
        const { TncInfo } = appstore.getState().tncPrivacy;

        if (TncInfo && IsNewTncConsentNeeded(userProfile, TncInfo)) {
            await RetrieveTnc(true, true, false);

            Dispatch.Dialog.ShowDialog(DialogKind.TncConsent);

            Dispatch.LoginValidation.AwaitSubTask(TaskKind.TncAcceptance);
            return;
        }
        else {
            Dispatch.LoginValidation.CompleteSubTask(TaskKind.TncAcceptance);
        }
    }

    // Step 5 -- Completion check
    const TaskLookUpTable = state.loginValidation.TaskLookUpTable;

    if (TaskLookUpTable[TaskKind.NoValidContactNumber] === TaskStatus.Completed && TaskLookUpTable[TaskKind.MobileVerification] === TaskStatus.Completed && TaskLookUpTable[TaskKind.TncAcceptance] === TaskStatus.Completed) {
        RemoveProfileValidationListener();
        return;
    }
}

/**
 * The user has just signed up from our UI. Some default values on their user profile should be updated.
 * This process is necessary because we don't have full control over the user creation process - it is done via Auth0 and 13cabs Auth0 API and Booking API.
 * 1) The user has acknowledged the terms and conditions as part of the sign up dialog. Report this to the back end.
 * 2) During the sign up dialog, we do SMS verification of the user's mobile phone. We can safely mark this as verified. 
 */
async function AdjustNewlyCreatedProfile() {

    const appState = appstore.getState();
    let userProfile = appState.authentication.UserProfile;
    if (!userProfile) return;

    // Save user consent
    if (FeatureFlags.LegalDocumentsV2) {
        const latestLegalDocsId = appstore.getState().legalDocuments.LegalDocumentsPack.LegalDocumentsVersion;

        if (latestLegalDocsId) {
            const consentRequest: UserConsentRequest = {
                LegalDocsId: latestLegalDocsId
            }

            var consentResult = await Api.LegalDocuments.UpdateUserConsent(consentRequest);

            if (!consentResult.isSuccess) {
                return;
            }

            Dispatch.LegalDocuments.ConsentStatus({ IsConsentRequired: false, LegalDocumentsToConsent: null });
        }

    } else {
        const tncInfo = appState.tncPrivacy.TncInfo;

        if (tncInfo) {
            const tncId = tncInfo.tncId;
            const result = await Api.User.AddUserTncAgreement(tncId);
            appInsights.trackEvent("User Sign Up", { UserProfile: JSON.stringify(userProfile), TncConsent: result.isSuccess ? "Success" : "Failure" });

            // the above call will have updated the user's profile entity, but we don't need to reload it from the server because we know exactly what field has changed
            if (result.isSuccess) {
                userProfile = {
                    ...userProfile,
                    TncConsentId: tncId,
                };

                Dispatch.Auth.UserProfile(userProfile);
                MyStorage.UserProfileV2.StoreData(userProfile);
            }
        }
    }

    // if the user verified their mobile phone number during the signup process, now we can set it on the user account
    if (IsVerificationNeeded(userProfile)) {

        const smsChallengeId = appState.verification.SmsChallengeId!;

        const result = await Api.SmsVerification.UpdatePhoneNumber(smsChallengeId);

        if (result.isSuccess) {
            await RefreshProfileAfterDataChange();
            LogEvent.UpdateUserVerificationStatusSuccess(userProfile.UserId);
        }
        else {
            LogEvent.UpdateUserVerificationStatusFail(userProfile.UserId);
        }
    }

    // Google Analytics - Lodge a sign up event
    LogEventInGA4.UserSignUp(userProfile.UserId, LoginStatusKind.LoggedIn)
}