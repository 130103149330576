import ReactGA from "react-ga4";
import appstore from "../appStore";
import { LoginStatusKind } from "../modules/Authentication/AuthEntities";
import { LogBookingCreationDataInGA4 } from "../modules/Booking/LogBookingCreation";
import { ContactFormFieldsForGoogleAnalytics } from "../modules/ContactUs/ContactEntities";
import { BookingDataOwnership } from "../Services/BookingEntities";

/** Send different events to google analytics. */
export const LogEventInGA4 = {
    /**
     * A user has logged in.
     * */
    UserLogin: function (userId: string, loginStatus: string) {
        ReactGA.event('login_finish', {
            Enc_id: userId,
            login_status: loginStatus
        });
    },

    /**
     * A user has signed up.
     * */
    UserSignUp: function (userId: string, loginStatus: string) {
        ReactGA.event('signup_finish', {
            Enc_id: userId,
            login_status: loginStatus
        });
    },

    /**
     * Pickup address entered
     * */
    PickupAddress: function (pickupAddress: string) {
        ReactGA.event('pickup_entered', {
            Enc_id: appstore.getState().authentication.UserProfile?.UserId ?? BookingDataOwnership.Guest,
            pickup_input: pickupAddress
        });
    },

    /**
     * Destination address entered
     * */
    DestinationAddress: function (destinationAddress: string) {
        ReactGA.event('destination_entered', {
            Enc_id: appstore.getState().authentication.UserProfile?.UserId ?? BookingDataOwnership.Guest,
            destination_input: destinationAddress
        });
    },

    /**
     * The contact us form has been submitted
     * */
    ContactUsFormSubmission: function (contactUsFormFields: ContactFormFieldsForGoogleAnalytics) {
        ReactGA.event('contact_us_enquiry', {
            enquiry_person: contactUsFormFields.section,
            enquiry_about: contactUsFormFields.subCategory,
            enquiry_sub_category: contactUsFormFields.subCategory2,
            trip_type: contactUsFormFields.tripType,
            Enc_id: appstore.getState().authentication.UserProfile?.UserId ?? BookingDataOwnership.Guest,
            login_status: appstore.getState().authentication.UserProfile ? LoginStatusKind.LoggedIn : LoginStatusKind.LoggedOut
        });
    },

    /**
     * The user has created the booking
     * */
    CreateBooking: function (bookingCreationData: LogBookingCreationDataInGA4) {
        ReactGA.event('make_booking', {
            pickup_input: bookingCreationData.PickupInput,
            destination_input: bookingCreationData.DropOffInput,
            pickup_datetime: bookingCreationData.PickupDateTime,
            car_type: bookingCreationData.VehicleType,
            fare_amount: bookingCreationData.FareAmount,
            payment_method: bookingCreationData.PaymentMethod,
            booking_id: bookingCreationData.BookingId,
            user_id: bookingCreationData.UserId,
            user_type: bookingCreationData.UserId === BookingDataOwnership.Guest ? "guest" : "signed_in",
            payment: bookingCreationData.DetailedPaymentMethod
        });
    },

    /**
     * The user has cancelled the booking
     * */
    CancelBooking: function (userId: string, bookingId: number) {
        ReactGA.event('cancel_booking', {
            booking_id: bookingId.toString(),
            user_id: userId
        });
    },

    /**
     * A Vehicle has been selected
     * */
    SelectVehicle: function (vehicleType: string) {
        ReactGA.event('vehicle_selected', {
            vehicle_type: vehicleType,
        });
    },
}