import React from "react";
import BookingDetails from "./BookingDetails";
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { FareEstimateSnackbar } from "./Widget/FareEstimateSnackbar";
import { Dispatch } from "../Dispatch";
import { FeatureFlags } from '../../Config/FeatureFlags';
import { SnackbarForPriceGuaranteeTip } from "./Widget/SnackbarForPriceGuaranteeTip";
import { DriverNotesSection } from "./Widget/DriverNotesSection";
import { BookingLocationContactName } from "./PerLocation/BookingLocationContactName";
import { MaybeAccountDetails } from "./OptionalParts/MaybeAccountDetails";
import { MaybeCardSelector } from "./OptionalParts/MaybeCardSelector";
import { FormStepLabel } from "./FormSteps/FormStepLabel";
import { BookingFormGroup } from "./FormSteps/BookingFormGroup";
import { BookingPhoneInput } from "../PhoneNumber/BookingPhoneInput";

/** Renders part of the booking form inputs on desktop devices. */
export default class NewBookingForm extends React.Component {
    
    componentWillUnmount() {
        Dispatch.UILogicControl.HideFareEstimateMessage();
    }

    render() {                      

        return (
            <>
                <MaybeAccountDetails />
                
                <BookingFormGroup WithStepLabel={FormStepLabel.BookingDetails}>
                    <BookingDetails />
                </BookingFormGroup>

                <BookingFormGroup WithStepLabel={FormStepLabel.ContactDetails}>
                    <BookingLocationContactName Location={0} />
                    <BookingPhoneInput Location={0} />
                </BookingFormGroup>

                <MaybeCardSelector />

                <BookingFormGroup WithStepLabel={FormStepLabel.DriverInstruction}>
                    <DriverNotesSection />
                </BookingFormGroup>

                <FareEstimateSnackbar />
                <SnackbarForPriceGuaranteeTip />
            </>
        );
    }
}

/**
 * Calculate if the booking is on account:
 *   1) Logged in;
 *   2) Account toggle is on.
 */
export function CalculateIsBookingOnAccount(loginStatus: LoginStatusKind, isOnAccount: boolean, isMobileDevice: boolean): boolean {

    if (isMobileDevice && !FeatureFlags.MobileBookingOnAccount) return false;

    if (loginStatus !== LoginStatusKind.LoggedIn) return false;

    return isOnAccount;
}