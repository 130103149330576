import React, { useEffect } from "react";
import { connect } from "react-redux";
import "./MobileBookingPage.scss";
import "../../Dialog/Dialog.scss";
import { ApplicationState } from "../../../appState";
import { MobileBookingForm } from "./MobileBookingForm";
import { MobileDialog } from "../../Dialog/MobileDialog";
import SiteHeader from "../../../widgets/Header/SiteHeader";
import BookingTab from "../Widget/BookingTab";
import { BookingTabKind } from '../../UILogicControl/UILogicControlEntities';
import { MyBookingsListMobileWrapper } from "./MyBookingsListMobileWrapper";
import { MobileMenu } from "../../User/MobileMenu";
import { BookingAuthority } from "../../../Services/BookingEntities";
import { Dispatch } from "../../Dispatch";
import CardRegistration from "../../Cards/CardRegistration";
import QuickBookingTab from '../QuickBook/QuickBookingTab';

interface MobileBookingPagePropsFromStore {
    ActiveTab: BookingTabKind;
    NewTrackingLink: BookingAuthority | null;
    IsCardRegistrationPanelOpen: boolean;
}

interface TopSectionProps {
    ShouldShowHeader: boolean;
}

/**
 * Wrapper component for mobile version to host either the booking form or a list of the user's bookings (selected by tabs).
 */
const MobileBookingPageCore: React.FC<MobileBookingPagePropsFromStore & TopSectionProps> = (props) => {

    // Check for tab changes on component mount.
    useEffect(() => {
        // If the page is opened via a tracking link, default to the Active View.
        if (props.NewTrackingLink != null) {
            Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.ActiveBooking);
        }

    }, []);
    
    return (
        <>  
            <TopSection ShouldShowHeader={props.ShouldShowHeader}/>
            {RenderComponentActiveTab(props.ActiveTab)}
            <MobileDialog />
            <MobileMenu />
            {props.IsCardRegistrationPanelOpen && <CardRegistration CardRegistrationPanelParent="MobileBookingPage" />}
        </> 
    );
}

function mapStateToProps(state: ApplicationState): MobileBookingPagePropsFromStore & TopSectionProps {
    return {        
        ActiveTab: state.uiLogicControl.BookingForm.SelectedBookingTab,
        ShouldShowHeader: state.uiLogicControl.BookingForm.ShouldShowHeader,
        NewTrackingLink: state.trackingLinks.NewFromUrl?.BookingKey ?? null,
        IsCardRegistrationPanelOpen: state.payment.IsCardRegistrationPanelOpen
    }
}

export const MobileBookingPage = connect(mapStateToProps)(MobileBookingPageCore);

/**
 * Top section of mobile booking page, which includes header and tabs
 */
const TopSection: React.FC<TopSectionProps> = (props) => {
    return (
        <div className={props.ShouldShowHeader ? "mobile-booking-page-top-section top-section-display" : "mobile-booking-page-top-section top-section-hide"}>
            <div className="top-section-panel"> 
                <SiteHeader/>
                <BookingTab/>
            </div>
        </div>
    );
}

function RenderComponentActiveTab(tab: BookingTabKind) {
    if (tab === BookingTabKind.ActiveBooking) return <MyBookingsListMobileWrapper />;

    if (tab === BookingTabKind.QuickBook) return <QuickBookingTab />;

    return <MobileBookingForm />
}
