import React from 'react';
import { DialogKind } from '../../Dialog/DialogEntities';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import '../../Verification/ContactVerification.scss';
import { Dispatch } from '../../Dispatch';


/** 
 * The popup to let the user know that the enquiry has been submitted and an email has been sent.
 */
export default class SentEnquiry extends React.Component {

    closeDialog() {        
        Dispatch.Dialog.CloseDialog(DialogKind.SentEnquiry);
    }

    render() {
        return (
            <div className="contact-details mobile-confirmation">
                <h1 className="orange-header sent-enquiry-mobile-font">Thanks - enquiry received!</h1>
                <p className="enquiry-text">A <span className="bold-text">case number</span> has been sent to the email address provided.</p>
                <img className="sent-enquiry-illustration" src={getContentUrl(ContentURL.images.ContactUs.NewMessageSend)} alt="" />
                <button type="button" className="send-verification-btn active-btn confirmation-btn" onClick={this.closeDialog}>OK</button>
            </div>
        );
    }
}