import { ShowDialog } from "../ShowDialog";

/**
 * Display the dialog to let the user know that the booking was cancelled due to payment failure.
 */
export async function DisplayPaymentFailureMessage(errorMessage: string): Promise<void> {

    // Open the modal to display booking cancelled reason. No action is needed after the dialog close.
    var paymentFailureResult = await ShowDialog.PaymentFailure({ ErrorMessage: errorMessage });
    
    return;
}