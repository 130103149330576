import { ApiGeoPoint } from "../../../Services/BookingEntities";

/**
 * Returns the distance in meters between two geopoints.
 * Source: https://stackoverflow.com/a/1502821
 * Used in place of google.maps.geometry.spherical, which doesn't exist in unit tests because it only loads at runtime.
 */
export function GetDistanceBetweenPoints(p1: ApiGeoPoint, p2: ApiGeoPoint) {
    const earthRadiusMeters = 6378137;
    const deltaLatitudeRadians = ConvertDegreesToRadians(p2.Latitude - p1.Latitude);
    const deltaLongitudeRadians = ConvertDegreesToRadians(p2.Longitude - p1.Longitude);
    const a = Math.sin(deltaLatitudeRadians / 2) * Math.sin(deltaLatitudeRadians / 2) +
        Math.cos(ConvertDegreesToRadians(p1.Latitude)) * Math.cos(ConvertDegreesToRadians(p2.Latitude)) *
        Math.sin(deltaLongitudeRadians / 2) * Math.sin(deltaLongitudeRadians / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceMeters = earthRadiusMeters * c;
    return distanceMeters;
};

function ConvertDegreesToRadians(degrees: number) {
    return degrees * Math.PI / 180;
};