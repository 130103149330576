import { MyBookingsState, MyBookingsInitialState } from "./MyBookingsState";
import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { BookingDataOwnership, BookingStatus, BookingDriverDetails, ApiGeoPoint } from "../../Services/BookingEntities";
import { GetCurrentTime } from "../Utils/GetCurrentTime";
import { ExtendedEta, PODImageDetails, BookingInfo, BookingTrackingDetails, BookingDriverPhoto, PartialDateRange } from "./MyBookingEntities";
import { MyStorage } from "../../Storage";

const slice = new ReduxStoreSlice<MyBookingsState>("MyBookings", MyBookingsInitialState);

/** Dispatcher for actions in the MyBookings state slice. */
export const MyBookingsDispatchV2 = {

    Add: slice.Action("Add My Booking", AddMyBooking),
    DataRefresh: slice.Action("Data Refresh", DataRefresh),
    Clear: slice.EmptyAction("Clear", Clear),

    /** Apply multiple vehicle location updates to the MyBookingsState. */
    BulkLocationUpdate: slice.Action("Bulk Location Update", BulkLocationUpdate),

    StatusUpdate: slice.Action("Status Update", StatusUpdate),
    UpdateEta: slice.Action("Update ETA", EtaUpdate),
    Remove: slice.Action("Remove", RemoveBooking),
    MyRecentTripsRefresh: slice.Action("Recent Trips", RecentBooking),
    SetBookingToShare: slice.Action("Share", UpdateBookingDetailsToShare),
    RefreshBookingWithReadAccessCode: slice.Action("Refresh Read Access Code", UpdateBookingWithReadAccessCode),
    RefreshDriverDetails: slice.Action("Refresh Driver Details", RefreshDriverDetails),
    AddMapFocusedBookingID: slice.Action("Add MapFocusedBookingID", AddMapFocusedBookingID),
    RefreshLinkBookings: slice.Action("Refresh Link Bookings", RefreshLinkBookings),
    AddLinkBookingsToAll: slice.Action("All: Add Link Bookings", RefreshAllWithLinkBookings),
    /** Remove a booking from the TrackingLinkBookings list. */
    RemoveLinkBooking: slice.Action("Remove Link Booking", RemoveLinkBooking),

    /** Note the booking the user wants to cancel, to be displayed in the cancel booking confirmation dialog */
    CancelCandidate: slice.Action("Cancel Candidate", CancelCandidate),

    /** Update when the last request was sent to V2 API to get bulk status updates. */
    RefreshLastBulkStatusUpdateTime: slice.Action("Refresh Last Bulk Status update Time", RefreshLastStatusUpdateTime),
    AddPODImageUrls: slice.Action("Add POD Image Urls", AddPODImageUrls),

    /** Update the driver photo of a booking. This is only applied to the V2 API bookings. */
    RefreshDriverPhoto: slice.Action("Refresh Driver Photo", RefreshDriverPhoto),

    /** Clear existing driver image from a booking. Useful when a booking is recalled or the assigned driver has changed for some reason. */
    ClearDriverImage: slice.Action("Clear Driver Image", ClearDriverImage),

    /** Update the Date Range (Start Date and End Date) selected through the Booking History Date Range Picker */
    MyRecentTripsDateRangeRefresh: slice.Action("Recent Trips DateRange Refresh", RefreshRecentBookingHistoryDateRange)
};

/** Reducer for the MyBookings store slice */
export const MyBookingsReducerV2 = slice.MakeCombinedReducer();

/** Apply an AddBooking action to the MyBookings state.
 *  Adds the new booking to the front of the All list. */
function AddMyBooking(state: MyBookingsState, booking: BookingInfo): MyBookingsState {
    UpdateManuallyPopulatedFields(booking.TrackingInfo);
    const newAllBookings = [booking, ...state.All];
    MyStorage.Bookings.StoreData(newAllBookings);

    return {
        ...state,
        All: newAllBookings
    };
}

/** Apply a DataRefresh action to the MyBookings state.
 *  For now, just do a complete reset of the data. */
function DataRefresh(state: MyBookingsState, allBookings: BookingInfo[]): MyBookingsState {

    for (let booking of allBookings) {
        UpdateManuallyPopulatedFields(booking.TrackingInfo);
    }

    MyStorage.Bookings.StoreData(allBookings);

    return {
        ...state,
        All: allBookings,
    };
}

/** Apply a Clear action to the MyBookings state.
 *  Remove any bookings associated with the user (normally this is all bookings). */
function Clear(state: MyBookingsState): MyBookingsState {

    // keep guest bookings since they will be lost otherwise. (They are not really meant to be cleared)
    const bookingsToKeep = state.All.filter(i => i.DataOwnership == BookingDataOwnership.Guest);
    MyStorage.Bookings.StoreData(bookingsToKeep);

    return {
        ...state,
        Etas: {},
        All: bookingsToKeep,
    };
}

/**
 * Apply multiple vehicle location updates to the MyBookingsState.
 * The changeset has new locations for some bookings, and is keyed by Booking ID.
 */
function BulkLocationUpdate(state: MyBookingsState, vehicleLocationChanges: Record<number, ApiGeoPoint>): MyBookingsState {

    const updatedBookingList = state.All.map<BookingInfo>(booking => {

        // check for an update matching this booking ID
        const newVehicleLocation = vehicleLocationChanges[booking.BookingID];
        if (!newVehicleLocation) return booking;

        return {
            ...booking,
            TrackingInfo: {
                ...booking.TrackingInfo,
                VehicleLocation: {
                    ...newVehicleLocation,
                    LocalDataLoadedTime: GetCurrentTime(),
                    Time: '', // not supported by V2 API (or used, actually)
                }
            }
        };
    });

    MyStorage.Bookings.StoreData(updatedBookingList);

    return {
        ...state,
        All: updatedBookingList,
    };
}

/** Apply a StatusUpdate action to the MyBookings state.
 * Some bookings will have new values. */
function StatusUpdate(state: MyBookingsState, changedBookings: Record<number, BookingTrackingDetails>): MyBookingsState {

    const updatedBookingList = state.All.map(booking => {

        // check for an update matching this booking ID
        const changes = changedBookings[booking.BookingID];
        if (!changes) return booking;

        return {
            ...booking,
            TrackingInfo: GetNewBookingState(booking.TrackingInfo, changes)
        };
    });

    MyStorage.Bookings.StoreData(updatedBookingList);

    return {
        ...state,
        All: updatedBookingList,
    };
}

/** Get new status of the booking with updated tracking details. */
function GetNewBookingState(trackingDetails: BookingTrackingDetails, updates: BookingTrackingDetails): BookingTrackingDetails {
    const trackingInfo: BookingTrackingDetails = {
        ...trackingDetails,
        BookingStatusID: updates.BookingStatusID,
        DriverID: updates.DriverID, // Always assign driver ID even if it is null in order to make it null if the booking is recalled.
        CarNumber: updates.CarNumber,
        WasJitPreauthFailure: updates.WasJitPreauthFailure,
        JitPreauthFailureMessage: updates.JitPreauthFailureMessage
    };

    if (updates.VehicleLocation) {
        trackingInfo.VehicleLocation = updates.VehicleLocation;

        // also capture the time
        trackingInfo.VehicleLocation.LocalDataLoadedTime = GetCurrentTime();
    }

    if (updates.DriverName) {
        trackingInfo.DriverName = updates.DriverName
    }

    // Reset other Driver details if DriverID is null. i.e.: A driver was assigned before but removed from the booking now. 
    if (!updates.DriverID) {
        trackingInfo.DriverName = null;
        trackingInfo.DriverImage = null;
    }

    UpdateManuallyPopulatedFields(trackingInfo);

    return trackingInfo;
}

/**
 * Check for changes in any fields that are manually populated by the MyBookings redux store.
 * This internal method must be called from any action reduces that adds or updates a booking.
 */
function UpdateManuallyPopulatedFields(booking: BookingTrackingDetails) {
    CheckForStartOfDispatch(booking);
    CheckAndSetSetPickedupTime(booking);
    CheckAndSetDropoffTime(booking);
}

/** Set the DispatchStartTime field to the current time when we first detect the booking dispatching. */
function CheckForStartOfDispatch(trackingDetails: BookingTrackingDetails) {

    if ((!trackingDetails.DispatchStartTime) && IsDispatchingNowInProgress(trackingDetails)) {
        trackingDetails.DispatchStartTime = GetCurrentTime();
    }
}

/** Returns true when the booking is being actively dispatched, i.e. looking for a taxi. */
function IsDispatchingNowInProgress(trackingDetails: BookingTrackingDetails): boolean {

    if (trackingDetails.BookingStatusID == BookingStatus.Dispatching) return true;
    if (trackingDetails.BookingStatusID == BookingStatus.Acknowledged) return true;
    if (trackingDetails.BookingStatusID == BookingStatus.Assigned) return true;

    return false;
}

/** Set the Dropoff time to current time when the completed status is detected for the first time. */
function CheckAndSetDropoffTime(booking: BookingTrackingDetails) {
    
    if (!booking.DropoffTime && booking.BookingStatusID == BookingStatus.Completed) {
        booking.DropoffTime = GetCurrentTime();
    }    
}

/** Set the PickedupTime time to current time when the Pickedup status is detected for the first time. */
function CheckAndSetSetPickedupTime(trackingDetails: BookingTrackingDetails) {

    if ((!trackingDetails.PickedupTime) && trackingDetails.BookingStatusID == BookingStatus.PickedUp) {
        trackingDetails.PickedupTime = GetCurrentTime();
    }
}

/** Handle an ETA Update action. The ETA is added to the ETAs lookup keyed by Booking ID. */
function EtaUpdate(state: MyBookingsState, eta: ExtendedEta): MyBookingsState {
    return {
        ...state,
        Etas: {
            ...state.Etas,
            [eta.BookingId]: eta,
        }
    };
}

/** Handle a RemoveBooking action. The specified booking is removed from the All list. */
function RemoveBooking(state: MyBookingsState, booking: BookingInfo): MyBookingsState {
    var newBookingList = state.All.filter((e) => {
        return e.BookingID !== booking.BookingID;
    });

    MyStorage.Bookings.StoreData(newBookingList);

    return {
        ...state,
        All: newBookingList,
    };
}

/** Apply RecentTripsRefresh action to the MyBookings state. */
function RecentBooking(state: MyBookingsState, booking: BookingInfo[]): MyBookingsState {
    return {
        ...state,
        RecentTrips: booking
    };
}

/** Set booking details to share via SMS */
function UpdateBookingDetailsToShare(state: MyBookingsState, bookingToShare: BookingInfo): MyBookingsState {
    return { ...state, SelectedBookingToShare: bookingToShare };
}

/** Update read access code of the selected booking from the All list. */
function UpdateBookingWithReadAccessCode(state: MyBookingsState, bookingWithReadAccessCode: BookingInfo): MyBookingsState {

    const updatedBookingsList = state.All.map(booking => {

        if (booking.BookingID !== bookingWithReadAccessCode.BookingID) return booking;

        // Updated booking
        return bookingWithReadAccessCode;
    });

    MyStorage.Bookings.StoreData(updatedBookingsList);

    return {
        ...state,
        All: updatedBookingsList,
    };
}

/** Update a booking in all bookings list with driver details. */
function RefreshDriverDetails(state: MyBookingsState, driverDetails: BookingDriverDetails): MyBookingsState {

    const updatedBookingList = state.All.map(booking => {

        if (booking.BookingID != driverDetails.BookingId) return booking;

        const newBooking: BookingInfo =
        {
            ...booking,
            TrackingInfo: {
                ...booking.TrackingInfo,
                DriverName: driverDetails.DriverName,
                DriverImage: driverDetails.DriverImageURL
            }            
        };        

        return newBooking;
    });

    MyStorage.Bookings.StoreData(updatedBookingList);

    return {
        ...state,
        All: updatedBookingList,
    };
}

/** Add ID for a single booking for taxi tracking purpose in a map on dialog */
function AddMapFocusedBookingID(state: MyBookingsState, bookingID: number): MyBookingsState {
    return {
        ...state,
        MapFocusedBookingID: bookingID
    };
}

/** Apply a data refresh action to TrackingLinkBookings list */
function RefreshLinkBookings(state: MyBookingsState, bookings: BookingInfo[]): MyBookingsState {
    return {
        ...state,
        TrackingLinkBookings: bookings
    };
}

/** Add bookings from tracking links to the All list. This is required to maintain one booking list that the UI can use to create tracking cards. */
function RefreshAllWithLinkBookings(state: MyBookingsState, linkBookings: BookingInfo[]): MyBookingsState {
    // Add link bookings to All bookings list and remove duplicates.
    const newAllBookings = CombineUserAndLinkBookings(state.All, linkBookings);

    return {
        ...state,
        All: newAllBookings
    }
}

/** Combine LinkBookings to All list and remove duplicates in order to maintain one list to create the UI. */
function CombineUserAndLinkBookings(userBookings: BookingInfo[], linkBookings: BookingInfo[]): BookingInfo[] {

    if (linkBookings.length === 0) return userBookings;

    const result: BookingInfo[] = [];

    for (const old of userBookings) {

        const link = linkBookings.find(i => i.BookingID === old.BookingID);
        const combined = MergeItems(old, link);

        result.push(combined);
    }

    // Bookings only in the link list
    const newBookings = linkBookings
        .filter((b1) => !userBookings.find(b2 => b2.BookingID === b1.BookingID));

    newBookings.forEach((i) => UpdateManuallyPopulatedFields(i.TrackingInfo));

    result.push(...newBookings);

    return result;
}

/** Prioritise bookings when there are multiple instances of the same booking based on the ownership of each instance and return the highest prioritised instance.  */
function MergeItems(left: BookingInfo, right: BookingInfo | undefined): BookingInfo {

    if (!right) return left;

    const leftScore = GetScore(left.DataOwnership!);
    const rightScore = GetScore(right.DataOwnership!);

    const bestBooking = leftScore > rightScore ? left : right;

    return bestBooking;
}

/** Assign a value for each ownership type based on the pre-defined priority. */
function GetScore(ownership: BookingDataOwnership): number {

    if (ownership === BookingDataOwnership.Test) return 1;
    if (ownership === BookingDataOwnership.ReadOnlyLink) return 2;
    if (ownership === BookingDataOwnership.Guest) return 3;
    if (ownership === BookingDataOwnership.WritableLink) return 4;
    if (ownership === BookingDataOwnership.User) return 5;

    // Default
    return 1;
}

/** Remove a booking from the TrackingLinkBookings list. */
function RemoveLinkBooking(state: MyBookingsState, bookingToRemove: BookingInfo): MyBookingsState {
    var newBookingsList = state.All.filter((e) => {
        return e.BookingID != bookingToRemove.BookingID;
    });

    return {
        ...state,
        TrackingLinkBookings: newBookingsList,
    };
}

/** Add the proof of delivery image urls to a specific booking */
function AddPODImageUrls(state: MyBookingsState, podImageDetails: PODImageDetails): MyBookingsState {

    var updatedBookingList = state.All.map<BookingInfo>(booking => {
        
        if (booking.BookingID == podImageDetails.BookingId) {

            const newBooking: BookingInfo = {
                ...booking,
                TrackingInfo: {
                    ...booking.TrackingInfo,
                    ProofOfDeliveryUrls: podImageDetails.ImageUrls
                }
            };

            return newBooking;
        }

        return booking;
    });

    return {
        ...state,
        All: updatedBookingList
    };
}

/** Note the booking the user wants to cancel, to be displayed in the cancel booking confirmation dialog */
function CancelCandidate(state: MyBookingsState, cancelCandidate: BookingInfo): MyBookingsState {

    return {
        ...state,
        CancelBookingCandidate: cancelCandidate,
    };
}

/** Update when the last request was sent to V2 API to get bulk status updates. The value is an input to the next request.  */
function RefreshLastStatusUpdateTime(state: MyBookingsState, lastUpdateTime: string): MyBookingsState {
    return {
        ...state,
        LastStatusUpdateRequestTimestamp: lastUpdateTime
    }
}

/** Update the driver photo of a booking. This is only applied to the V2 API bookings. */
function RefreshDriverPhoto(state: MyBookingsState, driverPhoto: BookingDriverPhoto): MyBookingsState {
    const updatedBookingList = state.All.map(booking => {

        if (booking.BookingID != driverPhoto.BookingId) return booking;

        const newBooking: BookingInfo =
        {
            ...booking,
            TrackingInfo: {
                ...booking.TrackingInfo,
                DriverImage: driverPhoto.DriverPhotoSource
            }
        };

        return newBooking;
    });

    MyStorage.Bookings.StoreData(updatedBookingList);

    return {
        ...state,
        All: updatedBookingList,
    };
}

/** Clear existing driver image from a booking. Useful when a booking is recalled or the assigned driver has changed for some reason. */
function ClearDriverImage(state: MyBookingsState, bookingId: number): MyBookingsState {
    const updatedBookingList = state.All.map(booking => {

        if (booking.BookingID != bookingId) return booking;

        const newBooking: BookingInfo =
        {
            ...booking,
            TrackingInfo: {
                ...booking.TrackingInfo,
                DriverImage: null
            }
        };

        return newBooking;
    });

    MyStorage.Bookings.StoreData(updatedBookingList);

    return {
        ...state,
        All: updatedBookingList,
    };
}

/** The Date Range selected from the Date Range Picker for the Booking History table. */
function RefreshRecentBookingHistoryDateRange(state: MyBookingsState, bookingDates: PartialDateRange): MyBookingsState {
    return {
        ...state,
        RecentTripHistoryDateRange: bookingDates
    };
}