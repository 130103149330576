import { GetValues } from "../../Config/MyAppConfig";
import { LocationData, KnownLocation, LocationSourceKind } from "./Entities";

/** The subset of the Application's state related to the Location feature. */
export interface LocationState {

    /** User's location as determined by IP geolocation. Initially undefined. */
    ipLocation?: LocationData;

    /** User's location of interest, as explictly selected by them. */
    userPickedLocation?: KnownLocation;

    /** Guaranteed to be a valid location (value.isValid === true), but may use fallback data */
    reliableLocation: KnownLocation;

    /** Prefers userPickedLocation, but may be invalid data (isValid === false). */
    preferredLocation: LocationData;
}

/** This is used as a default, or fallback if there is nothing else available */
export const fallbackLocation: KnownLocation =
{
    isKnown: true,
    source: LocationSourceKind.Fallback,
    value: GetValues().DefaultLocation
};

/** This is used as the default value in the reducer. */
export const initialLocationState: LocationState =
{
    reliableLocation: fallbackLocation,
    preferredLocation: fallbackLocation,
};