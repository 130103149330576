import { GetBrandedUrl, BrandedImage } from '../../modules/Utils/BrandedContentUrls';

export const socialLinks = [
    {
        display: '13cabs posts on Facebook',
        href: 'https://www.facebook.com/13cabs',
        img: GetBrandedUrl(BrandedImage.Facebook)
    },
    {
        display: '13cabs tweets on Twitter',
        href: 'https://twitter.com/13cabs',
        img: GetBrandedUrl(BrandedImage.Twitter)
    },
    {
        display: '13cabs casting on Instagram',
        href: 'https://www.instagram.com/13cabs',
        img: GetBrandedUrl(BrandedImage.Instagram)
    }
];