import { Button, Typography } from "@mui/material";
import React from "react";
import { DialogKind } from "../../Dialog/DialogEntities";
import { Dispatch } from "../../Dispatch";
import "./TechnicalDifficulties.scss";

/** An error message saying there are technical difficulties and asking the user to try again later. This doesn't include the brand phone number; 132227. It is a temporary dialog to reduce the number of calls to contact centre during dispatch system outage. We can get rid of this later and re-enable the old messaging with the phone number. It is implemented separately from 'SimpleErrorMessaging' since the layout of this is different from other error messages (e.g. no title) */
export const TechnicalDifficulties: React.FC<{}> = () => {

    const CloseDialog = () => {
        Dispatch.Dialog.CloseDialog(DialogKind.TechnicalDifficulties);
    }

    return (
        <div className="tech-difficulties-dialog">
            <Typography variant='body1' align="center">Sorry, we are currently experiencing technical difficulties. Please try again later.</Typography>
            <Button onClick={CloseDialog} size="large" variant='contained' color='primary'>OK</Button>
        </div>
    );
}