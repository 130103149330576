import appstore from "../../../appStore";
import { Api } from "../../../Services/Api";
import { ApiGeoPoint, BookingAuthority, BookingStatus } from "../../../Services/BookingEntities";
import { Dispatch } from "../../Dispatch";
import { BookingInfo, BookingTrackingDetails } from "../MyBookingEntities";

/**
 * Refresh the vehicle locations for any bookings that are active (accepted or picked up).
 * This is used for booking created by the V2 Booking API.
 */
export async function RefreshVehicleLocations(updateCandidates: BookingInfo[]) {

    const bookingsToLoad = GetVehicleLoadCandidates(updateCandidates);
    if (bookingsToLoad.length === 0) return;

    // call API here
    const keysToLoad = bookingsToLoad.map<BookingAuthority>(i => ({
        bookingId: i.BookingID,
        hashCode: i.AccessCode,
    }));

    const result = await Api.BookingV2.GetBookingVehicleLocations(keysToLoad);
    if (!result.isSuccess) return;

    const locations = result.value;
    if (locations.length === 0) return;

    // apply changes
    const locationLookup: Record<number, ApiGeoPoint> = {};

    for (const item of locations) {
        locationLookup[item.InternalBookingId] = item.VehicleLocation;
    }

    Dispatch.MyBookings.BulkLocationUpdate(locationLookup);
}

/**
 * Return the subset of [updateCandidates] which are now active (accepted / picked up).
 * CAREFUL: [updateCandidates] is the state before the booking status refresh operation. Some bookings may have changed status.
 * Get the latest version of the record from the app store.
 */
function GetVehicleLoadCandidates(updateCandidates: BookingInfo[]): BookingInfo[] {

    // put the updates into a lookup keyed by Booking ID
    const latestData = appstore.getState().myBookings.All;

    const latestDataLookup = new Map<number, BookingTrackingDetails>();

    for (const update of latestData) {
        latestDataLookup.set(update.BookingID, update.TrackingInfo);
    }

    // the ones which are now active
    const vehicleLoadCandidates: BookingInfo[] = [];

    for (const booking of updateCandidates) {
        const currentState = latestDataLookup.get(booking.BookingID);
        if (!currentState) continue;

        const statusId = currentState.BookingStatusID;
        const isActive = (statusId === BookingStatus.Accepted) || (statusId === BookingStatus.PickedUp);

        if (isActive) {
            vehicleLoadCandidates.push(booking);
        }
    }

    return vehicleLoadCandidates;
}