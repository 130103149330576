import React from 'react';
import { connect } from "react-redux";
import NewBooking from '../NewBooking';
import QuickBookingTab from '../QuickBook/QuickBookingTab';
import { ApplicationState } from "../../../appState";
import { BookingTabKind } from '../../UILogicControl/UILogicControlEntities';
import BookingTab from "./BookingTab";

interface PropsFromStore {
    ActiveTab: BookingTabKind;
}

/**
 * Panel host both BookingTab and booking widgets underneath it.
 */
class BookingTabPanel extends React.Component<PropsFromStore> {
      
    render() {       

        return (
            <React.Fragment>
                <BookingTab />
                {this.props.ActiveTab === BookingTabKind.QuickBook ? <QuickBookingTab /> : <NewBooking />}
            </React.Fragment>              
        )
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {        
        ActiveTab: state.uiLogicControl.BookingForm.SelectedBookingTab
    }
}

export default connect(mapStateToProps)(BookingTabPanel);
