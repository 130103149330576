import braintree, { PayPalTokenizePayload } from "braintree-web";
import appstore from "../../appStore";
import { GetValues } from "../../Config/MyAppConfig";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { CnpPaymentKind, PayPalGuestTempPlaceholder } from "../Payment/PaymentEntities";
import { ConvertToPaymentOption } from "../Payment/PaymentHandler";
import { LoadMyCards } from "../Payment/PaymentHelper";
import { AddPayPalRequest } from "../../Services/PaymentContractsV2";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { AddPayPalFunnel } from "./AddPayPalFunnel";
import { DialogKind } from "../Dialog/DialogEntities";
import { ConsiderGuestPaymentReset } from "../GuestPayment/ConsiderGuestPaymentReset";

/** Adding a PayPal payment method */
export namespace PayPal {

    /** Braintree client SDK from a client token */
    let braintreeClient: braintree.Client | null = null;

    /** PayPal client SDK from our Braintree SDK */
    let payPalClient: braintree.PayPal | null = null;

    /**
     * Provide the completed BrainTree and PayPal SDK clients after they are loaded.
     */
    export function SdkIsReady(btClient: braintree.Client, ppClient: braintree.PayPal) {
        braintreeClient = btClient;
        payPalClient = ppClient;
    }

    /**
     * Performs the add PayPal payment UI flow for signed in and guest users. 
     * This method just addresses the loader UI concern. 
     */
    export async function RunAddPayPalUiFlow() {

        Dispatch.UILogicControl.ShowLoading();
        await RunAddPayPalUiFlow2();
        Dispatch.UILogicControl.HideLoading();
    }

    /**
     * Initialise PayPal login (get token from MPS, open PayPal login screen, send Nonce to MPS after a successful login).
     */
    async function RunAddPayPalUiFlow2() {

        AddPayPalFunnel.Start();

        // 4) PayPal popup window: login, select card, etc
        let tokenisedPaypal: braintree.PayPalTokenizePayload;

        try {
            const brandName = GetValues().BrandName;

            tokenisedPaypal = await payPalClient!.tokenize({
                flow: "vault",
                billingAgreementDescription: `Pay Cabcharge via ${brandName}`,
                displayName: brandName
            });
        }
        catch (error) {
            AddPayPalFunnel.Exception("PayPal Popup", error, true);
            return;
        }

        // 5) MPS / Booking API side
        const loginState = appstore.getState().authentication.LoginStatus;

        if (loginState == LoginStatusKind.LoggedIn) {
            await AddPayPalSignedIn(tokenisedPaypal);
        }
        else {
            AddPayPalGuest(tokenisedPaypal);
            await PopulateDeviceData(braintreeClient!);
        }
    }

    // #region Signed In User

    /** 
     * The signed in user has a tokenised PayPal Payment method.
     * Send this through to MPS to add it to the user's account. 
     */
    async function AddPayPalSignedIn(paypalData: PayPalTokenizePayload) {

        const nonceRequest: AddPayPalRequest = {
            PayPalAccountId: paypalData.details.payerId,
            TokenisedPaymentNonce: paypalData.nonce,
        };

        const result = await Api.PaymentV2.AddPayPal(nonceRequest);

        if (!result.isSuccess) {
            AddPayPalFunnel.ApiError("AddPayPal API", result, true);
            return;
        }

        // refresh the cards list again
        await LoadMyCards();
        await SetPayPalAsDefault();

        return result.value;
    }

    /** Set PayPal as the default payment method after successfully adding a new PayPal account. */
    export async function SetPayPalAsDefault() {
        const cardsList = appstore.getState().payment.AllCards;

        // getting the first element is fine because at any given time only one PayPal account can be linked to the user profile.
        const payPal = cardsList.find(c => c.CardType === CnpPaymentKind.PayPal);

        if (payPal) {
            payPal.IsDefault = true;

            const serviceResponse = await Api.Payment.EditCard(payPal);

            if (serviceResponse.isSuccess) {
                Dispatch.Payment.SetDefaultCard(payPal);

                // automatically select PayPal as the payment method in the booking form
                const paymentOption = ConvertToPaymentOption(payPal);
                Dispatch.Booking.PaymentMethod(paymentOption);
            }
        }
    }

    // #endregion

    // #region Guest User

    /**
     * Continue the Add PayPal flow after PayPal tokenisation for a guest user.
     * SMS Verification, and therefore the guest user account creation and payment registration, is deferred until the booking creation.
     */
    function AddPayPalGuest(paypalData: PayPalTokenizePayload) {

        // shelve the token for now
        Dispatch.GuestPayment.PayPalTokenised(paypalData);

        // select a fake payment method
        Dispatch.Booking.PaymentMethod(PayPalGuestTempPlaceholder);

        // clear any other guest payment method
        ConsiderGuestPaymentReset();
    }

    /**
     * Populate the DeviceData booking property, used for payment fraud detection.
     * This is only required for guest users; signed in users will already have populated this at login.
     */
    async function PopulateDeviceData(braintreeClient: braintree.Client) {

        const dataCollector = await braintree.dataCollector.create({
            client: braintreeClient,
            kount: true,
            paypal: true,
        });

        const deviceData = dataCollector.deviceData;
        Dispatch.Booking.SetDeviceData(deviceData);
    }

    /** Opens a dialog to get confirmation to unlink PayPal from guest user. */
    export function OpenUnlinkDialog() {
        //Dispatch.GuestPayment.ClearPayPal();
        Dispatch.Dialog.ShowDialog(DialogKind.UnlinkPayPal);
    }

    /** Unlink PayPal payment method from guest user. Clear some of the state to update the UI. */
    export function UnlinkPayPal() {
        Dispatch.GuestPayment.ClearPayPal();

        const payPalInCardsList = appstore.getState().payment.AllCards.find(c => c.CardType === "PayPal");
        if (payPalInCardsList) Dispatch.Payment.RemoveCard(payPalInCardsList);
        Dispatch.Dialog.CloseDialog(DialogKind.UnlinkPayPal);

        if (appstore.getState().booking.PaymentOption?.Type === "PayPal") {
            Dispatch.Booking.ClearPaymentOption();
        }        
    }

    // #endregion
}