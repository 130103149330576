import React, { Fragment } from "react";
import { connect } from 'react-redux';
import { ApplicationState } from "../../../appState";
import DVACustomFields from "./DVACustomFields";
import AccountCustomFields from "./AccountCustomFields";
import { SimpleUserProfile, SimpleAccount } from "../../User/ProfileEntitiesV2";
import { AccountBookingPayload } from "../BookingEntities";
import { Dispatch } from "../../Dispatch";
import { CustomErrorMessages } from '../../Utils/ErrorMessages';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from "@mui/material";

interface AccountProps {
    UserProfile: SimpleUserProfile | undefined;
    AccountBookingDetails: AccountBookingPayload | null;
    IsBookingFormStrictValidationModeOn: boolean;
    SpecifiedAccountNotFound: boolean;
}

interface AccountDetailsState {
    selectedAccountIndex: number;
    accountsList: SimpleAccount[];
}

/** The component to be shown when the user selects Book on account. Contains a dropdown to select the account and custom fields of the selected account. */
class AccountDetailsCore extends React.Component<AccountProps, AccountDetailsState> {
    constructor(props: AccountProps) {
        super(props);

		this.state = {
            selectedAccountIndex: 0,
            accountsList: []
		};
    }
       
    componentDidMount() {
        
        let selectedIndex = this.state.selectedAccountIndex;
        const { UserProfile, AccountBookingDetails } = this.props;
        
        if (AccountBookingDetails) {
            selectedIndex = AccountBookingDetails.SelectedAccountIndex!;
        }

        this.setState({
            accountsList: UserProfile!.Accounts,
            selectedAccountIndex: selectedIndex
        }, () => {

                const { AccountBookingDetails, SpecifiedAccountNotFound } = this.props;
                const { accountsList, selectedAccountIndex } = this.state;

                /**
                 *  1. Update the selected account details in store on page load.
                 *  2. The account details are already updated in store (i.e. AccountBookingDetails will be not null), when booking from template/recent/booking history.
                 */             
                if (!SpecifiedAccountNotFound && !AccountBookingDetails) {
                    
                    const accountPayload = {
                        OrderNumber: '',
                        FileNumber: '',
                        SelectedAccountIndex: selectedAccountIndex,
                        SelectedAccount: accountsList[selectedAccountIndex]
                    }

                    Dispatch.Booking.AccountDetails(accountPayload);
                }                               
        });      
    }

    /** On change of the option values, update the selected account index and the store. */
    UpdateBookingAccount = (event: SelectChangeEvent) => {

        let selectedIndex = parseInt(event.target.value);

        this.setState({selectedAccountIndex: selectedIndex});
        // When the account changes, resets the Order number and File number to avoid the incorrect values to be passed with createBooking request.
        Dispatch.Booking.AccountDetails({SelectedAccount: this.state.accountsList[event.target.value], OrderNumber: "", FileNumber: "", SelectedAccountIndex: selectedIndex });
        Dispatch.UILogicControl.SpecifiedAccountNotFound(false);
    }

    render() {
        const { UserProfile : profile, AccountBookingDetails, IsBookingFormStrictValidationModeOn : isBookingButtonClicked, SpecifiedAccountNotFound } = this.props;

        if (!profile) return null;

        if (this.state.accountsList.length === 0) return null;

        const accountOptions = this.state.accountsList.map((account, index) => {
            return <MenuItem value={index} key={account.AccountNumber}>{account.AccountName}</MenuItem>
        });

        const labelText =  !!AccountBookingDetails ? "Account" : "Please select";

        // Incase of invalid account, assign empty value to account selector to display "Please select" 
        const selectedAccountValue = SpecifiedAccountNotFound ? "" : this.state.selectedAccountIndex.toString();

        const accountSelect = (
            <div className="booking-fields-panel">
                <FormControl variant="outlined" fullWidth={true} className="account-selector-dropdown" error={SpecifiedAccountNotFound}>
                    <InputLabel id="card-selector-label">{labelText}</InputLabel>
                    <Select
                        label={labelText}
                        value={selectedAccountValue}
                        labelId="card-selector-label"
                        onChange={this.UpdateBookingAccount}
                        >
                        {accountOptions}
                    </Select>
                </FormControl>
                { SpecifiedAccountNotFound && <div className="booking-form-error-message">{CustomErrorMessages.AccountValidationErrorMessage}</div> }
            </div>
        );

        const selectedAccount = SpecifiedAccountNotFound ? null : this.state.accountsList[this.state.selectedAccountIndex];
        
        return (
            <>
                {accountSelect}
                {
                    !selectedAccount ? null : selectedAccount.IsDVA ?
                        <DVACustomFields isBookingButtonClicked={isBookingButtonClicked} AccountBookingDetails={AccountBookingDetails}/> 
                    : 
                        <AccountCustomFields account={selectedAccount}/>
                }
            </>
        );
    }
}

function mapStateToProps(state: ApplicationState): AccountProps {
    return {
        UserProfile: state.authentication.UserProfile,
        AccountBookingDetails: state.booking.AccountData,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn,
        SpecifiedAccountNotFound: state.uiLogicControl.BookingForm.SpecifiedAccountNotFound
    }
}

export const AccountDetails = connect(mapStateToProps)(AccountDetailsCore);