import React from 'react';
import '../Login/SignupLogin.scss';

interface ErrorInDialogProps {
    ErrorMessage: string;
}

/**
 * Reusable error block which used in authentication dialogs, such as login & signup, to show dialog level error message (not field level).
 */
export const ErrorInDialog: React.FC<ErrorInDialogProps> = (props) => {

    return(
        <p className="credentials-auth0-error-message" dangerouslySetInnerHTML={{ __html: props.ErrorMessage }}></p>
    );
}