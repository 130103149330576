import { LegalDocuments } from "../../Services/LegalDocumentsContracts";

export interface LegalDocumentsState {
    LegalDocumentsPack: LegalDocuments;

    /** Current status of legal documents consent (V2 implementation). NULL if not applicable to the current brand. */
    IsConsentRequired: boolean;
}

export const LegalDocumentsInitialState: LegalDocumentsState = {
    LegalDocumentsPack: {
        TermsAndConditionsUrl: null,
        PrivacyPolicyUrl: null,
        CollectionStatementUrl: null,
        LegalDocumentsVersion: null
    },
    IsConsentRequired: false
}