import React from 'react'
import { connect } from 'react-redux';
import { ApplicationState } from '../../../appState';
import { BookingFormKind } from '../../UILogicControl/UILogicControlEntities';
import { BrandedImage, GetBrandedUrl } from '../../Utils/BrandedContentUrls';

interface ParcelIconProps {
    ActiveForm: BookingFormKind;
}

/** The parcel icon used in the taxi vs parcel selector in the booking form */
const ParcelIcon: React.FC<ParcelIconProps> = (props) => {

    const parcelIconImage = props.ActiveForm === BookingFormKind.ParcelBooking ? GetBrandedUrl(BrandedImage.ParcelIconWhiteSvg) : GetBrandedUrl(BrandedImage.ParcelIconBlackSvg);

    return (
        <div className="tabIcon">
        <img src={parcelIconImage} alt="Parcel Icon" ></img>
    </div>
    )
}

function mapStateToProps(state: ApplicationState): ParcelIconProps {
    return {
        ActiveForm: state.uiLogicControl.BookingForm.ActiveBookingForm,
    }
}

export default connect(mapStateToProps)(ParcelIcon);