import React from 'react';
import { BookingWidgetGoogleMap } from '../../widgets/google-maps/MapComponents/BookingWidgetGoogleMap';
import HeaderProfilePanel from '../../widgets/Header/HeaderProfilePanel';
import SiteHeader from '../../widgets/Header/SiteHeader';
import NavBar from '../../widgets/NavBar/NavBar';
import ConditionInfoView from '../Condition/UI/ConditionInfoView';
import { FareEstimatorForm } from './FareEstimatorForm';

interface FareEstimatorPageProps { 
    ShowServiceInfo: boolean;
}

/**
 * This is a wrapper of fare estimator desktop view layout..
 */
export const FareEstimatorPage: React.FC<FareEstimatorPageProps> = (props) => (
    <div className="booking-page-root">
        <SiteHeader />
        <NavBar NavItems={[]} />

        <div className="container">
            <BookingWidgetGoogleMap />
            <div className="booking-container">
            {props.ShowServiceInfo ? <ConditionInfoView /> : <FareEstimatorForm />}
            </div>
        </div>

        <HeaderProfilePanel />
    </div>
);
