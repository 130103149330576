import { BookingStatus, BookingAuthority } from "../../../Services/BookingEntities";
import { Api } from "../../../Services/Api";
import { Dispatch } from "../../Dispatch";
import { BookingDriverPhoto, BookingInfo, BookingTrackingDetails } from "../MyBookingEntities";

/** Consider and perform the loading of driver details for a particular booking. Only load driver photo if the booking's ApiVersion is V2 since other driver details are already available. */
export async function ConsiderRefreshDriverDetails(oldBooking: BookingInfo, updatedBooking: BookingTrackingDetails) {

    if (oldBooking.ApiVersion === 1) {
        if (ShouldLoadDriverDetails(oldBooking.TrackingInfo, updatedBooking)) {
            await LoadDriverDetails(oldBooking);
        }
    } else {
        if (ShouldLoadDriverPhoto(oldBooking.TrackingInfo, updatedBooking)) {
            await LoadDriverPhoto(oldBooking);
        }
    }    
}

/** Determine whether the driver details should be loaded (only used for V1 API). */
function ShouldLoadDriverDetails(oldBooking: BookingTrackingDetails, newBooking: BookingTrackingDetails): boolean {

    // Call the API only if the status is Accepted or higher.
    if (newBooking.BookingStatusID >= BookingStatus.Accepted) {

        // No driver details (name) loaded yet for the booking.
        if (!oldBooking.DriverName) return true;

        // Booking is accepted. But the driver ID is null in the previous status call.
        // This means previously accepted driver may have been removed from the booking.
        if (!newBooking.DriverID) return true;

        // Booking already has driver details but the driver ID has been changed (e.g: rejected or recalled)
        if (oldBooking.DriverID != newBooking.DriverID) return true;
    }

    return false;
}

/** Determine whether the driver photo should be loaded for V2 API bookings. */
function ShouldLoadDriverPhoto(oldBooking: BookingTrackingDetails, newBooking: BookingTrackingDetails): boolean {

    // Don't call the API if driver photo not exists. This is false when there is no driver yet for the booking.
    if (!newBooking.DriverPhotoExists) {

        // If there is already a driver image in store, clear it. This means the driver for the booking has been changed (e.g: rejected or recalled)
        if (oldBooking.DriverImage !== null) {
            Dispatch.MyBookings.ClearDriverImage(newBooking.BookingID);
        }

        return false;
    }

    // Call the API only if the status is Accepted or higher.
    if (newBooking.BookingStatusID >= BookingStatus.Accepted) {

        // No photo is loaded yet for the booking.
        if (!oldBooking.DriverImage) return true;

        // Booking already has a driver photo but the driver ID has been changed and DriverPhotoExists = true for the new driver
        if (oldBooking.DriverID != newBooking.DriverID) return true;
    }

    return false;
}

/** Load driver details for the specified booking. */
async function LoadDriverDetails(booking: BookingInfo) {
    const bookingKey: BookingAuthority = {
        bookingId: booking.BookingID,
        hashCode: booking.AccessCode
    }

    const serviceResult = await Api.Booking.GetDriverDetails(bookingKey);

    // If error, just return. No need to display an error message here.
    if (!serviceResult.isSuccess) {
        return;
    }

    Dispatch.MyBookings.RefreshDriverDetails(serviceResult.value);
}

/** Load driver photo for the specified booking. */
async function LoadDriverPhoto(booking: BookingInfo) {
    const bookingKey: BookingAuthority = {
        bookingId: booking.BookingID,
        hashCode: booking.AccessCode
    }

    const serviceResult = await Api.BookingV2.GetBookingDriverPhoto(bookingKey);

    // return on error
    if (!serviceResult.isSuccess) {
        return;
    }

    // Construct the image source here using the base64 string received from the API so that the UI can use it directly in the <img> tag without checking the ApiVersion and processing the string.
    const driverPhotoDetails: BookingDriverPhoto = {
        BookingId: booking.BookingID,
        DriverPhotoSource: `data:image/jpeg;base64, ${serviceResult.value.Base64FileContent}`
    }

    Dispatch.MyBookings.RefreshDriverPhoto(driverPhotoDetails);
}


/** Consider and load driver details (V1 API) or driver photos (V2 API) for bookings loaded recently (user's bookings or opened via links). 
 * called once after loading of bookings in bulk. */
export async function ConsiderLoadDriverDetailsOfRefreshedBookings(linkBookings: BookingInfo[]) {
    for (let booking of linkBookings) {

        // Since this is called just after booking is loaded and before any status update requests, both oldBooking and updateBooking parameters of 'ConsiderRefreshDriverDetails()' have same value so it simulates status update response with no real updates.
        await ConsiderRefreshDriverDetails(booking, booking.TrackingInfo);
    }
}