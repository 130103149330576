import { Api } from "../../Services/Api";
import { RegisterCnpGuestRequest, RegisterPayPalGuestRequest } from "../../Services/PaymentContractsV2";
import appstore from "../../appStore";
import { PayPal } from "../PayPal/PayPal";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { LoadMyCards } from "../Payment/PaymentHelper";
import { AddPayPalFunnel } from "../PayPal/AddPayPalFunnel";
import { BackingUserDetail } from "./GuestPaymentState";
import { AddApplePayFunnel } from "../ApplePay/AddApplePayFunnel";
import { AddGooglePayFunnel } from "../GooglePay/AddGooglePayFunnel";
import { GuestCreditCardFunnel } from "../CreditCard/GuestCreditCardFunnel";

/** 
 * UI flow for creating a temporary user account to support guest payment scenarios like PayPal Guest. 
 * This requires an SMS verification which leads to disjointed code across the ContactDetails and Verification dialogs.
 * The idea is to concentrate as much of the flow's code into this file.
 */
export namespace GuestPayUserSetupFlow {

	/** 
	 * Continue the PayPal Guest flow after SMS verification is successful.
	 * Now we register the user account and payment method, then refresh the cards list to bring in the PayPal option.
	 * Returns true on success. 
	 */
	export async function Step_2b_PostSmsVerification_PayPal(): Promise<boolean> {

		// recall some stuff from memory
		const appState = appstore.getState();

		const smsChallengeId = appState.verification.SmsChallengeId!;
		const paypalTokenised = appState.GuestPayment.PayPalTokenised!;
		const paypalAccount = paypalTokenised.details;

		const request: RegisterPayPalGuestRequest = {
			FirstName: paypalAccount.firstName,
			LastName: paypalAccount.lastName,
			PayPalAccountId: paypalAccount.payerId,
			SmsChallengeId: smsChallengeId,
			TokenisedPaymentNonce: paypalTokenised.nonce,
		};

		const result = await Api.PaymentV2.RegisterPayPalGuest(request);

		if (!result.isSuccess) {
			AddPayPalFunnel.ApiError("RegisterPayPalGuest", result, true);
			return false;
		}

		const userDetail: BackingUserDetail = {
			Auth: result.value.Auth,
			PhoneNumber: appState.verification.PhoneNumberUnderChallenge!,
		};
		
		Dispatch.GuestPayment.TempUserDetails(userDetail);
		Dispatch.Verification.ClearChallenge();

		await LoadMyCards();
		await PayPal.SetPayPalAsDefault();
		return true;
	}

	/**
	 * Continue the Apple Pay Guest flow after SMS verification is successful.
	 * Now we register the user account and payment method. The response will include the registered payment method and it will be used in the booking request.
	 * Returns true on success. 
	 */
	export async function PostSmsVerification_ApplePay(): Promise<boolean> {

		// recall some stuff from memory
		const appState = appstore.getState();

		const smsChallengeId = appState.verification.SmsChallengeId!;
		const applePayTokenised = appState.GuestPayment.ApplePayTokenised!;
		const bookingContact = appState.booking.Locations[0].Contact;

		const request: RegisterCnpGuestRequest = {
			ContactName: bookingContact.Name!,
			SmsChallengeId: smsChallengeId,
			TokenisedPaymentNonce: applePayTokenised.nonce,
			PaymentType: applePayTokenised.type
		};

		const result = await Api.PaymentV2.RegisterApplePayGuest(request);

		if (!result.isSuccess) {
			AddApplePayFunnel.ApiError("RegisterApplePayGuest", result, true);			

			// verification dialog will be opened again after closing the error message appears above. Hence dispatching this event to close the dialog.
			Dispatch.Dialog.CloseDialog(DialogKind.Verification);
			Dispatch.GuestPayment.ClearApplePay();
			return false;
		}

		const userDetail: BackingUserDetail = {
			Auth: result.value.Auth,
			PhoneNumber: appState.verification.PhoneNumberUnderChallenge!,
		};

		Dispatch.GuestPayment.TempUserDetails(userDetail);
		Dispatch.Payment.AddApplePay(result.value.PaymentCard);

		Dispatch.Verification.ClearChallenge();

		return true;
	}

	/**
	 * Continue the Google Pay Guest flow after SMS verification is successful.
	 * Now we register the user account and payment method. The response will include the registered payment method and it will be used in the booking request.
	 * Returns true on success. 
	 */
	export async function PostSmsVerification_GooglePay(): Promise<boolean> {

		// recall some stuff from memory
		const appState = appstore.getState();

		const smsChallengeId = appState.verification.SmsChallengeId!;
		const googlePayTokenised = appState.GuestPayment.GooglePayTokenised!;
		const bookingContact = appState.booking.Locations[0].Contact;

		const request: RegisterCnpGuestRequest = {
			ContactName: bookingContact.Name!,
			SmsChallengeId: smsChallengeId,
			TokenisedPaymentNonce: googlePayTokenised.nonce,
			PaymentType: googlePayTokenised.type
		};

		const result = await Api.PaymentV2.RegisterGooglePayGuest(request);

		if (!result.isSuccess) {
			AddGooglePayFunnel.ApiError("RegisterGooglePayGuest", result, true);

			// verification dialog will be opened again after closing the error message appears above. Hence dispatching this event to close the dialog.
			Dispatch.Dialog.CloseDialog(DialogKind.Verification);
			Dispatch.GuestPayment.ClearGooglePay();
			return false;
		}

		const userDetail: BackingUserDetail = {
			Auth: result.value.Auth,
			PhoneNumber: appState.verification.PhoneNumberUnderChallenge!,
		};

		Dispatch.GuestPayment.TempUserDetails(userDetail);
		Dispatch.Payment.AddGooglePay(result.value.PaymentCard);

		Dispatch.Verification.ClearChallenge();

		return true;
	}

	/**
	 * Continue the Credit Card Guest flow after SMS verification is successful.
	 * Now we register the user account and payment method. The response will include the registered payment method and it will be used in the booking request.
	 * Returns true on success. 
	 */
	export async function PostSmsVerification_CreditCard(): Promise<boolean> {

		// recall some stuff from memory
		const appState = appstore.getState();

		const smsChallengeId = appState.verification.SmsChallengeId!;
		const creditCardVerifiedPayload = appState.GuestPayment.CreditCard3DsVerified!;
		const bookingContact = appState.booking.Locations[0].Contact;

		const request: RegisterCnpGuestRequest = {
			ContactName: bookingContact.Name!,
			SmsChallengeId: smsChallengeId,
			TokenisedPaymentNonce: creditCardVerifiedPayload.nonce,
			PaymentType: creditCardVerifiedPayload.type
		};

		const result = await Api.PaymentV2.RegisterCreditCardGuest(request);

		if (!result.isSuccess) {
			GuestCreditCardFunnel.ApiError("RegisterCreditCardGuest", result, true);

			// verification dialog will be opened again after closing the error message appears above. Hence dispatching this event to close the dialog.
			Dispatch.Dialog.CloseDialog(DialogKind.Verification);
			Dispatch.GuestPayment.ClearCreditCard3Ds();
			return false;
		}

		const userDetail: BackingUserDetail = {
			Auth: result.value.Auth,
			PhoneNumber: appState.verification.PhoneNumberUnderChallenge!,
		};

		Dispatch.GuestPayment.TempUserDetails(userDetail);
		Dispatch.Payment.GuestCreditCard(result.value.PaymentCard);
		Dispatch.Verification.ClearChallenge();

		return true;
	}
}