import { FailureResult } from "../../Services/ServiceEntities";
import { LogEvent } from "../../utils/LogEvent";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { PaymentFunnelExitDetails } from "../Payment/PaymentFunnelExit";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";

/** 
 * Records the user's passage through PayPal UI flow.
 */
export namespace AddPayPalFunnel {

    /** Signal the start of the Add PayPal funnel. i.e. the user just clicked the button. */
    export function Start() {
        LogEvent.AddPayPalStart();
    }

    /** 
     * Record an exit from the Add PayPal funnel with a JavaScript or BrainTree error.
     * Since it comes from a JavaScript catch block, we can't type it properly. 
     */
    export function Exception(stageName: string, error: any, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.Exception(error);

        NoteFunnelExit(stageName, "Exception", details, isUserAction);
    }

    /** Record an exit from the Add PayPal funnel with a failed API call. */
    export function ApiError(stageName: string, apiError: FailureResult, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.ApiError(apiError);

        NoteFunnelExit(stageName, "API Error", details, isUserAction);
    }

    /**
     * The point is to record where along the flow the user dropped off.
     * Also displays a generic error UI.
     */
    function NoteFunnelExit(stageName: string, errorKind: string, details: Record<string, string>, isUserAction: boolean) {

        details.IsUserAction = String(isUserAction);
        LogEvent.PayPalFunnelExit(stageName, errorKind, details);

        if (isUserAction) {

            // this case is not really an error
            if ((details.name === "BraintreeError") && (details.code === "PAYPAL_POPUP_CLOSED")) {
                return;
            }

            // special case error: popup blocked
            if ((details.name === "BraintreeError") && (details.code === "PAYPAL_POPUP_OPEN_FAILED")) {
                ShowDialogSimpleErrorMessage(WellKnownMessageKind.PayPalPopupBlocked);
                return;
            }

            // generic error UI
            ShowDialogSimpleErrorMessage(WellKnownMessageKind.GeneralFailure);
        }
    }
}