import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { PaymentOptionKind } from "../Payment/PaymentEntities";

/** Validate selected service for SATSS and update the store with the validity status. */
export function ValidateServicesForSatss() {
    if (IsSatssLogicOk()) {
        Dispatch.UILogicControl.ClearSatssError();
        return;
    }

    Dispatch.UILogicControl.InvalidateServiceForSatss();
}

/** Validate selected service against SATSS (based on the location). */
function IsSatssLogicOk(): boolean {
    const appState = appstore.getState();
    const preferredLocation = appState.location.preferredLocation;
    const selectedCondition = appState.condition.SelectedCondition;
    const selectedPaymentMethod = appState.booking.PaymentOption;

    if (!preferredLocation.isKnown) return true;
    if (preferredLocation.value.stateCode !== 'sa') return true;
    if (!selectedPaymentMethod) return true;
    if (selectedPaymentMethod.Kind !== PaymentOptionKind.SatssVoucher) return true;
    if (selectedCondition.Service.supportSATSS) return true;

    return false;
}