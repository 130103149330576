import React from "react";
import "./AccountBookings.scss";
import { SimpleAccount } from "../../User/ProfileEntitiesV2";
import { Dispatch } from "../../Dispatch";
import { connect } from "react-redux";
import { AccountBookingPayload } from "../BookingEntities";
import { ApplicationState } from "../../../appState";
import { BookingWidgetModeKind } from '../../BookingTemplate/BookingTemplateEntities';
import { CustomTextFieldProps, OutlinedTextField } from "../Widget/OutlinedTextField";
import { FeatureFlags } from "../../../Config/FeatureFlags";

interface AccountData {
    account: SimpleAccount;
}

interface PropsFromStore {
    BookingWidgetMode: BookingWidgetModeKind;
    accountBookingDetails: AccountBookingPayload | null;
    IsBookingFormStrictValidationModeOn: boolean;
}

interface AccountDataState {
    isOrderNumberFocused: boolean;
}

/** User input fields added to the new booking form when booking on account. Field labels are different based on the selected account. */
class AccountCustomFields extends React.Component<AccountData & PropsFromStore, AccountDataState> {
    
    private inputRef: React.RefObject<HTMLInputElement>;

    constructor(props: AccountData & PropsFromStore) {
        super(props);

        this.state = {
            isOrderNumberFocused: false
        }

        this.inputRef = React.createRef();
        this.UpdateCustomValues = this.UpdateCustomValues.bind(this);
    }

    /**
     * Update the Order Number, when booking from recent trips    
     */
    componentDidMount() {
        
        if (!this.props.accountBookingDetails) return;

        const { OrderNumber = "" } = this.props.accountBookingDetails;
        
        if (this.inputRef.current) {
            this.inputRef.current.value = OrderNumber;
        }        
    }

    /**
     * Update the Order Number, on account selection in the dropdown
     */
    componentDidUpdate(prevProps: Readonly<AccountData & PropsFromStore>): void {
        if (!this.props.accountBookingDetails) return;

        if(this.props.accountBookingDetails?.OrderNumber != prevProps?.accountBookingDetails?.OrderNumber)
        {
            const { OrderNumber = "" } = this.props.accountBookingDetails;
            
            if (this.inputRef.current && this.inputRef.current.value !== OrderNumber) {
                this.inputRef.current.value = OrderNumber;
            }
        }
    }

    /** Focus status of order umber field is used to show/hide validation message */
    updateFocusStatus = () => {
		this.setState({ isOrderNumberFocused: true });
	};

    UpdateCustomValues(e: React.FocusEvent<HTMLInputElement>) {
        this.setState({ isOrderNumberFocused: false }); // Make focus status false on blur
        Dispatch.Booking.AccountDetails({...this.props.accountBookingDetails!, OrderNumber: e.target.value});
    }

    /** Check the validity of the Order number field to show the error message.
     * Conditions to check are:
     * 1. User clicked on the Book button
     * 2. OrderNumberRequired field is true for the selected account 
     * 3. Account details is undefined or null
     * 4. Order number is undefined or empty
     * 5. Order number field is not focused
     * 
     * The function returns false either conditions 1,2 and 3 are satisfied or 1,2,4 and 5 are satisfied and the UI will show the error message.
     */
    IsInputValid = () => {

        // Do not validate for booking template
        if (this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking) return true;

        if (this.props.IsBookingFormStrictValidationModeOn) { // True if clicked on Book button
            if (this.props.account.IsOrderNumberRequired) { // Whether the Order number is mandatory for the selected account
                if (!this.props.accountBookingDetails) { // Account details is undefined or null
                    return false;
                } else if (!this.props.accountBookingDetails.OrderNumber && this.state.isOrderNumberFocused === false) { // Order number field is not focused and the value is empty or undefined.
                    return false;
                }
            }
        }

        return true;
    }

    /** Decides when to display Order Number input field in the booking form. */
    ShouldShowOrderNumberField(): boolean {
        if (!FeatureFlags.OrderNumber) return false;
        if (this.props.account.IsOrderNumberRequired) return true;
        if (this.props.account.OrderNumberLabel !== "") return true;

        return false;
    }

    /** Update empty value in store */
    clearText = () => Dispatch.Booking.AccountDetails({...this.props.accountBookingDetails!, OrderNumber: ''});;

    render() {

        if (!this.ShouldShowOrderNumberField()) {
            return null;
        }

        // Do not allow focus for booking template mode
        const allowFocus = this.props.BookingWidgetMode === BookingWidgetModeKind.Booking;

        // Validate the input
        const isInputInvalid = !this.IsInputValid();
        
        const label = this.props.account.OrderNumberLabel || "Order number";
        
        const errorText = `${label} is required`;

        const inputProp: CustomTextFieldProps  = {
            LabelText: label,
            Name: "OrderNumber",
            ErrorMessage: errorText,
            AllowAutoFocus: allowFocus,
            onClearEvent: this.clearText,
            IsInputInvalid: isInputInvalid,
            onFocusEvent: this.updateFocusStatus,
            onBlurEvent: this.UpdateCustomValues,
            IsInputFocussed: this.state.isOrderNumberFocused,
            DoesInputHasValue: !!this.props.accountBookingDetails?.OrderNumber
        }

        return (
            <div className="booking-fields-panel">
                <OutlinedTextField ref={this.inputRef} {...inputProp} />
            </div>            
        );
    }
}

function mapStateToProps(state: ApplicationState) : PropsFromStore {
    return {
        accountBookingDetails: state.booking.AccountData,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsBookingFormStrictValidationModeOn: state.uiLogicControl.BookingForm.IsStrictValidationModeOn
    }
}

export default connect(mapStateToProps)(AccountCustomFields);