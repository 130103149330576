/** 
 * This function can be used to format mobile numbers. If there is a leading 0, it removes the 0 and returns the rest of the number or else returns the number.
   IE doesn't support  startWith function. Used Indexof as an alternative to achive the same goal
 */
export function DropLeadingZero(mobileNumber: string) {
    return mobileNumber.indexOf("0")===0 ? mobileNumber.substr(1, mobileNumber.length - 1) : mobileNumber;
}

/**
 * 
 * Add plus if a mobile number does not have a leading "+"
 */
export function CheckAddPlus(s: string | null) {
    if (s == null || s == "") return "";
    return s.charAt(0) === "+" ? s : "+" + s;
}

/* Format the phone number to display properly (with spaces and brackets) */
export function FormatPhoneNumber(input: string) {
  if (input.startsWith('04') || input.startsWith('1300')) {
      const s = {
          a: input.substr(0, 4),
          b: input.substr(4, 3),
          c: input.substr(7, 3),
      };
      return `${s.a} ${s.b} ${s.c}`;
  } else {
      const s = {
          a: input.substr(0, 2),
          b: input.substr(2, 4),
          c: input.substr(6, 4),
      };
      return `(${s.a}) ${s.b} ${s.c}`;
  }
}

/** Check if the given value is a number */
export const isNumber = (val: number) => val !== undefined && val !== null && typeof (val) === 'number';

/** Add country code to 1300 and 1800 numbers. */
export function FormatBusinessNumber(contactNumber: string) {

    if (contactNumber.startsWith("1300") || contactNumber.startsWith("1800")) {
        return "+61" + contactNumber;
    }
    return contactNumber;
}