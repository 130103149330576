import React from "react";
import BookingPage from "./BookingPage";
import { Dispatch } from "../Dispatch";

/** Show the booking page, but include diagnostics. */
export class BookingPageWithDiag extends React.Component {

    componentDidMount() {
        Dispatch.Booking.EnableDiagnosticUI();
    }

    render() {
        return <BookingPage />
    }
}