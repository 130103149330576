import React from "react";
import { useAppState } from "../../../Redux/ReduxHooks";
import { OptionalUI } from "./OptionalUI";
import { EmailForPaymentReceipt } from "../../GuestPayment/EmailForPaymentReceipt";

/** Possibly renders the email for receipt input field in the booking form. */
export const MaybeEmailForPaymentReceipt: React.FC = () => {

    const shouldDisplayInput = useAppState(OptionalUI.EmailForPaymentReceipt);

    if (!shouldDisplayInput) return null;

    return (
        <EmailForPaymentReceipt />
    );
}