import { CheckAddPlus } from '../../utils/Formattingutil';
import { DialogKind } from '../Dialog/DialogEntities';
import { SimpleUserProfile } from '../User/ProfileEntitiesV2';
import { Dispatch } from "../Dispatch";
import { SmsVerification, StartSmsOutcome } from "./SmsVerification";

/** 
 *  Start an SMS verification challenge in order to complete the user's profile.
 *  This is for legacy users who signed up without defining a phone number.
 */
export async function VerifyPhoneForProfile(userProfile: SimpleUserProfile, thenCreateBooking: boolean) {

    Dispatch.UILogicControl.BookingFormApiStart();
    Dispatch.Auth.IsBookingNeededAfterVerification(thenCreateBooking);

    appInsights.trackEvent("Log In & Need Verification", { User: JSON.stringify(userProfile) });

    const phoneNumber = CheckAddPlus(userProfile.ContactPhone);
    const result = await SmsVerification.StartChallenge(phoneNumber);

    // Checked with Cameron, we have silent handling here, which means do nothing, if send verification fails 
    if (result.Outcome === StartSmsOutcome.Success) {
        Dispatch.Dialog.ShowDialog(DialogKind.Verification);
    }
}
