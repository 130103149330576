import React from 'react';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import '../../Verification/ContactVerification.scss';
import { DialogProps } from '../Entities';
import { RemoveDialogInput, RemoveDialogResult, RemoveTemplateUserAction } from './RemoveTemplateEntities';
import DialogClose from '../../Dialog/DialogClose';
import { Api } from '../../../Services/Api';
import { BookingTemplateDeletionRequest } from '../../BookingTemplate/BookingTemplateEntities';
import { LogEvent } from '../../../utils/LogEvent';
import { GetBrandedUrl, BrandedImage } from '../../Utils/BrandedContentUrls';

type MyProps = DialogProps<RemoveDialogInput, RemoveDialogResult>

interface stateProps {
    showSpinner: boolean;
}

/** Modal to confirm removal of booking templates. */
export class RemoveTemplateDialog extends React.Component<MyProps, stateProps> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            showSpinner: false
        }

        this.RemoveFavourite = this.RemoveFavourite.bind(this);
    }

    /** Remove the selected booking template and close the popup. */
    async RemoveFavourite() {
        this.setState({ showSpinner: true });

        const templateToRemove: BookingTemplateDeletionRequest = {
            BookingTemplateId: this.props.Input.TemplateID
        }

        var removeResult = await Api.BookingTemplate.RemoveTemplate(templateToRemove);

        this.props.CompletionCallback({
            UserAction: RemoveTemplateUserAction.Confirm,
            IsRemoveSuccess: removeResult.isSuccess
        });

        if(removeResult.isSuccess) LogEvent.BookingTemplateRemoved();
    }

    /** Close the popup on click of 'No' button */
    CancelRemove = () => {
        this.props.CompletionCallback({
            UserAction: RemoveTemplateUserAction.Cancel
        });
    }

    render() {

        const illustratorClass = this.state.showSpinner ? "popup-illustrator low-opacity" : "popup-illustrator";

        return (
            <div className="contact-details booking-cancel-dialog remove-fav-dialog">
                <DialogClose />
                <h1 className="orange-header header-title">Are you sure?</h1>

                <img src={GetBrandedUrl(BrandedImage.ThinkingGirl)} className={illustratorClass}></img>

                <p className="popup-description">Are you sure you want to remove this favourite?</p>

                <div className="popup-btn-group">
                    <button type="button" className="cancel-btn half-btn" onClick={this.RemoveFavourite}>Yes Remove</button>
                    <button type="button" className="active-btn half-btn" onClick={this.CancelRemove}>No</button>
                </div>

                {this.state.showSpinner ? <div className="cancel-spinner"><img alt="Cancelling" src={getContentUrl(ContentURL.images.Loading)} width="100" /></div> : ""}
            </div>
        );
    }
}