import React from 'react';
import "./QuickBooking.scss";
import { connect } from "react-redux";
import { Dispatch } from '../../Dispatch';
import EmptyRecentTrips from './EmptyRecentTrips';
import { ApplicationState } from "../../../appState";
import { RecentTripHistory } from "./RecentTripHistory";
import { LoginStatusKind } from '../../Authentication/AuthEntities';
import { QuickBookLoadingAnimation } from './QuickBookLoadingAnimation';
import { SimpleUserProfile } from '../../User/ProfileEntitiesV2';
import { BookingTemplateState } from "../../BookingTemplate/BookingTemplateState";
import BookingTemplateDetails from '../../BookingTemplate/BookingTemplateDetails';
import { AreTemplatesAvailable, GetToggleRecentTripListCssClass } from '../../BookingTemplate/BookingTemplateHelper';
import { BookingTabKind, RecentTripLoadKind, QuickBookExpandedSection } from '../../UILogicControl/UILogicControlEntities';
import { LogEvent } from '../../../utils/LogEvent';
import { BookingInfo } from '../../MyBookings/MyBookingEntities';
import { Tooltip } from '@mui/material';

export interface PropsFromStore {
    TemplateList: BookingTemplateState;
    LoginStatus: LoginStatusKind;
    RecentTrips: BookingInfo[];
    UserProfile: SimpleUserProfile | undefined;
    RecentTripLoadingStatus: RecentTripLoadKind;
    QuickBookExpandedStatus: QuickBookExpandedSection;
}

class QuickBookingTab extends React.Component<PropsFromStore> {
    
    componentDidMount() {
        LogEvent.BookingTabSelected("QuickBook");
    }

    // Expand/Collapse the recent trip list
    toggleHandler = () => {

        const { RecentTrips, QuickBookExpandedStatus} = this.props;

        // Toggle only when the Switch is ON and not a DVA user
        if (!AreTemplatesAvailable()) return;
        
        // When the list is empty and expanded, it's not collapsible
        if (RecentTrips.length === 0 && QuickBookExpandedStatus === QuickBookExpandedSection.RecentBookings) return;
        
        const listState = QuickBookExpandedStatus !== QuickBookExpandedSection.RecentBookings ? QuickBookExpandedSection.RecentBookings : QuickBookExpandedSection.None;
     
        Dispatch.UILogicControl.ToggleQuickBookSection(listState);
    }
    
    renderComponent = () => {

        const { LoginStatus, RecentTrips, RecentTripLoadingStatus, QuickBookExpandedStatus } = this.props;

        const isUserLoggedIn = LoginStatus === LoginStatusKind.LoggedIn;

        const condition = `${LoginStatus} && ${RecentTripLoadingStatus}`;

        switch (condition) {
                                    
            // Load <QuickBookLoadingAnimation />, until that the user is logged-in and the recent trips are    loaded
            case `${LoginStatusKind.GetUserProfileInProgress} && ${RecentTripLoadKind.Idle}`:
            case `${LoginStatusKind.GetUserProfileInProgress} && ${RecentTripLoadKind.Loading}`:
            case `${LoginStatusKind.LoggedIn} && ${RecentTripLoadKind.Idle}`:
            case `${LoginStatusKind.LoggedIn} && ${RecentTripLoadKind.Loading}`:
                return <QuickBookLoadingAnimation />                                   

            // Switch to NewBooking Tab, when the user is logged out due to session expiration
            case `${LoginStatusKind.LoggedOut} && ${RecentTripLoadKind.Loading}`:
                Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);
                return null;       

            // Load <RecentTripHistory />, if the user is logged-in and has recent trips
            case `${LoginStatusKind.LoggedIn} && ${RecentTripLoadKind.Loaded}`:
                return <RecentTripHistory recentTrips={RecentTrips} disabledButton={false} listState={QuickBookExpandedStatus}  />

            /**
             * Load <EmptyRecentTrips />, when either of the below condition is met:
                1. user is not logged-in (guest)
                2. user is logged-in but, has no previous booking history
             */
            default:
                return <EmptyRecentTrips isUserLoggedIn={isUserLoggedIn} listState={QuickBookExpandedStatus} />
        }
    }    

    render() {

        const { TemplateList, QuickBookExpandedStatus } = this.props;

        // Count the number of templates
        const templateCount = TemplateList.MyTemplates.length;

        // Check to render templates
        const canShowTemplates = AreTemplatesAvailable();        

        // Show the top border when the booking template are displayed
        const topMarginClass = canShowTemplates && "recent-trips-title-top-margin";

        // Logged-in Non-DVA users can toggle the recent trip list if exist
        const arrowClass = GetToggleRecentTripListCssClass(QuickBookExpandedStatus);

        // Show pointer cursor to logged-in Non-DVA users if, the recent trip list exist
        const showCursorClass = arrowClass && "recent-trips-show-pointer-cursor";   
        
        const tooltipTitle = QuickBookExpandedStatus === QuickBookExpandedSection.RecentBookings ? "Hide recent trips" : "Show recent trips";

        return (           
                <div className="quick-book-panel">                    
                    { canShowTemplates && <BookingTemplateDetails numberOfTemplates={templateCount} /> }
                    <div className={`recent-trips-form ${topMarginClass}`}>                        
                        <div className={`recent-trips-title ${showCursorClass}`} onClick={this.toggleHandler} >
                            <p className="header">Recent trips</p>
                            <p className="description">
                                <span>Re-use past bookings made</span>
                                <Tooltip title={tooltipTitle} arrow >
                                    <span className={arrowClass}></span>
                                </Tooltip>                       
                            </p>
                        </div>
                        { this.renderComponent() }
                    </div>
                </div>
        )                    
    }    
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        TemplateList: state.bookingTemplates,
        LoginStatus: state.authentication.LoginStatus,
        RecentTrips: state.myBookings.RecentTrips,
        UserProfile: state.authentication.UserProfile,
        QuickBookExpandedStatus: state.uiLogicControl.BookingForm.QuickBookExpandedStatus,
        RecentTripLoadingStatus: state.uiLogicControl.BookingForm.RecentTripLoadingStatus
    }
}

export default connect(mapStateToProps)(QuickBookingTab);