import React from "react";
import { Dispatch } from "../../Dispatch";
import "./TrackVisibilityBlock.scss";

interface TrackVisibilityProps {
    isVisible?: boolean;
}

/**
 * On the top of mobile booking form, which is used for tracking visibility purpose for sticky header
 */
export class TrackVisibilityBlock extends React.Component<TrackVisibilityProps> {

    /**
     * Listen to TrackVisibility
     */
    componentDidUpdate() {
        if (this.props.isVisible === true) {
            Dispatch.UILogicControl.ShowMobileHeader();
        }
        else if (this.props.isVisible === false) {
            Dispatch.UILogicControl.HideMobileHeader();
        }
    }

    render() {
        return(<div className="tracking-visibility-block" ></div>)
    }
}