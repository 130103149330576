import { ServiceCheckStatus } from "../Booking/BookingEntities";
import { CanUserMakeFixedFareBookings } from "../Fare/FareHelper";
import { ApplicationState } from "../../appState";

/**
 * Whether fare estimate data can be shown at all.
 * Sometimes the UI is not ready, e.g. booking locations not populated.
 */
export function IsValidFareDataAvailable(state: ApplicationState): boolean {

    const isServicedStatus = state.booking.PickupServiceCheck.status;
    const pickup = state.booking.Locations[0].Address;
    const dropoff = state.booking.Locations[1].Address;

    if (!pickup) return false;
    if (!dropoff) return false;
    if (isServicedStatus !== ServiceCheckStatus.KnownGood) return false;

    if (state.uiLogicControl.BookingForm.IsInvalidConditionForSatss) return false;
    if (!state.uiLogicControl.BookingForm.IsSelectedVehicleServiceable) return false;

    return true;
}

/** 
 *  Whether the fixed fare capability / service is supported for the selected vehicle.
 *  When consuming this from a Component, bind it the a prop using react-redux.
 */
export function IsFixedFareAvailable(state: ApplicationState): boolean {
    
    // Fare is not refreshed, but the address is cleared.
    if (!IsValidFareDataAvailable(state)) return false;
    if (!CanUserMakeFixedFareBookings(state)) return false;

    // Fixed fare can be undefined if the specified location is not in a fixed fare available area.
    if (!state.condition.IsFixedFareAvailable) return false;

    return true;
}