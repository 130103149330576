import { CuratedLocationFeatureUsage, LocationZoneQuery, LocationZoneResult } from "./LocationsEntitiesV2";
import { ServiceResult } from "./ServiceEntities";
import { GetManagementServiceRootUrlWithoutState, Post } from "./ServiceManager";

/** Base URL for the LocationV2Controller in the Booking Management Service. */
function GetBaseUrl(): string {
    return `${GetManagementServiceRootUrlWithoutState()}LocationV2/`;
}

/** HTTP API Calls to the LocationController in the Booking Management Service. */
class LocationServiceV2Class {

    /** Determine whether a given location falls within a curated location zone, and if so return a list of acceptable addresses within the zone. */
    public QueryCuratedLocation(request: LocationZoneQuery): Promise<ServiceResult<LocationZoneResult>> {
        const url = `${GetBaseUrl()}QueryCuratedLocation`;
        return Post<LocationZoneResult>(url, request);
    }

    /** Send feedback for analysis purposes on the particular location address a user chose within a zone, if any. */
    public FeedbackCuratedLocation(request: CuratedLocationFeatureUsage): Promise<ServiceResult<void>> {
        const url = `${GetBaseUrl()}FeedbackCuratedLocation`;
        return Post<void>(url, request);
    }
}

/** Singleton export for static access to the operations */
export const LocationServiceV2 = new LocationServiceV2Class();