import { BookingAuthority } from "../../Services/BookingEntities";
import { Api } from "../../Services/Api";
import { Dispatch } from "../Dispatch";
import { BookingInfo } from "./MyBookingEntities";

/** Get the share URL and the read only hash code. */
export async function GetBookingDetailsToShare(booking: BookingInfo): Promise<BookingInfo> {

    // If the read access code is available, no need to proceed.
    if (booking.ReadAccessCode) return booking;

    const bookingKey: BookingAuthority = {
        bookingId: booking.BookingID,
        hashCode: booking.AccessCode
    }
    const result = await Api.Booking.GetShareURL(bookingKey);

    // Nothing happens if GetShareURL fails. The UI components handle it when the ReadAccessCode is not available.
    if (!result.isSuccess) return booking;

    const bookingWithReadOnlyAccessCode: BookingInfo = {
        ...booking,
        ReadAccessCode: result.value.HashCode,
        BookingTrackingBaseUrl: result.value.UrlBase
    }

    // To access this booking in the Share booking dialog.
    Dispatch.MyBookings.SetBookingToShare(bookingWithReadOnlyAccessCode);  
    Dispatch.MyBookings.RefreshBookingWithReadAccessCode(bookingWithReadOnlyAccessCode);

    return bookingWithReadOnlyAccessCode;
}