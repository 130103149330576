import { ReduxStoreSlice } from "../../../Redux/ReduxStoreSlice";
import { BookingLocationInfo, BookingWorkFlowState, DefaultBookingWorkFlowState, ReOrderedLocation, locationInitialValue } from "./BookingState";
import { ServiceCheckStatus, PickupServiceCheckState, AccountBookingPayload, PickupServiceabilityDetails } from "../BookingEntities";
import { PaymentOption } from "../../Payment/PaymentEntities";
import { FeatureFlags } from "../../../Config/FeatureFlags";
import { AddressV2 } from "../../../Services/MakeBookingContracts";
import { DateTime } from "luxon";
import { BookingTimeFutureV2, BookingTimeNowV2, BookingTimeV2 } from "../../BookingV2/BookingV2Entities";
import { AddressPointDetails } from "../../../Services/AddressPointsContracts";
import { DeliveryOptionKind } from "../Parcel/ParcelEntities";
import { FullPhoneNumber } from "../../PhoneNumber/PhoneNumberEntities";
import { PerLocationBookingSlice } from "./PerLocationBookingSlice";

const slice = new ReduxStoreSlice<BookingWorkFlowState>("Booking", DefaultBookingWorkFlowState);
const perLocation = new PerLocationBookingSlice(slice);

/** Dispatcher for actions in the Booking state slice. */
export const BookingDispatchV2 = {

    /** Update the address of a booking location */
    Address: perLocation.Action("Address", Address),

    /** Reset (to empty) the address of a booking location. */
    ClearAddress: perLocation.EmptyAction("Clear Address", ClearAddress),

    // timing
    TimeForNow: slice.EmptyAction("Time For Now", TimeForNow),
    TimeForFuture: slice.Action("Time For Future", TimeForFuture),

    /** Update the driver notes for a booking location. */
    DriverNotes: perLocation.Action("Driver Notes", DriverNotes),

    /** Reset / Clear the driver notes for a booking location */
    ClearDriverNotes: perLocation.EmptyAction("Clear Driver Notes", ClearDriverNotes),

    PickupServiceability: slice.Action("Pickup Serviceability", PickupServiceability),
    EnableDiagnosticUI: slice.EmptyAction("Enable Diagnostic UI", HandleEnableDiagnosticUI),
    SetBookOnAccount: slice.Action("Book On Account", HandleSetBookOnAccount),
    PaymentMethod: slice.Action("Change Payment Option", PaymentMethod),
    AccountDetails: slice.Action("Change Account Details", AccountDetails),
    ClearAccountDetails: slice.EmptyAction("Clear Account Details", HandleClearAccountDetails),
    SetDeviceData: slice.Action("Set Device Data", HandleSetDeviceData),
    SetTemplateName: slice.Action("Change Template Name", HandleTemplateNameChange),

    /** Clear / unselect the payment option on the booking */
    ClearPaymentOption: slice.EmptyAction("Clear Payment Option", ClearPaymentOption),

    /** Set the favourite address record used to select the address at this location */
    FavouriteAddress: perLocation.Action("Favourite Address", FavouriteAddress),

    /** Reset / Clear the favourite address linked to the address at this location */
    ClearFavouriteAddress: perLocation.EmptyAction("Clear Favourite Address", ClearFavouriteAddress),

    ChangeDeliveryOption: slice.Action("Change Delivery Option", HandleDeliveryOptionChange),

    /** Reset the Delivery Option to the default (null). */
    ClearDeliveryOption: slice.EmptyAction("Clear Delivery Option", ClearDeliveryOption),
    SetDeliveryOptionError: slice.Action("Set Delivery Option Error", SetDeliveryOptionErrorMessage),

    /** Set the contact or passenger name for a booking location */
    ContactName: perLocation.Action("Contact Name", ContactName),

    /** Clear / reset the contact or passenger name for a booking location */
    ClearContactName: perLocation.EmptyAction("Clear Contact Name", ClearContactName),

    /** Set the phone number for the contact or passenger at a booking location */
    ContactPhone: perLocation.Action("Contact Phone", ContactPhone),

    /** Clear / reset the phone number for the contact or passenger at a booking location */
    ClearContactPhone: perLocation.EmptyAction("Clear Contact Phone", ClearContactPhone),

    /** Generate a fresh ODRD Trip ID after creating a booking. */
    RegenerateOdrdTripId: slice.Action("Regenerate ODRD Trip ID", RegenerateOdrdTripId),

    /** Adds an empty location in to the Locations list in order to create a new destination field in the UI. */
    AddEmptyLocation: slice.EmptyAction("Add Empty Location", AddEmptyLocation),

    /** Removes a location from the Locations list. This is dispatched when the user removes a destination input field. */
    RemoveLocation: slice.Action("Remove Location", RemoveLocation),

    /** Reorder the locations in the Locations list. This is called after dragging and dropping an input field. */
    ReOrderLocations: slice.Action("ReOrder Locations", ReOrderLocations),
};

/** Reducer for the Booking store slice */
export const BookingReducerV2 = slice.MakeCombinedReducer();

/** Apply a booking time update action to the booking state (book for now). */
function TimeForNow(state: BookingWorkFlowState): BookingWorkFlowState {

    const timeV2: BookingTimeNowV2 = {
        IsImmediate: true
    };

    return BookingTimeHandler(state, timeV2);
}

/** Apply a booking time update action to the booking state (book for future). */
function TimeForFuture(state: BookingWorkFlowState, bookingTime: DateTime): BookingWorkFlowState {

    const timeV2: BookingTimeFutureV2 = {
        RequestedDate: bookingTime,
        IsImmediate: false
    };

    return BookingTimeHandler(state, timeV2);
}

/** Generate a shared BookingWorkFlowState for update time now and future. */
function BookingTimeHandler(state: BookingWorkFlowState, timeV2: BookingTimeV2): BookingWorkFlowState {

    return {
        ...state,
        BookingTimeV2: timeV2
    };
}

/** Handler for the PickupServiceCheckUpdate action. This will update the PickupServiceCheck data. */
function PickupServiceability(bookingState: BookingWorkFlowState, serviceabilityDetails: PickupServiceabilityDetails): BookingWorkFlowState {

    // invalid updates get ignored
    if (!IsProposedStateChangeValid(bookingState, serviceabilityDetails.PickupPlaceId, serviceabilityDetails.ServiceabilityCheckState)) {
        return bookingState;
    }

    return {
        ...bookingState,
        PickupServiceCheck: serviceabilityDetails.ServiceabilityCheckState,
    };
}

/** This is turning into a mini reducer for CreateBookingPayload.PickupServiceCheck. Should i be using recompose or something?? */
function IsProposedStateChangeValid(currentBookingState: BookingWorkFlowState, pickupAddressPlaceId: string, proposedNewState: PickupServiceCheckState): boolean {

    // check is only fitting to BookingController
    if (FeatureFlags.BookingApiV2) return true;

    // updates to indeterminate state are always OK
    if ((proposedNewState.status === ServiceCheckStatus.CheckInProgress) || (proposedNewState.status === ServiceCheckStatus.NoInputSelected)) {
        return true;
    }

    // result update: only honour if it still matches the pickup address
    const existingPickup = currentBookingState.Locations[0].Address;
    return !!existingPickup && existingPickup.GoogleMapsPlaceId === pickupAddressPlaceId;
}

/** Handle a EnableDiagnosticUI action. Just sets the flag to true. */
function HandleEnableDiagnosticUI(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        ShowDiagnosticUI: true,
    };
}

/** Enable/disable Book on account feature based on the given value */
function HandleSetBookOnAccount(state: BookingWorkFlowState, newValue: boolean): BookingWorkFlowState {
    return {
        ...state,
        IsOnAccount: newValue
    };
}

/** Handle the Payment option change for Booking Payload */
function PaymentMethod(state: BookingWorkFlowState, selectedPaymentOption: PaymentOption): BookingWorkFlowState {
    return {
        ...state,
        PaymentOption: selectedPaymentOption
    };
};

/** Handle updating account details for bookings on account. */
function AccountDetails(state: BookingWorkFlowState, accountDetails: AccountBookingPayload): BookingWorkFlowState {
    return {
        ...state,
        AccountData: accountDetails,
    };
}

/** Handle clear account details when Book on account is disabled. */
function HandleClearAccountDetails(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        AccountData: null
    };
}

/** Handle for setting device data for Booking Payload */
function HandleSetDeviceData(state: BookingWorkFlowState, deviceData: string): BookingWorkFlowState {
    return {
        ...state,
        DeviceData: deviceData
    };
};

/** Handle the Template name */
function HandleTemplateNameChange(state: BookingWorkFlowState, name: string): BookingWorkFlowState {
    return {
        ...state,
        TemplateName: name,
    };
}

/** Clear / unselect the payment option on the booking */
function ClearPaymentOption(state: BookingWorkFlowState): BookingWorkFlowState {

    const { PaymentOption, ...newCreatePayload } = state;

    return newCreatePayload;
};

/** Handle the delivery option change for Booking Payload */
function HandleDeliveryOptionChange(state: BookingWorkFlowState, selectedDeliveryOptionValue: DeliveryOptionKind): BookingWorkFlowState {
    return {
        ...state,
        DeliveryOption: selectedDeliveryOptionValue
    };
};

/** Reset the Delivery Option to the default (null). */
function ClearDeliveryOption(state: BookingWorkFlowState): BookingWorkFlowState {
    return {
        ...state,
        DeliveryOption: null
    };
}

/** Set the error message if delivery option not selected when new parcel booking */
function SetDeliveryOptionErrorMessage(state: BookingWorkFlowState, errorMessage: string | null): BookingWorkFlowState {
    return {
        ...state,
        DeliveryOptionError: errorMessage
    }
}

function RegenerateOdrdTripId(state: BookingWorkFlowState, newId: string): BookingWorkFlowState {
    return {
        ...state,
        GoogleOdrdTripId: newId,
    };
}

// #region Per-location reducer definition

/** Update the address of a booking location */
function Address(state: BookingLocationInfo, address: AddressV2): BookingLocationInfo {
    return {
        ...state,
        Address: address,
    };
}

/** Reset (to empty) the address of a booking location. */
function ClearAddress(state: BookingLocationInfo): BookingLocationInfo {
    return {
        ...state,
        Address: null,
    };
}

/** Update the driver notes for a booking location. */
function DriverNotes(state: BookingLocationInfo, notes: string): BookingLocationInfo {
    return {
        ...state,
        DriverNotes: notes,
    };
}

/** Reset / Clear the driver notes for a booking location */
function ClearDriverNotes(state: BookingLocationInfo): BookingLocationInfo {
    return {
        ...state,
        DriverNotes: null,
    };
}

/** Set the favourite address record used to select the address at this location */
function FavouriteAddress(state: BookingLocationInfo, favourite: AddressPointDetails): BookingLocationInfo {
    return {
        ...state,
        Favourite: favourite,
    };
}

/** Reset / Clear the favourite address linked to the address at this location */
function ClearFavouriteAddress(state: BookingLocationInfo): BookingLocationInfo {
    return {
        ...state,
        Favourite: null,
    };
}

/** Set the contact or passenger name for a booking location */
function ContactName(state: BookingLocationInfo, contactName: string): BookingLocationInfo {
    return {
        ...state,
        Contact: {
            ...state.Contact,
            Name: contactName,
        }
    };
}

/** Clear / reset the contact or passenger name for a booking location */
function ClearContactName(state: BookingLocationInfo): BookingLocationInfo {

    const { Name, ...otherContactDetails } = state.Contact;

    return {
        ...state,
        Contact: otherContactDetails,
    };
}

/** Set the phone number for the contact or passenger at a booking location */
function ContactPhone(state: BookingLocationInfo, contactPhone: FullPhoneNumber): BookingLocationInfo {
    return {
        ...state,
        Contact: {
            ...state.Contact,
            Phone: contactPhone
        }
    }
}

/** Clear / reset the phone number for the contact or passenger at a booking location */
function ClearContactPhone(state: BookingLocationInfo): BookingLocationInfo {
    return {
        ...state,
        Contact: {
            ...state.Contact,
            Phone: null
        }
    }
}

// #endregion

/** Adds an empty location in to the Locations list in order to create a new destination field in the UI. */
function AddEmptyLocation(state: BookingWorkFlowState): BookingWorkFlowState {

    return {
        ...state,
        Locations: [...state.Locations, locationInitialValue]
    };
}

/** Removes a location from the Locations list. */
function RemoveLocation(state: BookingWorkFlowState, index: number): BookingWorkFlowState {
    return {
        ...state,
        Locations: state.Locations.filter((_, i) => i !== index)
    };
}

/** Reorder the locations in the Locations list. */
function ReOrderLocations(state: BookingWorkFlowState, location: ReOrderedLocation): BookingWorkFlowState {

    const newLocations = state.Locations;
    const currentIndex = newLocations.indexOf(location.Location);

    newLocations.splice(currentIndex, 1);
    newLocations.splice(location.NewIndex, 0, location.Location);

    return {
        ...state,
        Locations: newLocations
    }
}