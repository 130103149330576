/**
 * FeatureIds for cards appearing on the WhatsNew list.
 * These numbers are used to keep track of acknowledged features and also to make the order of the list.
 * Recently added features should go to the top. Therefore, they are sorted in descending order.
 */
export enum FeatureId {

    Favourites = 1,
    Parcel = 2,
    DriverProfile = 3,
    MultipleTracking = 4,
    SearchYourFavourites = 5,
    /** This feature is not in use anymore. But this enum entry is kept commented out and not removed entirely to prevent any future developer renumbering the list because the acknowledged feature IDs are stored in localstorage and shouldn't be reassigned. */
    //DriverVaxed = 6,
    SaveAddresses = 7,
    TripHistory = 8,
    PaymentMethods = 9,
}

/**
 * NewFeatureIds array to render WhatIsNew on mobile (on the first load)
 * Please specify Which feature id that we would like to show into this array. 
 * e.g export const NewFeatureIds: FeatureId[] = [FeatureId.SearchYourFavourites];
 */
export const NewFeatureIds: FeatureId[] = [FeatureId.SaveAddresses];

/**
 * Parameters to be passed to each feature card
 */
export interface FeatureCardProps {

    /** Unique feature value */
    FeatureId: FeatureId;
    
    CanShowAcknowledgeButton: boolean;

    CanShowCloseButton: boolean;
}