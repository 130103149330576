
import { createStore, Store } from 'redux';
import rootReducer from './Redux/CombinedReducer'
import { composeWithDevTools } from 'redux-devtools-extension';
import { ApplicationState } from './appState';

const intitialState = {};
const appstore = createStore(rootReducer, intitialState, composeWithDevTools()) as Store<ApplicationState>;

export default appstore;
