import { ShowDialog } from "../ShowDialog";
import { RemoveTemplateUserAction } from "./RemoveTemplateEntities";

/** Open the dialog to confirm the removal of the favourite and returns true if the template is successfully deleted.*/
export async function ConfirmTemplateRemoval(templateID: number): Promise<boolean> {
    // Open the modal for confirmation of removal
    var removeTemplateResult = await ShowDialog.RemoveBookingTemplate({ TemplateID: templateID });

    // Closed the modal
    if (removeTemplateResult.DidUserCancel) return false;

    // Clicked on 'No' button
    if (removeTemplateResult.Output.UserAction === RemoveTemplateUserAction.Cancel) return false;

    // Remove template request failed. No user experience for this at the moment (similar to Cancel Booking).
    if (!removeTemplateResult.Output.IsRemoveSuccess) return false;

    // Removed successfully.
    return true;
}