import React from "react"
import { ServiceCheckStatus } from "./BookingEntities";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { getContentUrl, ContentURL } from "../Utils/ContentURL";
import "./PickupAddressStatus.scss";

/** Props from state.booking.PickupServiceCheck. */
interface PropsFromStore {

    /** Whether the pickup address is valid, or if the check is still in progress */
    status: ServiceCheckStatus;

    /** Only meaningful / expected to be populated when the status is KnownBad or Error. */
    errorMessage: string | undefined;
}

/** This component renders a small status indicator next to the input address indicating whether it is valid. */
class PickupAddressStatusCore extends React.Component<PropsFromStore> {

    render() {

        const imageUrl = this.getImageUrl();
        if (imageUrl === null) return null;

        return (
            <div className="pickup-address-status">
                <img src={imageUrl} />
            </div>
        );
    }

    /** Returns the URL of the image to display, or null if none should be displayed. */
    getImageUrl(): string | null {

        // for shorthand:
        const status = this.props.status;

        // if (status === ServiceCheckStatus.NoInputSelected) return null;
        // if (status === ServiceCheckStatus.KnownGood) return getContentUrl(ContentURL.images.GreenCheckMark);
        // if (status === ServiceCheckStatus.KnownBad) return getContentUrl(ContentURL.images.RedX);
        // if (status === ServiceCheckStatus.CheckInProgress) return getContentUrl(ContentURL.images.Loading);
        // if (status === ServiceCheckStatus.Error) return getContentUrl(ContentURL.images.RedX);

        // fallback; shouldn't happen
        //return null;

        /** 
         * Based on current UX/Zeplin design, we will only use erviceCheckStatus.CheckInProgress here.
         * But, I do not wanna make too much change here, because this implementation is smart and good.
         * Also, we can use this thinking somewhere else as an example.
        */

        if (status === ServiceCheckStatus.CheckInProgress) 
            return getContentUrl(ContentURL.images.Loading);
        else
            return null;
    }
}

/** Defines a mapping between the Redux store and props on PickupAddressStatusCore. */
function mapStateToProps(state: ApplicationState): PropsFromStore {

    const pickupCheck = state.booking.PickupServiceCheck;

    return {
        status: pickupCheck.status,
        errorMessage: (pickupCheck.status === ServiceCheckStatus.KnownBad) || (pickupCheck.status === ServiceCheckStatus.Error) ? pickupCheck.errorMessage.ProblemText : undefined,
    };
}

export const PickupAddressStatus = connect(mapStateToProps)(PickupAddressStatusCore);